const Constants = (global.Constants = {
  events: {
    CONTACT_FIND_A_LAWYER: (name, method, accountType) => ({
      event: 'find_a_lawyer_contact',
      name,
      method,
      accountType,
      dt: new Date().toISOString(),
    }),
    SEARCH_FIND_A_LAWYER: (search, accountType) => ({
      event: 'find_a_lawyer_search',
      search,
      accountType,
      dt: new Date().toISOString(),
    }),
    FILTER_FIND_A_LAWYER: (filter, accountType) => ({
      event: 'find_a_lawyer_filter',
      filter,
      accountType,
      dt: new Date().toISOString(),
    }),
    LOGIN: { event: 'User login', category: 'User' },
    REGISTER: { event: 'User register', category: 'User' },
    SIGNUP_FROM_LINK: (link) => ({
      event: 'signup_from_link_' + link,
      category: 'User',
    }),
  },
  defaultLocale: 'en',
  STORYBOOK: false,
  minWordCount: 100,
  UNLOCK_PURCHASES: true,
  businessTypes: [
    'LIMITED_COMPANY',
    'LIMITED_LIABILITY_PARTNERSHIP',
    'PARTNERSHIP',
    'SOLE_TRADER',
    'CHARITY',
    'OTHER',
  ],
  MIN_PROBLEM_WORDS: 100,
  simulate: __DEV__
    ? {
        PRODUCTS: true,
        acceptedTerms: true,
        submission:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse cursus pulvinar molestie. Integer non nulla ut velit egestas dignissim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dignissim purus eu vulputate posuere. Quisque eleifend viverra diam vitae elementum. Phasellus augue dolor, pharetra sit amet vestibulum ut, tincidunt sed libero. Aenean sit amet sollicitudin erat. Integer laoreet nibh non neque blandit, eget condimentum nisi scelerisque.\n' +
          '\n' +
          'Morbi id justo quis eros ornare rhoncus. Mauris ultrices turpis et vehicula ullamcorper. In scelerisque in tellus at blandit. Sed sit amet enim luctus, tincidunt quam vitae, tempor ante. Nunc tincidunt lacinia nulla ac feugiat. Sed risus est, tempus at aliquet et, tempor ut justo. Praesent lobortis, nisl id convallis dictum, dolor erat cursus justo, in vehicula eros metus sit amet elit. Vivamus eleifend sapien a quam eleifend, rutrum pretium sapien euismod. Ut mattis odio leo, iaculis rutrum neque consectetur vel. Fusce sem velit text ono.',
        // CONFIRM_EMAIL: true,
        // FORCE_SUB_PAGE: RouteUrls.BookingDetailsScreen,
        // FORCE_LANGUAGE: false, // set to "en" etc to specify a language
      }
    : {},

  blogURL: 'https://legalutopiablog.wordpress.com/',
  advertisingStatementURL: 'https://www.legalutopia.co.uk',
  complaintsURL: 'https://www.legalutopia.co.uk/complaints-policy',
  complaintEmail: 'customerservice@legalutopia.co.uk',
  complaintSubject: 'Complaint',
  confidentialityURL: 'https://www.legalutopia.co.uk/confidentiality',
  cookiesPolicyAppURL: 'https://www.legalutopia.co.uk/cookies-policy-app',
  cookiesPolicyURL: 'https://www.legalutopia.co.uk/cookies-policy',
  dataRetentionURL: 'https://www.legalutopia.co.uk/data-retention',
  dataRequestEmail: 'customerservice@legalutopia.co.uk',
  dataRequestSubject: 'Data request',
  disclaimerURL: 'https://www.legalutopia.co.uk/disclaimer-general',
  disclaimerURLLegalChecker:
    'https://www.legalutopia.co.uk/disclaimer-legal-checker',
  engagementLetter:
    'https://www.legalutopia.co.uk/engagement-letter-legal-utopia',
  employeePrivacyURL: 'https://www.legalutopia.co.uk/employee-privacy',
  equalOpportunitiesURL: 'https://www.legalutopia.co.uk/equal-opportunities',
  machineLearningURL: 'https://www.legalutopia.co.uk/machine-learning',
  marketingPolicyURL: 'https://www.legalutopia.co.uk/marketing-policy',
  privacyMembersURL: 'https://www.legalutopia.co.uk/privacy-policy',
  privacyURL: 'https://www.legalutopia.co.uk/privacy-policy',
  privacyWebURL: 'https://www.legalutopia.co.uk/privacy-policy-web',
  refundEmail: 'customerservice@legalutopia.co.uk',
  refundSubject: 'Refund request',
  supportEmail: 'customerservice@legalutopia.co.uk',
  supportSubject: 'Customer Support Request',
  supportURL: 'https://www.legalutopia.co.uk/support',
  termsBusinessURL: 'https://www.legalutopia.co.uk/terms-of-use-business',
  termsOfSaleBusinessURL:
    'https://www.legalutopia.co.uk/terms-of-sale-business',
  termsOfSaleLawyerURL: 'https://www.legalutopia.co.uk/terms-of-sale-lawyer',
  termsOfSaleURL: 'https://www.legalutopia.co.uk/terms-of-sale-consumer',
  termsURL: 'https://www.legalutopia.co.uk/terms-of-use-consumer',
  termsURLLawyers: 'https://www.legalutopia.co.uk/terms-of-use-lawyers',
  websiteURL: 'https://www.legalutopia.co.uk/index',
  statusBarHeight: 0, // gets set on launch
  pages: {
    NOT_FOUND: 'Not Found',
    HOME_PAGE: 'Home',
  },
  // <title>
  titles: {
    home: 'The Web App', // Used by default on all pages
    NOT_FOUND: 'Not Found',
    HOME_PAGE: 'Home',
  },
  // meta:description
  descriptions: {
    // Used by default
    home: '',
  },
  // meta:description
  keywords: {
    // Used by default
    home: '',
  },
  authRoutes: [
    '/signup',
    '/login',
    '/forgot-password',
    '/reset-password',
    '/reset-password/success',
    '/verify-email',
    '/mfa',
    '/mfa/verify',
    '/mfa/enable',
  ],
  // If paid Avvoka templates are ever figured out then this can be set to true,
  // which will enable paid document templates.
  isAvvokaEnabled: false,
})

export default Constants
