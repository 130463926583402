import React, { FC, useState } from 'react'
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api'

import location from 'assets/location.png'
import { AppState } from 'common/state-type'
import LawfirmCardMbl from 'components/CWP Sections/lawfirm/LawfrimCardMbl'
import ProfileCardMobile from 'components/CWP Sections/components/ProfileCardMobile'

export type MapType = {
  center: google.maps.LatLngLiteral
  barristers: AppState['barristers']
  lawyers: AppState['lawyerProfiles']
  offices: AppState['offices']
  activeMarker: string
  setActiveMarker: (v: string) => void
  areas?: string[]
  handleBookNowPress?: (item: any) => void
  handleFavPress?: (item: any) => void
}

type MapMarkerProps = {
  id: string
  latitude: number
  longitude: number
  fullName: string
  avatar: string
  nameInitials: string
  onClick: () => void
  onCloseClick: () => void
  activeMarker: string
}
const MapMarker: FC<MapMarkerProps> = ({
  id,
  latitude,
  longitude,
  avatar,
  fullName,
  nameInitials,
  onClick,
  onCloseClick,
  activeMarker,
}) => {
  return (
    <Marker
      icon={location}
      key={id}
      onClick={onClick}
      position={{
        lat: Number(latitude),
        lng: Number(longitude),
      }}
    >
      {activeMarker === id && (
        <InfoWindow
          position={{
            lat: Number(latitude),
            lng: Number(longitude),
          }}
          onCloseClick={onCloseClick}
          options={{ pixelOffset: new google.maps.Size(0, -30) }}
        >
          <div className='text-[black] flex flex-row'>
            <div
              className={`w-12 h-12 flex justify-center items-center rounded-full border-[#BAB9B9] border-2 text-xl text-black`}
            >
              {avatar ? (
                <img
                  className='object-cover relative rounded-full w-full h-full'
                  src={avatar}
                />
              ) : (
                nameInitials
              )}
            </div>

            <p>{fullName}</p>
          </div>
        </InfoWindow>
      )}
    </Marker>
  )
}

const Map: FC<MapType> = ({
  barristers,
  offices,
  center,
  activeMarker,
  lawyers,
  setActiveMarker,
  areas,
  handleFavPress,
  handleBookNowPress,
}) => {
  const [selectedItem, setSelectedItem] = useState(null)
  const isMobile = Utils.getWindowDimensions().width < 768

  const setMarker = (id: string | null) => {
    if (id) {
      const el = document.getElementById(`profile-${id}`)
      if (el) {
        el.scrollIntoView({ block: 'start', inline: 'start' })
      }
    }
    setActiveMarker(id)
  }

  return (
    <div className='flex flex-1 w-full md:w-[57.70vw]'>
      <GoogleMap
        mapContainerStyle={{
          height: '100vh',
          width: '100%',
          position: 'relative',
        }}
        zoom={10}
        center={center}
      >
        {!!barristers?.results?.length &&
          barristers.results.map((barrister) => (
            <MapMarker
              key={barrister.id}
              id={barrister.id}
              latitude={barrister.latitude}
              longitude={barrister.longitude}
              avatar={barrister.avatar}
              fullName={barrister.full_name}
              nameInitials={Utils.getNameInitials(barrister.full_name)}
              onClick={() => {
                setMarker(barrister.id)
                isMobile && setSelectedItem(barrister)
              }}
              onCloseClick={() => {
                setMarker(null)
                setSelectedItem(null)
              }}
              activeMarker={activeMarker}
            />
          ))}

        {!!lawyers?.results?.length &&
          lawyers.results.map((lawyer) => (
            <MapMarker
              key={lawyer.id}
              id={lawyer.id}
              latitude={lawyer.office.latitude}
              longitude={lawyer.office.longitude}
              avatar={lawyer.avatar}
              fullName={lawyer.user.first_name + ' ' + lawyer.user.last_name}
              nameInitials={Utils.getNameInitials(
                lawyer.user.first_name + ' ' + lawyer.user.last_name,
              )}
              onClick={() => {
                setMarker(lawyer.id)
                isMobile && setSelectedItem(lawyer)
              }}
              onCloseClick={() => {
                setMarker(null)
                setSelectedItem(null)
              }}
              activeMarker={activeMarker}
            />
          ))}

        {!!offices?.results?.length &&
          offices.results.map((office) => (
            <MapMarker
              key={office.id}
              id={office.id}
              latitude={office.latitude}
              longitude={office.longitude}
              avatar={office.organisation.logo}
              fullName={office.organisation.practice_name}
              nameInitials={office.organisation.practice_name_initials}
              onClick={() => {
                setMarker(office.id)
                isMobile && setSelectedItem(office)
              }}
              onCloseClick={() => {
                setMarker(null)
                setSelectedItem(null)
              }}
              activeMarker={activeMarker}
            />
          ))}
        {isMobile && selectedItem && (
          <div className='z-5 w-[80%] absolute bottom-8 left-[10%]'>
            <div className='overflow-scroll max-h-[calc(100vh-4rem)]'>
              {barristers || lawyers ? (
                <ProfileCardMobile
                  areas={areas}
                  onMouseEnter={() => {}}
                  latitude={center.lat}
                  longitude={center.lng}
                  data={selectedItem}
                  onFavPress={() => handleFavPress(selectedItem)}
                  onBookNow={() => handleBookNowPress(selectedItem)}
                  lawyerType={barristers ? 'barrister' : 'solicitor'}
                  onClose={() => {
                    setMarker(null)
                    setSelectedItem(null)
                  }}
                />
              ) : (
                <LawfirmCardMbl
                  onMouseEnter={() => null}
                  latitude={center.lat}
                  longitude={center.lng}
                  data={selectedItem}
                  onFavPress={() => handleFavPress(selectedItem)}
                  onBookNow={() => handleBookNowPress(selectedItem)}
                  onClose={() => {
                    setMarker(null)
                    setSelectedItem(null)
                  }}
                />
              )}
            </div>
          </div>
        )}
      </GoogleMap>
    </div>
  )
}

export default Map
