import cn from 'classnames'
import Popup from 'reactjs-popup'
import { useFormik } from 'formik'
import emailIcon from '../../assets/email.svg'
import phoneIcon from '../../assets/phone.svg'
import tickIcon from '../../assets/tick.svg'
import pencilIcon from '../../assets/pencil.svg'
import profileIcon from '../../assets/profile-filled-white.svg'
import suitcaseIcon from '../../assets/suitcase.svg'
import vectorBgLarge from '../../assets/vector-bg-large.png';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect, useCallback } from 'react'

import Constants from 'common/utils/constants'
import useInviteLink from 'common/providers/useInviteLink'
import useIndustries from 'common/providers/useIndustries'
import useOccupation from 'common/providers/useOccupation'
import useUploadUserAvatar from 'common/providers/useUploadUserAvatar'
import { AccountType } from '../../../../common/state-type'
import useProfile from '../../../../common/providers/useProfile'
import {
  ProfileSchema,
  BusinessProfileSchema,
} from 'common/utils/ProfileValidation'
import { BaseContainer } from 'components/BaseContainer'
import { InputGroup } from 'common/components/Inputs/InputGroup'
import { InputDropdown } from 'common/components/Inputs/InputDropdown'
import { InputMultiSelect } from 'common/components/Inputs/InputMultiSelect'
import { ProfileForm } from 'components/ProfileForm'
import { SiteFooter } from 'components/SiteFooter'
import { RoundIcon } from 'common/components/RoundIcon'
import { Avatar } from 'common/components/Avatar'
import { ImageCropModal } from 'components/ImageCropModal'
import { Area } from 'react-easy-crop'

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  htmlfor: string
  error?: string
}
export const TextInput: React.FC<TextInputProps> = ({
  label,
  htmlfor,
  error,
  ...rest
}) => {
  const borderColor = error ? 'border-red-500' : 'border-cyan-500'
  return (
    <div>
      <label
        className='block mb-2 text-lg font-medium text-blue-900'
        htmlFor={htmlfor}
      >
        {label}
      </label>
      <input
        className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${borderColor}`}
        {...rest}
      />
      {error && <span className='text-sm text-red-500'>{error}</span>}
    </div>
  )
}

const SubmitModal = ({ isOpen, closeModal }) => {
  return (
    <Popup open={isOpen} closeOnDocumentClick modal onClose={closeModal}>
      <div className='modal-dialog modal-dialog-centered modal-lg relative w-auto pointer-events-none'>
        <div className='modal-content border-none shadow-lg relative flex flex-col w-full bg-white bg-clip-padding rounded-md'>
          <div className='modal-header flex flex-shrink-0 border-b border-gray-200 rounded-t-md h-[7.29vh] bg-lawBlue'></div>
          <div className='modal-body relative h-[36.5vh] text-4xl font-bold color-darkBlue px-36 text-center pt-[8.9vh]'>
            <span>Your Profile details have now been updated</span>
          </div>
        </div>
      </div>
    </Popup>
  )
}

const initialBusinessProfile = {
  address_line_1: null,
  address_line_2: null,
  town: null,
  postal_code: null,
  business_name: '',
  business_type: '',
  industries: undefined,
}

const EditProfile: React.FC = () => {
  const { acceptedLink } = useInviteLink()
  const { getIndustries, industries } = useIndustries()
  const { profile, updateProfile, getProfile, profileSaving } = useProfile()
  const { getOccupations, occupations } = useOccupation()
  const [isBusiness, setIsBusiness] = useState(false)
  const [submitModal, setSubmitModal] = useState(false)
  const [showSwitchAccountsModal, setShowSwitchAccountsModal] = useState(false)
  const [showUploadAvatarModal, setShowAvatarModal] = useState(false)
  const { createUploadUserAvatar } = useUploadUserAvatar()

  useEffect(() => {
    getProfile()
    getIndustries({})
    getOccupations({})
  }, [getProfile, getIndustries, getOccupations])

  const industryData = industries?.map((data) => ({
    value: data.id,
    label: data.name,
  }))

  useEffect(() => {
    setIsBusiness(profile?.account_type === 'BUSINESS' ? true : false)
  }, [profile, industries])

  const { values, handleChange, handleSubmit, setFieldValue, errors } =
    useFormik({
      initialValues: {
        ...profile,
        business_profile: profile?.business_profile,
      },
      validationSchema:
        profile?.business_profile || isBusiness
          ? BusinessProfileSchema
          : ProfileSchema,
      onSubmit: (values) => {
        updateProfile(
          {
            ...values,
            used_invite_token: acceptedLink,
            business_profile: values.business_profile,
          },
          {
            onSuccess: () => {
              setSubmitModal(true)
            },
          },
        )
      },
    });

  const updateIsBusiness = useCallback((checked: boolean) => {
    values.account_type = checked
      ? AccountType.BUSINESS
      : AccountType.CONSUMER;

    values.business_profile = checked
      ? initialBusinessProfile
      : null;

    setIsBusiness(checked)
  }, []);

  const onSwitchAccountsClick = () => {
    setShowSwitchAccountsModal(true)
  };

  const onAvatarClick = () => {
    setShowAvatarModal(true);
  }

  const onAvatarSave = (file: File | null, crop?: Area) => {
    setShowAvatarModal(false);

    if (!file) {
      return;
    }

    createUploadUserAvatar({ file, ...crop }, {
      onSuccess: () => {
        getProfile();
      },
    });
  };

  if (!profile) {
    return null;
  }

  const fullName = `${profile.first_name} ${profile.last_name}`;

  return (
    <>
      <div className='bg-neutral-50'>
        <div
          className="bg-cover bg-bottom h-[150px]"
          style={{ backgroundImage: `url('${vectorBgLarge}')` }}
        />
        <BaseContainer size="md" className='mt-[-50px] md:mt-[-80px] lg:mt-[-40px]'>
          <div className="flex flex-col lg:flex-row items-center">
            <button
              type="button"
              className="relative cursor-pointer"
              onClick={onAvatarClick}
            >
              <Avatar
                isLarge
                className='border-4 border-white shadow-lg'
                fullName={fullName}
                imgSource={profile.avatar}
              />
              <RoundIcon
                src={pencilIcon}
                size={40}
                iconSize={16}
                className="absolute bg-lue-purple-400 bottom-[-2%] right-[-2%] md:bottom-[4px] md:right-[4px] lg:bottom-[6px] lg:right-[6px] border border-white border-2"
              />
            </button>
            <div className="mt-2 lg:ml-4 lg:mt-8 text-center lg:text-left">
              <h1 className='text-2xl lg:text-3xl font-bold'>
                {fullName}
              </h1>
              <p className='text-gray-600 text-base'>
                {values.email}
              </p>
            </div>
          </div>
        </BaseContainer>
      </div>
      <div className='bg-neutral-50'>
        <BaseContainer size="md" className="pt-8 lg:pt-12 pb-20">
          <ProfileForm
            title="Personal Info"
            description="Update your personal details"
            onSubmit={handleSubmit}
            onSwitchAccountsClick={onSwitchAccountsClick}
            isProfileSaving={profileSaving}
            isBusiness={isBusiness}
          >
            <div className="flex flex-col lg:flex-row space-y-6 lg:space-x-6 lg:space-y-0">
              <InputGroup
                isLabelVisible
                theme="grey"
                className='flex-1'
                id='first_name'
                label='First Name'
                type='text'
                name='first_name'
                onChange={handleChange}
                value={values.first_name}
                error={errors.first_name}
              />
              <InputGroup
                isLabelVisible
                theme="grey"
                className='flex-1'
                id='last_name'
                label='Surname'
                type='text'
                name='last_name'
                onChange={handleChange}
                value={values.last_name}
                error={errors.last_name}
              />
            </div>
            <InputGroup
              isLabelVisible
              theme="grey"
              className='flex-1'
              id='email'
              label='Email'
              type='email'
              name='email'
              value={values.email}
              imgBefore={emailIcon}
              disabled
            />
            <InputGroup
              isLabelVisible
              theme="grey"
              className='flex-1'
              id='phone_number'
              label='Telephone Number'
              type='tel'
              name='phone_number'
              onChange={handleChange}
              value={values.phone_number}
              error={errors.phone_number}
              imgBefore={phoneIcon}
            />
            {!!occupations?.length && (
              <InputDropdown
                isLabelVisible
                theme="grey"
                className="flex-1"
                name="occupation"
                placeholder="Select..."
                label="Occupation"
                value={values.occupation}
                error={errors.occupation}
                options={occupations.map((oc) => ({
                  label: oc.name,
                  value: oc.id,
                }))}
                onSelect={(value) => {
                  setFieldValue('occupation', value);
                }}
              />
            )}
            <div className="flex flex-col lg:flex-row space-y-6 lg:space-x-6 lg:space-y-0">
              <InputDropdown
                isLabelVisible
                theme="grey"
                className="flex-1"
                name="gender"
                placeholder="Select..."
                label="Gender"
                value={values.gender}
                error={errors.gender}
                options={[
                  { label: 'Male', value: 'MALE' },
                  { label: 'Female', value: 'FEMALE' },
                  { label: 'Other', value: 'OTHER' },
                ]}
                onSelect={(value) => {
                  setFieldValue('gender', value);
                }}
              />
              <InputGroup
                isLabelVisible
                theme="grey"
                className='flex-1'
                id='date_of_birth'
                label='Date of birth'
                type='date'
                name='date_of_birth'
                onChange={handleChange}
                value={values.date_of_birth}
                error={errors.date_of_birth}
              />
            </div>
            <InputGroup
              isLabelVisible
              theme="grey"
              className="flex-1"
              id="address_line_1"
              label='No. / Building'
              type='text'
              placeholder=''
              name='address_line_1'
              onChange={handleChange}
              value={values.address_line_1}
              error={errors.address_line_1}
            />
            <InputGroup
              isLabelVisible
              theme="grey"
              className="flex-1"
              id="address_line_2"
              label='Street address'
              type='text'
              placeholder=''
              name='address_line_2'
              onChange={handleChange}
              value={values.address_line_2}
              error={errors.address_line_2}
            />
            <div className="flex flex-col lg:flex-row space-y-6 lg:space-x-6 lg:space-y-0">
              <InputGroup
                isLabelVisible
                theme="grey"
                className="flex-1"
                id="town"
                label='Town'
                type='text'
                placeholder=''
                name='town'
                onChange={handleChange}
                value={values.town}
                error={errors.town}
              />
              <InputGroup
                isLabelVisible
                theme="grey"
                className="flex-1"
                id="county"
                label='City'
                type='text'
                placeholder=''
                name='county'
                onChange={handleChange}
                value={values.county}
                error={errors.county}
              />
            </div>
            <div className="flex flex-col lg:flex-row space-y-6 lg:space-x-6 lg:space-y-0">
              <InputGroup
                isLabelVisible
                theme="grey"
                className="flex-1"
                id="postal_code"
                label='Post code'
                type='text'
                placeholder=''
                name='postal_code'
                onChange={handleChange}
                value={values.postal_code}
                error={errors.postal_code}
              />
              <div className='flex-1' />
            </div>
          </ProfileForm>

          {isBusiness && (
            <ProfileForm
              className="mt-20"
              title="Business Details"
              description="Enter your business details"
              onSubmit={handleSubmit}
              onSwitchAccountsClick={onSwitchAccountsClick}
              isProfileSaving={profileSaving}
              isBusiness={isBusiness}
            >
              <div className="flex flex-col lg:flex-row space-y-6 lg:space-x-6 lg:space-y-0">
                <InputGroup
                  isLabelVisible
                  theme="grey"
                  className="flex-1"
                  id="business_name"
                  label='Company Name'
                  type='text'
                  placeholder=''
                  name='business_profile.business_name'
                  onChange={handleChange}
                  value={values.business_profile?.business_name}
                  error={errors.business_profile?.business_name}
                />
                <InputMultiSelect
                  isLabelVisible
                  theme="grey"
                  className="flex-1"
                  label='Industry'
                  value={(values.business_profile?.industries ?? []).map((industry) => ({
                    value: industry,
                    label: industryData?.find((data) => data.value === industry)?.label,
                  }))}
                  onChange={(items) => {
                    setFieldValue('business_profile.industries', items)
                  }}
                  name='business_profile.industries'
                  error={
                    Array.isArray(errors.business_profile?.industries)
                      ? errors.business_profile?.industries[0]
                      : errors.business_profile?.industries
                  }
                  options={industryData}
                />
              </div>
              <InputDropdown
                isLabelVisible
                theme="grey"
                className="flex-1"
                label='Business Category'
                value={values.business_profile?.business_type}
                name='business_profile.business_type'
                error={errors.business_profile?.business_type}
                options={Constants.businessTypes.map((business) => ({
                  label: sentenceCase(business),
                  value: business,
                }))}
                onSelect={(value) => {
                  setFieldValue('business_profile.business_type', value);
                }}
              />
              <InputGroup
                isLabelVisible
                theme="grey"
                className="flex-1"
                id="business_address_line_1"
                label='No. / Building'
                type='text'
                name='business_profile.address_line_1'
                onChange={handleChange}
                value={values.business_profile?.address_line_1}
                error={errors.business_profile?.address_line_1}
              />
              <InputGroup
                isLabelVisible
                theme="grey"
                className="flex-1"
                id="business_address_line_2"
                label='Street address'
                type='text'
                name='business_profile.address_line_2'
                onChange={handleChange}
                value={values.business_profile?.address_line_2}
                error={errors.business_profile?.address_line_2}
              />
              <div className="flex flex-col lg:flex-row space-y-6 lg:space-x-6 lg:space-y-0">
                <InputGroup
                  isLabelVisible
                  theme="grey"
                  className="flex-1"
                  id="business_town"
                  label='Town'
                  type='text'
                  placeholder=''
                  name='business_profile.town'
                  onChange={handleChange}
                  value={values.business_profile?.town}
                  error={errors.business_profile?.town}
                />
                <InputGroup
                  isLabelVisible
                  theme="grey"
                  className="flex-1"
                  id="business_county"
                  label='City'
                  type='text'
                  placeholder=''
                  name='business_profile.county'
                  onChange={handleChange}
                  value={values.business_profile?.county}
                  error={errors.business_profile?.county}
                />
              </div>
              <div className="flex flex-col lg:flex-row space-y-6 lg:space-x-6 lg:space-y-0">
                <InputGroup
                  isLabelVisible
                  theme="grey"
                  className="flex-1"
                  id="business_postal_code"
                  label='Post code'
                  type='text'
                  placeholder=''
                  name='business_profile.postal_code'
                  onChange={handleChange}
                  value={values.business_profile?.postal_code}
                  error={errors.business_profile?.postal_code}
                />
                <div className='flex-1' />
              </div>
            </ProfileForm>
          )}
        </BaseContainer>
      </div>
      <SubmitModal
        isOpen={submitModal}
        closeModal={() => {
          setSubmitModal(false)
        }}
      />

      {/* Switch accounts modal */}
      <Popup
        open={showSwitchAccountsModal}
        closeOnDocumentClick
        modal
        onClose={() => {
          setShowSwitchAccountsModal(false);
        }}
      >
        <div className='modal-dialog modal-dialog-centered z-50'>
          <div className='modal-content shadow-lg w-full bg-white bg-clip-padding rounded-3xl'>
            <div className='modal-body text-4xl font-bold color-darkBlue p-12'>
              <p className="text-center text-xl font-medium mb-6">
                Change account type
              </p>
              <div className="flex flex-direction space-x-12">
                <button
                  className="flex flex-col items-center cursor-pointer"
                  onClick={() => {
                    updateIsBusiness(true);
                    setShowSwitchAccountsModal(false);
                  }}
                >
                  <div
                    className={cn(
                      "block w-[111px] h-[111px] rounded-xl mb-5 flex items-center justify-center relative",
                      isBusiness ? 'bg-lue-purple-400' : 'bg-lue-blue-300',
                    )}
                  >
                    <RoundIcon
                      src={suitcaseIcon}
                      size={60}
                      iconSize={isBusiness ? 50 : 35}
                      className="bg-lue-purple-400"
                    />
                    {isBusiness && (
                      <RoundIcon
                        src={tickIcon}
                        size={30}
                        iconSize={18}
                        className="absolute bg-lue-turquoise-300 bottom-[-15px]"
                      />
                    )}
                  </div>
                  <p className="text-center text-xl font-bold">
                    Business
                  </p>
                  <p
                    className={cn(
                      'text-lg font-normal',
                      isBusiness ? 'text-gray-500' : 'text-lue-purple-400',
                    )}
                  >
                    {isBusiness ? 'Current' : 'Switch to business'}
                  </p>
                </button>
                <button
                  className="flex flex-col items-center cursor-pointer"
                  onClick={() => {
                    updateIsBusiness(false);
                    setShowSwitchAccountsModal(false);
                  }}
                >
                  <div
                    className={cn(
                      "block w-[111px] h-[111px] rounded-xl mb-5 flex items-center justify-center relative",
                      isBusiness ? 'bg-lue-blue-300' : 'bg-lue-purple-400',
                    )}
                  >
                    <RoundIcon
                      src={profileIcon}
                      size={60}
                      iconSize={isBusiness ? 30 : 40}
                      className="bg-lue-purple-400"
                    />
                    {!isBusiness && (
                      <RoundIcon
                        src={tickIcon}
                        size={30}
                        iconSize={18}
                        className="absolute bg-lue-turquoise-300 bottom-[-15px]"
                      />
                    )}
                  </div>
                  <p className="text-center text-xl font-bold">
                    Personal
                  </p>
                  <p
                    className={cn(
                      'text-lg font-normal',
                      isBusiness ? 'text-lue-purple-400' : 'text-gray-500',
                    )}
                  >
                    {isBusiness ? 'Switch to personal' : 'Current'}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Popup>

      <ImageCropModal
        open={showUploadAvatarModal}
        onClose={() => {
          setShowAvatarModal(false);
        }}
        aspectRatio={1}
        onSave={onAvatarSave}
      />
      <SiteFooter />
    </>
  )
}

export default EditProfile
