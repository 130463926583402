import Rating from "react-rating";
import React, { useState } from "react";
import { FaAngleLeft, FaRegStar, FaStar } from "react-icons/fa";

import { AccountType } from "common/state-type";
import { ThemedButton } from "./AppointmentBase";
import useReview from "common/providers/useReview";
import useProfile from "common/providers/useProfile";

interface AddReviewToAppointmentProps {
  theme: {
    hex: string;
    class: string;
  };
  onBackClick: () => void;
  lawyerType: "lawyer" | "barrister";
  lawyerId: string;
}

const AddReviewToAppointment: React.FC<AddReviewToAppointmentProps> = ({
  theme,
  onBackClick,
  lawyerType,
  lawyerId,
}) => {
  const { profile } = useProfile();
  const { createReview, reviewSaving } = useReview();
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const handleSendClick = () => {
    if (rating !== 0 && reviewText !== "") {
      createReview(
        {
          [lawyerType]: lawyerId,
          rating,
          review: reviewText,
          requested_removal: false,
        },
        {
          onSuccess: (data) => {
            onBackClick();
          },
        },
      );
    }
  };

  return (
    <div className='flex flex-1 flex-col w-full h-full text-black md:text-legalColor-midBlue'>
      <div className='hidden md:block w-full p-2'>
        <button onClick={onBackClick} className='flex flex-row items-center'>
          <FaAngleLeft size={26} />
          <span className='pl-2'>back</span>
        </button>
      </div>
      <div className='flex pt-8 md:pt-[15vw] px-[13vw] flex-1 text-lg font-bold flex-col'>
        <span className='md:hidden pb-4 text-lg font-bold'>Review Booking</span>
        <div className='md:w-[31vw] flex flex-col'>
          <span className='text-sm md:text-base pr-2'>
            Leave a star review:{' '}
          </span>
          <Rating
            emptySymbol={
              <FaRegStar className='mt-2' size={24} color='#122D5C' />
            }
            fullSymbol={<FaStar className='mt-2' size={24} color='#122D5C' />}
            onChange={(value) => {
              setRating(value)
            }}
          />
          <div className='pt-4 w-[50%] md:w-auto'>
            <span className='text-sm md:text-base font-bold'>Name:</span>
            <div
              style={{ borderColor: theme.hex }}
              className={`border py-2 px-2 rounded-lg mt-2 mb-4 text-sm md:text-base`}
            >
              {profile.first_name} {profile.last_name}
            </div>
          </div>
          <>
            <span>Review:</span>
            <textarea
              style={{ borderColor: theme.hex }}
              onChange={(e) => {
                setReviewText(e.target.value)
              }}
              className={`w-full h-40 p-2 mt-2 border rounded-lg`}
            ></textarea>
          </>
          <div className='flex md:justify-end w-full mt-2'>
            <ThemedButton
              btnClass={`${theme.class}`}
              onClick={handleSendClick}
              disabled={reviewSaving}
            >
              Send
            </ThemedButton>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AddReviewToAppointment;
