import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseBarristers = {
  barristers: AppState['barristers']
  barristersLoading: AppState['barristersLoading']
  barristersError: AppState['barristersError']
  getBarristers: (
    data: RequestTypes['getBarristers'],
    callbacks?: Callbacks,
  ) => void
}

export default function useBarristers(): UseBarristers {
  const { barristers, barristersLoading, barristersError } = useSelector(
    (state: AppState) => ({
      barristers: state.barristers,
      barristersLoading: state.barristersLoading,
      barristersError: state.barristersError,
    }),
  )
  const dispatch = useDispatch()
  const getBarristers = useCallback(
    (data: RequestTypes['getBarristers'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getBarristers(data, callbacks))
    },
    [dispatch],
  )
  return {
    barristers,
    barristersLoading,
    barristersError,
    getBarristers,
  }
}
