import { MembershipType } from "../../../../../common/state-type";

type LFProfileDetailProps = {
  lawyerInfo?: {
    name: string;
    operatingAreas: string[];
    experience: string;
    jobTitle: string;
  };
  areas?:string[]
  name: string;
  isLegalAid: boolean;
  subcriptionType: string;
  approvalStatus: boolean;
  operatingAreas: string[];
  themeColor: {
    hex: string;
    class: string;
  };
};

export const LFProfileDetail: React.FC<LFProfileDetailProps> = ({
  name,
  isLegalAid,
  subcriptionType,
  approvalStatus,
  operatingAreas,
                                                                  areas:filteredAreas,
  themeColor,
  lawyerInfo,
}) => {
  const renderOperatingAreas = () => {
    const areas = lawyerInfo ? lawyerInfo.operatingAreas : operatingAreas;
    if (Boolean(areas))
      return (
        <>
          <span className="text-black text-sm font-normal">Operating areas</span>
          <div className="gap-2 py-3">
            {areas.map((area: string, index: number) => {
              let textColor: string;
              const isActive = filteredAreas?.includes(area);
              textColor = isActive ? "text-white" : "text-black";

              return (
                  <span
                      key={area}
                      style={
                        isActive
                            ? { backgroundColor: themeColor.hex }
                            : { backgroundColor: "#F4F4F4" }
                      }
                      className={`px-2.5 py-1.5 items-center text-sm inline-block text-center whitespace-nowrap align-baseline ${textColor} rounded-lg mr-2 mb-1`}
                  >
                  {area}
                </span>
              );
            })}
          </div>
        </>
      );
    return <></>;
  };

  return (
    <div className='flex flex-col w-full ml-4 mr-2'>
      <div className='flex flex-row place-content-between'>
        <div className='flex flex-row items-center max-w-xs'>
          <h3 className='font-bold text-black text-lg pr-2'>{name}</h3>
        </div>
        <div>
          {subcriptionType?.toUpperCase() !== MembershipType.NONE && (
            <span
              className={`text-sm inline-block p-2 text-black rounded-lg font-bold ml-2 ${themeColor.class}`}
            >
              {subcriptionType}
            </span>
          )}
        </div>
      </div>
      {lawyerInfo ? (
        <>
          <div className='flex flex-row items-center mt-2'>
            <h3 className='font-bold text-black text-lg pr-2'>
              {" "}
              {Utils.capitalizeFirstLetter(lawyerInfo.name)}
            </h3>
            <p className='text-xl text-black'> {lawyerInfo.jobTitle} </p>
          </div>
          <span className='text-black text-sm mb-2 font-normal'>
            Experience :{" "}
            {`${lawyerInfo.experience} ${"year"}${
              Number(lawyerInfo.experience) !== 1 ? "s" : ""
            }`}
          </span>
        </>
      ) : (
        <div className='flex flex-row items-center py-3'>
          {isLegalAid && (
            <h4 className='text-xl text-black text-center max-w-[260px]  mr-2'>
              Legal Aid
            </h4>
          )}
          {approvalStatus && (
            <span
              className={`text-sm inline-block p-2 text-black rounded-lg font-bold ml-2 ${themeColor.class}`}
            >
              Approved
            </span>
          )}
        </div>
      )}

      {renderOperatingAreas()}
    </div>
  );
};
