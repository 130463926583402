import React from "react";
import PropTypes from "prop-types";
import newsletter from "../../assets/Public Content/newsletter.png";
import icon_readarticle from "../../assets/Public Content/icon_readarticle.png";
import NewsLetterGridView from "./NewsLetterGridView";

const Newsletter = () => {
  return (
    <React.Fragment>
      <div className="bg-[#3C5996] bg-opacity-[0.1] py-[60px]">
        <div className="flex lg:max-w-[1024px] justify-between items-center mx-auto">
          <p className="text-[24px] font-bold text-[#1B4A8A] text-left my-[30px]">
            Newsletters
          </p>
        </div>
        <div className="flex lg:max-w-[1024px] justify-between items-center mx-auto">
          <div className="md:flex  flex-col md:flex-row  md:space-x-20">
            <div className="md:base-1/2">
              <div className="md:w-[462px]">
                <img src={newsletter} className="max-w-full h-auto" />
              </div>
            </div>
            <div className="md:base-1/2">
              <div className="flex flex-col mx-auto items-left md:max-w-[520px] p-[20px]">
                <div className="text-[#1B4A8A] text-[40px] font-bold pb-[10px] text-left">
                  LawTech Amman
                </div>
                <div className="text-[24px] font-bold text-[#20BFE7] pb-[10px] text-left">
                  Legal Utopia in MENA
                </div>
                <div className="text-[16px] text-[#1B4A8A] pt-[10px] text-justify leading-[24px]">
                  Technology brings the law closer to people. We have been
                  bringing the law closer to more than 20,000 people since 2017.
                  We have partnered with many law firms and organisations,
                  receiving funding from national and international institutions
                  for our innovative approaches. We’ve put law into peoples’
                  pockets.
                </div>
                <div className="flex flex-row md:mt-[40px]">
                  <div className="text-[#1B4A8A] leading-[26px] text-[20px]  font-bold underline">
                    Read full article
                  </div>
                  <div className="p-[7px]">
                    <img src={icon_readarticle} className="max-w-full h-auto" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetterGridView />
    </React.Fragment>
  );
};

Newsletter.propTypes = {};

export default Newsletter;
