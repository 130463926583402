import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type useFavourite = {
  favourites: AppState['favourites']
  favouritesLoading: AppState['favouritesLoading']
  favouritesSaving: AppState['favouritesSaving']
  favouritesError: AppState['favouritesError']
  createFavourite: (
    data: RequestTypes['createFavourite'],
    callbacks?: Callbacks,
  ) => void

  deleteFavourite: (
    data: RequestTypes['deleteFavourite'],
    callbacks?: Callbacks,
  ) => void
}

export default function useFavourite(): useFavourite {
  const { favourites, favouritesError, favouritesSaving, favouritesLoading } =
    useSelector((state: AppState) => ({
      favourites: state.favourites,
      favouritesLoading: state.favouritesLoading,
      favouritesSaving: state.favouritesSaving,
      favouritesError: state.favouritesError,
    }))
  const dispatch = useDispatch()

  const createFavourite = useCallback(
    (data: RequestTypes['createFavourite'], callbacks?: Callbacks) => {
      return dispatch(AppActions.createFavourite(data, callbacks))
    },
    [dispatch],
  )

  const deleteFavourite = useCallback(
    (data: RequestTypes['deleteFavourite'], callbacks?: Callbacks) => {
      return dispatch(AppActions.deleteFavourite(data, callbacks))
    },
    [dispatch],
  )

  return {
    favourites,
    favouritesError,
    favouritesSaving,
    favouritesLoading,
    createFavourite,
    deleteFavourite,
  }
}
