import React from "react";

const Subscribe = () => {
  return (
    <React.Fragment>
      <div className="bg-[#294B85] py-[60px]">
        <div className="flex md:flex-col lg:max-w-[1024px] justify-between items-left mx-auto">
          <div className="flex justify-center space-x-10">
            <div className="form-check form-check-inline pt-40px">
              <div className="bg-[#20BFE7] rounded-[11px] shadow-sm shadow-[rgba(160, 160, 160, 0.35)] px-[30px] py-[12px] text-white text-[30px] leading-[28px]">
                Subscribe
              </div>
            </div>
            <div className="form-check form-check-inline">
              <div className="form-check">
                <input
                  type="text"
                  placeholder="Enter your Email Address"
                  className="w-[400px] px-[25px] py-[11px] bg-[#D9D9D9] text-white placeholder:text-white border-[1px] border-solid border-[#D9D9D9] rounded-[11px] leading-[28px]"
                />
              </div>
              <div className="form-check pt-[20px]">
                <input
                  className="form-check-input appearance-none h-5 w-5 border border-gray-300 rounded-[5px] bg-[#BAB9B9] checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="option1"
                />
                <label
                  className="form-check-label inline-block text-white-500 text-white leading-[28px] text-[20px]"
                  htmlFor="contest"
                >
                  I consent to recieving marketing material
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Subscribe;
