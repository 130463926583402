import cn from 'classnames';
import appleAppStoreBadgeLight from "../../assets/AppStore/apple-app-store-badge-light.svg";
import appleAppStoreBadgeDark from "../../assets/AppStore/apple-app-store-badge-dark.svg";

type AppleAppStoreButtonProps = {
  className?: string;
  theme: 'light' | 'dark';
}

export const AppleAppStoreButton = ({
  className,
  theme ='light',
}: AppleAppStoreButtonProps) => (
  <a
    className={cn('block', className)}
    aria-label="Download on the App Store"
    href="https://apps.apple.com/gb/app/legal-utopia/id1487650991">
    <img
      src={theme === 'light' ? appleAppStoreBadgeLight : appleAppStoreBadgeDark}
      width="150px"
      height="50px"
      alt="App Store logo"
    />
  </a>
);