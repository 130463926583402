import React from 'react'
import cn from 'classnames'

interface Button {
  className?: string
  children?: React.ReactChildren | React.ReactChild
  onClick?: () => void
  onMouseUp?: () => void
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  icon?: string
}

//Default Button without any styles
export const Button: React.FC<Button> = ({
  icon,
  className,
  children,
  onMouseUp,
  ...rest
}) => (
  <button
    type='button'
    {...rest}
    onMouseUp={onMouseUp}
    className={cn({ btn: true }, className)}
  >
    {children}

    {icon ? (
      <div className='btn__icon'>
        <i className={cn({ icon: true }, icon)} />
      </div>
    ) : null}
  </button>
)