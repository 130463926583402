import Select,{StylesConfig} from "react-select";
import React,{ FC, useEffect } from "react";
import useAreasOfPractice from "../common/providers/useAreasOfPractice";


type ComponentType = {
  value: string[],
  onChange: (v: string[]) => void,
  styles?: StylesConfig<any>
    disabled?:boolean

}

const TheComponent: FC<ComponentType> = ({ value: _value = [],disabled, onChange,styles }) => {
  const { getAreasOfPractice, areasOfPractice } = useAreasOfPractice();
  useEffect(() => {
    getAreasOfPractice({});
  }, []);
  const options = areasOfPractice?.map((a) => ({ value: a.id, label: a.name }));
  const value = areasOfPractice
    ? _value.map((v) => ({
        value: v,
        label: areasOfPractice.find((a) => a.id === v)?.name,
      }))
    : [];


  return (
    <Select
       isDisabled={disabled}
      styles={styles}
      className="react-select"
      classNamePrefix="react-select"
      placeholder="Select..."
      value={value}
      onChange={(v) => {
        onChange(v.map((a) => a.value));
      }}
      options={options}
      isMulti
      isSearchable

    />
  );
};

export default TheComponent;
