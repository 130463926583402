import React, { useCallback, useRef, useState } from 'react'
import * as yup from 'yup'
import { FormPageWrapper } from './FormPageWrapper'
import { FormMessage } from './FormMessage'
import { useFormik } from 'formik'
import { Button } from './Button'
import { FooterText } from './FooterText'
import Cognito from '../../cognito'
import { VerificationCodeInput } from './VerificationCodeInput'
import { PasswordInputGroup } from '../Inputs/PasswordInputGroup'
import { InputGroup } from '../Inputs/InputGroup'

const schema = yup.object().shape({
  code: yup.string().required('Confirmation code is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().required('Password is required'),
  confirm_password: yup
    .string()
    .required('Password confirmation is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

const ResetPasswordPage = () => {
  const codeInputRef = useRef<HTMLInputElement>(null)
  const [isResending, setIsResending] = useState<boolean>(false)
  const [formError, setFormError] = useState<string>('')
  const [currentStep, setCurrentStep] = useState(1)
  const { email } = Utils.fromParam()

  const resendEmail = useCallback(async () => {
    setFormError('')
    setIsResending(true)
    try {
      await Cognito.resendConfirmSignUp(email)
      setFormError('Verification code resent successfully')
    } catch (error) {
      setFormError(error.message || 'Failed to resend verification code')
    } finally {
      setIsResending(false)
    }
  }, [email])

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      email: email ?? '',
      code: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        await Cognito.changePassword(values.email, values.code, values.password)
        document.location.href = '/reset-password/success'
      } catch (err) {
        setFormError(err.message || 'Failed to reset password')
      }
    },
  })

  const { handleBlur, handleChange, values, errors, setTouched } = formik

  const onNextStep = () => {
    if (currentStep === 1) {
      const code = codeInputRef.current?.value || ''
      if (!code.trim()) {
        setFormError('Please enter the verification code')
        return
      }
      if (!values.email) {
        setFormError('Please enter your email')
        return
      }
      setFormError('')
      setTouched({ email: true, code: true })
      formik.setFieldValue('code', code)
      setCurrentStep(2)
    } else {
      formik.handleSubmit()
    }
  }

  return (
    <FormPageWrapper title='New Password'>
      <p className=' text-center text-zinc-600 text-base'>
        We've sent you a reset code
        {email && (
          <>
            {' '}
            to <span className='font-bold text-black text-base'>{email}</span>
          </>
        )}
        .{' '}
      </p>
      <p className='mb-4 text-center text-zinc-600 text-base'>
        {currentStep === 1
          ? 'Please input the code below.'
          : 'Please choose your new password.'}
      </p>

      <FormMessage type='error'>{formError}</FormMessage>

      {isResending && (
        <FormMessage type='success'>
          The verification email has been resent.
        </FormMessage>
      )}

      <form onSubmit={formik.handleSubmit}>
        {currentStep === 1 && (
          <>
            <VerificationCodeInput isNew ref={codeInputRef} />
          </>
        )}
        {currentStep === 2 && (
          <>
            <PasswordInputGroup
              id='password'
              label='Password'
              name='password'
              theme='grey'
              autoComplete='password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={
                typeof errors.password === 'string'
                  ? errors.password
                  : undefined
              }
              className='my-5'
              type='password'
              placeholder='Password'
            />
            <PasswordInputGroup
              id='confirm_password'
              label='Confirm Password'
              name='confirm_password'
              theme='grey'
              autoComplete='new-password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirm_password}
              error={
                typeof errors.confirm_password === 'string'
                  ? errors.confirm_password
                  : undefined
              }
              className='my-5'
              type='password'
              placeholder='Confirm Password'
            />
          </>
        )}
        <Button type='button' onClick={onNextStep}>
          {currentStep === 1 ? 'Next' : 'Reset Password'}
        </Button>
      </form>
      <div className='mt-4'>
        <FooterText
          text="Didn't get the email?"
          linkOnClick={resendEmail}
          linkText='Click to resend'
        />
        <FooterText text='Back to' linkHref='/login' linkText='Sign In' />
      </div>
    </FormPageWrapper>
  )
}

export default ResetPasswordPage
