import React, { useContext, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useAuth } from 'common/providers/useAuth'
import Project from 'common/project'
import { SocialSignInButtons } from './SocialSignInButtons'
import { FooterText } from './FooterText'
import { Button } from './Button'
import { InputGroup } from '../Inputs/InputGroup'
import { CheckboxGroup } from '../Inputs/CheckboxGroup'
import { FormPageWrapper } from './FormPageWrapper'
import { FormMessage } from './FormMessage'
import { PasswordInputGroup } from '../Inputs/PasswordInputGroup'
import { AuthPageContext } from '../../providers/AuthPageProvider'
import { encodeAuthState } from 'common/utils/auth'

const schema = yup.object().shape({
   first_name: yup.string().required('Please set your first name'),
  last_name: yup.string().required('Please set your last name'),
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Please enter an email address'),
  password: yup.string().required('Please set your password'),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Please confirm your Password'),
  policies: yup
    .boolean()
    .oneOf([true], 'Please accept the terms and privacy policy'),
});

const SignUpPage = () => {
  const { hasSocialSignIn, hasNameFields, accountType, hasMarketing } = useContext(AuthPageContext);

  const [error, setError] = useState<string | undefined>();
  const { register } = useAuth();

  const { handleBlur, handleSubmit, handleChange, errors, setTouched, values } = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      email: '',
      password: '',
      repeatPassword: '',
      first_name: '',
      last_name: '',
      account_type: '',
      policies: false,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      register(
        {
          ...values,
          account_type: accountType,
          accepted_marketing: hasMarketing,
        },
        {
          onSuccess: () => {
                      console.log('success');
            document.location = `${
              document.location.origin
            }/verify-email?state=${encodeAuthState({
              email: values.email,
              password: values.password,
            })}`;
          },
          onError: setError,
        }
      );
    },
  });

  const onSubmit = (e) => {
    const newTouched = {};
    Object.keys(errors).map((v) => {
      newTouched[v] = true;
    });
    setTouched(newTouched);
    handleSubmit(e);
  };

  return (
    <FormPageWrapper className={'w-full md:w-[84%] '} title='Create Account'>
      <FormMessage type='error'>{error}</FormMessage>
      <form onSubmit={onSubmit} className='mt-10'>
        {hasNameFields && (
          <>
            <InputGroup
              id='first_name'
              label='First Name'
              name='first_name'
              theme='grey'
              isLabelVisible
              isNew
              onChange={handleChange}
              value={values.first_name}
              placeholder='First Name'
              onBlur={handleBlur}
              error={errors.first_name}
              className={` mt-5 mx-auto `}
            />
            <InputGroup
              id='last_name'
              label='Last Name'
              name='last_name'
              theme='grey'
              isLabelVisible
              isNew
              onChange={handleChange}
              value={values.last_name}
              placeholder='Last Name'
              onBlur={handleBlur}
              error={errors.last_name}
              className={` mt-5 mx-auto`}
            />
          </>
        )}
        <InputGroup
          id='email'
          label='Email'
          name='email'
          theme='grey'
          isLabelVisible
          isNew
          onChange={handleChange}
          value={values.email}
          placeholder='Email Address'
          onBlur={handleBlur}
          error={errors.email}
          className={`my-5  mx-auto `}
        />
        <PasswordInputGroup
          id='password'
          label='Password'
          name='password'
          theme='grey'
          isNew
          isLabelVisible
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.password}
          value={values.password}
          type='password'
          placeholder='Password'
          className={` my-5 mx-auto `}
        />
        <PasswordInputGroup
          id='repeatPassword'
          label='Repeat Password'
          name='repeatPassword'
          theme='grey'
          isNew
          isLabelVisible
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.repeatPassword}
          value={values.repeatPassword}
          type='password'
          placeholder='Repeat Password'
          className={` my-2 mx-auto `}
        />
        <div className="w-full  mx-auto">
        <CheckboxGroup
          id='terms'
          className='mb-5 flex flex-row items-start'
          label={
            <span className='text-base text-black'>
              I agree with the
              <a
                href={new URL('/terms-of-use-consumer', Project.cwp).href}
                className='!text-blue inline-block px-1'
              >
                Terms
              </a>
              and
              <a
                href={new URL('/privacy-policy-web', Project.cwp).href}
                className='!text-blue inline-block px-1'
              >
                Privacy policy
              </a>
            </span>
          }
          name='policies'
          onBlur={handleBlur}
          error={errors.policies}
          checked={values.policies}
          onChange={handleChange}
        />
        </div>
        <Button type='submit' className='!w-[40%] mt-10 !mx-auto'>Continue</Button>
      </form>

      <div className='mt-4 '>
        {hasSocialSignIn && (
          <>
            <p className='text-base font-normal text-center text-primary-font !py-4 mb-4 !leading-6 '>
              or</p>
            <SocialSignInButtons className={'w-full mx-auto'} />
          </>
        )}
        <FooterText
          text='Already have an account?'
          linkHref='/login'
          linkText='Log In'
        />
      </div>

    </FormPageWrapper>
  );
};

export default SignUpPage;
