import Popup from 'reactjs-popup'
import Cropper, { Area } from 'react-easy-crop'
import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

type ImageCropModalProps = {
  open: boolean;
  onClose: () => void;
  onSave: (file: File | null, crop?: Area) => void;
  aspectRatio: number;
};

export const ImageCropModal = ({
  open,
  onClose,
  aspectRatio,
  onSave,
}: ImageCropModalProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
  const [file, setFile] = useState<File | null>(null);
  const [displayedImage, setDisplayedImage] = useState<string>();

  const onCropComplete = useCallback((_croppedArea: Area, pixels: Area) => {
    setCroppedAreaPixels(pixels);
  }, []);

  useEffect(() => {
    if (file) {
      setDisplayedImage(URL.createObjectURL(file));
    }
  }, [file])

  return (
    <Popup
      open={open}
      closeOnDocumentClick
      modal
      onClose={onClose}
    >
      <div className='modal-dialog modal-dialog-centered z-50'>
        <div className='modal-content shadow-lg w-full bg-white bg-clip-padding rounded-3xl'>
          <div className='modal-body p-8'>
            <p className="text-center text-xl font-medium mb-6">
              Upload image
            </p>
            <div className='relative h-[280px] w-[250px] sm:w-[300px] border mx-auto mb-6'>
              <Cropper
                image={displayedImage}
                crop={crop}
                zoom={zoom}
                aspect={aspectRatio}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>

            <div className="flex flex-col justify-center items-center">
              <div>
                <label
                  className="cursor-pointer select-none rounded-full border border-lue-purple-600 text-lue-purple-600 text-lg font-bold py-2 px-8 whitespace-nowrap bg-white transition-colors hover:bg-lue-purple-600 hover:text-white block"
                >
                  Choose file
                  <input
                    type="file"
                    className="hidden"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </label>
                <button
                  className={
                    classNames(
                      "select-none rounded-full text-lg font-bold py-2 px-8 whitespace-nowrap transition-colors mt-4 w-full",
                      displayedImage ? 'text-white bg-lue-purple-400 hover:bg-lue-purple-500 cursor-pointer' : 'bg-gray-200 text-gray-600 cursor-not-allowed',
                    )
                  }
                  disabled={!displayedImage}
                  onClick={() => {
                    onSave(file, croppedAreaPixels);
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}
