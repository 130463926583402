import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'

type UseLawyerBookings = {
  lawyerBookings: AppState['lawyerBookings']
  lawyerBookingsLoading: AppState['lawyerBookingsLoading']
  lawyerBookingsError: AppState['lawyerBookingsError']
  getLawyerBookings: (
    data: RequestTypes['getLawyerBookings'],
    callbacks?: Callbacks,
  ) => void
}

export default function useLawyerBookings(): UseLawyerBookings {
  const { lawyerBookings, lawyerBookingsLoading, lawyerBookingsError } =
    useSelector((state: AppState) => ({
      lawyerBookings: state.lawyerBookings,
      lawyerBookingsLoading: state.lawyerBookingsLoading,
      lawyerBookingsSaving: state.lawyerBookingsSaving,
      lawyerBookingsError: state.lawyerBookingsError,
    }))
  const dispatch = useDispatch()

  const getLawyerBookings = useCallback(
    (data: RequestTypes['getLawyerBookings'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getLawyerBookings(data, callbacks))
    },
    [dispatch],
  )

  return {
    lawyerBookings,
    lawyerBookingsLoading,
    lawyerBookingsError,
    getLawyerBookings,
  }
}
