import cn from 'classnames'
import { ReactNode } from 'react'

type BaseContainerProps = {
  children: ReactNode
  size: 'sm' | 'md' | 'lg' | 'xl'
  className?: string
}

export const BaseContainer = ({
  children,
  size,
  className,
}: BaseContainerProps) => (
  <div
    className={cn(
      'px-6 md:px-12',
      'mx-auto',
      'w-full',
      {
        sm: 'max-w-[1140px]',
        md: 'max-w-[1500px]',
        lg: 'max-w-[2000px]',
        xl: 'max-w-[2560px]',
      }[size],
      className,
    )}
  >
    {children}
  </div>
)
