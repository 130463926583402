import React from "react";
import dummy from "../../assets/Public Content/dummy.png";
import icon from "../../assets/Public Content/icon.png";
import podcastdummy from '../../assets/Public Content/podcastdummy.png'

const Podcasts = () => {
  return (
    <React.Fragment>
      <div className="bg-[#1B4A8A] py-[60px]">
        <div className="flex md:max-w-[1024px] justify-between items-center mx-auto">
          <div className="flex  flex-col md:flex-row  md:space-x-20">
            <div className="base-1/2">
              <div className="flex flex-col">
                <img src={dummy} className="max-w-full h-auto" />
                <p className="text-[24px] underline font-bold text-white text-center my-[30px]">
                  More Episodes
                  <span className="ml-[10px] inline-block">
                    <img src={icon} />
                  </span>
                </p>
              </div>
            </div>
            <div className="base-1/2">
              <div className="flex flex-col w-[420px] mx-auto justify-center items-center">
                <div className="bg-[#DADADA] rounded-[10px] p-[30px] flex flex-col bg-opacity-[0.2]">
                  <p className="text-[24px] text-[#20BFE7] font-bold">
                    <span></span>Podcasts
                  </p>
                  <p className="text-[36px] text-white font-bold leading-[46px]">
                    EP1: Title of Episode
                  </p>
                </div>
                <div className="flex flex-row space-x-10 py-2">
                  <p className="text-[#20BFE7] text-[16px] leading-[20px]">
                    Series 2
                  </p>
                  <p className="text-[#20BFE7] text-[16px] leading-[20px]">
                    06/05/2022
                  </p>
                  <p className="text-[#20BFE7] text-[16px] leading-[20px]">
                    Technology
                  </p>
                </div>
                <div className="text-[16px] text-white p-[10px] text-justify leading-[26px]">
                  Technology brings the law closer to people. We have been
                  bringing the law closer to more than 20,000 people since 2017.
                  We have partnered with many law firms and organisations,
                  receiving funding from national and international institutions
                  for our innovative approaches. We’ve put law into peoples’
                  pockets.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex md:max-w-[1024px] justify-between items-center mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-8">
            <div className="overflow-hidden tems-center">
              <img src={podcastdummy} className="rounded-[10px]"/>
              <div className="text-white text-[20px] leading-[22px] py-[20px]">Find A Lawyer 2.0 - Walkthrough</div>
              <div className="flex flex-row space-x-1 py-[20px]">
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">Legal Utopia</div>
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">10 days ago</div>
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">Tutorials</div>
              </div>
            </div>
            <div className="overflow-hidden tems-center">
              <img src={podcastdummy} className="rounded-[10px]"/>
              <div className="text-white text-[20px] leading-[22px] py-[20px]">Find A Lawyer 2.0 - Walkthrough</div>
              <div className="flex flex-row space-x-1 py-[20px]">
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">Legal Utopia</div>
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">10 days ago</div>
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">Tutorials</div>
              </div>
            </div>
            <div className="overflow-hidden tems-center">
              <img src={podcastdummy} className="rounded-[10px]"/>
              <div className="text-white text-[20px] leading-[22px] py-[20px]">Find A Lawyer 2.0 - Walkthrough</div>
              <div className="flex flex-row space-x-1 py-[20px]">
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">Legal Utopia</div>
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">10 days ago</div>
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">Tutorials</div>
              </div>
            </div>
          </div>

        </div>
        <div className="flex md:max-w-[1024px] justify-between items-center mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-8">
            <div className="overflow-hidden tems-center">
              <img src={podcastdummy} className="rounded-[10px]"/>
              <div className="text-white text-[20px] leading-[22px] py-[20px]">Find A Lawyer 2.0 - Walkthrough</div>
              <div className="flex flex-row space-x-1 py-[20px]">
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">Legal Utopia</div>
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">10 days ago</div>
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">Tutorials</div>
              </div>
            </div>
            <div className="overflow-hidden tems-center">
              <img src={podcastdummy} className="rounded-[10px]"/>
              <div className="text-white text-[20px] leading-[22px] py-[20px]">Find A Lawyer 2.0 - Walkthrough</div>
              <div className="flex flex-row space-x-1 py-[20px]">
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">Legal Utopia</div>
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">10 days ago</div>
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">Tutorials</div>
              </div>
            </div>
            <div className="overflow-hidden tems-center">
              <img src={podcastdummy} className="rounded-[10px]"/>
              <div className="text-white text-[20px] leading-[22px] py-[20px]">Find A Lawyer 2.0 - Walkthrough</div>
              <div className="flex flex-row space-x-1 py-[20px]">
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">Legal Utopia</div>
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">10 days ago</div>
                <div className="text-[#20BFE7] text-[16px] leading-[20px] uppercase">Tutorials</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </React.Fragment>
  );
};

export default Podcasts;
