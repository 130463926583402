import React from "react";
import {
  FaStar,
  FaRegStar,
  FaCheckCircle,
  FaRegTimesCircle,
} from "react-icons/fa";
import moment from "moment";
import Rating from "react-rating";

import "../../../styles/profileReview.css";
import { ProfileReview, ReviewResponse } from "common/state-type";

interface ReviewItemProps {
  reviewData: ProfileReview;
  profileHolder: string;
}
interface ProfileReviewsProps {
  onClose: () => void;
  reviews: ProfileReview[];
  profileHolder: string;
}
interface ResponseProps {
  responseData: ReviewResponse;
  profileHolder: string;
}

const ReviewItem: React.FC<ReviewItemProps> = ({
  reviewData,
  profileHolder,
}) => {
  return (
    <>
      <div className='flex flex-row color-[#000] pt-3'>
        <div className='stick'> </div>
        <div>
          <div className='flex flex-row items-center '>
            <span className='pl2 text-base'>
              {reviewData.user.first_name + ' ' + reviewData.user.last_name}{' '}
              {moment(reviewData.created_at).format('DD/ MM/YY')}
            </span>
            <FaCheckCircle size={16} className='m-1' color={'#122D5C'} />
            <span className='text-xs'>Verified</span>
          </div>
          <div className='flex flex-row items-center '>
            <Rating
              emptySymbol={
                <FaRegStar className='mt-2' size={24} color='#122D5C' />
              }
              fullSymbol={<FaStar className='mt-2' size={24} color='#122D5C' />}
              initialRating={reviewData.rating}
              readonly
            />
            <span className='pl-2'>{reviewData.rating}</span>
          </div>
          <p className='mt-2 text-sm'>{reviewData.review}</p>
        </div>
      </div>
      {reviewData.lawyer_rating_responses
        ? reviewData.lawyer_rating_responses?.map((response) => (
            <Response
              profileHolder={profileHolder}
              responseData={response}
              key={response.id}
            />
          ))
        : reviewData.barrister_rating_responses
        ? reviewData.barrister_rating_responses?.map((response) => (
            <Response
              profileHolder={profileHolder}
              responseData={response}
              key={response.id}
            />
          ))
        : null}
    </>
  )
}

const Response: React.FC<ResponseProps> = ({ responseData, profileHolder }) => {
  return (
    <div className='response-container'>
      <div className='flex flex-row font-bold md:justify-end pb-2 text-base'>
        <span className='pr-2'> {profileHolder} </span>
        <span>Response</span>
      </div>
      <p className='text-sm'>{responseData.response}</p>
    </div>
  )
}

const ProfileReviews: React.FC<ProfileReviewsProps> = ({
  onClose,
  reviews,
  profileHolder,
}) => {
  return (
    <div className='review-root-container'>
      <h3 className='font-bold text-base'>Legal Utopia Reviews </h3>
      <div className='review-box'>
        {reviews?.map((review) => {
          return (
            <ReviewItem
              key={review.id}
              reviewData={review}
              profileHolder={profileHolder}
            />
          )
        })}
      </div>
      <button className='place-self-center' onClick={onClose}>
        <FaRegTimesCircle size={25} color={'#294B85'} />
      </button>
    </div>
  )
}

export default ProfileReviews;
