import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseLawyerProfiles = {
  lawyerProfiles: AppState['lawyerProfiles']
  lawyerProfilesLoading: AppState['lawyerProfilesLoading']
  lawyerProfilesSaving: AppState['lawyerProfilesSaving']
  lawyerProfilesError: AppState['lawyerProfilesError']
  getLawyerProfiles: (
    data: RequestTypes['getLawyerProfiles'],
    callbacks?: Callbacks,
  ) => void
}

export default function useLawyerProfiles(): UseLawyerProfiles {
  const {
    lawyerProfiles,
    lawyerProfilesLoading,
    lawyerProfilesSaving,
    lawyerProfilesError,
  } = useSelector((state: AppState) => ({
    lawyerProfiles: state.lawyerProfiles,
    lawyerProfilesLoading: state.lawyerProfilesLoading,
    lawyerProfilesSaving: state.lawyerProfilesSaving,
    lawyerProfilesError: state.lawyerProfilesError,
  }))
  const dispatch = useDispatch()

  const getLawyerProfiles = useCallback(
    (data: RequestTypes['getLawyerProfiles'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getLawyerProfiles(data, callbacks))
    },
    [dispatch],
  )

  return {
    lawyerProfiles,
    lawyerProfilesLoading,
    lawyerProfilesSaving,
    lawyerProfilesError,
    getLawyerProfiles,
  }
}
