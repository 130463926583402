import React from 'react'
import { Popup } from 'reactjs-popup'
import { AiFillInfoCircle } from "react-icons/ai";

import { BookingStatus } from "common/state-type";
interface AppointmentStatusItemProps {
  label: string
  borderColor: string
  fillColor?: string
  direction: 'left' | 'right'
}

export const AppointmentStatusItem: React.FC<AppointmentStatusItemProps> = ({
  label,
  borderColor,
  fillColor,
  direction,
}) => {
  const textColor = !!fillColor ? "text-white" : "text-zinc-500";
  if (direction === "left") {
    return (
      <>
        <div className='flex flex-row items-center h-[48px]'>
          <div
            style={{ borderColor: borderColor }}
            className={`rounded-lg border-2 ${textColor} w-52 py-1 text-center text-lg mr-2 ${fillColor}`}
          >
            <span>{label}</span>
          </div>
          <div className='traiangle-left' />
          <div className='bg-[#C4C4C4] w-0.5 h-12 ' />
        </div>
        <div className='h-[48px]' />
      </>
    );
  } else {
    return (
      <>
        <div className='h-[48px]' />
        <div className='flex flex-row items-center h-[48px]'>
          <div className='bg-[#C4C4C4] w-0.5 h-12 ' />
          <div className='traiangle-right' />
          <div
            style={{ borderColor: borderColor }}
            className={`rounded-lg border-2 ${textColor} w-52 py-1 text-center text-lg mr-2 ${fillColor}`}
          >
            <span>{label}</span>
          </div>
        </div>
      </>
    );
  }
};

interface TextInputProps {
  label: string;
  error?: string;
  isMultiline?: boolean;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  value?: string;
  name?: string;
  borderColor?: string;
  disabled?: boolean;
}
export const TextInput: React.FC<TextInputProps> = ({
  label,
  error,
  isMultiline,
  onChange,
  value,
  name,
  disabled,
  borderColor,
  ...rest
}) => {
  const errorBorder = error && "border-red-500";
  return (
    <div>
      <label className='block mb-2 text-base md:text-xl font-medium text-black'>
        {label}
      </label>
      {isMultiline ? (
        <textarea
          disabled={disabled}
          name={name}
          value={value}
          onChange={onChange}
          className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-36 ${errorBorder}`}
          style={!error && { borderColor: borderColor }}
          {...rest}
        />
      ) : (
        <input
          disabled={disabled}
          name={name}
          value={value}
          onChange={onChange}
          className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${errorBorder}`}
          style={!error && { borderColor: borderColor }}
          {...rest}
        />
      )}
      {error && <span className='text-sm text-red-500'>{error}</span>}
    </div>
  )
};

interface ThemedButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  btnClass?: string;
  children: React.ReactNode;
  infoText?: {
    title: string;
    content: string;
  };
  theme?: {
    hex: string;
    class: string;
  };
  disabled?: boolean;
}

export const ThemedButton: React.FC<ThemedButtonProps> = ({
  children,
  onClick,
  btnClass,
  infoText,
  theme,
  disabled,
  ...rest
}) => {
  return (
    <div className='flex'>
      <button
        disabled={disabled}
        type='button'
        onClick={onClick}
        className={`font-medium self-start w-full rounded-lg text-white text-lg py-2 px-8 ${btnClass}`}
        {...rest}
      >
        {children}
      </button>
      {infoText && (
        <Popup
          trigger={
            <div className='self-start -mt-4 -ml-4'>
              <AiFillInfoCircle color={theme.hex} size={16} className='ml-1' />
            </div>
          }
          position={"left bottom"}
          on={["hover", "focus"]}
          arrow={true}
        >
          <div className='max-w-[30vw] rounded-t-lg border-b border-gray-200'>
            <div
              className={`py-2 px-3 rounded-t-lg border-b border-gray-200 ${theme.class}`}
            >
              <h5 className='font-bold text-base text-white text-center'>
                {infoText.title}
              </h5>
            </div>
            <div className='p-3 bg-white' style={{ color: theme.hex }}>
              <p>{infoText.content}</p>
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};

interface StatusTreeProps {
  themeColor: {
    hex: string;
    class: string;
  };
  hasPhotoId: boolean;
  hasProofOfAddress: boolean;
  guestsArePresent: boolean;
  meetingStatus: BookingStatus;
}

export const StatusTree: React.FC<StatusTreeProps> = ({
  themeColor,
  hasPhotoId,
  hasProofOfAddress,
  guestsArePresent,
  meetingStatus,
}) => {
  return (
    <div className='flex flex-row'>
      <div className='flex flex-col'>
        <AppointmentStatusItem
          label='Photo ID'
          borderColor={themeColor.hex}
          direction='left'
          fillColor={hasPhotoId ? themeColor.class : null}
        />
        <AppointmentStatusItem
          label='Proof of Address'
          borderColor={themeColor.hex}
          direction='left'
          fillColor={hasProofOfAddress ? themeColor.class : null}
        />
        <AppointmentStatusItem
          label='Meeting Reschedule'
          borderColor={themeColor.hex}
          direction='left'
          fillColor={
            meetingStatus === BookingStatus.CANCELLED ? themeColor.class : null
          }
        />
        <AppointmentStatusItem
          label='Meeting Cancelled'
          borderColor={themeColor.hex}
          direction='left'
          fillColor={
            meetingStatus === BookingStatus.CANCELLED ? themeColor.class : null
          }
        />
      </div>
      <div className='w-0'></div>
      <div className='flex flex-col -ml-0.5'>
        <AppointmentStatusItem
          label='Guest Added'
          borderColor={themeColor.hex}
          direction='right'
          fillColor={guestsArePresent ? themeColor.class : null}
        />
        <AppointmentStatusItem
          label='Meeting Pending'
          borderColor={themeColor.hex}
          direction='right'
          fillColor={
            meetingStatus === BookingStatus.PENDING_BARRISTER_ACCEPTANCE ||
            meetingStatus === BookingStatus.PENDING_LAWYER_ACCEPTANCE
              ? themeColor.class
              : null
          }
        />
        <AppointmentStatusItem
          label='Meeting Confimed '
          borderColor={themeColor.hex}
          direction='right'
          fillColor={
            meetingStatus === BookingStatus.ACCEPTED ? themeColor.class : null
          }
        />
        <AppointmentStatusItem
          label='Meeting Completed'
          borderColor={themeColor.hex}
          direction='right'
          fillColor={
            meetingStatus === BookingStatus.COMPLETE ? themeColor.class : null
          }
        />
      </div>
    </div>
  );
};

interface FileUploadBtnProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: () => void;
  theme: {
    hex: string;
    class: string;
  };
  value: any;
  label: string;
  fileUploadRef: React.RefObject<HTMLInputElement>;
  infoText?: {
    title: string;
    content: string;
  };
}
export const FileUploadBtn: React.FC<FileUploadBtnProps> = ({
  onChange,
  onClick,
  theme,
  value,
  label,
  fileUploadRef,
  infoText,
  disabled,
  ...rest
}) => {

  return (
    <div>
      <input
        type='file'
        name='file'
        onChange={onChange}
        style={{ display: 'none' }}
        ref={fileUploadRef}
        {...rest}
      />
      <ThemedButton
        onClick={onClick}
        btnClass={`${theme.class}`}
        infoText={infoText}
        theme={theme}
        disabled={disabled}
      >
        {label}
      </ThemedButton>
      <span className='text-sm block pt-1'>
        {value && typeof value === 'string'
          ? Utils.fileNameFromPath(value)
          : value && typeof value === 'object'
          ? value.name
          : 'No file selected'}
      </span>
    </div>
  )
};
