import { cloneElement } from "react";
import cn from 'classnames';

const CONTENT_CLASS_NAMES = {
  p: 'text-lg text-black mb-6',
  ul: 'mb-6 list-disc pl-6',
  ol: 'mb-6 list-decimal pl-6',
  li: 'text-black text-lg mb-1',
  strong: 'font-bold',
  a: 'cursor-pointer text-lue-purple-400',
  h2: 'text-3xl lg:text-4xl mb-4 lg:mb-6 font-bold mt-8',
  h3: 'text-2xl lg:text-3xl mb-3 lg:mb-6 font-bold mt-8',
  h4: 'text-xl lg:text-2xl mb-2 lg:mb-4 font-bold mt-8',
}

export type ApplyHtmlStylesClassNames = Partial<
  Record<keyof typeof CONTENT_CLASS_NAMES, string>
>;

export const applyHtmlStyles = (
  element?: string | JSX.Element | JSX.Element[],
  classNames: ApplyHtmlStylesClassNames = {},
)=> {
  if (!element || typeof element === 'string') {
    return element;
  }

  if (Array.isArray(element)) {
    return element.map((element) => applyHtmlStyles(element, classNames));
  }

  return cloneElement(element, {
    children: applyHtmlStyles(element.props.children),
    className: cn(
      classNames[element.type],
      CONTENT_CLASS_NAMES[element.type],
    ),
  });
}
