import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseVerifyMfa = {
  verifyMfa: AppState['verifyMfa']
  verifyMfaLoading: AppState['verifyMfaLoading']
  verifyMfaSaving: AppState['verifyMfaSaving']
  verifyMfaError: AppState['verifyMfaError']
  createVerifyMfa: (
    data: RequestTypes['createVerifyMfa'],
    callbacks?: Callbacks,
  ) => void
}

export default function useVerifyMfa(): UseVerifyMfa {
  const { verifyMfa, verifyMfaLoading, verifyMfaSaving, verifyMfaError } =
    useSelector((state: AppState) => ({
      verifyMfa: state.verifyMfa,
      verifyMfaLoading: state.verifyMfaLoading,
      verifyMfaSaving: state.verifyMfaSaving,
      verifyMfaError: state.verifyMfaError,
    }))
  const dispatch = useDispatch()

  const createVerifyMfa = useCallback(
    (data: RequestTypes['createVerifyMfa'], callbacks?: Callbacks) => {
      return dispatch(AppActions.createVerifyMfa(data, callbacks))
    },
    [dispatch],
  )

  return {
    verifyMfa,
    verifyMfaLoading,
    verifyMfaSaving,
    verifyMfaError,
    createVerifyMfa,
  }
}
