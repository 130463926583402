import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseBooking = {
  booking: AppState['booking']
  bookingLoading: AppState['bookingLoading']
  bookingSaving: AppState['bookingSaving']
  bookingError: AppState['bookingError']
  getBooking: (data: RequestTypes['getBooking'], callbacks?: Callbacks) => void
  updateBooking: (
    data: RequestTypes['updateBooking'],
    callbacks?: Callbacks,
  ) => void
}

export default function useBooking(): UseBooking {
  const { booking, bookingLoading, bookingError, bookingSaving } = useSelector(
    (state: AppState) => ({
      booking: state.booking,
      bookingLoading: state.bookingLoading,
      bookingSaving: state.bookingSaving,
      bookingError: state.bookingError,
    }),
  )
  const dispatch = useDispatch()
  const getBooking = useCallback(
    (data: RequestTypes['getBooking'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getBooking(data, callbacks))
    },
    [dispatch],
  )
  const updateBooking = useCallback(
    (data: RequestTypes['updateBooking'], callbacks?: Callbacks) => {
      return dispatch(AppActions.updateBooking(data, callbacks))
    },
    [dispatch],
  )
  return {
    booking,
    bookingLoading,
    bookingSaving,
    bookingError,
    getBooking,
    updateBooking,
  }
}
