import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'

type useProfileDetail = {
  profileDetail: AppState['profileDetail']
  profileDetailLoading: AppState['profileDetailLoading']
  profileDetailError: AppState['profileDetailError']
  getProfileDetail: (
    data: RequestTypes['getProfileDetail'],
    callbacks?: Callbacks,
  ) => void
}

export default function useProfileDetail(): useProfileDetail {
  const { profileDetail, profileDetailError, profileDetailLoading } =
    useSelector((state: AppState) => ({
      profileDetail: state.profileDetail,
      profileDetailLoading: state.profileDetailLoading,
      profileDetailError: state.profileDetailError,
    }))
  const dispatch = useDispatch()

  const getProfileDetail = useCallback(
    (data: RequestTypes['getProfileDetail'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getProfileDetail(data, callbacks))
    },
    [dispatch],
  )

  return {
    profileDetail,
    profileDetailError,
    profileDetailLoading,
    getProfileDetail,
  }
}
