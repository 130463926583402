export default function () {
    return (
        <div dangerouslySetInnerHTML={{__html:`        <div class="container content-page mx-4">
            <div>
                <ol type="1" style="margin:0pt; padding-left:0pt">
                    <li className="ListParagraph"
                        style="margin-left:33.01pt; text-align:justify; padding-left:2.99pt; font-family:Arial"
                    ><span>Definitions </span></li>
                </ol>
                <p style="text-align:justify"><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p
                className="ListParagraph"
                style="text-indent:-18pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1'; -aw-list-number-styles:'decimal decimal'; -aw-list-number-values:'1 1'; -aw-list-padding-sml:1.32pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>1.1</span></span><span
                style="width:1.32pt; font:7pt 'Times New Roman'; display:inline-block"
            > </span></span><span style="font-family:Arial">In this Complaints Policy the following expressions have the
                following meanings, unless expressly provided to the contrary herein: </span></p><p
                style="text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p style="text-align:justify"><span
                style="font-family:Arial"
            >“Appeal” means your request will escalate from Level Two to Level Three if you are not satisfied with the
                outcome at Level Two;</span></p><p style="text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p style="text-align:justify"><span style="font-family:Arial">“Appeal Handler” means an
                employee of Legal Utopia Limited working with Senior Management Level who will handle Level Three
                Complains;</span></p><p style="text-align:justify"><span style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p style="text-align:justify"><span style="font-family:Arial">“Business Day” means, any
                day (other than Saturday or Sunday) on which ordinary banks are open for their full range of normal
                business in London, United Kingdom;</span></p><p style="text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p style="text-align:justify"><span style="font-family:Arial">“Complaint” means a
                complaint about goods and services sold by Legal Utopia Limited, about our customer service, or about
                our employees;</span></p><p style="text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p style="text-align:justify"><span style="font-family:Arial">“Complaints Policy” means
                this document; </span></p><p style="text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p style="text-align:justify"><span style="font-family:Arial">“Complaint Procedure” means
                the internal complaints handling procedure of Legal Utopia Limited which is followed when handling a
                Complaint and is available from legalutopia.co.uk for your reference; </span></p><p
                style="text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p style="text-align:justify"><span
                style="font-family:Arial"
            >“Complaint Reference” means a unique code assigned to your Complaint that will be used to track your
                Complaint;</span></p><p style="text-align:justify"><span style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p style="text-align:justify"><span style="font-family:Arial">“Level One” means the first
                stage in our complaints handling procedure under which your Complaint will be handled by a Level One
                Complaint Handler; </span></p><p style="text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p style="text-align:justify"><span style="font-family:Arial">“Level One Complaint
                Handler” means an employee of Legal Utopia Limited working at management who will handle Level One
                Complaints;</span></p><p style="text-align:justify"><span style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p style="text-align:justify"><span style="font-family:Arial">“Level Two” means the second
                stage in our complaints handling procedure under which you may appeal the outcome of a Level One
                Complaint. Your Complaint will be handled by Level Complaint Handler; </span></p><p
                style="text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p style="text-align:justify"><span
                style="font-family:Arial"
            >“Level Two Complaint Handler” means an employee of Legal Utopia Limited working at senior management who
                will handle Level Two Complaints;</span></p><p style="text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p style="text-align:justify"><span style="font-family:Arial">“Level Three” means the
                third and final stage in our complaints handling procedure under which you may appeal the outcome of a
                Level Two Complaint. Your Complaint will be handled by an Appeal Handler. </span></p><p><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p>
                <ol start="2" type="1" style="margin:0pt; padding-left:0pt">
                    <li className="ListParagraph" style="margin-left:33.01pt; padding-left:2.99pt; font-family:Arial">
                        <span>Purpose of the Complaints Policy </span></li>
                </ol>
                <p><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                          style="text-indent:-18pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1'; -aw-list-number-styles:'decimal decimal'; -aw-list-number-values:'2 1'; -aw-list-padding-sml:1.32pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>2.1</span></span><span
                style="width:1.32pt; font:7pt 'Times New Roman'; display:inline-block"
            > </span></span><span style="font-family:Arial">Legal Utopia Limited welcomes and encourages feedback of all
                kinds from our customers. If you have a Complaint about our goods and/or services, our customer service,
                or about our employees, not only do we want to resolve it to your satisfaction but we also want to learn
                from it in order to improve our business and customer experience in the future. </span></p><p
                className="ListParagraph" style="text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                    style="text-indent:-18pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1'; -aw-list-number-styles:'decimal decimal'; -aw-list-number-values:'2 2'; -aw-list-padding-sml:1.32pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>2.2</span></span><span
                style="width:1.32pt; font:7pt 'Times New Roman'; display:inline-block"
            > </span></span><span style="font-family:Arial">It is our policy to resolve Complaints quickly and fairly,
                where possible without recourse to formal investigations or external bodies. In particular, the aims of
                this Complaints Policy are: </span></p><p style="text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p style="margin-left:36pt; text-align:justify"><span style="font-family:Arial">2.2.1 To
                provide a clear and fair procedure for any customer who wish to make a Complaint about Legal Utopia
                Limited, our goods and/or services, our customer service, or about our employees;</span></p><p
                style="margin-left:36pt; text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p
                style="margin-left:36pt; text-align:justify"
            ><span style="font-family:Arial">2.2.2 To ensure that everyone working for or with Legal Utopia Limited
                knows how to handle Complaints made by our customers;</span></p><p
                style="margin-left:36pt; text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p
                style="margin-left:36pt; text-align:justify"
            ><span style="font-family:Arial">2.2.3 To ensure that all Complaints are handled equally and in a fair and
                timely fashion; </span></p><p style="margin-left:36pt; text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p style="margin-left:36pt; text-align:justify"><span style="font-family:Arial">2.2.4 To
                ensure that important information is gathered from Complaints and used in the future to avoid such a
                situation arising again. </span></p><p><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span>
            </p>
                <ol start="3" type="1" style="margin:0pt; padding-left:0pt">
                    <li className="ListParagraph" style="margin-left:33.01pt; padding-left:2.99pt; font-family:Arial">
                        <span>What this Complaints Policy Covers</span></li>
                </ol>
                <p style="text-align:justify"><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p
                className="ListParagraph"
                style="text-indent:-18pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1'; -aw-list-number-styles:'decimal decimal'; -aw-list-number-values:'3 1'; -aw-list-padding-sml:1.32pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>3.1</span></span><span
                style="width:1.32pt; font:7pt 'Times New Roman'; display:inline-block"
            > </span></span><span style="font-family:Arial">This Complaints Policy applies to the sale of goods and/or
                the provision of services by Legal Utopia Limited, to our customer service and to our employees. </span>
            </p><p className="ListParagraph" style="text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p className="ListParagraph"
                                 style="text-indent:-18pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1'; -aw-list-number-styles:'decimal decimal'; -aw-list-number-values:'3 2'; -aw-list-padding-sml:1.32pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>3.2</span></span><span
                style="width:1.32pt; font:7pt 'Times New Roman'; display:inline-block"
            > </span></span><span style="font-family:Arial"> For the purposes of this Complaints Policy, any reference
                to Legal Utopia Limited also includes our employees. </span></p><p className="ListParagraph"
                                                                                   style="text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                    style="text-indent:-18pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1'; -aw-list-number-styles:'decimal decimal'; -aw-list-number-values:'3 3'; -aw-list-padding-sml:1.32pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>3.3</span></span><span
                style="width:1.32pt; font:7pt 'Times New Roman'; display:inline-block"
            > </span></span><span style="font-family:Arial">Complaints may relate to any of our activities and may
                include (but not be limited to): </span></p><p className="ListParagraph" style="text-align:justify">
                <span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                       style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'3 3 1'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>3.3.1</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">The quality of customer
                service you have received from Legal Utopia Limited;</span></p><p className="ListParagraph"
                                                                                  style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'3 3 2'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>3.3.2</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">The behaviour and/or
                professional competence of our employees;</span></p><p className="ListParagraph"
                                                                       style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'3 3 3'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>3.3.3</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">Delays, defects or other
                problems associated with the sale of goods by Legal Utopia; </span></p><p className="ListParagraph"
                                                                                          style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'3 3 4'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>3.3.4</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">Delays, defects, poor
                workmanship or other problems associated with the provision of services by Legal Utopia Limited. </span>
            </p><p className="ListParagraph" style="text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p className="ListParagraph"
                                 style="text-indent:-18pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1'; -aw-list-number-styles:'decimal decimal'; -aw-list-number-values:'3 4'; -aw-list-padding-sml:1.32pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>3.4</span></span><span
                style="width:1.32pt; font:7pt 'Times New Roman'; display:inline-block"
            > </span></span><span style="font-family:Arial">The following are not considered to be Complaints and
                should, therefore, be directed to the appropriate person: </span></p><p className="ListParagraph"
                                                                                        style="text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                    style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'3 4 1'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>3.4.1</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">General questions about our
                goods and/or services;</span></p><p className="ListParagraph"
                                                    style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'3 4 2'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>3.4.2</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">Return of damaged, faulty,
                incorrect or unwanted goods or services for exchange or refund, in accordance with the relevant Terms of
                Sale, where there is no further complaint;</span></p><p className="ListParagraph"
                                                                        style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'3 4 3'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>3.4.3</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">Matters concerning contractual
                or other legal disputes;</span></p><p className="ListParagraph"
                                                      style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'3 4 4'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>3.4.4</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">Formal requests for the
                disclosure of information, for example, under the Data Protection Act. </span></p><p
                style="text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p>
                <ol start="4" type="1" style="margin:0pt; padding-left:0pt">
                    <li className="ListParagraph"
                        style="margin-left:33.01pt; text-align:justify; padding-left:2.99pt; font-family:Arial"
                    ><span>Making a Complaint </span>
                        <ol type="1" className="awlist1" style="margin-right:0pt; margin-left:0pt; padding-left:0pt">
                            <li className="ListParagraph"
                                style="margin-left:0pt; text-indent:-18pt; -aw-list-padding-sml:1.32pt"
                            ><span
                                style="width:1.32pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore"
                            > </span><span>All Complaints, whether they concern our goods and/or services, our customer
                                service, or our employees should be made in one of the following ways: </span></li>
                        </ol>
                    </li>
                </ol>
                <p style="text-align:justify"><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p
                className="ListParagraph"
                style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'4 1 1'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>4.1.1</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">In writing, addressed to FAO
                Customer Service, 3</span><span style="font-family:Arial; font-size:8pt; vertical-align:super">rd</span><span
                style="font-family:Arial"
            > Floor, 86-90 Paul Street, London, EC2A 4NE; and </span></p><p className="ListParagraph"
                                                                            style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'4 1 2'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>4.1.2</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">By email, addressed to
                Customer Service at </span><a href="mailto:customerservice@legalutopia.co.uk"
                                              style="text-decoration:none"
            ><span className="Hyperlink" style="font-family:Arial">customerservice@legalutopia.co.uk</span></a></p><p
                className="ListParagraph" style="text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                    style="text-indent:-18pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1'; -aw-list-number-styles:'decimal decimal'; -aw-list-number-values:'4 2'; -aw-list-padding-sml:1.32pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>4.2</span></span><span
                style="width:1.32pt; font:7pt 'Times New Roman'; display:inline-block"
            > </span></span><span style="font-family:Arial">When making a Complaint, you will be required to provide the
                following information in as much detail as is reasonably possible: </span></p><p
                style="text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                    style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'4 2 1'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>4.2.1</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">Your name, address, telephone
                number and email address (We will contact you using your preferred contact method as your Complaint is
                handled);</span></p><p className="ListParagraph"
                                       style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'4 2 2'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>4.2.2</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">If you are making a Complaint
                on behalf of someone else, that person’s name and contact details as well as your own;</span></p><p
                className="ListParagraph"
                style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'4 2 3'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>4.2.3</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">If you are making a Complaint
                about a particular transaction, the reference, transaction or invoice number provided to you;</span></p>
                <p className="ListParagraph"
                   style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'4 2 4'; -aw-list-padding-sml:9.31pt"
                ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>4.2.4</span></span><span
                    style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">If you are making a
                    Complaint about a particular employee of ours, the name and, where appropriate, position of that
                    employee;</span></p><p className="ListParagraph"
                                           style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'4 2 5'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>4.2.5</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">Further details of your
                Complaint including, as appropriate, all times, dates, events, and people involve;</span></p><p
                className="ListParagraph"
                style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'4 2 6'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>4.2.6</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">Details of any documents or
                other evidence you wish to rely on in support of your Complaint;</span></p><p className="ListParagraph"
                                                                                              style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'4 2 7'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>4.2.7</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">Details of what you would like
                Legal Utopia Limited to do to resolve your Complaint and to put things right. </span></p><p><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p style="text-align:justify"><span style="font-family:Arial">(Please note that whilst we
                will make every reasonable effort to accommodate such requests, we are not bound to take any action
                beyond that which we may be contractually or otherwise legally obligated to take.) </span></p><p><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p>
                <ol start="5" type="1" style="margin:0pt; padding-left:0pt">
                    <li className="ListParagraph" style="margin-left:33.01pt; padding-left:2.99pt; font-family:Arial">
                        <span>How We Handle Your Complaint </span></li>
                </ol>
                <p><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                          style="text-indent:-18pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1'; -aw-list-number-styles:'decimal decimal'; -aw-list-number-values:'5 1'; -aw-list-padding-sml:1.32pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.1</span></span><span
                style="width:1.32pt; font:7pt 'Times New Roman'; display:inline-block"
            > </span></span><span style="font-family:Arial">Legal Utopia Limited operates this three-stage complaints
                handling procedure. Following our Complaints Procedure, our aim is to always resolve Complaints to your
                satisfaction at Level One without further recourse to Level Two or Level Three. If you are not satisfied
                at the end of Level One, you may escalate your Complaint to Level Two. If you are not satisfied at the
                end of Level Two your Complaint may be escalated to Level Three at which point it will be handled by a
                director of Legal Utopia Limited. </span></p><p className="ListParagraph"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p className="ListParagraph"
                                 style="text-indent:-18pt; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1'; -aw-list-number-styles:'decimal decimal'; -aw-list-number-values:'5 2'; -aw-list-padding-sml:1.32pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.2</span></span><span
                style="width:1.32pt; font:7pt 'Times New Roman'; display:inline-block"
            > </span></span><span style="font-family:Arial">Level One: </span></p><p className="ListParagraph"
                                                                                     style="text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                    style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 2 1'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.2.1</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">Upon receipt of your
                Complaint, customer service will log the Complaint in our Complaints Log and will acknowledge receipt of
                it in writing within 48hrs, giving you a Complaint Reference. Where the Complaint is submitted in
                writing to the abovementioned business address, the logging, acknowledge and provision of a complaints
                reference may take up to 7 clear business days. </span></p><p className="ListParagraph"
                                                                              style="margin-left:54pt; text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                    style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 2 2'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.2.2</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">When we acknowledge receipt of
                your Complaint, we will also provide details of your Level One Complaint Handler. This will be from the
                customer service team to whom your original Complaint was directed, or your Complaint may be referred to
                another appropriate member of our team. </span></p><p style="text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p className="ListParagraph"
                                 style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 2 3'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.2.3</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">If your Complaint relates to a
                specific employee, that person will be informed or your Complaint and given a fair and reasonable
                opportunity to respond. Any communication between you and the employee in question should take place
                only via the Level One Complaint Handler and we respectfully ask that you do not contact the employee in
                question directly concerning the Complaint while we are working to resolve it. </span></p><p
                style="text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                    style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 2 4'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.2.4</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">If we require any further
                information or evidence from you, the Level One Complaint Handler will contact you as quickly as is
                reasonably possible to ask for it. We ask that you use reasonable efforts to supply any such information
                or evidence as quickly as possible in order to avoid delays to the complaints handling process. If you
                are for any reason unable to provide such information or evidence, we will use all reasonable efforts to
                proceed without it, however, please be aware that we will not ask for further information or evidence
                unless we consider it important to the successful resolution of your Complaint. </span></p><p
                style="text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                    style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 2 5'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.2.5</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">We aim to resolve Level One
                Complaints within 5 business days, however, in some cases, particularly if your Complaint is of a
                complex nature, this may not be possible. If this is not possible for any reason you will be informed of
                the delay, the likely length of the delay and the reason for it. </span></p><p className="ListParagraph"
                                                                                               style="text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                    style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 2 6'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.2.6</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">At the conclusion of the Level
                One Complaints Procedure, regardless of the outcome, we will provide you with full details of our
                investigation, our conclusion from that investigation, and any action taken as a result. You will also
                be reminded of your right to appeal our decision and escalate the complaint to Level Two. </span></p><p
                className="ListParagraph"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                    style="text-indent:-18pt; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1'; -aw-list-number-styles:'decimal decimal'; -aw-list-number-values:'5 3'; -aw-list-padding-sml:1.32pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.3</span></span><span
                style="width:1.32pt; font:7pt 'Times New Roman'; display:inline-block"
            > </span></span><span style="font-family:Arial">Level Two </span></p><p><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p className="ListParagraph"
                                 style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 3 1'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.3.1</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">If you are not satisfied with
                the resolution of your complaint at Level One, you may request that the Complaint be escalated to Level
                Two within 10 business days, where your Complaint will be handled by management level members of our
                team. </span></p><p className="ListParagraph" style="margin-left:54pt; text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p className="ListParagraph"
                                 style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 3 2'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.3.2</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">Escalation requests, quoting
                your original Complaint Reference, should be directed to your Level One Complaint Handler who will
                forward the request to an appropriate Level Two Complaint Handler. Receipt of escalation requests will
                be acknowledged in writing within 48hrs. When acknowledge receipt of your escalation request, we will
                also provide details of your Level Two Complaint Handler. </span></p><p style="text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p className="ListParagraph"
                                 style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 3 3'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.3.3</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">If your Complaint relates to a
                specific employee, that person will be informed of your Level Two Complaint and given a further
                opportunity to respond. Any communication between you and the employee in question should take place
                only via the Level Two Complaint Handler and we respectfully ask that you do not contact the employee in
                question directly concerning the Complaint while we are working to resolve it. </span></p><p><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p className="ListParagraph"
                                 style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 3 4'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.3.4</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">If we require any further
                information or evidence from you, the Level Two Complaint Hander will contact you as quickly as is
                reasonably possible to ask for it. We ask that you use reasonable efforts to supply any such information
                or evidence to us quickly in order to avoid delaying the complaints handling process. If you are for any
                reason unable to provide such information or evidence, we will use all reasonable efforts to proceed
                without it, however, please be aware that we will not ask for further information or evidence unless we
                consider it important to the successful resolution of your Complaint. </span></p><p
                style="text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                    style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 3 5'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.3.5</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">We aim to resolve Level Two
                Complaints within 48hrs, however in some cases, particularly if your Complaint is of a complex nature,
                this may not be possible. If this is not possible for any reason you will be informed of the delay, the
                likely length of the delay and the reasons for it.</span></p><p style="text-align:justify"><span
                style="font-family:Arial; -aw-import:spaces"
            >&nbsp;</span></p><p className="ListParagraph"
                                 style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 3 6'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.3.6</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">At the conclusion of the Level
                Two complaints procedure, regardless of the outcome, we will provide you with full details of our
                investigation, our conclusions from that investigation, and any action taken as a result. You will also
                be reminded of your right to appeal our decision and escalate the Complaint to Level Three. </span></p>
                <p className="ListParagraph"><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p>
                <span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                       style="text-indent:-18pt; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1'; -aw-list-number-styles:'decimal decimal'; -aw-list-number-values:'5 4'; -aw-list-padding-sml:1.32pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.4</span></span><span
                style="width:1.32pt; font:7pt 'Times New Roman'; display:inline-block"
            > </span></span><span style="font-family:Arial">Level Three </span></p><p style="text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p className="ListParagraph"
                                 style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 4 1'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.4.1</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">If you are not satisfied with
                the resolution of your Complaint at Level Two, you may appeal the decision within 48hrs, and have the
                Complaint escalated to Level Three where it will be handled by one of the directors. </span></p><p
                className="ListParagraph" style="text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                    style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 4 2'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.4.2</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">Appeals, quoting your original
                Complaint Reference, should be directed to your Level Two Complaint Handler who will forward the request
                to an appropriate Appeal Handler. Receipt of Appeals will be acknowledged in writing within 24hrs. When
                we acknowledge receipt of your Appeal, we will also provide details of your Appeal Handler. </span></p>
                <p style="text-align:justify"><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p
                className="ListParagraph"
                style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 4 3'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.4.3</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">If your Complaint relates to a
                specific employee, that person will be informed of your Appeal and given a further opportunity to
                respond. Any communication between you and the employee in question should take place only via the
                Appeal Handler and we respectfully ask that you do not contact the employee in question directly
                concerning the Complaint while we are working to resolve it. </span></p><p style="text-align:justify">
                <span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                       style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 4 4'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.4.4</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">If we require any further
                information or evidence from you, the Appeal Handler will contact you as quickly as is reasonably
                possible to ask for it. We ask that you use reasonable efforts to supply any such information or
                evidence to us quickly in order to avoid delaying the complaints handling process. If you are for any
                reason unable to provide such information or evidence, we will use all reasonable efforts to proceed
                without it, however, please be aware that we will not ask for further information or evidence unless we
                consider it important to the successful resolution of your Complaint. </span></p><p><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p className="ListParagraph"
                                 style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 4 5'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.4.5</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">We aim to resolve Level Three
                Complaints within 24hrs, however in some cases, particularly if your Complaint is of a complex nature,
                this may not be possible. If this is not possible for any reason you will be informed of the delay, the
                likely length of the delay and the reason for it. </span></p><p style="text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p className="ListParagraph"
                                 style="margin-left:54pt; text-indent:-36pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2'; -aw-list-number-styles:'decimal decimal decimal'; -aw-list-number-values:'5 4 6'; -aw-list-padding-sml:9.31pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>5.4.6</span></span><span
                style="width:9.31pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family:Arial">At the conclusion of the Level
                Three procedure, regardless of the outcome, we will provide you with full details of our investigation,
                our conclusions from that investigation, and any action taken as a result. Our decision at this stage is
                final. </span></p><p><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p>
                <ol start="6" type="1" style="margin:0pt; padding-left:0pt">
                    <li className="ListParagraph" style="margin-left:33.01pt; padding-left:2.99pt; font-family:Arial">
                        <span>Confidentiality and Data Protection </span></li>
                </ol>
                <p style="text-align:justify"><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p
                className="ListParagraph"
                style="text-indent:-18pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1'; -aw-list-number-styles:'decimal decimal'; -aw-list-number-values:'6 1'; -aw-list-padding-sml:1.32pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>6.1</span></span><span
                style="width:1.32pt; font:7pt 'Times New Roman'; display:inline-block"
            > </span></span><span style="font-family:Arial">All Complaints and information relating thereto are treated
                with the utmost confidence. Such information will only be shared with those employees of Legal Utopia
                Limited who need to know in order to handle your Complaint. </span></p><p className="ListParagraph"
                                                                                          style="text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                    style="text-indent:-18pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1'; -aw-list-number-styles:'decimal decimal'; -aw-list-number-values:'6 2'; -aw-list-padding-sml:1.32pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>6.2</span></span><span
                style="width:1.32pt; font:7pt 'Times New Roman'; display:inline-block"
            > </span></span><span style="font-family:Arial">We ask that all Complaints whilst being handled or
                investigated under this Complaints Policy remain confidential until its conclusion. Any and all
                correspondence remains confidential for an indefinite period. </span></p><p className="ListParagraph"
                                                                                            style="text-align:justify"
            ><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p className="ListParagraph"
                                                                                    style="text-indent:-18pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1'; -aw-list-number-styles:'decimal decimal'; -aw-list-number-values:'6 3'; -aw-list-padding-sml:1.32pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>6.3</span></span><span
                style="width:1.32pt; font:7pt 'Times New Roman'; display:inline-block"
            > </span></span><span style="font-family:Arial">We may ask for your permission to use details of your
                Complaint (with your personal details removed) for internal training and quality improvement purposes.
                If you have given such permission, you may revoke it at any time by contact Customer Service, whose
                details are provided above. </span></p><p className="ListParagraph" style="text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p className="ListParagraph"
                                 style="text-indent:-18pt; text-align:justify; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1'; -aw-list-number-styles:'decimal decimal'; -aw-list-number-values:'6 4'; -aw-list-padding-sml:1.32pt"
            ><span style="-aw-import:ignore"><span style="font-family:Arial"><span>6.4</span></span><span
                style="width:1.32pt; font:7pt 'Times New Roman'; display:inline-block"
            > </span></span><span style="font-family:Arial">All personal information that we may collect (including, but
                not limited to, your name and address) will be collected, used and held in accordance with the
                provisions of the Data Protection Act 1998 and your rights under that Act. This Complaints Policy is
                subject to our Privacy Policy. </span></p><p><span style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p>
                <ol start="7" type="1" style="margin:0pt; padding-left:0pt">
                    <li className="ListParagraph" style="margin-left:33.01pt; padding-left:2.99pt; font-family:Arial">
                        <span>Questions and Further Information</span></li>
                </ol>
                <p><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p style="text-align:justify">
                <span style="font-family:Arial">If you have any questions or require further information about any
                    aspect of this Complaints Policy or about our Complaints Procedure, please contact Customer Service
                    by email using </span><a href="mailto:customerservice@legalutopia.co.uk"
                                             style="text-decoration:none"
            ><span className="Hyperlink" style="font-family:Arial">customerservice@legalutopia.co.uk</span></a><span
                style="font-family:Arial"
            >. </span></p><p><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p>
                <ol start="8" type="1" style="margin:0pt; padding-left:0pt">
                    <li className="ListParagraph" style="margin-left:33.01pt; padding-left:2.99pt; font-family:Arial">
                        <span>Policy Responsibility and Review </span></li>
                </ol>
                <p><span style="font-family:Arial; -aw-import:ignore">&nbsp;</span></p><p style="text-align:justify">
                <span style="font-family:Arial">8.1 Overall responsibility for this Complaints Policy within Legal
                    Utopia Limited and implementation thereof lies with the Chief Executive. </span><span
                style="font-family:Arial; -aw-import:spaces"
            >&nbsp;</span></p><p style="text-align:justify"><span style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p style="text-align:justify"><span style="font-family:Arial">8.2 This Complaints Policy
                is regularly reviewed and updated as required. </span></p><p style="text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p style="text-align:justify"><span style="font-family:Arial">8.3 This Complaints Policy
                was adopted on 3</span><span style="font-family:Arial; font-size:8pt; vertical-align:super"
            >rd</span><span style="font-family:Arial"> June 2019. </span></p><p style="text-align:justify"><span
                style="font-family:Arial; -aw-import:ignore"
            >&nbsp;</span></p><p style="text-align:justify"><span style="font-family:Arial">8.4 This Complaints Policy
                was last reviewed on 26</span><span style="font-family:Arial; font-size:8pt; vertical-align:super"
            >th</span><span style="font-family:Arial"> July 2022.</span></p></div>
        </div>
`}}/>
    )
}
