import { useCallback } from "react";
import { useParams } from "react-router-dom";
import luLogo from '../assets/lu-logo.svg';
import { ButtonSecondary } from "components/base/forms/Button";
import { PrimaryButton } from "./PrimaryButton";
import { useLegalDocumentDownload } from "common/hooks/useLegalDocumentDownload";
import vectorBg from '../assets/vector-bg.png';

const LegalDocumentDownload = () => {
  const { id } = useParams();

  const {
    status,
    onDownloadClick,
    message,
    description,
  } = useLegalDocumentDownload(id, 'stripe_product_id', (fileUrl) => {
    window.open(fileUrl, "_blank");
  });

  const onBackClick = useCallback(() => {
    const {
      pathname,
      origin,
    } = new URL(window.location.href);

    window.location.href = `${origin}${pathname.replace("/download", "")}`
  }, []);

  return (
    (
      <main
        className='flex flex-col items-center justify-center bg-cover min-h-screen'
        style={{ backgroundImage: `url('${vectorBg}')` }}
      >
        <div className='flex flex-col items-center sm:justify-center w-full h-screen sm:h-auto sm:max-w-[500px] xl:max-w-[700px] px-6 lg:px-12 bg-white sm:rounded-3xl'>
          <div className="flex flex-col items-center mt-20 sm:my-12">
            <a href="/">
              <img src={luLogo} className="h-12 w-12 mb-8" />
            </a>
            <p className="text-2xl xl:text-3xl text-center font-bold mb-10">
              {message}
            </p>
            {description && (
              <p className="mb-10">
                {description}
              </p>
            )}
            <div className="space-y-6 flex flex-col items-center">
              <PrimaryButton
                disabled={status !== 'ready'}
                onClick={onDownloadClick}
              >
                Download file
              </PrimaryButton>
              <ButtonSecondary
                onClick={onBackClick}
                className="cursor-pointer text-lue-purple-400 text-lg"
              >
                Go back
              </ButtonSecondary>
            </div>
          </div>
        </div>
      </main>
    )
  );
}

export default LegalDocumentDownload;
