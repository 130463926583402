import React from "react";

import { Avatar } from 'common/components/Avatar';
import { LawfirmMember } from "common/state-type";

interface LawfirmMembersProps {
  members: LawfirmMember[];
}

export const LawfirmMembers: React.FC<LawfirmMembersProps> = ({ members }) => {
  return (
    <div className='flex flex-1 flex-col items-center mb-4 md:mb-0'>
      <span className='text-lg font-bold'>Our Team</span>
      <div className='flex flex-row flex-1 flex-wrap space-x-6 justify-center'>
        {members.map((member) => {
          return (
            <div className='flex flex-col items-center' key={member.id}>
              <Avatar
                className='h-20 w-20 bg-white border-[#BABABA] border-2 text-xl'
                imgSource={member.avatar}
                fullName={member.name}
              />
              <span className='text-xs md:text-sm text-black'>
                {Utils.capitalizeFirstLetter(member.job_title)}
              </span>
              <span className='text-base'>
                {Utils.capitalizeFirstLetter(member.name)}
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
};
