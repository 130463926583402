import { forwardRef } from "react";

interface DropDownProps {
  className?: string;
  display: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}

const DropDown = forwardRef<HTMLDivElement, DropDownProps>((
  {
    className,
    display,
    children,
    onClick,
  }: DropDownProps,
  ref,
) => {
  if (!display) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={className}
      onClick={onClick}
    >
      <ul>{children}</ul>
    </div>
  );
});

export default DropDown;
