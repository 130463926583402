import _ from 'lodash'
import moment from 'moment'
import { AnyAction } from 'redux'
import fetch from 'isomorphic-unfetch'
import { put, all, takeLatest, takeEvery, select } from 'redux-saga/effects'

import Project from './project'
import _data from './utils/_data'
import { Actions, Callbacks } from './app-actions'
import {
  handleResponse,
  updateAction,
  patchAction,
  // eslint-disable-next-line no-unused-vars
  deleteAction,
  // eslint-disable-next-line no-unused-vars
  errorHandler,
  // eslint-disable-next-line no-unused-vars
  getAction,
  postAction,
} from './utils/saga-helpers'
import {
  AppState,
  CreditItem,
  RequestTypes,
  SubscriptionDTO,
  BarristerProfile,
  GovernmentService,
} from './state-type'
import store from './store'

type IAction = Callbacks &
  AnyAction & {
    data?: any
  }

const createFormData = (data: Record<string, string | Blob>) => {
  const formData = new FormData()

  Object.entries(data).map(([key, value]) => {
    formData.append(key, value)
  })

  return formData
}

// Called when the application starts up, if using SSR this is done in the server
export function* startup(action: IAction) {
  try {
    const { ...rest } = action.data || {}
    const token = action.data?.token

    if (token) {
      _data.setToken(token)
      // set the user
      yield onToken(action, {})
    }

    const isOnline = typeof navigator === 'undefined' ? true : navigator.onLine
    const data = { ready: true, isOnline, ...rest }
    yield put({
      type: Actions.STARTUP_LOADED,
      data: { ready: true, isOnline, ...rest },
    })
    if (action.onSuccess) {
      action.onSuccess(data)
    }
  } catch (e) {
    yield put(API.ajaxHandler(Actions.STARTUP_ERROR, e))
    if (action?.onError) {
      action.onError({ error: e })
    }
  }
}

export function* onToken(action, result) {
  //  If you need to refresh a user profile, do it here
  yield handleResponse(action, 'LOGIN', result, false)
}

export function* handleLogin(action) {
  const session = yield API.auth.Cognito.getSession()
  const token = session.getAccessToken().getJwtToken()
  _data.setToken(token)
  const res = yield getProfile(action)
  API.identify && API.identify(res.id)
}

export function* login(action) {
  try {
    const data: RequestTypes['login'] = action.data
    const user = yield API.auth.Cognito.login(data.username, data.password)
    yield handleResponse(action, 'LOGIN', user, false)
    yield handleLogin(action)
    action.onSuccess && action.onSuccess(user)
  } catch (e) {
    yield errorHandler(action, 'LOGIN', false, e)
  }
}

export function* oauth(action) {
  try {
    const data: RequestTypes['oauth'] = action.data
    const token = yield data.authorize()
    _data.setToken(token)
    if (data.account_type || data.used_invite_token) {
      const patchData: any = {}
      if (data.account_type) {
        patchData.account_type = data.account_type
      }
      if (data.used_invite_token) {
        patchData.used_invite_token = data.used_invite_token
      }
      yield _data.patch(`${Project.liveApi}users/me/`, patchData)
    }

    const res = yield getProfile(action)
    API.identify && API.identify(res.id)
  } catch (e) {
    yield errorHandler(action, 'LOGIN', false, e)
  }
}

export function* resetPassword(action) {
  try {
    const data: RequestTypes['resetPassword'] = action.data
    yield API.auth.Cognito.resetPassword(data.email)
    action.onSuccess()
    yield put({ type: Actions.RESET_PASSWORD_LOADED })
  } catch (e) {
    yield errorHandler(action, 'RESET_PASSWORD', false, e)
  }
}

export function* changePassword(action) {
  try {
    const data: RequestTypes['changePassword'] = action.data
    yield API.auth.Cognito.changePassword(
      data.username,
      data.code,
      data.password,
    )
    action.onSuccess()
    yield put({ type: Actions.CHANGE_PASSWORD_LOADED })
  } catch (e) {
    yield errorHandler(action, 'CHANGE_PASSWORD', false, e)
  }
}

export function* register(action) {
  try {
    const data: RequestTypes['register'] = action.data
    yield API.auth.Cognito.signUp(data.email, data.password)
    yield action.onSuccess()
    yield put({ type: Actions.REGISTER_LOADED })
  } catch (e) {
    yield errorHandler(action, 'REGISTER', false, e)
  }
}

export function* logout(action) {
  const data: RequestTypes['logout'] = action.data
  API.logout()
  yield API.setStoredToken(null)
  yield API.storage.removeItem('user')
  yield API.storage.removeItem('profile')
  yield API.setStoredRefreshToken(null)
  _data.setToken(null)
  _data.setRefreshToken(null)
  yield API.auth.Cognito.logout()
  yield put({ type: Actions.CLEAR_USER })
  yield API.push?.unsubscribe(data.id)
  action.onSuccess && action.onSuccess()
}

export function* confirmEmail(action) {
  yield postAction(action, `${Project.api}user/verify/email/`, 'CONFIRM_EMAIL')
}

export function* updateUser(action) {
  yield updateAction(
    action,
    `${Project.api}user/${action.data.id}/`,
    'UPDATE_USER',
  )
}

export function* getProfile(action) {
  return yield getAction(action, `${Project.liveApi}users/me/`, 'GET_PROFILE')
}

export function* updateProfile(action) {
  yield patchAction(action, `${Project.liveApi}users/me/`, 'UPDATE_PROFILE')
}

export function* getFeedbackSummary(action) {
  yield getAction(
    action,
    `${Project.liveApi}feedback/summary-statistics/`,
    'GET_FEEDBACK_SUMMARY',
    false,
    (data: AppState['feedbackSummary']) => {
      data.average_score = Utils.roundToStep(data.average_score, 0.5)
      return data
    },
  )
}

export function* getFeedback(action) {
  const data: RequestTypes['feedbackGet'] = action.data
  const url = action.data.page || `${Project.liveApi}feedback/`
  yield getAction(
    {
      ...action,
      data: {},
    },
    url,
    'GET_FEEDBACK',
    false,
    (res: AppState['feedback']) => {
      // return res?.reverse() todo: this does not look correct, res is a paginated list
      return res
    },
  )
}

export function* createFeedback(action) {
  yield postAction(action, `${Project.liveApi}feedback/`, 'CREATE_FEEDBACK')
}

export function* createMembershipRedemption(action) {
  yield postAction(
    action,
    `${Project.liveApi}membership-codes/redeem/`,
    'REDEEM',
  )
}

export function* getFaqs(action) {
  yield getAction(action, `${Project.liveApi}help/faqs/`, 'GET_FAQS')
}

export function* getLegalChecker(action) {
  const prefix = 'GET_LEGAL_CHECKER'
  try {
    const [roles, remedies, statuses] = yield all([
      _data.get(`${Project.liveApi}legal-checker/role-descriptions/`),
      _data.get(`${Project.liveApi}legal-checker/remedy-descriptions/`),
      _data.get(`${Project.liveApi}legal-checker/status-descriptions/`),
    ])
    yield handleResponse(action, prefix, { roles, remedies, statuses })
  } catch (e) {
    yield errorHandler(action, prefix, false, e)
  }
}

export function* getLegalCheckerDomains(action) {
  const prefix = 'GET_LEGAL_CHECKER_DOMAINS'
  try {
    const domains = yield _data.get(
      `${Project.liveApi}legal-checker/merged-domain-subdomain/?${Utils.toParam(
        action.data || {},
      )}`,
    )
    yield handleResponse(action, prefix, domains)
  } catch (e) {
    yield errorHandler(action, prefix, false, e)
  }
}

export function* getLegalCheckerSubDomains(action) {
  const prefix = 'GET_LEGAL_CHECKER_SUB_DOMAINS'
  const domainId = action.data.url
  try {
    const domain = yield _data.get(
      `${Project.liveApi}legal-checker/sub-domains/?domain=${domainId}`,
    )

    yield handleResponse(action, prefix, domain)
  } catch (e) {
    yield errorHandler(action, prefix, false, e)
  }
}

export function* getLegalCheckerQuestions(action) {
  yield getAction(
    action,
    `${Project.liveApi}legal-checker/questions/?sub_domain=${action.data.subDomainId}`,
    'GET_QUESTIONS',
  )
}

export function* getLegalCheckerScenarios(action) {
  yield getAction(
    action,
    `${Project.liveApi}legal-checker/scenarios/?sub_domain=${action.data.subDomainId}`,
    'GET_SCENARIOS',
  )
}

export function* createLegalCheckerSubmission(action) {
  yield postAction(
    action,
    `${Project.liveApi}legal-checker/submissions/`,
    'CREATE_LEGAL_CHECKER_SUBMISSION',
  )
}

export function* getLegalCheckerResults(action) {
  yield getAction(
    action,
    `${Project.liveApi}legal-checker/results/?${Utils.toParam(action.data)}`,
    'GET_LEGAL_CHECKER_RESULTS',
  )
}

export function* getLegalCheckerServices(action) {
  yield getAction(
    action,
    `${Project.liveApi}legal-services/offices/?page_size=${
      action.data.page_size
    }&scenario=${action.data.scenarioId}&radius_km=${
      action.data.radius_km
    }&center=${action.data.center}&page=${action.data.page ?? 1}`,
    'GET_LEGAL_CHECKER_SERVICES',
  )
}

export function* getLegalCheckerPortals(action) {
  yield getAction(
    action,
    `${Project.liveApi}legal-checker/portals/?scenario=${action.data.scenarioId}`,
    'GET_LEGAL_CHECKER_PORTALS',
  )
}

export function* getLegalCheckerGuidance(action) {
  const prefix = 'GET_LEGAL_CHECKER_GUIDANCE'
  const actionData: RequestTypes['getLegalCheckerGuidance'] = action.data

  const data = yield getAction(
    action,
    `${Project.liveApi}legal-checker/guidance/?scenario=${actionData.scenarioId}&role=${actionData.roleId}`,
    prefix,
  )

  if (!data) {
    yield handleResponse(action, prefix, {})

    return
  }

  const [faqs, recommendations, feedback] = yield all([
    _data.get(`${Project.liveApi}legal-checker/guidance/${data.id}/faqs/`),
    _data.get(
      `${Project.liveApi}legal-checker/guidance/${data.id}/further-recommendations/`,
    ),
    _data.get(
      `${Project.liveApi}legal-checker/guidance/feedback/?legal_checker_result=${actionData.resultId}`,
    ),
  ])

  yield handleResponse(action, prefix, {
    ...data,
    faqs,
    recommendations,
    feedback: feedback.results?.[0],
  })
}

export function* getLegalCheckerFurtherGuidance(action) {
  const prefix = 'GET_LEGAL_CHECKER_FUTHER_GUIDANCE'
  const actionData: RequestTypes['getLegalCheckerFurtherGuidance'] = action.data

  const data = yield getAction(
    action,
    `${Project.liveApi}legal-checker/guidance/${actionData.guidanceId}}`,
    prefix,
  )

  const [faqs, recommendations, feedback] = yield all([
    _data.get(
      `${Project.liveApi}legal-checker/guidance/${actionData.guidanceId}/faqs/`,
    ),
    _data.get(
      `${Project.liveApi}legal-checker/guidance/${actionData.guidanceId}/further-recommendations/`,
    ),
    _data.get(
      `${Project.liveApi}legal-checker/guidance/feedback/?guidance=${actionData.guidanceId}&legal_checker_result=${actionData.resultId}`,
    ),
  ])
  yield handleResponse(action, prefix, {
    ...data,
    faqs,
    recommendations,
    feedback: feedback.results?.[0],
  })
}

export function* getLegalCheckerResources(action) {
  yield getAction(
    action,
    `${Project.liveApi}legal-checker/resources/?scenario=${action.data.scenarioId}`,
    'GET_LEGAL_CHECKER_RESOURCES',
  )
}

export function* createLegalCheckerResult(action) {
  const data: RequestTypes['createLegalCheckerResult'] = action.data
  yield postAction(
    action,
    `${Project.liveApi}legal-checker/results/`,
    'CREATE_LEGAL_CHECKER_RESULT',
  )
}

export function* getLegalCheckerResult(action) {
  const data: RequestTypes['getLegalCheckerResult'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}legal-checker/results/${action.data.id}/`,
    'GET_LEGAL_CHECKER_RESULT',
  )
}

export function* updateLegalCheckerResult(action) {
  const data: RequestTypes['updateLegalCheckerResult'] = action.data
  yield updateAction(
    action,
    `${Project.liveApi}legal-checker/results/${action.data.id}/`,
    'UPDATE_LEGAL_CHECKER_RESULT',
  )
}

export function* deleteLegalCheckerResult(action) {
  const data: RequestTypes['deleteLegalCheckerResult'] = action.data
  yield deleteAction(
    action,
    `${Project.liveApi}legal-checker/results/${action.data.id}/`,
    'DELETE_LEGAL_CHECKER_RESULT',
  )
}

export function* getLegalAidQuestions(action) {
  const data: RequestTypes['getLegalAidQuestions'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}legal-checker/legal-aid/question-sequences/${action.data.id}/`,
    'GET_LEGAL_AID_QUESTIONS',
  )
}

export function* createGuidanceFeedback(action) {
  const data: RequestTypes['createGuidanceFeedback'] = action.data
  yield postAction(
    action,
    `${Project.liveApi}legal-checker/guidance/feedback/`,
    'CREATE_GUIDANCE_FEEDBACK',
  )
}

export function* updateGuidanceFeedback(action) {
  const data: RequestTypes['updateGuidanceFeedback'] = action.data
  yield updateAction(
    action,
    `${Project.liveApi}legal-checker/guidance/feedback/${action.data.id}/`,
    'UPDATE_GUIDANCE_FEEDBACK',
  )
}

export function* getLegalAidContent(action) {
  const data: RequestTypes['getLegalAidContent'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}legal-checker/legal-aid/content-items/`,
    'GET_LEGAL_AID_CONTENT',
  )
}

export function* getIaps(action) {
  const data: RequestTypes['getIaps'] = action.data
  const prefix = 'GET_IAPS'
  try {
    const res = yield API.iap.getProducts(data.creditType, data.accountType)
    return yield handleResponse(action, prefix, res)
  } catch (e) {
    console.log(e)
    yield errorHandler(action, prefix)
  }
}

export function* getSubscriptions(action) {
  // todo: unused
  const data: RequestTypes['getSubscriptions'] = action.data
  const prefix = 'GET_SUBSCRIPTIONS'
  try {
    const res = yield API.iap.getCurrentSubscriptions(data.creditType)
    return yield handleResponse(action, prefix, res)
  } catch (e) {
    console.log(e)
    yield errorHandler(action, prefix)
  }
}

export function* createClaimPurchase(action) {
  const data: RequestTypes['createClaimPurchase'] = action.data
  let res

  try {
    res = yield _data.post(`${Project.liveApi}payments/claim-purchase/`, {
      platform: data.platform,
      receipt_payload: data.receipt_payload,
    })
  } catch (e) {
    yield errorHandler(action, 'CREATE_CLAIM_PURCHASE', false, e)

    return
  }

  yield handleResponse(action, 'CREATE_CLAIM_PURCHASE', res)
}

export function* createVerifyCreditPurchase(action) {
  const data: RequestTypes['createVerifyCreditPurchase'] = action.data
  const prefix = 'CREATE_VERIFY_CREDIT_PURCHASE'
  try {
    const res = yield _data.post(`${Project.liveApi}payments/claim-purchase/`, {
      platform: data.platform,
      receipt_payload: data.receipt_payload,
    })

    yield getResultCredits({ data: {} })

    yield API.iap.completePurchase(data.receipt_payload, data.isConsumable)
    return yield handleResponse(action, prefix, res)
  } catch (e) {
    console.log(e)
    yield errorHandler(action, prefix)
  }
}

export function* getResultCredits(action) {
  const data: RequestTypes['getResultCredits'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}payments/credits/`,
    'GET_RESULT_CREDITS',
    false,
    async (res: CreditItem[]) => {
      const newData: AppState['resultCredits'] = {
        items: res,
        isSubscribed: false,
        total: 0,
      }
      res?.map((item) => {
        newData.total += item.remaining_credits
      })
      let subscriptions: SubscriptionDTO =
        store().store.getState().profile?.subscriptions
      subscriptions = subscriptions?.filter((s) => {
        return moment(s.expiry_date).valueOf() > new Date().valueOf()
      })
      if (subscriptions?.length) {
        newData.isSubscribed = true
      }
      return newData
    },
  )
}

export function* createUnlock(action) {
  const data: RequestTypes['createUnlock'] = action.data
  switch (data.creditType) {
    default:
      yield postAction(
        action,
        `${Project.liveApi}legal-checker/results/${data.id}/unlock/`,
        'CREATE_UNLOCK',
      )
      yield getLegalCheckerResults({ data: {} })
      break
  }
  yield getResultCredits({ data: {} })
}

export function* createViewResult(action) {
  const data: RequestTypes['createViewResult'] = action.data
  yield patchAction(
    {
      ...action,
      data: {
        last_viewed_at: new Date().toISOString(),
      },
    },
    `${Project.liveApi}legal-checker/results/${action.data.id}/`,
    'CREATE_VIEW_RESULT',
  )
}

export function* getLegalDocuments(action) {
  const data: RequestTypes['getLegalDocuments'] = action.data
  let page = action.data?.page ?? 1
  if (data.url) {
    const params = Utils.fromParam(data.url.split('?')[1])
    page = params.page
  }
  yield getAction(
    action,
    `${Project.liveApi}documents/legal-documents/?${Utils.toParam(
      action.data,
    )}&page=${page}`,
    'GET_LEGAL_DOCUMENTS',
  )
}

export function* getLegalDocument(action) {
  yield getAction(
    action,
    `${Project.liveApi}documents/legal-documents/${action.data.id}`,
    'GET_LEGAL_DOCUMENT',
  )
}

export function* getLegalDocumentCode(action) {
  yield getAction(
    action,
    `${Project.liveApi}documents/legal-documents/${action.data.id}/code`,
    'GET_LEGAL_DOCUMENT_CODE',
  )
}

export function* getLegalDocumentRatings(action) {
  yield getAction(
    action,
    `${Project.liveApi}documents/ratings/?${Utils.toParam(action.data)}`,
    'GET_LEGAL_DOCUMENT_RATINGS',
  )
}

export function* createLegalDocumentRating(action) {
  yield postAction(
    action,
    `${Project.liveApi}documents/ratings/`,
    'CREATE_LEGAL_DOCUMENT_RATING',
  )
}

export function* getLegalDocumentTypes(action) {
  yield getAction(
    action,
    `${Project.liveApi}documents/types/?${Utils.toParam(action.data)}`,
    'GET_LEGAL_DOCUMENT_TYPES',
  )
}

export function* getLegalDocumentTemplateFilters(action) {
  yield getAction(
    action,
    `${Project.liveApi}documents/template-filters/`,
    'GET_LEGAL_DOCUMENT_TEMPLATE_FILTERS',
  )
}

export function* UPDATELegalDocumentRating(action) {
  const { rating_id, ...data }: RequestTypes['updateLegalDocumentRating'] =
    action.data

  yield updateAction(
    {
      ...action,
      data,
    },
    `${Project.liveApi}documents/ratings/${rating_id}/`,
    'UPDATE_LEGAL_DOCUMENT_RATING',
  )
}

export function* getGovernmentServices(action) {
  const data: RequestTypes['getGovernmentServices'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}government-services/`,
    'GET_GOVERNMENT_SERVICES',
    false,
    (res: GovernmentService[]) => {
      const groups = _.groupBy(
        res,
        (service: GovernmentService) => service.group?.name || '',
      )
      return Object.keys(groups).map((key) => {
        return {
          title: key,
          data: _.sortBy(groups[key], 'name'),
        }
      })
    },
  )
}

export function* createCloseAccount(action) {
  const data: RequestTypes['createCloseAccount'] = action.data

  try {
    const res = yield _data.delete(`${Project.liveApi}users/me/`, {})
    API.logout && API.logout()
    yield handleResponse(action, 'CREATE_CLOSE_ACCOUNT', {})
  } catch (e) {
    yield errorHandler(action, 'CREATE_CLOSE_ACCOUNT', false, e)
  }
}

export function* getCovidRegulations(action) {
  const data: RequestTypes['getCovidRegulations'] = action.data
  let page = action.data?.page ?? 1
  if (data.url) {
    const params = Utils.fromParam(data.url.split('?')[1])
    page = params.page
  }
  yield getAction(
    action,
    `${Project.liveApi}documents/covid-regulations/?${Utils.toParam(
      action.data,
    )}&page=${page}`,
    'GET_COVID_REGULATIONS',
  )
}

export function* createLegalResultFeedback(action) {
  const data: RequestTypes['createLegalResultFeedback'] = action.data
  yield postAction(
    action,
    `${Project.liveApi}legal-checker/results/feedback/`,
    'CREATE_LEGAL_RESULT_FEEDBACK',
  )
}

export function* getIndustries(action) {
  const data: RequestTypes['getIndustries'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}users/business/industries/`,
    'GET_INDUSTRIES',
  )
}

export function* getInviteToken(action) {
  const data: RequestTypes['getInviteToken'] = action.data
  yield postAction(
    action,
    `${Project.liveApi}users/invites/generate-invite-token/`,
    'GET_INVITE_TOKEN',
  )
  yield getProfile({})
}

export function* getAreasOfPractice(action) {
  const data: RequestTypes['getAreasOfPractice'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}legal-services/areas-of-practice/?${Utils.toParam(
      action.data,
    )}`,
    'GET_AREAS_OF_PRACTICE',
  )
}

export function* getOffices(action) {
  const data: RequestTypes['getOffices'] = action.data
  yield getAction(
    action,
    data.link ||
      `${Project.liveApi}legal-services/offices/?${Utils.toParam(action.data)}`,
    'GET_OFFICES',
  )
}

export function* getOffice(action) {
  const data: RequestTypes['getOffice'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}legal-services/organisations/${data.id}/`,
    'GET_OFFICE',
  )
}

export function* getAreaCategories(action) {
  const data: RequestTypes['getAreaCategories'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}legal-services/area-of-practice-categories/`,
    'GET_AREA_CATEGORIES',
  )
}

export function* getDocumentFilters(action) {
  const data: RequestTypes['getDocumentFilters'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}documents/document-filters/`,
    'GET_DOCUMENT_FILTERS',
  )
}

export function* getDocumentCategories(action) {
  const data: RequestTypes['getDocumentCategories'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}documents/document-filter-categories/`,
    'GET_DOCUMENT_CATEGORIES',
  )
}

export function* getLawyerAvailability(action) {
  const data: RequestTypes['getLawyerAvailability'] = action.data
  const lawyerType = Utils.getUserType(data.lawyerType) + 's'
  yield getAction(
    action,
    `${Project.liveApi}legal-services/${lawyerType}/profiles/${
      data.profile_id
    }/availability/?on_date=${moment(data.on_date).format(
      'YYYY-MM-DD',
    )}&duration_minutes=30`,
    'GET_LAWYER_AVAILABILITY',
  )
}

export function* createPassword(action) {
  const data: RequestTypes['createPassword'] = action.data
  try {
    const user = yield API.auth.Cognito.completeNewPassword(data.newPassword)
    yield handleLogin(action)
    action.onSuccess && action.onSuccess(user)
  } catch (e) {
    yield errorHandler(action, 'CREATE_PASSWORD', false, e)
  }
}

export function* getLawyersProfile(action) {
  const data: RequestTypes['getLawyersProfile'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}legal-services/${Utils.getLegalServicesUrl()}/profile/`,
    'GET_LAWYERS_PROFILE',
  )
}

export function* createCronofyAuthLink(action) {
  const data: RequestTypes['createCronofyAuthLink'] = action.data
  yield postAction(
    action,
    `${
      Project.liveApi
    }legal-services/${Utils.getLegalServicesUrl()}/get-cronofy-auth-link/`,
    'CREATE_CRONOFY_AUTH_LINK',
  )
}

export function* createAuthWithCronofy(action) {
  const data: RequestTypes['createAuthWithCronofy'] = action.data

  yield postAction(
    action,
    `${Project.liveApi}legal-services/${Utils.getLegalServicesUrl()}/profiles/${
      data.profile_id
    }/authenticate-with-cronofy/`,
    'CREATE_AUTH_WITH_CRONOFY',
  )
}

export function* createSetupMfa(action) {
  // const data: RequestTypes['createSetupMfa'] = action.data
  try {
    const secretCode = yield API.auth.Cognito.setupMfa()
    yield handleResponse(action, 'CREATE_SETUP_MFA', secretCode, false)
    if (action.onSuccess) {
      action.onSuccess({ code: secretCode })
    }
    yield handleLogin(action)
  } catch (e) {
    yield errorHandler(action, 'CREATE_SETUP_MFA', false, e)
  }
}

export function* createVerifyMfa(action) {
  const data: RequestTypes['createVerifyMfa'] = action.data

  try {
    yield API.auth.Cognito.verifyMfa(data.authCode)
    yield handleLogin(action)
    yield handleResponse(action, 'CREATE_VERIFY_MFA', null, false)
  } catch (e) {
    console.error(e)
    yield errorHandler(action, 'CREATE_VERIFY_MFA', false, e)
  }
}

export function* getLawyerProfiles(action) {
  const data: RequestTypes['getLawyerProfiles'] = action.data
  yield getAction(
    action,
    data.link ||
      `${Project.liveApi}legal-services/lawyers/profiles/?${Utils.toParam(
        data,
      )}`,
    'GET_LAWYER_PROFILES',
  )
}

export function* createCronofyUiToken(action) {
  const data: RequestTypes['createCronofyUiToken'] = action.data
  yield postAction(
    action,
    `${Project.liveApi}legal-services/${Utils.getLegalServicesUrl(
      '/get-cronofy-ui-element-token/',
      '/cronofy-ui-element-token/',
    )}`,
    'CREATE_CRONOFY_UI_TOKEN',
  )
}

export function* enableCommission(action) {
  const legalServiceType = Utils.getLegalServicesUrl().slice(0, -1)
  yield postAction(
    action,
    `${Project.liveApi}legal-services/${legalServiceType}s/enable-commission/`,
    'ENABLE_COMMISSION',
  )
}

export function* disableCommission(action) {
  const legalServiceType = Utils.getLegalServicesUrl().slice(0, -1)
  yield postAction(
    action,
    `${Project.liveApi}legal-services/${legalServiceType}s/disable-commission/`,
    'DISABLE_COMMISSION',
  )
}

export function* createConfirmSignin(action) {
  const data: RequestTypes['createConfirmSignin'] = action.data

  try {
    yield API.auth.Cognito.confirmSignIn(data.authCode)
    yield handleLogin(action)
    yield handleResponse(action, 'CREATE_CONFIRM_SIGNIN', null, false)
  } catch (e) {
    yield errorHandler(action, 'CREATE_CONFIRM_SIGNIN', false, e)
  }
}

export function* getBookings(action) {
  if (action.data?.isConsumer) {
    const prefix = 'GET_BOOKINGS'
    try {
      const [bookings1, bookings2] = yield all([
        _data.get(`${Project.liveApi}book-a-lawyer/appointments`),
        _data.get(`${Project.liveApi}book-a-barrister/appointments`),
      ])
      yield handleResponse(action, prefix, {
        ...bookings1,
        count: bookings1.count + bookings2.count,
        results: bookings1.results.concat(bookings2.results),
      })
    } catch (e) {
      yield errorHandler(action, prefix, false, e)
    }
    return
  } else {
    const legalServiceType = Utils.getLegalServicesUrl().slice(0, -1)
    const data: RequestTypes['getBookings'] = action.data
    yield getAction(
      action,
      data?.link ||
        `${Project.liveApi}book-a-${legalServiceType}/appointments/`,
      'GET_BOOKINGS',
    )
  }
}

export function* getLawyerBookings(action) {
  const data: RequestTypes['getLawyerBookings'] = action.data
  yield getAction(
    action,
    data?.link || `${Project.liveApi}book-a-lawyer/appointments/`,
    'GET_LAWYER_BOOKINGS',
  )
}

export function* getBarristerBookings(action) {
  const data: RequestTypes['getBarristerBookings'] = action.data
  yield getAction(
    action,
    data?.link || `${Project.liveApi}book-a-barrister/appointments/`,
    'GET_BARRISTER_BOOKINGS',
    false,
    (res: AppState['barristerBookings']) => {
      return {
        ...res,
        results: res.results.map((item) => {
          return {
            ...item,
            barrister: {
              ...item.barrister,
              user: {
                id: item.barrister.id,
                first_name: item.barrister.full_name,
                last_name: '',
                email: item.barrister.email_address,
              },
            },
          }
        }),
      }
    },
  )
}

export function* getAcceptedBookings(action) {
  const data: RequestTypes['getAcceptedBookings'] = action.data
  const legalServiceType = Utils.getLegalServicesUrl().slice(0, -1)
  yield getAction(
    action,
    data?.link ??
      `${Project.liveApi}book-a-${legalServiceType}/appointments?upcoming_only=true&statuses=ACCEPTED`,
    'GET_ACCEPTED_BOOKINGS',
  )
}

export function* getPendingBookings(action) {
  const data: RequestTypes['getPendingBookings'] = action.data
  const legalServiceType = Utils.getLegalServicesUrl().slice(0, -1)
  yield getAction(
    action,
    data?.link ??
      `${
        Project.liveApi
      }book-a-${legalServiceType}/appointments?upcoming_only=true&statuses=PENDING_${legalServiceType.toUpperCase()}_ACCEPTANCE`,
    'GET_PENDING_BOOKINGS',
  )
}

export function* getDeclinedBookings(action) {
  const data: RequestTypes['getDeclinedBookings'] = action.data
  const legalServiceType = Utils.getLegalServicesUrl().slice(0, -1)
  yield getAction(
    action,
    data?.link ??
      `${Project.liveApi}book-a-${legalServiceType}/appointments?upcoming_only=true&statuses=DECLINED`,
    'GET_DECLINED_BOOKINGS',
  )
}

export function* createAppointmentBooking(action) {
  const data: RequestTypes['createAppointmentBooking'] = action.data
  const key = Object.keys(data)?.[0]
  yield postAction(
    action,
    `${Project.liveApi}book-a-${key}/appointments/`,
    'CREATE_APPOINTMENT_BOOKING',
  )
}

export function* updateBooking(action) {
  const data: RequestTypes['updateBooking'] = action.data
  const lawyerType = Utils.getUserType(data.lawyerType)
  try {
    const formData = new FormData()
    Object.keys(data).forEach((k) => {
      const res = data[k]
      if (k === 'guests') {
        formData.append(k, JSON.stringify(res))
      } else {
        formData.append(`${k}`, res)
      }
    })
    const res = yield _data.patch(
      `${Project.liveApi}book-a-${lawyerType}/appointments/${data.id}/`,
      formData,
      {
        'content-type': 'multipart/form-data',
      },
    )
    yield handleResponse(action, 'UPDATE_BOOKING', res)
  } catch (e) {
    yield errorHandler(action, 'UPDATE_BOOKING', false, e)
  }
}

export function* createBookingAction(action) {
  const data: RequestTypes['createBookingAction'] = action.data
  const response = data.response
  const legalSercviceType = Utils.getLegalServicesUrl().slice(0, -1)
  yield postAction(
    { action, data: null },
    `${Project.liveApi}book-a-${legalSercviceType}/appointments/${data.id}/${response}/`,
    'CREATE_BOOKING_ACTION',
  )
  yield getBookings({})
  yield getPendingBookings({})
  if (response === 'accept') {
    yield getAcceptedBookings({})
  }
  if (response === 'decline') {
    yield getDeclinedBookings({})
  }
}

export function* deleteBookALawyer(action) {
  const data: RequestTypes['deleteBookALawyer'] = action.data
  yield deleteAction(
    action,
    `${Project.api}legal-services/${Utils.getLegalServicesUrl()}/profiles/${
      data.id
    }/book-appointment/`,
    'DELETE_BOOK_A_LAWYER',
  )
}

export function* createBookALawyer(action) {
  const data: RequestTypes['createBookALawyer'] = action.data
  try {
    const formData = new FormData()
    Object.keys(data).map((k) => {
      const res = data[k]
      formData.append(`${k}`, res)
    })
    const res = yield _data.post(
      `${Project.liveApi}book-a-lawyer/appointments/`,
      formData,
      {
        'content-type': 'multipart/form-data',
      },
    )
    yield handleResponse(action, 'CREATE_BOOK_A_LAWYER', res)
  } catch (e) {
    yield errorHandler(action, 'CREATE_BOOK_A_LAWYER', false, e)
  }
}

export function* getBookALawyer(action) {
  const data: RequestTypes['getBookALawyer'] = action.data
  yield getAction(
    action,
    `${Project.api}legal-services/${Utils.getLegalServicesUrl()}/profiles/${
      data.id
    }/book-appointment/`,
    'GET_BOOK_A_LAWYER',
  )
}

export function* updateBookALawyer(action) {
  const data: RequestTypes['updateBookALawyer'] = action.data
  yield updateAction(
    action,
    `${Project.api}legal-services/${Utils.getLegalServicesUrl()}/profiles/${
      data.id
    }/book-appointment/`,
    'UPDATE_BOOK_A_LAWYER',
  )
}

export function* createZoomAuthLink(action) {
  const data: RequestTypes['createZoomAuthLink'] = action.data
  yield postAction(
    action,
    `${Project.liveApi}legal-services/${Utils.getLegalServicesUrl()}/profiles/${
      data.profile_id
    }/get-zoom-auth-link/`,
    'CREATE_ZOOM_AUTH_LINK',
  )
}
export function* getCompanies(action) {
  const data: RequestTypes['getCompanies'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}integrations/companies-housesearch-companies/?q=${data.q}`,
    'GET_COMPANIES',
  )
}

export function* getStripeProductDetails(action) {
  const data: RequestTypes['getStripeProductDetails'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}integrations/stripe/products/${data.id}`,
    'GET_STRIPE_PRODUCT_DETAILS',
  )
}

export function* getStripeCheckoutUrl(action) {
  const data: RequestTypes['getStripeCheckoutUrl'] = action.data
  yield postAction(
    action,
    `${Project.liveApi}integrations/stripe/products/${data.id}/checkout`,
    'GET_STRIPE_CHECKOUT_URL',
  )
}

export function* createActionAppointment(action) {
  const data: RequestTypes['createActionAppointment'] = action.data
  yield postAction(
    action,
    `${Project.liveApi}book-a-lawyer/appointments/${data.id}/${data.action}/`,
    'CREATE_ACTION_APPOINTMENT',
  )
  yield getBookings({ ...action, data: { upcoming_only: true } })
}

export function* getCronofyConferencingProfiles(action) {
  const data: RequestTypes['getCronofyConferencingProfiles'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}legal-services/${Utils.getLegalServicesUrl()}/profiles/${
      data.profile_id
    }/cronofy-conferencing-profiles/`,
    'GET_CRONOFY_CONFERENCING_PROFILES',
  )
}

export function* getBooking(action) {
  const data: RequestTypes['getBooking'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}book-a-lawyer/appointments/${data.id}/`,
    'GET_BOOKING',
  )
}

export function* getLawyerMembershipTypes(action) {
  const data: RequestTypes['getLawyerMembershipTypes'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}legal-services/lawyers/legal-memberships/`,
    'GET_LAWYER_MEMBERSHIP_TYPES',
  )
}

export function* getLawyerQualificationTypes(action) {
  const data: RequestTypes['getLawyerQualificationTypes'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}legal-services/lawyers/legal-qualifications/`,
    'GET_LAWYER_QUALIFICATION_TYPES',
  )
}

export function* getDetailedLawyerProfile(action) {
  const data: RequestTypes['getDetailedLawyerProfile'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}legal-services/lawyers/profiles/${data.id}/`,
    'GET_DETAILED_LAWYER_PROFILE',
  )
}

export function* getDetailedLawfirmProfile(action) {
  const data: RequestTypes['getDetailedLawfirmProfile'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}legal-services/offices/${data.id}/`,
    'GET_DETAILED_LAWFIRM_PROFILE',
  )
}

export function* getDetailedBarristerProfile(action) {
  const data: RequestTypes['getDetailedBarristerProfile'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}legal-services/barristers/profiles/${data.id}/`,
    'GET_DETAILED_BARRISTER_PROFILE',
  )
}

export function* getBarristers(action) {
  const data: RequestTypes['getBarristers'] = action.data
  yield getAction(
    action,
    data.link ||
      `${Project.liveApi}legal-services/barristers/profiles?${Utils.toParam(
        data,
      )}`,
    'GET_BARRISTERS',
    false,
    (res: AppState['barristers']) => {
      return {
        ...res,
        results: res.results.map((v) => {
          return {
            ...v,
            user: v.user || {
              id: v.id,
              first_name: v.full_name,
              last_name: '',
              email: v.email_address,
            },
          } as BarristerProfile
        }),
      }
    },
  )
}

export function* getBarrister(action) {
  const data: RequestTypes['getBarrister'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}legal-services/barristers/${data.id}/`,
    'GET_BARRISTER',
  )
}

export function* getBarristerHonours(action) {
  const data: RequestTypes['getBarristerHonours'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}legal-services/barristers/honours/`,
    'GET_BARRISTER_HONOURS',
  )
}

export function* getBarristersSearch(action) {
  const data: RequestTypes['getBarristersSearch'] = action.data
  yield postAction(
    action,
    `${Project.liveApi}legal-services/barristers/search/`,
    'GET_BARRISTERS_SEARCH',
  )
}

export function* updateBarrister(action) {
  const data: RequestTypes['updateBarrister'] = action.data
  yield patchAction(
    action,
    `${Project.liveApi}legal-services/barristers/profiles/${action.data.id}/`,
    'UPDATE_BARRISTER',
  )
}

export function* createLawyersProfile(action) {
  const { ...restData }: RequestTypes['createLawyersProfile'] = action.data

  try {
    const formData = new FormData()
    Object.keys(restData).map((key) => {
      const val = restData[key]
      if (val === undefined) {
        return
      }
      if (key === 'avatar') {
        formData.append(key, val, val.name)
      } else {
        if (typeof val !== 'string' && typeof val?.length === 'number') {
          if (val.length) {
            val.map((v) => {
              formData.append(key, v)
            })
          }
        } else {
          formData.append(key, val)
        }
      }
    })

    const res = yield fetch(
      `${
        Project.liveApi
      }legal-services/${Utils.getLegalServicesUrl()}/profiles/`,
      {
        method: 'POST',
        body: formData,
        headers: {
          AUTHORIZATION: `Bearer ${_data.token}`,
        },
      },
    )
      .then(_data.status)
      .then(_data.return)

    yield getLawyersProfile(action)
  } catch (e) {
    yield errorHandler(action, 'UPDATE_LAWYERS_PROFILE', false, e)
  }
}
export function* getOrganisationBySra(action) {
  const data: RequestTypes['getOrganisationBySra'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}find-a-lawyer/organisation/sra/${action.data.id}`,
    'GET_ORGANISATION_BY_SRA',
  )
}

export function* createUploadAvatar(action) {
  try {
    const data: RequestTypes['createUploadAvatar'] = action.data
    // const formData = new FormData()
    // formData.append('image', action.data.file)
    //
    // const res = yield fetch(`${Project.liveApi}find-a-lawyer/upload-avtar/`, {
    //   method: 'POST',
    //   body: formData,
    //   headers: {
    //     AUTHORIZATION: `Bearer ${_data.token}`,
    //   },
    // })
    //   .then(_data.status)
    //   .then(_data.return)

    const result = yield updateLawyersProfile({
      type: Actions.UPDATE_LAWYERS_PROFILE,
      data: { id: action.data.id, avatar: action.data.file },
    })
    yield handleResponse(action, 'CREATE_UPLOAD_AVATAR', result, false)
  } catch (e) {
    yield errorHandler(action, 'CREATE_UPLOAD_AVATAR', false, e)
  }
}

export function* createUploadUserAvatar(action) {
  let res

  try {
    res = yield _data.post(
      `${Project.liveApi}users/avatar/`,
      createFormData(action.data),
      {
        'content-type': 'multipart/form-data',
        'content-disposition': 'attachment; filename=avatar.jpg',
      },
    )
  } catch (e) {
    yield errorHandler(action, 'CREATE_UPLOAD_USER_AVATAR', false, e)

    return
  }

  yield handleResponse(action, 'CREATE_UPLOAD_USER_AVATAR', res)
}

export function* updateLawyersProfile(action) {
  const { id, ...restData }: RequestTypes['updateLawyersProfile'] = action.data

  try {
    const formData = new FormData()
    Object.keys(restData).map((key) => {
      const val = restData[key]
      if (val === undefined) {
        return
      }
      if (key === 'avatar') {
        formData.append(key, val, val.name)
      } else {
        if (typeof val !== 'string' && typeof val?.length === 'number') {
          if (val.length) {
            val.map((v) => {
              formData.append(key, v)
            })
          }
        } else {
          formData.append(key, val)
        }
      }
    })

    const res = yield fetch(
      `${
        Project.liveApi
      }legal-services/${Utils.getLegalServicesUrl()}/profiles/${
        action.data.id
      }/`,
      {
        method: 'PATCH',
        body: formData,
        headers: {
          AUTHORIZATION: `Bearer ${_data.token}`,
        },
      },
    )
      .then(_data.status)
      .then(_data.return)

    yield getLawyersProfile(action)
  } catch (e) {
    yield errorHandler(action, 'UPDATE_LAWYERS_PROFILE', false, e)
  }
}

export function* getOrganisation(action) {
  const data: RequestTypes['getOrganisation'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}find-a-lawyer/organisations/${action.data.id}/`,
    'GET_ORGANISATION',
  )
}

export function* createReportBarrister(action) {
  const data: RequestTypes['createReportBarrister'] = action.data
  yield updateAction(
    action,
    `${Project.liveApi}legal-services/barristers/profiles/${data.id}/request`,
    'CREATE_REPORT_BARRISTER',
  )
}

export function* createReportOrganisation(action) {
  const data: RequestTypes['createReportOrganisation'] = action.data
  yield updateAction(
    action,
    `${Project.liveApi}legal-services/organisation/${action.data.id}/request`,
    'CREATE_REPORT_ORGANISATION',
  )
}

export function* getStripeSubscriptions(action) {
  const data: RequestTypes['getStripeSubscriptions'] = action.data
  yield getAction(
    action,
    `${Project.liveApi}payments/subscriptions/`,
    'GET_STRIPE_SUBSCRIPTIONS',
  )
}

export function* createStripeCheckout(action) {
  const data: RequestTypes['createStripeCheckout'] = action.data
  yield postAction(
    action,
    `${Project.liveApi}payments/subscriptions/checkout/`,
    'CREATE_STRIPE_CHECKOUT',
  )
}

export function* deleteSubscription(action) {
  const data: RequestTypes['deleteSubscription'] = action.data
  yield deleteAction(
    action,
    `${Project.liveApi}payments/subscriptions/user/${action.data.id}/`,
    'DELETE_SUBSCRIPTION',
  )
}

export function* createQualification(action) {
  const data: RequestTypes['createQualification'] = action.data
  yield postAction(
    action,
    `${Project.liveApi}find-a-lawyer/associations/legal-qualifications/`,
    'CREATE_QUALIFICATION',
  )
}

export function* createMembership(action) {
  const data: RequestTypes['createMembership'] = action.data
  yield postAction(
    action,
    `${Project.liveApi}find-a-lawyer/associations/legal-memberships/`,
    'CREATE_MEMBERSHIP',
  )
}

export function* createArea(action) {
  const data: RequestTypes['createArea'] = action.data
  yield postAction(
    action,
    `${Project.liveApi}legal-services/areas-of-practice/`,
    'create_area',
  )
}

export function* createFavourite(action) {
  const data: RequestTypes['createFavourite'] = action.data
  const key = Object.keys(data)?.[0]
  const userType = key === 'office' ? key : key + 's'
  yield postAction(
    action,
    `${Project.liveApi}legal-services/${userType}/favourite/`,
    'CREATE_FAVOURITE',
  )
}

export function* deleteFavourite(action) {
  const data: RequestTypes['deleteFavourite'] = action.data
  const userType =
    Utils.getUserType(data.type) === 'office'
      ? 'office'
      : Utils.getUserType(data.type) + 's'
  yield deleteAction(
    action,
    `${Project.liveApi}legal-services/${userType}/favourite/${action.data.id}/`,
    'DELETE_FAVOURITE',
  )
}

export function* getProfileDetail(action) {
  const data: RequestTypes['getProfileDetail'] = action.data
  const userType = Utils.getUserType(data.type)
  yield getAction(
    action,
    `${Project.liveApi}legal-services/${userType}s/profiles/${action.data.id}/`,
    'GET_PROFILE_DETAIL',
  )
}

export function* createReview(action) {
  const data: RequestTypes['createReview'] = action.data
  const key = Object.keys(data)?.[0]
  yield postAction(
    action,
    `${Project.liveApi}legal-services/${key}s/rating/`,
    'CREATE_REVIEW',
  )
}

export function* createResponseToReview(action) {
  const data: RequestTypes['createResponseToReview'] = action.data
  const userType = Utils.getUserType(data.params.type)
  yield postAction(
    { ...action, data: data.body },
    `${Project.liveApi}legal-services/${userType}s/rating/${data.params.id}/response/`,
    'CREATE_RESPONSE_TO_REVIEW',
  )
  yield getLawyersProfile({})
}

export function* createLeadershipArticle(action) {
  const data: RequestTypes['createLeadershipArticle'] = action.data
  try {
    const res = yield fetch(
      `${Project.liveApi}legal-services/leadership-articles/`,
      {
        method: 'POST',
        body: data,
        headers: {
          AUTHORIZATION: `Bearer ${_data.token}`,
        },
      },
    )
      .then(_data.status)
      .then(_data.return)
    yield handleResponse(action, 'CREATE_LEADERSHIP_ARTICLE', res)
  } catch (e) {
    yield errorHandler(action, 'CREATE_LEADERSHIP_ARTICLE', false, e)
  }
}

export function* getOccupations(action) {
  yield getAction(
    action,
    `${Project.liveApi}users/occupations/`,
    'GET_OCCUPATIONS',
  )
}

// END OF YIELDS

function* rootSaga() {
  yield all([
    takeLatest(Actions.LOGIN, login),
    takeLatest(Actions.REGISTER, register),
    takeLatest(Actions.RESET_PASSWORD, resetPassword),
    takeLatest(Actions.CHANGE_PASSWORD, changePassword),
    takeLatest(Actions.LOGOUT, logout),
    takeLatest(Actions.CONFIRM_EMAIL, confirmEmail),
    takeLatest(Actions.UPDATE_USER, updateUser),
    takeLatest(Actions.STARTUP, startup),
    takeLatest(Actions.GET_PROFILE, getProfile),
    takeEvery(Actions.UPDATE_PROFILE, updateProfile),
    takeLatest(Actions.GET_FEEDBACK_SUMMARY, getFeedbackSummary),
    takeEvery(Actions.GET_FEEDBACK, getFeedback),
    takeEvery(Actions.CREATE_FEEDBACK, createFeedback),
    takeEvery(Actions.REDEEM, createMembershipRedemption),
    takeLatest(Actions.GET_FAQS, getFaqs),
    takeEvery(Actions.CREATE_OAUTH, oauth),
    takeEvery(Actions.GET_LEGAL_CHECKER, getLegalChecker),
    takeEvery(Actions.GET_LEGAL_CHECKER_DOMAINS, getLegalCheckerDomains),
    takeEvery(Actions.GET_LEGAL_CHECKER_SUB_DOMAINS, getLegalCheckerSubDomains),
    takeEvery(Actions.GET_QUESTIONS, getLegalCheckerQuestions),
    takeEvery(Actions.GET_SCENARIOS, getLegalCheckerScenarios),
    takeEvery(
      Actions.CREATE_LEGAL_CHECKER_SUBMISSION,
      createLegalCheckerSubmission,
    ),
    takeEvery(Actions.GET_LEGAL_CHECKER_RESULTS, getLegalCheckerResults),
    takeEvery(Actions.GET_LEGAL_CHECKER_SERVICES, getLegalCheckerServices),
    takeEvery(Actions.GET_LEGAL_CHECKER_PORTALS, getLegalCheckerPortals),
    takeEvery(Actions.GET_LEGAL_CHECKER_GUIDANCE, getLegalCheckerGuidance),
    takeEvery(Actions.GET_LEGAL_CHECKER_RESOURCES, getLegalCheckerResources),
    takeEvery(Actions.CREATE_LEGAL_CHECKER_RESULT, createLegalCheckerResult),
    takeEvery(Actions.GET_LEGAL_CHECKER_RESULT, getLegalCheckerResult),
    takeEvery(Actions.UPDATE_LEGAL_CHECKER_RESULT, updateLegalCheckerResult),
    takeEvery(Actions.DELETE_LEGAL_CHECKER_RESULT, deleteLegalCheckerResult),
    takeEvery(Actions.GET_LEGAL_AID_QUESTIONS, getLegalAidQuestions),
    takeEvery(Actions.CREATE_GUIDANCE_FEEDBACK, createGuidanceFeedback),
    takeEvery(Actions.UPDATE_GUIDANCE_FEEDBACK, updateGuidanceFeedback),
    takeLatest(Actions.GET_LEGAL_AID_CONTENT, getLegalAidContent),
    takeLatest(
      Actions.GET_LEGAL_CHECKER_FUTHER_GUIDANCE,
      getLegalCheckerFurtherGuidance,
    ),
    takeEvery(
      Actions.CREATE_VERIFY_CREDIT_PURCHASE,
      createVerifyCreditPurchase,
    ),
    takeEvery(Actions.CREATE_CLAIM_PURCHASE, createClaimPurchase),
    takeLatest(Actions.GET_IAPS, getIaps),
    takeLatest(Actions.GET_SUBSCRIPTIONS, getSubscriptions), // todo: unused
    takeLatest(Actions.GET_RESULT_CREDITS, getResultCredits),
    takeEvery(Actions.CREATE_UNLOCK, createUnlock),
    takeEvery(Actions.CREATE_VIEW_RESULT, createViewResult),
    takeLatest(Actions.GET_LEGAL_DOCUMENTS, getLegalDocuments),
    takeLatest(Actions.GET_LEGAL_DOCUMENT, getLegalDocument),
    takeLatest(Actions.GET_LEGAL_DOCUMENT_RATINGS, getLegalDocumentRatings),
    takeEvery(Actions.CREATE_LEGAL_DOCUMENT_RATING, createLegalDocumentRating),
    takeEvery(Actions.UPDATE_LEGAL_DOCUMENT_RATING, UPDATELegalDocumentRating),
    takeLatest(Actions.GET_LEGAL_DOCUMENT_TYPES, getLegalDocumentTypes),
    takeLatest(Actions.GET_LEGAL_DOCUMENT_CODE, getLegalDocumentCode),
    takeLatest(
      Actions.GET_LEGAL_DOCUMENT_TEMPLATE_FILTERS,
      getLegalDocumentTemplateFilters,
    ),
    takeLatest(Actions.GET_GOVERNMENT_SERVICES, getGovernmentServices),
    takeEvery(Actions.CREATE_CLOSE_ACCOUNT, createCloseAccount),
    takeLatest(Actions.GET_COVID_REGULATIONS, getCovidRegulations),
    takeLatest(Actions.CREATE_LEGAL_RESULT_FEEDBACK, createLegalResultFeedback),
    takeLatest(Actions.GET_INDUSTRIES, getIndustries),
    takeLatest(Actions.GET_INVITE_TOKEN, getInviteToken),
    takeLatest(Actions.GET_AREAS_OF_PRACTICE, getAreasOfPractice),
    takeLatest(Actions.GET_OFFICES, getOffices),
    takeEvery(Actions.GET_OFFICE, getOffice),
    takeLatest(Actions.GET_AREA_CATEGORIES, getAreaCategories),
    takeLatest(Actions.GET_DOCUMENT_CATEGORIES, getDocumentCategories),
    takeLatest(Actions.GET_DOCUMENT_FILTERS, getDocumentFilters),
    takeLatest(Actions.GET_LAWYER_AVAILABILITY, getLawyerAvailability),
    takeEvery(Actions.CREATE_PASSWORD, createPassword),
    takeEvery(Actions.GET_LAWYERS_PROFILE, getLawyersProfile),
    takeEvery(Actions.CREATE_CRONOFY_AUTH_LINK, createCronofyAuthLink),
    takeEvery(Actions.CREATE_AUTH_WITH_CRONOFY, createAuthWithCronofy),
    takeEvery(Actions.CREATE_SETUP_MFA, createSetupMfa),
    takeEvery(Actions.CREATE_VERIFY_MFA, createVerifyMfa),
    takeEvery(Actions.CREATE_CRONOFY_UI_TOKEN, createCronofyUiToken),
    takeEvery(Actions.GET_LAWYER_PROFILES, getLawyerProfiles),
    takeEvery(Actions.CREATE_CONFIRM_SIGNIN, createConfirmSignin),
    takeEvery(Actions.DELETE_BOOK_A_LAWYER, deleteBookALawyer),
    takeEvery(Actions.CREATE_BOOK_A_LAWYER, createBookALawyer),
    takeEvery(Actions.GET_BOOK_A_LAWYER, getBookALawyer),
    takeEvery(Actions.UPDATE_BOOK_A_LAWYER, updateBookALawyer),
    takeEvery(Actions.GET_BOOKINGS, getBookings),
    takeEvery(Actions.CREATE_ZOOM_AUTH_LINK, createZoomAuthLink),
    takeLatest(Actions.GET_COMPANIES, getCompanies),
    takeLatest(Actions.GET_STRIPE_PRODUCT_DETAILS, getStripeProductDetails),
    takeLatest(Actions.GET_STRIPE_CHECKOUT_URL, getStripeCheckoutUrl),
    takeEvery(Actions.CREATE_ACTION_APPOINTMENT, createActionAppointment),
    takeLatest(
      Actions.GET_CRONOFY_CONFERENCING_PROFILES,
      getCronofyConferencingProfiles,
    ),
    takeLatest(Actions.GET_BOOKING, getBooking),
    takeLatest(Actions.GET_LAWYER_MEMBERSHIP_TYPES, getLawyerMembershipTypes),
    takeLatest(
      Actions.GET_LAWYER_QUALIFICATION_TYPES,
      getLawyerQualificationTypes,
    ),
    takeLatest(Actions.GET_DETAILED_LAWYER_PROFILE, getDetailedLawyerProfile),
    takeLatest(Actions.GET_BARRISTERS, getBarristers),
    takeLatest(Actions.GET_BARRISTER, getBarrister),
    takeLatest(Actions.GET_BARRISTER_HONOURS, getBarristerHonours),
    takeLatest(Actions.GET_BARRISTERS_SEARCH, getBarristersSearch),
    takeEvery(Actions.UPDATE_BARRISTER, updateBarrister),
    takeEvery(Actions.CREATE_LAWYERS_PROFILE, createLawyersProfile),
    takeLatest(Actions.GET_ORGANISATION_BY_SRA, getOrganisationBySra),
    takeEvery(Actions.CREATE_UPLOAD_AVATAR, createUploadAvatar),
    takeEvery(Actions.CREATE_UPLOAD_USER_AVATAR, createUploadUserAvatar),
    takeEvery(Actions.UPDATE_LAWYERS_PROFILE, updateLawyersProfile),
    takeEvery(Actions.GET_ORGANISATION, getOrganisation),
    takeEvery(Actions.CREATE_REPORT_BARRISTER, createReportBarrister),
    takeEvery(Actions.CREATE_REPORT_ORGANISATION, createReportOrganisation),
    takeEvery(Actions.UPDATE_BOOKING, updateBooking),
    takeLatest(Actions.GET_STRIPE_SUBSCRIPTIONS, getStripeSubscriptions),
    takeEvery(Actions.CREATE_STRIPE_CHECKOUT, createStripeCheckout),
    takeEvery(Actions.DELETE_SUBSCRIPTION, deleteSubscription),
    takeEvery(Actions.CREATE_QUALIFICATION, createQualification),
    takeEvery(Actions.CREATE_MEMBERSHIP, createMembership),
    takeEvery(Actions.create_area, createArea),
    takeEvery(Actions.CREATE_FAVOURITE, createFavourite),
    takeEvery(Actions.DELETE_FAVOURITE, deleteFavourite),
    takeEvery(Actions.DISABLE_COMMISSION, disableCommission),
    takeEvery(Actions.ENABLE_COMMISSION, enableCommission),
    takeLatest(Actions.GET_PROFILE_DETAIL, getProfileDetail),
    takeEvery(Actions.CREATE_RESPONSE_TO_REVIEW, createResponseToReview),
    takeEvery(Actions.CREATE_LEADERSHIP_ARTICLE, createLeadershipArticle),
    takeEvery(Actions.CREATE_APPOINTMENT_BOOKING, createAppointmentBooking),
    takeEvery(Actions.CREATE_REVIEW, createReview),
    takeEvery(Actions.GET_DETAILED_LAWFIRM_PROFILE, getDetailedLawfirmProfile),
    takeEvery(
      Actions.GET_DETAILED_BARRISTER_PROFILE,
      getDetailedBarristerProfile,
    ),
    takeEvery(Actions.CREATE_BOOKING_ACTION, createBookingAction),
    takeEvery(Actions.GET_ACCEPTED_BOOKINGS, getAcceptedBookings),
    takeEvery(Actions.GET_PENDING_BOOKINGS, getPendingBookings),
    takeEvery(Actions.GET_DECLINED_BOOKINGS, getDeclinedBookings),
    takeEvery(Actions.GET_LAWYER_BOOKINGS, getLawyerBookings),
    takeEvery(Actions.GET_BARRISTER_BOOKINGS, getBarristerBookings),
    takeEvery(Actions.GET_OCCUPATIONS, getOccupations),
    // END OF TAKE_LATEST
    // KEEP THE ABOVE LINE IN, IT IS USED BY OUR CLI
  ])
}

export default rootSaga
