import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import './app-actions'
import rootSaga from './saga'
import { AppState } from './state-type'

let _store
let _persistor

// @ts-ignore - state-type needs to be adjusted such that all state keys are optional
export default function (initialState: AppState = {}, forceNewStore?: boolean) {
  // It's very important to only return the cached store on the client, otherwise SSR will return the previous request state
  // @ts-ignore
  if (
    _store &&
    (typeof window !== 'undefined' || global.__JEST__ !== 'undefined') &&
    !forceNewStore
  ) {
    return { store: _store, persistor: _persistor }
  }

  const persistConfig = {
    key: 'root',
    whitelist: [
      'user',
      'profile',
      'subscriptions',
      'iaps',
      'feedback',
      'areasOfPractice',
      'feedbackSummary',
      'menuItems',
      'faqs',
      'theme',
      'verifyMfa',
      // "legalCheckerOptions"
    ],
    storage: API.storage,
  }

  const sagaMiddleware = createSagaMiddleware()

  const middlewares = API.middlewares
    ? [sagaMiddleware, ...API.middlewares]
    : [sagaMiddleware]

  const rootReducer = require('./reducer').default
  const reducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(
    reducer,
    // @ts-ignore - state-type needs to be adjusted such that all state keys are optional
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  )
  // @ts-ignore
  store.sagaTask = sagaMiddleware.run(rootSaga)
  _store = store

  _persistor = persistStore(store)
  return { store: _store, persistor: _persistor }
}
