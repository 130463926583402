import cn from 'classnames'
import Constants from '../../../common/utils/constants'
import phone from '../assets/phone.svg'
import { Button } from '../../../common/components/Authentication/Button'

type ContactBarProps = {
  className?: string
}

export const ContactBar = ({ className }: ContactBarProps) => (
  <div
    className={cn(
      'flex flex-col lg:flex-row items-center justify-cente space-y-5 lg:space-y-0',
      className,
    )}
  >
    <div className='text-lg flex justify-center'>
      <img src={phone} width='23' height='23' alt='' />
      <div className='whitespace-nowrap mx-2'>
        Call
        <span className='ml-2 text-base font-medium leading-6 tracking-[0.4px]'>
          020 3918 5630
        </span>
      </div>
    </div>
    <span className='text-[#001A3D] mx-6 text-xs font-normal leading-4 tracking-[0.4px]'>
      OR
    </span>
    <div>
      <Button
        onClick={() => {
          window.location.href = `mailto:${Constants.supportEmail}`
        }}
      >
        Email us
      </Button>
    </div>
  </div>
)
