import React, { useState } from 'react';
import cn from 'classnames';

type BaseFooterTextProps = {
  text: string
  linkText: string
}

type FooterTextLinkProps = BaseFooterTextProps & {
  linkOnClick?: never
  linkHref: string
}

type FooterTextButtonProps = BaseFooterTextProps & {
  linkOnClick: () => void
  linkHref?: string
}

type FooterTextProps = FooterTextLinkProps | FooterTextButtonProps

export const FooterText = ({
  text,
  linkText,
  linkHref,
  linkOnClick,
}: FooterTextProps) => {
   const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const linkClasses = cn(
    'underline inline-block pl-1 cursor-pointer text-base transition-colors duration-200',
    {
      '!text-blue': !isHovered && !isClicked,
      '!text-[#205497]': isHovered || isClicked,
    }
  );
    const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsClicked(false);
  };
  const handleMouseDown = () => setIsClicked(true);
  const handleMouseUp = () => setIsClicked(false);


  return (
    <p className='text-base font-normal mt-2 text-center text-primary-font'>
      {text}
      <span>
        {linkHref ? (
          <a href={linkHref} className={linkClasses}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}>
            {linkText}
          </a>
        ) : (
          <button type='button' onClick={linkOnClick}  className={linkClasses}>
            {linkText}
          </button>
        )}
      </span>
    </p>
  )
}
