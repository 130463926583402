import React from 'react'
import { Popup } from 'reactjs-popup'
import { FaCheckCircle } from 'react-icons/fa'
import { AiFillInfoCircle } from 'react-icons/ai'
import { BookingStatus } from 'common/state-type'

interface AppointmentBtnMblProps {
  onClick: () => void
  isActive: boolean
  label: string
  infoText?: {
    title: string
    content: string
  }
  disabled?: boolean
  Icon?: any
  className?: string
}
export const AppointmentBtnMbl: React.FC<AppointmentBtnMblProps> = ({
  onClick,
  isActive,
  label,
  infoText,
  disabled,
  Icon,
  className,
  ...rest
}) => {
  const renderIcon = () => {
    if (isActive) {
      return Icon ? (
        <Icon size={16} color='white' className='mr-2' />
      ) : (
        <FaCheckCircle size={16} color='white' className='mr-2' />
      )
    }
    if (infoText) {
      return <div />
    } else {
      return null
    }
  }
  return (
    <button
      disabled={disabled}
      type='button'
      onClick={onClick}
      className={`flex flex-row items-center font-medium w-full text-gray-500 text-lg py-2 px-8 border-2 rounded-lg border-lawBlue
      ${infoText ? 'place-content-between items-center' : 'justify-center'}
      ${isActive && 'bg-lawBlue text-white'} ${className}`}
      {...rest}
    >
      {renderIcon()}
      {label}
      {!isActive && infoText && (
        <Popup
          trigger={
            <div>
              <AiFillInfoCircle color='#65C2D7' size={16} className='ml-1' />
            </div>
          }
          position={'left bottom'}
          on={['hover', 'focus']}
          arrow={true}
        >
          <div className='max-w-[30vw] rounded-t-lg border-b border-gray-200'>
            <div className={`py-2 px-3 rounded-t-lg border-b border-gray-200`}>
              <h5 className='font-bold text-base text-white text-center'>
                {infoText.title}
              </h5>
            </div>
            <div className='p-3 bg-white'>
              <p>{infoText.content}</p>
            </div>
          </div>
        </Popup>
      )}
    </button>
  )
}

interface FileUploadBtnMblProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClick: () => void
  btnClass?: string
  value: any
  label: string
  fileUploadRef: React.RefObject<HTMLInputElement>
  infoText?: {
    title: string
    content: string
  }
  isActive: boolean
}

export const FileUploadBtnMbl: React.FC<FileUploadBtnMblProps> = ({
  onChange,
  onClick,
  isActive,
  value,
  label,
  fileUploadRef,
  infoText,
  disabled,
  btnClass,
  ...rest
}) => {
  return (
    <div>
      <input
        type='file'
        name='file'
        onChange={onChange}
        style={{ display: 'none' }}
        ref={fileUploadRef}
      />
      <div className='flex'>
        <AppointmentBtnMbl
          onClick={onClick}
          infoText={infoText}
          disabled={disabled}
          isActive={isActive}
          label={label}
        />
      </div>
      <span className='text-sm block pt-1'>
        {value && typeof value === 'string'
          ? Utils.fileNameFromPath(value)
          : value && typeof value === 'object'
          ? value.name
          : ''}
      </span>
    </div>
  )
}
