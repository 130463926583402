import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'

type UseBarristerBookings = {
  barristerBookings: AppState['barristerBookings']
  barristerBookingsLoading: AppState['barristerBookingsLoading']
  barristerBookingsError: AppState['barristerBookingsError']
  getBarristerBookings: (
    data: RequestTypes['getBarristerBookings'],
    callbacks?: Callbacks,
  ) => void
}

export default function useBarristerBookings(): UseBarristerBookings {
  const {
    barristerBookings,
    barristerBookingsLoading,
    barristerBookingsError,
  } = useSelector((state: AppState) => ({
    barristerBookings: state.barristerBookings,
    barristerBookingsLoading: state.barristerBookingsLoading,
    barristerBookingsError: state.barristerBookingsError,
  }))
  const dispatch = useDispatch()

  const getBarristerBookings = useCallback(
    (data: RequestTypes['getBarristerBookings'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getBarristerBookings(data, callbacks))
    },
    [dispatch],
  )

  return {
    barristerBookings,
    barristerBookingsLoading,
    barristerBookingsError,
    getBarristerBookings,
  }
}
