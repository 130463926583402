import React from "react";

interface BookingPopupProps {}

const BookingPopup: React.FC<BookingPopupProps> = ({ children }) => {
  return (
    <div className='flex flex-col pb-3 rounded-lg shadow-lg shadow-slate-600 bg-white w-[95.16vw] right-[2.89vw] md:w-[417px] h-[100vh] md:h-[652px] absolute md:right-[3.30vw] z-10 mt-2 overflow-y-scroll'>
      {children}
    </div>
  )
};

export default BookingPopup;
