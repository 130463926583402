export type AuthState = {
  email: string
  password?: string
}

export const encodeAuthState = (state: AuthState) => {
  return window.btoa(JSON.stringify(state))
}

export const decodeAuthState = (): Partial<AuthState> => {
  const searchParams = new URLSearchParams(window.location.search)
  const state = searchParams.get('state')

  if (!state) {
    return
  }

  try {
    return JSON.parse(window.atob(state))
  } catch (err) {
    console.error(err)
  }

  return {}
}
