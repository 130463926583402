import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UselegalDocumentTemplateFilters = {
  legalDocumentTemplateFilters?: AppState['legalDocumentTemplateFilters']
  legalDocumentTemplateFiltersLoading: AppState['legalDocumentTemplateFiltersLoading']
  legalDocumentTemplateFiltersError: AppState['legalDocumentTemplateFiltersError']
  getLegalDocumentTemplateFilters: (
    data: RequestTypes['getLegalDocumentTemplateFilters'],
    callbacks?: Callbacks,
  ) => void
}

export default function useLegalDocumentTemplateFilters(): UselegalDocumentTemplateFilters {
  const {
    legalDocumentTemplateFilters,
    legalDocumentTemplateFiltersLoading,
    legalDocumentTemplateFiltersError,
  } = useSelector((state: AppState) => ({
    legalDocumentTemplateFilters: state.legalDocumentTemplateFilters,
    legalDocumentTemplateFiltersLoading:
      state.legalDocumentTemplateFiltersLoading,
    legalDocumentTemplateFiltersError: state.legalDocumentTemplateFiltersError,
  }))

  const dispatch = useDispatch()

  const getLegalDocumentTemplateFilters = useCallback(
    (
      data: RequestTypes['getLegalDocumentTemplateFilters'],
      callbacks?: Callbacks,
    ) => {
      return dispatch(
        AppActions.getLegalDocumentTemplateFilters(data, callbacks),
      )
    },
    [dispatch],
  )

  return {
    legalDocumentTemplateFilters,
    legalDocumentTemplateFiltersLoading,
    legalDocumentTemplateFiltersError,
    getLegalDocumentTemplateFilters,
  }
}
