import React, { useState, useEffect } from 'react'
import { SortBy } from 'common/state-type'
import { BiSortDown, BiSortUp } from 'react-icons/bi'
import { FaChevronDown } from 'react-icons/fa'
import { lawyerType } from './FilterSection'

const DropdownItem = ({ onClick, label }) => {
  return (
    <li className='w-full'>
      <button
        onClick={onClick}
        className='dropdown-item text-sm py-2 px-4 font-normal text-gray-700 hover:bg-gray-100 w-full text-left'
      >
        {label}
      </button>
    </li>
  )
}

interface SortDropdownProps {
  getSortBy: (sortBy: SortBy, direction: 'ASC' | 'DESC') => void
  listType: lawyerType
  isLoading: boolean
}
const SortDropdown: React.FC<SortDropdownProps> = ({
  getSortBy,
  listType,
  isLoading,
}) => {
  const [direction, setDirection] = useState<'ASC' | 'DESC'>('ASC')
  const [sortBy, setSortBy] = useState<{ value: SortBy; label: string }>(null)

  useEffect(() => {
    setDirection('ASC')
    setSortBy(null)
  }, [listType])

  const handleChooseSortBy = (
    value: SortBy,
    label: string,
    order?: 'ASC' | 'DESC',
  ) => {
    setSortBy({ value, label })
    order && setDirection(order)
    getSortBy(value, order)
  }

  return (
    <div className='flex justify-center items-center w-fit rounded shadow-md ml-4 mt-4 px-2'>
      {direction === 'DESC' && (
        <BiSortUp
          size={22}
          color={direction === 'DESC' ? '#5858DF' : '#949494'}
          onClick={
            isLoading
              ? null
              : () => handleChooseSortBy(sortBy.value, sortBy.label, 'ASC')
          }
        />
      )}
      {direction === 'ASC' && (
        <BiSortDown
          size={22}
          color={direction === 'ASC' ? '#5858DF' : '#949494'}
          onClick={
            isLoading
              ? null
              : () => handleChooseSortBy(sortBy.value, sortBy.label, 'DESC')
          }
        />
      )}
      <div>
        <div className='dropdown relative'>
          <button
            disabled={isLoading}
            className='dropdown-toggle px-3 py-2.5 text-black text-sm font-medium transition duration-150 ease-in-out flex items-center'
            type='button'
            id='sortDropdown'
            data-bs-toggle='dropdown'
            aria-expanded='false'
          >
            {sortBy ? `${sortBy?.label} (${direction}) ` : 'Sort by'}
            <FaChevronDown className='ml-2' />
          </button>

          {listType === lawyerType.OFFICES ? (
            <ul
              className='dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none'
              aria-labelledby='sortDropdown'
            >
              <DropdownItem
                onClick={() => {
                  handleChooseSortBy(SortBy.PRICE_OFFICE, 'Price')
                }}
                label='Price'
              />
              <DropdownItem
                onClick={() => {
                  handleChooseSortBy(SortBy.NAME_OFFICE, 'Name')
                }}
                label='Name'
              />
            </ul>
          ) : (
            <ul
              className='dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none'
              aria-labelledby='sortDropdown'
            >
              <DropdownItem
                onClick={() => {
                  handleChooseSortBy(SortBy.PRICE, 'Price')
                }}
                label='Price'
              />
              {/* <DropdownItem
              onClick={() => {
                handleChooseSortBy(SortBy.DISTANCE, 'Distance')
              }}
              label='Distance'
            /> */}
              <DropdownItem
                onClick={() => {
                  handleChooseSortBy(SortBy.RATING, 'Rating')
                }}
                label='Rating'
              />
              <DropdownItem
                onClick={() => {
                  handleChooseSortBy(SortBy.NAME, 'Name')
                }}
                label='Name'
              />
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default SortDropdown
