import { ChangeEvent, useCallback, useEffect } from 'react'
import useLegalDocumentTemplateFilters from 'common/providers/useLegalDocumentTemplateFilters'
import {
  useLegalDocumentTemplateSearch,
  SORT_OPTIONS,
} from 'common/hooks/useLegalDocumentTemplateSearch'
import useDocumentFilters from 'common/providers/useDocumentFilters'
import humanize from 'humanize'
import vectorBgLarge from '../../../assets/vector-bg-large.png'
import legalDocumentIcon from '../../../assets/legal-document.svg'
import { InputDropdown } from 'common/components/Inputs/InputDropdown'
import { Input } from 'common/components/Inputs/Input'
import { InputWrapper } from 'common/components/Inputs/InputWrapper'
import cn from 'classnames'
import cleanDeep from 'clean-deep'
import qs from 'qs'
import { BaseContainer } from '../../BaseContainer'
import Constants from 'common/utils/constants'

type LegalDocumentsProps = {
  pageSize?: number
  isStandalonePage?: boolean
}

const SHOW_MORE_BUTTON_CLASS_NAME =
  'cursor-pointer select-none rounded-full border border-white text-white text-lg font-bold py-2 px-8 whitespace-nowrap bg-lue-purple-600 transition-colors hover:bg-white hover:text-lue-purple-600'

const DEFAULT_DOCUMENT_FILTER = { name: 'All Templates' }

export const LegalDocuments = ({
  pageSize = 8,
  isStandalonePage,
}: LegalDocumentsProps) => {
  const {
    results,
    searchValue,
    sortOption,
    setSortOption,
    documentFilter,
    setDocumentFilter,
    selectedType,
    loadMore,
    setSelectedType,
    hasNext,
    updateSearchValue,
  } = useLegalDocumentTemplateSearch({
    pageSize,
  })

  const { legalDocumentTemplateFilters, getLegalDocumentTemplateFilters } =
    useLegalDocumentTemplateFilters()

  const { documentFilters, getDocumentFilters } = useDocumentFilters()

  useEffect(() => {
    getDocumentFilters({})
    getLegalDocumentTemplateFilters({
      is_template: Constants.isAvvokaEnabled,
    })
  }, [])

  const documentFilterOptions = [
    DEFAULT_DOCUMENT_FILTER,
    ...(documentFilters ?? []),
  ].map((documentFilter) => ({
    value: documentFilter.id,
    label: documentFilter.name,
  }))

  const onSortDropdownSelect = (value: string) => {
    setSortOption(SORT_OPTIONS.find((filter) => filter.value === value))
  }

  const onFilterDropdownSelect = (value: string) => {
    setDocumentFilter(value)
  }

  const handleSearchInputChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      updateSearchValue(evt.target.value)
    },
    [],
  )

  const onTypeClick = useCallback((id) => {
    setSelectedType(id)
  }, [])

  // When first mounted load the initial filters from query params.
  useEffect(() => {
    const initialFilters =
      qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      }) ?? {}

    updateSearchValue(initialFilters.title ? String(initialFilters.title) : '')

    setSelectedType(
      initialFilters.type ? String(initialFilters.type) : undefined,
    )

    setSortOption(
      SORT_OPTIONS.find((option) => option.value === initialFilters.sort) ??
        SORT_OPTIONS[0],
    )
  }, [])

  return (
    <section
      className={cn(
        'py-6 lg:pt-12 overflow-hidden bg-cover bg-top bg-[length:200%] md:bg-[length:100%]',
        isStandalonePage ? 'pb-12' : 'pb-[220px] md:pb-[150px] lg:pb-[180px]',
      )}
      style={{
        backgroundImage: `url('${vectorBgLarge}')`,
        minHeight: isStandalonePage ? '100vh' : 'auto',
      }}
    >
      <BaseContainer size='lg' className='grid lg:grid-cols-12'>
        <div className='flex flex-col overflow-hidden lg:col-span-6'>
          <h2 className='text-white font-bold text-2xl lg:text-3xl text-center lg:text-left'>
            Create, share, negotiate & sign in minutes
          </h2>
          <ul className='text-white flex flex-row text-lg lg:text-xl mt-auto mb-3 lg:mb-0 overflow-auto no-scroll-bar lg:flex-wrap'>
            {legalDocumentTemplateFilters?.types.map(
              ({ label, type, count }, i) => (
                <li key={label} className='block'>
                  <button
                    type='button'
                    className={cn(
                      'cursor-pointer p-2 whitespace-nowrap mt-4 select-none',
                      selectedType === type?.id &&
                        'border-basic-blue border-b-[6px]',
                      legalDocumentTemplateFilters.length !== i - 1 && 'mr-6',
                    )}
                    onClick={() => {
                      onTypeClick(type?.id)
                    }}
                  >
                    {`${label} (${humanize.numberFormat(count, 0)})`}
                  </button>
                </li>
              ),
            )}
          </ul>
        </div>
        <div className='flex flex-col items-end mt-4 lg:mt-0 lg:col-span-6'>
          <InputWrapper className='w-full lg:w-[100%] mb-6'>
            <Input
              placeholder='Search'
              className='px-4'
              onChange={handleSearchInputChange}
              value={searchValue}
            />
          </InputWrapper>
          <div className='mt-auto w-full md:w-auto flex flex-col md:flex-row lg:flex-col gap-y-6 md:gap-y-0 md:gap-x-4 lg:gap-y-6 lg:gap-x-0 2xl:flex-row 2xl:gap-y-0 2xl:gap-x-6'>
            <InputDropdown
              isLabelInline
              isLabelVisible
              className='flex-1 text-white font-bold text-lg'
              name='filter'
              placeholder='Select...'
              label='Filter by'
              value={documentFilter ?? DEFAULT_DOCUMENT_FILTER.name}
              options={documentFilterOptions}
              onSelect={onFilterDropdownSelect}
            />
            <InputDropdown
              isLabelInline
              isLabelVisible
              className='flex-1 text-white font-bold text-lg'
              name='sort'
              placeholder='Select...'
              label='Sort by'
              value={sortOption.value}
              options={SORT_OPTIONS}
              onSelect={onSortDropdownSelect}
            />
          </div>
        </div>
      </BaseContainer>
      <BaseContainer size='lg'>
        <ul className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-8 lg:mt-10'>
          {results.map((legalDocument) => (
            <li key={legalDocument.id}>
              <a
                href={`/legal-documents/${legalDocument.id}`}
                className='flex flex-col h-full rounded-lg overflow-hidden border border-white border-2 cursor-pointer'
              >
                <div className='bg-lue-blue-300 flex items-center justify-center py-14 px-6'>
                  <img src={legalDocumentIcon} alt='' width='52' height='62' />
                </div>
                <div className='bg-white px-4 pt-4 pb-3 flex-grow flex flex-col'>
                  <h3 className='font-medium text-xl xl:text-2xl text-b33'>
                    {legalDocument.title}
                  </h3>
                  <p className='mt-1 text-lawGrey flex-grow line-clamp-4'>
                    {legalDocument.description}
                  </p>
                  <div className='mt-4'>
                    <button
                      type='button'
                      className='tracking-wide text-basic-blue text-sm font-bold'
                    >
                      BUY TEMPLATE
                    </button>
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
        {!!hasNext && (
          <div className='flex items-center justify-center mt-8'>
            {isStandalonePage ? (
              <a
                type='button'
                className={SHOW_MORE_BUTTON_CLASS_NAME}
                onClick={loadMore}
              >
                Show more
              </a>
            ) : (
              <a
                href={`/legal-documents?${qs.stringify(
                  cleanDeep({
                    title: searchValue,
                    sort: sortOption.value,
                    type: selectedType,
                  }),
                )}`}
                className={SHOW_MORE_BUTTON_CLASS_NAME}
              >
                Show more
              </a>
            )}
          </div>
        )}
      </BaseContainer>
    </section>
  )
}
