import moment from 'moment'
import { useFormik } from 'formik'
import React, { useRef, useState } from "react";
import { FaRegClock, FaCalendarAlt, FaTimesCircle } from "react-icons/fa";

import {
  TextInput,
  StatusTree,
  ThemedButton,
  FileUploadBtn,
} from "./AppointmentBase";
import useBooking from "common/providers/useBooking";
import AddReviewToAppointment from "./AddReviewToAppointment";
import { AccountType, Booking, BookingStatus } from "common/state-type";

interface AppointmentDetailProps {
  selectedAppointment: Booking;
  theme: {
    hex: string;
    class: string;
  };
  handleClose: () => void;
}
const AppointmentDetail: React.FC<AppointmentDetailProps> = ({
  selectedAppointment,
  handleClose,
  theme,
}) => {
  const photoIdUpload = useRef(null);
  const proofOfAddressUpload = useRef(null);
  const { updateBooking, bookingSaving } = useBooking();

  const [isReviewActive, setIsReviewActive] = useState(false);
  const isExpired = selectedAppointment.status === BookingStatus.EXPIRED;
  const isCompleted = selectedAppointment.status === BookingStatus.COMPLETE;
  const isCancelled = selectedAppointment.status === BookingStatus.DECLINED

  const { values, handleChange, handleSubmit, setFieldValue, errors } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        notes: selectedAppointment.notes ?? '',
        guests: selectedAppointment.guests,
        is_video: selectedAppointment.is_video,
        accessibility_requirements:
          selectedAppointment.accessibility_requirements ?? '',
        identification_document:
          selectedAppointment.identification_document as File,
        proof_of_address: selectedAppointment.proof_of_address as File,
      },
      onSubmit: (values) => {
        updateBooking({
          ...values,
          id: selectedAppointment.id,
          lawyerType: selectedAppointment.lawyer
            ? AccountType.LAWYER
            : AccountType.BARRISTER,
        })
      },
    })

  const handelAddReview = () => {
    setIsReviewActive(true)
  }

  if (isReviewActive) {
    return (
      <AddReviewToAppointment
        theme={theme}
        onBackClick={() => {
          setIsReviewActive(false)
        }}
        lawyerType={selectedAppointment.lawyer ? 'lawyer' : 'barrister'}
        lawyerId={
          selectedAppointment?.lawyer?.id ?? selectedAppointment?.barrister?.id
        }
      />
    )
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className='flex place-content-end pr-10' onClick={handleClose}>
        <FaTimesCircle size={30} color={theme.hex} />
      </div>
      <div
        className={`flex flex-1 flex-row px-[2.5vw] w-full pt-6 ${
          isExpired || (isCompleted && 'opacity-60')
        }`}
      >
        <div className='flex flex-col pr-5'>
          <div className='flex flex-row items-center text-slate-500 font-bold text-2xl pb-4'>
            <FaRegClock size={26} />
            <span className='block pl-2'>
              {moment(selectedAppointment?.start).format('dddd DD MMMM YYYY')}
            </span>
          </div>
          <div className='flex flex-row items-center text-slate-500 font-bold text-2xl mb-8'>
            <FaCalendarAlt size={26} />
            <span className='block pl-2'>
              {moment(selectedAppointment?.start).format('HH:mm')}
              {' - '}
              {moment(selectedAppointment?.end).format('HH:mm')}
            </span>
          </div>
          <TextInput
            disabled={isExpired || isCompleted || isCancelled}
            name='notes'
            value={values.notes}
            onChange={handleChange}
            label='Notes'
            isMultiline
            borderColor={theme.hex}
          />
          {values.guests?.length > 0 && (
            <label className='block mb-2 text-xl font-medium text-black mt-4'>
              Guest Details
            </label>
          )}
          {values.guests?.map((guest, index) => (
            <div key={index} className='pb-2'>
              <TextInput
                disabled={
                  isExpired ||
                  isCancelled ||
                  isCompleted ||
                  selectedAppointment.guests[index] !== undefined
                }
                onChange={(e) => {
                  setFieldValue(`guests[${index}]`, e.target.value)
                }}
                label='Email'
                borderColor={theme.hex}
                name={`guests[${index}]`}
                value={values.guests[index]}
              />
            </div>
          ))}
          {!values.guests ||
            (values.guests?.length <= 1 && (
              <button
                disabled={isCompleted || isExpired || isCancelled}
                type='button'
                onClick={(e) => {
                  setFieldValue('guests', [...values.guests, ''])
                }}
                className={`font-medium self-center rounded-lg text-white mt-8 text-lg py-0.5 px-8 mb-5 ${theme.class}`}
              >
                + Guest
              </button>
            ))}
          <TextInput
            name='accessibility_requirements'
            label='Accessibility Requirements'
            isMultiline
            value={values.accessibility_requirements}
            onChange={handleChange}
            borderColor={theme.hex}
            disabled={isCompleted || isExpired || isCancelled}
          />
        </div>
        <div className='flex flex-1 flex-col relative mt-16'>
          <span className='block pl-2 text-slate-500 font-bold text-2xl pb-12 text-center'>
            Appointment Status
          </span>
          <StatusTree
            themeColor={theme}
            hasPhotoId={!!selectedAppointment.identification_document}
            hasProofOfAddress={!!selectedAppointment.proof_of_address}
            guestsArePresent={selectedAppointment.guests?.length > 0}
            meetingStatus={selectedAppointment.status}
          />
          <div className='grid grid-cols-2 gap-x-8 gap-y-4 pt-16'>
            <ThemedButton
              disabled
              btnClass={`${
                selectedAppointment.is_video
                  ? theme.class
                  : 'bg-slate-300 text-black'
              } `}
            >
              Join Video Call
            </ThemedButton>
            ; ;
            <FileUploadBtn
              infoText={{
                title: 'Photo ID  Examples ',
                content:
                  'Please note we accept the following forms of ID : Passport Driving Licence',
              }}
              label='Upload Photo ID'
              fileUploadRef={photoIdUpload}
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setFieldValue('identification_document', e.target.files[0])
                }
              }}
              onClick={() => {
                photoIdUpload.current.click()
              }}
              theme={theme}
              value={values.identification_document}
              disabled={isCompleted || isExpired || isCancelled}
            />
            {isCompleted ? (
              <ThemedButton
                btnClass={'bg-[#5858DF] text-black z-10'}
                onClick={handelAddReview}
              >
                Leave a Review
              </ThemedButton>
            ) : (
              <ThemedButton
                theme={theme}
                infoText={{
                  title: 'Audio Call ',
                  content:
                    'Please note the Legal Representative will call you directly.',
                }}
                disabled
                btnClass={`${
                  !selectedAppointment.is_video
                    ? theme.class
                    : 'bg-slate-300 text-black'
                } `}
              >
                Audio Call
              </ThemedButton>
            )}
            ;
            <FileUploadBtn
              infoText={{
                title: 'Proof of Address Examples',
                content:
                  'Please note we accept the following forms of ID Untility bill. Credit card statement. Bank statement/letter. Mortgage statement Letter issued by Local authority Payslip. Home insurance policy.',
              }}
              label='Upload Proof of Address'
              fileUploadRef={proofOfAddressUpload}
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setFieldValue('proof_of_address', e.target.files[0])
                }
              }}
              onClick={() => {
                proofOfAddressUpload.current.click()
              }}
              theme={theme}
              value={values.proof_of_address}
              disabled={isCompleted || isExpired || isCancelled}
            />
          </div>
        </div>
      </div>
      {isCompleted || isExpired ? null : (
        <div className='flex justify-center pt-7 flex-1'>
          <ThemedButton
            type='submit'
            disabled={bookingSaving}
            btnClass={`${theme.class} w-full`}
          >
            Apply Changes
          </ThemedButton>
        </div>
      )}
    </form>
  )
};

export default AppointmentDetail
