import React, { useState } from 'react'
import { InputHTMLAttributes, ReactNode } from 'react'
import { InputErrorText } from './InputErrorText'
import cn from 'classnames'

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  label: ReactNode
  id: string
  className?: string
  error?: string
}

const Checkbox = ({ checked, onChange, disabled }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      className={cn(
        'w-[16px] h-[16px] border rounded flex items-center justify-center transition-all duration-200',
        {
          'bg-[#0C5F71] border-[#0C5F71]': checked && !disabled,
          'bg-white border-[#0C5F71]': !checked && !disabled && isHovered,
          'bg-white border-[#818285]': !checked && !disabled && !isHovered,
          'bg-[#F4F4F4] border-[#A0ABBE]': disabled,
        }
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {checked && !disabled && (
        <svg width="12" height="8" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 5L5 9L13 1" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )}
    </div>
  )
}

export const CheckboxGroup = ({
  label,
  id,
  className,
  error,
  checked,
  onChange,
  disabled,
  ...restProps
}: CheckboxProps) => {
  return (
    <div className={className}>
      <div className="flex flex-colmn flex-wrap items-center">
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className="hidden"
          {...restProps}
        />
        <label htmlFor={id} className="flex  items-center cursor-pointer">
          <Checkbox checked={checked} onChange={onChange} disabled={disabled} />
          <span className={cn("ml-2", { 'text-[#A0ABBE]': disabled })}>
            {label}
          </span>
        </label>
        {error && <InputErrorText>{error}</InputErrorText>}
      </div>

    </div>
  )
}