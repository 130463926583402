import cn from 'classnames'
import { DropdownArrowIcon } from '../Icons/DropdownArrowIcon'

type DropdownArrowProps = {
  className?: string
  size?: number
}

export const DropdownArrow = ({ className }: DropdownArrowProps) => (
  <div className={cn('pointer-events-none', className)}>
    <div className='w-[12px] lg:w-[20px] flex justify-end'>
      <DropdownArrowIcon />
    </div>
  </div>
)
