import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'

type UseDetailedBarristerProfile = {
  detailedBarristerProfile: AppState['detailedBarristerProfile']
  detailedBarristerProfileError: AppState['detailedBarristerProfileError']
  detailedBarristerProfileLoading: AppState['detailedBarristerProfileLoading']
  getDetailedBarristerProfile: (
    data: RequestTypes['getDetailedBarristerProfile'],
    callbacks?: Callbacks,
  ) => void
}

export default function useDetailedBarristerProfile(): UseDetailedBarristerProfile {
  const {
    detailedBarristerProfile,
    detailedBarristerProfileLoading,
    detailedBarristerProfileError,
  } = useSelector((state: AppState) => ({
    detailedBarristerProfile: state.detailedBarristerProfile,
    detailedBarristerProfileLoading: state.detailedBarristerProfileLoading,
    detailedBarristerProfileSaving: state.detailedBarristerProfileSaving,
    detailedBarristerProfileError: state.detailedBarristerProfileError,
  }))
  const dispatch = useDispatch()

  const getDetailedBarristerProfile = useCallback(
    (
      data: RequestTypes['getDetailedBarristerProfile'],
      callbacks?: Callbacks,
    ) => {
      return dispatch(AppActions.getDetailedBarristerProfile(data, callbacks))
    },
    [dispatch],
  )

  return {
    detailedBarristerProfile,
    detailedBarristerProfileLoading,
    detailedBarristerProfileError,
    getDetailedBarristerProfile,
  }
}
