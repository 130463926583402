export default function () {
    return (
        <div
            className="px-20 py-12" 
            dangerouslySetInnerHTML={{__html:`
        
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Verdana;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
@font-face
	{font-family:"Arial Unicode MS";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:Cambria;
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Tahoma;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
@font-face
	{font-family:"\@Arial Unicode MS";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:12.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoToc1, li.MsoToc1, div.MsoToc1
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:5.0pt;
	margin-left:0cm;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
a:link, span.MsoHyperlink
	{font-family:"Arial",sans-serif;
	color:black;
	font-style:italic;
	text-decoration:underline;}
p.TitleClause, li.TitleClause, div.TitleClause
	{mso-style-name:"Title Clause";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:36.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	line-height:15.0pt;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.DescriptiveHeading, li.DescriptiveHeading, div.DescriptiveHeading
	{mso-style-name:DescriptiveHeading;
	mso-style-link:"DescriptiveHeading Char";
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:18.0pt;
	margin-left:0cm;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
span.DescriptiveHeadingChar
	{mso-style-name:"DescriptiveHeading Char";
	mso-style-link:DescriptiveHeading;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.Parasubclause1, li.Parasubclause1, div.Parasubclause1
	{mso-style-name:"Para subclause 1\,BIWS Heading 2";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-align:justify;
	line-height:15.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Untitledsubclause1, li.Untitledsubclause1, div.Untitledsubclause1
	{mso-style-name:"Untitled subclause 1";
	margin-top:14.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	line-height:15.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Untitledsubclause2, li.Untitledsubclause2, div.Untitledsubclause2
	{mso-style-name:"Untitled subclause 2";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:77.75pt;
	text-align:justify;
	text-indent:-28.05pt;
	line-height:15.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Untitledsubclause3, li.Untitledsubclause3, div.Untitledsubclause3
	{mso-style-name:"Untitled subclause 3";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:113.75pt;
	text-align:justify;
	text-indent:-28.8pt;
	line-height:15.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Untitledsubclause4, li.Untitledsubclause4, div.Untitledsubclause4
	{mso-style-name:"Untitled subclause 4";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:144.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	line-height:15.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.HeadingLevel2, li.HeadingLevel2, div.HeadingLevel2
	{mso-style-name:"Heading Level 2";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	text-align:justify;
	line-height:15.0pt;
	page-break-after:avoid;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.NoNumTitle-Clause, li.NoNumTitle-Clause, div.NoNumTitle-Clause
	{mso-style-name:"No Num Title - Clause";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:36.0pt;
	text-align:justify;
	line-height:15.0pt;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
span.DefTerm
	{mso-style-name:DefTerm;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.NoNumUntitledsubclause1, li.NoNumUntitledsubclause1, div.NoNumUntitledsubclause1
	{mso-style-name:"No Num Untitled subclause 1";
	margin-top:14.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-align:justify;
	line-height:15.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
 /* Page Definitions */
 @page WordSection1
	{size:612.0pt 792.0pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;}
@page WordSection2
	{size:612.0pt 792.0pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection2
	{page:WordSection2;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=EN-GB link=black vlink=black style='word-wrap:break-word'>

<div class=WordSection1>

<p class=HeadingLevel2>CONTENTS</p>

<p class=HeadingLevel2>____________________________________________________________</p>

<p class=HeadingLevel2>CLAUSE</p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000000"><span style='color:black'>1.</span><span
style='font-family:"Calibri",sans-serif;color:black'>    </span><span
style='color:black'><span style='color:black'>About us................................................................................................................................... </span></span><span style='color:black'><span style='color:black'>1</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000001"><span style='color:black'>2.</span><span
style='font-family:"Calibri",sans-serif;color:black'>    </span><span
style='color:black'><span style='color:black'>Our contract with you............................................................................................................... </span></span><span style='color:black'><span style='color:black'>1</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000002"><span style='color:black'>3.</span><span
style='font-family:"Calibri",sans-serif;color:black'>    </span><span
style='color:black'><span style='color:black'>Placing an order and its
acceptance....................................................................................... </span></span><span style='color:black'><span style='color:black'>2</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000003"><span style='color:black'>4.</span><span
style='font-family:"Calibri",sans-serif;color:black'>    </span><span
style='color:black'><span style='color:black'>Cancelling your order and
obtaining a refund......................................................................... </span></span><span style='color:black'><span style='color:black'>2</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000004"><span style='color:black'>5.</span><span
style='font-family:"Calibri",sans-serif;color:black'>    </span><span
style='color:black'><span style='color:black'>Our services............................................................................................................................. </span></span><span style='color:black'><span style='color:black'>3</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000005"><span style='color:black'>6.</span><span
style='font-family:"Calibri",sans-serif;color:black'>    </span><span
style='color:black'><span style='color:black'>Your obligations....................................................................................................................... </span></span><span style='color:black'><span style='color:black'>4</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000006"><span style='color:black'>7.</span><span
style='font-family:"Calibri",sans-serif;color:black'>    </span><span
style='color:black'><span style='color:black'>Services in UK only.................................................................................................................. </span></span><span style='color:black'><span style='color:black'>5</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000007"><span style='color:black'>8.</span><span
style='font-family:"Calibri",sans-serif;color:black'>    </span><span
style='color:black'><span style='color:black'>Charges................................................................................................................................... </span></span><span style='color:black'><span style='color:black'>5</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000008"><span style='color:black'>9.</span><span
style='font-family:"Calibri",sans-serif;color:black'>    </span><span
style='color:black'><span style='color:black'>How to pay............................................................................................................................... </span></span><span style='color:black'><span style='color:black'>6</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000009"><span style='color:black'>10.</span><span
style='font-family:"Calibri",sans-serif;color:black'>      </span><span
style='color:black'><span style='color:black'>Complaints........................................................................................................................... </span></span><span style='color:black'><span style='color:black'>7</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000010"><span style='color:black'>11.</span><span
style='font-family:"Calibri",sans-serif;color:black'>      </span><span
style='color:black'><span style='color:black'>Intellectual property rights.................................................................................................... </span></span><span style='color:black'><span style='color:black'>7</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000011"><span style='color:black'>12.</span><span
style='font-family:"Calibri",sans-serif;color:black'>      </span><span
style='color:black'><span style='color:black'>How we may use your personal
information....................................................................... </span></span><span style='color:black'><span style='color:black'>7</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000012"><span style='color:black'>13.</span><span
style='font-family:"Calibri",sans-serif;color:black'>      </span><span
style='color:black'><span style='color:black'>Limitation of liability: YOUR
ATTENTION IS PARTICULARLY DRAWN TO THIS CLAUSE.           </span></span><span style='color:black'><span style='color:black'>7</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000013"><span style='color:black'>14.</span><span
style='font-family:"Calibri",sans-serif;color:black'>      </span><span
style='color:black'><span style='color:black'>Confidentiality....................................................................................................................... </span></span><span style='color:black'><span style='color:black'>9</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000014"><span style='color:black'>15.</span><span
style='font-family:"Calibri",sans-serif;color:black'>      </span><span
style='color:black'><span style='color:black'>Termination, consequences of
termination and survival..................................................... </span></span><span style='color:black'><span style='color:black'>9</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000015"><span style='color:black'>16.</span><span
style='font-family:"Calibri",sans-serif;color:black'>      </span><span
style='color:black'><span style='color:black'>Events outside our control................................................................................................. </span></span><span style='color:black'><span style='color:black'>10</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000016"><span style='color:black'>17.</span><span
style='font-family:"Calibri",sans-serif;color:black'>      </span><span
style='color:black'><span style='color:black'>Non-solicitation................................................................................................................... </span></span><span style='color:black'><span style='color:black'>11</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000017"><span style='color:black'>18.</span><span
style='font-family:"Calibri",sans-serif;color:black'>      </span><span
style='color:black'><span style='color:black'>Communications between us............................................................................................. </span></span><span style='color:black'><span style='color:black'>11</span></span></a></span></span></p>

<p class=MsoToc1><span class=MsoHyperlink><span style='color:black'><a
href="#_Toc256000018"><span style='color:black'>19.</span><span
style='font-family:"Calibri",sans-serif;color:black'>      </span><span
style='color:black'><span style='color:black'>General............................................................................................................................... </span></span><span style='color:black'><span style='color:black'>11</span></span></a></span></span></p>

<p class=HeadingLevel2>&nbsp;</p>

</div>

<b><span style='font-size:14.0pt;font-family:"Arial",sans-serif;color:black'><br
clear=all style='page-break-before:always'>
</span></b>

<div class=WordSection2>

<p class=HeadingLevel2>&nbsp;</p>

<p class=DescriptiveHeading><span lang=EN-US>AGREED TERMS</span></p>

<p class=NoNumTitle-Clause><a name=a585916>Your attention is particularly drawn
to the provisions of <span class=MsoHyperlink><span style='color:black'><span
style='color:black'>clause 13</span></span></span> (Limitation of liability).</a></p>

<p class=TitleClause>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a name=a905165></a><a name="_Toc256000000">About us</a></p>

<p class=Untitledsubclause1><a name=a555308>1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><b>Company details.</b> Legal Utopia Limited (company number 10909418) (<b>we</b>
and <b>us</b>) is a company registered in England and Wales and our registered
office is at 3<sup>rd</sup> Floor, 86-90 Paul Street, London, EC2A 4NE. Our VAT
number is GB 404 391 911. We operate the website legalutopia.co.uk and its
subdomains (Our “Site”).  </a></p>

<p class=Untitledsubclause1><a name=a891579><b>1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Contacting us.</b> To contact us, telephone our customer service
team at 020 3918 5630 or email us at customerservice@legalutopia.co.uk. How to
give us formal notice of any matter under the Contract is set out in </a>clause
18.2.</p>

<p class=Untitledsubclause1><a name=a975392><b>1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Our data protection registration. </b>Legal Utopia Limited is
registered with the Information Commissioner’s Office under registration
reference ZA 324 233.<b> </b></a></p>

<p class=TitleClause>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a name=a606823></a><a name="_Toc256000001">Our contract with you</a></p>

<p class=Untitledsubclause1><a name=a538773>2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><b>Our contract.</b> These terms and conditions (<b>Terms</b>) apply to
the order by you and supply of Services by us to you (<span class=DefTerm><span
style='color:black'><span style='color:black'>Contract</span></span></span>).
They apply to the exclusion of any other terms that you seek to impose or
incorporate, or which are implied by law, trade custom, practice or course of
dealing.</a></p>

<p class=Untitledsubclause1><a name=a175999>2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><b>Entire agreement.</b> The Contract is the entire agreement between
you and us in relation to its subject matter. You acknowledge that you have not
relied on any statement, promise or representation or assurance or warranty
that is not set out in the Contract.</a></p>

<p class=Untitledsubclause1><a name=a788019><b>2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Language.</b> These Terms and the Contract are made only in the
English language.</a></p>

<p class=Untitledsubclause1><a name=a625448>2.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><b>Your copy.</b> You should print off a copy of these Terms or save
them to your computer for future reference.</a></p>

<p class=TitleClause>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a name=a683230></a><a name="_Toc256000002">Placing an order and its
acceptance</a></p>

<p class=Untitledsubclause1><a name=a641125>3.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><b>Placing your order.</b> Please follow the onscreen prompts to place
your order. You may only submit an order using the method set out on the site.
Each order is an offer by you to buy the services specified in the order (<b>Services</b>)
subject to these Terms.</a></p>

<p class=Untitledsubclause1><a name=a791423>3.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><b>Correcting input errors.</b> Our order process allows you to check
and amend any errors before submitting your order to us. Please check the order
carefully before confirming it. You are responsible for ensuring that your
order and any data submitted by you is complete and accurate.</a></p>

<p class=Untitledsubclause1><a name=a710463>3.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><b>Acknowledging receipt of your order. </b>After you place your order,
you may receive an email from us acknowledging that we have received it, but
please note that this does not mean that your order has been accepted. Our
acceptance of your order will take place as described in </a>clause
3.4.</p>

<p class=Untitledsubclause1><a name=a914363>3.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><b>Accepting your order.</b> Our acceptance of your order takes place
when we send an email to you to accept it (<b>Order Confirmation</b>), at which
point and on which date (<b>Commencement Date</b>) the Contract between you and
us will come into existence. The Contract will relate only to those Services
confirmed in the Order Confirmation.</a></p>

<p class=Untitledsubclause1><a name=a696609><b>3.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>If we cannot accept your order.</b> If we are unable to supply
you with the Services for any reason, we will inform you of this by email and
we will not process your order. If you have already paid for the Services, we
will refund you the full amount.</a></p>

<p class=TitleClause>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a name=a244843></a><a name="_Toc256000003">Cancelling your order</a> and obtaining a refund</p>

<p class=Untitledsubclause1><a name=a256006>4.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>You may cancel the Contract, if you notify us as set out in </a>clause
4.2 within 1 day of your receipt of the Order Confirmation and you have not
used or benefited from our Services. You cannot cancel the Contract once we have
provider, or you have benefited from our Services, even if the 1-day period is
still running.<b> </b>You may cancel the renewal of your Contract, if you
notify us as set out in clause 4.2.
We require 14 days' notice for the cancellation of a monthly renewal.</p>

<p class=Untitledsubclause1><a name=a111615>4.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>To cancel the Contract, you must send us an email at </a><a
href="mailto:customerservice@legalutopia.co.uk"><i><span style='color:black'><span
style='color:black'>customerservice@legalutopia.co.uk</span></span></i></a>. We
will email you to confirm we have received your cancellation. </p>

<p class=Parasubclause1>You can also email us at customerservice@legalutopia.co.uk
or by post to 3<sup>rd</sup> Floor, 86-90 Paul Street, London, EC2A 4NE. If you
are emailing us or writing to us, please include details of your order to help
us to identify it. If you send us your cancellation notice by email or by post,
then your cancellation is effective from the date you send us the email or post
the letter to us. For example, you will have given us notice in time as long as
you get your letter into the last post on the last day of the cancellation
period or email us before midnight on that day.</p>

<p class=Untitledsubclause1><a name=a855733>4.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>If you cancel the Contract, we will refund you in full for the price you
paid for the Services, by the method you used for payment. We may deduct from
any refund an amount for the supply of the Services provided for the period up
to the time when you gave notice of cancellation in accordance with </a>clause
4.2. The amount we deduct will reflect the amount that has been supplied as a
proportion of the entirety of the Contract.</p>

<p class=TitleClause>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a name=a771894></a><a name="_Toc256000004">Our services</a></p>

<p class=Untitledsubclause1><a name=a900780><b>5.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Descriptions and illustrations. </b>Any descriptions or
illustrations on our site are published for the sole purpose of giving an
approximate idea of the services described in them. They will not form part of
the Contract or have any contractual force.</a> </p>

<p class=Untitledsubclause1><a name=a801660>5.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><b>Compliance with specification</b>. Subject to our right to amend the
specification (see </a>clause 5.3) we will supply the Services to you in
accordance with the specification for the Services appearing on our website at
the date of your order in all material respects. </p>

<p class=Untitledsubclause1><a name=a486908>5.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><b>Changes to specification.</b> We reserve the right to amend the
specification of the Services if required by any applicable statutory or
regulatory requirement or if the amendment will not materially affect the
nature or quality of the Services.</a></p>

<p class=Untitledsubclause1><a name=a391289>5.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><b>Reasonable care and skill.</b> We warrant to you that the Services
will be provided using reasonable care and skill.</a></p>

<p class=Untitledsubclause1><a name=a357672><b>5.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Time for performance.</b> We will use all reasonable endeavours
to meet any performance dates specified in the Order Confirmation, but any such
dates are estimates only and failure to perform the Services by such dates will
not give you the right to terminate the Contract.</a></p>

<p class=TitleClause>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a name=a182315></a><a name="_Toc256000005">Your obligations</a></p>

<p class=Untitledsubclause1><a name=a162617><b>6.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b>It is your responsibility to ensure that:</a></p>

<p class=Untitledsubclause2><a name=a261881>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>the terms of your order are complete and accurate;</a></p>

<p class=Untitledsubclause2><a name=a469612>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>you cooperate with us in all matters relating to the Services;</a></p>

<p class=Untitledsubclause2><a name=a343213>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>you provide us with such information and materials we may reasonably
require in order to supply the Services, and ensure that such information is
complete and accurate in all material respects; </a></p>

<p class=Untitledsubclause2><a name=a561872>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>you </a>maintain your availability, availability rules, calendar and
email address for receiving and accepting appointment bookings; </p>

<p class=Untitledsubclause2>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>you maintain your professional profile, ensuring, at all times, it
remains an accurate, fair, truthful and reasonable representation of your
professional qualification(s), authorisation(s), accreditation(s),
membership(s), expertise, experience, and professional abilities; </p>

<p class=Untitledsubclause2><a name=a702010>(f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>you obtain and maintain all necessary licences, permissions and consents
which may be required for the Services before the date on which the Services
are to start;</a></p>

<p class=Untitledsubclause2><a name=a114865>(g)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>you comply with all applicable laws, including professional codes of
conduct, regulations, and good practises;</a></p>

<p class=Untitledsubclause2>(h)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>you maintain and act in accordance with your professional accreditation
and regulation with either the Bar Standards Board or Solicitors Regulation
Authority with the explicit obligation to notify us of any notification to you
on a compliant, investigation, or similar event concerning your compliance with
such professional regulation;</p>

<p class=Untitledsubclause2>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>you cooperate with and report of any event of complaint in relation to
your professional services, whether or not referred or facilitated from Legal
Utopia Limited, that is made to the Legal Ombudsman or other appropriate
independent complaints body; </p>

<p class=Untitledsubclause2>(j)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>you have, and maintain, the appropriate authorisation to manage and
maintain the information and data of the law firm, chambers, or other entity in
which you work or are engaged with in providing your professional legal
services with the obligation to notify us if this changes at any time;</p>

<p class=Untitledsubclause2>(k)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>you will notify us within 15 calendar days of any referral or engagement
between you, your firm or other party (to which you may re-refer to
subsequently engage) that is obtained from our Services to you, together with
the date of engagement between you and the client; </p>

<p class=Untitledsubclause2>(l)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>you will notify us within 7 calendar days of an invoice or payment
becoming due arising from 6.1(k), together with its due date, and the amount
and due date of any commission payable to us under clause 8; and </p>

<p class=Untitledsubclause2>(m)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>you exercise your obligations without unreasonable delay and with full
and frank transparency in your disclosure or communications with us. </p>

<p class=Untitledsubclause2 style='text-indent:0cm'>&nbsp;</p>

<p class=Untitledsubclause1><a name=a262374>6.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>If our ability to perform the Services is prevented or delayed by any
failure by you to fulfil any obligation listed in </a>clause
6.1 (<span class=DefTerm><span style='color:black'><span style='color:black'>Your
Default</span></span></span>):</p>

<p class=Untitledsubclause2><a name=a667280>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>we will be entitled to suspend performance of the Services until you
remedy Your Default, and to rely on Your Default to relieve us from the
performance of the Services, in each case to the extent Your Default prevents
or delays performance of the Services. In certain circumstances Your Default
may entitle us to terminate the Contract under </a>clause
15 (Termination);</p>

<p class=Untitledsubclause2><a name=a934313>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>we will not be responsible for any costs or losses you sustain or incur
arising directly or indirectly from our failure or delay to perform the
Services; and</a></p>

<p class=Untitledsubclause2><a name=a447672>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>it will be your responsibility to reimburse us on written demand for any
costs or losses we sustain or incur arising directly or indirectly from Your
Default.</a></p>

<p class=TitleClause>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a name=a925344></a><a name="_Toc256000006">Services in UK only</a><a name=a856570></a></p>

<p class=Untitledsubclause1><b>7.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b>You may place an order for the Services from an address outside the
UK, but the order must be for performance of the Services to a UK-registered
and authorised solicitor or barrister regulated by the Bar Standards Board or
Solicitors Regulation Authority. </p>

<p class=Untitledsubclause1><b>7.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b>You must have a UK business address and/or registered office. </p>

<p class=TitleClause>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a name=a587362></a><a name="_Toc256000007">Charges</a> </p>

<p class=Untitledsubclause1><a name=a240539>8.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>In consideration of us providing the Services you must pay our charges (<b>Charges</b>)
in accordance with this </a>clause 8.</p>

<p class=Untitledsubclause1><a name=a204446>8.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>The Charges are the prices quoted on our site at the time you submit
your order. </a></p>

<p class=Untitledsubclause1><a name=a902005>8.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>If you wish to change the scope of the Services after we accept your
order, and we agree to such change, we will modify the Charges accordingly.</a></p>

<p class=Untitledsubclause1><a name=a940085>8.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>We use our best efforts to ensure that the prices stated for the
Services are correct at the time when the relevant information was entered into
the system. However, please see </a>clause 8.7
for what happens if we discover an error in the price of the Services you
ordered.</p>

<p class=Untitledsubclause1><a name=a503189>8.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Our Charges may change from time to time, but changes will not affect
any order you have already placed</a>.</p>

<p class=Untitledsubclause1><a name=a744261>8.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Our Charges are exclusive of VAT. Where VAT is payable in respect of
some or all of the Services you must pay us such additional amounts in respect
of VAT, at the applicable rate, at the same time as you pay the Charges. </a></p>

<p class=Untitledsubclause1><a name=a224969>8.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>It is always possible that, despite our best efforts, some of the
Services on our site may be incorrectly priced. Where the correct price for the
Services is less than the price stated on our site, we will charge the lower
amount and if the correct price for the Services is higher than the price
stated on our site, we will contact you, as soon as possible to inform you of
this error and we will give you the option of continuing to purchase the
Services at the correct price or cancelling your order. We will not process
your order until we have your instructions. If we are unable to contact you
using the contact details you provided during the order process, we will treat
the order as cancelled and notify you in writing. However, if we mistakenly
accept and process your order where a pricing error is obvious and
unmistakeable and could reasonably have been recognised by you as a mispricing,
we may cancel supply of the Services and refund you any sums you have paid.</a></p>

<p class=TitleClause>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a name=a448142></a><a name="_Toc256000008">How to pay</a></p>

<p class=Untitledsubclause1><a name=a343388>9.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Payment for the Services is in advance unless you accept our commission
plan. We will take your first payment upon acceptance of your order and will
take subsequent payments monthly in advance</a> unless cancelled by you.</p>

<p class=Untitledsubclause1><a name=a368569>9.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>You can pay for the Services using a debit card or credit card. We
accept </a>all major credit and debit cards.</p>

<p class=Untitledsubclause1><a name=a319178>9.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>We will send you an electronic invoice within seven days of the
beginning of the month following payment. For any failed or cancelled payments,
a £0.20 + 1.9% administration fee will be levied against the value of the
transation.  </a></p>

<p class=Untitledsubclause1><a name=a647600>9.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>If you fail to make a payment under the Contract by the due date, then,
without limiting our remedies under </a>clause
15 (Termination), you will have to pay interest on the overdue sum from the due
date until payment of the overdue sum, whether before or after judgment.
Interest under this clause 9.4 will accrue each day at 1% a year above the Bank
of England's base rate from time to time, but at 4% a year for any period when
that base rate is below 0%.</p>

<p class=TitleClause>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a name=a302157></a><a name="_Toc256000009">Complaints</a></p>

<p class=NoNumUntitledsubclause1><a name=a499366>If a problem arises or you are
dissatisfied with the Services, we have a comprehensive complaints policy, see </a>our
site.</p>

<p class=TitleClause>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a name=a258866></a><a name="_Toc256000010">Intellectual property rights</a></p>

<p class=Untitledsubclause1><a name=a735660>11.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>All intellectual property rights in or arising out of or in connection
with the Services (other than intellectual property rights in any materials
provided by you) will be owned by us. </a></p>

<p class=Untitledsubclause1><a name=a376707>11.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>We agree to grant you a fully paid-up, worldwide, non-exclusive,
royalty-free licence during the term of the Contract to copy the deliverables
specified in your order (excluding materials provided you) for the purpose of
receiving and using the Services and such deliverables in your business. You
may not sub-license, assign or otherwise transfer the rights granted in this </a>clause
11.2.</p>

<p class=Untitledsubclause1><a name=a678853>11.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>You agree to grant us a fully paid-up, non-exclusive, royalty-free,
non-transferable licence to copy and modify any materials provided by you to us
for the term of the Contract for the purpose of providing the Services to you.</a></p>

<p class=TitleClause>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a name=a115327></a><a name="_Toc256000011">How we may use your personal
information</a></p>

<p class=Untitledsubclause1><a name=a507339>12.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>We will use any personal information you provide to us to:</a></p>

<p class=Untitledsubclause2><a name=a859934>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>provide the Services; </a></p>

<p class=Untitledsubclause2><a name=a146407>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>process your payment for the Services; </a></p>

<p class=Untitledsubclause2>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>improving, developing, or enhancing our Services; and</p>

<p class=Untitledsubclause2><a name=a876982>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>inform you about similar products or services that we provide, but you
may stop receiving these at any time by contacting us.</a></p>

<p class=Untitledsubclause1><a name=a237507>12.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>We will process your personal information in accordance with our Privacy
Policy,the terms of which are incorporated into this Contract</a> and available
on our site. </p>

<p class=TitleClause>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a
name=a374023></a><a name="_Toc256000012">Limitation of liability: YOUR
ATTENTION IS PARTICULARLY DRAWN TO THIS CLAUSE.</a></p>

<p class=Untitledsubclause1><a name=a604464>13.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Nothing in the Contract limits any liability which cannot legally be
limited, including liability for:</a></p>

<p class=Untitledsubclause2><a name=a380044>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>death or personal injury caused by negligence;</a></p>

<p class=Untitledsubclause2><a name=a173326>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>fraud or fraudulent misrepresentation; and</a></p>

<p class=Untitledsubclause2><a name=a790256>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>breach of the terms implied by section 2 of the Supply of Goods and
Services Act 1982 (title and quiet possession).</a></p>

<p class=Untitledsubclause1><a name=a418831>13.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Subject to </a>clause 13.1, we will not be liable to you, whether in
contract, tort (including negligence), for breach of statutory duty, or
otherwise, arising under or in connection with the Contract for:</p>

<p class=Untitledsubclause2><a name=a136412>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>loss of profits;</a></p>

<p class=Untitledsubclause2><a name=a117226>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>loss of sales or business;</a></p>

<p class=Untitledsubclause2><a name=a440891>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>loss of agreements or contracts;</a></p>

<p class=Untitledsubclause2><a name=a93065>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>loss of anticipated savings;</a></p>

<p class=Untitledsubclause2><a name=a488274>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>loss of use or corruption of software, data or information;</a></p>

<p class=Untitledsubclause2><a name=a129281>(f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>loss of or damage to goodwill; </a></p>

<p class=Untitledsubclause2>(g)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>loss of reputation; and</p>

<p class=Untitledsubclause2><a name=a125109>(h)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>any indirect or consequential loss.</a></p>

<p class=Untitledsubclause1><a name=a747040>13.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Subject to </a>clause 13.1, our total liability to you arising under or
in connection with the Contract, whether in contract, tort (including
negligence), breach of statutory duty, or otherwise, will be limited to 100% of
the total Charges paid under the Contract.</p>

<p class=Untitledsubclause1><a name=a700964>13.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>We have given commitments as to compliance of the Services with the
relevant specification in </a>clause 5.2.
In view of these commitments, the terms implied by sections 3, 4 and 5 of the
Supply of Goods and Services Act 1982 are, to the fullest extent permitted by
law, excluded from the Contract.</p>

<p class=Untitledsubclause1><a name=a831810>13.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Unless you notify us that you intend to make a claim in respect of an
event within the notice period, we shall have no liability for that event. The
notice period for an event shall start on the day on which you became, or ought
reasonably to have become, aware of the event having occurred and shall expire 12
months from that date. The notice must be in writing and must identify the
event and the grounds for the claim in reasonable detail.</a></p>

<p class=Untitledsubclause1><a name=a670097>13.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Nothing in these Terms limits or affects the exclusions and limitations
set out in our </a>site’s terms of use.</p>

<p class=Untitledsubclause1><a name=a104788>13.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>This </a>clause 13 will survive termination of the Contract.</p>

<p class=TitleClause>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a name=a425275></a><a name="_Toc256000013">Confidentiality</a> </p>

<p class=Untitledsubclause1><a name=a153104>14.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>We each undertake that we will not at any time disclose to any person
any confidential information concerning one another's business, affairs,
customers, clients or suppliers, except as permitted by </a>clause
14.2.</p>

<p class=Untitledsubclause1><a name=a517446>14.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>We each may disclose the other's confidential information:</a></p>

<p class=Untitledsubclause2><a name=a788128>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>to such of our respective employees, officers, representatives,
subcontractors or advisers who need to know such information for the purposes
of exercising our respective rights or carrying out our respective obligations
under the Contract. We will each ensure that such employees, officers,
representatives, subcontractors or advisers comply with this </a>clause
14; and</p>

<p class=Untitledsubclause2><a name=a489125>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>as may be required by law, a court of competent jurisdiction or any
governmental or regulatory authority.</a></p>

<p class=Untitledsubclause1><a name=a525096>14.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Each of us may only use the other's confidential information for the
purpose of fulfilling our respective obligations under the Contract. </a></p>

<p class=TitleClause>15.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a
name=a243376></a><a name="_Toc256000014">Termination</a>, consequences of
termination and survival</p>

<p class=Untitledsubclause1><a name=a632299>15.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><b>Term</b>i<b>nation. </b>Without limiting any of our other rights, we
may suspend the performance of the Services, or terminate the Contract with
immediate effect by giving written notice to you if:</a></p>

<p class=Untitledsubclause2><a name=a388666>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>you commit a material breach of any term of the Contract and (if such a
breach is remediable) fail to remedy that breach within 30 days of you being
notified in writing to do so;</a></p>

<p class=Untitledsubclause2><a name=a144574>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>you fail to pay any amount due under the Contract on the due date for
payment;</a></p>

<p class=Untitledsubclause2><a name=a452269>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>you take any step or action in connection with you entering
administration, provisional liquidation or any composition or arrangement with
your creditors (other than in relation to a solvent restructuring), applying to
court for or obtaining a moratorium under Part A1 of the Insolvency Act 1986,
being wound up (whether voluntarily or by order of the court, unless for the
purpose of a solvent restructuring), having a receiver appointed to any of your
assets or ceasing to carry on business or, if the step or action is taken in
another jurisdiction, in connection with any analogous procedure in the
relevant jurisdiction;</a></p>

<p class=Untitledsubclause2><a name=a749558>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>you suspend, threaten to suspend, cease or threaten to cease to carry on
all or a substantial part of your business; or</a></p>

<p class=Untitledsubclause2><a name=a646931>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>your financial position deteriorates to such an extent that in our
opinion your capability to adequately fulfil your obligations under the
Contract has been placed in jeopardy.</a></p>

<p class=Untitledsubclause1><a name=a702244><b>15.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Consequences of termination</b></a><a name=a515961></a></p>

<p class=Untitledsubclause2 style='margin-left:49.7pt;text-indent:0cm'>Termination
of the Contract will not affect your or our rights and remedies that have
accrued as at termination.</p>

<p class=Untitledsubclause1><a name=a744407>15.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><b>Survival. </b>Any provision of the Contract that expressly or by
implication is intended to come into or continue in force on or after
termination will remain in full force and effect.</a></p>

<p class=TitleClause>16.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a name=a393521></a><a name="_Toc256000015">Events outside our control</a></p>

<p class=Untitledsubclause1><a name=a257569>16.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>We will not be liable or responsible for any failure to perform, or
delay in performance of, any of our obligations under the Contract that is
caused by any act or event beyond our reasonable control (<b>Event Outside Our
Control</b>). </a></p>

<p class=Untitledsubclause1><a name=a198495>16.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>If an Event Outside Our Control takes place that affects the performance
of our obligations under the Contract:</a></p>

<p class=Untitledsubclause2><a name=a462368>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>we will contact you as soon as reasonably possible to notify you; and</a></p>

<p class=Untitledsubclause2><a name=a764036>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>our obligations under the Contract will be suspended and the time for
performance of our obligations will be extended for the duration of the Event
Outside Our Control. We will arrange a new date for performance of the Services
with you after the Event Outside Our Control is over. </a></p>

<p class=Untitledsubclause1><a name=a947312>16.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>You may cancel the Contract affected by an Event Outside Our Control
which has continued for more than 30 days. To cancel please contact us. If you
opt to cancel we will refund the price you have paid, less the charges
reasonably and actually incurred by us in performing the Services up to the
date of the occurrence of the Event Outside Our Control.</a></p>

<p class=TitleClause>17.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a name=a440898></a><a name="_Toc256000016">Non-solicitation</a> </p>

<p class=NoNumUntitledsubclause1><a name=a707923>You must not attempt to
procure services that are competitive with the Services from any of our
directors, employees or consultants, whether as an employee or on a freelance
basis, during the period that we are providing the Services to you and for a
period of six months following termination of the Contract.</a></p>

<p class=TitleClause>18.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a name=a402607></a><a name="_Toc256000017">Communications between us</a></p>

<p class=Untitledsubclause1><a name=a282149>18.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>When we refer to &quot;in writing&quot; in these Terms, this includes
email.</a></p>

<p class=Untitledsubclause1><a name=a322492>18.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Any notice or other communication given by one of us to the other under
or in connection with the Contract must be in writing and be delivered
personally, sent by pre-paid first class post or other next working day
delivery service, or email.</a></p>

<p class=Untitledsubclause1><a name=a373493>18.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>A notice or other communication is deemed to have been received: </a></p>

<p class=Untitledsubclause2><a name=a568181>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>if delivered personally, on signature of a delivery receipt or at the
time the notice is left at the proper address; </a></p>

<p class=Untitledsubclause2><a name=a792459>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>if sent by pre-paid first class post or other next working day delivery
service, at 9.00 am on the second working day after posting; or</a></p>

<p class=Untitledsubclause2><a name=a737008>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>if sent by email, at 9.00 am the next working day after transmission.</a></p>

<p class=Untitledsubclause1><a name=a187866>18.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>In proving the service of any notice, it will be sufficient to prove, in
the case of a letter, that such letter was properly addressed, stamped and
placed in the post and, in the case of an email, that such email was sent to
the specified email address of the addressee. </a></p>

<p class=Untitledsubclause1><a name=a1010327>18.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>The provisions of this clause will not apply to the service of any
proceedings or other documents in any legal action.</a></p>

<p class=TitleClause>19.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><a name=a273784></a><a name="_Toc256000018">General</a></p>

<p class=Untitledsubclause1><a name=a680341><b>19.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Assignment and transfer</b></a></p>

<p class=Untitledsubclause2><a name=a785685>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>We may assign or transfer our rights and obligations under the Contract
to another entity</a>.</p>

<p class=Untitledsubclause2><a name=a757628>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>You may only assign or transfer your rights or your obligations under
the Contract to another person if we agree in writing. </a></p>

<p class=Untitledsubclause1><a name=a738740>19.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><b>Variation.</b> Any variation of the Contract only has effect if it is
in writing and signed by you and us (or our respective authorised
representatives).</a></p>

<p class=Untitledsubclause1><a name=a140222><b>19.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Waiver. </b>If we do not insist that you perform any of your
obligations under the Contract, or if we do not enforce our rights against you,
or if we delay in doing so, that will not mean that we have waived our rights
against you or that you do not have to comply with those obligations. If we do
waive any rights, we will only do so in writing, and that will not mean that we
will automatically waive any right related to any later default by you.</a></p>

<p class=Untitledsubclause1><a name=a975427><b>19.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Severance.</b> Each paragraph of these Terms operates separately.
If any court or relevant authority decides that any of them is unlawful or
unenforceable, the remaining paragraphs will remain in full force and effect.</a></p>

<p class=Untitledsubclause1><a name=a196971><b>19.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Third party rights.</b> The Contract is between you and us. No
other person has any rights to enforce any of its terms. </a></p>

<p class=Untitledsubclause1><a name=a608924><b>19.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Governing law and jurisdiction.</b> The Contract is governed by
English law and we each irrevocably agree to submit all disputes arising out of
or in connection with the Contract to the exclusive jurisdiction of the English
courts.</a></p>

</div>

</body>

`}}/>
    )
}
