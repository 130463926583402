import { useEffect } from "react";
import { InputWrapper } from "common/components/Inputs/InputWrapper";
import { DropdownArrow } from "common/components/Dropdowns/DropdownArrow";
import useAreasOfPractice from "common/providers/useAreasOfPractice";
import { DropdownMultiSelect } from "common/components/Dropdowns/DropdownMultiSelect";

type FieldOfLawInputProps = {
  value: string[];
  onChange: (newValue: string[]) => void;
};

export const FieldOfLawInput = ({
  value,
  onChange,
}: FieldOfLawInputProps) => {
  const { getAreasOfPractice, areasOfPractice } = useAreasOfPractice();

  useEffect(() => {
    getAreasOfPractice({});
  }, []);

  const options = areasOfPractice?.map((a) => ({
    value: a.id,
    label: a.name
  })) ?? [];

  const selectValue = areasOfPractice
    ? value.map((v) => ({
        value: v,
        label: areasOfPractice.find((a) => a.id === v)?.name,
      }))
    : []

  return (
    <InputWrapper
      hasOverflow
      imgAfter={<DropdownArrow />}
    >
      <label
        className="hidden"
        htmlFor="fieldOfLaw"
      >
        Field of law
      </label>
      <DropdownMultiSelect
        isLarge
        name="field"
        className="lg:min-w-[240px] xl:min-w-[280px]"
        value={selectValue}
        options={options}
        placeholder="Law"
        onChange={onChange}
      />
    </InputWrapper>
  );
};
