import { SVGProps } from 'react'

export const AppleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='12'
    height='15'
    viewBox='0 0 12 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M11.0328 5.2726C10.9516 5.3356 9.51806 6.1434 9.51806 7.9396C9.51806 10.0172 11.3422 10.7522 11.3967 10.7704C11.3883 10.8152 11.107 11.777 10.435 12.757C9.83584 13.6194 9.21007 14.4804 8.25813 14.4804C7.30618 14.4804 7.0612 13.9274 5.96226 13.9274C4.89132 13.9274 4.51054 14.4986 3.63979 14.4986C2.76904 14.4986 2.16148 13.7006 1.46292 12.7206C0.653763 11.5698 0 9.782 0 8.0852C0 5.3636 1.7695 3.9202 3.511 3.9202C4.43635 3.9202 5.2077 4.5278 5.78867 4.5278C6.34164 4.5278 7.20399 3.8838 8.25673 3.8838C8.65571 3.8838 10.0892 3.9202 11.0328 5.2726ZM7.75696 2.7316C8.19233 2.215 8.50031 1.4982 8.50031 0.7814C8.50031 0.682 8.49191 0.5812 8.47372 0.5C7.76536 0.5266 6.9226 0.9718 6.41443 1.5612C6.01546 2.0148 5.64308 2.7316 5.64308 3.4582C5.64308 3.5674 5.66128 3.6766 5.66968 3.7116C5.71447 3.72 5.78727 3.7298 5.86007 3.7298C6.49563 3.7298 7.29498 3.3042 7.75696 2.7316Z'
      fill='black'
    />
  </svg>
)
