export default function () {
    return (
        <div dangerouslySetInnerHTML={{
            __html: `<div class="container content-page mx-4">
  <div>
    <p
      class="normal0"
      style="
        text-indent: 36pt;
        text-align: center;
        widows: 2;
        orphans: 2;
        border-top: 0.75pt solid #000000;
        border-bottom: 0.75pt solid #000000;
        padding-top: 1pt;
        padding-bottom: 1pt;
        font-size: 12pt;
      "
    >
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
          font-variant: small-caps;
        "
      >
        COOKIES POLICY
      </span>
    </p>
    <p class="normal0" style="font-size: 12pt">
      <span style="font-family: 'Century Gothic'"> </span>
    </p>
    <p class="normal0" style="font-size: 12pt">
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        BACKGROUND:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        text-indent: -35.45pt;
        font-size: 12pt;
      "
    >
      <span style="font-family: 'Century Gothic'"> </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        text-indent: -35.45pt;
        font-size: 12pt;
      "
    >
      <span
        style="
          width: 35.45pt;
          text-indent: 0pt;
          display: inline-block;
        "
      >
         
      </span>
      <span style="font-family: 'Century Gothic'">
        These Apps (Apple:
      </span>
      <a
        href="https://apps.apple.com/gb/app/legal-utopia/id1487650991"
        style="text-decoration: none"
      >
        <span
          style="
            font-family: 'Century Gothic';
            text-decoration: underline;
            color: #1155cc;
          "
        >
          https://apps.apple.com/gb/app/legal-utopia/id1487650991
        </span>
      </a>
      <span style="font-family: 'Century Gothic'">
        / Google:
      </span>
      <a
        href="https://play.google.com/store/apps/details?id=com.vrgsoft.utopia&amp;hl=en_GB&amp;gl=US"
        style="text-decoration: none"
      >
        <span
          style="
            font-family: 'Century Gothic';
            text-decoration: underline;
            color: #1155cc;
          "
        >
          https://play.google.com/store/apps/details?id=com.vrgsoft.utopia&amp;hl=en_GB&amp;gl=US
        </span>
      </a>
      <span style="font-family: 'Century Gothic'">
        ) (“Our
      </span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        Apps
      </span>
      <span style="font-family: 'Century Gothic'">
        ”) use Cookies and similar technologies in order to
        distinguish you from other users. By using Cookies,
        We are able to provide you with a better experience
        and to improve Our Apps by better understanding how
        you use it. Please read this Cookie Policy carefully
        and ensure that you understand it. Your acceptance
        of Our Cookie Policy is deemed to occur when you
        press the “Accept” or equivalent affirmative consent
        button on Our Cookie pop-up banner or
      </span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      ></span>
      <span style="font-family: 'Century Gothic'">
        when you have selected your preferred Cookie options
        in Our Cookie prompt and pressed the affirmative
        consent button
      </span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        .
      </span>
      <span style="font-family: 'Century Gothic'">
        If you do not agree to Our Cookie Policy, please
        stop using Our Apps immediately.
      </span>
    </p>
    <p class="normal0" style="font-size: 12pt">
      <span style="font-family: 'Century Gothic'"> </span>
    </p>
    <p class="normal0" style="font-size: 12pt">
      <span style="font-family: 'Century Gothic'"> </span>
    </p>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 14.17pt;
          margin-bottom: 6pt;
          page-break-inside: avoid;
          page-break-after: avoid;
          widows: 2;
          orphans: 2;
          padding-left: 21.28pt;
        "
      >
        <span
          style="
            font-family: 'Century Gothic';
            font-size: 12pt;
            font-weight: bold;
          "
        >
          Definitions and Interpretation
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '1 1';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span style="font-family: Arial; font-size: 11pt">
            1.1
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        In this Cookie Policy, unless the context otherwise
        requires, the following expressions have the
        following meanings:
      </span>
    </p>
    <p class="normal0" style="font-size: 12pt">
      <span style="font-family: 'Century Gothic'"> </span>
    </p>
    <table
      cellspacing="0"
      cellpadding="0"
      class="Table1"
      style="
        margin-left: 40.85pt;
        border-collapse: collapse;
      "
    >
      <tbody>
        <tr>
          <td
            style="
              padding: 1.4pt 1.4pt 7.1pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-left: 7.1pt;
                text-indent: -7.1pt;
                text-align: left;
                widows: 2;
                orphans: 2;
                font-size: 12pt;
              "
            >
              <span
                style="
                  font-family: 'Century Gothic';
                  font-weight: bold;
                "
              >
                “Cookie”
              </span>
            </p>
          </td>
          <td
            style="
              padding: 1.4pt 1.4pt 7.1pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                text-align: left;
                widows: 2;
                orphans: 2;
                font-size: 12pt;
              "
            >
              <span style="font-family: 'Century Gothic'">
                means a small file placed on your computer
                or device by Our Apps when you visit certain
                parts of Our Apps and/or when you use
                certain features of Our Apps. This may also
                incorporate similar technologies such as
                E-tag HTTP Protocols, JavaScript, Local
                Storage (including HTML5 and Local Shared
                Storage), and Beacons;
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding: 1.4pt 1.4pt 7.1pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-left: 7.1pt;
                text-indent: -7.1pt;
                text-align: left;
                widows: 2;
                orphans: 2;
                font-size: 12pt;
              "
            >
              <span
                style="
                  font-family: 'Century Gothic';
                  font-weight: bold;
                "
              >
                “Cookie Law”
              </span>
            </p>
          </td>
          <td
            style="
              padding: 1.4pt 1.4pt 7.1pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                text-align: left;
                widows: 2;
                orphans: 2;
                font-size: 12pt;
              "
            >
              <span style="font-family: 'Century Gothic'">
                means the relevant parts of the Privacy and
                Electronic Communications (EC Directive)
                Regulations 2003;
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding: 1.4pt 1.4pt 7.1pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-left: 7.1pt;
                text-indent: -7.1pt;
                text-align: left;
                widows: 2;
                orphans: 2;
                font-size: 12pt;
              "
            >
              <span
                style="
                  font-family: 'Century Gothic';
                  font-weight: bold;
                "
              >
                “personal data”
              </span>
            </p>
          </td>
          <td
            style="
              padding: 1.4pt 1.4pt 7.1pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                text-align: left;
                widows: 2;
                orphans: 2;
                font-size: 12pt;
              "
            >
              <span style="font-family: 'Century Gothic'">
                means any and all data that relates to an
                identifiable person who can be directly or
                indirectly identified from that data, as
                defined by the Data Protection Act 1998, as
                amended from time to time; and
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding: 1.4pt 1.4pt 7.1pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-left: 7.1pt;
                text-indent: -7.1pt;
                text-align: left;
                widows: 2;
                orphans: 2;
                font-size: 12pt;
              "
            >
              <span
                style="
                  font-family: 'Century Gothic';
                  font-weight: bold;
                "
              >
                “We/Us/Our”
              </span>
            </p>
          </td>
          <td
            style="
              padding: 1.4pt 1.4pt 7.1pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                text-align: left;
                widows: 2;
                orphans: 2;
                font-size: 12pt;
              "
            >
              <span style="font-family: 'Century Gothic'">
                means Legal Utopia Limited, a limited
                company registered in England under company
                number 10909418, whose registered address is
                3rd Floor 86-90 Paul Street London EC2A 4NE
                .
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="normal0" style="font-size: 12pt">
      <span style="font-family: 'Century Gothic'"> </span>
    </p>
    <ol
      start="2"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 14.17pt;
          margin-bottom: 6pt;
          page-break-inside: avoid;
          page-break-after: avoid;
          widows: 2;
          orphans: 2;
          padding-left: 21.28pt;
        "
      >
        <span
          style="
            font-family: 'Century Gothic';
            font-size: 12pt;
            font-weight: bold;
          "
        >
          Information About Us
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '2 1';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span style="font-family: Arial; font-size: 11pt">
            2.1
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Our Apps are owned and operated by Us.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '2 2';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-size: 11pt">2.2</span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        We are regulated by the Information Commissioner’s
        Office under registration number:
      </span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        ZA324233.
      </span>
      <span style="font-family: 'Century Gothic'"></span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '2 3';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span style="font-family: Arial; font-size: 11pt">
            2.3
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        We are registered for VAT under registration number:
        GB 404 291 911 chargeable at 20%.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        font-size: 12pt;
      "
    >
      <span style="font-family: 'Century Gothic'"> </span>
    </p>
    <ol
      start="3"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 14.17pt;
          margin-bottom: 6pt;
          page-break-inside: avoid;
          page-break-after: avoid;
          widows: 2;
          orphans: 2;
          padding-left: 21.28pt;
        "
      >
        <span
          style="
            font-family: 'Century Gothic';
            font-size: 12pt;
            font-weight: bold;
          "
        >
          How Do Our Apps Use Cookies?
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '3 1';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-size: 11pt">3.1</span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Our Apps may place and access certain first party
        Cookies on your computer or device. First party
        Cookies are those placed directly by Us and are used
        only by Us. We use Cookies to facilitate and improve
        your experience of Our Apps and to provide and
        improve Our services
      </span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        .
      </span>
      <span style="font-family: 'Century Gothic'">
        We have carefully chosen these Cookies and have
        taken steps to ensure that your privacy and personal
        data is protected and respected at all times.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '3 2';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span style="font-family: Arial; font-size: 11pt">
            3.2
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        By using Our Apps, you may also receive certain
        third party Cookies on your computer or device.
        Third party Cookies are those placed by websites,
        services, and/or parties other than Us. Third party
        Cookies are used on Our Apps for provision of
        insightful analytics to Us to inform Us on how Our
        Apps are accessed and used. This helps inform Our
        decisions around advertising and marketing Our
        services to you and others. For more details, please
        refer to section 4 below.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '3 3';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span style="font-family: Arial; font-size: 11pt">
            3.3
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        All Cookies used by and on Our Apps are used in
        accordance with current Cookie Law. We may use some
        or all of the following types of Cookie:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        text-indent: -35.35pt;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
        -aw-list-level-number: 4px;
        -aw-list-number-format: '%0.%2.%4';
        -aw-list-number-styles: 'decimal decimal decimal';
        -aw-list-number-values: '3 3 1';
        -aw-list-padding-sml: 11.35pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span>3.3.1</span>
        </span>
        <span
          style="
            width: 11.35pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Strictly Necessary Cookies
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.35pt;
        margin-bottom: 6pt;
        font-size: 12pt;
      "
    >
      <span style="font-family: 'Century Gothic'">
        A Cookie falls into this category if it is essential
        to the operation of Our Apps, supporting functions
        such as logging in, your shopping basket, and
        payment transactions.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        text-indent: -35.35pt;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
        -aw-list-level-number: 4px;
        -aw-list-number-format: '%0.%2.%4';
        -aw-list-number-styles: 'decimal decimal decimal';
        -aw-list-number-values: '3 3 2';
        -aw-list-padding-sml: 11.35pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span>3.3.2</span>
        </span>
        <span
          style="
            width: 11.35pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Analytics Cookies
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.35pt;
        margin-bottom: 6pt;
        font-size: 12pt;
      "
    >
      <span style="font-family: 'Century Gothic'">
        It is important for Us to understand how you use Our
        Apps, for example, how efficiently you are able to
        navigate around it, and what features you use.
        Analytics Cookies enable us to gather this
        information, helping Us to improve Our Apps and your
        experience on them.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        text-indent: -35.35pt;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
        -aw-list-level-number: 4px;
        -aw-list-number-format: '%0.%2.%4';
        -aw-list-number-styles: 'decimal decimal decimal';
        -aw-list-number-values: '3 3 3';
        -aw-list-padding-sml: 11.35pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span>3.3.3</span>
        </span>
        <span
          style="
            width: 11.35pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Functionality Cookies
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.35pt;
        margin-bottom: 6pt;
        font-size: 12pt;
      "
    >
      <span style="font-family: 'Century Gothic'">
        Functionality Cookies enable Us to provide
        additional functions to you on Our Apps such as
        personalisation and remembering your saved
        preferences. Some functionality Cookies may also be
        strictly necessary Cookies, but not all necessarily
        fall into that category.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        text-indent: -35.35pt;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
        -aw-list-level-number: 4px;
        -aw-list-number-format: '%0.%2.%4';
        -aw-list-number-styles: 'decimal decimal decimal';
        -aw-list-number-values: '3 3 4';
        -aw-list-padding-sml: 11.35pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span>3.3.4</span>
        </span>
        <span
          style="
            width: 11.35pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Targeting Cookies
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.35pt;
        margin-bottom: 6pt;
        font-size: 12pt;
      "
    >
      <span style="font-family: 'Century Gothic'">
        It is important for Us to know when and how often
        you visit Our Apps, and which parts of it you have
        used (including which pages you have visited and
        which links you have visited). As with analytics
        Cookies, this information helps us to better
        understand you and, in turn, to make Our Apps and
        advertising more relevant to your interests. Some
        information gathered by targeting Cookies may also
        be shared with, but not sold or traded to, third
        parties.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        text-indent: -35.35pt;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
        -aw-list-level-number: 4px;
        -aw-list-number-format: '%0.%2.%4';
        -aw-list-number-styles: 'decimal decimal decimal';
        -aw-list-number-values: '3 3 5';
        -aw-list-padding-sml: 11.35pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span>3.3.5</span>
        </span>
        <span
          style="
            width: 11.35pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Third Party Cookies
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.35pt;
        margin-bottom: 6pt;
        font-size: 12pt;
      "
    >
      <span style="font-family: 'Century Gothic'">
        Third party Cookies are not placed by Us; instead,
        they are placed by third parties that provide
        services to Us and/or to you. Third party Cookies
        may be used by advertising services to serve up
        tailored advertising to you on Our Apps, or by third
        parties providing analytics services to Us (these
        Cookies will work in the same way as analytics
        Cookies described above).
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        text-indent: -35.35pt;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
        -aw-list-level-number: 4px;
        -aw-list-number-format: '%0.%2.%4';
        -aw-list-number-styles: 'decimal decimal decimal';
        -aw-list-number-values: '3 3 6';
        -aw-list-padding-sml: 11.35pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span>3.3.6</span>
        </span>
        <span
          style="
            width: 11.35pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Persistent Cookies
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.35pt;
        margin-bottom: 6pt;
        font-size: 12pt;
      "
    >
      <span style="font-family: 'Century Gothic'">
        Any of the above types of Cookie may be a persistent
        Cookie. Persistent Cookies are those which remain on
        your computer or device for a predetermined period
        and are activated each time you visit Our Apps.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        text-indent: -35.35pt;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
        -aw-list-level-number: 4px;
        -aw-list-number-format: '%0.%2.%4';
        -aw-list-number-styles: 'decimal decimal decimal';
        -aw-list-number-values: '3 3 7';
        -aw-list-padding-sml: 11.35pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span>3.3.7</span>
        </span>
        <span
          style="
            width: 11.35pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Session Cookies
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.35pt;
        margin-bottom: 6pt;
        font-size: 12pt;
      "
    >
      <span style="font-family: 'Century Gothic'">
        Any of the above types of Cookie may be a session
        Cookie. Session Cookies are temporary and only
        remain on your computer or device from the point at
        which you visit Our Apps until you close your
        browser. Session Cookies are deleted when you close
        your browser.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '3 4';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-size: 11pt">3.4</span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Cookies on Our Apps are not permanent and will
        expire
      </span>
      <a name="gjdgxs"></a>
      <span style="font-family: 'Century Gothic'">
        as indicated in the table below.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '3 5';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span style="font-family: Arial; font-size: 11pt">
            3.5
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        For more details of the personal data that We
        collect and use, the measures we have in place to
        protect personal data, your legal rights, and our
        legal obligations, please refer to our Privacy
        Policy.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '3 6';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span style="font-family: Arial; font-size: 11pt">
            3.6
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        For more specific details of the Cookies that We
        use, please refer to the table below.
      </span>
    </p>
    <p class="normal0" style="font-size: 12pt">
      <span style="font-family: 'Century Gothic'"> </span>
    </p>
    <ol
      start="4"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 14.17pt;
          margin-bottom: 6pt;
          page-break-inside: avoid;
          page-break-after: avoid;
          widows: 2;
          orphans: 2;
          padding-left: 21.28pt;
        "
      >
        <span
          style="
            font-family: 'Century Gothic';
            font-size: 12pt;
            font-weight: bold;
          "
        >
          What Cookies Do Our Apps Use?
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '4 1';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span style="font-family: Arial; font-size: 11pt">
            4.1
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        The following third party Cookies may be placed on
        your computer or device:
      </span>
    </p>
    <table
      cellspacing="0"
      cellpadding="0"
      class="Table2"
      style="
        border: 0.75pt solid #000000;
        -aw-border: 0.5pt single;
        -aw-border-insideh: 0.5pt single #000000;
        -aw-border-insidev: 0.5pt single #000000;
        border-collapse: collapse;
      "
    >
      <tbody>
        <tr>
          <td
            style="
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
            "
          >
            <p class="normal0">
              <span
                style="
                  font-family: 'Century Gothic';
                  font-weight: bold;
                "
              >
                Name of Cookie
              </span>
            </p>
          </td>
          <td
            style="
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-right: 0.5pt single;
            "
          >
            <p class="normal0">
              <span
                style="
                  font-family: 'Century Gothic';
                  font-weight: bold;
                "
              >
                Purpose &amp; Type
              </span>
            </p>
          </td>
          <td
            style="
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-right: 0.5pt single;
            "
          >
            <p class="normal0">
              <span
                style="
                  font-family: 'Century Gothic';
                  font-weight: bold;
                "
              >
                Provider
              </span>
            </p>
          </td>
          <td
            style="
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
            "
          >
            <p class="normal0">
              <span
                style="
                  font-family: 'Century Gothic';
                  font-weight: bold;
                "
              >
                Strictly Necessary
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                __cfduid
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie: is used to speed up load times and
                for threat defence services. It does not
                collect or share user identification.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Cloudflare Inc
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Yes
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                __cfduid
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie: is used to speed up load times and
                for threat defence services. It does not
                collect or share user identification.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Cloudflare Inc
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Yes
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                cookiefirst-consent
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie: saves your cookie preferences for
                this website.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                CookieFirst
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Yes
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                cookiefirst-id
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie: contains a unique ID so CookieFirst
                can identify unique visitors to this
                website.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                CookieFirst
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Yes
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                fr
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                 
              </span>
              <span style="font-family: 'Century Gothic'">
                Cookie:
              </span>
              <span style="font-family: 'Century Gothic'">
                 
              </span>
              <span style="font-family: 'Century Gothic'">
                allows Facebook to deliver personalised ads.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Facebook.com
              </span>
              <a name="kix.ynvwits91res"></a>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                ads_prefs
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Allow following our Twitter account easily
                sharing content via Twitter, and displaying
                the latest tweet.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Twitter.com
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                auth_token
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Allows following our Twitter account easily
                sharing content via Twitter, and displaying
                the latest tweet.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Twitter.com
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                dnt
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie is used for performance generating a
                number of cookies to track user activity on
                websites that can be used for advertising
                purposes.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Facebook Inc
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                guest_id
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie is set for identifying and tracking
                the website visitor.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Twitter.com
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                personalization_id
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie carries out information about how the
                end user uses the website and any
                advertising that the end user may have seen
                before visiting the website.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Twitter.com
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                __hstc
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Linked to websites that are built with the
                HubSpot Platform and used for website
                analysis.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Hubspot Inc
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                __hssrc
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Whenever HubSpot Inc changes the session
                cookie, this cookie is also set to determine
                if the visitor has restarted their browser.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                HubSpot Inc
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                __hssc
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie is linked to websites that are built
                with HubSpot Platform for website analysis.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                HubSpot Inc
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                _hjid
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie is set when the customer first lands
                on a page with the Hotjar script. It is used
                to persist the random User ID, unique to
                that site on the browser. This ensures that
                behaviour in subsequent visits to the same
                site will be attributed to the same User ID.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                HotJar
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                _hjTLDTest
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Detects SEO-ranking for the current website.
                A third-party statistics and analysis
                service.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Hotjar
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                _hjIncludeInPageviewSample
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie is being set for performance and
                analytics purposes.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Hotjar
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                _hjFirstSeen
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie is being set for performance and
                analytics purposes.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Hotjar
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                _hjAbsoluteSessionInProgress
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie is being set for performance and
                analytics purposes.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Hotjar
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                _gid
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Registers unique ID for a website visitor it
                tracks how the visitor uses the website.
                Used for statistics.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Google Inc
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                _gat_*****
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Controls the request rate.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Google Inc
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                _ga
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Registers unique ID for a website visitor it
                tracks how the visitor uses the website.
                Used for statistics.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Google Inc
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                messagesUtk
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie used to recognise visitors who chat
                with us via the message tool. If the visitor
                leaves the website before they’re added as a
                contact, they will have this cookie
                associated with their browser. If the
                website has a history of chatting with a
                visitor and they return to the website later
                in the same cookies browser, the message
                tool will load your conversation history.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                HubSpot Inc
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                hubspotutk
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie is associated with websites built on
                the HubSpot Platform. Used to keep track of
                the visitor&#x27;s identity . Cookie is
                passed to HubSpot on form submission and
                used when deduplicating contacts.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                HubSpot Inc
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                _fbp
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie used for advertising purposes and
                conversion tracking.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Facebook.com
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                YSC
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Registers a unique ID to keep statistics of
                what videos from YouTube the user has seen.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                YouTube Inc
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                VISITOR_INFO1_LIVE
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie allows YouTube to check for bandwidth
                usage.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                YouTube Inc
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                IDE
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie helps analyse and optimise
                advertising campaigns.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Google Inc
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                CONSENT
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Provides certain features from Google and
                may store certain preferences according to
                usage patterns and personalise the ads that
                appear in Google searches.
              </span>
            </p>
          </td>
          <td
            style="
              border-style: solid;
              border-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Google Inc + YouTube Inc
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              border-bottom-style: solid;
              border-bottom-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-bottom: 0.5pt single;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 11.4pt">
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                CONSENT
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-left: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                Cookie used for tracking conversions and
                advertising purposes.
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-right-style: solid;
              border-right-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-left: 0.5pt single;
              -aw-border-right: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0" style="text-align: left">
              <span style="font-family: 'Century Gothic'">
                Facebook Inc
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 0.75pt;
              border-left-style: solid;
              border-left-width: 0.75pt;
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
              -aw-border-left: 0.5pt single;
              -aw-border-top: 0.5pt single;
            "
          >
            <p class="normal0">
              <span style="font-family: 'Century Gothic'">
                No
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="normal0"><span> </span></p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '4 2';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span style="font-family: Arial; font-size: 11pt">
            4.2
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Our Apps uses analytics services provided by, but
        not limited to, Facebook, AppsFlyer, Google
        Analytics, Google Tag Manager, Google AdWords,
        MailChimp, FormSpree, Twitter, LinkedIn, Reddit,
        Instagram, and Amazon Web Services (See Our Privacy
        Policy for more details). Website and app analytics
        refers to a set of tools used to collect and analyse
        anonymous usage information, enabling Us to better
        understand how Our Apps is used. This, in turn,
        enables Us to improve Our Apps and the services
        offered through it. You do not have to allow Us to
        use these Cookies, however whilst Our use of them
        does not pose any risk to your privacy or your safe
        use of Our Apps, it does enable Us to continually
        improve Our Apps, making it a better and more useful
        experience for you.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '4 3';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span style="font-family: Arial; font-size: 11pt">
            4.3
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        The analytics service(s) used by Our Apps use(s)
        analytics Cookies to gather the required
        information.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '4 4';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span style="font-family: Arial; font-size: 11pt">
            4.4
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        The analytics service(s) used by Our Apps are
        provided in the above table.
      </span>
    </p>
    <ol
      start="5"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 14.17pt;
          margin-bottom: 6pt;
          page-break-inside: avoid;
          page-break-after: avoid;
          widows: 2;
          orphans: 2;
          padding-left: 21.28pt;
        "
      >
        <span
          style="
            font-family: 'Century Gothic';
            font-size: 12pt;
            font-weight: bold;
          "
        >
          Consent and Control
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '5 1';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-size: 11pt">5.1</span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Before Cookies are placed on your computer or
        device, you will be shown a prompt or pop-up
        requesting your consent to set those Cookies. By
        giving your consent to the placing of Cookies you
        are enabling Us to provide the best possible
        experience and service to you. You may, if you wish,
        deny consent to the placing of Cookies unless those
        Cookies are strictly necessary; however certain
        features of Our Apps may not function fully or as
        intended. You will be given the opportunity to allow
        and/or deny different categories of Cookie that We
        use.
      </span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      ></span>
      <span style="font-family: 'Century Gothic'">
        You can return to your Cookie preferences to review
        and/or change them at any time in Our Apps’
        settings.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '5 2';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span style="font-family: Arial; font-size: 11pt">
            5.2
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        In addition to the controls that We provide, you can
        choose to enable or disable Cookies in your
        smartphone hardware or operating software (OS)
        provider in settings. Most smartphones also enable
        you to choose whether you wish to disable all
        Cookies or only third party Cookies. By default,
        most mobile phone hardware or OS providers accept
        Cookies but this can be changed. For further
        details, please consult your smart phone settings or
        provider for more information.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '5 3';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span style="font-family: Arial; font-size: 11pt">
            5.3
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        The links below provide instructions on how to
        control Cookies in all mainstream browsers:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        text-indent: -35.35pt;
        text-align: left;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
        -aw-list-level-number: 4px;
        -aw-list-number-format: '%0.%2.%4';
        -aw-list-number-styles: 'decimal decimal decimal';
        -aw-list-number-values: '5 3 1';
        -aw-list-padding-sml: 11.35pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span>5.3.1</span>
        </span>
        <span
          style="
            width: 11.35pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                 
        </span>
      </span>
      <a name="_tyjcwt"></a>
      <span style="font-family: 'Century Gothic'">
        Google Chrome:
      </span>
      <a
        href="https://support.google.com/chrome/answer/95647?hl=en-GB"
        style="text-decoration: none"
      >
        <span
          style="
            font-family: 'Century Gothic';
            text-decoration: underline;
            color: #0563c1;
          "
        >
          https://support.google.com/chrome/answer/95647?hl=en-GB
        </span>
      </a>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        text-indent: -35.35pt;
        text-align: left;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
        -aw-list-level-number: 4px;
        -aw-list-number-format: '%0.%2.%4';
        -aw-list-number-styles: 'decimal decimal decimal';
        -aw-list-number-values: '5 3 2';
        -aw-list-padding-sml: 11.35pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span>5.3.2</span>
        </span>
        <span
          style="
            width: 11.35pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Microsoft Internet Explorer:
      </span>
      <a
        href="https://support.microsoft.com/en-us/kb/278835"
        style="text-decoration: none"
      >
        <span
          style="
            font-family: 'Century Gothic';
            text-decoration: underline;
            color: #0563c1;
          "
        >
          https://support.microsoft.com/en-us/kb/278835
        </span>
      </a>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        text-indent: -35.35pt;
        text-align: left;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
        -aw-list-level-number: 4px;
        -aw-list-number-format: '%0.%2.%4';
        -aw-list-number-styles: 'decimal decimal decimal';
        -aw-list-number-values: '5 3 3';
        -aw-list-padding-sml: 11.35pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span>5.3.3</span>
        </span>
        <span
          style="
            width: 11.35pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Microsoft Edge:
      </span>
      <a
        href="https://support.microsoft.com/en-gb/products/microsoft-edge"
        style="text-decoration: none"
      >
        <span
          style="
            font-family: 'Century Gothic';
            text-decoration: underline;
            color: #0563c1;
          "
        >
          https://support.microsoft.com/en-gb/products/microsoft-edge
        </span>
      </a>
      <span style="font-family: 'Century Gothic'">
        (Please note that there are no specific instructions
        at this time, but Microsoft support will be able to
        assist)
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        text-indent: -35.35pt;
        text-align: left;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
        -aw-list-level-number: 4px;
        -aw-list-number-format: '%0.%2.%4';
        -aw-list-number-styles: 'decimal decimal decimal';
        -aw-list-number-values: '5 3 4';
        -aw-list-padding-sml: 11.35pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span>5.3.4</span>
        </span>
        <span
          style="
            width: 11.35pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Safari (macOS):
      </span>
      <a
        href="https://support.apple.com/kb/PH21411?viewlocale=en_GB&amp;locale=en_GB"
        style="text-decoration: none"
      >
        <span
          style="
            font-family: 'Century Gothic';
            text-decoration: underline;
            color: #0563c1;
          "
        >
          https://support.apple.com/kb/PH21411?viewlocale=en_GB&amp;locale=en_GB
        </span>
      </a>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        text-indent: -35.35pt;
        text-align: left;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
        -aw-list-level-number: 4px;
        -aw-list-number-format: '%0.%2.%4';
        -aw-list-number-styles: 'decimal decimal decimal';
        -aw-list-number-values: '5 3 5';
        -aw-list-padding-sml: 11.35pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span>5.3.5</span>
        </span>
        <span
          style="
            width: 11.35pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Safari (iOS):
      </span>
      <a
        href="https://support.apple.com/en-gb/HT201265"
        style="text-decoration: none"
      >
        <span
          style="
            font-family: 'Century Gothic';
            text-decoration: underline;
            color: #0563c1;
          "
        >
          https://support.apple.com/en-gb/HT201265
        </span>
      </a>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        text-indent: -35.35pt;
        text-align: left;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
        -aw-list-level-number: 4px;
        -aw-list-number-format: '%0.%2.%4';
        -aw-list-number-styles: 'decimal decimal decimal';
        -aw-list-number-values: '5 3 6';
        -aw-list-padding-sml: 11.35pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span>5.3.6</span>
        </span>
        <span
          style="
            width: 11.35pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Mozilla Firefox:
      </span>
      <a
        href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
        style="text-decoration: none"
      >
        <span
          style="
            font-family: 'Century Gothic';
            text-decoration: underline;
            color: #0563c1;
          "
        >
          https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences
        </span>
      </a>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        text-indent: -35.35pt;
        text-align: left;
        widows: 2;
        orphans: 2;
        font-size: 12pt;
        -aw-list-level-number: 4px;
        -aw-list-number-format: '%0.%2.%4';
        -aw-list-number-styles: 'decimal decimal decimal';
        -aw-list-number-values: '5 3 7';
        -aw-list-padding-sml: 11.35pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span>5.3.7</span>
        </span>
        <span
          style="
            width: 11.35pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Android:
      </span>
      <a
        href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&amp;hl=en"
        style="text-decoration: none"
      >
        <span
          style="
            font-family: 'Century Gothic';
            text-decoration: underline;
            color: #0563c1;
          "
        >
          https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&amp;hl=en
        </span>
      </a>
      <span style="font-family: 'Century Gothic'">
        (Please refer to your device’s documentation for
        manufacturers’ own browsers)
      </span>
    </p>
    <p
      class="normal0"
      style="text-align: left; font-size: 12pt"
    >
      <span style="font-family: 'Century Gothic'"> </span>
    </p>
    <ol
      start="6"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 14.17pt;
          margin-bottom: 6pt;
          page-break-inside: avoid;
          page-break-after: avoid;
          widows: 2;
          orphans: 2;
          padding-left: 21.28pt;
        "
      >
        <span
          style="
            font-family: 'Century Gothic';
            font-size: 12pt;
            font-weight: bold;
          "
        >
          Changes to this Cookie Policy
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '6 1';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span style="font-family: Arial; font-size: 11pt">
            6.1
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        We may alter this Cookie Policy at any time.
      </span>
      <span style="font-family: 'Century Gothic'"> </span>
      <span style="font-family: 'Century Gothic'">
        If We do so, details of the changes will be
        highlighted at the top of this page.
      </span>
      <span style="font-family: 'Century Gothic'"> </span>
      <span style="font-family: 'Century Gothic'">
        Any such changes will become binding on you when you
        revisit Our Apps or, where we use Cookies for a
        different use or new Cookies, when you reaffirm your
        consent.
      </span>
      <span style="font-family: 'Century Gothic'"> </span>
      <span style="font-family: 'Century Gothic'">
        You are therefore advised to check this page from
        time to time.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '6 2';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span style="font-family: Arial; font-size: 11pt">
            6.2
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        In the event of any conflict between the current
        version of this Cookie Policy and any previous
        version(s), the provisions current and in effect
        shall prevail unless it is expressly stated
        otherwise.
      </span>
    </p>
    <p
      class="normal0"
      style="text-align: left; font-size: 12pt"
    >
      <span style="font-family: 'Century Gothic'"> </span>
    </p>
    <ol
      start="7"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 14.17pt;
          margin-bottom: 6pt;
          page-break-inside: avoid;
          page-break-after: avoid;
          widows: 2;
          orphans: 2;
          padding-left: 21.28pt;
        "
      >
        <span
          style="
            font-family: 'Century Gothic';
            font-size: 12pt;
            font-weight: bold;
          "
        >
          Further Information
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        font-size: 12pt;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '7 1';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span style="font-family: 'Century Gothic'">
          <span style="font-family: Arial; font-size: 11pt">
            7.1
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        If you would like to know more about how We use
        Cookies, please contact Us at
        customerservice@legalutopia.co.uk, or by post at 3rd
        Floor 86-90 Paul Street London EC2A 4NE .
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        font-size: 12pt;
      "
    >
      <span style="font-family: 'Century Gothic'"> </span>
    </p>
    <div style="clear: both">
      <p class="normal0" style="font-size: 8pt">
        <span></span>
        <span></span>
        <span></span>
        <span
          style="width: 449.15pt; display: inline-block"
        >
           
        </span>
        <span>1</span>
        <span></span>
      </p>
      <p class="normal0"><span> </span></p>
    </div>
  </div>
</div>
`
        }}
        />
    )
}
