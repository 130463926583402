const parseErrorFromAPI = (error) => {
  // Write code for handling errors from API here.
  return error?.message || Strings.defaultErrorMessage
}

export default (e): { message: string; original: any } => {
  console.log('real error======>', e)
  const defaultErrorMessage = Strings.defaultErrorMessage
  if (!e) return { message: defaultErrorMessage, original: e }

  if (e && e.message) {
    return e.message
  }
  // Handle string errors.
  if (typeof e === 'string') return { message: e, original: e }

  // Handle JS errors.
  if (e instanceof Error) return e.message || defaultErrorMessage

  // Handle status codes
  if (e.httpStatus) {
    switch (e.httpStatus) {
      case 504: // Gateway timeout
        return { message: Strings.gatewayTimeoutError, original: e }
      default:
        break
    }
  }

  // Handle API errors
  try {
    if (e._bodyText) {
      const error = JSON.parse(e._bodyText)
      return {
        message: parseErrorFromAPI(error) || defaultErrorMessage,
        original: error,
      }
    }
    return { message: defaultErrorMessage, original: null }
  } catch (err) {
    return { message: defaultErrorMessage, original: null }
  }
}
