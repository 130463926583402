import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseAppointmentBooking = {
  appointmentBooking: AppState['appointmentBooking']
  appointmentBookingLoading: AppState['appointmentBookingLoading']
  appointmentBookingSaving: AppState['appointmentBookingSaving']
  appointmentBookingError: AppState['appointmentBookingError']
  createAppointmentBooking: (
    data: RequestTypes['createAppointmentBooking'],
    callbacks?: Callbacks,
  ) => void
}

export default function useAppointmentBooking(): UseAppointmentBooking {
  const {
    appointmentBooking,
    appointmentBookingLoading,
    appointmentBookingSaving,
    appointmentBookingError,
  } = useSelector((state: AppState) => ({
    appointmentBooking: state.appointmentBooking,
    appointmentBookingLoading: state.appointmentBookingLoading,
    appointmentBookingSaving: state.appointmentBookingSaving,
    appointmentBookingError: state.appointmentBookingError,
  }))
  const dispatch = useDispatch()

  const createAppointmentBooking = useCallback(
    (data: RequestTypes['createAppointmentBooking'], callbacks?: Callbacks) => {
      return dispatch(AppActions.createAppointmentBooking(data, callbacks))
    },
    [dispatch],
  )

  return {
    appointmentBooking,
    appointmentBookingLoading,
    appointmentBookingSaving,
    appointmentBookingError,
    createAppointmentBooking,
  }
}
