import { useEffect } from 'react'
import { Auth } from 'aws-amplify'

const SignoutPage = () => {
  const signOut = async () => {
    try {
      await Auth.signOut()
      document.location.href = '/login'
    } catch (error) {
      console.error('Error signing out: ', error)
    }
  }

  useEffect(() => {
    signOut()
  }, [])

  return null
}

export default SignoutPage
