import React from 'react'

export const QuestionCircle = (props) => (
  <svg
    height='16'
    width='16'
    fill='none'
    viewBox='0 0 16 16'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_10598_62344)'>
      <path
        d='M8.0026 14.6654C11.6845 14.6654 14.6693 11.6806 14.6693 7.9987C14.6693 4.3168 11.6845 1.33203 8.0026 1.33203C4.32071 1.33203 1.33594 4.3168 1.33594 7.9987C1.33594 11.6806 4.32071 14.6654 8.0026 14.6654Z'
        stroke='#001A3D'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
      <path
        d='M6.0625 6.00038C6.21924 5.55483 6.5286 5.17912 6.9358 4.9398C7.343 4.70049 7.82176 4.61301 8.28728 4.69285C8.7528 4.7727 9.17504 5.01473 9.47922 5.37607C9.78339 5.7374 9.94987 6.19473 9.94917 6.66705C9.94917 8.00038 7.94917 8.66705 7.94917 8.66705'
        stroke='#001A3D'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
      <path
        d='M8 11.332H8.00667'
        stroke='#001A3D'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
    </g>
    <defs>
      <clipPath id='clip0_10598_62344'>
        <rect height='16' width='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
