import { ReactNode } from 'react'
import { ErrorMessage, SuccessMessage } from '../Messages'

type FormMessageProps = {
  children: ReactNode
  type: 'error' | 'success'
}

export const FormMessage = ({ children, type }: FormMessageProps) => {
  if (!children) {
    return null
  }

  return (
    <div className='mt-6 text-center rounded-xl'>
      {type === 'error' && <ErrorMessage>{children}</ErrorMessage>}
      {type === 'success' && <SuccessMessage>{children}</SuccessMessage>}
    </div>
  )
}
