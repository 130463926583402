type CardProps = {
  title: string;
  description?: string;
  href: string;
  img: string;
  imgPosition?: string;
}

export const Card = ({
  title,
  description,
  href,
  imgPosition,
  img,
}: CardProps) => (
  <a
    draggable={false} // prevent link dragging
    href={href}
    className="block select-none">
      <div className="aspect-[2/3] rounded-lg overflow-hidden">
        {img ? (
          <img
            src={img}
            alt=""
            className="object-cover object-center w-full h-full"
            style={{ objectPosition: imgPosition }}
          />
        ) : (
          <div className="bg-neutral-200 w-full h-full" />
        )}
      </div>
      <div className="z-10 bottom-0 mt-2 max-w-full text-b33 overflow-hidden">
        <h3 className="font-bold text-xl lg:text-2xl">{title}</h3>
        {description && (
          <p className="font-medium text-sm tracking-wide mt-2">{description}</p>
        )}
      </div>
  </a>
);
