import { FaHeart, FaRegHeart, FaTimesCircle } from 'react-icons/fa'
import React, { FC, MouseEventHandler, useState } from 'react'
import { AiOutlineUp, AiOutlineDown, AiOutlineEllipsis } from 'react-icons/ai'

import {
  InfoItem,
  MenuItem,
  InfoPopup,
  AvatarImage,
  ContactPopup,
  BookNowSection,
  ProfileReviews,
  ProfileCardBase,
  ContactBtnsSection,
  ExternalReviewLink,
  SharePopup,
} from '.'
import {
  AccountType,
  LawyerListObject,
  MembershipType,
  BarristerProfile,
} from 'common/state-type'
import Project from 'common/project'
import { Button } from 'components/base/Button'
import 'styles/style.css'
import Img from '../../base/Img'
import ProfileRating from '../../ProfileRating'
import { getCardTheme } from 'utils/StylesUtil'
import Multiline from 'components/base/Multiline'
import map_icon from 'assets/FAL/map_icon.png'
import LoadingSpinner from 'components/base/LoadingSpinner'
import useProfileDetail from 'common/providers/useProfileDetail'
import useAreasOfPractice from 'common/providers/useAreasOfPractice'

type ProfileCardMobileType = {
  onMouseEnter?: MouseEventHandler
  data: LawyerListObject | BarristerProfile
  longitude: any
  latitude: any
  areas?: string[]
  onFavPress?: () => void
  onBookNow?: () => void
  onReviewBooking?: () => void
  cardStyle?: string
  onClick?: () => void
  lawyerType: 'solicitor' | 'barrister'
  onClose?: () => void
}

const ProfileCardMobile: FC<ProfileCardMobileType> = ({
  data,
  longitude,
  latitude,
  onMouseEnter,
  onFavPress,
  areas,
  onBookNow,
  cardStyle,
  lawyerType,
  onClose,
}) => {
  const { profileDetail, getProfileDetail, profileDetailLoading } =
    useProfileDetail()
  const { areasOfPractice } = useAreasOfPractice()

  const isSolicitor = lawyerType === 'solicitor'
  const getAreasOfPractice = () => {
    if (isSolicitor) {
      const newData = data as LawyerListObject
      return (
        newData?.organisation.areas_of_practice &&
        areasOfPractice &&
        areasOfPractice.filter((e) =>
          newData?.organisation.areas_of_practice.find(
            (area) => area.id === e.id,
          ),
        )
      )
    } else {
      const newData = data as BarristerProfile
      return (
        newData?.areas_of_practice &&
        areasOfPractice &&
        areasOfPractice.filter((e) => newData.areas_of_practice.includes(e.id))
      )
    }
  }

  const operatingAreas = getAreasOfPractice()?.map((item) => item.name)
  const [expandedView, setExpandedView] = useState(false)
  const [reviewSection, setReviewSection] = useState(false)

  const fullName = `${data.user?.first_name} ${data.user?.last_name}`
  const handleShowMore = () => {
    setExpandedView(!expandedView)
    setReviewSection(false)
  }

  const openReviews = () => {
    if (data.id !== profileDetail?.id) {
      getProfileDetail({
        type:
          lawyerType === 'solicitor'
            ? AccountType.LAWYER
            : AccountType.BARRISTER,
        id: data.id,
      })
    }
    setReviewSection(true)
    setExpandedView(true)
  }

  const closeReviews = () => {
    setReviewSection(false)
    setExpandedView(false)
  }

  const membershipType: MembershipType = data.membership_type
  const themeColor = getCardTheme(membershipType)
  const memberships = data.memberships.map((m) => m.name).join(', ')
  const qualifications = data.qualifications.map((q) => q.name).join(', ')
  const isMember = membershipType !== MembershipType.NONE
  const renderReviewSection = () => {
    if (profileDetail) {
      return (
        <ProfileReviews
          onClose={closeReviews}
          reviews={
            profileDetail?.lawyer_ratings || profileDetail?.barrister_ratings
          }
          profileHolder={fullName}
        />
      )
    } else if (profileDetailLoading) {
      return <LoadingSpinner />
    }
    return null
  }

  const renderInfoSection = () => {
    if (isSolicitor) {
      const newData = data as LawyerListObject
      return (
        <div className='grid grid-cols-2'>
          <InfoItem
            title='SRA AUTHORISED'
            content={newData.sra_authorised ? 'Yes' : 'No'}
          />
          <InfoItem title='SRA NUMBER' content={newData.sra_number} />
          {!!newData.qualifications.length && (
            <InfoItem
              title='ACADEMIC QUALIFICATIONS'
              content={qualifications}
            />
          )}
          {!!newData.memberships.length && (
            <InfoItem title='MEMBERSHIPS' content={memberships} />
          )}
        </div>
      )
    } else {
      const newData = data as BarristerProfile
      const honours = newData.honours
        ?.map?.((m) => Utils.capitalizeFirstLetter(m.name))
        .join(', ')
      return (
        <div className='grid grid-cols-2'>
          <InfoItem title='Date of Call' content={newData.date_of_call} />
          <InfoItem title='Chamber’s Name' content={newData.chamber_name} />
          <InfoItem
            title='Primary Status'
            content={newData.primary_status.toLowerCase()}
          />
          <InfoItem
            title='Member Status'
            content={newData.member_status.toLowerCase()}
          />
          {!!newData.memberships && (
            <InfoItem title='Membership' content={memberships} />
          )}

          {!!newData.honours && <InfoItem title='Honours' content={honours} />}
        </div>
      )
    }
  }

  return (
    <div
      id={`profile-${data.id}`}
      onMouseEnter={onMouseEnter}
      className='w-full md:hidden'
    >
      <ProfileCardBase membershipType={membershipType} className={cardStyle}>
        <div className='flex flex-col flex-1 w-full'>
          <div className='flex flex-col'>
            {onClose && (
              <Button
                className='self-end mb-8 mt-[-18px] md:hidden'
                onClick={onClose}
              >
                <FaTimesCircle
                  size={20}
                  color={themeColor.hex}
                  onClick={() => {}}
                />
              </Button>
            )}

            <div className='flex flex-row'>
              <div className='flex flex-col'>
                <AvatarImage imgSource={data.avatar} fullName={fullName} />

                <span className='text-black text-xs mb-6 mt-2 font-normal'>
                  Experience : <br />
                  {data.number_of_practice_years} years
                </span>
              </div>

              <div className='flex flex-col w-full mx-3'>
                <div className='flex flex-row place-content-between mb-3'>
                  <div className='flex flex-col text-black'>
                    <span className='text-lg font-bold'>{fullName}</span>
                    <span className='text-base'>
                      {isSolicitor
                        ? (data as LawyerListObject).organisation.practice_name
                        : (data as BarristerProfile).chamber_name}
                    </span>
                  </div>
                  {isMember && (
                    <div className='flex flex-row gap-x-2'>
                      <button
                        onClick={onFavPress}
                        className={`flex items-center justify-center h-8 w-8 rounded-full ${themeColor.class}`}
                      >
                        {data.favourite_id ? (
                          <FaRegHeart color='#fff' size={20} />
                        ) : (
                          <FaHeart color='#fff' size={20} />
                        )}
                      </button>
                      <div
                        className={`flex items-center justify-center h-8 w-8 rounded-full ${themeColor.class}`}
                      >
                        <InfoPopup
                          themeColor={themeColor}
                          triggerrer={
                            <AiOutlineEllipsis color='#fff' size={20} />
                          }
                          position='bottom right'
                          on='hover'
                          closeOnDocumentClick
                          mouseLeaveDelay={300}
                          mouseEnterDelay={0}
                          arrow
                          nested
                          MenuItems={[
                            <MenuItem
                              key='1stItem'
                              Icon={data.favourite_id ? FaHeart : FaRegHeart}
                              label={
                                data.favourite_id
                                  ? 'Remove from Favourite'
                                  : 'Add Favorites'
                              }
                              onClick={onFavPress}
                            />,
                            <SharePopup
                              key='2ndItem'
                              shareLink={
                                isSolicitor
                                  ? `${Project.cwp}lawyers/${data.id}`
                                  : `${Project.cwp}barristers/${data.id}`
                              }
                              position='bottom right'
                            />,
                            <ContactPopup
                              position='bottom right'
                              key='3rdItem'
                              themeColor={themeColor}
                              contactNumber={data.phone_number}
                              email={data.user.email}
                              website={data.website_url}
                            />,
                          ]}
                        >
                          <></>
                        </InfoPopup>
                      </div>
                    </div>
                  )}
                </div>
                {isMember && (
                  <>
                    {data.google_reviews_url && (
                      <ExternalReviewLink
                        label='See our Google reviews'
                        isGoogle
                        link={data.google_reviews_url}
                      />
                    )}

                    {data.trust_pilot_reviews_url && (
                      <ExternalReviewLink
                        label=' See our TrustPilot Reviews'
                        isTrustpilot
                        link={data.trust_pilot_reviews_url}
                      />
                    )}
                    <button onClick={openReviews}>
                      <ProfileRating rating={data.average_rating} />
                    </button>
                  </>
                )}
                <div className='flex flex-row gap-x-4 mt-3'>
                  <div
                    className={`text-sm px-2.5 items-center flex h-7 text-black rounded-lg font-normal bg-[#F4F4F4]`}
                  >
                    <span>{isSolicitor ? 'Solicitor' : 'Barrister'}</span>
                  </div>
                  {isMember && (
                    <div
                      className={`text-sm px-2.5 items-center flex h-7 text-black rounded-lg font-bold ${themeColor.class}`}
                    >
                      <span>{data.membership_type}</span>
                    </div>
                  )}
                  {isMember && (
                    <div className='flex flex-row items-center text-black'>
                      <Img imgSrc={map_icon} className={'h-4 w-4'} />
                      <span className='pl-2 text-xs capitalize'>
                        {isSolicitor
                          ? Utils.getDistance(
                              latitude,
                              longitude,
                              (data as LawyerListObject).office.latitude,
                              (data as LawyerListObject).office.longitude,
                            )
                          : Utils.getDistance(
                              latitude,
                              longitude,
                              (data as BarristerProfile).latitude,
                              (data as BarristerProfile).longitude,
                            )}{' '}
                        Miles
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='w-full mt-4 flex flex-col'>
              {Boolean(operatingAreas) && (
                <>
                  <span className='text-black text-sm font-bold'>
                    Operating areas
                  </span>
                  <div className=' gap-2 py-3'>
                    {operatingAreas.map((area: string, index: number) => {
                      let textColor: string
                      const isActive = areas?.includes(area)
                      textColor = isActive ? 'text-white' : 'text-black'
                      return (
                        <span
                          key={area}
                          style={
                            isActive
                              ? { backgroundColor: themeColor.hex }
                              : { backgroundColor: '#F4F4F4' }
                          }
                          className={`px-2.5 py-1.5 items-center text-sm inline-block text-center whitespace-nowrap align-baseline ${textColor} rounded-lg mr-2 mb-1`}
                        >
                          {area}
                        </span>
                      )
                    })}
                  </div>
                </>
              )}
              {isMember && (
                <button
                  className='flex flex-row self-center'
                  onClick={handleShowMore}
                >
                  {!expandedView && (
                    <p className='text-center text-gray-500 text-sm pr-2'>
                      show more ...
                    </p>
                  )}
                  {expandedView ? (
                    <AiOutlineUp size={20} color='#000000' />
                  ) : (
                    <AiOutlineDown size={20} color='#000000' />
                  )}
                </button>
              )}
              {reviewSection ? (
                renderReviewSection()
              ) : expandedView ? (
                <div className='flex flex-col flex-1 text-sm'>
                  <h4 className='font-bold text-black pb-2'>
                    About {fullName}
                  </h4>
                  <div className='font-normal mb-4 text-black'>
                    <Multiline text={data.bio} />
                  </div>
                  {renderInfoSection()}
                </div>
              ) : null}
              <div className='flex flex-row place-content-between'>
                <ContactBtnsSection
                  phone={data.phone_number}
                  email={data.user.email}
                  website={data.website_url}
                  themeColor={themeColor}
                />

                {isMember && (
                  <BookNowSection
                    btnLabel={'Book Now'}
                    onClick={onBookNow}
                    title={
                      data.rate_per_hour_excluding_vat
                        ? `From £${data.rate_per_hour_excluding_vat?.toFixed(
                            2,
                          )} ph`
                        : '£ Nil ph'
                    }
                    themeColor={themeColor}
                    popupTitle='*Price information*'
                    popupDescription={`Legal Utopia’s ‘Book a Lawyer’ and ‘Find a Lawyer’ services are free.
              \n We do not charge users for finding or booking a lawyer through our website or mobile app.
               Please discuss your legal fees directly with your lawyer`}
                    titleClass='text-lg'
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </ProfileCardBase>
    </div>
  )
}

export default ProfileCardMobile
