import React from "react";
import events_next from "../../assets/Public Content/events_next.png";
import calender from "../../assets/Public Content/calender.png";
import calenderplus from "../../assets/Public Content/calenderplus.png";

const EventsAndWebinar = () => {
  return (
    <React.Fragment>
      <div className="bg-[#5858DF] bg-opacity-[0.2] py-[60px]">
        <div className="md:flex lg:max-w-[1024px] justify-between items-center mx-auto">
          <div className="flex flex-row md:mt-[40px]">
            <div className="text-[#1B4A8A] leading-[48px] text-[36px]  font-bold">
              Events & Webinars
            </div>
            <div className="p-[10px]">
              <img src={events_next} className="max-w-full h-auto" />
            </div>
          </div>
        </div>
        <div className="md:flex lg:max-w-[1024px] justify-between items-center mx-auto py-[40px] px-[20px]">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-8">
            <div className="rounded-[10px] overflow-hidden shadow-lg bg-white px-[28px] py-[22px] items-center">
              {/* <img
              className="w-full"
              src=""
              alt="news letter"
            /> */}
              <div className="w-[260px] h-[202px] bg-[#D9D9D9] rounded-[10px]"></div>
              <div className="py-4">
                <div className="py-[20px] text-[#1B4A8A] font-bold text-[20px] leading-[26px]">
                  EVENT TITLE & NAMES
                </div>
                <div className="py-[20px] flex flex-row">
                  <img src={calender} className="max-w-full h-auto" />
                  <p className="text-[#777777] font-normal text-[20px] leading-[29px] pl-[10px]">
                    21/05/2022
                  </p>
                </div>
                <div className="flex justify-between">
                  <div className="font-bold text-[20px] leading-[26px] mb-2 text-[#1B4A8A] bg-[#65C2D7] bg-opacity-[0.2] rounded-[10px] px-[12px] py-[5px]">
                    Conference
                  </div>
                  <div className="float-right w-[42px] h-[42px] bg-[#F0F0F0] rounded-full relative">
                  <img src={calenderplus} className="max-w-full h-auto absolute top-0 bottom-0 left-0 right-0 m-auto" />
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="rounded-[10px] overflow-hidden shadow-lg bg-white px-[28px] py-[22px] items-center">
              {/* <img
              className="w-full"
              src=""
              alt="news letter"
            /> */}
              <div className="w-[260px] h-[202px] bg-[#D9D9D9] rounded-[10px]"></div>
              <div className="py-4">
                <div className="py-[20px] text-[#1B4A8A] font-bold text-[20px] leading-[26px]">
                  EVENT TITLE & NAMES
                </div>
                <div className="py-[20px] flex flex-row">
                  <img src={calender} className="max-w-full h-auto" />
                  <p className="text-[#777777] font-normal text-[20px] leading-[29px] pl-[10px]">
                    21/05/2022
                  </p>
                </div>
                <div className="flex justify-between">
                  <div className="font-bold text-[20px] leading-[26px] mb-2 text-[#1B4A8A] bg-[#65C2D7] bg-opacity-[0.2] rounded-[10px] px-[12px] py-[5px]">
                    Conference
                  </div>
                  <div className="float-right w-[42px] h-[42px] bg-[#F0F0F0] rounded-full relative">
                  <img src={calenderplus} className="max-w-full h-auto absolute top-0 bottom-0 left-0 right-0 m-auto" />
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="rounded-[10px] overflow-hidden shadow-lg bg-white px-[28px] py-[22px] items-center">
              {/* <img
              className="w-full"
              src=""
              alt="news letter"
            /> */}
              <div className="w-[260px] h-[202px] bg-[#D9D9D9] rounded-[10px]"></div>
              <div className="py-4">
                <div className="py-[20px] text-[#1B4A8A] font-bold text-[20px] leading-[26px]">
                  EVENT TITLE & NAMES
                </div>
                <div className="py-[20px] flex flex-row">
                  <img src={calender} className="max-w-full h-auto" />
                  <p className="text-[#777777] font-normal text-[20px] leading-[29px] pl-[10px]">
                    21/05/2022
                  </p>
                </div>
                <div className="flex justify-between">
                  <div className="font-bold text-[20px] leading-[26px] mb-2 text-[#1B4A8A] bg-[#65C2D7] bg-opacity-[0.2] rounded-[10px] px-[12px] py-[5px]">
                    Conference
                  </div>
                  <div className="float-right w-[42px] h-[42px] bg-[#F0F0F0] rounded-full relative">
                  <img src={calenderplus} className="max-w-full h-auto absolute top-0 bottom-0 left-0 right-0 m-auto" />
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EventsAndWebinar;
