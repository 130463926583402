import React, { useEffect, useMemo } from 'react'
import QRCode from 'qrcode.react'
import useSetupMfa from 'common/providers/useSetupMfa'
import useProfile from 'common/providers/useProfile'

export const MfaQrCode = () => {
  const { profile } = useProfile()
  const { setupMfa, createSetupMfa } = useSetupMfa()

  useEffect(() => {
    createSetupMfa({ id: null })
  }, [createSetupMfa])

  const issuer = 'legal_utopia'
  const qrCodeUrl = useMemo(() => {
    const url = new URL(`otpauth://totp/${issuer}:${profile?.email}`)

    url.searchParams.append('secret', String(setupMfa))
    url.searchParams.append('issuer', issuer)

    return url.href
  }, [profile, setupMfa])

  return (
    <QRCode
      className='mb-8'
      value={qrCodeUrl}
      size={160}
      bgColor={'#ffffff'}
      fgColor={'#000000'}
      level={'L'}
      includeMargin={false}
      renderAs={'svg'}
      imageSettings={{
        src: 'https://static.zpao.com/favicon.png',
        x: null,
        y: null,
        height: 24,
        width: 24,
        excavate: true,
      }}
    />
  )
}
