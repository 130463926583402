type SiteFooterLinksListProps = {
  links: {
    label: string
    href: string
  }[]
}

export const SiteFooterLinksList = ({ links }: SiteFooterLinksListProps) => (
  <ul className='space-y-3'>
    {links.map(({ label, href }) => (
      <li key={label}>
        <a
          className='cursor-pointer hover:underline tracking-[0.4px] text-[#BBC7D7] font-normal  text-lg'
          href={href}
        >
          {label}
        </a>
      </li>
    ))}
  </ul>
)
