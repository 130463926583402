import cn from 'classnames'
import { ReactElement, ReactNode, isValidElement } from 'react'
import { useKeyboardTabbed } from '../../hooks/useKeyboardTabbed'

type InputWrapperProps = {
  imgBefore?: ReactElement | string
  imgAfter?: ReactElement | string
  onClick?: () => void
  children: ReactNode
  isFirst?: boolean
  className?: string
  hasError?: boolean
  theme?: 'default' | 'grey'
  hasOverflow?: boolean
  isNew?: boolean
}

const isElement = (img: ReactElement | string): img is ReactElement =>
  isValidElement(img)

export const InputWrapper = ({
  children,
  imgBefore,
  imgAfter,
  onClick,
  isFirst,
  className,
  hasError,
  theme = 'default',
  hasOverflow,
  isNew,
}: InputWrapperProps) => {
  const enableFocusStyles = useKeyboardTabbed()
  const imgBeforeClassName = 'pl-4 lg:pl-5'
  const imgAfterClassName = 'pr-4 lg:pr-5 absolute right-0'

  return (
    <div
      className={cn(
        isNew
          ? 'bg-white flex rounded-lg gap-1 items-center relative'
          : 'rounded-3xl bg-neutral-100 flex items-center relative',
        !hasOverflow && 'overflow-hidden',
        isFirst && 'lg:rounded-l-full',
        enableFocusStyles && 'focus-within-outline',
        hasError && 'border border-red-500',
        theme === 'default' && 'lg:bg-white',
        isNew &&
          'shadow-[inset_0px_0.8px_2px_0px_rgba(0,0,0,0.15),0px_0.5px_2px_0px_rgba(0,0,0,0.25)]',
        isNew &&
          !hasError &&
          'focus-within:border focus-within:border-[#B3EBF9]',
        className,
      )}
      onClick={onClick}
    >
      {imgBefore && (
        <>
          {isElement(imgBefore) ? (
            <div className={imgBeforeClassName}>{imgBefore}</div>
          ) : (
            <div className={imgBeforeClassName}>
              <img
                className='pointer-events-none'
                src={imgBefore}
                width='20'
                height='20'
                alt=''
              />
            </div>
          )}
        </>
      )}
      {children}
      {imgAfter && (
        <>
          {isElement(imgAfter) ? (
            <div className={imgAfterClassName}>{imgAfter}</div>
          ) : (
            <div className={imgBeforeClassName}>
              <img
                className='pointer-events-none'
                src={imgAfter}
                width='20'
                height='20'
                alt=''
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}
