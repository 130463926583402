// https://products.aspose.app/words/conversion/docx-to-html
// https://magic.reactjs.net/htmltojsx.htm
export default function () {
    return (
        <div dangerouslySetInnerHTML={{
            __html: `<div><p class="NoNumTitle-Clause" style="margin-left:0pt"><a name="a394473"><span>Consent to installation of the
    App</span></a></p>
    <p class="ParaClause" style="margin-left:0pt"><span>Under data protection laws, we are required to provide you with
        certain information about who we are, how we process your personal data and for what purposes, and your rights
        in relation to your personal data. This information is provided in our Privacy Policy and it is important that
        you read that information. </span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>Before installation of this App, please indicate your consent to
        our processing of your personal data (including your name, contact details and device information) as described
        in the Privacy Policy. </span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-weight:bold">YES </span><span>I consent to the
        installation of the App for the purposes of legal and information services. </span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-weight:bold">NO</span><span> I do not consent to the
        installation of the App. </span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-weight:bold">How you can withdraw consent</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>Once you provide consent by selecting "Download" or “Get” or
        “Install”, you may change your mind and withdraw consent at any time by contacting us uninstalling the app but
        that will not affect the lawfulness of any processing carried out before you withdraw your consent. </span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-weight:bold">Consent to processing Location
        Data </span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-weight:bold">YES </span><span>I consent to
        processing of my Location Data (including details of my current location disclosed by GPS technology or other
        similar technologies so that location-enabled Services are activated to provide you with information, as part of
        our services, relevant to your active location. </span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-weight:bold">NO</span><span> I do not consent to
        processing of my Location Data and location-enabled Services are disabled in my settings.</span></p>
    <p class="NoNumTitle-Clause" style="margin-left:0pt"><a name="a247073"><span>Location Data</span></a></p>
    <p class="ParaClause" style="margin-left:0pt"><span>Legal Utopia Limited (</span><span class="DefTerm"
    >we</span><span>) are committed to protecting your personal data and respecting your privacy.</span></p>
    <p class="NoNumTitle-Clause" style="margin-left:0pt"><a name="a686182"><span>Introduction </span></a></p>
    <p class="ParaClause" style="margin-left:0pt"><span>This policy (together with our end-user licence agreement as set
        out at website and app store listing (</span><span class="DefTerm">EULA</span><span>) and any additional terms
        of use incorporated by reference into the EULA, together our </span><span class="DefTerm">Terms of
        Use</span><span>) applies to your use of:</span></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li class="subclause1Bullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">The Legal Utopia mobile application software (</span><span class="DefTerm"
        >App</span><span style="font-family:Arial">) hosted by Apple Inc or Google Commerce LLC (</span><span
                class="DefTerm"
        >App Site</span><span style="font-family:Arial">), once you have downloaded or streamed a copy of the App onto
            your mobile telephone, computer or handheld device (</span><span class="DefTerm">Device</span><span
                style="font-family:Arial"
        >).</span></li>
        <li class="subclause1Bullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Any of the services accessible through the App (</span><span class="DefTerm">Services</span><span
                style="font-family:Arial"
        >) that are available on the App Site or other sites of ours (</span><span class="DefTerm">Services Sites</span><span
                style="font-family:Arial"
        >), unless the EULA states that a separate privacy policy applies to a particular Service, in which case that
            privacy policy only applies. This policy sets out the basis on which any personal data we collect from you,
            or that you provide to us, will be processed by us. This App is not intended for children and we do not
            knowingly collect data relating to children. Please read the following carefully to understand our practices
            regarding your personal data and how we will treat it.</span></li>
    </ul>
    <p class="ParaClause" style="margin-left:0pt"><span style="-aw-import:ignore">&nbsp;</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="-aw-import:ignore">&nbsp;</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="-aw-import:ignore">&nbsp;</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="-aw-import:ignore">&nbsp;</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>This policy is provided in a layered format so you can click
        through to the specific areas set out below. </span><span style="-aw-import:spaces">&nbsp;</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-style:italic">IMPORTANT INFORMATION AND WHO WE
        ARE</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-style:italic">THE DATA WE COLLECT ABOUT YOU</span>
    </p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-style:italic">HOW IS YOUR PERSONAL DATA
        COLLECTED?</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-style:italic">HOW WE USE YOUR PERSONAL DATA</span>
    </p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-style:italic">DISCLOSURES OF YOUR PERSONAL
        DATA</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-style:italic">INTERNATIONAL TRANSFERS</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-style:italic">DATA SECURITY</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-style:italic">DATA RETENTION</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-style:italic">YOUR LEGAL RIGHTS</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-style:italic">GLOSSARY</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-style:italic">DESCRIPTION OF CATEGORIES OF PERSONAL
        DATA</span></p>
    <p class="NoNumTitle-Clause" style="margin-left:0pt"><a name="a825208"><span>Important information and who we
        are</span></a></p>
    <p class="ParaClause" style="margin-left:0pt"><span>Legal Utopia Limited is made up of different legal entities, (1)
        Legal Utopia Limited and (2) Universal Legal Company Limited. When we mention "Legal Utopia” we are referring to
        the relevant company in the Legal Utopia Group responsible for processing your data which will be clear to you
        when you use our App. Legal Utopia Limited is the controller responsible for this App.</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>We have appointed a data privacy manager. If you have any
        questions about this privacy policy, please contact them using the details set out below.</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-weight:bold">Contact details</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>Our full details are:</span></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Full name of legal entity: Legal Utopia Limited</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Name or title of data privacy manager: Fraser Matcham </span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Email address: fraser@legalutopia.co.uk</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Postal address: 86-90 Paul Street, London, EC2A 4NE</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Telephone number: 020 3918 5630</span></li>
    </ul>
    <p class="ParaClause" style="margin-left:0pt"><span>You have the right to make a complaint at any time to the
        Information Commissioner's Office (</span><span class="DefTerm">ICO</span><span>), the UK regulator for data
        protection issues. </span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-weight:bold">Changes to the privacy policy and your
        duty to inform us of changes</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>We keep our privacy policy under regular review. </span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>This version was last updated on 23</span><span
            style="font-size:7.33pt; vertical-align:super"
    >rd</span><span> June 2022. It may change and if it does, these changes will be posted on this page and, where
        appropriate, notified to you by SMS or by email or when you next start the App or log onto one of the Services
        Sites. The new policy may be displayed on-screen and you may be required to read and accept the changes to
        continue your use of the App or the Services.</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>It is important that the personal data we hold about you is
        accurate and current. Please keep us informed if your personal data changes during our relationship with
        you. </span></p>
    <p class="ParaClause" style="margin-left:0pt"><span style="font-weight:bold">Third party links</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>Our Sites may, from time to time, contain links to and from the
        websites of our partner networks, advertisers and affiliates. Please note that these websites and any services
        that may be accessible through them have their own privacy policies and that we do not accept any responsibility
        or liability for these policies or for any personal data that may be collected through these websites or
        services, such as Contact and </span><span class="DefTerm" style="font-weight:normal">Location Data</span><span
            style="font-weight:bold"
    >.</span><span> Please check these policies before you submit any personal data to these websites or use these
        services.</span></p>
    <p class="NoNumTitle-Clause" style="margin-left:0pt"><a name="a532893"><span>The data we collect about
        you</span></a></p>
    <p class="ParaClause" style="margin-left:0pt"><span>We may collect, use, store and transfer different kinds of
        personal data about you as follows:</span></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Identity Data.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Contact Data.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Transaction Data.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Device Data.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Content Data.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Profile Data.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Usage Data.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Marketing and Communications Data.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Location Data </span></li>
    </ul>
    <p class="ParaClause" style="margin-left:0pt"><span>We also collect, use and share Aggregated Data such as
        statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is
        not considered personal data in law as this data will not directly or indirectly reveal your identity. For
        example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific App feature.
        However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly
        identify you, we treat the combined data as personal data which will be used in accordance with this privacy
        policy.</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>We do not collect any Special Categories of Personal Data about
        you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual
        orientation, political opinions, trade union membership, information about your health, and genetic and
        biometric data). Nor do we collect any information about criminal convictions and offences.</span></p>
    <p class="NoNumTitle-Clause" style="margin-left:0pt"><a name="a517215"><span>How is your personal data
        collected?</span></a></p>
    <p class="ParaClause" style="margin-left:0pt"><span>We will collect and process the following data about you:</span>
    </p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Information you give us. </span><span
                style="font-family:Arial"
        >This is information (including Identity, Contact, and Marketing and Communications Data) you consent to giving
            us about you by filling in forms on the App Site and the Services Sites (together </span><span
                class="DefTerm"
        >Our Sites</span><span style="font-family:Arial">), or by corresponding with us (for example, by email or chat).
            It includes information you provide when you register to use the App Site, download or register an App,
            subscribe to any of our Services, search for an App or Service, make an in-App purchase, share data via an
            App's social media functions, enter a competition, promotion or survey, or engage with any feature or
            service we provide and when you report a problem with an App, our Services, or any of Our Sites. If you
            contact us, we will keep a record of that correspondence. </span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Information we collect about you and your device.</span><span
                style="font-family:Arial"
        > Each time you visit one of Our Sites or use one of our Apps we will automatically collect personal data
            including Device, Content and Usage Data. We collect this data using cookies and other similar technologies.
            Please see our Cookies Policy. </span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Location Data.</span><span style="font-family:Arial"> We also
            use GPS technology or alike technologies to determine your current location. Some of our location-enabled
            Services require your personal data for the feature to work. If you wish to use the particular feature, you
            will be asked to consent to your data being used for this purpose. You can withdraw your consent at any time
            by disabling Location Data in your settings or declining permission when requested. </span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Information we receive from other sources including third
            parties and publicly available sources. </span><span style="font-family:Arial">We will receive personal data
            about you from various third parties and public sources as set out below:</span></li>
    </ul>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li class="ClauseBullet2"
            style="margin-left:63.91pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Device Data from the following parties:</span></li>
    </ul>
    <p class="ClauseBullet2Para"><span>analytics providers such as Google LLC and Apple Inc based outside the UK;</span>
    </p>
    <p class="ClauseBullet2Para"><span>advertising networks such as Meta Inc, Facebook Ireland Ltd, LinkedIn Inc,
        Twitter Inc, Twitter International Company, Google LLC, Google Ireland Limited, Google Commerce Limited, Google
        Asia Pacific Pte based inside and outside the UK; and</span></p>
    <p class="ClauseBullet2Para"><span>search information providers such as Google LLC based outside the UK.</span></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li class="ClauseBullet2"
            style="margin-left:63.91pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Contact and Transaction Data from providers of technical, payment and delivery
            services such as Google Payments Corp, Apple Inc, and Stripe Inc based outside the UK;</span></li>
        <li class="ClauseBullet2"
            style="margin-left:63.91pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Identity and Contact Data from data brokers or aggregators such as ZoomInfo
            Technologies LLC and Yoti Ltd based in and outside the UK;</span></li>
        <li class="ClauseBullet2"
            style="margin-left:63.91pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Identity, Profile and Contact Data from publicly available sources such as
            Companies House, The Insolvency Register, Solicitors Regulation Authority, Bar Standards Board, Legal
            Ombudsman, Legal Aid Agency, Directors’ Disqualification Register, The Law Society of England and Wales, The
            Law Society of Scotland, The Solicitors Disciplinary Tribunal, The National Archive and the electoral
            register based inside the UK; and</span></li>
    </ul>
    <p class="ClauseBullet1" style="text-indent:0pt"><span style="font-weight:bold">Unique application
        numbers</span><span>. When you want to install or uninstall a Service containing a unique application number or
        when such a Service searches for automatic updates, that number and information about your installation, for
        example, the type of operating system, may be sent to us.</span></p>
    <p class="NoNumTitle-Clause" style="margin-left:0pt"><a name="a195459"><span>Cookies</span></a></p>
    <p class="ParaClause" style="margin-left:0pt"><span>We use cookies and/or other tracking technologies to distinguish
        you from other users of the App, App Site, the distribution platform (Appstore) or Services Sites and to
        remember your preferences. This helps us to provide you with a good experience when you use the App or browse
        any of Our Sites and also allows us to improve the App and Our Sites. For detailed information on the cookies we
        use, the purposes for which we use them and how you can exercise your choices regarding our use of your cookies,
        see our Cookies Policy. </span></p>
    <p class="NoNumTitle-Clause" style="margin-left:0pt"><a name="a844532"><span>How we use your personal
        data</span></a></p>
    <p class="ParaClause" style="margin-left:0pt"><span>We will only use your personal data when the law allows us to do
        so. Most commonly we will use your personal data in the following circumstances: </span></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Where you have consented before the processing.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Where we need to perform a contract we are about to enter or have entered with
            you.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Where it is necessary for our legitimate interests (or those of a third party)
            and your interests and fundamental rights do not override those interests.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Where we need to comply with a legal or regulatory obligation. </span></li>
    </ul>
    <p class="ParaClause" style="margin-left:0pt"><span>We will only send individuals using the App for personal use
        only direct marketing communications by email or text if we have your consent. User of the App for business and
        personal use have the right to withdraw that consent at any time by contacting us. </span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>We will get your express opt-in consent, if you are using the
        App for personal use, before we share your personal data with any third party for marketing purposes.</span></p>
    <p class="NoNumTitle-Clause" style="margin-left:0pt"><a name="a703344"><span>Purposes for which we will use your
        personal data</span></a></p>
    <table cellspacing="0" cellpadding="0"
           style="width:93.3%; margin-left:30.35pt; margin-bottom:0pt; border:0.75pt solid #000000; -aw-border:0.5pt single; -aw-border-insideh:0.5pt single #000000; -aw-border-insidev:0.5pt single #000000; border-collapse:collapse"
    >
        <tbody>
        <tr style="height:23.6pt">
            <td style="width:33.32%; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single">
                <p class="Paragraph"><span style="font-weight:bold">Purpose/activity </span></p></td>
            <td style="width:33.34%; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-right:0.5pt single">
                <p class="Paragraph"><span style="font-weight:bold">Type of data </span></p></td>
            <td style="width:33.34%; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single">
                <p class="Paragraph"><span style="font-weight:bold">Lawful basis for processing </span></p></td>
        </tr>
        <tr style="height:22.95pt">
            <td style="width:33.32%; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">
                <p class="Paragraph"><span>To install the App and register you as a new App user </span></p></td>
            <td style="width:33.34%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border:0.5pt single">
                <p class="Paragraph"><span>Identity</span></p>
                <p class="Paragraph"><span>Contact </span></p>
                <p class="Paragraph"><span>Device</span></p></td>
            <td style="width:33.34%; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">
                <p class="Paragraph"><span>Your consent </span></p></td>
        </tr>
        <tr style="height:23.6pt">
            <td style="width:33.32%; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">
                <p class="Paragraph"><span>To process in-App purchases and deliver Services including managing payments
                    and collecting money owed to us </span></p></td>
            <td style="width:33.34%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border:0.5pt single">
                <p class="Paragraph"><span>Identity </span></p>
                <p class="Paragraph"><span>Contact </span></p>
                <p class="Paragraph"><span>Transaction</span></p>
                <p class="Paragraph"><span>Device</span></p>
                <p class="Paragraph"><span>Marketing and Communications </span></p>
                <p class="Paragraph"><span>Location</span></p></td>
            <td style="width:33.34%; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">
                <p class="Paragraph"><span>Your consent</span></p>
                <p class="Paragraph"><span>Performance of a contract with you </span></p>
                <p class="Paragraph"><span>Necessary for our legitimate interests (to recover debts due to us)</span>
                </p></td>
        </tr>
        <tr style="height:23.6pt">
            <td style="width:33.32%; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">
                <p class="Paragraph"><span>To manage our relationship with you including notifying you of changes to the
                    App or any Services </span></p></td>
            <td style="width:33.34%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border:0.5pt single">
                <p class="Paragraph"><span>Identity </span></p>
                <p class="Paragraph"><span>Contact </span></p>
                <p class="Paragraph"><span>Profile </span></p>
                <p class="Paragraph"><span>Marketing and Communications </span></p></td>
            <td style="width:33.34%; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">
                <p class="Paragraph"><span>Your consent</span></p>
                <p class="Paragraph"><span>Performance of a contract with you </span></p>
                <p class="Paragraph"><span>Necessary for our legitimate interests (to keep records updated and to
                    analyse how customers use our products/ Services)</span></p>
                <p class="Paragraph"><span>Necessary to comply with legal obligations (to inform you of any changes to
                    our terms and conditions) </span></p></td>
        </tr>
        <tr style="height:22.95pt">
            <td style="width:33.32%; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">
                <p class="Paragraph"><span>To enable you to participate in a prize draw, competition or complete a
                    survey </span></p></td>
            <td style="width:33.34%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border:0.5pt single">
                <p class="Paragraph"><span>Identity</span></p>
                <p class="Paragraph"><span>Contact</span></p>
                <p class="Paragraph"><span>Device </span></p>
                <p class="Paragraph"><span>Profile </span></p>
                <p class="Paragraph"><span>Marketing and Communications</span></p></td>
            <td style="width:33.34%; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">
                <p class="Paragraph"><span>Your consent </span></p>
                <p class="Paragraph"><span>Performance of a contract with you</span></p>
                <p class="Paragraph"><span>Necessary for our legitimate interests (to analyse how customers use our
                    products/Services and to develop them and grow our business)</span></p></td>
        </tr>
        <tr style="height:23.6pt">
            <td style="width:33.32%; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">
                <p class="Paragraph"><span>To administer and protect our business and this App including
                    troubleshooting, data analysis and system testing</span></p></td>
            <td style="width:33.34%; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border:0.5pt single">
                <p class="Paragraph"><span>Identity </span></p>
                <p class="Paragraph"><span>Contact</span></p>
                <p class="Paragraph"><span>Device </span></p></td>
            <td style="width:33.34%; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">
                <p class="Paragraph"><span>Necessary for our legitimate interests (for running our business, provision
                    of administration and IT services, network security) </span></p></td>
        </tr>
        <tr style="height:22.95pt">
            <td style="width:33.32%; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">
                <p class="Paragraph"><span>To deliver content and advertisements to you </span></p>
                <p class="Paragraph"><span>To make recommendations to you about goods or services which may interest
                    you</span></p>
                <p class="Paragraph"><span>To measure and analyse the effectiveness of the advertising we serve
                    you </span></p>
                <p class="Paragraph"><span>To monitor trends so we can improve the App</span></p></td>
            <td style="width:33.34%; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-left:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">
                <p class="Paragraph"><span>Identity </span></p>
                <p class="Paragraph"><span>Contact </span></p>
                <p class="Paragraph"><span>Device </span></p>
                <p class="Paragraph"><span>Content </span></p>
                <p class="Paragraph"><span>Profile </span></p>
                <p class="Paragraph"><span>Usage </span></p>
                <p class="Paragraph"><span>Marketing and Communications </span></p>
                <p class="Paragraph"><span>Location </span></p></td>
            <td style="width:33.34%; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">
                <p class="Paragraph"><span>Consent </span></p>
                <p class="Paragraph"><span>Necessary for our legitimate interests (to develop our products/Services and
                    grow our business)</span></p></td>
        </tr>
        </tbody>
    </table>
    <p class="NoNumTitle-Clause" style="margin-left:0pt"><a name="a899211"><span>Disclosures of your personal
        data</span></a></p>
    <p class="ParaClause" style="margin-left:0pt"><span>When you consent to providing us with your personal data, we
        will also ask you for your consent to share your personal data with the third parties set out below for the
        purposes set out in the table </span><span style="font-style:italic">Purposes for which we will use your
        personal data</span><span>:</span></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Internal Third Parties as set out in the </span><span
                style="font-family:Arial; font-style:italic"
        >Glossary</span><span style="font-family:Arial">.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">External Third Parties as set out in the </span><span
                style="font-family:Arial; font-style:italic"
        >Glossary</span><span style="font-family:Arial">.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Specific third parties listed in the table </span><span
                style="font-family:Arial; font-style:italic"
        >Purposes for which we will use your personal data</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Third parties to whom we may choose to sell, transfer or merge parts of our
            business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a
            change happens to our business, then the new owners may use your personal data in the same way as set out in
            this privacy policy.</span></li>
    </ul>
    <p class="NoNumTitle-Clause" style="margin-left:0pt"><a name="a333300"><span>International transfers</span></a></p>
    <p class="ParaClause" style="margin-left:0pt"><span>Many of our external third parties are based outside the UK so
        their processing of your personal data will involve a transfer of data outside the UK.</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>Whenever we transfer your personal data out of the UK, we ensure
        a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is
        implemented:</span></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">We will only transfer your personal data to countries that have been deemed to
            provide an adequate level of protection for personal data with reference to the protection provided in the
            UK.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">Where we use certain service providers, we may use specific contracts approved
            by the UK which give personal data the same protection it has in the UK. </span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial">We enquire and seek information from third parties where personal data is
            internationally transferred to verify internationally recognised certifications and procedures for the
            protection of personal data before any third party is allowed to transfer personal data
            internationally. </span></li>
    </ul>
    <p class="ParaClause" style="margin-left:0pt"><span>Please contact us if you want further information on the
        specific mechanism used by us when transferring your personal data out of the UK.</span></p>
    <p class="NoNumTitle-Clause" style="margin-left:0pt"><a name="a466576"><span>Data security</span></a></p>
    <p class="ParaClause" style="margin-left:0pt"><span>All information you provide to us is stored on our secure
        servers. Any payment transactions carried out by our chosen third-party provider of payment processing services
        will be encrypted using Secured Sockets Layer technology or its equivalent. Where we have given you (or where
        you have chosen) a password that enables you to access certain parts of Our Sites, you are responsible for
        keeping this password confidential. We ask you not to share a password with anyone.</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>Once we have received your information, we will use strict
        procedures and security features to try to prevent your personal data from being accidentally lost, used or
        accessed in an unauthorised way. </span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>We will collect and store personal data on your Device using
        application data caches and browser web storage (including HTML5) and other technology.</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>Certain Services include social networking, chat room or forum
        features. Ensure when using these features that you do not submit any personal data that you do not want to be
        seen, collected or used by other users.</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>We have put in place procedures to deal with any suspected
        personal data breach and will notify you and any applicable regulator when we are legally required to do
        so.</span></p>
    <p class="NoNumTitle-Clause" style="margin-left:0pt"><a name="a939995"><span>Data retention</span></a></p>
    <p class="ParaClause" style="margin-left:0pt"><span>Details of retention periods for different aspects of your
        personal data are available in our Retention Policy which you can find on our website. </span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>By law we have to keep basic information about our customers
        (including Contact, Identity, and Transaction Data) for six years after they cease being customers for tax
        purposes.</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>In some circumstances you can ask us to delete your data: see
        Your legal rights below for further information.</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>In some circumstances we will anonymise your personal data (so
        that it can no longer be associated with you) for research or statistical purposes, in which case we may use
        this information indefinitely without further notice to you.</span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>In the event that you do not use the App for a period of 48
        months then we will treat the account as expired and your personal data may be deleted.</span></p>
    <p class="NoNumTitle-Clause" style="margin-left:0pt"><a name="a153233"><span>Your legal rights</span></a></p>
    <p class="ParaClause" style="margin-left:0pt"><span>Under certain circumstances you have the following rights under
        data protection laws in relation to your personal data. </span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>Please click on the links below to find out more about these
        rights: </span></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-style:italic">Request access to your personal data</span><span
                style="font-family:Arial"
        >.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-style:italic">Request correction of your personal data</span><span
                style="font-family:Arial"
        >.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-style:italic">Request erasure of your personal data</span><span
                style="font-family:Arial"
        >.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-style:italic">Object to processing of your personal data</span><span
                style="font-family:Arial"
        >.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-style:italic">Request restriction of processing your personal
            data</span><span style="font-family:Arial">.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-style:italic">Request transfer or your personal data</span><span
                style="font-family:Arial"
        >.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-style:italic">Right to withdraw consent</span><span
                style="font-family:Arial"
        >.</span></li>
    </ul>
    <p class="ParaClause" style="margin-left:0pt"><span>You also have the right to ask us not to continue to process
        your personal data for marketing purposes. </span></p>
    <p class="ParaClause" style="margin-left:0pt"><span>You can exercise any of these rights at any time by contacting
        us using the contact details aforementioned.</span></p>
    <p class="NoNumTitle-Clause" style="margin-left:0pt"><a name="a559215"><span>Glossary</span></a></p>
    <p class="NoNumTitlesubclause1" style="margin-left:0pt"><a name="a833766"><span>Lawful basis</span></a></p>
    <p class="Parasubclause1" style="margin-left:0pt"><span class="DefTerm">Consent</span><span> means processing your
        personal data where you have signified your agreement by a statement or clear opt-in to processing for a
        specific purpose. Consent will only be valid if it is a freely given, specific, informed and unambiguous
        indication of what you want. You can withdraw your consent at any time by contacting us. </span></p>
    <p class="Parasubclause1" style="margin-left:0pt"><span class="DefTerm">Legitimate Interest</span><span> means the
        interest of our business in conducting and managing our business to enable us to give you the best
        service/product and the best and most secure experience. We make sure we consider and balance any potential
        impact on you (both positive and negative) and your rights before we process your personal data for our
        legitimate interests. We do not use your personal data for activities where our interests are overridden by the
        impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain
        further information about how we assess our legitimate interests against any potential impact on you in respect
        of specific activities by contacting us.</span></p>
    <p class="Parasubclause1" style="margin-left:0pt"><span class="DefTerm">Performance of Contract</span><span> means
        processing your data where it is necessary for the performance of a contract to which you are a party or to take
        steps at your request before entering into such a contract.</span></p>
    <p class="Parasubclause1" style="margin-left:0pt"><span class="DefTerm">Comply with a legal obligation</span><span>
        means processing your personal data where it is necessary for compliance with a legal obligation that we are
        subject to.</span></p>
    <p class="NoNumTitlesubclause1" style="margin-left:0pt"><a name="a933656"><span>Third parties</span></a></p>
    <p class="NoNumTitlesubclause1" style="margin-left:0pt"><a name="a402979"><span class="DefTerm"
                                                                                    style="font-weight:normal"
    >Internal third parties</span></a></p>
    <p class="Parasubclause1" style="margin-left:0pt"><span>Other companies in the Legal Utopia Group acting as joint
        controllers or processors and who are based in England and Wales and provide IT and system administration
        services and undertake leadership reporting.</span></p>
    <p class="NoNumTitlesubclause1" style="margin-left:0pt"><a name="a569398"><span class="DefTerm"
                                                                                    style="font-weight:normal"
    >External third parties</span></a></p>
    <p class="Parasubclause1" style="margin-left:0pt"><span>Service providers acting as processors based in USA, Canada,
        India, Israel, Mexico, Europe, EEA, and the EU who provide IT, software and system administration
        services.</span></p>
    <p class="Parasubclause1" style="margin-left:0pt"><span>Professional advisers acting as processors or joint
        controllers including lawyers, bankers, auditors and insurers based in and outside of the UK who provide
        consultancy, banking, legal, insurance and accounting services.</span></p>
    <p class="Parasubclause1" style="margin-left:0pt"><span>HM Revenue and Customs, regulators and other authorities
        acting as processors or joint controllers based in the UK who require reporting of processing activities in
        certain circumstances.</span></p>
    <p class="NoNumTitlesubclause1" style="margin-left:0pt"><a name="a825701"><span class="DefTerm"
                                                                                    style="font-weight:normal"
    >Your legal rights</span></a></p>
    <p class="Parasubclause1" style="margin-left:0pt"><span>You have the right to:</span></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li class="subclause1Bullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Request access</span><span style="font-family:Arial"> to your
            personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the
            personal data we hold about you and to check that we are lawfully processing it.</span></li>
        <li class="subclause1Bullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Request correction</span><span style="font-family:Arial"> of
            the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold
            about you corrected, though we may need to verify the accuracy of the new data you provide to us.</span>
        </li>
        <li class="subclause1Bullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Request erasure</span><span style="font-family:Arial"> of
            your personal data. This enables you to ask us to delete or remove personal data where there is no good
            reason for us continuing to process it. You also have the right to ask us to delete or remove your personal
            data where you have successfully exercised your right to object to processing (see below), where we may have
            processed your information unlawfully or where we are required to erase your personal data to comply with
            local law. Note, however, that we may not always be able to comply with your request of erasure for specific
            legal reasons which will be notified to you, if applicable, at the time of your request.</span></li>
        <li class="subclause1Bullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Object to processing</span><span style="font-family:Arial">
            of your personal data where we are relying on a legitimate interest (or those of a third party) and there is
            something about your particular situation which makes you want to object to processing on this ground as you
            feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are
            processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have
            compelling legitimate grounds to process your information which override your rights and freedoms.</span>
        </li>
        <li class="subclause1Bullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Request restriction of processing</span><span
                style="font-family:Arial"
        > of your personal data. This enables you to ask us to suspend the processing of your personal data in the
            following scenarios:</span></li>
    </ul>
    <p class="Untitledsubclause2"
       style="-aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'(%2)'; -aw-list-number-styles:'lowerLetter'; -aw-list-number-values:'1'; -aw-list-padding-sml:14.61pt"
    ><span style="-aw-import:ignore"><span>(a)</span><span
            style="width:14.61pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><a name="a853264"><span>if you want us to
        establish the data's accuracy;</span></a></p>
    <p class="Untitledsubclause2"
       style="-aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'(%2)'; -aw-list-number-styles:'lowerLetter'; -aw-list-number-values:'2'; -aw-list-padding-sml:14.61pt"
    ><span style="-aw-import:ignore"><span>(b)</span><span
            style="width:14.61pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><a name="a400167"><span>where our use of the
        data is unlawful but you do not want us to erase it;</span></a></p>
    <p class="Untitledsubclause2"
       style="-aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'(%2)'; -aw-list-number-styles:'lowerLetter'; -aw-list-number-values:'3'; -aw-list-padding-sml:15.22pt"
    ><span style="-aw-import:ignore"><span>(c)</span><span
            style="width:15.22pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><a name="a637190"><span>where you need us to
        hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims;
        or</span></a></p>
    <p class="Untitledsubclause2"
       style="-aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'(%2)'; -aw-list-number-styles:'lowerLetter'; -aw-list-number-values:'4'; -aw-list-padding-sml:14.61pt"
    ><span style="-aw-import:ignore"><span>(d)</span><span
            style="width:14.61pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces"
    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><a name="a427112"><span>you have objected to
        our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</span></a>
    </p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li class="subclause1Bullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Request the transfer</span><span style="font-family:Arial">
            of your personal data to you or to a third party. We will provide to you, or a third party you have chosen,
            your personal data in a structured, commonly used, machine-readable format. Note that this right only
            applies to automated information which you initially provided consent for us to use or where we used the
            information to perform a contract with you.</span></li>
        <li class="subclause1Bullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Withdraw consent at any time</span><span
                style="font-family:Arial"
        > where we are relying on consent to process your personal data. However, this will not affect the lawfulness of
            any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be
            able to provide certain products or services to you. We will advise you if this is the case at the time you
            withdraw your consent.</span></li>
    </ul>
    <p class="NoNumTitle-Clause" style="margin-left:0pt"><a name="a480810"><span>Description of categories of personal
        data</span></a></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Identity Data</span><span style="font-family:Arial">: first
            name, last name, maiden name, username or similar identifier, marital status, title, date of birth,
            gender.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Contact Data</span><span style="font-family:Arial">: billing
            address, delivery address, email address and telephone numbers.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Transaction Data</span><span style="font-family:Arial">:
            includes details about payments to and from you and details of in-App purchases. </span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Device Data:</span><span style="font-family:Arial"> includes
            the type of mobile device you use, a unique device identifier (for example, your Device's IMEI number, the
            MAC address of the Device's wireless network interface, or the mobile phone number used by the Device),
            mobile network information, your mobile operating system, the type of mobile browser you use, and time zone
            setting.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Content Data:</span><span style="font-family:Arial"> includes
            information stored on your Device, including friends' lists, login information, photos, videos or other
            digital content, and check-ins.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Profile Data</span><span style="font-family:Arial">: includes
            your username and password, in-App purchase history, your interests, preferences, feedback and survey
            responses.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Usage Data</span><span style="font-family:Arial">: includes
            details of your use of any of our Apps or your visits to any of Our Sites including, but not limited to,
            traffic data and other communication data, whether this is required for our own billing purposes or
            otherwise and the resources that you access.</span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Marketing and Communications Data</span><span
                style="font-family:Arial"
        >: includes your preferences in receiving marketing from us and our third parties and your communication
            preferences. </span></li>
        <li class="ClauseBullet1"
            style="margin-left:46.06pt; text-indent:0pt; padding-left:7.79pt; font-family:serif; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''"
        ><span style="font-family:Arial; font-weight:bold">Location Data</span><span style="font-family:Arial">:
            includes your current location disclosed by GPS technology or alike technologies, or the location inputted
            or otherwise disclosed by you or your device or surrounding connected devices. </span></li>
    </ul>
    <div style="-aw-headerfooter-type:footer-primary; clear:both"><p style="text-align:center"><span
            style="-aw-field-start:true"
    ></span><span style="-aw-field-code:'PAGE'"></span><span style="-aw-field-separator:true"></span><span>1</span><span
            style="-aw-field-end:true"
    ></span></p></div>
</div>` }} className="container content-page mx-4"
        />

    );
}
