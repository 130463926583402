import { useMemo } from 'react'
import humanize from 'humanize'
import { AppState } from 'common/state-type'

const DATE_FORMAT = 'jS M Y'

export const useLegalDocumentMetadata = (
  legalDocument?: AppState['legalDocument'],
) => {
  return useMemo(() => {
    if (!legalDocument) {
      return []
    }

    return [
      legalDocument.source && {
        key: 'Source',
        value: legalDocument.source.name,
      },
      legalDocument.publisher && {
        key: 'Publisher',
        value: legalDocument.publisher.name,
      },
      legalDocument.created_at && {
        key: 'Date Uploaded',
        value: humanize.date(DATE_FORMAT, new Date(legalDocument.created_at)),
      },
      legalDocument.updated_at && {
        key: 'Date Updated',
        value: humanize.date(DATE_FORMAT, new Date(legalDocument.updated_at)),
      },
      legalDocument.use && {
        key: 'Use',
        value: legalDocument.use.name,
      },
      legalDocument.type && {
        key: 'Status',
        value: legalDocument.type.name,
      },
      legalDocument.jurisdiction && {
        key: 'Jurisdiction',
        value: legalDocument.jurisdiction.name,
      },
      legalDocument.language && {
        key: 'Language',
        value: legalDocument.language.name,
      },
    ]
  }, [legalDocument])
}
