import { Popup } from 'reactjs-popup'
import { AiFillInfoCircle } from 'react-icons/ai'
import { MouseEvent } from 'react'

type InfoIconPopupProps = {
  onOpen: () => void
  onClose: () => void
  onTriggerClick?: (evt: MouseEvent<HTMLElement>) => void
  isOpen: boolean
  text: string
  className?: string
}

export const InfoIconPopup = ({
  onOpen,
  onClose,
  onTriggerClick,
  isOpen,
  text,
  className,
}: InfoIconPopupProps) => (
  <Popup
    className={className}
    onOpen={onOpen}
    onClose={onClose}
    trigger={
      <div onClick={onTriggerClick}>
        <AiFillInfoCircle
          color={isOpen ? '#66c2d7' : '#94a3b8'}
          size={16}
          className='ml-1'
        />
      </div>
    }
    position='right bottom'
    on={['hover', 'focus']}
    arrow={false}
  >
    <div className='inline-block text-sm font-light text-white rounded-lg border border-transparent shadow-sm max-w-[16rem] p-2 bg-lue-blue-500'>
      <p>{text}</p>
    </div>
  </Popup>
)
