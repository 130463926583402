import { useDispatch, useSelector } from 'react-redux'
import { AppActions } from '../app-actions'
import { AppState } from '../state-type'
import { useCallback } from 'react'

type UseInviteLink = {
  inviteLink: AppState['inviteLink']
  acceptedLink: AppState['acceptedLink']
  setInviteLink: (data: { inviteLink?: string; acceptedLink?: string }) => void
}

export default function useInviteLink(): UseInviteLink {
  const { inviteLink, acceptedLink } = useSelector((state: AppState) => ({
    inviteLink: state.inviteLink,
    acceptedLink: state.acceptedLink,
  }))
  const dispatch = useDispatch()
  const setInviteLink = useCallback(
    (data) => {
      return dispatch(AppActions.setInviteLink(data))
    },
    [dispatch],
  )
  return {
    acceptedLink,
    inviteLink,
    setInviteLink,
  }
}
