import React from 'react'

interface IconButtonProps {
  Icon: any
  size: number
  color?: string
  backgroundColor?: string
  onClick: () => void
}

const IconButton: React.FC<IconButtonProps> = ({
  Icon,
  size,
  color,
  backgroundColor,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      style={backgroundColor ? { backgroundColor } : null}
      className={`flex bg-[#F0F0F0] rounded-full h-8 w-8 items-center justify-items-center justify-center`}
    >
      <Icon size={size} color={color ?? 'black'} />
    </button>
  )
}

export default IconButton
