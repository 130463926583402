import { SVGProps } from 'react'

export const DropdownArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='12'
    height='7'
    viewBox='0 0 12 7'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6.00002 7C5.86053 7 5.72089 6.94298 5.61439 6.82908L0.159852 0.995781C-0.053284 0.767845 -0.053284 0.398743 0.159852 0.170952C0.372988 -0.0568382 0.718124 -0.0569841 0.931123 0.170952L6.00002 5.59184L11.0689 0.170952C11.2821 -0.0569841 11.6272 -0.0569841 11.8402 0.170952C12.0532 0.398889 12.0533 0.767991 11.8402 0.995781L6.38566 6.82908C6.27916 6.94298 6.13952 7 6.00002 7Z'
      fill='currentColor'
    />
  </svg>
)
