import Cookies from 'js-cookie'

const AUTH_COOKIE_REDIRECT_KEY = 'auth-redirect'

export const setAuthRedirectCookie = (value: string) => {
  Cookies.set(AUTH_COOKIE_REDIRECT_KEY, value)
}

export const getAuthRedirectCookie = () => Cookies.get(AUTH_COOKIE_REDIRECT_KEY)

export const removeAuthRedirectCookie = () => {
  Cookies.remove(AUTH_COOKIE_REDIRECT_KEY)
}
