import { ChevronRightIcon } from "@heroicons/react/solid";
import { Disclosure } from "@headlessui/react";
import LawPagesData from "../data/LawPagesData";
import React from "react";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const LawPage = () => {
  let query = useQuery();
  let id:any = query.get('id');
  const filtered = LawPagesData.filter((data) => {
    return data.id == id;
  });
  return (
    <main className="flex flex-col grow text-[#264B85]">
      <div className="flex flex-col items-center justify-center w-full h-full">
        {filtered.map((data) => {
          return (
            <React.Fragment>
              <div className="mt-5 mx-5 md:m-0">
                <img src={data.img} className="max-w-full h-auto" />
              </div>
              <h1 className="font-bold text-[22px] md:text-[55px] leading-[71px] text-darkBlue mt-4 md:mt-8">
                {data.title}
              </h1>
              <p className="font-small  text-[10px] md:text-[16px]  text-[#777777] leading-[26px] pb-4 md:py-8 text-center md:px-[490px]">
                {data.captionText}
              </p>

              <hr className="w-full md:w-[800px] m-auto bg-[#65C2D7] border-[#65C2D7] border-[1px]" />
              <p className="font-medium text-[10px] md:text-[20px] leading-[14px] md:leading-[26px] text-[#000000] pt-4 md:py-8 px-4 md:px-14 md:text-center">
                {data.description}
                <br /> <br />
                Here is what {data.title} covers:
              </p>

              <div className="w-full px-4 md:px-14 md:pt-16 mb-10">
                <div className="mx-auto w-full  rounded-2xl bg-white">
                  {data.elements.map((element) => {
                    return (
                      <Disclosure key={element.id}>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full rounded-lg bg-white text-[22px] md:text-[36px] leading-[46px] font-bold py-2 md:py-4 text-lawBlue focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                              <div className="hidden md:block">
                                <ChevronRightIcon
                                    className={`${
                                        open ? "rotate-90 transform" : ""
                                    } h-10 w-10 mr-3 text-lawBlue`}
                                />
                              </div>

                              <span className="mt-1 text-left md:text-center">{element.title}</span>
                            </Disclosure.Button>
                            <Disclosure.Panel className="hidden md:block px-[54px] py-2 text-sm text-gray-500">
                              {element.description}
                            </Disclosure.Panel>
                            <p className="md:hidden leading-[14px] text-[10px]">
                              {element.description}
                            </p>
                          </>
                        )}
                      </Disclosure>
                    );
                  })}
                </div>
              </div>
            </React.Fragment>
          );
        })}
        {/* <div className="grid grid-cols-3 gap-2 auto-cols-max container mx-auto my-5">
          <img src={faqImage} className="h-auto w-auto" />
          <div className="col-span-1">
            <h1 className="font-bold text-5xl text-darkBlue mt-8">
              Frequently Asked Questions
            </h1>
          </div>
        </div> */}
      </div>
    </main>
  );
};

export default LawPage;
