import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseSetupMfa = {
  setupMfa: AppState['setupMfa']
  setupMfaLoading: AppState['setupMfaLoading']
  setupMfaSaving: AppState['setupMfaSaving']
  setupMfaError: AppState['setupMfaError']
  createSetupMfa: (
    data: RequestTypes['createSetupMfa'],
    callbacks?: Callbacks,
  ) => void
}

export default function useSetupMfa(): UseSetupMfa {
  const { setupMfa, setupMfaLoading, setupMfaSaving, setupMfaError } =
    useSelector((state: AppState) => ({
      setupMfa: state.setupMfa,
      setupMfaLoading: state.setupMfaLoading,
      setupMfaSaving: state.setupMfaSaving,
      setupMfaError: state.setupMfaError,
    }))
  const dispatch = useDispatch()

  const createSetupMfa = useCallback(
    (data: RequestTypes['createSetupMfa'], callbacks?: Callbacks) => {
      return dispatch(AppActions.createSetupMfa(data, callbacks))
    },
    [dispatch],
  )

  return {
    setupMfa,
    setupMfaLoading,
    setupMfaSaving,
    setupMfaError,
    createSetupMfa,
  }
}
