import { Amplify } from 'aws-amplify'
import { FaAngleLeft } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useLoadScript } from '@react-google-maps/api'

import Map from '../Map'
import {
  AccountType,
  BarristerProfile,
  LawyerListObject,
} from 'common/state-type'
import Project from 'common/project'
import useProfile from 'common/providers/useProfile'
import useFavourite from 'common/providers/useFavourite'
import LawyerProfileCard from '../lawyer/LawyerProfileCard'
import useLawyerProfiles from 'common/providers/useLawyerProfiles'
import { BookingPopup, CreateBooking, BookingProfileType } from '../booking'
import PaginationBar from 'components/CWP Sections/components/PaginationBar'
import ProfileCardMobile from 'components/CWP Sections/components/ProfileCardMobile'

const mileToKm = 1.60934 // mile to km
export type position = {
  lat: number
  lng: number
}

type RegionType = {
  latitude: number
  longitude: number
  latitudeDelta: number
  longitudeDelta: number
}

Amplify.configure(Project.cognitoWeb)
const isMobile = Utils.getWindowDimensions().width < 768

const LawfirmMembersList = () => {
  const navigate = useNavigate()
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDomcLY-xoazL9KnRBNN9b_oROG_SMO9m8',
  })

  const { createFavourite, deleteFavourite } = useFavourite()
  const {
    getLawyerProfiles,
    lawyerProfiles: lawyers,
    lawyerProfilesLoading,
  } = useLawyerProfiles()
  const { profile } = useProfile()
  const [isLoading, setisLoading] = useState(true)
  const [bookingProfile, setBookingProfile] = useState<BookingProfileType>(null)
  const params = useParams()
  const kilometers = 20 * mileToKm
  const delta = kilometers / 100
  const initialRegionValue = {
    latitude: 51.5074,
    longitude: 0.1278,
    latitudeDelta: delta,
    longitudeDelta: delta,
  }
  const [region, setRegion] = useState<RegionType>(initialRegionValue)
  const [activeMarker, setActiveMarker] = useState<string | null>(null)

  // Initially setting the center of map to user's geolocation
  useEffect(() => {
    if (navigator.geolocation) {
      (
        navigator.permissions?.query?.({ name: 'geolocation' }) ||
        Promise.resolve()
      ).then(() => {
        navigator.geolocation.getCurrentPosition((position) => {
          setRegion({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            latitudeDelta: delta,
            longitudeDelta: delta,
          })
        })
      })
    }
  }, [])

  useEffect(() => {
    getLawyerProfiles(
      { office: params.id, page_size: 30 },
      {
        onSuccess: () => {
          setisLoading(false)
        },
      },
    )
  }, [])

  const handleFavPress = (item: LawyerListObject, accountType: AccountType) => {
    if (profile?.id) {
      if (item.favourite_id) {
        const data = {
          id: item.favourite_id,
          type: accountType,
        }
        deleteFavourite(data)
      } else {
        const type = Utils.getUserType(accountType)
        createFavourite({ [type]: item.id })
      }
    } else {
      navigate('/login')
    }
  }

  const handleBookPress = (
    item: LawyerListObject | BarristerProfile,
    accountType: AccountType,
  ) => {
    setBookingProfile({ profile: item, lawyerType: accountType })
  }

  const handlePageClick = (clickEvent) => {
    if (clickEvent.isNext) {
      lawyers.next && getLawyerProfiles({ link: lawyers.next })
    }
    if (clickEvent.isPrevious) {
      lawyers.previous && getLawyerProfiles({ link: lawyers.previous })
    }
  }

  const renderContent = () => {
    if ((!lawyers && lawyerProfilesLoading) || isLoading) {
      return (
        <div className='flex h-[100%] items-center justify-center'>
          Loading....
        </div>
      )
    } else if (!!lawyers?.results?.length) {
      return (
        <>
          {lawyers.results.map((profile) => {
            return (
              <div key={profile.id}>
                <ProfileCardMobile
                  cardStyle={'mt-4'}
                  onMouseEnter={() => setActiveMarker(profile.id)}
                  latitude={region.latitude}
                  longitude={region.longitude}
                  data={profile as LawyerListObject}
                  onFavPress={() => handleFavPress(profile, AccountType.LAWYER)}
                  onBookNow={() =>
                    handleBookPress(
                      profile as LawyerListObject,
                      AccountType.LAWYER,
                    )
                  }
                  lawyerType='solicitor'
                />
                <LawyerProfileCard
                  cardStyle={'mt-4'}
                  className='hidden md:flex flex-1'
                  key={profile.id}
                  onMouseEnter={() => setActiveMarker(profile.id)}
                  latitude={region.latitude}
                  longitude={region.longitude}
                  data={profile}
                  onFavPress={() => handleFavPress(profile, AccountType.LAWYER)}
                  onBookNow={() => handleBookPress(profile, AccountType.LAWYER)}
                />
              </div>
            )
          })}
        </>
      )
    } else {
      return (
        <div className='flex flex-1 items-center justify-center text-xl'>
          No Lawyer Found
        </div>
      )
    }
  }

  return (
    <>
      <div className='bg-gray-200 w-full p-2'>
        <button
          onClick={() => {
            navigate('/dashboard')
          }}
          className='flex flex-row items-center'
        >
          <FaAngleLeft size={26} />
          <span className='pl-2'>Back</span>
        </button>
      </div>
      <div className='flex flex-row w-auto'>
      <div className='flex flex-col px-1 relative overfloww-scroll w-full md:w-[42.29vw] h-[100vh] bg-zinc-100 pb-8'>
          <div id='profile-list' className='py-4'>
            {renderContent()}
          </div>
          {(lawyers?.next || lawyers?.previous) && (
            <PaginationBar onClick={handlePageClick} />
          )}
        </div>
        {bookingProfile && (
          <BookingPopup>
            <CreateBooking
              lawyer={bookingProfile.profile}
              lawyerType={bookingProfile.lawyerType}
              handleClose={() => setBookingProfile(null)}
            />
          </BookingPopup>
        )}
        {isLoaded && !isMobile ? (
          <Map
            setActiveMarker={setActiveMarker}
            activeMarker={activeMarker}
            center={{
              lat: region.latitude,
              lng: region.longitude,
            }}
            lawyers={lawyers ?? null}
            barristers={null}
            offices={null}
          />
        ) : null}
      </div>
    </>
  )
}

export default LawfirmMembersList
