
import { TYPES_OF_LAWYER } from "data/typeOfLawyerData";
import { InputDropdown } from 'common/components/Inputs/InputDropdown';

type TypeOfLawyerInputProps = {
  value: string;
  onSelect: (value?: string) => void;
};

export const TypeOfLawyerInput = ({
  value,
  onSelect,
}: TypeOfLawyerInputProps) => (
  <InputDropdown
    isLarge
    className="lg:min-w-[240px] xl:min-w-[280px] lg:rounded-none lg:min-h-[84px]"
    name="type"
    placeholder="Lawyers"
    label="Type of Lawyer"
    value={value}
    options={TYPES_OF_LAWYER}
    onSelect={onSelect}
  />
);
