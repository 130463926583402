import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseLegalDocuments = {
  legalDocuments?: AppState['legalDocuments']
  legalDocumentsLoading: AppState['legalDocumentsLoading']
  legalDocumentsError: AppState['legalDocumentsError']
  getLegalDocuments: (
    data: RequestTypes['getLegalDocuments'],
    callbacks?: Callbacks,
  ) => void
}

export default function useLegalDocuments(): UseLegalDocuments {
  const { legalDocuments, legalDocumentsLoading, legalDocumentsError } =
    useSelector((state: AppState) => ({
      legalDocuments: state.legalDocuments,
      legalDocumentsLoading: state.legalDocumentsLoading,
      legalDocumentsError: state.legalDocumentsError,
    }))
  const dispatch = useDispatch()
  const getLegalDocuments = useCallback(
    (data: RequestTypes['getLegalDocuments'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getLegalDocuments(data, callbacks))
    },
    [dispatch],
  )
  return {
    legalDocuments,
    legalDocumentsLoading,
    legalDocumentsError,
    getLegalDocuments,
  }
}
