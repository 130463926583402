
import './index.css';
import './fonts.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'tw-elements';
import './project/polyfill'
import { Link } from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react'
import LanguageHandler from 'common/LanguageHandler'
import createStore from "common/store";
import { LinkProvider } from 'common/providers/LinkProvider';
import { AuthPageProvider } from 'common/providers/AuthPageProvider';
import vectorBg from './assets/vector-bg.png';
import luLogo from './assets/lu-logo.svg';
import { AccountType } from 'common/state-type';

const store = createStore().store
const persistor = createStore().persistor

const LinkComponent = ({ href, className, children }) => (
  <Link to={href} className={className}>{children}</Link>
)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
            <LanguageHandler>
              <LinkProvider LinkComponent={LinkComponent}>
                <AuthPageProvider
                  hasSocialSignIn
                  hasNameFields
                  hasMarketing
                  logoBackLink="/"
                  tagline="Find your lawyer"
                  backgroundImageSrc={vectorBg}
                  logoSrc={luLogo}
                  accountType={AccountType.CONSUMER}
                >
                  <App />
                </AuthPageProvider>
              </LinkProvider>
            </LanguageHandler>
        </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
