import { FC } from "react";

type MultilineType = {
  text?: string;
};

const Multiline: FC<MultilineType> = ({ text }) => {
  const parts = text?.split(/\n/g);
  return (
    <>
      {parts?.map((v, i) => (
        <div key={i + Math.random()}>
          <span>{v}</span>
          {parts!.length > 1 && i + 1 !== parts.length && <br />}
        </div>
      ))}
    </>
  )
};

export default Multiline;
