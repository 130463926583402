import { Children, ReactNode } from 'react'
import cn from 'classnames'

type SiteFooterLinksListSectionProps = {
  title: string
  children: ReactNode
}

export const SiteFooterLinksListSection = ({
  title,
  children,
}: SiteFooterLinksListSectionProps) => {
  const numberOfColumns = Children.toArray(children).length

  return (
    <section
      className={cn({
        'col-span-2': numberOfColumns === 2,
      })}
    >
      <h4 className='text-2xl leading-6  font-bold mb-5'>{title}</h4>
      <div
        className={cn('grid gap-x-6', {
          'grid-cols-2': numberOfColumns === 2,
        })}
      >
        {children}
      </div>
    </section>
  )
}
