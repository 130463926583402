import { AnyAction } from 'redux'
import { RequestTypes } from './state-type'

const BaseConstants = {
  LOGIN: 'LOGIN',
  LOGIN_LOADED: 'LOGIN_LOADED',
  LOGIN_ERROR: 'LOGIN_ERROR',
  REFRESH_TOKENS: 'REFRESH_TOKENS',

  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_LOADED: 'RESET_PASSWORD_LOADED',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',

  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_LOADED: 'CHANGE_PASSWORD_LOADED',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',

  REGISTER: 'REGISTER',
  REGISTER_LOADED: 'REGISTER_LOADED',
  REGISTER_ERROR: 'REGISTER_ERROR',

  STARTUP: 'STARTUP',
  STARTUP_LOADED: 'STARTUP_LOADED',
  STARTUP_ERROR: 'STARTUP_ERROR',

  LOGOUT: 'LOGOUT',
  CLEAR_USER: 'CLEAR_USER',
  REFRESH: 'REFRESH',

  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  CONFIRM_EMAIL_ERROR: 'CONFIRM_EMAIL_ERROR',
  CONFIRM_EMAIL_LOADED: 'CONFIRM_EMAIL_LOADED',

  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',
  UPDATE_USER_LOADED: 'UPDATE_USER_LOADED',

  SET_ACTIVE_SCREEN: 'SET_ACTIVE_SCREEN',
}
export interface Callbacks {
  onSuccess?: (data: any) => void
  onError?: (data: any) => void
}

const BaseActions = {
  login(data: Record<string, any>, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.LOGIN,
      data,
      ...callbacks,
    }
  },

  resetPassword(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.RESET_PASSWORD,
      data,
      ...callbacks,
    }
  },

  changePassword(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CHANGE_PASSWORD,
      data,
      ...callbacks,
    }
  },
  // STARTUP
  startup(data: Record<string, any>, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.STARTUP,
      data,
      ...callbacks,
    }
  },
  // REGISTER
  register(data: Record<string, any>, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.REGISTER,
      data,
      ...callbacks,
    }
  },
  updateUser(data: Record<string, any>, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.UPDATE_USER,
      data,
      ...callbacks,
    }
  },
  confirmEmail(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CONFIRM_EMAIL,
      data,
      ...callbacks,
    }
  },
  logout(data, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.LOGOUT,
      data,
      ...callbacks,
    }
  },
  setActiveScreen(name: string, navigator = 'root'): AnyAction {
    return {
      type: Actions.SET_ACTIVE_SCREEN,
      index: navigator,
      data: name,
    }
  },
}

export const Actions = (global.Actions = Object.assign({}, BaseConstants, {
  GET_PROFILE: 'GET_PROFILE',
  GET_PROFILE_LOADED: 'GET_PROFILE_LOADED',
  GET_PROFILE_ERROR: 'GET_PROFILE_ERROR',

  UPDATE_PROFILE: 'UPDATE_PROFILE',
  UPDATE_PROFILE_LOADED: 'UPDATE_PROFILE_LOADED',
  UPDATE_PROFILE_ERROR: 'UPDATE_PROFILE_ERROR',

  GET_FEEDBACK_SUMMARY: 'GET_FEEDBACK_SUMMARY',
  GET_FEEDBACK_SUMMARY_LOADED: 'GET_FEEDBACK_SUMMARY_LOADED',
  GET_FEEDBACK_SUMMARY_ERROR: 'GET_FEEDBACK_SUMMARY_ERROR',

  GET_FEEDBACK: 'GET_FEEDBACK',
  GET_FEEDBACK_LOADED: 'GET_FEEDBACK_LOADED',
  GET_FEEDBACK_ERROR: 'GET_FEEDBACK_ERROR',

  CREATE_FEEDBACK: 'CREATE_FEEDBACK',
  CREATE_FEEDBACK_LOADED: 'CREATE_FEEDBACK_LOADED',
  CREATE_FEEDBACK_ERROR: 'CREATE_FEEDBACK_ERROR',

  REDEEM: 'REDEEM',
  REDEEM_LOADED: 'REDEEM_LOADED',
  REDEEM_ERROR: 'REDEEM_ERROR',

  //Legal Checker
  GET_LEGAL_CHECKER: 'GET_LEGAL_CHECKER',
  GET_LEGAL_CHECKER_LOADED: 'GET_LEGAL_CHECKER_LOADED',
  GET_LEGAL_CHECKER_ERROR: 'GET_LEGAL_CHECKER_ERROR',
  SET_LEGAL_CHECKER_OPTIONS: 'SET_LEGAL_CHECKER_OPTIONS',
  SET_LEGAL_CHECKER_META: 'SET_LEGAL_CHECKER_META',
  GET_LEGAL_CHECKER_DOMAINS: 'GET_LEGAL_CHECKER_DOMAINS',
  GET_LEGAL_CHECKER_DOMAINS_LOADED: 'GET_LEGAL_CHECKER_DOMAINS_LOADED',
  GET_LEGAL_CHECKER_DOMAINS_ERROR: 'GET_LEGAL_CHECKER_DOMAINS_ERROR',
  GET_LEGAL_CHECKER_SUB_DOMAINS: 'GET_LEGAL_CHECKER_SUB_DOMAINS',
  GET_LEGAL_CHECKER_SUB_DOMAINS_LOADED: 'GET_LEGAL_CHECKER_SUB_DOMAINS_LOADED',
  GET_LEGAL_CHECKER_SUB_DOMAINS_ERROR: 'GET_LEGAL_CHECKER_SUB_DOMAINS_ERROR',

  GET_FAQS: 'GET_FAQS',
  GET_FAQS_LOADED: 'GET_FAQS_LOADED',
  GET_FAQS_ERROR: 'GET_FAQS_ERROR',

  SET_MENU_ICONS: 'SET_MENU_ICONS',

  CREATE_OAUTH: 'CREATE_OAUTH',

  'GET_QUESTIONS': 'GET_QUESTIONS',
  'GET_QUESTIONS_LOADED': 'GET_QUESTIONS_LOADED',
  'GET_QUESTIONS_ERROR': 'GET_QUESTIONS_ERROR',

  'GET_SCENARIOS': 'GET_SCENARIOS',
  'GET_SCENARIOS_LOADED': 'GET_SCENARIOS_LOADED',
  'GET_SCENARIOS_ERROR': 'GET_SCENARIOS_ERROR',

  'CREATE_LEGAL_CHECKER_SUBMISSION': 'CREATE_LEGAL_CHECKER_SUBMISSION',
  'CREATE_LEGAL_CHECKER_SUBMISSION_LOADED':
    'CREATE_LEGAL_CHECKER_SUBMISSION_LOADED',
  'CREATE_LEGAL_CHECKER_SUBMISSION_ERROR':
    'CREATE_LEGAL_CHECKER_SUBMISSION_ERROR',

  GET_LEGAL_CHECKER_RESULTS: 'GET_LEGAL_CHECKER_RESULTS',
  GET_LEGAL_CHECKER_RESULTS_LOADED: 'GET_LEGAL_CHECKER_RESULTS_LOADED',
  GET_LEGAL_CHECKER_RESULTS_ERROR: 'GET_LEGAL_CHECKER_RESULTS_ERROR',

  GET_LEGAL_CHECKER_SERVICES: 'GET_LEGAL_CHECKER_SERVICES',
  GET_LEGAL_CHECKER_SERVICES_LOADED: 'GET_LEGAL_CHECKER_SERVICES_LOADED',
  GET_LEGAL_CHECKER_SERVICES_ERROR: 'GET_LEGAL_CHECKER_SERVICES_ERROR',

  SET_INVITE_LINK: 'SET_INVITE_LINK',
  GET_LEGAL_CHECKER_PORTALS: 'GET_LEGAL_CHECKER_PORTALS',
  GET_LEGAL_CHECKER_PORTALS_LOADED: 'GET_LEGAL_CHECKER_PORTALS_LOADED',
  GET_LEGAL_CHECKER_PORTALS_ERROR: 'GET_LEGAL_CHECKER_PORTALS_ERROR',

  GET_LEGAL_CHECKER_GUIDANCE: 'GET_LEGAL_CHECKER_GUIDANCE',
  GET_LEGAL_CHECKER_GUIDANCE_LOADED: 'GET_LEGAL_CHECKER_GUIDANCE_LOADED',
  GET_LEGAL_CHECKER_GUIDANCE_ERROR: 'GET_LEGAL_CHECKER_GUIDANCE_ERROR',

  GET_LEGAL_CHECKER_RESOURCES: 'GET_LEGAL_CHECKER_RESOURCES',
  GET_LEGAL_CHECKER_RESOURCES_LOADED: 'GET_LEGAL_CHECKER_RESOURCES_LOADED',
  GET_LEGAL_CHECKER_RESOURCES_ERROR: 'GET_LEGAL_CHECKER_RESOURCES_ERROR',

  'CREATE_LEGAL_CHECKER_RESULT': 'CREATE_LEGAL_CHECKER_RESULT',
  'CREATE_LEGAL_CHECKER_RESULT_LOADED': 'CREATE_LEGAL_CHECKER_RESULT_LOADED',
  'CREATE_LEGAL_CHECKER_RESULT_ERROR': 'CREATE_LEGAL_CHECKER_RESULT_ERROR',

  'GET_LEGAL_CHECKER_RESULT': 'GET_LEGAL_CHECKER_RESULT',
  'GET_LEGAL_CHECKER_RESULT_LOADED': 'GET_LEGAL_CHECKER_RESULT_LOADED',
  'GET_LEGAL_CHECKER_RESULT_ERROR': 'GET_LEGAL_CHECKER_RESULT_ERROR',

  'UPDATE_LEGAL_CHECKER_RESULT': 'UPDATE_LEGAL_CHECKER_RESULT',
  'UPDATE_LEGAL_CHECKER_RESULT_LOADED': 'UPDATE_LEGAL_CHECKER_RESULT_LOADED',
  'UPDATE_LEGAL_CHECKER_RESULT_ERROR': 'UPDATE_LEGAL_CHECKER_RESULT_ERROR',

  'DELETE_LEGAL_CHECKER_RESULT': 'DELETE_LEGAL_CHECKER_RESULT',
  'DELETE_LEGAL_CHECKER_RESULT_LOADED': 'DELETE_LEGAL_CHECKER_RESULT_LOADED',
  'DELETE_LEGAL_CHECKER_RESULT_ERROR': 'DELETE_LEGAL_CHECKER_RESULT_ERROR',

  'ENABLE_COMMISSION': 'ENABLE_COMMISSION',
  'ENABLE_COMMISSION_LOADED': 'ENABLE_COMMISSION_LOADED',
  'ENABLE_COMMISSION_ERROR': 'ENABLE_COMMISSION_ERROR',

  'DISABLE_COMMISSION': 'DISABLE_COMMISSION',
  'DISABLE_COMMISSION_LOADED': 'DISABLE_COMMISSION_LOADED',
  'DISABLE_COMMISSION_ERROR': 'DISABLE_COMMISSION_ERROR',

  'GET_LEGAL_AID_QUESTIONS': 'GET_LEGAL_AID_QUESTIONS',
  'GET_LEGAL_AID_QUESTIONS_LOADED': 'GET_LEGAL_AID_QUESTIONS_LOADED',
  'GET_LEGAL_AID_QUESTIONS_ERROR': 'GET_LEGAL_AID_QUESTIONS_ERROR',

  'CREATE_GUIDANCE_FEEDBACK': 'CREATE_GUIDANCE_FEEDBACK',
  'CREATE_GUIDANCE_FEEDBACK_LOADED': 'CREATE_GUIDANCE_FEEDBACK_LOADED',
  'CREATE_GUIDANCE_FEEDBACK_ERROR': 'CREATE_GUIDANCE_FEEDBACK_ERROR',

  'UPDATE_GUIDANCE_FEEDBACK': 'UPDATE_GUIDANCE_FEEDBACK',
  'UPDATE_GUIDANCE_FEEDBACK_LOADED': 'UPDATE_GUIDANCE_FEEDBACK_LOADED',
  'UPDATE_GUIDANCE_FEEDBACK_ERROR': 'UPDATE_GUIDANCE_FEEDBACK_ERROR',
  'GET_LEGAL_AID_CONTENT': 'GET_LEGAL_AID_CONTENT',
  'GET_LEGAL_AID_CONTENT_LOADED': 'GET_LEGAL_AID_CONTENT_LOADED',
  'GET_LEGAL_AID_CONTENT_ERROR': 'GET_LEGAL_AID_CONTENT_ERROR',

  GET_LEGAL_CHECKER_FUTHER_GUIDANCE: 'GET_LEGAL_CHECKER_FUTHER_GUIDANCE',
  GET_LEGAL_CHECKER_FUTHER_GUIDANCE_LOADED:
    'GET_LEGAL_CHECKER_FUTHER_GUIDANCE_LOADED',
  GET_LEGAL_CHECKER_FUTHER_GUIDANCE_ERROR:
    'GET_LEGAL_CHECKER_FUTHER_GUIDANCE_ERROR',

  'CREATE_VERIFY_CREDIT_PURCHASE': 'CREATE_VERIFY_CREDIT_PURCHASE',
  'CREATE_VERIFY_CREDIT_PURCHASE_LOADED':
    'CREATE_VERIFY_CREDIT_PURCHASE_LOADED',
  'CREATE_VERIFY_CREDIT_PURCHASE_ERROR': 'CREATE_VERIFY_CREDIT_PURCHASE_ERROR',

  'GET_IAPS': 'GET_IAPS',
  'GET_IAPS_LOADED': 'GET_IAPS_LOADED',
  'GET_IAPS_ERROR': 'GET_IAPS_ERROR',

  'GET_SUBSCRIPTIONS': 'GET_SUBSCRIPTIONS',
  'GET_SUBSCRIPTIONS_LOADED': 'GET_SUBSCRIPTIONS_LOADED',
  'GET_SUBSCRIPTIONS_ERROR': 'GET_SUBSCRIPTIONS_ERROR',

  'GET_RESULT_CREDITS': 'GET_RESULT_CREDITS',
  'GET_RESULT_CREDITS_LOADED': 'GET_RESULT_CREDITS_LOADED',
  'GET_RESULT_CREDITS_ERROR': 'GET_RESULT_CREDITS_ERROR',

  'CREATE_UNLOCK': 'CREATE_UNLOCK',
  'CREATE_UNLOCK_LOADED': 'CREATE_UNLOCK_LOADED',
  'CREATE_UNLOCK_ERROR': 'CREATE_UNLOCK_ERROR',

  'CREATE_VIEW_RESULT': 'CREATE_VIEW_RESULT',
  'CREATE_VIEW_RESULT_LOADED': 'CREATE_VIEW_RESULT_LOADED',
  'CREATE_VIEW_RESULT_ERROR': 'CREATE_VIEW_RESULT_ERROR',

  'GET_LEGAL_DOCUMENT_TYPES': 'GET_LEGAL_DOCUMENT_TYPES',
  'GET_LEGAL_DOCUMENT_TYPES_LOADED': 'GET_LEGAL_DOCUMENT_TYPES_LOADED',
  'GET_LEGAL_DOCUMENT_TYPES_ERROR': 'GET_LEGAL_DOCUMENT_TYPES_ERROR',

  'GET_LEGAL_DOCUMENT_TEMPLATE_FILTERS': 'GET_LEGAL_DOCUMENT_TEMPLATE_FILTERS',
  'GET_LEGAL_DOCUMENT_TEMPLATE_FILTERS_LOADED':
    'GET_LEGAL_DOCUMENT_TEMPLATE_FILTERS_LOADED',
  'GET_LEGAL_DOCUMENT_TEMPLATE_FILTERS_ERROR':
    'GET_LEGAL_DOCUMENT_TEMPLATE_FILTERS_ERROR',

  'GET_LEGAL_DOCUMENTS': 'GET_LEGAL_DOCUMENTS',
  'GET_LEGAL_DOCUMENTS_LOADED': 'GET_LEGAL_DOCUMENTS_LOADED',
  'GET_LEGAL_DOCUMENTS_ERROR': 'GET_LEGAL_DOCUMENTS_ERROR',

  'GET_LEGAL_DOCUMENT': 'GET_LEGAL_DOCUMENT',
  'GET_LEGAL_DOCUMENT_LOADED': 'GET_LEGAL_DOCUMENT_LOADED',
  'GET_LEGAL_DOCUMENT_ERROR': 'GET_LEGAL_DOCUMENT_ERROR',

  'GET_LEGAL_DOCUMENT_RATINGS': 'GET_LEGAL_DOCUMENT_RATINGS',
  'GET_LEGAL_DOCUMENT_RATINGS_LOADED': 'GET_LEGAL_DOCUMENT_RATINGS_LOADED',
  'GET_LEGAL_DOCUMENT_RATINGS_ERROR': 'GET_LEGAL_DOCUMENT_RATINGS_ERROR',

  'GET_LEGAL_DOCUMENT_CODE': 'GET_LEGAL_DOCUMENT_CODE',
  'GET_LEGAL_DOCUMENT_CODE_LOADED': 'GET_LEGAL_DOCUMENT_CODE_LOADED',
  'GET_LEGAL_DOCUMENT_CODE_ERROR': 'GET_LEGAL_DOCUMENT_CODE_ERROR',

  'CREATE_LEGAL_DOCUMENT_RATING': 'CREATE_LEGAL_DOCUMENT_RATING',
  'CREATE_LEGAL_DOCUMENT_RATING_LOADED': 'CREATE_LEGAL_DOCUMENT_RATING_LOADED',
  'CREATE_LEGAL_DOCUMENT_RATING_ERROR': 'CREATE_LEGAL_DOCUMENT_RATING_ERROR',

  'UPDATE_LEGAL_DOCUMENT_RATING': 'UPDATE_LEGAL_DOCUMENT_RATING',
  'UPDATE_LEGAL_DOCUMENT_RATING_LOADED': 'UPDATE_LEGAL_DOCUMENT_RATING_LOADED',
  'UPDATE_LEGAL_DOCUMENT_RATING_ERROR': 'UPDATE_LEGAL_DOCUMENT_RATING_ERROR',

  'GET_GOVERNMENT_SERVICES': 'GET_GOVERNMENT_SERVICES',
  'GET_GOVERNMENT_SERVICES_LOADED': 'GET_GOVERNMENT_SERVICES_LOADED',
  'GET_GOVERNMENT_SERVICES_ERROR': 'GET_GOVERNMENT_SERVICES_ERROR',

  'CREATE_CLOSE_ACCOUNT': 'CREATE_CLOSE_ACCOUNT',
  'CREATE_CLOSE_ACCOUNT_LOADED': 'CREATE_CLOSE_ACCOUNT_LOADED',
  'CREATE_CLOSE_ACCOUNT_ERROR': 'CREATE_CLOSE_ACCOUNT_ERROR',

  'GET_COVID_REGULATIONS': 'GET_COVID_REGULATIONS',
  'GET_COVID_REGULATIONS_LOADED': 'GET_COVID_REGULATIONS_LOADED',
  'GET_COVID_REGULATIONS_ERROR': 'GET_COVID_REGULATIONS_ERROR',

  'CREATE_LEGAL_RESULT_FEEDBACK': 'CREATE_LEGAL_RESULT_FEEDBACK',
  'CREATE_LEGAL_RESULT_FEEDBACK_LOADED': 'CREATE_LEGAL_RESULT_FEEDBACK_LOADED',
  'CREATE_LEGAL_RESULT_FEEDBACK_ERROR': 'CREATE_LEGAL_RESULT_FEEDBACK_ERROR',

  'GET_INDUSTRIES': 'GET_INDUSTRIES',
  'GET_INDUSTRIES_LOADED': 'GET_INDUSTRIES_LOADED',
  'GET_INDUSTRIES_ERROR': 'GET_INDUSTRIES_ERROR',

  'GET_INVITE_TOKEN': 'GET_INVITE_TOKEN',
  'GET_INVITE_TOKEN_LOADED': 'GET_INVITE_TOKEN_LOADED',
  'GET_INVITE_TOKEN_ERROR': 'GET_INVITE_TOKEN_ERROR',

  'GET_AREAS_OF_PRACTICE': 'GET_AREAS_OF_PRACTICE',
  'GET_AREAS_OF_PRACTICE_LOADED': 'GET_AREAS_OF_PRACTICE_LOADED',
  'GET_AREAS_OF_PRACTICE_ERROR': 'GET_AREAS_OF_PRACTICE_ERROR',

  'GET_OFFICES': 'GET_OFFICES',
  'GET_OFFICES_LOADED': 'GET_OFFICES_LOADED',
  'GET_OFFICES_ERROR': 'GET_OFFICES_ERROR',

  'GET_OFFICE': 'GET_OFFICE',
  'GET_OFFICE_LOADED': 'GET_OFFICE_LOADED',
  'GET_OFFICE_ERROR': 'GET_OFFICE_ERROR',

  'GET_AREA_CATEGORIES': 'GET_AREA_CATEGORIES',
  'GET_AREA_CATEGORIES_LOADED': 'GET_AREA_CATEGORIES_LOADED',
  'GET_AREA_CATEGORIES_ERROR': 'GET_AREA_CATEGORIES_ERROR',

  'GET_DOCUMENT_CATEGORIES': 'GET_DOCUMENT_CATEGORIES',
  'GET_DOCUMENT_CATEGORIES_LOADED': 'GET_DOCUMENT_CATEGORIES_LOADED',
  'GET_DOCUMENT_CATEGORIES_ERROR': 'GET_DOCUMENT_CATEGORIES_ERROR',

  'GET_DOCUMENT_FILTERS': 'GET_DOCUMENT_FILTERS',
  'GET_DOCUMENT_FILTERS_LOADED': 'GET_DOCUMENT_FILTERS_LOADED',
  'GET_DOCUMENT_FILTERS_ERROR': 'GET_DOCUMENT_FILTERS_ERROR',

  'GET_LAWYER_AVAILABILITY': 'GET_LAWYER_AVAILABILITY',
  'GET_LAWYER_AVAILABILITY_LOADED': 'GET_LAWYER_AVAILABILITY_LOADED',
  'GET_LAWYER_AVAILABILITY_ERROR': 'GET_LAWYER_AVAILABILITY_ERROR',

  'CREATE_CHALLENGE_NAME': 'CREATE_CHALLENGE_NAME',

  'CREATE_PASSWORD': 'CREATE_PASSWORD',
  'CREATE_PASSWORD_LOADED': 'CREATE_PASSWORD_LOADED',
  'CREATE_PASSWORD_ERROR': 'CREATE_PASSWORD_ERROR',

  'GET_LAWYERS_PROFILE': 'GET_LAWYERS_PROFILE',
  'GET_LAWYERS_PROFILE_LOADED': 'GET_LAWYERS_PROFILE_LOADED',
  'GET_LAWYERS_PROFILE_ERROR': 'GET_LAWYERS_PROFILE_ERROR',

  'CREATE_CRONOFY_AUTH_LINK': 'CREATE_CRONOFY_AUTH_LINK',
  'CREATE_CRONOFY_AUTH_LINK_LOADED': 'CREATE_CRONOFY_AUTH_LINK_LOADED',
  'CREATE_CRONOFY_AUTH_LINK_ERROR': 'CREATE_CRONOFY_AUTH_LINK_ERROR',

  'CREATE_AUTH_WITH_CRONOFY': 'CREATE_AUTH_WITH_CRONOFY',
  'CREATE_AUTH_WITH_CRONOFY_LOADED': 'CREATE_AUTH_WITH_CRONOFY_LOADED',
  'CREATE_AUTH_WITH_CRONOFY_ERROR': 'CREATE_AUTH_WITH_CRONOFY_ERROR',

  'CREATE_SETUP_MFA': 'CREATE_SETUP_MFA',
  'CREATE_SETUP_MFA_LOADED': 'CREATE_SETUP_MFA_LOADED',
  'CREATE_SETUP_MFA_ERROR': 'CREATE_SETUP_MFA_ERROR',

  'CREATE_VERIFY_MFA': 'CREATE_VERIFY_MFA',
  'CREATE_VERIFY_MFA_LOADED': 'CREATE_VERIFY_MFA_LOADED',
  'CREATE_VERIFY_MFA_ERROR': 'CREATE_VERIFY_MFA_ERROR',

  'CREATE_CRONOFY_UI_TOKEN': 'CREATE_CRONOFY_UI_TOKEN',
  'CREATE_CRONOFY_UI_TOKEN_LOADED': 'CREATE_CRONOFY_UI_TOKEN_LOADED',
  'CREATE_CRONOFY_UI_TOKEN_ERROR': 'CREATE_CRONOFY_UI_TOKEN_ERROR',

  'GET_LAWYER_PROFILES': 'GET_LAWYER_PROFILES',
  'GET_LAWYER_PROFILES_LOADED': 'GET_LAWYER_PROFILES_LOADED',
  'GET_LAWYER_PROFILES_ERROR': 'GET_LAWYER_PROFILES_ERROR',

  'CREATE_CONFIRM_SIGNIN': 'CREATE_CONFIRM_SIGNIN',
  'CREATE_CONFIRM_SIGNIN_LOADED': 'CREATE_CONFIRM_SIGNIN_LOADED',
  'CREATE_CONFIRM_SIGNIN_ERROR': 'CREATE_CONFIRM_SIGNIN_ERROR',

  'GET_BOOKINGS': 'GET_BOOKINGS',
  'GET_BOOKINGS_LOADED': 'GET_BOOKINGS_LOADED',
  'GET_BOOKINGS_ERROR': 'GET_BOOKINGS_ERROR',

  'GET_ACCEPTED_BOOKINGS': 'GET_ACCEPTED_BOOKINGS',
  'GET_ACCEPTED_BOOKINGS_LOADED': 'GET_ACCEPTED_BOOKINGS_LOADED',
  'GET_ACCEPTED_BOOKINGS_ERROR': 'GET_ACCEPTED_BOOKINGS_ERROR',

  'GET_PENDING_BOOKINGS': 'GET_PENDING_BOOKINGS',
  'GET_PENDING_BOOKINGS_LOADED': 'GET_PENDING_BOOKINGS_LOADED',
  'GET_PENDING_BOOKINGS_ERROR': 'GET_PENDING_BOOKINGS_ERROR',

  'GET_DECLINED_BOOKINGS': 'GET_DECLINED_BOOKINGS',
  'GET_DECLINED_BOOKINGS_LOADED': 'GET_DECLINED_BOOKINGS_LOADED',
  'GET_DECLINED_BOOKINGS_ERROR': 'GET_DECLINED_BOOKINGS_ERROR',

  'SET_BOOK_A_LAWYER_APPOINTMENT': 'SET_BOOK_A_LAWYER_APPOINTMENT',

  'CREATE_ZOOM_AUTH_LINK': 'CREATE_ZOOM_AUTH_LINK',
  'CREATE_ZOOM_AUTH_LINK_LOADED': 'CREATE_ZOOM_AUTH_LINK_LOADED',
  'CREATE_ZOOM_AUTH_LINK_ERROR': 'CREATE_ZOOM_AUTH_LINK_ERROR',

  'GET_COMPANIES': 'GET_COMPANIES',
  'GET_COMPANIES_LOADED': 'GET_COMPANIES_LOADED',
  'GET_COMPANIES_ERROR': 'GET_COMPANIES_ERROR',

  'GET_STRIPE_PRODUCT_DETAILS': '_STRIPE_PRODUCT_DETAILS',
  'GET_STRIPE_PRODUCT_DETAILS_LOADED': 'GET_STRIPE_PRODUCT_DETAILS_LOADED',
  'GET_STRIPE_PRODUCT_DETAILS_ERROR': 'GET_STRIPE_PRODUCT_DETAILS_ERROR',

  'GET_STRIPE_CHECKOUT_URL': 'GET_STRIPE_CHECKOUT_URL',
  'GET_STRIPE_CHECKOUT_URL_LOADED': 'GET_STRIPE_CHECKOUT_URL_LOADED',
  'GET_STRIPE_CHECKOUT_URL_ERROR': 'GET_STRIPE_CHECKOUT_URL_ERROR',

  'CREATE_ACTION_APPOINTMENT': 'CREATE_ACTION_APPOINTMENT',
  'CREATE_ACTION_APPOINTMENT_LOADED': 'CREATE_ACTION_APPOINTMENT_LOADED',
  'CREATE_ACTION_APPOINTMENT_ERROR': 'CREATE_ACTION_APPOINTMENT_ERROR',

  'GET_CRONOFY_CONFERENCING_PROFILES': 'GET_CRONOFY_CONFERENCING_PROFILES',
  'GET_CRONOFY_CONFERENCING_PROFILES_LOADED':
    'GET_CRONOFY_CONFERENCING_PROFILES_LOADED',
  'GET_CRONOFY_CONFERENCING_PROFILES_ERROR':
    'GET_CRONOFY_CONFERENCING_PROFILES_ERROR',

  'GET_BOOKING': 'GET_BOOKING',
  'GET_BOOKING_LOADED': 'GET_BOOKING_LOADED',
  'GET_BOOKING_ERROR': 'GET_BOOKING_ERROR',

  'GET_LAWYER_MEMBERSHIP_TYPES': 'GET_LAWYER_MEMBERSHIP_TYPES',
  'GET_LAWYER_MEMBERSHIP_TYPES_LOADED': 'GET_LAWYER_MEMBERSHIP_TYPES_LOADED',
  'GET_LAWYER_MEMBERSHIP_TYPES_ERROR': 'GET_LAWYER_MEMBERSHIP_TYPES_ERROR',

  'GET_LAWYER_QUALIFICATION_TYPES': 'GET_LAWYER_QUALIFICATION_TYPES',
  'GET_LAWYER_QUALIFICATION_TYPES_LOADED':
    'GET_LAWYER_QUALIFICATION_TYPES_LOADED',
  'GET_LAWYER_QUALIFICATION_TYPES_ERROR':
    'GET_LAWYER_QUALIFICATION_TYPES_ERROR',

  'GET_DETAILED_LAWYER_PROFILE': 'GET_DETAILED_LAWYER_PROFILE',
  'GET_DETAILED_LAWYER_PROFILE_LOADED': 'GET_DETAILED_LAWYER_PROFILE_LOADED',
  'GET_DETAILED_LAWYER_PROFILE_ERROR': 'GET_DETAILED_LAWYER_PROFILE_ERROR',

  'GET_BARRISTERS': 'GET_BARRISTERS',
  'GET_BARRISTERS_LOADED': 'GET_BARRISTERS_LOADED',
  'GET_BARRISTERS_ERROR': 'GET_BARRISTERS_ERROR',

  'GET_BARRISTER': 'GET_BARRISTER',
  'GET_BARRISTER_LOADED': 'GET_BARRISTER_LOADED',
  'GET_BARRISTER_ERROR': 'GET_BARRISTER_ERROR',

  'GET_BARRISTER_HONOURS': 'GET_BARRISTER_HONOURS',
  'GET_BARRISTER_HONOURS_LOADED': 'GET_BARRISTER_HONOURS_LOADED',
  'GET_BARRISTER_HONOURS_ERROR': 'GET_BARRISTER_HONOURS_ERROR',

  'GET_BARRISTERS_SEARCH': 'GET_BARRISTERS_SEARCH',
  'GET_BARRISTERS_SEARCH_LOADED': 'GET_BARRISTERS_SEARCH_LOADED',
  'GET_BARRISTERS_SEARCH_ERROR': 'GET_BARRISTERS_SEARCH_ERROR',

  'UPDATE_BARRISTER': 'UPDATE_BARRISTER',
  'UPDATE_BARRISTER_LOADED': 'UPDATE_BARRISTER_LOADED',
  'UPDATE_BARRISTER_ERROR': 'UPDATE_BARRISTER_ERROR',

  'CREATE_LAWYERS_PROFILE': 'CREATE_LAWYERS_PROFILE',
  'CREATE_LAWYERS_PROFILE_LOADED': 'CREATE_LAWYERS_PROFILE_LOADED',
  'CREATE_LAWYERS_PROFILE_ERROR': 'CREATE_LAWYERS_PROFILE_ERROR',

  'GET_ORGANISATION_BY_SRA': 'GET_ORGANISATION_BY_SRA',
  'GET_ORGANISATION_BY_SRA_LOADED': 'GET_ORGANISATION_BY_SRA_LOADED',
  'GET_ORGANISATION_BY_SRA_ERROR': 'GET_ORGANISATION_BY_SRA_ERROR',

  'CREATE_UPLOAD_AVATAR': 'CREATE_UPLOAD_AVATAR',
  'CREATE_UPLOAD_AVATAR_LOADED': 'CREATE_UPLOAD_AVATAR_LOADED',
  'CREATE_UPLOAD_AVATAR_ERROR': 'CREATE_UPLOAD_AVATAR_ERROR',

  'CREATE_UPLOAD_USER_AVATAR': 'CREATE_UPLOAD_USER_AVATAR',
  'CREATE_UPLOAD_USER_AVATAR_LOADED': 'CREATE_UPLOAD_USER_AVATAR_LOADED',
  'CREATE_UPLOAD_USER_AVATAR_ERROR': 'CREATE_UPLOAD_USER_AVATAR_ERROR',

  'UPDATE_LAWYERS_PROFILE': 'UPDATE_LAWYERS_PROFILE',
  'UPDATE_LAWYERS_PROFILE_LOADED': 'UPDATE_LAWYERS_PROFILE_LOADED',
  'UPDATE_LAWYERS_PROFILE_ERROR': 'UPDATE_LAWYERS_PROFILE_ERROR',

  'GET_ORGANISATION': 'GET_ORGANISATION',
  'GET_ORGANISATION_LOADED': 'GET_ORGANISATION_LOADED',
  'GET_ORGANISATION_ERROR': 'GET_ORGANISATION_ERROR',

  'CREATE_REPORT_BARRISTER': 'CREATE_REPORT_BARRISTER',
  'CREATE_REPORT_BARRISTER_LOADED': 'CREATE_REPORT_BARRISTER_LOADED',
  'CREATE_REPORT_BARRISTER_ERROR': 'CREATE_REPORT_BARRISTER_ERROR',

  'CREATE_REPORT_ORGANISATION': 'CREATE_REPORT_ORGANISATION',
  'CREATE_REPORT_ORGANISATION_LOADED': 'CREATE_REPORT_ORGANISATION_LOADED',
  'CREATE_REPORT_ORGANISATION_ERROR': 'CREATE_REPORT_ORGANISATION_ERROR',

  'UPDATE_BOOKING': 'UPDATE_BOOKING',
  'UPDATE_BOOKING_LOADED': 'UPDATE_BOOKING_LOADED',
  'UPDATE_BOOKING_ERROR': 'UPDATE_BOOKING_ERROR',

  'GET_STRIPE_SUBSCRIPTIONS': 'GET_STRIPE_SUBSCRIPTIONS',
  'GET_STRIPE_SUBSCRIPTIONS_LOADED': 'GET_STRIPE_SUBSCRIPTIONS_LOADED',
  'GET_STRIPE_SUBSCRIPTIONS_ERROR': 'GET_STRIPE_SUBSCRIPTIONS_ERROR',

  'CREATE_STRIPE_CHECKOUT': 'CREATE_STRIPE_CHECKOUT',
  'CREATE_STRIPE_CHECKOUT_LOADED': 'CREATE_STRIPE_CHECKOUT_LOADED',
  'CREATE_STRIPE_CHECKOUT_ERROR': 'CREATE_STRIPE_CHECKOUT_ERROR',

  'DELETE_SUBSCRIPTION': 'DELETE_SUBSCRIPTION',
  'DELETE_SUBSCRIPTION_LOADED': 'DELETE_SUBSCRIPTION_LOADED',
  'DELETE_SUBSCRIPTION_ERROR': 'DELETE_SUBSCRIPTION_ERROR',

  'CREATE_QUALIFICATION': 'CREATE_QUALIFICATION',
  'CREATE_QUALIFICATION_LOADED': 'CREATE_QUALIFICATION_LOADED',
  'CREATE_QUALIFICATION_ERROR': 'CREATE_QUALIFICATION_ERROR',

  'CREATE_MEMBERSHIP': 'CREATE_MEMBERSHIP',
  'CREATE_MEMBERSHIP_LOADED': 'CREATE_MEMBERSHIP_LOADED',
  'CREATE_MEMBERSHIP_ERROR': 'CREATE_MEMBERSHIP_ERROR',

  'create_area': 'create_area',
  'create_area_LOADED': 'create_area_LOADED',
  'create_area_ERROR': 'create_area_ERROR',

  'DELETE_BOOK_A_LAWYER': 'DELETE_BOOK_A_LAWYER',
  'DELETE_BOOK_A_LAWYER_LOADED': 'DELETE_BOOK_A_LAWYER_LOADED',
  'DELETE_BOOK_A_LAWYER_ERROR': 'DELETE_BOOK_A_LAWYER_ERROR',

  'CREATE_BOOK_A_LAWYER': 'CREATE_BOOK_A_LAWYER',
  'CREATE_BOOK_A_LAWYER_LOADED': 'CREATE_BOOK_A_LAWYER_LOADED',
  'CREATE_BOOK_A_LAWYER_ERROR': 'CREATE_BOOK_A_LAWYER_ERROR',

  'GET_BOOK_A_LAWYER': 'GET_BOOK_A_LAWYER',
  'GET_BOOK_A_LAWYER_LOADED': 'GET_BOOK_A_LAWYER_LOADED',
  'GET_BOOK_A_LAWYER_ERROR': 'GET_BOOK_A_LAWYER_ERROR',

  'UPDATE_BOOK_A_LAWYER': 'UPDATE_BOOK_A_LAWYER',
  'UPDATE_BOOK_A_LAWYER_LOADED': 'UPDATE_BOOK_A_LAWYER_LOADED',
  'UPDATE_BOOK_A_LAWYER_ERROR': 'UPDATE_BOOK_A_LAWYER_ERROR',

  'CREATE_FAVOURITE': 'CREATE_FAVOURITE',
  'CREATE_FAVOURITE_LOADED': 'CREATE_FAVOURITE_LOADED',
  'CREATE_FAVOURITE_ERROR': 'CREATE_FAVOURITE_ERROR',

  'DELETE_FAVOURITE': 'DELETE_FAVOURITE',
  'DELETE_FAVOURITE_LOADED': 'DELETE_FAVOURITE_LOADED',
  'DELETE_FAVOURITE_ERROR': 'DELETE_FAVOURITE_ERROR',

  'GET_PROFILE_DETAIL': 'GET_PROFILE_DETAIL',
  'GET_PROFILE_DETAIL_LOADED': 'GET_PROFILE_DETAIL_LOADED',
  'GET_PROFILE_DETAIL_ERROR': 'GET_PROFILE_DETAIL_ERROR',

  'CREATE_RESPONSE_TO_REVIEW': 'CREATE_RESPONSE_TO_REVIEW',
  'CREATE_RESPONSE_TO_REVIEW_LOADED': 'CREATE_RESPONSE_TO_REVIEW_LOADED',
  'CREATE_RESPONSE_TO_REVIEW_ERROR': 'CREATE_RESPONSE_TO_REVIEW_ERROR',

  'CREATE_LEADERSHIP_ARTICLE': 'CREATE_LEADERSHIP_ARTICLE',
  'CREATE_LEADERSHIP_ARTICLE_LOADED': 'CREATE_LEADERSHIP_ARTICLE_LOADED',
  'CREATE_LEADERSHIP_ARTICLE_ERROR': 'CREATE_LEADERSHIP_ARTICLE_ERROR',

  'CREATE_APPOINTMENT_BOOKING': 'CREATE_APPOINTMENT_BOOKING',
  'CREATE_APPOINTMENT_BOOKING_LOADED': 'CREATE_APPOINTMENT_BOOKING_LOADED',
  'CREATE_APPOINTMENT_BOOKING_ERROR': 'CREATE_APPOINTMENT_BOOKING_ERROR',

  'CREATE_REVIEW': 'CREATE_REVIEW',
  'CREATE_REVIEW_LOADED': 'CREATE_REVIEW_LOADED',
  'CREATE_REVIEW_ERROR': 'CREATE_REVIEW_ERROR',

  'GET_DETAILED_LAWFIRM_PROFILE': 'GET_DETAILED_LAWFIRM_PROFILE',
  'GET_DETAILED_LAWFIRM_PROFILE_LOADED': 'GET_DETAILED_LAWFIRM_PROFILE_LOADED',
  'GET_DETAILED_LAWFIRM_PROFILE_ERROR': 'GET_DETAILED_LAWFIRM_PROFILE_ERROR',

  'GET_DETAILED_BARRISTER_PROFILE': 'GET_DETAILED_BARRISTER_PROFILE',
  'GET_DETAILED_BARRISTER_PROFILE_LOADED':
    'GET_DETAILED_BARRISTER_PROFILE_LOADED',
  'GET_DETAILED_BARRISTER_PROFILE_ERROR':
    'GET_DETAILED_BARRISTER_PROFILE_ERROR',

  'CREATE_BOOKING_ACTION': 'CREATE_BOOKING_ACTION',
  'CREATE_BOOKING_ACTION_LOADED': 'CREATE_BOOKING_ACTION_LOADED',
  'CREATE_BOOKING_ACTION_ERROR': 'CREATE_BOOKING_ACTION_ERROR',

  'GET_LAWYER_BOOKINGS': 'GET_LAWYER_BOOKINGS',
  'GET_LAWYER_BOOKINGS_LOADED': 'GET_LAWYER_BOOKINGS_LOADED',
  'GET_LAWYER_BOOKINGS_ERROR': 'GET_LAWYER_BOOKINGS_ERROR',

  'GET_BARRISTER_BOOKINGS': 'GET_BARRISTER_BOOKINGS',
  'GET_BARRISTER_BOOKINGS_LOADED': 'GET_BARRISTER_BOOKINGS_LOADED',
  'GET_BARRISTER_BOOKINGS_ERROR': 'GET_BARRISTER_BOOKINGS_ERROR',

  'GET_OCCUPATIONS': 'GET_OCCUPATIONS',
  'GET_OCCUPATIONS_LOADED': 'GET_OCCUPATIONS_LOADED',
  'GET_OCCUPATIONS_ERROR': 'GET_OCCUPATIONS_ERROR',

  'CREATE_CLAIM_PURCHASE': 'CREATE_CLAIM_PURCHASE',
  'CREATE_CLAIM_PURCHASE_LOADED': 'CREATE_CLAIM_PURCHASE_LOADED',
  'CREATE_CLAIM_PURCHASE_ERROR': 'CREATE_CLAIM_PURCHASE_ERROR',

  // END OF ACTION_STRINGS
}))

export const AppActions = (global.AppActions = Object.assign({}, BaseActions, {
  getProfile(data: Record<string, any>, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.GET_PROFILE,
      data,
      ...callbacks,
    }
  },

  updateProfile(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.UPDATE_PROFILE,
      data,
      ...callbacks,
    }
  },

  getFeedbackSummary(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_FEEDBACK_SUMMARY,
      data,
      ...callbacks,
    }
  },

  getFeedback(data: Record<string, any>, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.GET_FEEDBACK,
      data,
      ...callbacks,
    }
  },

  createFeedback(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_FEEDBACK,
      data,
      ...callbacks,
    }
  },

  createClaimPurchase(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_CLAIM_PURCHASE,
      data,
      ...callbacks,
    }
  },

  createMembershipRedemption(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.REDEEM,
      data,
      ...callbacks,
    }
  },

  getFaqs(data: Record<string, any>, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.GET_FAQS,
      data,
      ...callbacks,
    }
  },

  //Menu icons
  setMenuIcons(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.SET_MENU_ICONS,
      data,
      ...callbacks,
    }
  },

  createOauth(data: Record<string, any>, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.CREATE_OAUTH,
      data,
      ...callbacks,
    }
  },

  //Legal Checker
  getLegalChecker(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_CHECKER,
      data,
      ...callbacks,
    }
  },

  setLegalCheckerOptions(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.SET_LEGAL_CHECKER_OPTIONS,
      data,
      ...callbacks,
    }
  },

  setLegalCheckerMeta(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.SET_LEGAL_CHECKER_META,
      data,
      ...callbacks,
    }
  },

  getLegalCheckerDomains(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_CHECKER_DOMAINS,
      data,
      ...callbacks,
    }
  },

  getLegalCheckerSubDomains(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_CHECKER_SUB_DOMAINS,
      data,
      index: data.url,
      ...callbacks,
    }
  },

  getLegalCheckerQuestions(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_QUESTIONS,
      data,
      ...callbacks,
    }
  },

  getLegalCheckerScenarios(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_SCENARIOS,
      data,
      ...callbacks,
    }
  },

  createLegalCheckerSubmission(
    data: RequestTypes['legalCheckerSubmission'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_LEGAL_CHECKER_SUBMISSION,
      data,
      ...callbacks,
    }
  },

  getLegalCheckerResults(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_CHECKER_RESULTS,
      data,
      ...callbacks,
    }
  },

  getLegalCheckerServices(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_CHECKER_SERVICES,
      data,
      ...callbacks,
    }
  },

  getLegalCheckerPortals(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_CHECKER_PORTALS,
      data,
      ...callbacks,
    }
  },

  setInviteLink(data: Record<string, any>): AnyAction {
    return {
      type: Actions.SET_INVITE_LINK,
      data,
    }
  },

  getLegalCheckerGuidance(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_CHECKER_GUIDANCE,
      data,
      ...callbacks,
    }
  },

  getLegalCheckerResources(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_CHECKER_RESOURCES,
      data,
      ...callbacks,
    }
  },

  createLegalCheckerResult(
    data: RequestTypes['createLegalCheckerResult'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_LEGAL_CHECKER_RESULT,
      data,
      ...callbacks,
    }
  },

  getLegalCheckerResult(
    data: RequestTypes['getLegalCheckerResult'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_CHECKER_RESULT,
      data,
      ...callbacks,
    }
  },

  updateLegalCheckerResult(
    data: RequestTypes['updateLegalCheckerResult'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.UPDATE_LEGAL_CHECKER_RESULT,
      data,
      ...callbacks,
    }
  },

  deleteLegalCheckerResult(
    data: RequestTypes['deleteLegalCheckerResult'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.DELETE_LEGAL_CHECKER_RESULT,
      data,
      ...callbacks,
    }
  },

  getLegalAidQuestions(
    data: RequestTypes['getLegalAidQuestions'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_AID_QUESTIONS,
      data,
      ...callbacks,
    }
  },

  createGuidanceFeedback(
    data: RequestTypes['createGuidanceFeedback'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_GUIDANCE_FEEDBACK,
      data,
      ...callbacks,
    }
  },

  updateGuidanceFeedback(
    data: RequestTypes['updateGuidanceFeedback'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.UPDATE_GUIDANCE_FEEDBACK,
      data,
      ...callbacks,
    }
  },

  getLegalAidContent(
    data: RequestTypes['getLegalAidContent'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_AID_CONTENT,
      data,
      ...callbacks,
    }
  },

  getLegalCheckerFurtherGuidance(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_CHECKER_FUTHER_GUIDANCE,
      data,
      ...callbacks,
    }
  },

  getIaps(data: RequestTypes['getIaps'], callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.GET_IAPS,
      data,
      index: data.creditType,
      ...callbacks,
    }
  },

  getSubscriptions(
    data: RequestTypes['getSubscriptions'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_SUBSCRIPTIONS,
      data,
      index: data.creditType,
      ...callbacks,
    }
  },

  createVerifyCreditPurchase(
    data: RequestTypes['createVerifyCreditPurchase'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_VERIFY_CREDIT_PURCHASE,
      data,
      ...callbacks,
    }
  },

  getResultCredits(
    data: RequestTypes['getResultCredits'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_RESULT_CREDITS,
      data,
      ...callbacks,
    }
  },

  createUnlock(
    data: RequestTypes['createUnlock'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_UNLOCK,
      data,
      ...callbacks,
    }
  },

  createViewResult(
    data: RequestTypes['createViewResult'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_VIEW_RESULT,
      data,
      ...callbacks,
    }
  },

  getLegalDocuments(
    data: RequestTypes['getLegalDocuments'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_DOCUMENTS,
      data,
      ...callbacks,
    }
  },

  getLegalDocument(
    data: RequestTypes['getLegalDocument'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_DOCUMENT,
      data,
      ...callbacks,
    }
  },

  getLegalDocumentRatings(
    data: RequestTypes['getLegalDocumentRatings'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_DOCUMENT_RATINGS,
      data,
      ...callbacks,
    }
  },

  createLegalDocumentRating(
    data: RequestTypes['createLegalDocumentRating'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_LEGAL_DOCUMENT_RATING,
      data,
      ...callbacks,
    }
  },

  updateLegalDocumentRating(
    data: RequestTypes['updateLegalDocumentRating'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.UPDATE_LEGAL_DOCUMENT_RATING,
      data,
      ...callbacks,
    }
  },

  getLegalDocumentTypes(
    data: RequestTypes['getLegalDocumentTypes'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_DOCUMENT_TYPES,
      data,
      ...callbacks,
    }
  },

  getLegalDocumentCode(
    data: RequestTypes['getLegalDocumentCode'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_DOCUMENT_CODE,
      data,
      ...callbacks,
    }
  },

  getLegalDocumentTemplateFilters(
    data: RequestTypes['getLegalDocumentTemplateFilters'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LEGAL_DOCUMENT_TEMPLATE_FILTERS,
      data,
      ...callbacks,
    }
  },

  getGovernmentServices(
    data: RequestTypes['getGovernmentServices'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_GOVERNMENT_SERVICES,
      data,
      ...callbacks,
    }
  },

  createCloseAccount(
    data: RequestTypes['createCloseAccount'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_CLOSE_ACCOUNT,
      data,
      ...callbacks,
    }
  },

  getCovidRegulations(
    data: RequestTypes['getCovidRegulations'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_COVID_REGULATIONS,
      data,
      ...callbacks,
    }
  },

  createLegalResultFeedback(
    data: RequestTypes['createLegalResultFeedback'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_LEGAL_RESULT_FEEDBACK,
      data,
      ...callbacks,
    }
  },

  getIndustries(
    data: RequestTypes['getIndustries'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_INDUSTRIES,
      data,
      ...callbacks,
    }
  },

  getInviteToken(
    data: RequestTypes['getInviteToken'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_INVITE_TOKEN,
      data,
      ...callbacks,
    }
  },

  getAreasOfPractice(
    data: RequestTypes['getAreasOfPractice'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_AREAS_OF_PRACTICE,
      data,
      ...callbacks,
    }
  },

  getOffices(
    data: RequestTypes['getOffices'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_OFFICES,
      data,
      ...callbacks,
    }
  },

  getOffice(
    data: RequestTypes['getOffice'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_OFFICE,
      data,
      ...callbacks,
    }
  },

  getAreaCategories(
    data: RequestTypes['getAreaCategories'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_AREA_CATEGORIES,
      data,
      ...callbacks,
    }
  },

  getDocumentCategories(
    data: RequestTypes['getDocumentCategories'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_DOCUMENT_CATEGORIES,
      data,
      ...callbacks,
    }
  },

  getDocumentFilters(
    data: RequestTypes['getDocumentFilters'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_DOCUMENT_FILTERS,
      data,
      ...callbacks,
    }
  },

  getLawyerAvailability(
    data: RequestTypes['getLawyerAvailability'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LAWYER_AVAILABILITY,
      data,
      ...callbacks,
    }
  },

  createChallengeName(
    data: RequestTypes['createChallengeName'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_CHALLENGE_NAME,
      data,
      ...callbacks,
    }
  },

  createPassword(
    data: RequestTypes['createPassword'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_PASSWORD,
      data,
      ...callbacks,
    }
  },

  getLawyersProfile(
    data: RequestTypes['getLawyersProfile'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LAWYERS_PROFILE,
      data,
      ...callbacks,
    }
  },
  createLawyersProfile(
    data: RequestTypes['createLawyersProfile'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_LAWYERS_PROFILE,
      data,
      ...callbacks,
    }
  },

  createCronofyAuthLink(
    data: RequestTypes['createCronofyAuthLink'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_CRONOFY_AUTH_LINK,
      data,
      ...callbacks,
    }
  },

  createAuthWithCronofy(
    data: RequestTypes['createAuthWithCronofy'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_AUTH_WITH_CRONOFY,
      data,
      ...callbacks,
    }
  },

  createSetupMfa(
    data: RequestTypes['createSetupMfa'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_SETUP_MFA,
      data,
      ...callbacks,
    }
  },

  createVerifyMfa(
    data: RequestTypes['createVerifyMfa'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_VERIFY_MFA,
      data,
      ...callbacks,
    }
  },

  createConfirmSignin(
    data: RequestTypes['createConfirmSignin'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_CONFIRM_SIGNIN,
      data,
      ...callbacks,
    }
  },

  getBookings(
    data: RequestTypes['getBookings'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_BOOKINGS,
      data,
      ...callbacks,
    }
  },

  deleteBookALawyer(
    data: RequestTypes['deleteBookALawyer'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.DELETE_BOOK_A_LAWYER,
      data,
      ...callbacks,
    }
  },

  createBookALawyer(
    data: RequestTypes['createBookALawyer'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_BOOK_A_LAWYER,
      data,
      ...callbacks,
    }
  },

  getBookALawyer(
    data: RequestTypes['getBookALawyer'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_BOOK_A_LAWYER,
      data,
      ...callbacks,
    }
  },

  updateBookALawyer(
    data: RequestTypes['updateBookALawyer'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.UPDATE_BOOK_A_LAWYER,
      data,
      ...callbacks,
    }
  },

  createCronofyUiToken(
    data: RequestTypes['createCronofyUiToken'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_CRONOFY_UI_TOKEN,
      data,
      ...callbacks,
    }
  },

  getLawyerProfiles(
    data: RequestTypes['getLawyerProfiles'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LAWYER_PROFILES,
      data,
      ...callbacks,
    }
  },

  setBookALawyerAppointment(
    data: RequestTypes['setBookALawyerAppointment'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.SET_BOOK_A_LAWYER_APPOINTMENT,
      data,
      ...callbacks,
    }
  },

  createZoomAuthLink(
    data: RequestTypes['createZoomAuthLink'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_ZOOM_AUTH_LINK,
      data,
      ...callbacks,
    }
  },
  getCompanies(
    data: RequestTypes['getCompanies'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_COMPANIES,
      data,
      ...callbacks,
    }
  },
  getStripeProductDetails(
    data: RequestTypes['getStripeProductDetails'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_STRIPE_PRODUCT_DETAILS,
      data,
      ...callbacks,
    }
  },
  getStripeCheckoutUrl(
    data: RequestTypes['getStripeCheckoutUrl'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_STRIPE_CHECKOUT_URL,
      data,
      ...callbacks,
    }
  },
  createActionAppointment(
    data: RequestTypes['createActionAppointment'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_ACTION_APPOINTMENT,
      data,
      ...callbacks,
    }
  },

  getCronofyConferencingProfiles(
    data: RequestTypes['getCronofyConferencingProfiles'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_CRONOFY_CONFERENCING_PROFILES,
      data,
      ...callbacks,
    }
  },

  getBooking(
    data: RequestTypes['getBooking'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_BOOKING,
      data,
      ...callbacks,
    }
  },

  getLawyerMembershipTypes(
    data: RequestTypes['getLawyerMembershipTypes'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LAWYER_MEMBERSHIP_TYPES,
      data,
      ...callbacks,
    }
  },

  getLawyerQualificationTypes(
    data: RequestTypes['getLawyerQualificationTypes'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LAWYER_QUALIFICATION_TYPES,
      data,
      ...callbacks,
    }
  },

  getDetailedLawyerProfile(
    data: RequestTypes['getDetailedLawyerProfile'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_DETAILED_LAWYER_PROFILE,
      data,
      ...callbacks,
    }
  },

  getBarristers(
    data: RequestTypes['getBarristers'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_BARRISTERS,
      data,
      ...callbacks,
    }
  },

  getBarrister(
    data: RequestTypes['getBarrister'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_BARRISTER,
      data,
      ...callbacks,
    }
  },

  getBarristerHonours(
    data: RequestTypes['getBarristerHonours'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_BARRISTER_HONOURS,
      data,
      ...callbacks,
    }
  },

  getBarristersSearch(
    data: RequestTypes['getBarristersSearch'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_BARRISTERS_SEARCH,
      data,
      ...callbacks,
    }
  },

  updateBarrister(
    data: RequestTypes['updateBarrister'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.UPDATE_BARRISTER,
      data,
      ...callbacks,
    }
  },

  getOrganisationBySra(
    data: RequestTypes['getOrganisationBySra'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_ORGANISATION_BY_SRA,
      data,
      ...callbacks,
    }
  },

  createUploadAvatar(
    data: RequestTypes['createUploadAvatar'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_UPLOAD_AVATAR,
      data,
      ...callbacks,
    }
  },

  createUploadUserAvatar(
    data: RequestTypes['createUploadUserAvatar'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_UPLOAD_USER_AVATAR,
      data,
      ...callbacks,
    }
  },

  updateLawyersProfile(
    data: RequestTypes['updateLawyersProfile'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.UPDATE_LAWYERS_PROFILE,
      data,
      ...callbacks,
    }
  },

  getOrganisation(
    data: RequestTypes['getOrganisation'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_ORGANISATION,
      data,
      ...callbacks,
    }
  },

  createReportBarrister(
    data: RequestTypes['createReportBarrister'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_REPORT_BARRISTER,
      data,
      ...callbacks,
    }
  },

  createReportOrganisation(
    data: RequestTypes['createReportOrganisation'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_REPORT_ORGANISATION,
      data,
      ...callbacks,
    }
  },

  updateBooking(
    data: RequestTypes['updateBooking'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.UPDATE_BOOKING,
      data,
      ...callbacks,
    }
  },

  getStripeSubscriptions(
    data: RequestTypes['getStripeSubscriptions'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_STRIPE_SUBSCRIPTIONS,
      data,
      ...callbacks,
    }
  },

  createStripeCheckout(
    data: RequestTypes['createStripeCheckout'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_STRIPE_CHECKOUT,
      data,
      ...callbacks,
    }
  },

  deleteSubscription(
    data: RequestTypes['deleteSubscription'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.DELETE_SUBSCRIPTION,
      data,
      ...callbacks,
    }
  },

  createQualification(
    data: RequestTypes['createQualification'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_QUALIFICATION,
      data,
      ...callbacks,
    }
  },

  createMembership(
    data: RequestTypes['createMembership'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_MEMBERSHIP,
      data,
      ...callbacks,
    }
  },

  createArea(
    data: RequestTypes['createArea'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.create_area,
      data,
      ...callbacks,
    }
  },

  createFavourite(
    data: RequestTypes['createFavourite'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_FAVOURITE,
      data,
      ...callbacks,
    }
  },

  deleteFavourite(
    data: RequestTypes['deleteFavourite'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.DELETE_FAVOURITE,
      data,
      ...callbacks,
    }
  },

  getProfileDetail(
    data: RequestTypes['getProfileDetail'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_PROFILE_DETAIL,
      data,
      ...callbacks,
    }
  },

  createResponseToReview(
    data: RequestTypes['createResponseToReview'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_RESPONSE_TO_REVIEW,
      data,
      ...callbacks,
    }
  },

  createLeadershipArticle(
    data: RequestTypes['createLeadershipArticle'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_LEADERSHIP_ARTICLE,
      data,
      ...callbacks,
    }
  },

  createAppointmentBooking(
    data: RequestTypes['createAppointmentBooking'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_APPOINTMENT_BOOKING,
      data,
      ...callbacks,
    }
  },

  createReview(
    data: RequestTypes['createReview'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_REVIEW,
      data,
      ...callbacks,
    }
  },

  enableCommission(
    data: RequestTypes['enableCommission'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.ENABLE_COMMISSION,
      data,
      ...callbacks,
    }
  },

  disableCommission(
    data: RequestTypes['disableCommission'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.DISABLE_COMMISSION,
      data,
      ...callbacks,
    }
  },

  getDetailedLawfirmProfile(
    data: RequestTypes['getDetailedLawfirmProfile'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_DETAILED_LAWFIRM_PROFILE,
      data,
      ...callbacks,
    }
  },

  getDetailedBarristerProfile(
    data: RequestTypes['getDetailedBarristerProfile'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_DETAILED_BARRISTER_PROFILE,
      data,
      ...callbacks,
    }
  },

  createBookingAction(
    data: RequestTypes['createBookingAction'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_BOOKING_ACTION,
      data,
      ...callbacks,
    }
  },

  getAcceptedBookings(
    data: RequestTypes['getAcceptedBookings'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_ACCEPTED_BOOKINGS,
      data,
      ...callbacks,
    }
  },

  getPendingBookings(
    data: RequestTypes['getPendingBookings'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_PENDING_BOOKINGS,
      data,
      ...callbacks,
    }
  },

  getDeclinedBookings(
    data: RequestTypes['getDeclinedBookings'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_DECLINED_BOOKINGS,
      data,
      ...callbacks,
    }
  },

  getLawyerBookings(
    data: RequestTypes['getLawyerBookings'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_LAWYER_BOOKINGS,
      data,
      ...callbacks,
    }
  },

  getBarristerBookings(
    data: RequestTypes['getBarristerBookings'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_BARRISTER_BOOKINGS,
      data,
      ...callbacks,
    }
  },

  getOccupations(
    data: RequestTypes['getOccupations'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_OCCUPATIONS,
      data,
      ...callbacks,
    }
  },

  // END OF APP_ACTIONS
}))
