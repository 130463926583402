import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'

type UseOccupations = {
  occupations: AppState['occupations']
  occupationsLoading: AppState['occupationsLoading']

  occupationsError: AppState['occupationsError']
  getOccupations: (
    data: RequestTypes['getOccupations'],
    callbacks?: Callbacks,
  ) => void
}

export default function useOccupations(): UseOccupations {
  const { occupations, occupationsLoading, occupationsError } = useSelector(
    (state: AppState) => ({
      occupations: state.occupations,
      occupationsLoading: state.occupationsLoading,
      occupationsSaving: state.occupationsSaving,
      occupationsError: state.occupationsError,
    }),
  )
  const dispatch = useDispatch()

  const getOccupations = useCallback(
    (data: RequestTypes['getOccupations'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getOccupations(data, callbacks))
    },
    [dispatch],
  )

  return {
    occupations,
    occupationsLoading,
    occupationsError,
    getOccupations,
  }
}
