import React from 'react'
import cn from 'classnames'

type AvatarProps = {
  imgSource?: string
  placeholderImage?: any
  fullName: string
  className?: string
  isLarge?: boolean
}

export const Avatar: React.FC<AvatarProps> = ({
  imgSource,
  placeholderImage,
  fullName,
  className,
  isLarge,
}: AvatarProps) => (
  <div
    className={cn(
      'flex justify-center items-center rounded-full bg-lue-blue-700',
      isLarge
        ? 'h-[100px] w-[100px] md:h-[160px] md:w-[160px]'
        : 'h-[40px] w-[40px] md:h-[50px] md:w-[50px]',
      className,
    )}
  >
    {!imgSource ? (
      <span
        className={cn(
          'text-white font-medium capitalize',
          isLarge ? 'text-4xl' : 'text-base',
        )}
      >
        {Utils.getNameInitials(fullName)}
      </span>
    ) : (
      <img
        className='object-cover relative rounded-full w-full h-full'
        src={imgSource ?? placeholderImage}
      />
    )}
  </div>
)
