import { InputHTMLAttributes } from 'react'
import cn from 'classnames'

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  isLarge?: boolean
  isDropdown?: boolean
  isNew?: boolean
}

const getCursorClassname = (disabled?: boolean, isDropdown?: boolean) => {
  if (disabled) {
    return 'cursor-not-allowed'
  }

  if (isDropdown) {
    return 'cursor-pointer'
  }

  return 'cursor-text'
}

export const Input = ({
  className,
  isLarge,
  disabled,
  isDropdown,
  isNew,
  ...props
}: InputProps) => (
  <input
    className={cn(
      'bg-transparent w-full outline-none',
      isNew
        ? 'text-sm font-normal leading-5 tracking-[0.4px] text-[#333333] placeholder:text-[#818285]'
        : 'py-2.5 text-lg text-b33 font-normal placeholder:text-b33',
      getCursorClassname(disabled, isDropdown),
      isLarge ? 'lg:py-7 px-5' : 'px-4',
      className,
    )}
    disabled={disabled}
    {...props}
  />
)