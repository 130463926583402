import  { useRef } from "react";
import Slider, { Settings } from "react-slick";
import { CarouselArrow } from "./CarouselArrow";
import cn from "classnames";
import { OverlayCard } from "./OverlayCard";
import { Card } from "./Card";
import classNames from "classnames";

type CardCarouselProps = {
  type?: 'default' | 'overlay';
  data: {
    title: string;
    href: string;
    img: string;
    imgPosition?: string;
  }[]
  sliderSettings?: Settings;
  className?: string;
  isSlim?: boolean;
}

const DEFAULT_SLIDER_SETTINGS: Settings = {
  arrows: false,
  speed: 500,
  initialSlide: 0,
};

export const CardCarousel = ({
  type,
  data,
  sliderSettings,
  className,
  isSlim,
}: CardCarouselProps) => {
  const ref = useRef<Slider>();

  const onPreviousClick = () => {
    ref.current?.slickPrev();
  }

  const onNextClick = () => {
    ref.current?.slickNext();
  }

  return (
    <div
      style={{
        marginBottom: 62, // offset for the arrows
      }}
      className={classNames('px-4 relative w-full max-w-[1500px]', className)}>
      <Slider
        ref={ref}
        {...DEFAULT_SLIDER_SETTINGS}
        {...sliderSettings}>
        {data.map((item) => (
          <div
            key={item.title}
            className={cn('px-2', !isSlim && 'lg:px-4')}>
            {type === 'overlay' ? <OverlayCard {...item} /> : <Card {...item} />}
          </div>
        ))}
      </Slider>
      <div className="absolute top-[105%] flex w-full justify-between md:justify-center px-6 lg:px-4 left-0 right-0">
        <CarouselArrow
          type="previous"
          onClick={onPreviousClick}
        />
        <CarouselArrow
          type="next"
          onClick={onNextClick}
          style={{ marginLeft: 140 }}
        />
      </div>
    </div>
  )
};
