import { Dropdown } from '../Dropdowns/Dropdown'
import { Input } from './Input'
import { InputWrapper } from './InputWrapper'
import { DropdownArrow } from '../Dropdowns/DropdownArrow'
import { useState } from 'react'
import cn from 'classnames'
import { InputErrorText } from './InputErrorText'

type InputDropdownProps = {
  value: string
  name: string
  label: string
  isLabelVisible?: boolean
  isLabelInline?: boolean
  onSelect: (value?: string) => void
  options: {
    label: string
    value: string
    infoText?: string
  }[]
  placeholder?: string
  className?: string
  isLarge?: boolean
  error?: string
  theme?: 'default' | 'grey'
}

export const InputDropdown = ({
  value,
  onSelect,
  options,
  placeholder,
  name,
  label,
  isLabelVisible,
  isLabelInline,
  className,
  isLarge,
  error,
  theme = 'default',
}: InputDropdownProps) => {
  const id = `${name}-input`
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const onDropdownSelect = (value: string) => {
    setIsDropdownVisible(false)
    onSelect(value)
  }

  const onClickOutside = () => {
    setIsDropdownVisible(false)
  }

  const onInputClick = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  return (
    <div
      className={cn(isLabelInline && 'flex flex-row items-center', className)}
    >
      {label && (
        <label
          className={cn(
            'block',
            isLabelInline ? 'mr-4' : 'mb-1',
            isLabelVisible ? 'font-medium mb-1 block' : 'hidden',
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <div className='flex-1'>
        <InputWrapper
          hasOverflow
          imgAfter={<DropdownArrow size={isLarge ? 20 : 12} />}
          theme={theme}
          className='flex-1'
        >
          {/* Input used to store the actual option value */}
          <input readOnly type='hidden' name={name} id={id} value={value} />
          {/* Input used to display the label associated with the option value */}
          <Input
            readOnly
            isDropdown
            value={
              options.find((option) => option.value === value)?.label || ''
            }
            type='text'
            placeholder={placeholder}
            onClick={onInputClick}
            isLarge={isLarge}
          />
          <Dropdown
            isLarge={isLarge}
            options={options}
            isVisible={isDropdownVisible}
            onSelect={onDropdownSelect}
            onClickOutside={onClickOutside}
          />
        </InputWrapper>
        {error && <InputErrorText>{error}</InputErrorText>}
      </div>
    </div>
  )
}
