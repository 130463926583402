import { useLocation } from 'react-router-dom'
import Constants from 'common/utils/constants'
import { Navbar } from 'common/components/Nav/Navbar'
import { NavbarLogo } from 'common/components/Nav/NavbarLogo'

const EXCLUDED_ROUTES = [
  /legal-documents\/[^/]+\/download/,
  ...Constants.authRoutes,
]

const NavBar = () => {
  const location = useLocation()
  const { pathname } = location

  if (
    EXCLUDED_ROUTES.some((route) =>
      route instanceof RegExp ? route.test(pathname) : route === pathname,
    )
  ) {
    return null
  }

  return (
    <Navbar
      dropdownOptions={[
        {
          isLink: true,
          label: 'Profile',
          value: '/profile',
        },
        {
          isLink: true,
          label: 'Appointments',
          value: '/appointments',
        },
        {
          isLink: true,
          label: 'Favourites',
          value: '/favourites',
        },
      ]}
    >
      <NavbarLogo />
    </Navbar>
  )
}

export default NavBar
