import { Avatar } from 'common/components/Avatar'
import React from 'react'
import { FaTimesCircle } from 'react-icons/fa'

interface HeaderBarProps {
  onClose: () => void
  avatar: string
  fullName: string
  officeName: string
}

const HeaderBar: React.FC<HeaderBarProps> = ({
  onClose,
  avatar,
  fullName,
  officeName,
}) => {
  return (
    <div className='flex place-content-between items-center px-3 py-2 bg-[#F8F8F8]'>
      <div className='flex flex-row items-center'>
        <Avatar
          imgSource={avatar}
          fullName={fullName}
          className='mr-2 h-6 w-6'
        />
        <div className='flex flex-col ml-2 text-black'>
          <span className='text-lg font-bold'>{fullName}</span>
          <span className='text-xs'>{officeName}</span>
        </div>
      </div>
      <FaTimesCircle size={20} color={'#65C2D7'} onClick={onClose} />
    </div>
  )
}

export default HeaderBar
