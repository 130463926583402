import { FC } from 'react'

type ComponentType = {}

const TheComponent: FC<ComponentType> = ({}) => {
  return (
    <>
      <iframe
        width='610px'
        height='770px'
        src='https://crm.zoho.eu/crm/WebFormServeServlet?rid=32c7d62ad386cf7fe5427252606509ef5893a625967ccc12fbffd7637efc6ce3gid2fdff2e0e3cdfea6982630a4d00c7427a166d2dc9e2a08218e8223de557dcf17'
      ></iframe>
    </>
  )
}

export default TheComponent
