import aboutBgGreenWave from '../../../assets/AboutImages/about-bg-green-wave.png'
import aboutMain from '../../../assets/AboutImages/about-bg-img-main.png'
import aboutBgMobileBottom from '../../../assets/AboutImages/about-bg-mobile-bottom.png'
import aboutBgMobileMiddle from '../../../assets/AboutImages/about-bg-mobile-middle.png'
import aboutBgMobileTop from '../../../assets/AboutImages/about-bg-mobile-top.png'
import aboutClients from '../../../assets/AboutImages/about-clients.png'
import aboutScreenshotsMobile from '../../../assets/AboutImages/about-screenshots-mobile.png'
import aboutScreenshots from '../../../assets/AboutImages/about-screenshots.png'

export const AboutSection = () => {
  return (
    <section className='overflow-hidden'>
      <div className='relative'>
        <div className='hidden md:block mb-[-10px]'>
          <img
            className='w-full'
            src={aboutMain}
            alt=''
            width='3840'
            height='3472'
          />
        </div>
        <div className='md:hidden'>
          <img
            className='w-full'
            src={aboutBgMobileTop}
            alt=''
            width='3840'
            height='3472'
          />
        </div>
        <div className='absolute z-10 top-0 pt-[94%] md:pt-[10%] flex flex-col items-center w-full'>
          <div className='px-6 inline-block flex flex-col items-center'>
            <p className='font-bold text-2xl md:text-3xl text-white inline-block md:flex md:flex-col text-center max-w-[400px] md:max-w-none'>
              <span>Our everyday is making law work for everyone.</span>{' '}
              <span>We’re the people making that happen.</span>
            </p>
            <div className='mt-8 md:mt-12'>
              <a
                href='/whoweare'
                className='rounded-full bg-white text-black text-lg py-3 px-14 md:px-12 font-bold select-none'
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
        <div className='w-full relative md:absolute md:top-[35%] lg:top-[30%] mt-[-24%] md:mt-0 z-10'>
          <img
            className='hidden md:block w-full'
            src={aboutBgGreenWave}
            alt=''
            width='1920'
            height='690'
          />
          <img
            className='md:hidden w-full'
            src={aboutBgMobileMiddle}
            alt=''
            width='1920'
            height='690'
          />
          <div className='absolute w-full h-full flex flex-col md:flex-row top-0'>
            <div className='flex-1 items-end md:flex order-last md:order-none'>
              <img
                className='min-w-[120%] md:min-w-full ml-[-20%] mt-[30%] md:ml-0 md:mt-0 px-6 mb-6 hidden md:block'
                src={aboutScreenshots}
                alt=''
                width='907'
                height='503'
              />
              <img
                className='md:hidden absolute bottom-0 pr-6'
                src={aboutScreenshotsMobile}
                alt=''
                width='907'
                height='503'
              />
            </div>
            <div className='flex-1 z-10 flex flex-col items-center justify-center'>
              <div className='w-full max-w-[700px] text-lue-blue-700 px-6 text-center md:text-left'>
                <p className='font-bold text-2xl md:text-xl lg:text-2xl xl:text-3xl'>
                  Claim Your Profile, Today!
                </p>
                <p className='mt-8 md:mt-2 lg:mt-3 xl:mt-6 md:text-sm lg:text-lg leading-relaxed'>
                  Law firms, chambers, solicitors and barristers can now claim
                  their profile and secure new clients. Claim your profile in
                  under 5-minutes!
                </p>
                <div className='mt-8 md:mt-6 xl:mt-10'>
                  <a
                    href='https://lawyers.legalutopia.co.uk'
                    className='rounded-full bg-lue-purple-400 text-white text-lg py-2 px-8 text-xl select-none transition-colors hover:bg-lue-purple-500'
                  >
                    Claim Profile
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='hidden md:flex justify-center w-full absolute bottom-5 lg:bottom-[5%] px-6'>
          <div className='w-[80%] max-w-[1200px]'>
            <img
              className='w-full'
              src={aboutClients}
              alt='List of clients'
              width='1140'
              height='306'
            />
          </div>
        </div>
        <img
          className='w-full md:hidden mt-[-10%] relative'
          src={aboutBgMobileBottom}
          alt='List of clients'
          width='674'
          height='551'
        />
      </div>
    </section>
  )
}
