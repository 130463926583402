import React, {FC} from 'react'
import LUlogo from "../../public/LU-Logo.png";
import Rating from "react-rating";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";

type ProfileRatingType = {
    rating: number
}

const ProfileRating: FC<ProfileRatingType> = ({rating}) => {
    return rating ? (
      <div className='flex flex-row items-center self-center'>
        <img src={LUlogo} className='h-6 w-6 mr-2' />
        <Rating
          emptySymbol={<AiOutlineStar size={22} color='#122D5C' />}
          fullSymbol={<AiFillStar size={22} color='#122D5C' />}
          initialRating={rating}
          readonly
        />
        <sup className='text-black pl-1 text-sm md:text-base'>{rating}</sup>
      </div>
    ) : null
}

export default ProfileRating
