import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useLegalDocuments from 'common/providers/useLegalDocuments'
import { AppState } from 'common/state-type'
import Constants from 'common/utils/constants'
import throttle from 'lodash/throttle'

const SEARCH_DELAY = 500

export const SORT_OPTIONS: {
  label: string
  value: string
  sort: 'title' | 'updated_at'
  order: 'ASC' | 'DESC'
}[] = [
  {
    label: 'Name (A-Z)',
    value: 'name-az',
    sort: 'title',
    order: 'ASC',
  },
  {
    label: 'Name (Z-A)',
    value: 'name-za',
    sort: 'title',
    order: 'DESC',
  },
  {
    label: 'Newest to Oldest',
    value: 'newest',
    sort: 'updated_at',
    order: 'DESC',
  },
  {
    label: 'Oldest to Newest',
    value: 'oldest',
    sort: 'updated_at',
    order: 'ASC',
  },
]

export const useLegalDocumentTemplateSearch = ({
  pageSize = 20,
}: {
  pageSize?: number
} = {}) => {
  const [sortOption, setSortOption] = useState(SORT_OPTIONS[0])
  const [documentFilter, setDocumentFilter] = useState<string | undefined>(
    undefined,
  )
  const isLoadingMore = useRef(false)
  const [title, setTitle] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [page, setPage] = useState(1)
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false)
  const [selectedType, setSelectedType] = useState<string | undefined>(
    undefined,
  )

  const { legalDocuments, getLegalDocuments, legalDocumentsLoading } =
    useLegalDocuments()

  const [previousLegalDocuments, setPreviousLegalDocuments] = useState<
    AppState['legalDocuments']['results']
  >([])

  const throttledSetTitle = useCallback(
    throttle(setTitle, SEARCH_DELAY, { leading: true }),
    [],
  )

  const updateSearchValue = useCallback(
    (text: string) => {
      setSearchValue(text)
      throttledSetTitle(text)
    },
    [throttledSetTitle],
  )

  const loadDocs = useCallback(() => {
    getLegalDocuments(
      {
        is_template: Constants.isAvvokaEnabled,
        title,
        sort: sortOption.sort,
        order: sortOption.order,
        type: selectedType,
        document_filters: documentFilter,
        page,
        page_size: pageSize,
      },
      {
        onSuccess: () => {
          setIsRefreshing(false)
          isLoadingMore.current = false
        },
        onError: () => {
          setIsRefreshing(false)
          isLoadingMore.current = false
        },
      },
    )
  }, [
    sortOption,
    title,
    page,
    selectedType,
    documentFilter,
    pageSize,
    getLegalDocuments,
  ])

  useEffect(loadDocs, [loadDocs])

  const loadMore = useCallback(() => {
    if (!legalDocuments?.next || isLoadingMore.current) {
      return
    }

    isLoadingMore.current = true

    setPreviousLegalDocuments(legalDocuments.results)
    setPage(page + 1)
  }, [legalDocuments, page])

  const refresh = useCallback(() => {
    setIsRefreshing(true)
    setPreviousLegalDocuments([])
    loadDocs()
  }, [loadDocs])

  const results = useMemo(() => {
    const docs = [...previousLegalDocuments, ...(legalDocuments?.results ?? [])]

    // Check for uniqueness based on ID, just in case.
    return docs.filter(
      (value, index, self) =>
        index === self.findIndex(({ id }) => id === value.id),
    )
  }, [previousLegalDocuments, legalDocuments])

  return {
    results,
    isLoading: legalDocumentsLoading,
    loadMore,
    selectedType,
    setSelectedType,
    sortOption,
    setSortOption,
    documentFilter,
    setDocumentFilter,
    searchValue,
    updateSearchValue,
    hasNext: !!legalDocuments?.next,
    refresh,
    isRefreshing,
  }
}
