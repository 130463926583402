import { ReactNode } from 'react'
import { useAuth } from '../../providers/useAuth'
import useProfile from '../../providers/useProfile'
import { NavbarDropdown, NavbarDropdownProps } from './NavbarDropdown'
import classNames from 'classnames'

type NavbarProps = {
  dropdownOptions: NavbarDropdownProps['dropdownOptions']
  children?: ReactNode
  isSlimDropdown?: boolean
  className?: string
}

export const Navbar = ({
  dropdownOptions,
  children,
  isSlimDropdown,
  className,
}: NavbarProps) => {
  const { profile } = useProfile()
  const { logout } = useAuth()

  return (
    <nav
      className={classNames(
        'relative px-6 md:px-12 flex flex-row w-full max-w-screen justify-between items-center py-4 md:py-6',
        className,
      )}
    >
      {children}
      <NavbarDropdown
        isSlimDropdown={isSlimDropdown}
        profile={profile}
        dropdownOptions={dropdownOptions}
        onSignoutClick={() => {
          logout({ id: profile?.id })
        }}
      />
    </nav>
  )
}
