import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseLegalDocumentCode = {
  legalDocumentCode?: AppState['legalDocumentCode']
  legalDocumentCodeLoading: AppState['legalDocumentCodeLoading']
  legalDocumentCodeError: AppState['legalDocumentCodeError']
  getLegalDocumentCode: (
    data: RequestTypes['getLegalDocumentCode'],
    callbacks?: Callbacks,
  ) => void
}

export default function useLegalDocumentCode(): UseLegalDocumentCode {
  const {
    legalDocumentCode,
    legalDocumentCodeLoading,
    legalDocumentCodeError,
  } = useSelector((state: AppState) => ({
    legalDocumentCode: state.legalDocumentCode,
    legalDocumentCodeLoading: state.legalDocumentCodeLoading,
    legalDocumentCodeError: state.legalDocumentCodeError,
  }))
  const dispatch = useDispatch()
  const getLegalDocumentCode = useCallback(
    (data: RequestTypes['getLegalDocumentCode'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getLegalDocumentCode(data, callbacks))
    },
    [dispatch],
  )
  return {
    legalDocumentCode,
    legalDocumentCodeLoading,
    legalDocumentCodeError,
    getLegalDocumentCode,
  }
}
