import { SVGProps } from 'react'

export const LuLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    focusable='false'
    aria-hidden='true'
    viewBox='0 0 581 581'
    xmlSpace='preserve'
    {...props}
  >
    <path
      fill='#264C86'
      opacity='1.000000'
      stroke='none'
      d='
    M1.000004,489.000000
      C1.000000,352.977356 1.000000,216.954727 1.416990,80.497467
      C2.219714,79.052811 2.871138,78.063484 2.951672,77.029678
      C4.352234,59.050957 11.647402,43.477348 23.438871,30.254097
      C37.593365,14.380898 55.477314,5.053889 76.868446,2.893513
      C77.634895,2.816106 78.291954,1.655679 79.000000,1.000001
      C202.689316,1.000000 326.378632,1.000000 450.784851,1.248959
      C454.690063,2.678740 457.878387,3.859562 461.015869,5.470875
      C460.019012,16.838739 459.258026,27.795868 458.093048,38.709873
      C454.213379,75.056808 446.083832,110.466850 432.843231,144.527542
      C425.936890,162.293640 417.535126,179.478409 409.357147,196.995865
      C403.656860,197.128601 398.408234,197.191208 392.690735,197.210876
      C390.722565,197.143356 389.223297,197.100555 387.723938,197.097336
      C380.235992,197.081238 380.263458,197.085068 380.215240,204.401993
      C380.126282,217.897766 380.001587,231.393311 379.606323,245.066132
      C377.849670,247.256210 376.388947,249.276886 374.905670,251.280823
      C356.662933,275.926758 336.786346,299.125427 314.092041,319.438171
      C313.939117,317.406433 313.829437,315.801971 313.624695,312.807159
      C272.318817,312.807159 231.320282,312.807159 190.110123,312.807159
      C190.854935,241.695786 191.584824,172.008713 192.326340,101.211823
      C174.747040,101.211823 157.967926,101.211823 140.714447,101.173645
      C138.742920,101.110474 137.245834,101.067650 135.748642,101.063454
      C128.112335,101.042061 128.146240,101.045959 128.055359,108.642433
      C127.764908,132.918411 127.402428,157.193604 127.150116,181.469940
      C126.775032,217.559082 126.437157,253.648682 126.150383,289.738617
      C126.016846,306.543671 125.976318,323.350159 126.013824,340.155701
      C126.042442,352.979736 123.107018,352.389069 138.668762,351.961761
      C172.065384,352.348907 205.008881,352.687012 238.081223,353.120209
      C238.210052,353.215302 238.683319,353.273468 238.683319,353.273468
      C238.683319,353.273468 239.122147,353.108063 239.567093,353.151245
      C243.280975,353.185883 246.549896,353.177307 250.208191,353.331390
      C258.679443,353.696198 266.761383,353.898346 274.680634,354.375671
      C271.961090,356.768829 269.404236,358.886780 266.472168,361.083496
      C263.966766,361.234955 261.836273,361.376526 259.706329,361.369049
      C246.838104,361.323730 233.970016,361.244354 220.632324,361.152405
      C218.011505,361.086945 215.834457,361.241730 213.714310,360.963196
      C209.237076,360.375000 207.796509,362.308929 208.185959,366.593353
      C209.187393,377.610565 210.003601,388.644592 210.558105,399.786774
      C184.649429,413.712311 159.491440,428.370575 133.408783,441.140137
      C91.563339,461.626923 47.982330,477.816376 2.798049,489.469452
      C2.273925,489.604614 1.602614,489.169098 1.000004,489.000000
    M222.216965,195.412827
      C219.892456,195.387833 217.554214,195.513748 215.246582,195.303497
      C211.513428,194.963394 210.048004,196.115128 210.027451,200.242844
      C209.874435,230.953247 209.419830,261.661987 209.147812,292.372040
      C209.033905,305.232697 209.131027,305.233551 222.999603,304.884888
      C239.543488,304.884888 256.087372,304.884888 273.391815,304.884888
      C273.391815,268.365143 273.391815,232.396210 273.391815,195.567490
      C256.655762,195.567490 239.904022,195.567490 222.216965,195.412827
    z'
    />
    <path
      fill='#66C1D7'
      opacity='1.000000'
      stroke='none'
      d='
    M1.000004,489.444153
      C1.602614,489.169098 2.273925,489.604614 2.798049,489.469452
      C47.982330,477.816376 91.563339,461.626923 133.408783,441.140137
      C159.491440,428.370575 184.649429,413.712311 210.653748,400.195068
      C217.189316,429.744568 232.393616,452.595825 258.746246,467.327209
      C278.911774,478.600037 300.933777,482.052460 324.211975,481.775330
      C334.145569,481.188110 343.693268,481.213531 353.032715,479.831451
      C381.558563,475.610260 405.465149,462.979462 422.893616,439.430054
      C437.705261,419.416534 442.365845,396.259125 442.863770,372.060211
      C443.483826,341.926422 443.571381,311.781830 443.913849,281.642181
      C444.207123,255.834061 444.540863,230.026443 444.844635,204.218460
      C444.918457,197.945877 444.860046,198.032425 438.453278,197.853210
      C431.473022,197.657959 424.499817,197.210724 417.754974,196.609512
      C419.969482,194.569214 421.886108,192.711411 423.945557,191.028381
      C458.813049,162.534164 498.685394,144.588593 542.556885,135.383804
      C554.729919,132.829758 567.153503,130.747620 579.613098,135.071106
      C579.722107,135.093887 579.938721,135.042496 579.975220,135.507233
      C580.007874,254.776077 580.013916,373.580200 579.958557,492.384308
      C579.956665,496.473511 579.548157,500.591705 578.976501,504.646667
      C575.484192,529.418335 562.747925,548.816284 542.933350,563.326233
      C527.026123,574.974976 508.951141,580.204468 488.843658,580.158020
      C356.376953,579.852478 223.909241,580.009888 91.441856,579.958557
      C87.019432,579.956848 82.579636,579.491516 78.178040,578.982117
      C59.703201,576.844116 43.987499,568.494324 30.722818,556.000000
      C14.522308,540.740356 4.798569,521.898804 2.919966,499.379791
      C2.849824,498.539001 1.665233,497.791168 0.999994,497.000000
      C1.000000,494.629425 1.000000,492.258881 1.000004,489.444153
    z'
    />

    <path
      fill='#5058A2'
      opacity='1.000000'
      stroke='none'
      d='
    M496.531342,1.000001
      C497.984650,1.665858 498.922455,2.825602 499.960876,2.924039
      C517.656738,4.601488 533.144531,11.399024 546.990295,22.434229
      C562.058655,34.443855 571.965088,49.798462 577.008667,68.244072
      C578.595154,74.046265 579.716370,80.145164 579.866150,86.137871
      C580.265564,102.117546 580.021301,118.113319 579.985779,134.572601
      C579.938721,135.042496 579.722107,135.093887 579.461365,134.699677
      C573.489990,116.325325 565.974365,99.137222 556.136780,83.033737
      C533.520081,46.011608 503.087799,18.444405 461.066681,5.040384
      C457.878387,3.859562 454.690063,2.678740 451.250885,1.248959
      C466.020905,1.000000 481.041779,1.000000 496.531342,1.000001
    z'
    />
    <path
      fill='#BFBEBD'
      opacity='1.000000'
      stroke='none'
      d='
    M417.523560,196.873337
      C424.499817,197.210724 431.473022,197.657959 438.453278,197.853210
      C444.860046,198.032425 444.918457,197.945877 444.844635,204.218460
      C444.540863,230.026443 444.207123,255.834061 443.913849,281.642181
      C443.571381,311.781830 443.483826,341.926422 442.863770,372.060211
      C442.365845,396.259125 437.705261,419.416534 422.893616,439.430054
      C405.465149,462.979462 381.558563,475.610260 353.032715,479.831451
      C343.693268,481.213531 334.145569,481.188110 323.874329,481.633820
      C314.039581,479.994049 304.835205,479.243744 296.041260,476.931976
      C263.018219,468.251038 239.773026,448.253326 227.982437,415.844666
      C221.560379,398.192383 220.487854,379.766113 221.101868,361.176361
      C233.970016,361.244354 246.838104,361.323730 259.706329,361.369049
      C261.836273,361.376526 263.966766,361.234955 266.896606,361.131073
      C272.039642,360.656158 273.059418,362.998260 272.885071,366.841187
      C272.354279,378.539612 272.952209,390.190399 277.333832,401.192261
      C286.294403,423.691620 304.121033,433.064484 327.632996,434.332031
      C332.263153,434.345215 336.573303,434.301605 340.868073,434.022003
      C364.136230,432.507294 382.981689,417.507385 387.996124,394.707764
      C390.613831,382.805389 391.427887,370.345734 391.666992,358.110657
      C392.408752,320.154938 392.491974,282.186279 392.816132,244.222534
      C392.949829,228.566452 393.046234,212.910080 393.159607,197.253830
      C398.408234,197.191208 403.656860,197.128601 409.797058,197.005814
      C412.966919,196.921539 415.245239,196.897430 417.523560,196.873337
    z'
    />
    <path
      fill='#BEBEBD'
      opacity='1.000000'
      stroke='none'
      d='
    M249.818832,353.168732
      C246.549896,353.177307 243.280975,353.185883 239.277771,353.083740
      C238.346451,352.990417 238.149429,353.007751 237.952393,353.025116
      C205.008881,352.687012 172.065384,352.348907 138.648926,351.510834
      C138.354782,346.689789 138.646851,342.369995 138.694351,338.047485
      C139.062271,304.571075 139.389709,271.094238 139.716339,237.617416
      C140.060822,202.308426 140.378082,166.999161 140.734711,131.690292
      C140.837326,121.530312 141.034821,111.371284 141.188828,101.211823
      C157.967926,101.211823 174.747040,101.211823 192.326340,101.211823
      C191.584824,172.008713 190.854935,241.695786 190.110123,312.807159
      C231.320282,312.807159 272.318817,312.807159 313.624695,312.807159
      C313.829437,315.801971 313.939117,317.406433 314.052399,319.913025
      C314.015961,327.587067 313.951050,334.358917 313.941254,341.130829
      C313.922455,354.113831 313.935425,354.113861 300.487854,353.691376
      C283.367889,353.395721 266.593353,353.282227 249.818832,353.168732
    z'
    />
    <path
      fill='#E0F0F7'
      opacity='1.000000'
      stroke='none'
      d='
    M417.754974,196.609512
      C415.245239,196.897430 412.966919,196.921539 410.248718,196.935699
      C417.535126,179.478409 425.936890,162.293640 432.843231,144.527542
      C446.083832,110.466850 454.213379,75.056808 458.093048,38.709873
      C459.258026,27.795868 460.019012,16.838739 461.015869,5.470875
      C503.087799,18.444405 533.520081,46.011608 556.136780,83.033737
      C565.974365,99.137222 573.489990,116.325325 579.352356,134.676910
      C567.153503,130.747620 554.729919,132.829758 542.556885,135.383804
      C498.685394,144.588593 458.813049,162.534164 423.945557,191.028381
      C421.886108,192.711411 419.969482,194.569214 417.754974,196.609512
    z'
    />
    <path
      fill='#65C0D6'
      opacity='1.000000'
      stroke='none'
      d='
    M300.833282,353.873505
      C313.935425,354.113861 313.922455,354.113831 313.941254,341.130829
      C313.951050,334.358917 314.015961,327.587067 314.095581,320.340332
      C336.786346,299.125427 356.662933,275.926758 374.905670,251.280823
      C376.388947,249.276886 377.849670,247.256210 379.635864,245.542877
      C379.691193,267.795624 379.470886,289.749359 379.161133,311.701843
      C378.832611,334.984344 378.895782,358.285583 377.923157,381.542480
      C376.681274,411.238922 356.529846,431.735077 327.305908,434.166290
      C304.121033,433.064484 286.294403,423.691620 277.333832,401.192261
      C272.952209,390.190399 272.354279,378.539612 272.885071,366.841187
      C273.059418,362.998260 272.039642,360.656158 267.271790,361.052307
      C269.404236,358.886780 271.961090,356.768829 275.160706,354.348083
      C284.146729,353.988007 292.489990,353.930756 300.833282,353.873505
    z'
    />
    <path
      fill='#BFBEBD'
      opacity='1.000000'
      stroke='none'
      d='
    M223.152283,195.567490
      C239.904022,195.567490 256.655762,195.567490 273.391815,195.567490
      C273.391815,232.396210 273.391815,268.365143 273.391815,304.884888
      C256.087372,304.884888 239.543488,304.884888 222.520401,304.367188
      C222.411560,267.755493 222.781921,231.661484 223.152283,195.567490
    z'
    />
    <path
      fill='#717171'
      opacity='1.000000'
      stroke='none'
      d='
    M140.714447,101.173645
      C141.034821,111.371284 140.837326,121.530312 140.734711,131.690292
      C140.378082,166.999161 140.060822,202.308426 139.716339,237.617416
      C139.389709,271.094238 139.062271,304.571075 138.694351,338.047485
      C138.646851,342.369995 138.354782,346.689789 138.195831,351.461792
      C123.107018,352.389069 126.042442,352.979736 126.013824,340.155701
      C125.976318,323.350159 126.016846,306.543671 126.150383,289.738617
      C126.437157,253.648682 126.775032,217.559082 127.150116,181.469940
      C127.402428,157.193604 127.764908,132.918411 128.055359,108.642433
      C128.146240,101.045959 128.112335,101.042061 135.748642,101.063454
      C137.245834,101.067650 138.742920,101.110474 140.714447,101.173645
    z'
    />
    <path
      fill='#858788'
      opacity='1.000000'
      stroke='none'
      d='
    M327.632996,434.332031
      C356.529846,431.735077 376.681274,411.238922 377.923157,381.542480
      C378.895782,358.285583 378.832611,334.984344 379.161133,311.701843
      C379.470886,289.749359 379.691193,267.795624 379.921326,245.365692
      C380.001587,231.393311 380.126282,217.897766 380.215240,204.401993
      C380.263458,197.085068 380.235992,197.081238 387.723938,197.097336
      C389.223297,197.100555 390.722565,197.143356 392.690735,197.210876
      C393.046234,212.910080 392.949829,228.566452 392.816132,244.222534
      C392.491974,282.186279 392.408752,320.154938 391.666992,358.110657
      C391.427887,370.345734 390.613831,382.805389 387.996124,394.707764
      C382.981689,417.507385 364.136230,432.507294 340.868073,434.022003
      C336.573303,434.301605 332.263153,434.345215 327.632996,434.332031
    z'
    />
    <path
      fill='#858788'
      opacity='1.000000'
      stroke='none'
      d='
    M220.632324,361.152405
      C220.487854,379.766113 221.560379,398.192383 227.982437,415.844666
      C239.773026,448.253326 263.018219,468.251038 296.041260,476.931976
      C304.835205,479.243744 314.039581,479.994049 323.393188,481.602783
      C300.933777,482.052460 278.911774,478.600037 258.746246,467.327209
      C232.393616,452.595825 217.189316,429.744568 210.989471,400.080200
      C210.003601,388.644592 209.187393,377.610565 208.185959,366.593353
      C207.796509,362.308929 209.237076,360.375000 213.714310,360.963196
      C215.834457,361.241730 218.011505,361.086945 220.632324,361.152405
    z'
    />
    <path
      fill='#A1A1A3'
      opacity='1.000000'
      stroke='none'
      d='
    M222.684631,195.490158
      C222.781921,231.661484 222.411560,267.755493 222.061142,304.301270
      C209.131027,305.233551 209.033905,305.232697 209.147812,292.372040
      C209.419830,261.661987 209.874435,230.953247 210.027451,200.242844
      C210.048004,196.115128 211.513428,194.963394 215.246582,195.303497
      C217.554214,195.513748 219.892456,195.387833 222.684631,195.490158
    z'
    />
    <path
      fill='#8E939C'
      opacity='1.000000'
      stroke='none'
      d='
    M300.487854,353.691376
      C292.489990,353.930756 284.146729,353.988007 275.323364,354.072876
      C266.761383,353.898346 258.679443,353.696198 250.208191,353.331390
      C266.593353,353.282227 283.367889,353.395721 300.487854,353.691376
    z'
    />
    <path
      fill='#8E939C'
      opacity='1.000000'
      stroke='none'
      d='
    M238.081223,353.120209
      C238.149429,353.007751 238.346451,352.990417 238.832825,353.040588
      C239.122147,353.108063 238.683319,353.273468 238.683319,353.273468
      C238.683319,353.273468 238.210052,353.215302 238.081223,353.120209
    z'
    />
  </svg>
)
