import React from 'react'
import cn from 'classnames'

interface Messages {
  className?: string
  children?: React.ReactNode
  icon?: string
  'data-test'?: string
}

const Message: React.FC<Messages> = ({
  'data-test': dataTest,
  children,
  className,
  icon,
}) => {
  if (!children) {
    return null
  }

  return (
    <div data-test={dataTest} className={`alert ${className || ''}`}>
      <div className='flex flex-row'>
        <span data-test='message'>
          {icon && <span className={cn('icon mr-1', icon)} />}
          {typeof children === 'string' ? (
            children.replace(/\n/g, '')
          ) : typeof children === 'object' && Object.keys(children).length ? (
            <div>
              {Object.keys(children).map((child) => (
                <div key={child} className='ml-2'>
                  {child}
                </div>
              ))}
            </div>
          ) : (
            'Error processing request'
          )}
        </span>
      </div>
    </div>
  )
}

export const ErrorMessage: React.FC<Messages> = ({ className, ...props }) => (
  <Message
    {...props}
    icon={props.icon || 'fas fa-exclamation-circle'}
    className={cn(className, 'alert-danger')}
  />
)

export const SuccessMessage: React.FC<Messages> = ({ className, ...props }) => (
  <Message {...props} className={cn(className, 'alert-success')} />
)

export default Message
