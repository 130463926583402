import cn from 'classnames';
import carouselArrowLeft from '../assets/carousel-arrow-left.svg';
import carouselArrowRight from '../assets/carousel-arrow-right.svg';
import { CSSProperties } from 'react';

type CarouselArrowProps = {
  type: 'next' | 'previous';
  onClick: () => void;
  style?: CSSProperties;
}

export const CarouselArrow = ({
  type,
  onClick,
  style,
}: CarouselArrowProps) => (
  <button
    style={style}
    className="rounded-full bg-lue-blue-700 text-white flex items-center justify-center min-w-[44px] min-h-[44px] max-w-[44px] max-h-[44px]"
    onClick={onClick}
  >
    <img
      className={cn(
        'max-w-[20px] max-h-[20px]',
        type ==='next' ? 'ml-[3px]' : 'mr-[3px]', // fix visual trickery that makes the arrows feel off-center
      )}
      src={type ==='next' ? carouselArrowRight : carouselArrowLeft}
      alt={type}
      height="20"
      width="20"
    />
  </button>
);
