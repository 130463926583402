import { SiteFooter } from "./SiteFooter";
import luLogo from "../assets/lu-logo.svg";
import cityIcon from "../assets/city.svg";
import moneyIcon from "../assets/money.svg";
import calendarIcon from "../assets/calendar.svg";
import handwritingImg from "../assets/handwriting.jpg";
import cn from "classnames";
import { PrimaryButton } from "./PrimaryButton";
import useLegalDocument from "common/providers/useLegalDocument";
import useLegalDocumentRatings from "common/providers/useLegalDocumentRatings";
import useStripeProductDetails from "common/providers/useStripeProductDetails";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Banner } from "./Banner";
import { useHtmlContent } from "hooks/useHtmlContent";
import { useLegalDocumentCreation } from "hooks/useLegalDocumentCreation";
import { useCollapsedHtmlContent } from "hooks/useCollapsedHtmlContent";
import starBoxEmpty from '../assets/star-box-empty.svg';
import starBoxFilled from '../assets/star-box-filled.svg';
import useProfile from "common/providers/useProfile";
import TagList from "./TagList";
import { useLegalDocumentMetadata } from 'common/hooks/useLegalDocumentMetadata';
import { setAuthRedirectCookie } from "common/utils/redirects";
import useLegalDocumentCode from "common/providers/useLegalDocumentCode";

const LegalDocument = () => {
  let { id } = useParams();
  const { profile } = useProfile();
  const navigate = useNavigate();
  const { legalDocument, getLegalDocument } = useLegalDocument();
  const metadata = useLegalDocumentMetadata(legalDocument);
  const { legalDocumentCode, getLegalDocumentCode } = useLegalDocumentCode();
  const {
    isCheckoutSessionLoading,
    createLegalDocument,
  } = useLegalDocumentCreation();

  const [expandedFaqIndex, setExpandedFaqIndex] = useState<number | null>(null);
  const {
    legalDocumentRatings,
    getLegalDocumentRatings,
    createLegalDocumentRating,
    updateLegalDocumentRating,
  } = useLegalDocumentRatings()

  const {
    stripeProductDetails,
    getStripeProductDetails,
  } = useStripeProductDetails();

  const legalDocumentRating = legalDocumentRatings?.[0];
  const { rating } = legalDocumentRating ?? {};
  const stripeProductId = legalDocument?.type?.stripe_product_id;

  useEffect(() => {
    getLegalDocument({ id });
    getLegalDocumentCode({ id });

    if (profile) {
      getLegalDocumentRatings({ legaldocument_id: id, user_id: profile?.id })
    }
  }, [id, profile?.id]);

  useEffect(() => {
    if (stripeProductId) {
      getStripeProductDetails({ id: stripeProductId });
    }
  }, [stripeProductId]);

  const { content: summary } = useHtmlContent(legalDocument?.summary, {
    extraClassNames: {
      p: 'text-xl font-medium',
    },
  });

  const { content, anchors } = useHtmlContent(legalDocument?.content, {
    extraClassNames: {
      h2: 'scroll-mt-28',
    }
  });

  const { sections: faqSections } = useCollapsedHtmlContent(legalDocument?.faq);

  const loginWithRedirect = useCallback((action: 'download' | 'create') => {
    // Navigate to login, setting a cookie including a query param to indicate
    // that the user wants to download or create a legal document when
    // redirected back to the current page after login.
    setAuthRedirectCookie(`${window.location.href.split('?')[0]}?${action}=true`);
    navigate('/login');
  }, [])

  const create = useCallback(() => {
    if (profile?.id)  {
      createLegalDocument(stripeProductId);

      return;
    }

    loginWithRedirect('create');
  }, [createLegalDocument, stripeProductId]);

  const onDownloadClick = useCallback(() => {
    // Redirect to the download page if there is no stripe product ID associated
    // with this document, or if the user already has a valid download code.
    if (!stripeProductId || legalDocumentCode?.code) {
      window.location.href = `/legal-documents/${legalDocument.id}/download`;

      return;
    }

    create();
  }, [legalDocument, legalDocumentCode, stripeProductId, create]);

  // Handle the callback to create or download a document after having been sent
  // through the login flow.
  useEffect(() => {
    const currentUrl = new URL(window.location.href);

    if (
      !!currentUrl.searchParams.get('create') &&
      stripeProductId
    ) {
      create();
    }
  }, [stripeProductId]);

  const onFaqClick = useCallback((index: number) => {
    if (expandedFaqIndex === index) {
      setExpandedFaqIndex(null);

      return;
    }

    setExpandedFaqIndex(index);
  }, [expandedFaqIndex]);

  const onRatingClick = useCallback((rating: number) => {
    if (!profile) {
      navigate('/login');

      return;
    }

    if (legalDocumentRating) {
      updateLegalDocumentRating({
        rating_id: legalDocumentRating.id,
        legaldocument: legalDocument.id,
        rating,
      }, {
        onSuccess: () => {
          getLegalDocumentRatings({
            legaldocument_id: legalDocument.id,
            user_id: profile?.id,
          })
        },
      })

      return;
    }

    createLegalDocumentRating({
      legaldocument: legalDocument.id,
      rating,
    }, {
      onSuccess: () => {
        getLegalDocumentRatings({
          legaldocument_id: legalDocument.id,
          user_id: profile?.id,
        })
      },
    })
  }, [profile?.id, legalDocument?.id, rating]);

  const isProPlusDocument = /pro plus/i.test(legalDocument?.type?.name ?? '');
  const price = useMemo(() => {
    if (!stripeProductDetails) {
      return null;
    }

    const rawPrice = (stripeProductDetails.price.unit_amount / 100).toLocaleString("en-GB", {
      style: "currency",
      currency: stripeProductDetails.price.currency.toUpperCase()
    });

    if (stripeProductDetails.price.tax_behavior === 'exclusive') {
      return `${rawPrice} + VAT`;
    }

    return rawPrice;
  }, [stripeProductDetails])

  if (!legalDocument) {
    return null;
  }

  return (
    <>
      <header className="flex flex-col lg:flex-row bg-lue-blue-200">
        <div className="lg:flex-[50%] flex flex-col justify-center px-6 md:px-12 py-14 lg:py-20 text-center lg:text-left">
          <h1 className="font-bold text-4xl xl:text-5xl text-lue-purple-400">
            {legalDocument.title}
          </h1>
          <ul className="mt-4 text-xl text-lue-blue-700">
            <li>
              <span className="font-medium">ID: </span>{legalDocument.id}
            </li>
          </ul>
        </div>
        <div className="lg:flex-[50%] px-6 md:px-12">
          <div
            className="mt-8 lg:mt-20 py-8 lg:py-12 rounded-t-3xl bg-white flex flex-col items-center px-6 sm:px-8 xl:px-20"
          >
            <div className="mb-2 lg:mb-6 flex justify-center items-center font-medium tracking-wide text-xl lg:text-2xl font-semibold text-lue-blue-800 leading-none md:whitespace-nowrap">
              <img
                className="mr-2"
                src={luLogo}
                width="36"
                height="36"
                alt="Legal Utopia logo"
              />
              Legal Utopia
            </div>
            {Array(6).fill(null).map((_, i) => (
              <div
                key={i}
                className={cn(
                  'h-[10px] lg:h-[15px] w-full bg-neutral-100 rounded-full mt-5',
                  [4, 5].includes(i) && 'hidden md:block'
                )}
              />
            ))}
            <div
              className="h-[10px] lg:h-[15px] w-[40%] bg-lue-purple-400 rounded-full mt-5 mr-auto"
            />
          </div>
        </div>
      </header>

      <div className="px-6 md:px-12 bg-lue-blue-700 text-white flex flex-col lg:flex-row justify-between items-center text-lg md:sticky top-0">
        <div className="flex flex-col lg:flex-row items-center">
          <ul className="mt-6 lg:mt-0 lg:ml-3 text-center font-bold h-full space-y-4 md:space-x-6 md:space-y-0 flex flex-col md:flex-row items-center">
            {[
              { id: 'introduction', title: 'Introduction' },
              ...anchors,
            ].map((anchor) => (
              <li key={anchor.id} className="py-8 lg:py-6">
                <a
                  className="cursor-pointer"
                  href={`#${anchor.id}`}>
                  {anchor.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="min-w-[200px] py-8 lg:py-6">
          <PrimaryButton
            disabled={isCheckoutSessionLoading}
            onClick={onDownloadClick}
          >
            Download
          </PrimaryButton>
        </div>
      </div>

      <div className="text-lue-blue-700 px-6 md:px-12 mt-12 lg:mt-20 flex flex-col lg:flex-row">

        {/* Main content */}
        <div className="flex-grow md:pr-12">
          <section id="introduction" className="scroll-mt-28">
            <h2 className="text-3xl lg:text-4xl mb-4 lg:mb-8 font-bold">
              Introduction
            </h2>
            {summary}
            <div className="flex flex-col md:flex-row mt-12 mb-10 space-y-8 md:space-y-0 md:space-x-8">
              {[
                stripeProductDetails && {
                  icon: moneyIcon,
                  title: price,
                  description: stripeProductDetails.description,
                },
                {
                  icon: calendarIcon,
                  title: '24/365 Access',
                  description: 'Automated drafter',
                },
                legalDocument.jurisdiction && {
                  icon: cityIcon,
                  title: 'Jurisdiction',
                  description: legalDocument.jurisdiction.name,
                },
              ].filter(x => x).map((item) => (
                <div
                  key={item.title}
                  className="flex flex-row items-center"
                >
                  <div className="rounded-full p-3 bg-lue-purple-400 mr-3">
                    <img src={item.icon} alt="" width="24" height="24" />
                  </div>
                  <div>
                    <p className="text-xl font-bold">{item.title}</p>
                    <p className="text-lg">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </section>
          {content}
          {!!faqSections?.length && (
            <div
              className="bg-neutral-50 px-6 py-5 lg:px-8 lg:py-7 mt-12 lg:mt-20"
            >
              <h2 className="text-3xl lg:text-4xl	font-bold mb-6">FAQs</h2>
              {faqSections?.map(({ title, content }, i) => {
                const faqId = `faq-${i}`;
                const isExpanded = expandedFaqIndex === i;

                return (
                  <section key={title} className="mt-4">
                    <h3>
                      <button
                        aria-expanded={isExpanded}
                        aria-controls={faqId}
                        type="button"
                        className="flex flex-row justify-between text-xl mt-6 font-bold select-none hover:underline w-full text-left"
                        onClick={() => {
                          onFaqClick(i);
                        }}
                      >
                        {title}
                        <svg
                          width="18"
                          height="10"
                          viewBox="0 0 18 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className={cn(
                            'transition-transform duration-300',
                            isExpanded ? "" : "rotate-180",
                          )}
                        >
                          <path
                            d="M-8.31755e-08 8.09717L2.115 10L9 3.81916L15.885 10L18 8.09717L9 5.60272e-07L-8.31755e-08 8.09717Z"
                            fill="black"
                          />
                        </svg>
                      </button>
                    </h3>
                    <div
                      id={faqId}
                      className={cn(
                        "overflow-hidden transition-all duration-300",
                        isExpanded ? 'max-h-auto' : 'max-h-[0px]',
                      )}
                    >
                      <div className="mt-4">
                        {content}
                      </div>
                    </div>
                  </section>
                )
              })}
            </div>
          )}
        </div>

        {/* Sidebar */}
        <div className="w-full lg:w-[400px] lg:min-w-[400px] mt-12 lg:mt-0">
          <div className="w-full border w-full rounded-lg mb-6">
            <img
              className="object-cover w-full"
              src={handwritingImg}
              alt=""
            />
            <div className="p-5">
              <h3 className="text-b33 font-medium text-2xl">
                {isProPlusDocument ? (
                  'Pro Plus Access'
                ) : (
                  `${legalDocumentCode?.code || !stripeProductId ? '' : 'Purchase & '}Download`
                )}
              </h3>
              <p className="mt-2 text-lawGrey">
                {isProPlusDocument ? (
                  'Access 100 Pro Plus documents to download.'
                ) : (
                  'Download your document.'
                )}
              </p>
              {(price || legalDocumentCode?.code) && (
                <p className="text-basic-blue text-2xl font-bold mt-2 mb-6">
                  {legalDocumentCode?.code ? (
                    `${legalDocumentCode?.remaining_redeems} credit${legalDocumentCode?.remaining_redeems === 1 ? '' : 's'} remaining`
                  ) : (
                    price
                  )}
                </p>
              )}
              <PrimaryButton
                className="mt-6"
                disabled={isCheckoutSessionLoading}
                onClick={onDownloadClick}
              >
                {isProPlusDocument ? (
                  'Subscribe'
                ) : (
                  'Download'
                )}
              </PrimaryButton>
            </div>
          </div>

          <div className="w-full border w-full rounded-lg bg-neutral-50 px-10 py-12 flex flex-col items-center">
            <p className="text-lue-blue-700 font-bold text-base">
              How helpful was this page?
            </p>
            <ul className="flex flex-row space-x-3 mt-4">
              {Array.from({ length: 5 }, (_, i) => (
                <li key={i}>
                  <button
                    type="button"
                    className="cursor-pointer hover:scale-95 duration-200 transition-transform"
                    onClick={() => {
                      onRatingClick(i + 1);
                    }}
                  >
                    <span className="hidden">
                      Rate {i + 1} star
                    </span>
                    <img
                      src={rating && rating >= i + 1 ? starBoxFilled : starBoxEmpty}
                      alt=""
                      width="50"
                      height="50"
                    />
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="mt-6 w-full border w-full rounded-lg bg-neutral-50 px-8 py-12">
            <ul className="flex flex-col space-y-3">
              {metadata.filter((x) => x).map(({ key, value }) => (
                <li key={key}>
                  <span className="font-bold">{key}</span>:{' '}
                  {value}
                </li>
              ))}
            </ul>
          </div>

          <div className="mt-8 space-y-4">
            {!!legalDocument.areas_of_law.length && (
              <TagList
                title="Area of law"
                items={legalDocument.areas_of_law}
              />
            )}
            {!!legalDocument.business_categories.length && (
              <TagList
              title="Business Category"
                items={legalDocument.business_categories}
              />
            )}
            {!!legalDocument.industries.length && (
              <TagList
                title="Industry"
                items={legalDocument.industries}
              />
            )}
          </div>
        </div>
      </div>

      <div className="my-12 lg:mt-16 px-6 md:px-12">
        <Banner />
      </div>
      <SiteFooter />
    </>
  );
}

export default LegalDocument;
