import { useEffect, useState } from 'react'

const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.vrgsoft.utopia&hl=en_GB&gl=US'
const APP_STORE_URL = 'https://apps.apple.com/gb/app/id1487650991?mt=8'

declare global {
  interface Window {
    MSStream: unknown
  }
}

// see https://stackoverflow.com/a/9039885
const isIOS = () =>
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
    'Mac',
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document) ||
  // User agent sniffing fallback
  (/iPad|iPhone|iPod|Mac/.test(navigator.userAgent) && !window.MSStream)

const isAndroid = () =>
  /win/i.test(navigator.userAgent) || /android/i.test(navigator.userAgent)

const getAppStoreUrl = () => {
  if (isAndroid()) {
    return PLAY_STORE_URL
  }

  if (isIOS()) {
    return APP_STORE_URL
  }

  console.error("Can't detect operating system for app store link")

  return null
}

export const useAppStoreUrl = () => {
  const [appStoreUrl, setAppStoreUrl] = useState<string | null>(null)

  useEffect(() => {
    setAppStoreUrl(getAppStoreUrl())
  }, [])

  return { appStoreUrl }
}
