import { ComponentType, ReactNode, createContext, useMemo } from 'react'

type LinkComponentType = ComponentType<{
  href: string
  className?: string
  children: ReactNode
}>

type LinkProviderProps = {
  children: ReactNode
  LinkComponent: LinkComponentType
}

type LinkProviderContext = {
  Link: LinkComponentType
}

export const LinkContext = createContext<LinkProviderContext>({
  Link: () => {
    throw new Error('A Link component must be provided via the LinkProvider')
  },
})

/**
 * A provider to help with reusing components regardless of the framework.
 *
 * The CWP application currently uses React Router, whereas the Lawyer
 * application uses Next.js.
 */
export const LinkProvider = ({
  children,
  LinkComponent,
}: LinkProviderProps) => {
  const value = useMemo(() => ({ Link: LinkComponent }), [LinkComponent])

  return <LinkContext.Provider value={value}>{children}</LinkContext.Provider>
}
