import { useEffect, useState } from 'react'

/**
 * Toggle a flag when the tab key is pressed for the first time.
 *
 * This can be useful for cases where we want to disable the native focus
 * outline for an element when the user is using a mouse, but enable it when
 * they are navigating using the keyboard.
 */
export const useKeyboardTabbed = () => {
  const [usingKeyboard, setUsingKeyboard] = useState(false)

  useEffect(() => {
    const checkForTabPress = (evt) => {
      const isTab = evt.key === 'Tab' || evt.keyCode === 9

      if (isTab) {
        setUsingKeyboard(true)

        window.removeEventListener('keydown', checkForTabPress)
      }
    }

    window.addEventListener('keydown', checkForTabPress)

    return () => {
      window.removeEventListener('keydown', checkForTabPress)
    }
  }, [])

  return usingKeyboard
}
