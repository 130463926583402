import { ChangeEvent, KeyboardEvent, forwardRef, useCallback, useRef, useState } from 'react'
import cn from 'classnames'

type VerificationCodeInputProps = {
  label?: string
  isNew?: boolean
  error?: string
}

export const VerificationCodeInput = forwardRef <HTMLInputElement,
  VerificationCodeInputProps
>(({ label, isNew, error }, ref) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [value, setValue] = useState('')


  const onInputChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    const inputs = [...containerRef.current.querySelectorAll('input')]
    const currentIndex = inputs.findIndex((input) => input === evt.target)
    const hasValue = !!evt.target.value
    const code = inputs
      .map((input) => input.value.trim())
      .filter((x) => x)
      .join('')

    setValue(code)

    if (hasValue && currentIndex < inputs.length - 1) {
      inputs[currentIndex + 1].focus()
    }
  }, [])

  const onKeyDown = useCallback((evt: KeyboardEvent<HTMLInputElement>) => {
    const inputs = [...containerRef.current.querySelectorAll('input')]
    const currentIndex = inputs.findIndex((input) => input === evt.target)

    if (evt.key === 'Backspace' && !evt.currentTarget.value && currentIndex > 0) {
      inputs[currentIndex - 1].focus()
    }
  }, [])


  return (
    <div className='my-8'>
      {label && !isNew && (
        <label className='text-base font-bold mb-3' htmlFor='code'>
          {label}
        </label>
      )}
      <input ref={ref} type='hidden' name='code' value={value} />
      <div
        ref={containerRef}
        className='space-x-2 flex flex-row justify-center'
      >
        {Array(6)
          .fill(null)
          .map((_, i) => (
            <input
              key={i}
              type='text'
              maxLength={1}
              className={cn(
                'rounded-lg flex items-center relative overflow-hidden aspect-[1/1] w-[45px] text-[18px] font-bold text-center',
                ' text-lg tracking-[0.4px]',
                isNew ? 'bg-white border text-lg border-[#5858DF] shadow-[inset_1px_1px_1px_rgba(0,0,0,0.05)]' : 'bg-neutral-100',
                error ? 'border-[#CE2815]' : 'border-[rgba(110,119,133,0.3)]'
              )}
              onChange={onInputChange}
              onKeyDown={onKeyDown}
            />
          ))}
      </div>
      {error && (
        <div className="mt-2 flex ustify-center items-center text-[#CE2815]">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
          </svg>
          <span className="text-sm">{error}</span>
        </div>
      )}
    </div>
  )
})