import React, { ReactNode } from 'react'
import cn from 'classnames';
import profileIcon from '../assets/profile-filled-white.svg'
import suitcaseIcon from '../assets/suitcase.svg'
import { RoundIcon } from 'common/components/RoundIcon';

type ProfileFormProps = {
  onSubmit: () => void;
  onSwitchAccountsClick: () => void;
  isProfileSaving: boolean;
  isBusiness: boolean;
  children: ReactNode;
  title: string;
  description: string;
  className?: string;
}

export const ProfileForm = ({
  onSubmit,
  onSwitchAccountsClick,
  isProfileSaving,
  isBusiness,
  children,
  title,
  description,
  className,
}: ProfileFormProps) => (
  <div className={cn("grid lg:grid-cols-12", className)}>
    <div className="lg:col-span-3 mb-4 lg:mb-0">
      <h2 className="font-bold text-xl">
        {title}
      </h2>
      <p>
        {description}
      </p>
    </div>
    <div className="lg:col-span-9 border border-gray-200 rounded-xl bg-white shadow">
      <form
        onSubmit={onSubmit}
        className="space-y-8 p-6 lg:p-10"
      >
        {children}
      </form>
      <div
        className="border-t border-gray-200 p-6 lg:px-10 lg:py-8 flex flex-col md:flex-row md:justify-between items-center"
      >
        <div
          className="flex flex-row"
        >
          <RoundIcon
            src={isBusiness ? suitcaseIcon : profileIcon}
            size={30}
            iconSize={isBusiness ? 16 : 14}
            className="bg-lue-purple-400"
          />
          <div className="ml-2 font-medium text-lg select-none">
            {isBusiness ? 'Business account' : 'Personal account'}
          </div>
        </div>
        <div className="space-y-4 md:space-y-0 md:space-x-4 mt-4 md:mt-0 flex flex-col md:flex-row items-center">
          <button
            className="cursor-pointer select-none rounded-full border border-lue-purple-600 text-lue-purple-600 text-lg font-bold py-2 px-8 whitespace-nowrap bg-white transition-colors hover:bg-lue-purple-600 hover:text-white"
            onClick={onSwitchAccountsClick}
          >
            Switch accounts
          </button>
          <button
            className="cursor-pointer select-none rounded-full border border-white text-white text-lg font-bold py-2 px-8 whitespace-nowrap bg-lue-purple-400 transition-colors hover:bg-lue-purple-500"
            disabled={isProfileSaving}
            onClick={onSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
);
