import { FaHeart, FaRegHeart } from "react-icons/fa";
import React, { FC, MouseEventHandler, useState } from "react";
import {
  AiOutlineUp,
  AiOutlineDown,
  AiOutlineEllipsis,
  AiOutlineShareAlt,
} from "react-icons/ai";

import {
  InfoItem,
  MenuItem,
  InfoPopup,
  AvatarImage,
  ContactPopup,
  ProfileDetail,
  BookNowSection,
  ProfileReviews,
  ProfileCardBase,
  ContactBtnsSection,
  ExternalReviewLink,
  SharePopup,
} from "../components";
import "../../../styles/style.css";
import Img from "../../base/Img";
import ProfileRating from "../../ProfileRating";
import { getCardTheme } from "utils/StylesUtil";
import Multiline from "components/base/Multiline";
import map_icon from "assets/FAL/map_icon.png";
import LoadingSpinner from "components/base/LoadingSpinner";
import useProfileDetail from "common/providers/useProfileDetail";
import useAreasOfPractice from "common/providers/useAreasOfPractice";
import {
  AccountType,
  LawyerListObject,
  MembershipType,
} from "common/state-type";
import Project from "common/project";

type LawyerProfileCardType = {
  onMouseEnter?: MouseEventHandler
  data: LawyerListObject
  longitude: any
  latitude: any
  areas?: string[]
  onFavPress?: () => void
  onBookNow?: () => void
  bookingStatus?: React.ReactNode
  onReviewBooking?: () => void
  cardStyle?: string
  className?: string
  onClick?: () => void
}

const LawyerProfileCard: FC<LawyerProfileCardType> = ({
  data,
  longitude,
  latitude,
  onMouseEnter,
  onFavPress,
  areas,
  onBookNow,
  bookingStatus,
  onReviewBooking,
  cardStyle,
  className,
}) => {
  const { profileDetail, getProfileDetail, profileDetailLoading } =
    useProfileDetail()
  const { areasOfPractice } = useAreasOfPractice()
  const aop =
    data?.organisation.areas_of_practice &&
    areasOfPractice &&
    areasOfPractice.filter((e) =>
      data?.organisation.areas_of_practice.find((area) => area.id === e.id),
    )

  const operatingAreas = aop?.map((item) => item.name)
  const [expandedView, setExpandedView] = useState(false)
  const [reviewSection, setReviewSection] = useState(false)

  const handleShowMore = () => {
    setExpandedView(!expandedView)
    setReviewSection(false)
  }

  const openReviews = () => {
    if (data.id !== profileDetail?.id) {
      getProfileDetail({ type: AccountType.LAWYER, id: data.id })
    }
    setReviewSection(true)
    setExpandedView(true)
  }

  const closeReviews = () => {
    setReviewSection(false)
    setExpandedView(false)
  }

  const membershipType: MembershipType = data.membership_type
  const themeColor = getCardTheme(membershipType)
  const memberships = data.memberships.map((m) => m.name).join(', ')
  const qualifications = data.qualifications.map((q) => q.name).join(', ')

  const renderReviewSection = () => {
    if (profileDetail) {
      return (
        <ProfileReviews
          onClose={closeReviews}
          reviews={profileDetail?.lawyer_ratings}
          profileHolder={data.user.first_name + ' ' + data.user.last_name}
        />
      )
    } else if (profileDetailLoading) {
      return <LoadingSpinner />
    }
    return null
  }

  return (
    <div
      id={`profile-${data.id}`}
      onMouseEnter={onMouseEnter}
      className={className}
    >
      <ProfileCardBase membershipType={membershipType} className={cardStyle}>
        <div className='flex flex-col w-full'>
          <div className='flex flex-col flex-1 w-full'>
            <div className='flex flex-row'>
              <div className='flex flex-col text-black w-[23.87%] place-content-between items-center'>
                <AvatarImage
                  imgSource={data.avatar}
                  fullName={data.user.first_name + ' ' + data.user.last_name}
                />
                <ContactBtnsSection
                  phone={data.phone_number}
                  email={data.user.email}
                  website={data.website_url}
                  themeColor={themeColor}
                />
              </div>
              <ProfileDetail
                name={data.user.first_name + ' ' + data.user.last_name}
                profession='Solicitor'
                subcriptionType={Utils.capitalizeFirstLetter(membershipType)}
                organization={data.organisation.practice_name}
                experience={`${data.number_of_practice_years} years`}
                areas={areas}
                operatingAreas={aop && aop.length >= 1 ? operatingAreas : null}
                themeColor={themeColor}
                bookingStatus={bookingStatus}
              />
            </div>
            {membershipType !== MembershipType.NONE && (
              <div className='flex flex-1 flex-col'>
                <button
                  className=' place-self-center flex flex-row'
                  onClick={handleShowMore}
                >
                  {!expandedView && (
                    <p className='text-center text-gray-500 text-sm pr-2'>
                      show more ...
                    </p>
                  )}
                  {expandedView ? (
                    <AiOutlineUp size={20} color='#000000' />
                  ) : (
                    <AiOutlineDown size={20} color='#000000' />
                  )}
                </button>
                {reviewSection ? (
                  renderReviewSection()
                ) : expandedView ? (
                  <div className='flex flex-col flex-1 pr-2'>
                    <div className='flex flex-col flex-1'>
                      <h4 className='font-medium leading-tight text-black pb-2'>
                        About {data.user.first_name} {data.user.last_name}
                      </h4>
                      <div className='font-normal mb-4 text-black'>
                        <Multiline text={data.bio} />
                      </div>
                    </div>

                    <div
                      className={`flex items-center justify-center h-8 w-8 rounded-full ${themeColor.class}`}
                    >
                      <InfoPopup
                        themeColor={themeColor}
                        triggerrer={
                          <AiOutlineEllipsis color='#fff' size={20} />
                        }
                        position='right bottom'
                        on='hover'
                        closeOnDocumentClick
                        mouseLeaveDelay={300}
                        mouseEnterDelay={0}
                        arrow
                        nested
                        MenuItems={[
                          <MenuItem
                            key='1stItem'
                            Icon={data.favourite_id ? FaHeart : FaRegHeart}
                            label={
                              data.favourite_id
                                ? 'Remove from Favourite'
                                : 'Add Favorites'
                            }
                            onClick={onFavPress}
                          />,
                          <SharePopup
                            key='2ndItem'
                            shareLink={`${Project.cwp}lawyers/${data.id}`}
                          />,
                          <ContactPopup
                            key='3rdItem'
                            themeColor={themeColor}
                            contactNumber={data.phone_number}
                            email={data.user.email}
                            website={data.website_url}
                          />,
                        ]}
                      >
                        <></>
                      </InfoPopup>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
        {membershipType !== MembershipType.NONE && (
          <div className='flex flex-col place-content-between w-[31%]'>
            <div>
              <div className='flex flex-row place-content-between pb-6'>
                <div className='flex flex-col items-center pl-4 flex-1'>
                  <div className='flex flex-row items-center mr-1 text-black mt-4'>
                    <Img imgSrc={map_icon} />
                    <span className='pl-2 text-sm capitalize'>
                      {Utils.getDistance(
                        latitude,
                        longitude,
                        data.office.latitude,
                        data.office.longitude,
                      )}{' '}
                      Miles
                    </span>
                  </div>
                </div>
                <button
                  onClick={onFavPress}
                  className={`flex items-center justify-center h-8 w-8 rounded-full ${themeColor.class}`}
                >
                  {data.favourite_id ? (
                    <FaRegHeart color='#fff' size={20} />
                  ) : (
                    <FaHeart color='#fff' size={20} />
                  )}
                </button>
              </div>
              {data.google_reviews_url && (
                <ExternalReviewLink
                  label='See our Google reviews'
                  isGoogle
                  link={data.google_reviews_url}
                />
              )}

              {data.trust_pilot_reviews_url && (
                <ExternalReviewLink
                  label=' See our TrustPilot Reviews'
                  isTrustpilot
                  link={data.trust_pilot_reviews_url}
                />
              )}
              <button onClick={openReviews}>
                <ProfileRating rating={data.average_rating} />
              </button>
              {(expandedView || reviewSection) && (
                <>
                  <InfoItem
                    title='SRA AUTHORISED'
                    content={data.sra_authorised ? 'Yes' : 'No'}
                  />
                  <InfoItem title='SRA NUMBER' content={data.sra_number} />
                  {!!data.qualifications.length && (
                    <InfoItem
                      title='ACADEMIC QUALIFICATIONS'
                      content={qualifications}
                    />
                  )}
                  {!!data.memberships.length && (
                    <InfoItem title='MEMBERSHIPS' content={memberships} />
                  )}
                </>
              )}
            </div>
            <BookNowSection
              btnLabel={bookingStatus ? 'Review Booking' : 'Book Now'}
              onClick={bookingStatus ? onReviewBooking : onBookNow}
              title={
                data.rate_per_hour_excluding_vat
                  ? `From £${data.rate_per_hour_excluding_vat?.toFixed(2)} ph`
                  : '£ Nil ph'
              }
              themeColor={themeColor}
              popupTitle='*Price information*'
              popupDescription={`Legal Utopia’s ‘Book a Lawyer’ and ‘Find a Lawyer’ services are free.
              \n We do not charge users for finding or booking a lawyer through our website or mobile app.
               Please discuss your legal fees directly with your lawyer`}
              titleClass='text-lg'
            />
          </div>
        )}
      </ProfileCardBase>
    </div>
  )
}

export default LawyerProfileCard;
