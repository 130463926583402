import { KeyValue } from "common/state-type";
  
export const RadiusData: KeyValue[] = [
    {
      label: "Within 1/2 mile",
      value: 0.5,
    },
    {
      label: "Within 1/4 mile",
      value: 0.25,
    },
    {
      label: "Within 1 mile",
      value: 1,
    },
    {
      label: "Within 3 miles",
      value: 3,
    },
    {
      label: "Within 5 miles",
      value: 5,
    },
    {
      label: "Within 20 miles",
      value: 20,
    },
  ];