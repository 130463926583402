import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseStripeCheckoutUrl = {
  stripeCheckoutUrl?: AppState['stripeCheckoutUrl']
  stripeCheckoutUrlLoading: AppState['stripeCheckoutUrlLoading']
  stripeCheckoutUrlError: AppState['stripeCheckoutUrlError']
  getStripeCheckoutUrl: (
    data: RequestTypes['getStripeCheckoutUrl'],
    callbacks?: Callbacks,
  ) => void
}

export default function useStripeCheckoutUrl(): UseStripeCheckoutUrl {
  const {
    stripeCheckoutUrl,
    stripeCheckoutUrlLoading,
    stripeCheckoutUrlError,
  } = useSelector((state: AppState) => ({
    stripeCheckoutUrl: state.stripeCheckoutUrl,
    stripeCheckoutUrlLoading: state.stripeCheckoutUrlLoading,
    stripeCheckoutUrlError: state.stripeCheckoutUrlError,
  }))
  const dispatch = useDispatch()
  const getStripeCheckoutUrl = useCallback(
    (data: RequestTypes['getStripeCheckoutUrl'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getStripeCheckoutUrl(data, callbacks))
    },
    [dispatch],
  )
  return {
    stripeCheckoutUrl,
    stripeCheckoutUrlLoading,
    stripeCheckoutUrlError,
    getStripeCheckoutUrl,
  }
}
