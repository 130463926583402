import React from 'react'
import { SiteFooterLinksList } from './SiteFooterLinksList'
import { SiteFooterLinksListSection } from './SiteFooterLinksListSection'
import luLogo from '../assets/lu-logo.svg'
import { SiteFooterSocialLinks } from './SiteFooterSocialLinks'
import { AppleAppStoreButton } from 'components/AppStore/AppleAppStoreButton'
import { BaseContainer } from './BaseContainer'

export const SiteFooter = () => {
  const topCategories = [
    'Top UK Cities',
    'Top Lawyers',
    'Top LegalDocs',
    'Law Areas',
    'Law Firms',
    'Chambers',
    'Barristers',
    'Solicitors',
  ]
  const cities = [
    'Aberdeen',
    'Armagh',
    'Bangor',
    'Bath',
    'Belfast',
    'Birmingham',
    'Bradford',
    'Brighton and Hove',
    'Bristol',
    'Cambridge',
    'Canterbury',
    'Cardiff',
    'Carlisle',
    'Chelmsford',
    'Chester',
    'Chichester',
    'Colchester',
    'Coventry',
    'Derby',
    'Doncaster',
    'Dundee',
    'Dunfermline',
    'Durham',
    'Edinburgh',
    'Ely',
    'Exeter',
    'Glasgow',
    'Gloucester',
    'Hereford',
    'London',
  ]

  return (
    <footer className='bg-[#001A3D] text-white relative'>
      <BaseContainer size='xl' className='mb-[4%]'>
        <div className='py-10 space-y-12'>
          <nav className='grid grid-cols-2 sm:grid-cols-4 md:grid-cols-8 gap-2 md:gap-4 leading-6 text-lg sm:text-xl md:text-2xl font-semibold'>
            {topCategories.map((category, index) => (
              <a
                key={index}
                href={`https://www.legalutopia.co.uk/${category
                  .toLowerCase()
                  .replace(/ /g, '-')}`}
              >
                {category}
              </a>
            ))}
          </nav>

          {/* Cities list */}
          <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-2 md:gap-4 text-sm sm:text-base md:text-lg'>
            {cities.map((city, index) => (
              <a
                key={index}
                href={`https://www.legalutopia.co.uk/cities/${city
                  .toLowerCase()
                  .replace(/ /g, '-')}`}
              >
                <p className='text-[#BBC7D7]'> {city}</p>
              </a>
            ))}
          </div>

          <div className='bg-[#83A7D7] h-[0.3px] mx-auto w-[100%]'></div>
          {/* Main links */}

          {/* <div className='grid grid-cols-5 md:gap-2 2xl:gap-4  text-lg'> */}
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 sm:gap-8 text-lg py-6'>
            <SiteFooterLinksListSection title='Legal Help'>
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8'>
                <div>
                  <h4 className='font-semibold text-lg text-[#83A7D7] mb-2'>
                    For business & individuals
                  </h4>
                  <SiteFooterLinksList
                    links={[
                      {
                        label: 'Find a Lawyer',
                        href: '/find-a-lawyer',
                      },
                      {
                        label: 'Create a LegalDoc',
                        href: '/create-a-legaldoc',
                      },
                      {
                        label: 'Diagnose My Issue',
                        href: '/diagnose-my-issue',
                      },
                      {
                        label: 'Answer My Question',
                        href: '/answer-my-question',
                      },
                      {
                        label: 'Pricing',
                        href: '/pricing',
                      },
                    ]}
                  />
                </div>
                <div>
                  <h4 className='font-semibold text-lg text-[#83A7D7] mb-2'>
                    For lawyers
                  </h4>
                  <SiteFooterLinksList
                    links={[
                      {
                        label: 'Upskill My Practise',
                        href: '/upskill-my-practise',
                      },
                      {
                        label: 'Find a Lawyer',
                        href: '/find-a-lawyer',
                      },
                      {
                        label: 'Quality Indicators',
                        href: 'quality-indicators',
                      },
                      {
                        label: 'Data Standards',
                        href: '/data-standards',
                      },
                      {
                        label: 'Pricing',
                        href: 'pricing',
                      },
                    ]}
                  />
                </div>
              </div>
            </SiteFooterLinksListSection>

            <SiteFooterLinksListSection title='Resources'>
              <SiteFooterLinksList
                links={[
                  {
                    label: 'Discover Law',
                    href: '/discover-law',
                  },
                  {
                    label: 'Quality Indicators',
                    href: '/quality-indicators',
                  },
                  {
                    label: 'Document Dictionary',
                    href: '/document-dictionary',
                  },
                  {
                    label: 'Legal Term Glossary',
                    href: '/legal-term-glossary',
                  },
                  {
                    label: 'Law Finder',
                    href: 'law-finder',
                  },
                ]}
              />
            </SiteFooterLinksListSection>

            <SiteFooterLinksListSection title='Legal Utopia'>
              <SiteFooterLinksList
                links={[
                  {
                    label: 'Who we are',
                    href: '/whoweare',
                  },
                  {
                    label: 'Contact us',
                    href: '/contact-us',
                  },
                  {
                    label: 'Weekly email',
                    href: '/weekly-email',
                  },
                  { label: 'Blog', href: '/blog' },
                  {
                    label: 'Podcast',
                    href: '/podcast',
                  },
                  {
                    label: 'Sitemap',
                    href: '/sitemap',
                  },
                ]}
              />
            </SiteFooterLinksListSection>

            <SiteFooterLinksListSection title='Legal'>
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8'>
                <div>
                  <SiteFooterLinksList
                    links={[
                      {
                        label: 'Cookies (site) Policy',
                        href: '/cookies-policy',
                      },
                      {
                        label: 'Privacy Policy',
                        href: '/privacy-policy-web',
                      },
                      {
                        label: 'Terms of Use',
                        href: '/terms-of-use-consumer',
                      },
                      {
                        label: 'Equal Opportunities policy',
                        href: '/equal-opportunities',
                      },
                      {
                        label: 'Employee policy',
                        href: '/employee-privacy',
                      },
                      {
                        label: 'Marketing policy',
                        href: '/marketing-policy',
                      },
                      {
                        label: 'Cookies policy',
                        href: '/cookies-policy',
                      },
                      {
                        label: 'Cookies policy (Mobile App)',
                        href: '/cookies-policy-app',
                      },
                      {
                        label: 'Privacy policy',
                        href: '/privacy-policy-web',
                      },
                      {
                        label: 'Privacy policy (Mobile App)',
                        href: '/privacy-policy',
                      },
                    ]}
                  />
                </div>
                <div>
                  <SiteFooterLinksList
                    links={[
                      {
                        label: 'Data retention policy',
                        href: '/data-retention',
                      },
                      {
                        label: 'Complaints policy',
                        href: '/complaints-policy',
                      },
                      {
                        label: 'Terms of Sale (Consumer)',
                        href: '/terms-of-sale-consumer',
                      },
                      {
                        label: 'Terms of Use (Business)',
                        href: '/terms-of-use-business',
                      },
                      {
                        label: 'Terms of Use (Consumer)',
                        href: '/terms-of-use-consumer',
                      },
                      {
                        label: 'Terms of Sale (Business)',
                        href: '/terms-of-sale-business',
                      },
                      {
                        label: 'Terms of Sale (Lawyer)',
                        href: '/terms-of-sale-lawyer',
                      },
                      {
                        label: 'Disclaimer',
                        href: '/disclaimer-general',
                      },
                      {
                        label: 'Disclaimer Legal Checker',
                        href: '/disclaimer-legal-checker',
                      },
                      {
                        label: 'Reviews Policy',
                        href: '/reviews-policy',
                      },
                      {
                        label: 'Legal',
                        href: '/legal',
                      },
                    ]}
                  />
                </div>
              </div>
            </SiteFooterLinksListSection>
          </div>

          <div className='bg-[#83A7D7] h-[0.3px] mx-auto w-[100%]'></div>
          {/* Logo and app download */}
          <div className='flex flex-wrap items-start  gap-8 sm:gap-6 pt-8'>
            <div className='flex-1 min-w-[300px] max-w-[500px] mb-8 md:mb-0'>
              <div className='flex items-center font-medium tracking-wide mb-6'>
                <img
                  className='mr-2'
                  src={luLogo}
                  width='32'
                  height='32'
                  alt='Legal Utopia logo'
                />
                <span className='text-lg sm:text-xl'>Legal Utopia</span>
              </div>
              <p className='text-white text-4xl sm:text-5xl lg:text-[60px] font-bold leading-tight mb-8'>
                Law is for everyone<span className='text-lue-blue-400'>.</span>
              </p>
              <SiteFooterSocialLinks className='flex space-x-4' />
            </div>
            <div className='flex-shrink-0 mt-0 md:mt-6'>
              <p className='text-xl sm:text-2xl font-bold mb-4'>
                Get the app today.
              </p>
              <AppleAppStoreButton theme='light' />
            </div>
          </div>
        </div>
      </BaseContainer>
      <div className='bg-[#405985] py-[30px]'>
        <BaseContainer size='lg'>
          <p className='text-xs sm:text-sm leading-5'>
            Legal Utopia, Legal Utopia - The A.I Way and LegalCrowd are the
            trademarks and trading names of Legal Utopia Limited, a company
            registered in England and Wales under company number 10909418
            operating from and registered address Legal Utopia Limited 86-90
            Paul Street London EC2A 4NE. (C) Legal Utopia Limited 2019-2022.
            Legal Utopia is patent-pending in the United Kingdom, United States
            of America and Singapore. All Rights Reserved. Google Play and the
            Google Play logo are trademarks of Google LLC. iPhone and Apple are
            trademarks of Apple Inc., registered in the U.S. and other
            countries. Apple App Store logos are trademarks of Apple Inc.,
            registered in the U.S. and other countries. Legal Utopia Limited is
            registered for VAT under registration number: GB 404 391 911.
          </p>
        </BaseContainer>
      </div>
    </footer>
  )
}

export default SiteFooter
