import locationPin from '../../assets/location-pin.svg';
import { ChangeEvent } from "react";
import { InputGroup } from "common/components/Inputs/InputGroup";

type LocationInputProps = {
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

export const LocationInput = ({
  onChange,
  value,
}: LocationInputProps) => (
  <InputGroup
    label="Location"
    imgBefore={locationPin}
    className="lg:w-full"
    autoComplete="off"
    onChange={onChange}
    value={value}
    type="text"
    name="location"
    id="location"
    placeholder="Location"
  />
);
