import {  FaHeart, FaRegHeart } from "react-icons/fa";
import React, { FC, MouseEventHandler, useState} from "react";
import {
  AiOutlineUp,
  AiOutlineDown,
  AiFillInfoCircle,
  AiOutlineEllipsis,
} from 'react-icons/ai'

import {
  InfoItem,
  AvatarImage,
  ContactPopup,
  BookNowSection,
  ProfileCardBase,
  ContactBtnsSection,
  ExternalReviewLink,
  SharePopup,
  InfoPopup,
  MenuItem,
} from '../components'
import Img from '../../base/Img'
import '../../../styles/style.css'
import Project from 'common/project'
import Utils from 'common/utils/utils'
import Stepper from 'components/base/Stepper'
import map_icon from 'assets/FAL/map_icon.png'
import ProfileRating from '../../ProfileRating'
import { getCardTheme } from 'utils/StylesUtil'
import { LawfirmMember } from './LawfirmMember'
import { LawfirmMembers } from './LawfirmMembers'
import { LawfirmDetail } from './LawfirmDetailSection'
import { LFProfileDetail } from './LawfirmProfileBase'
import { MembershipType, OfficeSummary } from 'common/state-type'
import useAreasOfPractice from 'common/providers/useAreasOfPractice'

type LawfrimProfileCardType = {
  onMouseEnter: MouseEventHandler
  data: OfficeSummary
  longitude: any
  latitude: any
  areas?: string[]
  onFavPress?: () => void
  onBookNow: () => void
  cardStyle?: string
  className?: string
}

const LawfrimProfileCard: FC<LawfrimProfileCardType> = ({
  data,
  longitude,
  latitude,
  areas,
  onMouseEnter,
  onFavPress,
  onBookNow,
  cardStyle,
  className,
}) => {
  const { areasOfPractice } = useAreasOfPractice()
  const [expandedView, setExpandedView] = useState(false)
  const [activeLawyerId, setActiveLawyerId] = useState(null)
  const [lawyersDetail, setLawyersDetail] = useState(null)

  const aop =
    data.organisation.areas_of_practice &&
    areasOfPractice &&
    areasOfPractice.filter((e) =>
      data?.organisation.areas_of_practice.find((area) => area.id === e.id),
    )
  const operatingAreas = aop?.map((item) => item.name)

  const handleShowMore = () => {
    setExpandedView(!expandedView)
  }

  const membershipType: MembershipType = data.organisation.membership_type
  const themeColor = getCardTheme(membershipType)

  const handleStepChange = (page) => {
    if (page > 1) {
      const lawyerCount = page - 2
      setActiveLawyerId(data.organisation.lawyers[lawyerCount].id)
    } else {
      setActiveLawyerId(null)
    }
  }

  const renderStepperView = () => {
    const hasLawyers = !!data.organisation.lawyers.length
    const allLawers = data.organisation.lawyers.map((lawyer) => {
      return (
        <LawfirmMember
          member={lawyer}
          key={lawyer.id}
          fetchLawyerData={(data) => {
            setLawyersDetail({ ...lawyersDetail, [data.id]: data })
          }}
        />
      )
    })

    const steps = [<LawfirmDetail data={data} />]
    if (hasLawyers) {
      steps.push(<LawfirmMembers members={data.organisation.lawyers} />)
      steps.push(...allLawers)
    }

    return (
      <Stepper
        steps={steps}
        themeColor={themeColor}
        getCurrentStep={handleStepChange}
        renderPopupMenu={
          <InfoPopup
            themeColor={themeColor}
            triggerrer={<AiOutlineEllipsis color='#fff' size={20} />}
            position='right bottom'
            on='hover'
            closeOnDocumentClick
            mouseLeaveDelay={300}
            mouseEnterDelay={0}
            arrow
            nested
            MenuItems={[
              <MenuItem
                key='1stItem'
                Icon={data.favourite_id ? FaHeart : FaRegHeart}
                label={
                  data.favourite_id ? 'Remove from Favourite' : 'Add Favorites'
                }
                onClick={onFavPress}
              />,
              <SharePopup
                key='2ndItem'
                shareLink={`${Project.cwp}lawfirms/${data.id}`}
              />,
              <ContactPopup
                key='3rdItem'
                themeColor={themeColor}
                contactNumber={data.phone_number}
                email={data.email_address}
                website={data.website_url}
              />,
            ]}
          >
            <></>
          </InfoPopup>
        }
      />
    )
  }

  const activeLawyer =
    activeLawyerId && lawyersDetail?.[activeLawyerId]
      ? lawyersDetail[activeLawyerId]
      : null

  const renderRightInfoSection = () => {
    if (activeLawyer) {
      const lawyer = activeLawyer
      const memberships = lawyer.memberships.map((m) => m.name).join(', ')
      const qualifications = lawyer.qualifications.map((q) => q.name).join(', ')
      return (
        <>
          <InfoItem
            title='SRA AUTHORISED'
            content={lawyer.sra_authorised ? 'Yes' : 'No'}
          />
          {lawyer.sra_number && (
            <InfoItem title='SRA NUMBER' content={lawyer.sra_number} />
          )}
          {!!qualifications.length && (
            <InfoItem
              title='ACADEMIC QUALIFICATIONS'
              content={qualifications}
            />
          )}
          {!!memberships.length && (
            <InfoItem title='MEMBERSHIPS' content={memberships} />
          )}
        </>
      )
    } else {
      return (
        <>
          <InfoItem
            title='SRA Organisation ID'
            content={data.organisation.sra_organisation_id}
          />
          <InfoItem title='SRA Number' content={data.organisation.sra_number} />
          <InfoItem
            title='Company Number'
            content={data.organisation.company_registration_number}
          />
          <InfoItem
            title='Company Structure'
            content={data.organisation.company_structure}
          />
          <InfoItem
            title='Regulatory Body Type'
            content={data.organisation.regulatory_body_type}
          />
          <InfoItem
            title='Auth Date'
            content={data.organisation.authorisation_date}
          />
        </>
      )
    }
  }

  const renderProfileDetail = () => {
    let lawyer
    if (activeLawyer) {
      const lawyerAop =
        activeLawyer.areas_of_practice &&
        areasOfPractice &&
        areasOfPractice.filter((e) =>
          activeLawyer.areas_of_practice.find((area) => area === e.id),
        )
      const LawyeroperatingAreas = lawyerAop?.map((item) => item.name)
      lawyer = {
        name: activeLawyer.user.first_name + ' ' + activeLawyer.user.last_name,
        experience: activeLawyer.number_of_practice_years,
        operatingAreas: LawyeroperatingAreas,
        jobTitle: activeLawyer.job_title,
      }
    }

    return (
      <LFProfileDetail
        name={data.name}
        areas={areas}
        isLegalAid={data.legal_aid_practitioner}
        subcriptionType={data.organisation.membership_type}
        approvalStatus={!!data.government_contracted_areas_of_practice.length}
        operatingAreas={operatingAreas}
        themeColor={themeColor}
        lawyerInfo={lawyer}
      />
    )
  }

  return (
    <div
      id={`profile-${data.id}`}
      onMouseEnter={onMouseEnter}
      className={className}
    >
      <ProfileCardBase membershipType={membershipType} className={cardStyle}>
        <div className='flex flex-col w-full'>
          <div className='flex flex-col flex-1 w-full'>
            <div className='flex flex-row'>
              <div className='flex flex-col text-black w-[23.87%] place-content-between items-center'>
                <AvatarImage
                  imgSource={data.organisation.logo}
                  nameInitials={data.organisation.practice_name_initials}
                />
                <ContactBtnsSection
                  phone={data.phone_number}
                  email={data.email_address}
                  website={data.website_url}
                  themeColor={themeColor}
                />
              </div>
              {renderProfileDetail()}
            </div>
            {membershipType !== MembershipType.NONE && (
              <div className='flex flex-1 flex-col'>
                <button
                  className=' place-self-center flex flex-row'
                  onClick={handleShowMore}
                >
                  {!expandedView && (
                    <p className='text-center text-gray-500 text-sm pr-2'>
                      show more ...
                    </p>
                  )}
                  {expandedView ? (
                    <AiOutlineUp size={20} color='#000000' />
                  ) : (
                    <AiOutlineDown size={20} color='#000000' />
                  )}
                </button>
                {expandedView ? renderStepperView() : null}
              </div>
            )}
          </div>
        </div>
        {membershipType !== MembershipType.NONE && (
          <div className='flex flex-col place-content-between w-[31%]'>
            <div>
              <div className='flex flex-row place-content-between pb-6'>
                <div className='flex flex-col items-center pl-4 flex-1'>
                  <div className='flex flex-row items-center mr-1 text-black mt-4'>
                    <Img imgSrc={map_icon} />
                    <span className='pl-2 text-sm capitalize'>
                      {Utils.getDistance(
                        latitude,
                        longitude,
                        data.latitude,
                        data.longitude,
                      )}{' '}
                      Miles
                    </span>
                  </div>
                </div>
                <button
                  onClick={onFavPress}
                  className={`flex items-center justify-center h-8 w-8 rounded-full ${themeColor.class}`}
                >
                  {data.favourite_id ? (
                    <FaRegHeart color='#fff' size={20} />
                  ) : (
                    <FaHeart color='#fff' size={20} />
                  )}
                </button>
              </div>
              {data.organisation.google_reviews_url && (
                <ExternalReviewLink
                  label='See our Google reviews'
                  isGoogle
                  link={data.organisation.google_reviews_url}
                />
              )}

              {data.organisation.trustpilot_reviews_url && (
                <ExternalReviewLink
                  label='See our TrustPilot Reviews'
                  isTrustpilot
                  link={data.organisation.trustpilot_reviews_url}
                />
              )}

              <button onClick={() => null}>
                {/* Add real data */}
                <ProfileRating
                  rating={
                    // @ts-ignore
                    data.average_rating
                  }
                />
              </button>
              {expandedView && (
                <>
                  {renderRightInfoSection()}
                  <InfoItem
                    title='Regional Cost'
                    content={Utils.formatMoneyFromPence(
                      data.average_regional_cost_pence,
                    )}
                    rightElement={
                      <InfoPopup
                        themeColor={themeColor}
                        className='max-w-md'
                        triggerrer={
                          <AiFillInfoCircle color={themeColor.hex} size={16} />
                        }
                        title='Regional Cost'
                        description="The median legal bill by region is based on office address and Legal Services Board's (2019-2020) data"
                        position='right bottom'
                      >
                        <></>
                      </InfoPopup>
                    }
                  />
                </>
              )}
            </div>
            <BookNowSection
              btnLabel='Book Now'
              onClick={onBookNow}
              title={
                data.organisation?.fee_transparency_policy_url ? (
                  <a
                    target={'_blank'}
                    href={data.organisation.fee_transparency_policy_url}
                  >
                    Transparency Pricing
                  </a>
                ) : null
              }
              themeColor={themeColor}
              popupTitle='*Price information*'
              popupDescription={`This is limited mandatory pricing information regulated lawyers have to disclose to you`}
              titleClass={'text-sm underline font-bold'}
            />
          </div>
        )}
      </ProfileCardBase>
    </div>
  )
}

export default LawfrimProfileCard;
