export default function () {
    return (
      <div dangerouslySetInnerHTML={{__html:`<div class="container content-page mx-4">
  <div>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        Legal Utopia understands its responsibilities with
        respect to marketing activities and communications
        with you and that you only want Legal Utopia to
        contact you when you have said you want to be
        contacted. We respect and value the privacy of
        everyone who visits Our website, legalutopia.co.uk,
        (“
      </span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        Our Site
      </span>
      <span style="font-family: 'Century Gothic'">
        ”) and everyone who accesses Our mobile application
        available from the Apple App Store and Google Play
        Store (collectively “
      </span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        Our Apps
      </span>
      <span style="font-family: 'Century Gothic'">”).</span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        We only collect and use personal data for the
        marketing purposes and activities that are described
        here, and in a way that is consistent with Our
        obligations and your rights under the law. Unless an
        applicable supplement policy expressly applies to a
        specific service or function.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        Please read this Marketing Policy and ensure that
        you understand it. Your acceptance of this Marketing
        Policy is requested at the time of creating an
        Account on Our Apps, joining Our newsletter or
        contacting Us from Our Site
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        class="normal0"
        style="
          margin-left: 31.25pt;
          text-align: justify;
          padding-left: 4.75pt;
          font-family: 'Century Gothic';
        "
      >
        <span>Definitions and Interpretation</span>
      </li>
    </ol>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        In this Policy the following terms shall have the
        following meanings:
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">“</span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        Account
      </span>
      <span style="font-family: 'Century Gothic'">
        ” means an account required to access and/or use
        certain areas and features of Our Site and Apps;
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">“</span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        Cookies Law
      </span>
      <span style="font-family: 'Century Gothic'">
        ” means the relevant parts of the Privacy and
        Electronic Communications (EC Directive) Regulations
        2003;
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">“</span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        Consent
      </span>
      <span style="font-family: 'Century Gothic'">
        ” means the consent of the data subject which is
        freely given and informed indication of the data
        subject’s wishes by which they, by a statement or by
        a clear affirmative action, signify their agreement
        to the processing of personal data relating to them;
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">“</span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        Data Controller
      </span>
      <span style="font-family: 'Century Gothic'">
        ” means the natural or legal personal or
        organisation which, alone or jointly with others,
        determines the purposes and means of the processing
        of personal data. For the purposes of this Policy,
        the Company is the data controller of all personal
        data relating to any data subject used in Our
        business for Our commercial purposes;
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">“</span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        Data Processor
      </span>
      <span style="font-family: 'Century Gothic'">
        ” means a natural or legal person or organisation
        which processes personal data on behalf of a data
        controller;
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">“</span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        Data Protection Legislation
      </span>
      <span style="font-family: 'Century Gothic'">
        ” means the Data Protection Act 1998 and Data
        Protection Act 2018 together with any other
        statutory instrument of the UK Parliament;
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">“</span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        Data Subject
      </span>
      <span style="font-family: 'Century Gothic'">
        ” means a living, identified, or identifiable
        natural personal about whom we hold personal data;
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">“</span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        EEA
      </span>
      <span style="font-family: 'Century Gothic'">
        ” means Western Europe, the European Union, EU
        member states, or counties in the European Economic
        Area;
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">“</span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        Information
      </span>
      <span style="font-family: 'Century Gothic'">
        ” means any data generated from Our Site or Apps
        that is not otherwise personal data;
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">“</span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        UK
      </span>
      <span style="font-family: 'Century Gothic'">
        ” means the jurisdiction of the United Kingdom of
        Great Britain and Northern Ireland;
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">“</span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        Products and Services
      </span>
      <span style="font-family: 'Century Gothic'">
        ” means any product or service provided by Us as
        identified on Our Site and Apps.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <ol
      start="2"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-left: 31.25pt;
          text-align: justify;
          padding-left: 4.75pt;
          font-family: 'Century Gothic';
        "
      >
        <span>Information About Us</span>
      </li>
    </ol>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        Our Site and Apps are owned and operated by Legal
        Utopia Limited (referred to as “Our”, “We”, “Us”,
        “Legal Utopia”), a limited company by shares,
        registered in England under company number 10909418.
        Registered address: 3rd Floor. 86-90 Paul Street,
        London, EC2A 4NE.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        Data Protection Officer: Fraser Matcham
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        Email address:
      </span>
      <a
        href="mailto:fraser@legalutopia.co.uk"
        style="text-decoration: none"
      >
        <span
          style="
            font-family: 'Century Gothic';
            text-decoration: underline;
            color: #1155cc;
          "
        >
          fraser@legalutopia.co.uk
        </span>
      </a>
      <span style="font-family: 'Century Gothic'"></span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        Compliance Officer: Robert Marcus
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        Email address:
      </span>
      <a
        href="mailto:robert@legalutopia.co.uk"
        style="text-decoration: none"
      >
        <span
          style="
            font-family: 'Century Gothic';
            text-decoration: underline;
            color: #1155cc;
          "
        >
          robert@legalutopia.co.uk
        </span>
      </a>
      <span style="font-family: 'Century Gothic'"></span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 36pt;
        text-indent: -18pt;
        text-align: justify;
        -aw-import: list-item;
        -aw-list-level-number: 0;
        -aw-list-number-format: '●';
        -aw-list-number-styles: 'bullet';
        -aw-list-padding-sml: 11.36pt;
      "
    >
      <span style="-aw-import: ignore">
        <span style="font-family: 'Century Gothic'">
          <span>●</span>
        </span>
        <span
          style="
            width: 11.36pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
            -aw-import: spaces;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        We are registered with the Information
        Commissioner’s Office under reference number:
      </span>
      <span
        style="
          font-family: 'Century Gothic';
          font-weight: bold;
        "
      >
        ZA324233
      </span>
      <span style="font-family: 'Century Gothic'">.</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 36pt;
        text-indent: -18pt;
        text-align: justify;
        -aw-import: list-item;
        -aw-list-level-number: 0;
        -aw-list-number-format: '●';
        -aw-list-number-styles: 'bullet';
        -aw-list-padding-sml: 11.36pt;
      "
    >
      <span style="-aw-import: ignore">
        <span style="font-family: 'Century Gothic'">
          <span>●</span>
        </span>
        <span
          style="
            width: 11.36pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
            -aw-import: spaces;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        You can search the public register of data
        controllers here:
      </span>
      <span style="font-family: 'Century Gothic'"> </span>
      <a
        href="https://ico.org.uk/about-the-ico/what-we-do/register-of-fee-payers/"
        style="text-decoration: none"
      >
        <span
          style="
            font-family: 'Century Gothic';
            text-decoration: underline;
            color: #000000;
          "
        >
          https://ico.org.uk/about-the-ico/what-we-do/register-of-fee-payers/
        </span>
      </a>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 36pt;
        text-indent: -18pt;
        text-align: justify;
        -aw-import: list-item;
        -aw-list-level-number: 0;
        -aw-list-number-format: '●';
        -aw-list-number-styles: 'bullet';
        -aw-list-padding-sml: 11.36pt;
      "
    >
      <span style="-aw-import: ignore">
        <span style="font-family: 'Century Gothic'">
          <span>●</span>
        </span>
        <span
          style="
            width: 11.36pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
            -aw-import: spaces;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        You can make a complaint to the Information
        Commissioner’s Officer here:
      </span>
      <span style="font-family: 'Century Gothic'"> </span>
      <a
        href="https://ico.org.uk/about-the-ico/what-we-do/register-of-fee-payers/"
        style="text-decoration: none"
      >
        <span
          style="
            font-family: 'Century Gothic';
            text-decoration: underline;
            color: #000000;
          "
        >
          https://ico.org.uk/about-the-ico/what-we-do/register-of-fee-payers/
        </span>
      </a>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 36pt;
        text-indent: -18pt;
        text-align: justify;
        -aw-import: list-item;
        -aw-list-level-number: 0;
        -aw-list-number-format: '●';
        -aw-list-number-styles: 'bullet';
        -aw-list-padding-sml: 11.36pt;
      "
    >
      <span style="-aw-import: ignore">
        <span style="font-family: 'Century Gothic'">
          <span>●</span>
        </span>
        <span
          style="
            width: 11.36pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
            -aw-import: spaces;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        You can use Our Apps to make a Subject Access
        Request in the ‘Help’ tab.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <ol
      start="3"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-left: 31.25pt;
          text-align: justify;
          padding-left: 4.75pt;
          font-family: 'Century Gothic';
        "
      >
        <span>What does this policy cover?</span>
      </li>
    </ol>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        This Marketing Policy applies only to your use of
        Our Site and Apps. Our Site and Apps may contain
        links to other websites, portals, or similar online
        pages. Please note that We have no control over how
        your data is collected, stored, or used by other
        websites, portals, or similar online pages and We
        advise you to check the privacy policies of any such
        websites, portals, or similar online pages before
        providing any data to them.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <ol
      start="4"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-left: 31.25pt;
          text-align: justify;
          padding-left: 4.75pt;
          font-family: 'Century Gothic';
        "
      >
        <span>
          What is marketing communications and activities?
        </span>
      </li>
    </ol>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        This Marketing Policy applies only to Our use of
        your personal data and other forms of information to
        carry out communications and activities that promote
        the services We provide to you and others. This may
        include the collection of usage or diagnostic
        information surrounding Our Apps, Site, Products and
        Services to assist Us in understanding how to
        approach and apply Our marketing communications and
        activities within the business.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <ol
      start="5"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-left: 31.25pt;
          text-align: justify;
          padding-left: 4.75pt;
          font-family: 'Century Gothic';
        "
      >
        <span>What are my rights?</span>
      </li>
    </ol>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        It may be the case that some of the data or
        information that We use for marketing communications
        and activities is personal data. This does not mean
        all data or information used is personal data. In
        the cases where it is, your rights under Clause 5 of
        Our Privacy Policy shall apply.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        Use of your personal data and information for the
        purposes of marketing communications and activities
        can be restricted under the Data Protection
        Legislation and Cookies Law. This specifically
        concerns electronic marketing including by phone,
        fax, email, text or any other type of electronic
        mail where the data subject has not otherwise
        specifically requested marketing materials.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 36pt;
        text-indent: -18pt;
        text-align: justify;
        -aw-import: list-item;
        -aw-list-level-number: 0;
        -aw-list-number-format: '●';
        -aw-list-number-styles: 'bullet';
        -aw-list-padding-sml: 11.36pt;
      "
    >
      <span style="-aw-import: ignore">
        <span style="font-family: 'Century Gothic'">
          <span>●</span>
        </span>
        <span
          style="
            width: 11.36pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
            -aw-import: spaces;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        It will generally apply that you have the right not
        to receive unsolicited direct marketing
        communications from Us unless We have your consent.
        This consent can be expressed or implied consent, as
        well as indirect consent to a third party in certain
        circumstances.
      </span>
    </p>
    <p
      class="normal0"
      style="margin-left: 36pt; text-align: justify"
    >
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 36pt;
        text-indent: -18pt;
        text-align: justify;
        -aw-import: list-item;
        -aw-list-level-number: 0;
        -aw-list-number-format: '●';
        -aw-list-number-styles: 'bullet';
        -aw-list-padding-sml: 11.36pt;
      "
    >
      <span style="-aw-import: ignore">
        <span style="font-family: 'Century Gothic'">
          <span>●</span>
        </span>
        <span
          style="
            width: 11.36pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
            -aw-import: spaces;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        It will apply that you have the right to withdraw
        consent at any time to receiving marketing
        communications and activities.
      </span>
    </p>
    <p
      class="normal0"
      style="margin-left: 36pt; text-align: justify"
    >
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 36pt;
        text-indent: -18pt;
        text-align: justify;
        -aw-import: list-item;
        -aw-list-level-number: 0;
        -aw-list-number-format: '●';
        -aw-list-number-styles: 'bullet';
        -aw-list-padding-sml: 11.36pt;
      "
    >
      <span style="-aw-import: ignore">
        <span style="font-family: 'Century Gothic'">
          <span>●</span>
        </span>
        <span
          style="
            width: 11.36pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
            -aw-import: spaces;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        It will apply that if there are any significant
        changes to the way We conduct marketing
        communications or activities, your consent to them
        may become invalid and We must seek your consent or
        confirmation to continue with those communications
        or activities.
      </span>
    </p>
    <p
      class="normal0"
      style="margin-left: 36pt; text-align: justify"
    >
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 36pt;
        text-indent: -18pt;
        text-align: justify;
        -aw-import: list-item;
        -aw-list-level-number: 0;
        -aw-list-number-format: '●';
        -aw-list-number-styles: 'bullet';
        -aw-list-padding-sml: 11.36pt;
      "
    >
      <span style="-aw-import: ignore">
        <span style="font-family: 'Century Gothic'">
          <span>●</span>
        </span>
        <span
          style="
            width: 11.36pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
            -aw-import: spaces;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        It will apply that when receiving email marketing
        communications from Us, there must be valid contact
        information to opt out or unsubscribe from said
        email marketing communications.
      </span>
    </p>
    <p
      class="normal0"
      style="margin-left: 36pt; text-align: justify"
    >
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 36pt;
        text-indent: -18pt;
        text-align: justify;
        -aw-import: list-item;
        -aw-list-level-number: 0;
        -aw-list-number-format: '●';
        -aw-list-number-styles: 'bullet';
        -aw-list-padding-sml: 11.36pt;
      "
    >
      <span style="-aw-import: ignore">
        <span style="font-family: 'Century Gothic'">
          <span>●</span>
        </span>
        <span
          style="
            width: 11.36pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
            -aw-import: spaces;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        It will apply that on request to opt-out or stop
        receiving marketing communications or activities,
        your personal data and information will be
        suppressed. This means that said information may be
        stored but not used for marketing communications or
        activities. You may request it be deleted under Our
        Privacy Policy.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        Please note that requests to restrict the processing
        of personal data for marketing purposes does not
        mean your personal data is deleted but rather
        ‘suppressed’. This means it is excluded from that
        particular type of processing, whilst it may be used
        elsewhere, for example, to maintain your account
        with us. Such a request may take up to 30-days to
        become fully effective.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <ol
      start="6"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-left: 31.25pt;
          text-align: justify;
          padding-left: 4.75pt;
          font-family: 'Century Gothic';
        "
      >
        <span>How do you share my data?</span>
      </li>
    </ol>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        We aim to minimise the use of third party providers
        in seeking to conduct Our marketing communications
        and activities, however, this is not always
        possible. We provide a table to identify the
        controllers and processors of your personal data and
        information in Clause 10 of the Privacy Policy.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <ol
      start="7"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-left: 31.25pt;
          text-align: justify;
          padding-left: 4.75pt;
          font-family: 'Century Gothic';
        "
      >
        <span>How can I control the use of my data?</span>
      </li>
    </ol>
    <p
      class="normal0"
      style="margin-left: 36pt; text-align: justify"
    >
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        We provide you with strong controls on how your
        personal data is used. We also provide you with a
        built-in tracking usage management control setting
        within Our Apps to enable you to communicate with Us
        any changes you wish to make to Our use of that data
        and information. This is in addition to the ability
        to opt-out of receiving emails from Us which you may
        do by unsubscribing using the links provided in Our
        emails and, at the point of providing your details,
        by managing your Account with Us.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        You may also wish to sign up to one or more of the
        preference services operating in the UK: The
        Telephone Preference Service (“the TPS”), the
        Corporate Telephone Preference Service (“the CTPS”),
        and the Mailing Preference Service (“the MPS”).
        These may help to prevent you receiving unsolicited
        marketing. Please note, however, that these services
        will not prevent you from receiving marketing
        communications that you have consented to receiving.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        You may also contact Our Data Protection Officer,
        who will be able to review any specific requests and
        seek to enable you to exercise your rights and
        controls over your personal data.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <ol
      start="8"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-left: 31.25pt;
          text-align: justify;
          padding-left: 4.75pt;
          font-family: 'Century Gothic';
        "
      >
        <span>
          How do you use my personal data and information?
        </span>
      </li>
    </ol>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        We may use your personal data and/or information,
        for the purposes of generating aggregate reports,
        insights, or analytics to understand how and when
        Our products and services are used, as well as other
        such informative information about Our Sites and
        Apps. We may use this information to inform the
        business decisions related to the marketing
        communications and activities We choose to
        undertake, as well as improving the products and
        services, and conducting market research.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        In addition to informing Us, We may use the personal
        data and information to send you direct emails
        marketing Our products and services, as well as
        information that may be of interest to you in
        relation to Us. Some of this information may be
        promotional. We may also use the personal data and
        information for the purposes of targeted advertising
        or to present you with advertisements or promotional
        materials in relation to Our products and services,
        as well as Our Site and Apps.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        To undertake these activities, We may seek to use
        third party providers to collect, process, store,
        retain, display, maintain, or otherwise use your
        personal data and information. These third parties
        are identified under Clause 6 above.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <ol
      start="9"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-left: 31.25pt;
          text-align: justify;
          padding-left: 4.75pt;
          font-family: 'Century Gothic';
        "
      >
        <span>How do I contact you?</span>
      </li>
    </ol>
    <p
      class="normal0"
      style="margin-left: 36pt; text-align: justify"
    >
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        To contact Us about anything to do with your
        personal data and data protection, including
        requests under this Marketing Policy, please use the
        following details (for the attention of Data
        Protection Officer):
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 36pt;
        text-indent: -18pt;
        text-align: justify;
        -aw-import: list-item;
        -aw-list-level-number: 0;
        -aw-list-number-format: '●';
        -aw-list-number-styles: 'bullet';
        -aw-list-padding-sml: 11.36pt;
      "
    >
      <span style="-aw-import: ignore">
        <span style="font-family: 'Century Gothic'">
          <span>●</span>
        </span>
        <span
          style="
            width: 11.36pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
            -aw-import: spaces;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Email address:
      </span>
      <a
        href="mailto:fraser@legalutopia.co.uk"
        style="text-decoration: none"
      >
        <span
          style="
            font-family: 'Century Gothic';
            text-decoration: underline;
            color: #1155cc;
          "
        >
          fraser@legalutopia.co.uk
        </span>
      </a>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 36pt;
        text-indent: -18pt;
        text-align: justify;
        -aw-import: list-item;
        -aw-list-level-number: 0;
        -aw-list-number-format: '●';
        -aw-list-number-styles: 'bullet';
        -aw-list-padding-sml: 11.36pt;
      "
    >
      <span style="-aw-import: ignore">
        <span style="font-family: 'Century Gothic'">
          <span>●</span>
        </span>
        <span
          style="
            width: 11.36pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
            -aw-import: spaces;
          "
        >
                 
        </span>
      </span>
      <span style="font-family: 'Century Gothic'">
        Postal address: 3rd Floor, 86-90 Paul Street,
        London, EC2A 4NE
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <ol
      start="10"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-left: 36pt;
          text-align: justify;
          font-family: 'Century Gothic';
        "
      >
        <span>Changes to this Marketing Policy</span>
      </li>
    </ol>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        We may change this Marketing Policy from time to
        time to reflect any changes to Our marketing
        communications and activities. This may be
        necessary, for example, if the law changes, or if We
        change Our business in a way that affects your
        personal data protection or the lawful basis in
        which We may rely on.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        Any changes will be immediately posted on Our Sites
        and Apps and you will be deemed to have accepted the
        terms of the Marketing Policy on your further
        acceptance and consent following Our communication
        to you of the alterations. We recommend that you
        check this page regularly to keep up to date.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span
        style="
          font-family: 'Century Gothic';
          -aw-import: ignore;
        "
      >
         
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: 'Century Gothic'">
        This Marketing Policy was last updated on 26th July
        2022.
      </span>
    </p>
  </div>
</div>
`}}/>
    )
}
