export default function () {
    return (
        <div dangerouslySetInnerHTML={{
            __html: `<div class="container content-page mx-4">
  <div>
    <p
      class="normal0"
      style="text-align: left; line-height: 115%"
    >
      <span> </span>
    </p>
    <table
      cellspacing="0"
      cellpadding="0"
      class="Table1"
      style="
        width: 465.9pt;
        margin-left: 6.8pt;
        border: 0.75pt solid #000000;
        -aw-border-insideh: 0.75pt single #000000;
        -aw-border-insidev: 0.75pt single #000000;
        border-collapse: collapse;
      "
    >
      <tbody>
        <tr>
          <td style="padding: 6.42pt; vertical-align: top">
            <p
              class="normal0"
              style="
                text-align: center;
                line-height: normal;
                font-size: 14pt;
              "
            >
              <span>Legal Utopia Limited</span>
            </p>
            <p
              class="normal0"
              style="
                text-align: center;
                line-height: normal;
                font-size: 14pt;
              "
            >
              <span>Data Protection Policy</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 31.62pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
          padding-left: 3.83pt;
        "
      >
        <span style="font-weight: bold">Introduction</span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>
        This Policy sets out the obligations of Legal Utopia
        Limited, a company registered in England and Wales
        under number 10909418, whose registered office is at
        3rd Floor, 86-90 Paul Street, London, EC2A 4NE (“the
        Company”) regarding data protection and the rights
        of its employees (in this context, “employee data
        subjects”) in respect of their personal data under
        Data Protection Law. “Data Protection Law” means all
        legislation and regulations in force from time to
        time regulating the use of personal data and the
        privacy of electronic communications including, but
        not limited to, the retained EU law version of the
        General Data Protection Regulation ((EU) 2016/679)
        (the “UK GDPR”), as it forms part of the law of
        England and Wales, Scotland, and Northern Ireland by
        virtue of section 3 of the European Union
        (Withdrawal) Act 2018, the Data Protection Act 2018,
        the Privacy and Electronic Communications
        Regulations 2003 as amended, and any successor
        legislation.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>
        This Policy sets out the Company’s obligations
        regarding the collection, processing, transfer,
        storage, and disposal of personal data relating to
        employee data subjects. The procedures and
        principles set out herein must be followed at all
        times by the Company, its employees, agents,
        contractors, and other parties working on behalf of
        the Company.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="2"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 31.62pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
          padding-left: 3.83pt;
        "
      >
        <span style="font-weight: bold">Definitions</span>
      </li>
    </ol>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <table
      cellspacing="0"
      cellpadding="0"
      class="Table2"
      style="
        width: 429.95pt;
        margin-left: 40.85pt;
        border: 1pt solid #000000;
        -aw-border-insideh: 1pt single #000000;
        -aw-border-insidev: 1pt single #000000;
        border-collapse: collapse;
      "
    >
      <tbody>
        <tr>
          <td
            style="
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                text-align: left;
                line-height: normal;
              "
            >
              <span style="font-weight: bold">
                “consent”
              </span>
            </p>
          </td>
          <td
            style="
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                line-height: normal;
              "
            >
              <span>
                means the consent of the data subject which
                must be a freely given, specific, informed,
                and unambiguous indication of the data
                subject’s wishes by which they, by a
                statement or by a clear affirmative action,
                signify their agreement to the processing of
                personal data relating to them;
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                text-align: left;
                line-height: normal;
              "
            >
              <span style="font-weight: bold">
                “data controller”
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                line-height: normal;
              "
            >
              <span>
                means the natural or legal person or
                organisation which, alone or jointly with
                others, determines the purposes and means of
                the processing of personal data. For the
                purposes of this Policy, the Company is the
                data controller of all personal data
                relating to employee data subjects;
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                text-align: left;
                line-height: normal;
              "
            >
              <span style="font-weight: bold">
                “data processor”
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                line-height: normal;
              "
            >
              <span>
                means a natural or legal person or
                organisation which processes personal data
                on behalf of a data controller;
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                text-align: left;
                line-height: normal;
              "
            >
              <span style="font-weight: bold">
                “data subject”
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                line-height: normal;
              "
            >
              <span>
                means a living, identified, or identifiable
                natural person about whom the Company holds
                personal data (in this context, employee
                data subjects);
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                text-align: left;
                line-height: normal;
              "
            >
              <span style="font-weight: bold">“EEA”</span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                line-height: normal;
              "
            >
              <span>
                means the European Economic Area, consisting
                of all EU Member States, Iceland,
                Liechtenstein, and Norway;
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                text-align: left;
                line-height: normal;
              "
            >
              <span style="font-weight: bold">
                “personal data”
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                line-height: normal;
              "
            >
              <span>
                means any information relating to a data
                subject who can be identified, directly or
                indirectly, in particular by reference to an
                identifier such as a name, identification
                number, location data, an online identifier,
                or to one or more factors specific to the
                physical, physiological, genetic, mental,
                economic, cultural, or social identity of
                that data subject;
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                text-align: left;
                line-height: normal;
              "
            >
              <span style="font-weight: bold">
                “personal data breach”
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                line-height: normal;
              "
            >
              <span>
                means a breach of security leading to the
                accidental or unlawful destruction, loss,
                alteration, unauthorised disclosure of, or
                access to, personal data transmitted,
                stored, or otherwise processed;
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                text-align: left;
                line-height: normal;
              "
            >
              <span style="font-weight: bold">
                “processing”
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                line-height: normal;
              "
            >
              <span>
                means any operation or set of operations
                performed on personal data or sets of
                personal data, whether or not by automated
                means, such as collection, recording,
                organisation, structuring, storage,
                adaptation or alteration, retrieval,
                consultation, use, disclosure by
                transmission, dissemination or otherwise
                making available, alignment or combination,
                restriction, erasure or destruction;
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                text-align: left;
                line-height: normal;
              "
            >
              <span style="font-weight: bold">
                “pseudonymisation”
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                line-height: normal;
              "
            >
              <span>
                means the processing of personal data in
                such a manner that the personal data can no
                longer be attributed to a specific data
                subject without the use of additional
                information, provided that such additional
                information is kept separately and is
                subject to technical and organisational
                measures to ensure that the personal data is
                not attributed to an identified or
                identifiable natural person; and
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                text-align: left;
                line-height: normal;
              "
            >
              <span style="font-weight: bold">
                “special category personal data”
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="
                margin-bottom: 6pt;
                line-height: normal;
              "
            >
              <span>
                means personal data revealing racial or
                ethnic origin, political opinions, religious
                or philosophical beliefs, trade union
                membership, health, sexual life, sexual
                orientation, biometric, or genetic data.
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="3"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 31.62pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
          padding-left: 3.83pt;
        "
      >
        <span style="font-weight: bold">Scope</span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>3.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company is committed not only to the letter of
        the law, but also to the spirit of the law and
        places high importance on the correct, lawful, and
        fair handling of all personal data, respecting the
        legal rights, privacy, and trust of all individuals
        with whom it works. This policy applies both to the
        recruitment and consideration of data subjects for
        employment with the Company, as well as those under
        contract of employment with the Company only.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>3.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All executives are responsible for ensuring that all
        employees, agents, contractors, or other parties
        working on behalf of the Company comply with this
        Policy and, where applicable, must implement such
        practices, processes, controls, and training as are
        reasonably necessary to ensure such compliance.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>3.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Any questions relating to this Policy or to Data
        Protection Law should be referred to Fraser Matcham.
        In particular, an executive should always be
        consulted in the following cases:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        if there is any uncertainty relating to the lawful
        basis on which employee personal data is to be
        collected, held, and/or processed;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        if consent is being relied upon in order to collect,
        hold, and process employee personal data;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>c)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        if there is any uncertainty relating to the
        retention period for any particular type(s) of
        employee personal data;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>d)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        if any new or amended privacy notices or similar
        privacy-related documentation are required;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>e)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        if any assistance is required in dealing with the
        exercise of an employee data subject’s rights
        (including, but not limited to, the handling of
        subject access requests);
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>f)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        if a personal data breach (suspected or actual) has
        occurred;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>g)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        if there is any uncertainty relating to security
        measures (whether technical or organisational)
        required to protect employee personal data;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>h)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        if employee personal data is to be shared with third
        parties (whether such third parties are acting as
        data controllers or data processors);
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>i)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        if employee personal data is to be transferred
        outside of the UK and there are questions relating
        to the legal basis on which to do so;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>j)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        when any significant new processing activity is to
        be carried out, or significant changes are to be
        made to existing processing activities, which will
        require a Data Protection Impact Assessment;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>k)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        when employee personal data is to be used for
        purposes different to those for which it was
        originally collected;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>l)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        if any automated processing, including profiling or
        automated decision-making, is to be carried out; or
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>m)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        if any assistance is required in complying with the
        law applicable to direct marketing.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="4"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 31.62pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
          padding-left: 3.83pt;
        "
      >
        <span style="font-weight: bold">
          The Data Protection Principles
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>
        This Policy aims to ensure compliance with Data
        Protection Law. The UK GDPR sets out the following
        principles with which anyone handling personal data
        must comply. Data controllers are responsible for,
        and must be able to demonstrate, such compliance.
        All personal data must be:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>4.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        processed lawfully, fairly, and in a transparent
        manner in relation to the data subject;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>4.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        collected for specified, explicit, and legitimate
        purposes and not further processed in a manner that
        is incompatible with those purposes. Further
        processing for archiving purposes in the public
        interest, scientific or historical research purposes
        or statistical purposes shall not be considered to
        be incompatible with the initial purposes;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>4.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        adequate, relevant, and limited to what is necessary
        in relation to the purposes for which it is
        processed;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>4.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        accurate and, where necessary, kept up to date.
        Every reasonable step must be taken to ensure that
        personal data that is inaccurate, having regard to
        the purposes for which it is processed, is erased,
        or rectified without delay;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>4.5</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        kept in a form which permits identification of data
        subjects for no longer than is necessary for the
        purposes for which the personal data is processed.
        Personal data may be stored for longer periods
        insofar as the personal data will be processed
        solely for archiving purposes in the public
        interest, scientific or historical research
        purposes, or statistical purposes, subject to
        implementation of the appropriate technical and
        organisational measures required by the UK GDPR in
        order to safeguard the rights and freedoms of the
        data subject;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>4.6</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        processed in a manner that ensures appropriate
        security of the personal data, including protection
        against unauthorised or unlawful processing and
        against accidental loss, destruction, or damage,
        using appropriate technical or organisational
        measures.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="5"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 31.62pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
          padding-left: 3.83pt;
        "
      >
        <span style="font-weight: bold">
          The Rights of Data Subjects
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>
        The UK GDPR sets out the following key rights
        applicable to data subjects:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>5.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>the right to be informed;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>5.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>the right of access;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>5.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>the right to rectification;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>5.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the right to erasure (also known as the ‘right to be
        forgotten’);
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>5.5</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>the right to restrict processing;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>5.6</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>the right to data portability;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>5.7</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>the right to object; and</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>5.8</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        rights with respect to automated decision-making and
        profiling.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="6"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 31.62pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
          padding-left: 3.83pt;
        "
      >
        <span style="font-weight: bold">
          Lawful, Fair, and Transparent Data Processing
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>6.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Data Protection Law seeks to ensure that personal
        data is processed lawfully, fairly, and
        transparently, without adversely affecting the
        rights of the data subject. Specifically, the
        processing of personal data shall be lawful only if
        at least one of the following applies:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the data subject has given consent to the processing
        of their personal data for one or more specific
        purposes;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the processing is necessary for the performance of a
        contract to which the data subject is a party, or in
        order to take steps at the request of the data
        subject prior to entering into a contract;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>c)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the processing is necessary for compliance with a
        legal obligation to which the data controller is
        subject;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>d)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the processing is necessary to protect the vital
        interests of the data subject or of another natural
        person;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>e)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the processing is necessary for the performance of a
        task carried out in the public interest or in the
        exercise of official authority vested in the data
        controller; or
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>f)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the processing is necessary for the purposes of the
        legitimate interests pursued by the data controller
        or by a third party, except where such interests are
        overridden by the fundamental rights and freedoms of
        the data subject which require protection of
        personal data, in particular where the data subject
        is a child.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>6.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        If the personal data in question is special category
        personal data (also known as ‘sensitive personal
        data’), at least one of the following conditions
        must be met in addition to one of the conditions set
        out above:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the data subject has given their explicit consent to
        the processing of such data for one or more
        specified purposes (unless the law prohibits them
        from doing so);
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the processing is necessary for the purpose of
        carrying out the obligations and exercising specific
        rights of the data controller or of the data subject
        in the field of employment, social security, and
        social protection law (insofar as it is authorised
        by law or a collective agreement pursuant to law
        which provides for appropriate safeguards for the
        fundamental rights and interests of the data
        subject);
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>c)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the processing is necessary to protect the vital
        interests of the data subject or of another natural
        person where the data subject is physically or
        legally incapable of giving consent;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>d)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the data controller is a foundation, association, or
        other non-profit body with a political,
        philosophical, religious, or trade union aim, and
        the processing is carried out in the course of its
        legitimate activities, provided that the processing
        relates solely to the members or former members of
        that body or to persons who have regular contact
        with it in connection with its purposes and that the
        personal data is not disclosed outside the body
        without the consent of the data subjects;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>e)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the processing relates to personal data which is
        manifestly made public by the data subject;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>f)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the processing is necessary for the conduct of legal
        claims or whenever courts are acting in their
        judicial capacity;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>g)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the processing is necessary for substantial public
        interest reasons, on the basis of law which shall be
        proportionate to the aim pursued, shall respect the
        essence of the right to data protection, and shall
        provide for suitable and specific measures to
        safeguard the fundamental rights and interests of
        the data subject;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>h)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the processing is necessary for the purposes of
        preventative or occupational medicine, for the
        assessment of the working capacity of an employee,
        for medical diagnosis, for the provision of health
        or social care or treatment, or the management of
        health or social care systems or services on the
        basis of law or pursuant to a contract with a health
        professional, subject to the conditions and
        safeguards referred to in Article 9(3) of the UK
        GDPR;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>i)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the processing is necessary for public interest
        reasons in the area of public health, for example,
        protecting against serious cross-border threats to
        health or ensuring high standards of quality and
        safety of health care and of medicinal products or
        medical devices, on the basis of law which provides
        for suitable and specific measures to safeguard the
        rights and freedoms of the data subject (in
        particular, professional secrecy); or
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>j)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the processing is necessary for archiving purposes
        in the public interest, scientific or historical
        research purposes, or statistical purposes in
        accordance with Article 89(1) of the UK GDPR (as
        supplemented by section 19 of the Data Protection
        Act 2018) based on law which shall be proportionate
        to the aim pursued, respect the essence of the right
        to data protection, and provide for suitable and
        specific measures to safeguard the fundamental
        rights and the interests of the data subject.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="7"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 31.62pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
          padding-left: 3.83pt;
        "
      >
        <span style="font-weight: bold">Consent</span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>
        If consent is relied upon as the lawful basis for
        collecting, holding, and/or processing any personal
        data, the following shall apply:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>7.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Consent is a clear indication by the data subject
        that they agree to the processing of their personal
        data. Such a clear indication may take the form of a
        statement or a positive action. Silence, pre-ticked
        boxes, or inactivity are unlikely to amount to
        consent.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>7.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Where consent is given in a document which includes
        other matters, the section dealing with consent must
        be kept clearly separate from such other matters.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>7.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Data subjects are free to withdraw consent at any
        time and it must be made easy for them to do so. If
        a data subject withdraws consent, their request must
        be honoured promptly.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>7.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        If personal data is to be processed for a different
        purpose that is incompatible with the purpose or
        purposes for which that personal data was originally
        collected that was not disclosed to the data subject
        when they first provided their consent, consent to
        the new purpose or purposes may need to be obtained
        from the data subject.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>7.5</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Where special category personal data is processed,
        the Company shall normally rely on a lawful basis
        other than explicit consent. If explicit consent is
        relied upon, the data subject in question must be
        issued with a suitable privacy notice in order to
        capture their consent.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>7.6</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        In all cases where consent is relied upon as the
        lawful basis for collecting, holding, and/or
        processing personal data, records must be kept of
        all consents obtained in order to ensure that the
        Company can demonstrate its compliance with consent
        requirements.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="8"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 31.62pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
          padding-left: 3.83pt;
        "
      >
        <span style="font-weight: bold">
          Specified, Explicit, and Legitimate Purposes
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>8.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company collects and processes the employee
        personal data set out in Parts 23 to 28 of this
        Policy. This includes:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        personal data collected directly from employee data
        subjects; and
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        personal data obtained from third parties.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>8.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company only collects, processes, and holds
        employee personal data for the specific purposes set
        out in Parts 23 to 28 of this Policy (or for other
        purposes expressly permitted by Data Protection
        Law).
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>8.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employee data subjects shall be kept informed at all
        times of the purpose or purposes for which the
        Company uses their personal data. Please refer to
        Part 15 for more information on keeping data
        subjects informed.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="9"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 31.62pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
          padding-left: 3.83pt;
        "
      >
        <span style="font-weight: bold">
          Adequate, Relevant, and Limited Data Processing
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>9.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company will only collect and process employee
        personal data for and to the extent necessary for
        the specific purpose or purposes of which employee
        data subjects have been informed (or will be
        informed) as under Part 8, above, and as set out in
        Parts 23 to 28, below.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>9.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employees, agents, contractors, or other parties
        working on behalf of the Company may collect
        employee personal data only to the extent required
        for the performance of their job duties and only in
        accordance with this Policy. Excessive personal data
        must not be collected.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>9.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employees, agents, contractors, or other parties
        working on behalf of the Company may process
        employee personal data only when the performance of
        their job duties requires it. Employee personal data
        held by the Company cannot be processed for any
        unrelated reasons.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="10"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Accuracy of Data and Keeping Data Up-to-Date
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>10.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company shall ensure that all employee personal
        data collected, processed, and held by it is kept
        accurate and up-to-date. This includes, but is not
        limited to, the rectification of personal data at
        the request of an employee data subject, as set out
        in Part 17, below.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>10.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The accuracy of employee personal data shall be
        checked when it is collected and from time-to-time
        thereafter. If any employee personal data is found
        to be inaccurate or out-of-date, all reasonable
        steps will be taken without delay to amend or erase
        that data, as appropriate.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>10.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        It is the responsibility of individual employee data
        subjects to ensure that the personal data they have
        provided to the Company is kept up-to-date. If any
        such personal data changes, employees should ensure
        that the relevant member of staff and/or department
        is informed as soon as is reasonably possible. The
        Company relies on the cooperation of its employees
        to help meet its obligations under Data Protection
        Law.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="11"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Data Retention
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>11.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company shall not keep employee personal data
        for any longer than is necessary in light of the
        purpose or purposes for which it was originally
        collected, held, and processed.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>11.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        When employee personal data is no longer required,
        all reasonable steps will be taken to erase or
        otherwise dispose of it securely and without delay.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>11.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        For full details of the Company’s approach to data
        retention, including retention periods for specific
        personal data types held by the Company, please
        refer to our Data Retention Policy.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="12"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Secure Processing
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>12.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company shall ensure that all employee personal
        data collected, held, and processed is kept secure
        and protected against unauthorised or unlawful
        processing and against accidental loss, destruction,
        or damage. Further details of the technical and
        organisational measures which shall be taken are
        provided in Parts 29 to 34 of this Policy.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>12.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All technical and organisational measures taken to
        protect employee personal data shall be regularly
        reviewed and evaluated to ensure their ongoing
        effectiveness and the continued security of employee
        personal data.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>12.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Data security must be maintained at all times by
        protecting the confidentiality, integrity, and
        availability of all employee personal data as
        follows:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        only those with a genuine need to access and use
        employee personal data and who are authorised to do
        so may access and use it;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        employee personal data must be accurate and suitable
        for the purpose or purposes for which it is
        collected, held, and processed; and
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>c)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        authorised users must always be able to access
        employee personal data as required for the
        authorised purpose or purposes.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="13"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Accountability and Record-Keeping
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>13.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Fraser Matcham shall be responsible, working
        together with the senior management, for
        administering this Policy and for developing and
        implementing any applicable related policies,
        procedures, and/or guidelines.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>13.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company shall follow a ‘privacy by design’
        approach at all times when collecting, holding, and
        processing employee personal data. Data Protection
        Impact Assessments shall be conducted if any
        processing presents a significant risk to the rights
        and freedoms of employee data subjects (please refer
        to Part 14 for further information).
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>13.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All employees, agents, contractors, or other parties
        working on behalf of the Company shall be given
        appropriate training in data protection and privacy,
        addressing the relevant aspects of Data Protection
        Law, this Policy, and all other applicable Company
        policies.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>13.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company’s data protection compliance shall be
        regularly reviewed and evaluated by means of Data
        Protection Audits.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>13.5</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company shall keep written internal records of
        all employee personal data collection, holding, and
        processing, which shall incorporate the following
        information:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the name and details of the Company and any
        applicable third-party data transfers (including
        data processors and other data controllers with whom
        personal data is shared);
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the purposes for which the Company collects, holds,
        and processes employee personal data;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>c)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the Company’s legal basis or bases (including, where
        applicable, consent, the mechanism(s) for obtaining
        such consent, and records of such consent) for
        collecting, holding, and processing employee
        personal data;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>d)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        details of the categories of employee personal data
        collected, held, and processed by the Company, and
        the categories of employee data subject to which
        that personal data relates;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>e)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        details of any transfers of employee personal data
        to non-UK countries including all mechanisms and
        security safeguards;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>f)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        details of how long employee personal data will be
        retained by the Company (please refer to the
        Company’s Data Retention Policy);
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>g)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        details of employee personal data storage, including
        location(s);
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>h)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        detailed descriptions of all technical and
        organisational measures taken by the Company to
        ensure the security of employee personal data.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="14"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Data Protection Impact Assessments and Privacy by
          Design
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>14.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        In accordance with the ‘privacy by design’
        principles, the Company shall carry out Data
        Protection Impact Assessments for any and all new
        projects and/or new uses of employee personal data
        which involve the use of new technologies and where
        the processing involved is likely to result in a
        high risk to the rights and freedoms of employee
        data subjects.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>14.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The principles of ‘privacy by design’ should be
        followed at all times when collecting, holding, and
        processing employee personal data. The following
        factors should be taken into consideration:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the nature, scope, context, and purpose or purposes
        of the collection, holding, and processing;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the state of the art of all relevant technical and
        organisational measures to be taken;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>c)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the cost of implementing such measures; and
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>d)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the risks posed to employee data subjects and to the
        Company, including their likelihood and severity.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>14.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Data Protection Impact Assessments shall be overseen
        by Fraser Matcham and shall address the following:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the type(s) of employee personal data that will be
        collected, held, and processed;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the purpose(s) for which employee personal data is
        to be used;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>c)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>the Company’s objectives;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>d)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>how employee personal data is to be used;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>e)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the parties (internal and/or external) who are to be
        consulted;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>f)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the necessity and proportionality of the data
        processing with respect to the purpose(s) for which
        it is being processed;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>g)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>risks posed to employee data subjects;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>h)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        risks posed both within and to the Company; and
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>i)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        proposed measures to minimise and handle identified
        risks.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="15"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Keeping Data Subjects Informed
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>15.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company shall provide the information set out in
        Part 15.2 to every data employee data subject:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Where employee personal data is collected directly
        from employee data subjects, those employee data
        subjects will be informed of their right to access
        this policy; and
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        where employee personal data is obtained from a
        third party, the relevant employee data subjects
        will be informed of its purpose:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 141.75pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>i)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        if the personal data is used to communicate with the
        employee data subject, when the first communication
        is made; or
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 141.75pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>ii)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        if the personal data is to be transferred to another
        party, before that transfer is made; or
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 141.75pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>iii)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        as soon as reasonably possible and in any event not
        more than one month after the personal data is
        obtained.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>15.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The following information shall be provided in the
        form of a privacy notice:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        details of the Company including, but not limited
        to, all relevant contact details, and the names and
        contact details of any applicable representatives
        and executives;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the purpose(s) for which the employee personal data
        is being collected and will be processed (as
        detailed in Parts 23 to 28 of this Policy) and the
        lawful basis justifying that collection and
        processing;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>c)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        where applicable, the legitimate interests upon
        which the Company is justifying its collection and
        processing of the employee personal data;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>d)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        where the employee personal data is not obtained
        directly from the employee data subject, the
        categories of personal data collected and processed;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>e)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        where the employee personal data is to be
        transferred to one or more third parties, details of
        those parties;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>f)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        where the employee personal data is to be
        transferred to a third party that is located outside
        of the UK, details of that transfer, including but
        not limited to the safeguards in place (see Part 36
        of this Policy for further details);
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>g)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        details of applicable data retention periods;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>h)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        details of the employee data subject’s rights under
        the UK GDPR;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>i)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        details of the employee data subject’s right to
        withdraw their consent to the Company’s processing
        of their personal data at any time (where
        applicable);
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>j)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        details of the employee data subject’s right to
        complain to the Information Commissioner’s Office;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>k)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        where the employee personal data is not obtained
        directly from the employee data subject, details
        about the source of that personal data;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>l)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        where applicable, details of any legal or
        contractual requirement or obligation necessitating
        the collection and processing of the employee
        personal data and details of any consequences of
        failing to provide it; and
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>m)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        details of any automated decision-making or
        profiling that will take place using the employee
        personal data, including information on how
        decisions will be made, the significance of those
        decisions, and any consequences.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="16"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Data Subject Access
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>16.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employee data subjects may make subject access
        requests (“SARs”) at any time to find out more about
        the personal data which the Company holds about
        them, what it is doing with that personal data, and
        why.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>16.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employees wishing to make a SAR should do using a
        Subject Access Request Form, sending the form to
        Fraser Matcham at fraser@legalutopia.co.uk.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>16.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Responses to SARs must normally be made within one
        month of receipt; however, this may be extended by
        up to two months if the SAR is complex and/or
        numerous requests are made. If such additional time
        is required, the data subject shall be informed.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>16.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All SARs received shall be handled by the Company’s
        executives.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>16.5</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company does not charge a fee for the handling
        of normal SARs. The Company reserves the right to
        charge reasonable fees for additional copies of
        information that has already been supplied to an
        employee data subject, and for requests that are
        manifestly unfounded or excessive, particularly
        where such requests are repetitive.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="17"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Rectification of Personal Data
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>17.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employee data subjects have the right to require the
        Company to rectify any of their personal data that
        is inaccurate or incomplete.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>17.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company shall rectify the employee personal data
        in question, and inform the employee data subject of
        that rectification, within one month of the employee
        data subject informing the Company of the issue. The
        period can be extended by up to two months in the
        case of complex requests. If such additional time is
        required, the employee data subject shall be
        informed.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>17.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        In the event that any affected employee personal
        data has been disclosed to third parties, those
        parties shall be informed of any rectification that
        must be made to that personal data.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="18"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Erasure of Personal Data
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>18.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employee data subjects have the right to request
        that the Company erases the personal data it holds
        about them in the following circumstances:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        it is no longer necessary for the Company to hold
        that employee personal data with respect to the
        purpose(s) for which it was originally collected or
        processed;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the employee data subject wishes to withdraw their
        consent (where applicable) to the Company holding
        and processing their personal data;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>c)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the employee data subject objects to the Company
        holding and processing their personal data (and
        there is no overriding legitimate interest to allow
        the Company to continue doing so) (see Part 21 of
        this Policy for further details concerning the right
        to object);
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>d)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the employee personal data has been processed
        unlawfully;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>e)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the employee personal data needs to be erased in
        order for the Company to comply with a particular
        legal obligation.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>18.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Unless the Company has reasonable grounds to refuse
        to erase employee personal data, all requests for
        erasure shall be complied with, and the employee
        data subject informed of the erasure, within one
        month of receipt of the employee data subject’s
        request. The period can be extended by up to two
        months in the case of complex requests. If such
        additional time is required, the employee data
        subject shall be informed.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>18.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        In the event that any employee personal data that is
        to be erased in response to an employee data
        subject’s request has been disclosed to third
        parties, those parties shall be informed of the
        erasure (unless it is impossible or would require
        disproportionate effort to do so).
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="19"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Restriction of Personal Data Processing
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>19.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employee data subjects may, in certain limited
        circumstances, request that the Company ceases
        processing the personal data it holds about them. If
        an employee data subject makes a valid request, the
        Company shall retain only the amount of employee
        personal data concerning that data subject (if any)
        that is necessary to ensure that the personal data
        in question is not processed further.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>19.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        In the event that any affected employee personal
        data has been disclosed to third parties, those
        parties shall be informed of the applicable
        restrictions on processing it (unless it is
        impossible or would require disproportionate effort
        to do so).
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        page-break-after: avoid;
        line-height: normal;
      "
    >
      <span style="font-weight: bold"> </span>
    </p>
    <ol
      start="20"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Objections to Personal Data Processing
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>20.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employee data subjects have the right to object to
        the Company processing their personal data based on
        legitimate interests, for direct marketing
        (including profiling), and processing for scientific
        and/or historical research and statistics purposes.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>20.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Where an employee data subject objects to the
        Company processing their personal data based on its
        legitimate interests, the Company shall cease such
        processing immediately, unless it can be
        demonstrated that the Company’s legitimate grounds
        for such processing override the employee data
        subject’s interests, rights, and freedoms, or that
        the processing is necessary for the conduct of legal
        claims.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>20.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Where an employee data subject objects to the
        Company processing their personal data for direct
        marketing purposes, the Company shall cease such
        processing promptly.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>20.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Where an employee data subject objects to the
        Company processing their personal data for
        scientific and/or historical research and statistics
        purposes, the employee data subject must, under the
        UK GDPR, demonstrate grounds relating to his or her
        particular situation. The Company is not required to
        comply if the research is necessary for the
        performance of a task carried out for reasons of
        public interest.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="21"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">Personal Data</span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>
        The Company holds a range of personal data about its
        employees. Employee personal data shall be
        collected, held, and processed in accordance with
        employee data subjects’ rights and the Company’s
        obligations under Data Protection Law and with this
        Policy. The Company may collect, hold, and process
        the employee personal data detailed in Parts 21 to
        28 of this Policy (for details of data retention,
        please refer to the Company’s Data Retention
        Policy):
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>21.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Identification information relating to employees:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Name;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Contact Details;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>c)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Unique IDs;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>d)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Address;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>e)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>National Insurance Number;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>f)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Passport Numbers;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>g)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Date of Birth;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>h)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Signature;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>i)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Bank Account details.</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>21.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Equal opportunities monitoring information (Please
        refer to Part 22, below, for further information):
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Age;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Gender;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>c)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Ethnicity;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>d)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Nationality;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>e)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Religion.</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>21.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Health records (Please refer to Part 23, below, for
        further information):
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Details of sick leave;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Medical conditions;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>c)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Disabilities;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>d)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Prescribed medication;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>e)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Medical reports.</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>21.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Employment records:</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Interview notes;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        CVs, application forms, covering letters, and
        similar documents;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>c)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Assessments, performance reviews, and similar
        documents;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>d)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Details of remuneration including salaries, pay
        increases, bonuses, commission, overtime, benefits,
        and expenses;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>e)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Details of trade union membership (where applicable)
        (please refer to Part 25, below, for further
        information)
      </span>
      <span style="font-weight: bold">;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>f)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employee monitoring information (please refer to
        Part 26, below, for further information);
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>g)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Records of disciplinary matters including reports
        and warnings, both formal and informal;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>h)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Details of grievances including documentary
        evidence, notes from interviews, procedures
        followed, and outcomes;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>i)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Company correspondence and communications;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>j)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Identity documents and other similar documents
        evidencing an employee’s right to work;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>k)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Emergency contact or next of kin;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>l)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Marital status;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>m)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>References and their contact details;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>n)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>State benefits status;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>o)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Pensions information and registration numbers;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>p)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Details relating to previous employment and
        residential tax status.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span> </span>
    </p>
    <ol
      start="22"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Equal Opportunities Monitoring Information
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>22.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company collects, holds, and processes certain
        information for the purposes of monitoring equal
        opportunities. Some of the personal data collected
        for this purpose, such as details of ethnic origin
        and religious beliefs, falls within the UK GDPR’s
        definition of special category data (see Part 2 of
        this Policy for a definition). Where possible, such
        data will be anonymised. Where special category
        personal data remains, it will be collected, held,
        and processed strictly in accordance with the
        conditions for processing special category personal
        data, as set out in Part 6.2 of this Policy. No
        special category personal data relating to equal
        opportunities monitoring will be collected, held, or
        processed without the relevant employee data
        subject’s consent.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>22.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Non-anonymised equal opportunities monitoring
        information shall be accessible and used only by
        executives, directors, and senior management and
        shall not be revealed to other employees, agents,
        contractors, or other parties working on behalf of
        the Company without the express consent of the
        employee data subject(s) to whom such data relates,
        except in exceptional circumstances where it is
        necessary to protect the vital interests of the
        employee data subject(s) concerned, and such
        circumstances satisfy one or more of the conditions
        set out in Part 6.2 of this Policy.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>22.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Equal opportunities monitoring information will only
        be collected, held, and processed to the extent
        required to prevent, reduce, and stop unlawful
        discrimination in line with the Equality Act 2010,
        and to ensure that recruitment, promotion, training,
        development, assessment, benefits, pay, terms and
        conditions of employment, redundancy, and dismissals
        are determined on the basis of capability,
        qualifications, experience, skills, and
        productivity.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>22.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employee data subjects have the right to request
        that the Company does not keep equal opportunities
        monitoring information about them. All requests must
        be made in writing and addressed to Fraser Matcham
        at fraser@legalutopia.co.uk.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span> </span>
    </p>
    <ol
      start="23"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 18pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span></span>
        <span style="font-weight: bold">
          Health Records
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>23.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company may health records on all employee data
        subjects which are used to assess the health,
        wellbeing, and welfare of employees and to highlight
        any issues which may require further investigation.
        In particular, the Company places a high priority on
        maintaining health and safety in the workplace, on
        promoting equal opportunities, and on preventing
        discrimination on the grounds of disability or other
        medical conditions. In most cases, health data on
        employees falls within the UK GDPR’s definition of
        special category data (see Part 2 of this Policy for
        a definition). Any and all data relating to employee
        data subjects’ health, therefore, will be collected,
        held, and processed strictly in accordance with the
        conditions for processing special category personal
        data, as set out in Part 6.2 of this Policy. No
        special category personal data will be collected,
        held, or processed without the relevant employee
        data subject’s express consent.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>23.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Health records shall be accessible and used only by
        executives, directors, and senior management and
        shall not be revealed to other employees, agents,
        contractors, or other parties working on behalf of
        the Company without the express consent of the
        employee data subject(s) to whom such data relates,
        except in exceptional circumstances where it is
        necessary to protect the vital interests of the
        employee data subject(s) concerned, and such
        circumstances satisfy one or more of the conditions
        set out in Part 6.2 of this Policy.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>23.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Health records will only be collected, held, and
        processed to the extent required to ensure that
        employees are able to perform their work correctly,
        legally, safely, and without unlawful or unfair
        impediments or discrimination.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>23.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employee data subjects have the right to request
        that the Company does not keep health records about
        them. All such requests must be made in writing and
        addressed to Fraser Matcham at
        fraser@legalutopia.co.uk.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span> </span>
    </p>
    <ol
      start="24"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 18pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span></span>
        <span style="font-weight: bold">Benefits</span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>24.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        In cases where employee data subjects are enrolled
        in benefit schemes which are provided by the
        Company, it may be necessary from time to time for
        third party organisations to collect personal data
        from relevant employee data subjects.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>24.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Prior to the collection of such data, employee data
        subjects will be fully informed of the personal data
        that is to be collected, the reasons for its
        collection, and the way(s) in which it will be
        processed, as per the information requirements set
        out in Part 15 of this Policy.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>24.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company shall not use any such personal data
        except insofar as is necessary in the administration
        of the relevant benefits schemes.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>24.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The following schemes may be available to employees.
        Please note that not all schemes may be applicable
        to all employees:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        NEST Pension Scheme. For further information, please
        contact Fraser Matcham at fraser@legalutopia.co.uk.
        The following personal data may be collected, held,
        and processed:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 141.75pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>i)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Financial data related to employee remuneration;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 141.75pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>ii)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Age and date of birth;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 141.75pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>iii)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Employee contact information.</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span> </span>
    </p>
    <ol
      start="25"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 18pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span></span>
        <span style="font-weight: bold">Trade Unions</span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>25.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company will provide the following personal data
        concerning relevant employee data subjects to bona
        fide trade unions where those unions are recognised
        by the Company. In most cases, information about an
        individual’s trade union membership falls within the
        UK GDPR’s definition of special category data (see
        Part 4 of this Policy for a definition). Any and all
        data relating to employee data subjects’ trade union
        membership, therefore, will be collected, held, and
        processed strictly in accordance with the conditions
        for processing special category personal data, as
        set out in Part 6.2 of this Policy. No special
        category personal data will be collected, held, or
        processed without the relevant employee data
        subject’s express consent. The following data will
        be collected and supplied:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Name;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Job description;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>c)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>Position and status;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>d)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Other such employment records and personal data as
        relevant and required.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>25.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All employee data subjects have the right to request
        that the Company does not supply their personal data
        to trade unions and shall be informed of that right
        before any such transfer is made.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span> </span>
    </p>
    <ol
      start="26"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 18pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        <span></span>
        <span style="font-weight: bold">
          Employee Monitoring
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>26.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company may from time to time monitor the
        activities of employee data subjects. Such
        monitoring may include, but will not necessarily be
        limited to, internet and email monitoring. In the
        event that monitoring of any kind is to take place
        (unless exceptional circumstances, such as the
        investigation of criminal activity or a matter of
        equal severity, justify covert monitoring), employee
        data subjects will be informed of the exact nature
        of the monitoring in advance.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>26.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Monitoring should not (unless exceptional
        circumstances justify it, as above) interfere with
        an employee’s normal duties.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>26.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Monitoring will only take place if the Company
        considers that it is necessary to achieve the
        benefit it is intended to achieve. Personal data
        collected during any such monitoring will only be
        collected, held, and processed for reasons directly
        related to (and necessary for) achieving the
        intended result and, at all times, in accordance
        with employee data subjects’ rights and the
        Company’s obligations under Data Protection Law.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>26.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company shall ensure that there is no
        unnecessary intrusion upon employee data subjects’
        personal communications or activities, and under no
        circumstances will monitoring take place outside of
        an employee data subject’s normal place of work or
        work hours, unless the employee data subject in
        question is using Company equipment or other
        facilities including, but not limited to, Company
        email, the Company intranet, or a virtual private
        network (“VPN”) service provided by the Company for
        employee use.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="27"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Data Security - Transferring Personal Data and
          Communications
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>
        The Company shall ensure that the following measures
        are taken with respect to all communications and
        other transfers involving employee personal data:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>27.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All emails containing employee personal data must be
        reasonably securely stored and managed;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>27.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All emails containing employee personal data must be
        treated confidential;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>27.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employee personal data may be transmitted over
        secure networks only; transmission over unsecured
        networks is not permitted in any circumstances;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>27.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employee personal data may not be transmitted over a
        wireless network if there is a wired alternative
        that is reasonably practicable;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>27.5</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Where employee personal data is to be sent by
        facsimile transmission the recipient should be
        informed in advance of the transmission and should
        be waiting by the fax machine to receive the data;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>27.6</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Where employee personal data is to be transferred in
        hardcopy form it should be passed directly to the
        recipient or sent using recorded mail;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>27.7</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All employee personal data to be transferred
        physically, whether in hardcopy form or on removable
        electronic media shall be transferred in a suitably
        secure storage device.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="28"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Data Security - Storage
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>
        The Company shall ensure that the following measures
        are taken with respect to the storage of employee
        personal data:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>28.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All electronic copies of employee personal data
        should be stored securely using passwords and on a
        secure device;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>28.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All hardcopies of employee personal data, along with
        any electronic copies stored on physical, removable
        media should be stored securely in box, drawer,
        cabinet, or similar or a locked room;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>28.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All employee personal data stored electronically
        should be backed up regularly with backups stored
        with executives, directors, or senior managers only.
        All backups should be secured stored in a
        combination or biometric safe located in a secure
        location in the UK;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>28.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        No employee personal data should be stored on any
        mobile device (including, but not limited to,
        laptops, tablets, and smartphones), other than where
        a device does belong to the employee that personal
        data concerns or a executive, director, or senior
        management of the Company. Save for where an
        employee provide express consent and, in the event
        of such approval, strictly in accordance with all
        instructions and limitations described at the time
        the approval is given, and for no longer than is
        absolutely necessary;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>28.5</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        No employee personal data should be transferred to
        any device personally belonging to an employee,
        agent, contractor, or other party working on behalf
        of the Company and employee personal data may only
        be transferred to devices belonging to agents,
        contractors, or other parties working on behalf of
        the Company where the party in question has agreed
        to comply fully with the letter and spirit of this
        Policy and of Data Protection Law, including but not
        limited to the UK GDPR, (which may include
        demonstrating to the Company that all suitable
        technical and organisational measures have been
        taken);
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>28.6</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Sub-Clause 28.5 is subject to the same exceptions
        described in sub-Clause 28.4.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="29"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Data Security - Disposal
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>
        When any employee personal data is to be erased or
        otherwise disposed of for any reason (including
        where copies have been made and are no longer
        needed), it should be securely deleted and disposed
        of. For further information on the deletion and
        disposal of personal data, please refer to the
        Company’s Data Retention Policy.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="30"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Data Security - Use of Personal Data
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>
        The Company shall ensure that the following measures
        are taken with respect to the use of employee
        personal data:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>30.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        No employee personal data may be shared informally
        and if an employee, agent, contractor, or other
        party working on behalf of the Company requires
        access to any employee personal data that they do
        not already have access to, such access should be
        formally requested from Fraser Matcham at
        fraser@legalutopia.co.uk;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>30.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        No employee personal data may be transferred to any
        employee, agent, contractor, or other party, whether
        such parties are working on behalf of the Company or
        not, without the authorisation of Fraser Matcham or
        a Director of the Company;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>30.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employee personal data must be handled with care at
        all times and should not be left unattended or on
        view to unauthorised employees, agents, contractors,
        or other parties at any time;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>30.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        If employee personal data is being viewed on a
        computer screen and the computer in question is to
        be left unattended for any period of time, the user
        must lock the computer and screen before leaving it;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>30.5</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Where employee personal data held by the Company is
        used for marketing purposes, it shall be the
        responsibility of Fraser Matcham to ensure that the
        appropriate consent is obtained and that no employee
        data subjects have opted out, whether directly or
        via a third-party service such as the TPS.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="31"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Data Security - IT Security
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>
        The Company shall ensure that the following measures
        are taken with respect to IT and information
        security:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>31.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All passwords used to protect employee personal data
        should be changed regularly and should not use words
        or phrases that can be easily guessed or otherwise
        compromised. All passwords must contain a
        combination of uppercase and lowercase letters,
        numbers, and symbols. All software used by the
        Company is designed to require such passwords;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>31.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Under no circumstances should any passwords be
        written down or shared between any employees,
        agents, contractors, or other parties working on
        behalf of the Company, irrespective of seniority or
        department. If a password is forgotten, it must be
        reset using the applicable method. IT staff do not
        have access to passwords;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>31.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All software (including, but not limited to,
        applications and operating systems) shall be kept
        up-to-date. The Company’s IT staff shall be
        responsible for installing any and all
        security-related updates as soon as reasonably and
        practically possible unless there are valid
        technical reasons not to do so
      </span>
      <span style="font-weight: bold">;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>31.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        No software may be installed on any Company-owned
        computer or device without the prior approval of the
        Fraser Matcham;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>31.5</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All employees shall be required to undertake
        cybersecurity training on induction to the Company
        where their start-date is post the enactment of this
        policy.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="32"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Organisational Measures
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>
        The Company shall ensure that the following measures
        are taken with respect to the collection, holding,
        and processing of employee personal data:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>32.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All employees, agents, contractors, or other parties
        working on behalf of the Company shall be made fully
        aware of both their individual responsibilities and
        the Company’s responsibilities under Data Protection
        Law and under this Policy, and shall be provided
        with a copy of this Policy;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>32.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Only employees, agents, contractors, or other
        parties working on behalf of the Company that need
        access to, and use of, employee personal data in
        order to carry out their assigned duties correctly
        shall have access to employee personal data held by
        the Company;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>32.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All sharing of employee personal data shall comply
        with the information provided to the relevant
        employee data subjects and, if required, the consent
        of such data subjects shall be obtained prior to the
        sharing of their personal data;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>32.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All employees, agents, contractors, or other parties
        working on behalf of the Company handling employee
        personal data will be appropriately trained to do
        so;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>32.5</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All employees, agents, contractors, or other parties
        working on behalf of the Company handling employee
        personal data will be appropriately supervised;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>32.6</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All employees, agents, contractors, or other parties
        working on behalf of the Company handling employee
        personal data shall be required and encouraged to
        exercise care, caution, and discretion when
        discussing work-related matters that relate to
        employee personal data, whether in the workplace or
        otherwise;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>32.7</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Methods of collecting, holding, and processing
        employee personal data shall be regularly evaluated
        and reviewed;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>32.8</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All employee personal data held by the Company shall
        be reviewed periodically, as set out in the
        Company’s Data Retention Policy;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>32.9</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The performance of those employees, agents,
        contractors, or other parties working on behalf of
        the Company handling employee personal data shall be
        regularly evaluated and reviewed;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>32.10</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All employees, agents, contractors, or other parties
        working on behalf of the Company handling employee
        personal data will be bound to do so in accordance
        with the principles of Data Protection Law and this
        Policy by contract;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>32.11</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All agents, contractors, or other parties working on
        behalf of the Company handling employee personal
        data must ensure that any and all of their employees
        who are involved in the processing of employee
        personal data are held to the same conditions as
        those relevant employees of the Company arising out
        of this Policy and Data Protection Law;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>32.12</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Where any agent, contractor or other party working
        on behalf of the Company handling employee personal
        data fails in their obligations under this Policy,
        that party shall indemnify and hold harmless the
        Company against any costs, liability, damages, loss,
        claims or proceedings which may arise out of that
        failure;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>32.13</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company shall maintain adequate cybersecurity,
        employers’ liability, and other such relevant
        insurance as required to provide appropriate redress
        to any data subject of the Company, as determined by
        the sole discretion of the Company’s directors.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="33"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Sharing Personal Data
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>33.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company may only share employee personal data
        with third parties if specific safeguards are in
        place.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>33.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employee personal data may only be shared with other
        employees, agents, contractors, or other parties
        working on behalf of the Company if the recipient
        has a legitimate, job-related need-to-know. If any
        employee personal data is to be shared with a third
        party located outside of the UK, the provisions of
        Part 36, below, shall also apply.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>33.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Where a third-party data processor is used, that
        processor shall process personal data on behalf of
        the Company (as data controller) only on the written
        instruction of the Company.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>33.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employee personal data may only be shared with third
        parties in the following circumstances:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the third party has a legitimate need to know the
        information for the purpose of providing services to
        the Company under a contract;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the sharing of the employee personal data concerned
        complies with the privacy notice provided to the
        affected employee data subjects (see Part 15 for
        more information) and, if required, the employees
        concerned have consented to the sharing of their
        personal data;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>c)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        the third-party recipient has agreed to comply with
        all applicable data security standards, policies,
        and procedures, and has put in place adequate
        security measures to protect the employee personal
        data;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>d)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        (where applicable) the transfer complies with any
        cross-border transfer restrictions (see Part 36,
        below); and
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>e)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        a fully executed written agreement containing data
        processing clauses compliant with Data Protection
        Law has been entered into with the third-party
        recipient.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="34"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Transferring Personal Data to a Country Outside
          the UK
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>34.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The Company may, from time to time, transfer
        (‘transfer’ includes making available remotely)
        employee personal data to countries outside of the
        UK. The UK GDPR restricts such transfers in order to
        ensure that the level of protection given to data
        subjects is not compromised.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>34.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Employee personal data may only be transferred to a
        country outside the UK if one of the following
        applies:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The UK has issued regulations confirming that the
        country in question ensures an adequate level of
        protection (referred to as ‘adequacy decisions’ or
        ‘adequacy regulations’). From 1 January 2021,
        transfers of personal data from the UK to EEA
        countries will continue to be permitted.
        Transitional provisions are also in place to
        recognise pre-existing EU adequacy decisions in the
        UK.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Appropriate safeguards are in place including
        binding corporate rules, standard contractual
        clauses approved for use in the UK (this includes
        those adopted by the European Commission prior to 1
        January 2021), an approved code of conduct, or an
        approved certification mechanism.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>c)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The transfer is made with the informed and explicit
        consent of the relevant employee data subject(s).
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>d)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The transfer is necessary for one of the other
        reasons set out in the UK GDPR including the
        performance of a contract between the employee data
        subject and the Company; public interest reasons;
        for the establishment, exercise, or defence of legal
        claims; to protect the vital interests of the
        employee data subject where the employee data
        subject is physically or legally incapable of giving
        consent; or, in limited circumstances, for the
        Company’s legitimate interests.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="35"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Data Breach Notification
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>35.1</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        All personal data breaches concerning employee
        personal data must be reported immediately to Fraser
        Matcham at fraser@legalutopia.co.uk.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>35.2</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        If an employee, agent, contractor, or other party
        working on behalf of the Company becomes aware of or
        suspects that a personal data breach has occurred,
        they must not attempt to investigate it themselves.
        Any and all evidence relating to the personal data
        breach in question should be carefully retained.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>35.3</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        If a personal data breach occurs and that breach is
        likely to result in a risk to the rights and
        freedoms of employee data subjects (e.g. financial
        loss, breach of confidentiality, discrimination,
        reputational damage, or other significant social or
        economic damage), the Fraser Matcham must ensure
        that the Information Commissioner’s Office is
        informed of the breach without delay, and in any
        event, within 72 hours after having become aware of
        it.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>35.4</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        In the event that a personal data breach is likely
        to result in a high risk (that is, a higher risk
        than that described under Part 37.3) to the rights
        and freedoms of employee data subjects, the Fraser
        Matcham must ensure that all affected employee data
        subjects are informed of the breach directly and
        without undue delay.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>35.5</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Data breach notifications shall include the
        following information:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>a)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The categories and approximate number of data
        subjects concerned;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>b)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The categories and approximate number of personal
        data records concerned;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>c)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        The name and contact details of Fraser Matcham (or
        other contact point where more information can be
        obtained);
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>d)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>The likely consequences of the breach;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 106.3pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>e)</span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
      <span>
        Details of the measures taken, or proposed to be
        taken, by the Company to address the breach
        including, where appropriate, measures to mitigate
        its possible adverse effects.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <ol
      start="36"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 35.45pt;
          margin-bottom: 6pt;
          page-break-after: avoid;
          line-height: normal;
        "
      >
        <span style="font-weight: bold">
          Implementation of Policy
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>
        This Policy shall be deemed effective as of 1
      </span>
      <span
        style="font-size: 4.67pt; vertical-align: super"
      >
        st
      </span>
      <span>
        February 2021. No part of this Policy shall have
        retroactive effect and shall thus apply only to
        matters occurring on or after this date.
      </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        line-height: normal;
      "
    >
      <span>
        This Policy has been approved and authorised by:
      </span>
    </p>
    <table
      cellspacing="0"
      cellpadding="0"
      class="Table3"
      style="
        width: 465.35pt;
        margin-left: 5.4pt;
        border: 1pt solid #000000;
        -aw-border-insideh: 1pt single #000000;
        -aw-border-insidev: 1pt single #000000;
        border-collapse: collapse;
      "
    >
      <tbody>
        <tr>
          <td
            style="
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="margin-top: 9pt; line-height: 120%"
            >
              <span style="font-weight: bold">Name:</span>
            </p>
          </td>
          <td
            style="
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="margin-top: 9pt; line-height: 120%"
            >
              <span>Fraser Matcham</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="margin-top: 9pt; line-height: 120%"
            >
              <span style="font-weight: bold">
                Position:
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="margin-top: 9pt; line-height: 120%"
            >
              <span>Director</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="margin-top: 9pt; line-height: 120%"
            >
              <span style="font-weight: bold">Date:</span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="margin-top: 9pt; line-height: 120%"
            >
              <span>1</span>
              <span
                style="line-height: 120%; font-size: 7pt"
              >
                st
              </span>
              <span>February 2021</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="margin-top: 9pt; line-height: 120%"
            >
              <span style="font-weight: bold">
                Due for Review by:
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="margin-top: 9pt; line-height: 120%"
            >
              <span>1</span>
              <span
                style="line-height: 120%; font-size: 7pt"
              >
                st
              </span>
              <span>July 2023</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="margin-top: 9pt; line-height: 120%"
            >
              <span style="font-weight: bold">
                Signature:
              </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="margin-top: 9pt; line-height: 120%"
            >
              <span>F.MATCHAM</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="margin-top: 9pt; line-height: 120%"
            >
              <span> </span>
            </p>
          </td>
          <td
            style="
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              padding-right: 4.9pt;
              padding-left: 4.9pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="margin-top: 9pt; line-height: 120%"
            >
              <span> </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span style="-aw-import: ignore"> </span>
    </p>
    <p
      class="normal0"
      style="line-height: normal; font-size: 8pt"
    >
      <span> </span>
      <span>1</span>
    </p>
    <p class="normal0" style="line-height: normal">
      <span> </span>
    </p>
  </div>
</div>
`
        }}
        />)
}
