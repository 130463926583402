import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseAreasOfPractice = {
  areasOfPractice: AppState['areasOfPractice']
  areasOfPracticeLoading: AppState['areasOfPracticeLoading']
  areasOfPracticeError: AppState['areasOfPracticeError']
  getAreasOfPractice: (
    data: RequestTypes['getAreasOfPractice'],
    callbacks?: Callbacks,
  ) => void
}

export default function useAreasOfPractice(): UseAreasOfPractice {
  const { areasOfPractice, areasOfPracticeLoading, areasOfPracticeError } =
    useSelector((state: AppState) => ({
      areasOfPractice: state.areasOfPractice,
      areasOfPracticeLoading: state.areasOfPracticeLoading,
      areasOfPracticeError: state.areasOfPracticeError,
    }))
  const dispatch = useDispatch()
  const getAreasOfPractice = useCallback(
    (data: RequestTypes['getAreasOfPractice'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getAreasOfPractice(data, callbacks))
    },
    [dispatch],
  )
  return {
    areasOfPractice,
    areasOfPracticeLoading,
    areasOfPracticeError,
    getAreasOfPractice,
  }
}
