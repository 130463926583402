import * as yup from 'yup'

const schemaBase = {
  email: yup.string(),
  first_name: yup
    .string()
    .typeError('First Name is required')
    .required('First Name is required'),
  last_name: yup
    .string()
    .typeError('Last Name is required')
    .required('Last Name is required'),
}

export const ProfileSchema = yup.object().shape({
  ...schemaBase,
  address_line_1: yup
    .string()
    .typeError('No. / Building is required')
    .required('No. / Building is required'),
  address_line_2: yup.string().typeError('Street address is required'),
  town: yup.string().typeError('Town is required').required('Town is required'),
  gender: yup
    .string()
    .typeError('Gender is required')
    .required('Gender is required'),
  postal_code: yup
    .string()
    .typeError('Postal Code is required')
    .required('Postal Code is required'),
})

export const BusinessProfileSchema = yup.object().shape({
  ...schemaBase,
  address_line_1: yup
    .string()
    .typeError('No. / Building is required')
    .required('No. / Building is required'),
  address_line_2: yup.string().typeError('Street address is required'),
  town: yup.string().typeError('Town is required').required('Town is required'),
  gender: yup
    .string()
    .typeError('Gender is required')
    .required('Gender is required'),
  county: yup.string().typeError('County is required'),
  postal_code: yup
    .string()
    .typeError('Postal Code is required')
    .required('Postal Code is required'),
  business_profile: yup.object().shape({
    industries: yup.array().of(yup.string()).required('Industries is required'),
    business_name: yup.string().required('Business name is required'),
    business_type: yup.string().required('Business type is required'),
  }),
})
