import { useAppStoreUrl } from "common/hooks/useAppStoreUrl";
import { useEffect, useState } from "react";
import { AppleAppStoreButton } from "components/AppStore/AppleAppStoreButton";
import { GoogleAppStoreButton } from "components/AppStore/GoogleAppStoreButton";

const GetTheAppPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { appStoreUrl } = useAppStoreUrl();

  useEffect(() => {
    if (appStoreUrl) {
      window.location.href = appStoreUrl;

      return;
    }

    setIsLoading(false);
  }, [appStoreUrl]);

  if (isLoading) {
    return null;
  }

  // If we can't detect the app store URL automatically show some app store buttons.
  return (
    (
      <div className="flex flex-col items-center mt-20">
        <p className="text-2xl xl:text-3xl font-bold mb-6">
          Get the app today.
        </p>
        <div className="space-y-8">
          <AppleAppStoreButton className="block" theme="dark" />
          <GoogleAppStoreButton className="block" theme="dark" />
        </div>
      </div>
    )
  );
};

export default GetTheAppPage;
