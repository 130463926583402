import classNames from "classnames";
import { MouseEventHandler, ReactNode } from "react";

type PrimaryButtonProps = {
  href?: string;
  className?: string;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  disabled?: boolean;
}

export const PrimaryButton = ({
  href,
  className,
  children,
  onClick,
  disabled,
}: PrimaryButtonProps) => {
  const isLink = !!href;
  const Component = isLink ? 'a' : 'button';

  return  (
    <Component
      onClick={onClick}
      className={
        classNames(
          'rounded-full text-lg py-2 px-8 w-full flex items-center justify-center whitespace-nowrap transition-colors',
          disabled ? 'bg-neutral-300 text-neutral-500 cursor-not-allowed' : 'bg-lue-purple-400 hover:bg-lue-purple-500 text-white cursor-pointer',
          className,
        )
      }
    >
      {children}
    </Component>
  );
}
