import { cloneElement, useMemo } from "react";
import parseHtml from 'html-react-parser';
import { v4 as uuid } from 'uuid';
import { ApplyHtmlStylesClassNames, applyHtmlStyles } from "../utils/applyHtmlStyles";

type UseHtmlContentOptions = {
  extraClassNames?: ApplyHtmlStylesClassNames;
};

const isElement = (item: JSX.Element | string): item is JSX.Element => (
  typeof item !== 'string'
);

const parseContent = (
  html?: string,
  { extraClassNames }: UseHtmlContentOptions = {},
) => {
  const parsedHtml = parseHtml(html ?? '');
  const arr = Array.isArray(parsedHtml) ? parsedHtml : [parsedHtml];
  const anchors: { id: string; title: string }[] = [];

  const content = arr
    .map((element) => {
      if (isElement(element) && element.type === 'h2') {
        const id = uuid();

        anchors.push({ id, title: element.props.children })

        return cloneElement(element, { id });
      }

      return element;
    })
    .map((element) => applyHtmlStyles(element, extraClassNames));

  return { content, anchors };
};

export const useHtmlContent = (
  html?: string,
  opts?: UseHtmlContentOptions,
) => {
  return useMemo(() => (
    parseContent(html, opts)
  ), [html]);
};
