import Slider from "react-slider";
import classNames from "classnames";
import { FC, ReactNode } from "react";

import "../styles/slider.css";

type SliderType = {
  min: number;
  max: number;
  step: number;
  title?: string;
  disabled?:boolean
  renderLabel: (v: number) => ReactNode;
  onChange: (v: number[]) => void;
};

const RangeSlider: FC<SliderType> = ({
  min,
  max,
  onChange,
  renderLabel,
  title,
  step,
  disabled,
}) => {
  return (
    <div className='slider--container'>
      <span className='slider-minmax slider-minmax--min'>
        {renderLabel(min)}
      </span>
      <Slider
        disabled={disabled}
        onAfterChange={(v) => {
          onChange(v)
        }}
        thumbClassName='example-thumb'
        trackClassName='example-track'
        max={1000}
        defaultValue={[min, max] as never}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        renderThumb={(props, state) => (
          <div
            {...props}
            className={classNames(props.className, {
              'hide-value': state.valueNow === max || state.valueNow === min,
            })}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={50}
              height={33}
              fill='none'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2 0a2 2 0 0 0-2 2v29a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2v-2h9.856L40 11.928V2a2 2 0 0 0-2-2H2Z'
                fill='#BAB9B9'
              />
            </svg>
            <span className='thumb-value'>{renderLabel(state.valueNow)}</span>
          </div>
        )}
        pearling
        minDistance={step}
      />
      {!!title && <span className='slider-title'>{title}</span>}

      <span className='slider-minmax slider-minmax--max'>
        {renderLabel(max)}
      </span>
    </div>
  )
}

export default RangeSlider;
