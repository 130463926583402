import React from "react";

interface ImgElement{
    id?:string
    className?:string,
    imgSrc:string,
    imgAttribute?:string,



}

const Img = (props: ImgElement) =>{

    return(
        <div id={props.id} className={props.className + "-Container"}>
        <img className={props.className} src={props.imgSrc} alt={props.imgAttribute}></img>

                                        </div>
    )
}

export default Img

//The idea of this component is an easily adjustable img component