import instagramLogo from '../assets/Social/instagram_filled.svg'
import twitterLogo from '../assets/Social/twitter_filled.svg'
import linkedinLogo from '../assets/Social/linkedin_filled.svg'

import twitterLogoDark from '../assets/Social/twitter_dark.svg'
import instagramLogoDark from '../assets/Social/insta_dark.svg'
import linkedinLogoDark from '../assets/Social/linkedin_dark.svg'

type SiteFooterSocialLinksProps = {
  className: string
  isDark?: boolean
}

export const SiteFooterSocialLinks = ({
  className,
  isDark,
}: SiteFooterSocialLinksProps) => (
  <div className={className}>
    <a href='https://www.instagram.com/legalutopia/'>
      <img
        src={isDark ? instagramLogoDark : instagramLogo}
        width='35px'
        height='35px'
        alt='Instagram logo'
      />
    </a>
    <a href='https://twitter.com/ailegalutopia'>
      <img
        src={isDark ? twitterLogoDark : twitterLogo}
        width='35px'
        height='35px'
        alt='Twitter logo'
      />
    </a>
    <a href='https://www.linkedin.com/company/legal-utopia'>
      <img
        src={isDark ? linkedinLogoDark : linkedinLogo}
        width='35px'
        height='35px'
        alt='LinkedIn logo'
      />
    </a>
  </div>
)
