import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseDetailedLawyerProfile = {
  detailedLawyerProfile: AppState['detailedLawyerProfile']
  detailedLawyerProfileLoading: AppState['detailedLawyerProfileLoading']
  detailedLawyerProfileError: AppState['detailedLawyerProfileError']
  getDetailedLawyerProfile: (
    data: RequestTypes['getDetailedLawyerProfile'],
    callbacks?: Callbacks,
  ) => void
}

export default function useDetailedLawyerProfile(): UseDetailedLawyerProfile {
  const {
    detailedLawyerProfile,
    detailedLawyerProfileLoading,
    detailedLawyerProfileError,
  } = useSelector((state: AppState) => ({
    detailedLawyerProfile: state.detailedLawyerProfile,
    detailedLawyerProfileLoading: state.detailedLawyerProfileLoading,
    detailedLawyerProfileError: state.detailedLawyerProfileError,
  }))
  const dispatch = useDispatch()
  const getDetailedLawyerProfile = useCallback(
    (data: RequestTypes['getDetailedLawyerProfile'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getDetailedLawyerProfile(data, callbacks))
    },
    [dispatch],
  )
  return {
    detailedLawyerProfile,
    detailedLawyerProfileLoading,
    detailedLawyerProfileError,
    getDetailedLawyerProfile,
  }
}
