import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseDocumentFilters = {
  documentFilters: AppState['documentFilters']
  documentFiltersLoading: AppState['documentFiltersLoading']
  documentFiltersError: AppState['documentFiltersError']
  getDocumentFilters: (
    data: RequestTypes['getDocumentFilters'],
    callbacks?: Callbacks,
  ) => void
}

export default function useDocumentFilters(): UseDocumentFilters {
  const { documentFilters, documentFiltersLoading, documentFiltersError } =
    useSelector((state: AppState) => ({
      documentFilters: state.documentFilters,
      documentFiltersLoading: state.documentFiltersLoading,
      documentFiltersError: state.documentFiltersError,
    }))
  const dispatch = useDispatch()
  const getDocumentFilters = useCallback(
    (data: RequestTypes['getDocumentFilters'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getDocumentFilters(data, callbacks))
    },
    [dispatch],
  )
  return {
    documentFilters,
    documentFiltersLoading,
    documentFiltersError,
    getDocumentFilters,
  }
}
