import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseLegalDocument = {
  legalDocument?: AppState['legalDocument']
  legalDocumentLoading: AppState['legalDocumentLoading']
  legalDocumentError: AppState['legalDocumentError']
  getLegalDocument: (
    data: RequestTypes['getLegalDocument'],
    callbacks?: Callbacks,
  ) => void
}

export default function useLegalDocument(): UseLegalDocument {
  const { legalDocument, legalDocumentLoading, legalDocumentError } =
    useSelector((state: AppState) => ({
      legalDocument: state.legalDocument,
      legalDocumentLoading: state.legalDocumentLoading,
      legalDocumentError: state.legalDocumentError,
    }))
  const dispatch = useDispatch()
  const getLegalDocument = useCallback(
    (data: RequestTypes['getLegalDocument'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getLegalDocument(data, callbacks))
    },
    [dispatch],
  )
  return {
    legalDocument,
    legalDocumentLoading,
    legalDocumentError,
    getLegalDocument,
  }
}
