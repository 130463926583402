import { lawData } from 'data/lawData'
import { CardCarousel } from '../../CardCarousel'
import { Settings } from 'react-slick'
import { BaseContainer } from 'components/BaseContainer'

const CAROUSEL_DATA = lawData.map((item) => ({
  title: item.title,
  href: `./legalpages?id=${item.id}`,
  img: item.img,
}))

const SLIDER_SETTINGS: Settings = {
  slidesToShow: 5,
  slidesToScroll: 5,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
}

export const HubSection = () => (
  <section className='pt-12 lg:pt-16 pb-20 bg-lue-blue-300 flex flex-col items-center overflow-hidden'>
    <BaseContainer size='md'>
      <div className='text-center mb-6 lg:mb-10'>
        <h2 className='font-bold text-2xl md:text-3xl'>Legal Utopia Hub</h2>
        <p className='mt-6'>
          Build Your Legal Knowledge. Reduce Your Legal Bill.
        </p>
      </div>
      <CardCarousel
        type='overlay'
        data={CAROUSEL_DATA}
        sliderSettings={SLIDER_SETTINGS}
        className='md:px-10 lg:px-16'
      />
    </BaseContainer>
  </section>
)
