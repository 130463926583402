import { Popup } from "reactjs-popup";
import React, { useState } from "react";
import { AiFillInfoCircle } from "react-icons/ai";

import DropDown from "components/base/DropDown";

export const LawyerTypeData = [
  {
    id: "Info-Paragraph-1",
    text: "Barristers",
    infoText:
      "Public access barristers are regulated lawyers who are able to help and advise you on aspects of your case without the need to go to a solicitor",
  },
  {
    id: "Info-Paragraph-2",
    text: "Solicitors",
    infoText:
      "Solicitors are regulated lawyers who can advise you on and manage your legal matters, including conveyancing, helping prepare cases and drafting legal documents",
  },
];

interface LawyerTypeDropdownProps {
  handleSelect: (type: string) => void;
  initialValue?: string;
  disabled?: boolean
}

const LawyerTypeDropdown: React.FC<LawyerTypeDropdownProps> = ({
  handleSelect,
  initialValue,
  disabled
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(initialValue ?? null);

  const toggleDropdown = () => {
      if(disabled) return
    setIsDropdownVisible(!isDropdownVisible);
  };

  const onSelect = (lawyerType: any) => {
    setIsDropdownVisible(false);
    setSelectedItem(lawyerType);
    handleSelect(lawyerType);
  };

  return (
    <>
      <div
        className='w-full sm:pb-2 h-auto'
        onClick={toggleDropdown}
        id='searchTypeOfLawyer'
      >
        {selectedItem ? (
          <span className='text-black'> {selectedItem}</span>
        ) : (
          <span className='font-light text-[#808080]'>Select...</span>
        )}
      </div>
      <DropDown
        onClick={toggleDropdown}
        className='Input-Data flex flex-col px-4 p-2 mt-20 w-auto rounded-3xl text-black z-10 absolute leading-8 bg-white border-2 border-neutral-100 '
        display={isDropdownVisible}
      >
        {LawyerTypeData.map((data) => (
          <li className='flex flex-row' key={data.id}>
            <button onClick={() => onSelect(data.text)} className='w-full flex'>
              {data.text}

              <Popup
                trigger={
                  <div>
                    <AiFillInfoCircle
                      color={'#20BFE7'}
                      size={16}
                      className='ml-1'
                    />
                  </div>
                }
                position='right bottom'
                on={['hover', 'focus']}
                arrow={true}
              >
                <div
                  className={`inline-block text-sm font-light text-white rounded-lg border border-transparent shadow-sm max-w-[16rem] p-2 bg-[#20BFE7]`}
                >
                  <p>{data.infoText}</p>
                </div>
              </Popup>
            </button>
          </li>
        ))}
      </DropDown>
    </>
  )
};

export default LawyerTypeDropdown;
