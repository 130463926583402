import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseMembershipRedemption = {
  membershipRedemption: AppState['membershipRedemption']
  membershipRedemptionLoading: AppState['membershipRedemptionLoading']
  membershipRedemptionSaving: AppState['membershipRedemptionSaving']
  membershipRedemptionError: AppState['membershipRedemptionError']
  createMembershipRedemption: (
    data: RequestTypes['membershipRedemption'],
    callbacks?: Callbacks,
  ) => void
}

export default function useMembershipRedemption(): UseMembershipRedemption {
  const {
    membershipRedemption,
    membershipRedemptionLoading,
    membershipRedemptionSaving,
    membershipRedemptionError,
  } = useSelector((state: AppState) => ({
    membershipRedemption: state.membershipRedemption,
    membershipRedemptionLoading: state.membershipRedemptionLoading,
    membershipRedemptionSaving: state.membershipRedemptionSaving,
    membershipRedemptionError: state.membershipRedemptionError,
  }))
  const dispatch = useDispatch()

  const createMembershipRedemption = useCallback(
    (data: RequestTypes['membershipRedemption'], callbacks?: Callbacks) => {
      return dispatch(AppActions.createMembershipRedemption(data, callbacks))
    },
    [dispatch],
  )

  return {
    membershipRedemption,
    membershipRedemptionLoading,
    membershipRedemptionSaving,
    membershipRedemptionError,
    createMembershipRedemption,
  }
}
