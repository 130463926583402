import cn from 'classnames'
import { ReactNode } from 'react'

type RoundIconProps = {
  src?: string
  children?: ReactNode
  size?: number
  iconSize?: number
  className?: string
}

export const RoundIcon = ({
  src,
  size = 53,
  iconSize = 24,
  className,
  children,
}: RoundIconProps) => (
  <div
    className={cn('rounded-full flex items-center justify-center', className)}
    style={{
      width: size,
      height: size,
      minWidth: size,
      minHeight: size,
    }}
  >
    {!!src && (
      <img
        className='absolute'
        src={src}
        height={iconSize}
        width={iconSize}
        alt=''
      />
    )}
    {children}
  </div>
)
