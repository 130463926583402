import React from 'react'
import VerifyMFAForm from '../../../../common/components/Authentication/VerifyMfaForm'
import { FormPageWrapper } from 'common/components/Authentication/FormPageWrapper'
import { MfaQrCode } from './MfaQrCode'

const SetupMFAPage = () => {
  const { settings } = Utils.fromParam()

  const onContinue = () => {
    window.location.href = '/'
  }

  return (
    <FormPageWrapper title='Two-Factor Authentication'>
      <div className='flex flex-col items-center'>
        <MfaQrCode />
        <VerifyMFAForm
          key='verifyMFAFormKey'
          mfaSetup
          onSuccess={onContinue}
          onSkip={settings ? undefined : onContinue}
        />
        {!!settings && (
          <Link href='/settings'>
            <a className='block mt-4 text-lg'>Back</a>
          </Link>
        )}
      </div>
    </FormPageWrapper>
  )
}

export default SetupMFAPage
