import { useAuth } from '../../providers/useAuth'
import cn from 'classnames'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { Auth } from 'aws-amplify'
import { ComponentType, SVGProps ,ButtonHTMLAttributes} from 'react'

type SocialSignInButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &{
  children: string
  provider: CognitoHostedUIIdentityProvider
  Icon: ComponentType<SVGProps<SVGSVGElement>>
}

export const SocialSignInButton = ({
  provider,
  children,
  Icon,
  className
}: SocialSignInButtonProps) => {
  const { oauth } = useAuth()

  const onClick = () => {
    oauth({
      authorize: async () => Auth.federatedSignIn({ provider }),
    })
  }

  return (
    <button
      onClick={onClick}
      type='button'
      className={cn(
      'border  text-black text-base font-medium py-2 px-8 whitespace-nowrap mb-4 rounded-[15px] bg-white shadow-[0px_2px_4px_0px_rgba(0,0,0,0.25)]',
      className,
    )}
    >
      <div className='flex flex-row item-center justify-center'>
        <div className='w-[14px] mr-2 flex items-center'>
          <Icon width='14' />
        </div>
        <span>{children}</span>
      </div>
    </button>
  )
}
