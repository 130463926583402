import { ButtonHTMLAttributes } from 'react'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { SocialSignInButton } from './SocialSignInButton'
import { GoogleIcon } from '../Icons/GoogleIcon'
import { AppleIcon } from '../Icons/AppleIcon'

type SocialSignInButtons = ButtonHTMLAttributes<HTMLButtonElement> & {
  secondary?: boolean
}

export const SocialSignInButtons = ({className}:SocialSignInButtons) => (
  <div className='flex flex-col'>
    <SocialSignInButton
      provider={CognitoHostedUIIdentityProvider.Google}
      Icon={GoogleIcon}
      className={className}
    >
      Sign In with Google
    </SocialSignInButton>

    <SocialSignInButton
      provider={CognitoHostedUIIdentityProvider.Apple}
      Icon={AppleIcon}
      className={className}
    >
      Sign In with Apple
    </SocialSignInButton>
  </div>
)
