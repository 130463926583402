type OverlayCardProps = {
  title: string;
  href: string;
  img: string;
  imgPosition?: string;
}

export const OverlayCard = ({
  title,
  href,
  imgPosition,
  img,
}: OverlayCardProps) => (
  <a
    draggable={false} // prevent link dragging
    href={href}
    className="relative bg-cover bg-center aspect-[2/3] rounded-lg overflow-hidden block select-none bg-neutral-200"
    style={{
      backgroundPosition: imgPosition,
      backgroundImage: `url('${img}')`,
    }}>
      <div className="bg-black opacity-30 h-full w-full" />
      <div className="absolute z-10 bottom-0 px-4 pb-5 max-w-full text-white">
        <h3 className="font-bold text-xl lg:text-2xl">{title}</h3>
        <p className="font-bold text-sm tracking-wide">MORE INFO</p>
      </div>
  </a>
);
