import { RiArrowUpDownLine } from 'react-icons/ri'
import { FaSearch, FaRegMap } from 'react-icons/fa'

import up from 'assets/FAL/up.png'
import IconButton from './IconButton'
import down from 'assets/FAL/down.png'
import React, { useState } from 'react'
import Format from 'common/utils/format'
import { SortBy } from 'common/state-type'
import { Button } from 'components/base/Button'
import RangeSlider from 'components/RangeSlider'
import LawyerTypeDropdown from './LawyerTypeDropdown'
import AreasOfPracticeSelect from 'components/AreasOfPracticeSelect'
import { AuthorisationPeriodData, RadiusData } from 'data/DropdownData'
import { FilterOption, FilterOptionSelect, RadioButton } from './FilterOption'

export enum lawyerType {
  BARRISTERS = 'barristers',
  SOLICITORS = 'solicitors',
  OFFICES = 'offices',
}
const mileToKm = 1.60934 // mile to km
const defaultSelectedRadius = RadiusData[5]
const defaultSelectedAuthPeriod = AuthorisationPeriodData[3]

interface FilterSectionProps {
  initialFilterParams: SearchParam
  toggleMapView?: () => void
  mapViewActive?: boolean
  handleSearch: (searchParams: SearchParam) => void
  handleClearSearch: () => void
  isLoading: boolean
}

enum FilterType {
  LAWYER = 'lawyer',
  LAWFIRM = 'lawfirm',
}

export type SearchParam = {
  location?: string
  radius?: number
  searchType?: lawyerType
  lawArea?: string[]
  authorisationPeriod?: number
  priceFilter?: number[]
  sort_by?: SortBy
  sort_direction?: 'ASC' | 'DESC'
}
export const FilterSection: React.FC<FilterSectionProps> = ({
  initialFilterParams,
  isLoading,
  toggleMapView,
  mapViewActive,
  handleSearch,
  handleClearSearch,
}) => {
  const [showSearchbar, setShowSearchBar] = useState(true)
  const [filterType, setFilterType] = useState<FilterType>(
    initialFilterParams.searchType === lawyerType.OFFICES
      ? FilterType.LAWFIRM
      : FilterType.LAWYER
  )

  const [searchParams, setSearchParams] =
    useState<SearchParam>(initialFilterParams)

  const areasOfPracticeStyle = {
    control: (provided) => ({
      ...provided,
      minWidth: 120,
      minHeight: 42,
      marginTop: 4,
      marginBottom: 4,
      borderRadius: 8,
    }),
  }

  const onClearSearch = () => {
    setSearchParams(initialFilterParams)
    setFilterType(FilterType.LAWYER)
    handleClearSearch()
  }

  const handleOnChange = (key: string, value: any) => {
    setSearchParams({
      ...searchParams,
      [key]: value,
    })
    handleSearch({
      ...searchParams,
      [key]: value,
    })
  }

  return (
    <>
      <div className='md:hidden'>
        <div className='flex flex-1 w-full gap-x-4 justify-end pb-2'>
          <IconButton
            Icon={FaSearch}
            size={16}
            color={showSearchbar ? 'white' : null}
            backgroundColor={showSearchbar && '#20BFE7'}
            onClick={() => setShowSearchBar(!showSearchbar)}
          />
          {/* <IconButton Icon={RiArrowUpDownLine} size={16} onClick={() => {}} /> */}
          <IconButton
            Icon={FaRegMap}
            size={16}
            color={mapViewActive ? 'white' : null}
            backgroundColor={mapViewActive && '#20BFE7'}
            onClick={toggleMapView}
          />
        </div>
        <div className='bg-gray-200 w-full p-2'>
          <div>
            <Button onClick={() => setShowSearchBar(!showSearchbar)}>
              <img src={showSearchbar ? down : up} className='h-3 w-4 mb-2' />
            </Button>
          </div>
          <div className='flex w-full flex-col'>
            {showSearchbar && (
              <div className='px-6 justify-center items-center flex flex-col'>
                <div className='flex flex-row gap-8 mb-2'>
                  <RadioButton
                    id='filter-radio-1'
                    disabled={isLoading}
                    label='Lawyer Search'
                    onChange={(e: any) => {
                      setFilterType(FilterType.LAWYER)
                      setSearchParams({
                        ...searchParams,
                        searchType: lawyerType.BARRISTERS,
                      })
                    }}
                    defaultChecked={initialFilterParams.searchType !== lawyerType.OFFICES}
                  />
                  <RadioButton
                    id='filter-radio-1'
                    disabled={isLoading}
                    label='Law Firm Search'
                    onChange={(e: any) => {
                      setFilterType(FilterType.LAWFIRM)
                      setSearchParams({
                        ...searchParams,
                        searchType: lawyerType.OFFICES,
                      })
                    }}
                    defaultChecked={initialFilterParams.searchType === lawyerType.OFFICES}
                  />
                </div>
                <div className='grid grid-cols-2 gap-1 mb-4'>
                  <FilterOption
                    className='rounded-tl-lg'
                    title='Location'
                    inputElement={
                      <input
                        disabled={isLoading}
                        autoComplete='false'
                        className='my-1 border-none focus:ring-0 text-black p-0 w-[100%]'
                        onChange={(e) => {
                          setSearchParams({
                            ...searchParams,
                            location: e.target.value,
                          })
                        }}
                        value={searchParams.location}
                        type='text'
                      />
                    }
                  />

                  <FilterOption
                    className='rounded-tr-lg'
                    title='Search Radius'
                    inputElement={
                      <FilterOptionSelect
                        disabled={isLoading}
                        data={RadiusData}
                        onSelect={(data) => {
                          setSearchParams({
                            ...searchParams,
                            radius: data.value * mileToKm,
                          })
                        }}
                        defaultValue={defaultSelectedRadius}
                      />
                    }
                  />
                  {filterType === FilterType.LAWYER && (
                    <FilterOption
                      className='rounded-bl-lg'
                      title='Lawyer Type'
                      inputElement={
                        <LawyerTypeDropdown
                          disabled={isLoading}
                          handleSelect={(value) => {
                            setSearchParams({
                              ...searchParams,
                              searchType:
                                value.toLocaleLowerCase() as lawyerType,
                            })
                          }}
                          initialValue={Format.camelCase(
                            searchParams.searchType,
                          )}
                        />
                      }
                    />
                  )}
                  {filterType === FilterType.LAWFIRM && (
                    <FilterOption
                      className='rounded-bl-lg'
                      title='Authorisation Period'
                      inputElement={
                        <FilterOptionSelect
                          disabled={isLoading}
                          data={AuthorisationPeriodData}
                          onSelect={(data) => {
                            setSearchParams({
                              ...searchParams,
                              authorisationPeriod: data.value,
                            })
                          }}
                          defaultValue={defaultSelectedAuthPeriod}
                        />
                      }
                    />
                  )}
                  <FilterOption
                    className='rounded-br-lg'
                    title='Law Area'
                    inputElement={
                      <AreasOfPracticeSelect
                        disabled={isLoading}
                        styles={areasOfPracticeStyle}
                        value={searchParams.lawArea}
                        onChange={(areas) =>
                          setSearchParams({ ...searchParams, lawArea: areas })
                        }
                      />
                    }
                  />
                </div>

                <RangeSlider
                  disabled={isLoading}
                  onChange={(v) => {
                    setSearchParams({ ...searchParams, priceFilter: v })
                  }}
                  renderLabel={(v) => `£${v}`}
                  min={0}
                  max={1000}
                  step={10}
                />
                <button
                  disabled={isLoading}
                  className='bg-basic-blue text-white rounded-lg px-4 py-2 w-full'
                  onClick={() => handleSearch(searchParams)}
                >
                  {isLoading ? 'Searching...' : 'Confirm'}
                </button>
                <button
                  disabled={isLoading}
                  onClick={onClearSearch}
                  className='text-basic-blue px-4 py-2 mt-2'
                >
                  Clear Search
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='w-full p-2 flex-col hidden md:flex bg-[#F0F0F0] pb-4'>
        <Button onClick={() => setShowSearchBar(!showSearchbar)}>
          <img src={showSearchbar ? down : up} className='h-3 w-4 mb-2' />
        </Button>
        {showSearchbar && (
          <div className='flex flex-wrap gap-y-2'>
            <div className='space-y-2 mr-2'>
              <RadioButton
                id='filter-radio-2'
                disabled={isLoading}
                label='Lawyer Search'
                onChange={(e: any) => {
                  setFilterType(FilterType.LAWYER)
                  handleOnChange('searchType', lawyerType.BARRISTERS)
                }}
                defaultChecked={initialFilterParams.searchType !== lawyerType.OFFICES}
              />
              <RadioButton
                id='filter-radio-2'
                disabled={isLoading}
                label='Law Firm Search'
                onChange={(e: any) => {
                  setFilterType(FilterType.LAWFIRM)
                  handleOnChange('searchType', lawyerType.OFFICES)
                }}
                defaultChecked={initialFilterParams.searchType === lawyerType.OFFICES}
              />
            </div>
            <FilterOption
              title='Location'
              inputElement={
                <input
                  disabled={isLoading}
                  autoComplete='false'
                  className='my-1 border-none focus:ring-0 text-black p-0 w-[100%]'
                  onBlur={(e) => {
                    handleSearch({
                      ...searchParams,
                      location: e.target.value,
                    })
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.currentTarget.blur()
                    }
                  }}
                  onChange={(e) => {
                    setSearchParams({
                      ...searchParams,
                      location: e.target.value,
                    })
                  }}
                  value={searchParams.location}
                  type='text'
                />
              }
            />
            <FilterOption
              title='Search Radius'
              inputElement={
                <FilterOptionSelect
                  disabled={isLoading}
                  data={RadiusData}
                  onSelect={(data) => {
                    handleOnChange('radius', data.value * mileToKm)
                  }}
                  defaultValue={defaultSelectedRadius}
                />
              }
            />
            {filterType === FilterType.LAWYER && (
              <FilterOption
                title='Lawyer Type'
                inputElement={
                  <LawyerTypeDropdown
                    disabled={isLoading}
                    handleSelect={(value) => {
                      handleOnChange('searchType', value.toLocaleLowerCase())
                    }}
                    initialValue={Format.camelCase(searchParams.searchType)}
                  />
                }
              />
            )}
            {filterType === FilterType.LAWFIRM && (
              <FilterOption
                title='Authorisation Period'
                inputElement={
                  <FilterOptionSelect
                    data={AuthorisationPeriodData}
                    onSelect={(data) => {
                      handleOnChange('authorisationPeriod', data.value)
                    }}
                    defaultValue={defaultSelectedAuthPeriod}
                  />
                }
              />
            )}
            <FilterOption
              title='Law Area'
              inputElement={
                <AreasOfPracticeSelect
                  disabled={isLoading}
                  styles={areasOfPracticeStyle}
                  value={searchParams.lawArea}
                  onChange={(areas) => {
                    handleOnChange('lawArea', areas)
                  }}
                />
              }
            />
            <div className='flex flex-1 place-content-center col-span-2'>
              <RangeSlider
                disabled={isLoading}
                onChange={(v) => {
                  handleOnChange('priceFilter', v)
                }}
                title={
                  filterType === FilterType.LAWFIRM
                    ? 'Median Regional Cost (ex. VAT)'
                    : 'Rate  Per Hour (ex. VAT)'
                }
                renderLabel={(v) => `£${v}`}
                min={0}
                max={1000}
                step={10}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
