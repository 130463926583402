import { SVGProps } from 'react'

export const GoogleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='14'
    height='15'
    viewBox='0 0 14 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g>
      <path
        d='M3.07506 6.19625C3.34799 5.37051 3.87473 4.65206 4.58013 4.14338C5.28553 3.63471 6.13355 3.3618 7.00323 3.36358C7.98906 3.36358 8.8804 3.71358 9.5804 4.28642L11.6174 2.25C10.3761 1.16792 8.78531 0.5 7.00323 0.5C4.24406 0.5 1.86873 2.07383 0.726562 4.37917L3.07506 6.19625Z'
        fill='#EA4335'
      />
      <path
        d='M9.35384 11.0078C8.718 11.4179 7.91067 11.6367 6.99717 11.6367C6.13096 11.6384 5.28614 11.3677 4.5823 10.8628C3.87847 10.3579 3.3513 9.64434 3.07542 8.82324L0.71875 10.6123C1.29698 11.7827 2.19182 12.7674 3.30165 13.4546C4.41147 14.1419 5.69179 14.5042 6.99717 14.5002C8.70808 14.5002 10.3426 13.8918 11.567 12.7502L9.35442 11.0078H9.35384Z'
        fill='#34A853'
      />
      <path
        d='M11.5698 12.7504C12.8503 11.5557 13.6815 9.77771 13.6815 7.50037C13.6815 7.08621 13.6179 6.64112 13.5228 6.22754H7V8.93246H10.7543C10.5694 9.84187 10.0718 10.546 9.35725 11.008L11.5698 12.7504Z'
        fill='#4A90E2'
      />
      <path
        d='M3.07832 8.82274C2.93561 8.39627 2.86311 7.94945 2.86365 7.49974C2.86365 7.04357 2.93657 6.60549 3.0719 6.19599L0.723401 4.37891C0.242955 5.34881 -0.0047133 6.41738 6.79318e-05 7.49974C6.79318e-05 8.61974 0.259651 9.67557 0.721651 10.6118L3.07832 8.82274Z'
        fill='#FBBC05'
      />
    </g>
  </svg>
)
