import { LegalDocuments } from './CWP Sections/home/LegalDocuments'
import { SiteFooter } from './SiteFooter'

const LegalDocumentsPage = () => (
  <>
    <LegalDocuments isStandalonePage pageSize={12} />
    <SiteFooter />
  </>
)

export default LegalDocumentsPage
