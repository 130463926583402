import googlePlayStoreBadgeLight from "../../assets/AppStore/google-play-store-badge-light.svg";
import googlePlayStoreBadgeDark from "../../assets/AppStore/google-play-store-badge-dark.svg";

type GoogleAppStoreButtonProps = {
  className?: string;
  theme?: 'light' | 'dark';
}

export const GoogleAppStoreButton = ({
  className,
  theme ='light',
}: GoogleAppStoreButtonProps) => (
  <a
    className={className}
    aria-label="Get it on Google Play"
    href="https://play.google.com/store/apps/details?id=com.vrgsoft.utopia&hl=en_GB&gl=US">
    <img
      src={theme === 'light' ? googlePlayStoreBadgeLight : googlePlayStoreBadgeDark}
      width="150px"
      height="50px"
      alt="Google Play Store logo"
    />
  </a>
);
