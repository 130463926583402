import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseLawyerAvailability = {
  lawyerAvailability: AppState['lawyerAvailability']
  lawyerAvailabilityLoading: AppState['lawyerAvailabilityLoading']
  lawyerAvailabilityError: AppState['lawyerAvailabilityError']
  getLawyerAvailability: (
    data: RequestTypes['getLawyerAvailability'],
    callbacks?: Callbacks,
  ) => void
}

export default function useLawyerAvailability(): UseLawyerAvailability {
  const {
    lawyerAvailability,
    lawyerAvailabilityLoading,
    lawyerAvailabilityError,
  } = useSelector((state: AppState) => ({
    lawyerAvailability: state.lawyerAvailability,
    lawyerAvailabilityLoading: state.lawyerAvailabilityLoading,
    lawyerAvailabilityError: state.lawyerAvailabilityError,
  }))
  const dispatch = useDispatch()
  const getLawyerAvailability = useCallback(
    (data: RequestTypes['getLawyerAvailability'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getLawyerAvailability(data, callbacks))
    },
    [dispatch],
  )
  return {
    lawyerAvailability,
    lawyerAvailabilityLoading,
    lawyerAvailabilityError,
    getLawyerAvailability,
  }
}
