import React, { useContext, useState } from 'react'
import { useAuth } from '../../providers/useAuth'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { SocialSignInButtons } from './SocialSignInButtons'
import { InputGroup } from '../Inputs/InputGroup'
import { FormPageWrapper } from './FormPageWrapper'
import { Button } from './Button'
import { FooterText } from './FooterText'
import { FormMessage } from './FormMessage'
import { PasswordInputGroup } from '../Inputs/PasswordInputGroup'
import { LinkContext } from '../../providers/LinkProvider'
import { AuthPageContext } from '../../providers/AuthPageProvider'
import { AppState } from 'common/state-type'
import { encodeAuthState } from 'common/utils/auth'
import VerifyMFAForm from './VerifyMfaForm'
import { CheckboxGroup } from '../Inputs/CheckboxGroup'

type LoginPageProps = {
  onSuccess?: (data: any) => void
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Email must be valid')
    .required('Please enter an email connected to an account'),
  password: yup.string().required('Please enter the password'),
})

const LoginPage = ({ onSuccess }: LoginPageProps) => {
  const { hasSocialSignIn } = useContext(AuthPageContext)
  const { login, userLoading } = useAuth()
  const [userError, setError] = useState<boolean>(false)
  const [requiresMfa, setRequiresMfa] = useState<boolean>(false)
  const { Link } = useContext(LinkContext)
  const { handleBlur, handleSubmit, handleChange, errors, setTouched, values } =
    useFormik({
      validateOnBlur: false,
      validateOnChange: false,
      initialValues: {
        email: '',
        password: '',
        rmrPasswords: false,
      },
      validationSchema: schema,
      onSubmit: (values) => {
        login(
          {
            username: values.email,
            password: values.password,
          },
          {
            onSuccess: (cognitoUser: AppState['user']) => {
              if (cognitoUser?.challengeName === 'NEW_PASSWORD_REQUIRED') {
                document.location = `${
                  document.location.origin
                }/reset-password?email=${encodeURIComponent(values.email)}`

                return
              }

              if (cognitoUser?.challengeName === 'SOFTWARE_TOKEN_MFA') {
                setRequiresMfa(true)

                return
              }

              onSuccess?.(cognitoUser)
            },
            onError: (e) => {
              if (e?.includes('not confirmed')) {
                document.location = `${
                  document.location.origin
                }/verify-email?state=${encodeAuthState({
                  email: values.email,
                  password: values.password,
                })}`

                return
              }

              // MFA verification required
              if (e?.includes('not authenticated')) {
                return
              }

              setError(e)
            },
          },
        )
      },
    })

  const onSubmit = (e: any) => {
    const newTouched = {}
    Object.keys(errors).map((v) => {
      newTouched[v] = true
    })
    setTouched(newTouched)
    handleSubmit(e)
  }

  if (requiresMfa) {
    return (
      <FormPageWrapper title='Two-Factor Authentication'>
        <div className='flex flex-col items-center'>
          <VerifyMFAForm onSuccess={API.loginRedirect} />
        </div>
      </FormPageWrapper>
    )
  }

  return (
    <FormPageWrapper className={'w-full md:w-[84%] '} title='Sign in'>
      <FormMessage type='error'>{userError}</FormMessage>
      <form onSubmit={onSubmit} className='mt-10'>
      <InputGroup
  id='email'
  label='Email'
  name='email'
  theme='grey'
  isLabelVisible
  isNew
  onChange={handleChange}
  onBlur={handleBlur}
  value={values.email.trim()}
  error={errors.email}
  placeholder='Email Address'
  className={` mt-5 mx-auto `}
/>
        <PasswordInputGroup
          id='password'
          label='Password'
          name='password'
          theme='grey'
          isLabelVisible
          isNew
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          error={errors.password}
          className={` mt-5 mx-auto `}
          type='password'
          autoComplete='current-password'
          placeholder='Password'
        />
        <div className='flex flex-row mb-8  items-center justify-between mt-2  mx-auto sm:(space-x-4 flex-col) md:space-x-6'>
          <CheckboxGroup
            id='rmrPasswords'
            label={
              <span className='text-right text-[#6E7785] text-sm'>
                Remember my password
              </span>
            }
            name='rmrPasswords'
            onBlur={handleBlur}
            checked={values.rmrPasswords}
            onChange={handleChange}
          />

          <div className='text-right '>
            <Link
              href='/forgot-password'
              className='cursor pointer text-[#5858DF]'
            >
              <span className='text-sm'> Forgotten password</span>
            </Link>
          </div>
        </div>

        <Button
          type='submit'
          className={' !w-[40%] mt-10 !mx-auto'}
          disabled={userLoading}
        >
          Login
        </Button>
      </form>

      <div className='mt-4'>
        {hasSocialSignIn && (
          <>
            <p className='text-base font-normal text-center text-primary-font !py-4  mb-4 !leading-6 '>or</p>
            <SocialSignInButtons className={'w-full mx-auto'} />
          </>
        )}
        <FooterText
          text="Don't have an account?"
          linkHref='/signup'
          linkText='Sign Up'
        />
      </div>
    </FormPageWrapper>
  )
}

export default LoginPage
