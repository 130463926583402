import { ReactNode, useEffect, useRef, useState } from 'react'
import { InfoIconPopup } from '../Popups/InfoIconPopup'
import cn from 'classnames'

export interface DropdownProps {
  onSelect?: (value?: string) => void
  isVisible: boolean
  onClickOutside: () => void
  options: {
    label: string
    value: string
    infoText?: string
    isLink?: boolean
    icon?: string
  }[]
  isLarge?: boolean
  children?: ReactNode
  className?: string
}

export const Dropdown = ({
  onSelect,
  isVisible,
  onClickOutside,
  options,
  isLarge,
  children,
  className,
}: DropdownProps) => {
  const [openPopup, setOpenPopup] = useState<number | null>(null)
  const ref = useRef<HTMLDivElement>()

  const onPopupTriggerClick = (evt) => {
    // Prevent click of the tooltip from closing the dropdown
    evt.preventDefault()
    evt.stopPropagation()
  }

  useEffect(() => {
    if (!ref.current || !isVisible) {
      return
    }

    const handler = (evt) => {
      if (ref.current?.contains(evt.target)) {
        return
      }

      onClickOutside()
    }

    document.addEventListener('click', handler)
    document.addEventListener('touchstart', handler)

    return () => {
      document.removeEventListener('click', handler)
      document.removeEventListener('touchstart', handler)
    }
  }, [isVisible, onClickOutside])

  if (!isVisible) {
    return null
  }

  return (
    <div
      ref={ref}
      className={cn(
        'min-w-full flex flex-col p-2 rounded-lg text-b33 z-10 absolute top-[120%] bg-white text-lg shadow-[0_0_14px_0_rgba(0,0,0,0.15)] lg:shadow-[0_4px_4px_0_rgba(0,0,0,0.15)] rounded-[10px] max-h-[300px] overflow-y-auto',
        isLarge ? 'lg:rounded-t-none lg:top-[80%]' : '',
        className,
      )}
    >
      <ul>
        {options.length ? (
          options.map((data, i) => {
            const Component = data.isLink ? 'a' : 'button'

            return (
              <li className='flex flex-row' key={data.label}>
                <Component
                  href={data.isLink ? data.value : undefined}
                  className={cn(
                    'w-full flex items-center py-1 px-2 text-left font-normal select-none',
                    isLarge ? 'text-lg' : 'text-base',
                  )}
                  onClick={
                    data.isLink
                      ? undefined
                      : (evt) => {
                          evt.preventDefault()
                          onSelect?.(data.value)
                        }
                  }
                >
                  {data.icon && (
                    <img
                      src={data.icon}
                      className='w-6 h-6 mr-2 text-lue-gray-500'
                    />
                  )}
                  {data.label}
                  {data.infoText && (
                    <InfoIconPopup
                      text={data.infoText}
                      onOpen={() => {
                        setOpenPopup(i)
                      }}
                      onClose={() => {
                        setOpenPopup(null)
                      }}
                      isOpen={openPopup === i}
                      onTriggerClick={onPopupTriggerClick}
                    />
                  )}
                </Component>
              </li>
            )
          })
        ) : (
          <div className='flex items-center justify-center text-sm'>
            No options
          </div>
        )}
      </ul>
      {children}
    </div>
  )
}
