import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseIndustries = {
  industries: AppState['industries']
  industriesLoading: AppState['industriesLoading']
  industriesError: AppState['industriesError']
  getIndustries: (
    data: RequestTypes['getIndustries'],
    callbacks?: Callbacks,
  ) => void
}

export default function useIndustries(): UseIndustries {
  const { industries, industriesLoading, industriesError } = useSelector(
    (state: AppState) => ({
      industries: state.industries,
      industriesLoading: state.industriesLoading,
      industriesError: state.industriesError,
    }),
  )
  const dispatch = useDispatch()
  const getIndustries = useCallback(
    (data: RequestTypes['getIndustries'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getIndustries(data, callbacks))
    },
    [dispatch],
  )
  return {
    industries,
    industriesLoading,
    industriesError,
    getIndustries,
  }
}
