import QRCode from "react-qr-code";
import cn from 'classnames';

type GetTheAppQrCodeProps = {
  size: number;
  className?: string;
}

// Currently the app is only being promoted on iOS. When it is properly supported
// on Android then change this to /get-the-app (assuming that page still exists then).
const GET_THE_APP_URL = 'https://apps.apple.com/gb/app/id1487650991?mt=8';

export const GetTheAppQrCode = ({
  size,
  className,
}: GetTheAppQrCodeProps) => (
  <div
    className={cn('flex', className)}>
    <div className="p-1 bg-white">
      <QRCode
        size={size}
        value={GET_THE_APP_URL}
        viewBox={`0 0 ${size} ${size}`}
      />
    </div>
  </div>
)
