import { BaseContainer } from 'components/BaseContainer'
import SearchBar from '../../search/SearchBar'
import { GetTheAppButton } from 'components/AppStore/GetTheAppButton'
import { ContactBar } from 'components/ContactBar'

const STATS = ['6,449 Barristers', '27,228 Solicitors', '9,931 Law Firms']

export const HeroSection = () => (
  <header className='py-16 bg-hero bg-cover bg-bottom'>
    <BaseContainer
      size='sm'
      className='flex flex-col items-center justify-center'
    >
      <h1 className='text-lue-blue-700 text-5xl xl:text-6xl font-bold text-center max-w-[300px] xl:max-w-none leading-tight'>
        Find Your Lawyer,{' '}
        <span className='text-lawBlue font-bold inline-block'>Fast</span>
      </h1>

      <ul className='mt-10 [column-count:3] [column-rule:solid]'>
        {STATS.map((stat) => {
          const [num, ...words] = stat.split(' ')

          return (
            <li
              key={stat}
              className='text-lg xl:text-2xl font-medium text-lue-blue-700 py-1 px-3 xl:px-8 xl:py-4 text-center flex flex-col xl:block'
            >
              <span>{num}</span> <span>{words.join(' ')}</span>
            </li>
          )
        })}
      </ul>

      <SearchBar />
      <p className='text-lue-blue-700 font-bold text-xl mt-6 lg:mt-12 '>
        Need help with your search?
      </p>
      <ContactBar className='mt-6 lg:mt-8' />
      <GetTheAppButton className='mt-6 lg:hidden' />
    </BaseContainer>
  </header>
)
