import { useCallback, useEffect, useMemo, useState } from 'react'
import useLegalDocument from '../providers/useLegalDocument'
import useProfile from '../providers/useProfile'
import useLegalDocumentCode from '../providers/useLegalDocumentCode'
import useMembershipRedemption from '../providers/useMembershipRedemption'

type Status = 'ready' | 'preparing' | 'no-file' | 'retries-exceeded'

const MAX_RETRIES = 5
const RETRY_INTERVAL = 3000
const MESSAGES: Record<Status, string> = {
  'retries-exceeded': 'Purchase required',
  preparing: 'Preparing your document...',
  ready: 'Your document is ready to download.',
  'no-file': 'No file available.',
}

const DESCRIPTIONS: Partial<Record<Status, string>> = {
  'retries-exceeded':
    'You must purchase this document before you can download it.',
}

export const useLegalDocumentDownload = (
  id: string,
  productIdKey?: 'stripe_product_id' | 'ios_product_id' | 'android_product_id',
  onSuccess?: (fileUrl: string) => void,
) => {
  const { legalDocument, getLegalDocument, legalDocumentError } =
    useLegalDocument()

  const { legalDocumentCode, getLegalDocumentCode } = useLegalDocumentCode()
  const { createMembershipRedemption } = useMembershipRedemption()
  const { profile } = useProfile()
  const [retries, setRetries] = useState<number>(0)

  const retriesExceeded = useMemo(() => retries >= MAX_RETRIES, [retries])
  const productId = useMemo(
    () => legalDocument?.type?.[productIdKey],
    [legalDocument, productIdKey],
  )

  const loadLegalDocumentCode = useCallback(() => {
    getLegalDocumentCode(
      { id },
      {
        onError: () => {
          if (retriesExceeded) {
            return
          }

          // If there is an error it is possible that the webhook callback
          // that generates the code has not been processed yet, in which case
          // poll every few seconds until it is ready.
          setTimeout(() => {
            setRetries((previousValue) => previousValue + 1)
            loadLegalDocumentCode()
          }, RETRY_INTERVAL)
        },
      },
    )
  }, [id, retriesExceeded, getLegalDocumentCode])

  useEffect(() => {
    getLegalDocument({ id })

    if (productId) {
      loadLegalDocumentCode()
    }
  }, [getLegalDocument, loadLegalDocumentCode, id, profile?.id, productId])

  const onDownloadClick = useCallback(() => {
    if (!legalDocument?.file_url) {
      return
    }

    if (!productId) {
      onSuccess(legalDocument.file_url)

      return
    }

    if (!legalDocumentCode) {
      throw new Error('No legal document code')
    }

    createMembershipRedemption(legalDocumentCode, {
      onSuccess: () => {
        onSuccess(legalDocument.file_url)
        loadLegalDocumentCode()
      },
      onError: () => {
        loadLegalDocumentCode()
      },
    })
  }, [
    legalDocument,
    legalDocumentCode,
    createMembershipRedemption,
    onSuccess,
    loadLegalDocumentCode,
    productId,
  ])

  const status = useMemo((): Status => {
    if (legalDocumentError || (legalDocument && !legalDocument?.file_url)) {
      return 'no-file'
    }

    if (legalDocument && !legalDocument?.type?.[productIdKey]) {
      return 'ready'
    }

    if (retriesExceeded) {
      return 'retries-exceeded'
    }

    if (legalDocumentCode?.code) {
      return 'ready'
    }

    return 'preparing'
  }, [
    legalDocument,
    legalDocumentCode,
    retriesExceeded,
    legalDocumentError,
    productIdKey,
  ])

  const message = MESSAGES[status]
  const description = DESCRIPTIONS[status]

  return {
    status,
    message,
    description,
    legalDocumentCode,
    onDownloadClick,
  }
}
