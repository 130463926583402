import React, { useEffect } from "react";

import {
  AccountType,
  LawyerListObject,
  LawfirmMember as LawfirmMemberType,
} from 'common/state-type'
import { Avatar } from 'common/components/Avatar'
import { InfoItem } from '../components'
import useProfileDetail from 'common/providers/useProfileDetail'
import LoadingSpinner from 'components/base/LoadingSpinner'

interface LawfirmMemberProps {
  member: LawfirmMemberType
  fetchLawyerData: (data: any) => void
}
export const LawfirmMember: React.FC<LawfirmMemberProps> = ({
  member,
  fetchLawyerData,
}) => {
  const { getProfileDetail, profileDetail, profileDetailLoading } =
    useProfileDetail()

  useEffect(() => {
    getProfileDetail(
      { type: AccountType.LAWYER, id: member.id },
      {
        onSuccess(data: LawyerListObject) {
          fetchLawyerData(data)
        },
      },
    )
  }, [member.id])

  const memberships = profileDetail?.memberships.map((m) => m.name).join(', ')
  const qualifications = profileDetail?.qualifications
    .map((q) => q.name)
    .join(', ')

  return (
    <>
      <div className='hidden md:flex flex-col flex-1'>
        <div className='flex flex-col items-center'>
          <Avatar
            className='h-20 w-20 bg-white border-[#BABABA] border-2 text-xl'
            imgSource={member.avatar}
            fullName={member.name}
          />
          <span className='text-sm text-black'>
            {Utils.capitalizeFirstLetter(member.job_title)}
          </span>
          <span>{Utils.capitalizeFirstLetter(member.name)}</span>
        </div>
        <p className='font-normal my-4 text-black'>{member.bio}</p>
      </div>

      <div className='flex flex-col flex-1 md:hidden'>
        <div className='flex flex-row items-center'>
          <Avatar
            imgSource={member.avatar}
            fullName={member.name}
            className='mr-2 h-9 w-9'
          />
          <div className='flex flex-col ml-2 text-black'>
            <span className='text-lg font-bold'>{member.name}</span>
            <span className='text-xs'>Solicitor Advocate</span>
          </div>
        </div>

        <p className='font-normal text-sm my-4 text-black'>{member.bio}</p>
        {profileDetailLoading ? (
          <div className='flex flex-row justify-center'>
            <LoadingSpinner />
          </div>
        ) : profileDetail ? (
          <div className='grid grid-cols-2 mb-4'>
            <InfoItem
              title='SRA AUTHORISED'
              content={profileDetail.sra_authorised ? 'Yes' : 'No'}
            />
            {profileDetail.sra_number && (
              <InfoItem title='SRA NUMBER' content={profileDetail.sra_number} />
            )}
            {!!qualifications.length && (
              <InfoItem
                title='ACADEMIC QUALIFICATIONS'
                content={qualifications}
              />
            )}
            {!!memberships?.length && (
              <InfoItem title='MEMBERSHIPS' content={memberships} />
            )}
          </div>
        ) : null}
      </div>
    </>
  )
}
