import { ReactNode, useMemo } from "react";
import parseHtml from 'html-react-parser';
import { applyHtmlStyles } from "../utils/applyHtmlStyles";

const isElement = (item: JSX.Element | string): item is JSX.Element => (
  typeof item !== 'string'
);

const parseContent = (
  html?: string,
) => {
  const parsedHtml = parseHtml(html ?? '');
  const arr = Array.isArray(parsedHtml) ? parsedHtml : [parsedHtml];
  const sections: { title: string; content: ReactNode[] }[] = [];

  arr.forEach((element) => {
    if (isElement(element) && element.type === 'h2') {
      sections.push({ title: element.props.children, content: [] });

      return;
    }

    if (sections.length === 0) {
      sections.push({ title: '', content: [] });
    }

    sections[sections.length - 1].content.push(applyHtmlStyles(element));
  });

  return { sections };
};

export const useCollapsedHtmlContent = (
  html?: string,
) => {
  return useMemo(() => (
    parseContent(html)
  ), [html]);
};
