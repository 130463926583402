import { User } from './../state-type'
import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseAuthType = {
  // register: (data:Record<string, any>, callbacks?:Callbacks)=>void,
  register: (data: RequestTypes['register'], callbacks?: Callbacks) => void
  login: (data: RequestTypes['login'], callbacks?: Callbacks) => void
  createPassword: (
    data: RequestTypes['createPassword'],
    callbacks?: Callbacks,
  ) => void
  resetPassword: (
    data: RequestTypes['resetPassword'],
    callbacks?: Callbacks,
  ) => void
  changePassword: (
    data: RequestTypes['changePassword'],
    callbacks?: Callbacks,
  ) => void
  logout: (data?: RequestTypes['logout'], callbacks?: Callbacks) => void
  confirmEmail: (data: Record<string, any>, callbacks?: Callbacks) => void
  oauth: (data: RequestTypes['oauth'], callbacks?: Callbacks) => void
  updateUser: (data: Record<string, any>, callbacks?: Callbacks) => void
  user: AppState['user']
  profile: AppState['profile']
  userLoading: AppState['userLoading']
  userError: AppState['userError']
  passwordError: AppState['passwordError']
}

export function useAuth(): UseAuthType {
  const { user, profile, userLoading, userError, passwordError } = useSelector(
    (state: AppState) => ({
      user: state.user,
      profile: state.profile,
      userLoading: state.userLoading || state.profileLoading,
      userError: state.userError || state.profileError,
      passwordError: state.passwordError,
    }),
  )
  const dispatch = useDispatch()
  const login = useCallback(
    (data, callbacks) => {
      return dispatch(AppActions.login(data, callbacks))
    },
    [dispatch],
  )
  const createPassword = useCallback(
    (data, callbacks) => {
      return dispatch(AppActions.createPassword(data, callbacks))
    },
    [dispatch],
  )
  const resetPassword = useCallback(
    (data, callbacks) => {
      return dispatch(AppActions.resetPassword(data, callbacks))
    },
    [dispatch],
  )
  const changePassword = useCallback(
    (data, callbacks) => {
      return dispatch(AppActions.changePassword(data, callbacks))
    },
    [dispatch],
  )
  const register = useCallback(
    (data, callbacks) => {
      return dispatch(AppActions.register(data, callbacks))
    },
    [dispatch],
  )
  const logout = useCallback(
    (data, callbacks) => {
      return dispatch(AppActions.logout(data, callbacks))
    },
    [dispatch],
  )
  const confirmEmail = useCallback(
    (data, callbacks) => {
      return dispatch(AppActions.confirmEmail(data, callbacks))
    },
    [dispatch],
  )
  const updateUser = useCallback(
    (data, callbacks) => {
      return dispatch(AppActions.updateUser(data, callbacks))
    },
    [dispatch],
  )
  const oauth = useCallback(
    (data, callbacks) => {
      return dispatch(AppActions.createOauth(data, callbacks))
    },
    [dispatch],
  )

  return {
    profile,
    user,
    userLoading,
    userError,
    passwordError,
    login,
    oauth,
    createPassword,
    resetPassword,
    changePassword,
    logout,
    register,
    updateUser,
    confirmEmail,
  }
}
