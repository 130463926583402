import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStripeCheckoutUrl from "common/providers/useStripeCheckoutUrl";
import useProfile from "common/providers/useProfile";

export const useLegalDocumentCreation = () => {
  const navigate = useNavigate();
  const { profile } = useProfile();
  const { getStripeCheckoutUrl, stripeCheckoutUrl } = useStripeCheckoutUrl();
  const [isCheckoutSessionLoading, setIsCheckoutSessionLoading] = useState(false);

  const checkout = useCallback((stripeProductId: string) => {
    const { origin, pathname } = new URL(window.location.href);
    const successPathname = `${pathname}/download`.replace('//', '/');

    getStripeCheckoutUrl({
      id: stripeProductId,
      success_url: `${origin}${successPathname}`,
      cancel_url: `${origin}${pathname}`,
    }, {
      onSuccess(data) {
        window.location.href = data.checkoutSessionUrl;
      },
      onError() {
        setIsCheckoutSessionLoading(false);
      }
    });
  }, [])

  const createLegalDocument = useCallback((id: string) => {
    setIsCheckoutSessionLoading(true);
    checkout(id);
  }, [checkout, navigate, profile]);

  useEffect(() => {
    if (stripeCheckoutUrl?.checkoutSessionUrl) {
      window.location.href = stripeCheckoutUrl.checkoutSessionUrl;
    }
  }, [stripeCheckoutUrl]);

  return {
    createLegalDocument,
    isCheckoutSessionLoading,
  }
};
