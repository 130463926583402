import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseStripeProductDetails = {
  stripeProductDetails?: AppState['stripeProductDetails']
  stripeProductDetailsLoading: AppState['stripeProductDetailsLoading']
  stripeProductDetailsError: AppState['stripeProductDetailsError']
  getStripeProductDetails: (
    data: RequestTypes['getStripeProductDetails'],
    callbacks?: Callbacks,
  ) => void
}

export default function useStripeProductDetails(): UseStripeProductDetails {
  const {
    stripeProductDetails,
    stripeProductDetailsLoading,
    stripeProductDetailsError,
  } = useSelector((state: AppState) => ({
    stripeProductDetails: state.stripeProductDetails,
    stripeProductDetailsLoading: state.stripeProductDetailsLoading,
    stripeProductDetailsError: state.stripeProductDetailsError,
  }))
  const dispatch = useDispatch()
  const getStripeProductDetails = useCallback(
    (data: RequestTypes['getStripeProductDetails'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getStripeProductDetails(data, callbacks))
    },
    [dispatch],
  )
  return {
    stripeProductDetails,
    stripeProductDetailsLoading,
    stripeProductDetailsError,
    getStripeProductDetails,
  }
}
