import { InputWrapper } from './InputWrapper'
import cn from 'classnames'
import { InputErrorText } from './InputErrorText'
import { DropdownMultiSelect } from '../Dropdowns/DropdownMultiSelect'

type InputMultiSelectProps = {
  value: {
    value: string
    label: string
  }[]
  name: string
  label: string
  isLabelVisible?: boolean
  isLabelInline?: boolean
  onChange: (newValue: string[]) => void
  options: {
    value: string
    label: string
  }[]
  placeholder?: string
  className?: string
  error?: string
  theme?: 'default' | 'grey'
  isLarge?: boolean
}

export const InputMultiSelect = ({
  value,
  onChange,
  options,
  placeholder,
  name,
  label,
  isLabelVisible,
  isLabelInline,
  className,
  error,
  theme = 'default',
  isLarge,
}: InputMultiSelectProps) => {
  const id = `${name}-input`

  return (
    <div
      className={cn(isLabelInline && 'flex flex-row items-center', className)}
    >
      {label && (
        <label
          className={cn(
            'block',
            isLabelInline ? 'mr-4' : 'mb-1',
            isLabelVisible ? 'font-medium mb-1 block' : 'hidden',
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <div className='flex-1'>
        <InputWrapper hasOverflow theme={theme} className='flex-1'>
          <DropdownMultiSelect
            isLarge={isLarge}
            name={name}
            value={value}
            options={options}
            placeholder={placeholder}
            onChange={onChange}
          />
        </InputWrapper>
        {error && <InputErrorText>{error}</InputErrorText>}
      </div>
    </div>
  )
}
