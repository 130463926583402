import React, { ReactNode, useCallback } from 'react'
import { FormPageWrapper } from './FormPageWrapper'
import { Button } from './Button'
import { SuccessIcon } from '../Icons/SuccessIcon'

type SuccessPageWrapperProps = {
  children: ReactNode
}

const SuccessPageWrapper = ({ children }: SuccessPageWrapperProps) => {
  const onSignInClick = useCallback(() => {
    document.location.href = '/login'
  }, [])

  return (
    <FormPageWrapper title='Success !' Icon={SuccessIcon}>
      <p className='mb-6 text-black text-center text-zinc-600 text-base'>
        {children}
      </p>

      <Button className={' !w-[30%] !mx-auto'} onClick={onSignInClick}>Done</Button>
    </FormPageWrapper>
  )
}

export default SuccessPageWrapper
