import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'

type UseDetailedLawfirmProfile = {
  detailedLawfirmProfile: AppState['detailedLawfirmProfile']
  detailedLawfirmProfileLoading: AppState['detailedLawfirmProfileLoading']
  detailedLawfirmProfileError: AppState['detailedLawfirmProfileError']
  getDetailedLawfirmProfile: (
    data: RequestTypes['getDetailedLawfirmProfile'],
    callbacks?: Callbacks,
  ) => void
}

export default function useDetailedLawfirmProfile(): UseDetailedLawfirmProfile {
  const {
    detailedLawfirmProfile,
    detailedLawfirmProfileLoading,
    detailedLawfirmProfileError,
  } = useSelector((state: AppState) => ({
    detailedLawfirmProfile: state.detailedLawfirmProfile,
    detailedLawfirmProfileLoading: state.detailedLawfirmProfileLoading,
    detailedLawfirmProfileSaving: state.detailedLawfirmProfileSaving,
    detailedLawfirmProfileError: state.detailedLawfirmProfileError,
  }))
  const dispatch = useDispatch()

  const getDetailedLawfirmProfile = useCallback(
    (
      data: RequestTypes['getDetailedLawfirmProfile'],
      callbacks?: Callbacks,
    ) => {
      return dispatch(AppActions.getDetailedLawfirmProfile(data, callbacks))
    },
    [dispatch],
  )

  return {
    detailedLawfirmProfile,
    detailedLawfirmProfileLoading,
    detailedLawfirmProfileError,
    getDetailedLawfirmProfile,
  }
}
