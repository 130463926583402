import React from "react";
import bg from "../../assets/Public Content/bg.png";
import Podcasts from "./Podcasts";
import Newsletter from "./Newsletter";
import EventsAndWebinar from "./EventsAndWebinar";
import Research from "./Research";
import ResearchMore from "./ResearchMore";
import Subscribe from "./Subscribe";
import PreFooter from "./PreFooter";
import { SiteFooter } from "../SiteFooter";
import ThoughtLeadership from "./ThoughtLeadership";

const PublicContent = () => {
  return (
    <React.Fragment>
      <div className="bg-white relative max-w-full">
        <img src={bg} className="w-[100%]" />
        <div className="flex flex-col md:max-w-[460px] mx-auto justify-between items-center text-center absolute bottom-[20px] left-0 right-0">
        <p className="md:text-[48px] font-bold p-[10px]">Discover LawTech</p>
        <span className="md:text-[16px] font-bold text-[#20BFE7] p-[10px]">Join Legal Utopia on our Lawtech journey</span>
        </div>
      </div>
      <Podcasts/>
      <Newsletter/>
      <EventsAndWebinar/>
      <ThoughtLeadership />
      <Subscribe/>
      <Research/>
      <ResearchMore />
      <PreFooter/>
      <SiteFooter />
    </React.Fragment>
  );
};

PublicContent.propTypes = {};

export default PublicContent;
