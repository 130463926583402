import { Profile } from 'common/state-type'

const isStringDefined = (str?: string) => str && str !== 'null'

export const hasFullName = (profile?: Profile) =>
  profile &&
  isStringDefined(profile.first_name) &&
  isStringDefined(profile.last_name)

export const getFullName = (profile?: Profile): string | undefined =>
  hasFullName(profile)
    ? `${profile.first_name} ${profile.last_name}`
    : profile?.email
