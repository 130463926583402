import cn from 'classnames';
import { GetTheAppQrCode } from 'components/AppStore/GetTheAppQrCode';
import { AppleAppStoreButton } from 'components/AppStore/AppleAppStoreButton';
import banner from '../assets/BannerImages/banner.png';
import bannerMobile from '../assets/BannerImages/banner-mobile.png';

type BannerProps = {
  isFloating?: boolean;
};

// The aspect ratios of the banners
const ASPECT_RATIO_CLS = 'aspect-[103/19]';
const ASPECT_RATIO_CLS_MOBILE = 'aspect-[335/579]';

export const Banner = ({
  isFloating,
}: BannerProps) => {
  const getTheAppSection = (
    <div className="flex flex-row items-end">
      <GetTheAppQrCode className="block md:hidden xl:block mr-4" size={80} />
      <div>
        <p className="text-lg font-bold mb-2 block md:hidden xl:block">
          Get the app now.
        </p>
        <AppleAppStoreButton className="max-w-[120px]" theme="light" />
      </div>
    </div>
  );

  return (
    <>
      {/* Desktop banner */}
      <div
        style={{ backgroundImage: `url('${banner}')` }}
        className={cn(
          'mx-auto bg-cover hidden md:block max-w-[1500px] w-full',
          isFloating && 'relative bottom-[75px] lg:bottom-[100px] mb-[-75px] lg:mb-[-100px]',
          ASPECT_RATIO_CLS,
        )}
      >
        <div className="text-white px-4 py-3 xl:px-5 xl:py-4 flex flex-col justify-between h-full">
          <div className="mt-1 lg:mt-2">
            <p className="text-2xl lg:text-3xl font-bold mb-2">
              Instant support, 24/7 365
            </p>
            <p className="hidden lg:block">
              Our Legal Checker diagnoses your issue and provides solutions.
            </p>
          </div>
          {getTheAppSection}
        </div>
      </div>

      {/* Mobile banner */}
      <div
        style={{ backgroundImage: `url('${bannerMobile}')` }}
        className={cn(
          'mx-auto bg-cover md:hidden max-w-[400px] w-full',
          isFloating && 'relative bottom-[150px] mb-[-150px]',
          ASPECT_RATIO_CLS_MOBILE,
        )}
      >
        <div className="text-white px-5 py-4 flex flex-col h-full">
          <div className="mt-2 mb-10">
            <p className="text-2xl font-bold mb-3">
              Solving Legal Problems 24/7
            </p>
            <p>
              Our Legal Checker service helps your identify the legal nature of
              your problem on-demand.
            </p>
          </div>
          {getTheAppSection}
        </div>
      </div>
    </>
  )
}
