import { KeyValue } from "common/state-type";

export const AuthorisationPeriodData:KeyValue[] = [
    {
      label: "1 Year",
      value: 1,
    },
    {
      label: "2 Years",
      value: 2,
    },
    {
      label: "3 Years",
      value: 3,
    },
    {
      label: "5 Years +",
      value: 5,
    },
    {
      label: "10 Years +",
      value: 10,
    },
    {
      label: "15 Years +",
      value: 15,
    },
  ];