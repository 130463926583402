import fetch from 'isomorphic-unfetch' // we do this here instead of _data.js as it intereferes with react-native
import './ie11'

import {
  AppState,
  NetInfo,
  Clipboard,
} from 'polyfill-react-native'
import './localization'
import '../common/utils'
import './api'
import './libs'

import React from 'react'
global.fetch = fetch

if (typeof window !== 'undefined' && !HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value: function (callback, type, quality) {
      let canvas = this
      setTimeout(function () {
        let binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
          len = binStr.length,
          arr = new Uint8Array(len)

        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i)
        }

        callback(new Blob([arr], { type: type || 'image/png' }))
      })
    },
  })
}
if (typeof projectOverrides !== 'undefined') {
  global.Project = {
    ...global.Project,
  }
}


global.AppState = AppState
global.NetInfo = NetInfo
global.Clipboard = Clipboard
