// import React, { useState } from 'react'
// import { HeroSectionV2 } from './HeroSectionV2'
// import { SiteFooter } from '../../SiteFooter'
// import InfoSection from './InfoSection'
// import { HomeSearchBar, ComponentType } from './HomeSearchBar'
// import HomeAboutUs from './HomeAboutUs'
// import backgroundImg1 from '../../../assets/home_background_2_1.svg'
// import backgroundImg2 from '../../../assets/home_background_2_2.svg'
// import FeedbackModal from './feedbackModal'

// const HomePage = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false)

//   return (
//     <div>
//       <button
//         className='fixed left-[10px] hover:left-[20px] top-[60vh] bg-white  rounded-r-lg shadow-md transform -rotate-90 origin-left z-[1000] p-4 pt-8 '
//         onClick={() => setIsModalOpen(true)}
//       >
//         <span className='text-[#37A2BA] font-semibold '>
//           Give us your feedback
//         </span>
//       </button>

//       <HeroSectionV2 />
//       <InfoSection />

//       <div className='relative '>
//         <div
//           className='w-full bg-no-repeat bg-cover bg-left py-32 '
//           style={{
//             backgroundImage: `url(${backgroundImg1})`,
//             backgroundSize: 'auto 100%',
//             backgroundAttachment: 'contain',
//           }}
//         />
//         <HomeSearchBar type={ComponentType.DOCUMENT} />
//         <div
//           className={'my-[15vh] sm:my-[20vh] md:my-[25vh] lg:my-[27.78vh]'}
//         ></div>
//         <HomeSearchBar type={ComponentType.LAW} />
//         <div
//           className='w-full bg-no-repeat bg-cover bg-right py-32 '
//           style={{
//             backgroundImage: `url(${backgroundImg2})`,
//             backgroundSize: 'auto 100%',
//             backgroundAttachment: 'contain',
//           }}
//         />
//       </div>
//       <HomeAboutUs />
//       <SiteFooter />
//       <FeedbackModal
//         isOpen={isModalOpen}
//         onClose={() => setIsModalOpen(false)}
//       />
//     </div>
//   )
// }

// export default HomePage

import { HubSection } from './HubSection'
import { AboutSection } from './AboutSection'
import { HeroSection } from './HeroSection'
import { LawCardsSection } from './LawCardsSection'
import { BannerSection } from '../BannerSection'
import { LegalDocuments } from './LegalDocuments'
import { SiteFooter } from '../../SiteFooter'

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <LawCardsSection />
      <LegalDocuments />
      <BannerSection />
      <HubSection />
      <AboutSection />
      <SiteFooter />
    </>
  )
}

export default HomePage
