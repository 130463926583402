import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

function BlogPage() {
  const slug = useParams()

  const [page, setPage] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const authFetch = async (url, options = {}) => {
    const response = await fetch(url)

    if (!response.ok) {
      throw new Error(
        `Network response was not ok: ${response.status} ${response.statusText}`,
      )
    }

    return response.text()
  }

  useEffect(() => {
    const fetchPage = async () => {
      setLoading(true)
      try {
        const data = await authFetch(
          `http://127.0.0.1:8000/pages/reviews-policy/`,
        )
        setPage(data)
      } catch (error) {
        console.error('Error fetching page:', error.message)
        setError(`Failed to load page: ${error.message}`)
      } finally {
        setLoading(false)
      }
    }

    fetchPage()
  }, [])

  if (loading) return <div>Loading...</div>
  if (error) return <div>{error}</div>
  if (!page) return <div>Page not found</div>

  return <div dangerouslySetInnerHTML={{ __html: page }} />
}

export default BlogPage
