import { ProjectType } from 'common/projectType'

const Project: ProjectType = (global.Project = {
  debug: false,
  api: 'http://localhost:4000/',
  gaApi: 'https://lawyers.legalutopia.co.uk/api/',
  cwp: 'https://legalutopia.co.uk/',
  lp: 'https://lawyers.legalutopia.co.uk/',
  liveApi: 'https://production.api.legalutopia.co.uk/api/v1/',
  linkDomainPrefix: 'https://legalutopia.page.link',
  ga: '',
  productSuffix: '',
  appsflyer: 'Ch9iWDJxM8pW8xkLacck8g',
  appId: '1487650991',
  stripe:'pk_live_l7EQ68G1zeTNVBfofXYssGE900JHYvEJ8Q',
  logs: {
    DATA: true,
    STORE: true,
    DISPATCHER: true,
    STORAGE: true,
    SERVER: true,
  },
  cognito: {
    region: 'eu-west-2',
    userPoolId: 'eu-west-2_5seljCqLZ',
    userPoolWebClientId: '21c7ra2nrrmo7m9vpesh5na01j',
    oauth: {
      domain: 'legal-utopia-production.auth.eu-west-2.amazoncognito.com',
      scope: ['profile', 'email', 'openid'],
      redirectSignIn: 'legalutopia://',
      redirectSignOut: 'legalutopia://',
      responseType: 'code',
      // optional, for Cognito hosted ui specified options
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        // AdvancedSecurityDataCollectionFlag: true
      },
    },
  },
  cognitoWeb: {
    region: 'eu-west-2',
    userPoolId: 'eu-west-2_5seljCqLZ',
    userPoolWebClientId: '21c7ra2nrrmo7m9vpesh5na01j',
    oauth: {
      domain: 'legal-utopia-production.auth.eu-west-2.amazoncognito.com',
      scope: ['profile', 'email', 'openid'],
      redirectSignIn: 'https://www.legalutopia.co.uk',
      redirectSignOut: 'https://www.legalutopia.co.uk',
      responseType: 'code',
      // optional, for Cognito hosted ui specified options
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        // AdvancedSecurityDataCollectionFlag: true
      },
    },
  },
  grecaptcher: '6Lc1mf4UAAAAAC6uZ0HaGJ3ufgwCXY4GFoNhMeBD',
  formly: 'boilerplate',
})
export default Project
// if (typeof ENV_NAME !== 'undefined' && typeof ENV_TYPE !== 'undefined') {
// }
