import { ButtonHTMLAttributes, useState } from 'react'
import cn from 'classnames'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  secondary?: boolean
}

export const Button = ({
  children,
  className,
  disabled,
  secondary,
  ...restProps
}: ButtonProps) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isClicked, setIsClicked] = useState(false)

  return (
    <button
      type='button'
      {...restProps}
      disabled={disabled}
      className={cn(
        'rounded-lg text-lg py-1.5 px-8 w-full flex items-center justify-center whitespace-nowrap transition-all duration-200',
        {
          // Primary button styles
          'bg-[#020251] text-white': !secondary && !disabled && !isHovered && !isClicked,
          'bg-[#020251] text-[#65C2D7]': !secondary && !disabled && isHovered && !isClicked,
          'bg-[#83C8D7] text-[#020251]': !secondary && !disabled && isClicked,

          // Secondary button styles
          'bg-white text-[#1C7D93] border border-gray-300': secondary && !disabled && !isHovered && !isClicked,
          'bg-[#EBFBFF] text-[#1C7D93]': secondary && !disabled && isHovered && !isClicked,
          'text-[#020251] bg-[#83C8D7] ': secondary && !disabled && isClicked,

          // Disabled styles (same for both primary and secondary)
          'bg-[##3C8D7] text-[#A0ABBE] cursor-not-allowed': disabled,

          // Default cursor
          'cursor-pointer': !disabled,

        },
        className,
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false)
        setIsClicked(false)
      }}
      onMouseDown={() => setIsClicked(true)}
      onMouseUp={() => setIsClicked(false)}
    >
      {children}
    </button>
  )
}