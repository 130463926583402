import React from "react";
import ReactPaginate from "react-paginate";

const PaginationBar = ({ onClick }) => {
  return (
    <div className='flex flex-1 flex-col content-end items-center text-gray-400 fixed bottom-0 left-0 z-1 w-full md:w-[50%] bg-white'>
      <ReactPaginate
        breakLabel='...'
        nextLabel='Next ->'
        pageRangeDisplayed={1}
        pageCount={0}
        previousLabel='<- Previous'
        containerClassName='inline-flex px-5 py-2'
        pageClassName='mx-1 text-center m-auto block align-middle'
        previousClassName='mx-1 text-center m-auto block align-middle leading-[25px]'
        nextClassName='mx-1 text-center m-auto block align-middle leading-[25px]'
        activeClassName='bg-[#65C2D7] text-white rounded-full w-[25px] h-[25px]'
        activeLinkClassName='leading-[25px]'
        onClick={onClick}
      />
    </div>
  )
};

export default PaginationBar;
