import React from "react";
import PropTypes from "prop-types";
import newsletter_carddummy from "../../assets/Public Content/newsletter_carddummy.png";
import fraser from '../../assets/Public Content/fraser.png'

const NewsLetterGridView = () => {
  return (
    <React.Fragment>
      <div className="md:flex md:max-w-[1024px] justify-between items-center mx-auto py-[40px] px-[20px]">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
          <div className="rounded-[10px] overflow-hidden shadow-lg bg-[#D9D9D9]">
            <img
              className="w-full"
              src={newsletter_carddummy}
              alt="news letter"
            />
            <div className="px-6 py-4 bg-[#20BFE7] bg-opacity-[0.4]">
              <div className="text-[#1B4A8A] font-bold text-[24px] leading-[33px]">
                LawTech Marrakesh
              </div>
              <div className="text-white font-normal text-[16px] leading-[22px] pb-[20px]">
                Legal Utopia in MENA
              </div>
              <div className="font-bold text-[16px] leading-[20px] mb-2 text-white bg-[#1B4A8A] rounded-[8px] px-[12px] py-[5px] mt-5 inline ">
                Read full article
              </div>
              <div className="flex items-center pt-[20px]">
                <img
                  className="w-10 h-10 rounded-full mr-4 border-[2px] border-solid border-[#20BFE7]"
                  src={fraser}
                  alt="profile"
                />
                <div className="text-sm">
                  <p className="text-[#1B4A8A] font-bold text-[16px] leading-[26px]">
                    Fraser Matcham
                  </p>
                  <p className="text-[#1B4A8A] font-normal text-[16px] leading-[26px]">
                    Chief Product Officer{" "}
                  </p>
                  <p className="text-[#6C63FF] font-normal text-[16px] leading-[26px]">
                    Via Linkedin{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-[10px] overflow-hidden shadow-lg bg-[#D9D9D9]">
            <img
              className="w-full"
              src={newsletter_carddummy}
              alt="news letter"
            />
            <div className="px-6 py-4 bg-[#20BFE7] bg-opacity-[0.4]">
              <div className="text-[#1B4A8A] font-bold text-[24px] leading-[33px]">
                LawTech Marrakesh
              </div>
              <div className="text-white font-normal text-[16px] leading-[22px] pb-[20px]">
                Legal Utopia in MENA
              </div>
              <div className="font-bold text-[16px] leading-[20px] mb-2 text-white bg-[#1B4A8A] rounded-[8px] px-[12px] py-[5px] mt-5 inline ">
                Read full article
              </div>
              <div className="flex items-center pt-[20px]">
                <img
                  className="w-10 h-10 rounded-full mr-4 border-[2px] border-solid border-[#20BFE7]"
                  src={fraser}
                  alt="profile"
                />
                <div className="text-sm">
                  <p className="text-[#1B4A8A] font-bold text-[16px] leading-[26px]">
                    Fraser Matcham
                  </p>
                  <p className="text-[#1B4A8A] font-normal text-[16px] leading-[26px]">
                    Chief Product Officer{" "}
                  </p>
                  <p className="text-[#6C63FF] font-normal text-[16px] leading-[26px]">
                    Via Linkedin{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-[10px] overflow-hidden shadow-lg bg-[#D9D9D9]">
            <img
              className="w-full"
              src={newsletter_carddummy}
              alt="news letter"
            />
            <div className="px-6 py-4 bg-[#20BFE7] bg-opacity-[0.4]">
              <div className="text-[#1B4A8A] font-bold text-[24px] leading-[33px]">
                LawTech Marrakesh
              </div>
              <div className="text-white font-normal text-[16px] leading-[22px] pb-[20px]">
                Legal Utopia in MENA
              </div>
              <div className="font-bold text-[16px] leading-[20px] mb-2 text-white bg-[#1B4A8A] rounded-[8px] px-[12px] py-[5px] mt-5 inline ">
                Read full article
              </div>
              <div className="flex items-center pt-[20px]">
                <img
                  className="w-10 h-10 rounded-full mr-4 border-[2px] border-solid border-[#20BFE7]"
                  src={fraser}
                  alt="profile"
                />
                <div className="text-sm">
                  <p className="text-[#1B4A8A] font-bold text-[16px] leading-[26px]">
                    Fraser Matcham
                  </p>
                  <p className="text-[#1B4A8A] font-normal text-[16px] leading-[26px]">
                    Chief Product Officer{" "}
                  </p>
                  <p className="text-[#6C63FF] font-normal text-[16px] leading-[26px]">
                    Via Linkedin{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex mx-auto justify-center items-center pb-[20px]">
        <a className=" text-center bg-[#1B4A8A] bg-opacity-[0.2]  rounded-[10px] px-[80px] py-[14px] text-[#1B4A8A] leading-[31px] text-[24px] font-bold">See more</a>
      </div>
    </React.Fragment>
  );
};

NewsLetterGridView.propTypes = {};

export default NewsLetterGridView;
