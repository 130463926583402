export default function () {
    return (
        <div dangerouslySetInnerHTML={{
            __html: `<div class="container content-page mx-4">
  <div>
    <p
      class="normal0"
      style="
        line-height: 115%;
        widows: 0;
        orphans: 0;
        font-size: 11pt;
      "
    >
      <span style="font-family: Arial"> </span>
    </p>
    <table
      cellspacing="0"
      cellpadding="0"
      class="Table1"
      style="
        width: 465.15pt;
        border: 0.75pt solid #000000;
        -aw-border: 0.5pt single;
        -aw-border-insideh: 0.5pt single #000000;
        -aw-border-insidev: 0.5pt single #000000;
        border-collapse: collapse;
      "
    >
      <tbody>
        <tr>
          <td
            style="
              padding-right: 5.03pt;
              padding-left: 5.03pt;
              vertical-align: top;
            "
          >
            <p
              class="normal0"
              style="text-align: center; font-size: 14pt"
            >
              <span style="font-family: Arial">
                Data Retention Policy
              </span>
            </p>
            <p class="normal0" style="text-align: center">
              <span style="font-family: 'Century Gothic'">
                 
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="normal0">
      <span style="font-family: 'Century Gothic'"> </span>
    </p>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 14.17pt;
          margin-bottom: 6pt;
          text-align: justify;
          page-break-inside: avoid;
          page-break-after: avoid;
          padding-left: 21.28pt;
          font-family: Arial;
          font-size: 11pt;
        "
      >
        <span style="font-size: 12pt; font-weight: bold">
          Introduction
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        text-align: justify;
      "
    >
      <span style="font-family: Arial">
        This Policy sets out the obligations of Legal Utopia
        Limited, a company registered in England and Wales
        under number 10909418, whose registered office is at
      </span>
      <span
        style="font-family: Arial; font-weight: bold"
      ></span>
      <span style="font-family: Arial">
        3rd Floor, 86-90 Paul Street, London, EC2A 4NE (“the
        Company”) regarding retention of personal data
        collected, held, and processed by the Company in
        accordance with the Data Protection Legislation.
        “Data Protection Legislation” means all legislation
        and regulations in force from time to time
        regulating the use of personal data and the privacy
        of electronic communications including, but not
        limited to, the retained EU law version of the
        General Data Protection Regulation ((EU) 2016/679)
        (the “UK GDPR”), as it forms part of the law of
        England and Wales, Scotland, and Northern Ireland by
        virtue of section 3 of the European Union
        (Withdrawal) Act 2018, the Data Protection Act 2018,
        the Privacy and Electronic Communications
        Regulations 2003 as amended, and any successor
        legislation.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        text-align: justify;
      "
    >
      <span style="font-family: Arial">
        The Data Protection Legislation defines “personal
        data” as any information relating to an identified
        or identifiable natural person (a “data subject”).
        An identifiable natural person is one who can be
        identified, directly or indirectly, in particular by
        reference to an identifier such as a name, an
        identification number, location data, an online
        identifier, or to one or more factors specific to
        the physical, physiological, genetic, mental,
        economic, cultural, or social identity of that
        natural person.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        text-align: justify;
      "
    >
      <span style="font-family: Arial">
        The Data Protection Legislation also addresses
        “special category” personal data (also known as
        “sensitive” personal data). Such data includes, but
        is not necessarily limited to, data concerning the
        data subject’s race, ethnicity, politics, religion,
        trade union membership, genetics, biometrics (if
        used for ID purposes), health, sex life, or sexual
        orientation.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        text-align: justify;
      "
    >
      <span style="font-family: Arial">
        Under the Data Protection Legislation, personal data
        shall be kept in a form which permits the
        identification of data subjects for no longer than
        is necessary for the purposes for which the personal
        data is processed. In certain cases, personal data
        may be stored for longer periods where that data is
        to be processed for archiving purposes that are in
        the public interest, for scientific or historical
        research, or for statistical purposes (subject to
        the implementation of the appropriate technical and
        organisational measures required by the Data
        Protection Legislation to protect that data).
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        text-align: justify;
      "
    >
      <span style="font-family: Arial">
        In addition, the Data Protection Legislation
        includes the right to erasure or “the right to be
        forgotten”. Data subjects have the right to have
        their personal data erased (and to prevent the
        processing of that personal data) in the following
        circumstances:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '1';
        -aw-list-padding-sml: 25.67pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            a)
          </span>
        </span>
        <span
          style="
            width: 25.67pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                           
        </span>
      </span>
      <span style="font-family: Arial">
        Where the personal data is no longer required for
        the purpose for which it was originally collected or
        processed (see above);
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '2';
        -aw-list-padding-sml: 25.67pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            b)
          </span>
        </span>
        <span
          style="
            width: 25.67pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                           
        </span>
      </span>
      <span style="font-family: Arial">
        When the data subject withdraws their consent;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '3';
        -aw-list-padding-sml: 26.29pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            c)
          </span>
        </span>
        <span
          style="
            width: 26.29pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                           
        </span>
      </span>
      <span style="font-family: Arial">
        When the data subject objects to the processing of
        their personal data and the Company has no
        overriding legitimate interest;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '4';
        -aw-list-padding-sml: 25.67pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            d)
          </span>
        </span>
        <span
          style="
            width: 25.67pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                           
        </span>
      </span>
      <span style="font-family: Arial">
        When the personal data is processed unlawfully (i.e.
        in breach of the Data Protection Legislation);
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '5';
        -aw-list-padding-sml: 25.67pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            e)
          </span>
        </span>
        <span
          style="
            width: 25.67pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                           
        </span>
      </span>
      <span style="font-family: Arial">
        When the personal data has to be erased to comply
        with a legal obligation; or
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '6';
        -aw-list-padding-sml: 28.73pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            f)
          </span>
        </span>
        <span
          style="
            width: 28.73pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                             
        </span>
      </span>
      <span style="font-family: Arial">
        Where the personal data is processed for the
        provision of information society services to a
        child.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        text-align: justify;
      "
    >
      <span style="font-family: Arial">
        This Policy sets out the type(s) of personal data
        held by the Company, the period(s) for which that
        personal data is to be retained, the criteria for
        establishing and reviewing such period(s), and when
        and how it is to be deleted or otherwise disposed
        of.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        text-align: justify;
      "
    >
      <span style="font-family: Arial">
        For further information on other aspects of data
        protection and compliance with the Data Protection
        Legislation, please refer to the Company’s Data
        Protection Policy.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: Arial"> </span>
    </p>
    <ol
      start="2"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 14.17pt;
          margin-bottom: 6pt;
          text-align: justify;
          page-break-inside: avoid;
          page-break-after: avoid;
          padding-left: 21.28pt;
          font-family: Arial;
          font-size: 11pt;
        "
      >
        <span style="font-size: 12pt; font-weight: bold">
          Aims and Objectives
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '2 1';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            2.1
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: Arial">
        The primary aim of this Policy is to set out limits
        for the retention of personal data and to ensure
        that those limits, as well as further data subject
        rights to erasure, are complied with. By extension,
        this Policy aims to ensure that the Company complies
        fully with its obligations and the rights of data
        subjects under the Data Protection Legislation.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '2 2';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            2.2
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: Arial">
        In addition to safeguarding the rights of data
        subjects under the Data Protection Legislation, by
        ensuring that excessive amounts of data are not
        retained by the Company, this Policy also aims to
        improve the speed and efficiency of managing data.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        text-align: justify;
      "
    >
      <span style="font-family: Arial"> </span>
    </p>
    <ol
      start="3"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 14.17pt;
          margin-bottom: 6pt;
          text-align: justify;
          page-break-inside: avoid;
          page-break-after: avoid;
          padding-left: 21.28pt;
          font-family: Arial;
          font-size: 11pt;
        "
      >
        <span style="font-size: 12pt; font-weight: bold">
          Scope
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '3 1';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            3.1
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: Arial">
        This Policy applies to all personal data held by the
        Company
      </span>
      <span
        style="font-family: Arial; font-weight: bold"
      ></span>
      <span style="font-family: Arial">
        and by third-party data processors processing
        personal data on the Company’s behalf.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '3 2';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            3.2
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: Arial">
        Personal data, as held by the Company
      </span>
      <span
        style="font-family: Arial; font-weight: bold"
      ></span>
      <span style="font-family: Arial">
        is stored in the following ways and in the following
        locations:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '1';
        -aw-list-padding-sml: 25.67pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            a)
          </span>
        </span>
        <span
          style="
            width: 25.67pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                           
        </span>
      </span>
      <span style="font-family: Arial">
        The Company’s servers, located in London, United
        Kingdom;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '2';
        -aw-list-padding-sml: 25.67pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            b)
          </span>
        </span>
        <span
          style="
            width: 25.67pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                           
        </span>
      </span>
      <span style="font-family: Arial">
        Third-party servers, operated by Amazon Web Services
        SARL and located in London, United Kingdom and
        Luxembourg;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '3';
        -aw-list-padding-sml: 26.29pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            c)
          </span>
        </span>
        <span
          style="
            width: 26.29pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                           
        </span>
      </span>
      <span style="font-family: Arial">
        Computers located in the Company’s premises and work
        environments;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '4';
        -aw-list-padding-sml: 25.67pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            d)
          </span>
        </span>
        <span
          style="
            width: 25.67pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                           
        </span>
      </span>
      <span style="font-family: Arial">
        Laptop computers and other mobile devices provided
        by the Company to its employees;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '5';
        -aw-list-padding-sml: 25.67pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            e)
          </span>
        </span>
        <span
          style="
            width: 25.67pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                           
        </span>
      </span>
      <span style="font-family: Arial">
        Computers and mobile devices owned by employees,
        agents, and sub-contractors used in accordance with
        the Company’s Bring Your Own Device (“BYOD”) Polic
      </span>
      <span style="font-family: Arial; font-weight: bold">
        y
      </span>
      <span style="font-family: Arial">;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '6';
        -aw-list-padding-sml: 28.73pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            f)
          </span>
        </span>
        <span
          style="
            width: 28.73pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                             
        </span>
      </span>
      <span style="font-family: Arial">
        Physical records stored in secured locations within
        England.
      </span>
    </p>
    <p class="normal0" style="text-align: justify">
      <span style="font-family: Arial"> </span>
    </p>
    <ol
      start="4"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 14.17pt;
          margin-bottom: 6pt;
          text-align: justify;
          page-break-inside: avoid;
          page-break-after: avoid;
          padding-left: 21.28pt;
          font-family: Arial;
          font-size: 11pt;
        "
      >
        <span style="font-size: 12pt; font-weight: bold">
          Data Subject Rights and Data Integrity
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        text-align: justify;
      "
    >
      <span style="font-family: Arial">
        All personal data held by the Company is held in
        accordance with the requirements of the Data
        Protection Legislation and data subjects’ rights
        thereunder, as set out in the Company’s Data
        Protection Policy.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '4 1';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            4.1
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: Arial">
        Data subjects are kept fully informed of their
        rights, of what personal data the Company holds
        about them, how that personal data is used, and how
        long the Company will hold that personal data (or,
        if no fixed retention period can be determined, the
        criteria by which the retention of the data will be
        determined).
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '4 2';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            4.2
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: Arial">
        Data subjects are given control over their personal
        data held by the Company including the right to have
        incorrect data rectified, the right to request that
        their personal data be deleted or otherwise disposed
        of (notwithstanding the retention periods otherwise
        set by this Data Retention Policy), the right to
        restrict the Company’s use of their personal data,
        and further rights relating to automated
        decision-making and profiling.
      </span>
    </p>
    <p
      class="normal0"
      style="margin-bottom: 6pt; text-align: justify"
    >
      <span style="font-family: Arial"> </span>
    </p>
    <ol
      start="5"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 14.17pt;
          margin-bottom: 6pt;
          text-align: justify;
          page-break-inside: avoid;
          page-break-after: avoid;
          padding-left: 21.28pt;
          font-family: Arial;
          font-size: 11pt;
        "
      >
        <span style="font-size: 12pt; font-weight: bold">
          Technical and Organisational Data Security
          Measures
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '5 1';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            5.1
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: Arial">
        The Company shall maintain an adequate IT Security
        Policy that shall include both technical and
        operational security measures to appropriately
        secure any and all personal data processed by the
        Company.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '5 2';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            5.2
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: Arial">
        The Company shall maintain appropriate cyber
        security credentials and cyber and data insurance to
        an appropriate level based on the overall risk
        presented to data subjects’ personal data.
      </span>
    </p>
    <ol
      start="6"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 14.17pt;
          margin-bottom: 6pt;
          text-align: justify;
          page-break-inside: avoid;
          page-break-after: avoid;
          padding-left: 21.28pt;
          font-family: Arial;
          font-size: 11pt;
        "
      >
        <span style="font-size: 12pt; font-weight: bold">
          Data Disposal
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        text-align: justify;
      "
    >
      <span style="font-family: Arial">
        Upon the expiry of the data retention periods set
        out below in Part 7 of this Policy, or when a data
        subject exercises their right to have their personal
        data erased, personal data shall be deleted,
        destroyed, or otherwise disposed of as follows:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '6 1';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            6.1
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: Arial">
        Personal data stored electronically (including any
        and all backups thereof) shall be deleted securely
        using the reasonably available default methods;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '6 2';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            6.2
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: Arial">
        Special category personal data stored electronically
        (including any and all backups thereof) shall be
        deleted securely using the reasonably available
        default methods
      </span>
      <span style="font-family: Arial; font-weight: bold">
        ;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '6 3';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            6.3
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: Arial">
        Personal data and special category personal data
        stored in hardcopy form shall be shredded and
        recycled or repurposed for use as rabbit or chicken
        bedding following personal inspection of an employee
        of the Company;
      </span>
    </p>
    <ol
      start="7"
      type="1"
      style="margin: 0pt; padding-left: 0pt"
    >
      <li
        class="normal0"
        style="
          margin-top: 6pt;
          margin-left: 14.17pt;
          margin-bottom: 6pt;
          text-align: justify;
          page-break-inside: avoid;
          page-break-after: avoid;
          padding-left: 21.28pt;
          font-family: Arial;
          font-size: 11pt;
        "
      >
        <span style="font-size: 12pt; font-weight: bold">
          Data Retention
        </span>
      </li>
    </ol>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '7 1';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            7.1
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: Arial">
        As stated above, and as required by law, the Company
        shall not retain any personal data for any longer
        than is necessary in light of the purpose(s) for
        which that data is collected, held, and processed.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '7 2';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            7.2
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: Arial">
        Different types of personal data, used for different
        purposes, will necessarily be retained for different
        periods
      </span>
      <span style="font-family: Arial; font-weight: bold">
        (
      </span>
      <span style="font-family: Arial">
        and its retention periodically reviewed), as set out
        below.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '7 3';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            7.3
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: Arial">
        When establishing and/or reviewing retention
        periods, the following shall be taken into account:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '1';
        -aw-list-padding-sml: 25.67pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            a)
          </span>
        </span>
        <span
          style="
            width: 25.67pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                           
        </span>
      </span>
      <span style="font-family: Arial">
        The objectives and requirements of the Company;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '2';
        -aw-list-padding-sml: 25.67pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            b)
          </span>
        </span>
        <span
          style="
            width: 25.67pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                           
        </span>
      </span>
      <span style="font-family: Arial">
        The type of personal data in question;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '3';
        -aw-list-padding-sml: 26.29pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            c)
          </span>
        </span>
        <span
          style="
            width: 26.29pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                           
        </span>
      </span>
      <span style="font-family: Arial">
        The purpose(s) for which the data in question is
        collected, held, and processed;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '4';
        -aw-list-padding-sml: 25.67pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            d)
          </span>
        </span>
        <span
          style="
            width: 25.67pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                           
        </span>
      </span>
      <span style="font-family: Arial">
        The Company’s legal basis for collecting, holding,
        and processing that data;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '5';
        -aw-list-padding-sml: 25.67pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            e)
          </span>
        </span>
        <span
          style="
            width: 25.67pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                           
        </span>
      </span>
      <span style="font-family: Arial">
        The category or categories of data subject to whom
        the data relates;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '6';
        -aw-list-padding-sml: 28.73pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            f)
          </span>
        </span>
        <span
          style="
            width: 28.73pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                             
        </span>
      </span>
      <span style="font-family: Arial">
        The public, scientific and/or historical research or
        statistical purposes to which it may be used for;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '7';
        -aw-list-padding-sml: 25.67pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            g)
          </span>
        </span>
        <span
          style="
            width: 25.67pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                           
        </span>
      </span>
      <span style="font-family: Arial">
        The risk posed to the personal data and the data
        subject themselves;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '8';
        -aw-list-padding-sml: 25.67pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            h)
          </span>
        </span>
        <span
          style="
            width: 25.67pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                           
        </span>
      </span>
      <span style="font-family: Arial">
        The practical accessibility to redress by data
        subjects should their rights be breached
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 3px;
        -aw-list-number-format: '%3)';
        -aw-list-number-styles: 'lowerLetter';
        -aw-list-number-values: '9';
        -aw-list-padding-sml: 29.34pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            i)
          </span>
        </span>
        <span
          style="
            width: 29.34pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                             
        </span>
      </span>
      <span style="font-family: Arial">
        The statements provided within the Company’s data
        policies.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '7 4';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            7.4
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: Arial">
        If a precise retention period cannot be fixed for a
        particular type of data, criteria shall be
        established by which the retention of the data will
        be determined, thereby ensuring that the data in
        question, and the retention of that data, can be
        regularly reviewed against those criteria.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '7 5';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            7.5
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: Arial">
        Notwithstanding the following defined retention
        periods, certain personal data may be deleted or
        otherwise disposed of prior to the expiry of its
        defined retention period where a decision is made
        within the Company to do so (whether in response to
        a request by a data subject or otherwise).
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 70.9pt;
        margin-bottom: 6pt;
        text-indent: -35.45pt;
        text-align: justify;
        -aw-list-level-number: 2px;
        -aw-list-number-format: '%0.%2';
        -aw-list-number-styles: 'decimal decimal';
        -aw-list-number-values: '7 6';
        -aw-list-padding-sml: 20.16pt;
      "
    >
      <span>
        <span>
          <span style="font-family: Arial; font-size: 11pt">
            7.6
          </span>
        </span>
        <span
          style="
            width: 20.16pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
        >
                       
        </span>
      </span>
      <span style="font-family: Arial">
        In limited circumstances, it may also be necessary
        to retain personal data for longer periods where
        such retention is for archiving purposes that are in
        the public interest, for scientific or historical
        research purposes, or for statistical purposes. All
        such retention will be subject to the implementation
        of appropriate technical and organisational measures
        to protect the rights and freedoms of data subjects,
        as required by the Data Protection Legislation.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 35.45pt;
        margin-bottom: 6pt;
        text-align: justify;
      "
    >
      <span style="font-family: Arial"> </span>
    </p>
    <div style="clear: both">
      <p class="normal0" style="font-size: 8pt">
        <span></span>
        <span></span>
        <span></span>
        <span style="width: 449.6pt; display: inline-block">
           
        </span>
        <span>1</span>
        <span></span>
      </p>
      <p class="normal0"><span> </span></p>
    </div>
  </div>
</div>
`
        }}
        />
    )
}
