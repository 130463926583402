import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseOffices = {
  offices: AppState['offices']
  officesLoading: AppState['officesLoading']
  officesError: AppState['officesError']
  getOffices: (data: RequestTypes['getOffices'], callbacks?: Callbacks) => void
}

export default function useOffices(): UseOffices {
  const { offices, officesLoading, officesError } = useSelector(
    (state: AppState) => ({
      offices: state.offices,
      officesLoading: state.officesLoading,
      officesError: state.officesError,
    }),
  )
  const dispatch = useDispatch()
  const getOffices = useCallback(
    (data: RequestTypes['getOffices'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getOffices(data, callbacks))
    },
    [dispatch],
  )
  return {
    offices,
    officesLoading,
    officesError,
    getOffices,
  }
}
