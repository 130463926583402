import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseConfirmSignin = {
  confirmSignin: AppState['confirmSignin']
  confirmSigninLoading: AppState['confirmSigninLoading']
  confirmSigninSaving: AppState['confirmSigninSaving']
  confirmSigninError: AppState['confirmSigninError']
  createConfirmSignin: (
    data: RequestTypes['createConfirmSignin'],
    callbacks?: Callbacks,
  ) => void
}

export default function useConfirmSignin(): UseConfirmSignin {
  const {
    confirmSignin,
    confirmSigninLoading,
    confirmSigninSaving,
    confirmSigninError,
  } = useSelector((state: AppState) => ({
    confirmSignin: state.confirmSignin,
    confirmSigninLoading: state.confirmSigninLoading,
    confirmSigninSaving: state.confirmSigninSaving,
    confirmSigninError: state.confirmSigninError,
  }))
  const dispatch = useDispatch()

  const createConfirmSignin = useCallback(
    (data: RequestTypes['createConfirmSignin'], callbacks?: Callbacks) => {
      return dispatch(AppActions.createConfirmSignin(data, callbacks))
    },
    [dispatch],
  )

  return {
    confirmSignin,
    confirmSigninLoading,
    confirmSigninSaving,
    confirmSigninError,
    createConfirmSignin,
  }
}
