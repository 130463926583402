export default function () {
    return (
        <div dangerouslySetInnerHTML={{
            __html: `<div class="container content-page mx-4">
  <div>
    <p class="normal0" style="widows: 0; orphans: 0">
      <span> </span>
    </p>
    <table
      cellspacing="0"
      cellpadding="0"
      class="Table1"
      style="
        width: 454.7pt;
        margin-left: 5pt;
        border: 1pt solid #000000;
        -aw-border-insideh: 1pt single #000000;
        -aw-border-insidev: 1pt single #000000;
        border-collapse: collapse;
      "
    >
      <tbody>
        <tr style="height: 57.25pt">
          <td style="padding: 4.5pt; vertical-align: top">
            <p
              class="normal0"
              style="
                text-align: center;
                line-height: normal;
                widows: 0;
                orphans: 0;
              "
            >
              <span>Legal Utopia Limited</span>
            </p>
            <p
              class="normal0"
              style="
                margin-top: 5.9pt;
                text-align: center;
                line-height: normal;
                widows: 0;
                orphans: 0;
              "
            >
              <span>
                Equal Opportunities and Diversity Policy
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="normal0" style="widows: 0; orphans: 0">
      <span> </span>
    </p>
    <p class="normal0" style="widows: 0; orphans: 0">
      <span> </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 1pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">1.</span>
      <span
        style="
          font-weight: bold;
          text-decoration: underline;
        "
      >
        Policy Statement
      </span>
      <span style="font-weight: bold"></span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.15pt;
        margin-right: 0.3pt;
        margin-left: 0.4pt;
        text-indent: 0.65pt;
        text-align: justify;
        line-height: 143%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        Legal Utopia Limited (“the Company”) is committed to
        achieving a working environment
      </span>
      <span> </span>
      <span>
        which provides equality of opportunity and freedom
        from unlawful discrimination on the
      </span>
      <span> </span>
      <span>
        grounds of race, sex, pregnancy and maternity,
        marital or civil partnership status, gender
      </span>
      <span> </span>
      <span>
        reassignment, disability, religion or beliefs, age
        or sexual orientation. This Policy aims to
      </span>
      <span> </span>
      <span>
        remove unfair and discriminatory practices within
        the Company and to encourage full
      </span>
      <span> </span>
      <span>
        contribution from its diverse community. The Company
        is committed to actively opposing all
      </span>
      <span> </span>
      <span>forms of discrimination.</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 7.35pt;
        margin-left: 0.4pt;
        text-align: justify;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        The Company also aims to provide a service that does
        not discriminate against its clients
      </span>
      <span> </span>
      <span>
        and customers in the means by which they can access
        the services and goods supplied by
      </span>
      <span> </span>
      <span>
        the Company. The Company believes that all employees
        and clients are entitled to be
      </span>
      <span> </span>
      <span>treated with respect and dignity.</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 7.3pt;
        margin-right: 0.75pt;
        margin-left: 0.95pt;
        text-indent: -0.55pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        Any and all personal data used in connection with
        this Policy shall be collected, held, and
      </span>
      <span> </span>
      <span>
        processed in accordance with the Company’s Privacy
        Policy.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 35.35pt;
        margin-left: 0.5pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">2.</span>
      <span
        style="
          font-weight: bold;
          text-decoration: underline;
        "
      >
        Objectives of this Policy
      </span>
      <span style="font-weight: bold"></span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 14.9pt;
        margin-right: 0.4pt;
        margin-left: 36.7pt;
        text-indent: -36.15pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        2.1 To prevent, reduce and stop all forms of
        unlawful discrimination in line with the
      </span>
      <span> </span>
      <span>Equality Act 2010.</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 10.35pt;
        margin-right: 0.45pt;
        margin-left: 36.15pt;
        text-indent: -35.6pt;
        text-align: justify;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        2.2 To ensure that recruitment, promotion, training,
        development, assessment, benefits,
      </span>
      <span> </span>
      <span>
        pay, terms and conditions of employment, redundancy
        and dismissals are determined
      </span>
      <span> </span>
      <span>
        on the basis of capability, qualifications,
        experience, skills and productivity.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 38.35pt;
        margin-left: 0.5pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">3.</span>
      <span
        style="
          font-weight: bold;
          text-decoration: underline;
        "
      >
        Designated Officer
      </span>
      <span style="font-weight: bold"></span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 34.15pt;
        margin-left: 1pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">Name:</span>
      <span>Fraser Matcham</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 15.15pt;
        margin-left: 1.05pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">Position:</span>
      <span>Director</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 56.65pt;
        text-align: center;
        line-height: normal;
        widows: 0;
        orphans: 0;
        font-size: 8pt;
      "
    >
      <span style="font-family: 'Times New Roman'"> </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 0.5pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">4.</span>
      <span
        style="
          font-weight: bold;
          text-decoration: underline;
        "
      >
        Definition of Discrimination
      </span>
      <span style="font-weight: bold"></span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 11.9pt;
        margin-left: 0.6pt;
        text-indent: 0.5pt;
        text-align: justify;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        Discrimination is unequal or differential treatment
        which leads to one person being treated
      </span>
      <span> </span>
      <span>
        more or less favourably than others are, or would
        be, treated in the same or similar
      </span>
      <span> </span>
      <span>
        circumstances on the grounds of race, sex, pregnancy
        and maternity, marital or civil
      </span>
      <span> </span>
      <span>
        partnership status, gender reassignment, disability,
        religion or beliefs, age or sexual
      </span>
      <span> </span>
      <span>
        orientation. Discrimination may be direct or
        indirect, and includes discrimination by
      </span>
      <span> </span>
      <span>perception and association.</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 10.35pt;
        margin-left: 0.5pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">5.</span>
      <span
        style="
          font-weight: bold;
          text-decoration: underline;
        "
      >
        Types of Discrimination
      </span>
      <span style="font-weight: bold"></span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 15.15pt;
        margin-left: 0.5pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">
        5.1 Direct Discrimination
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.15pt;
        margin-right: 0.55pt;
        margin-left: 0.6pt;
        text-indent: -0.15pt;
        text-align: justify;
        line-height: 143%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        This occurs when a person or a policy intentionally
        treats a person less favourably than
      </span>
      <span> </span>
      <span>
        another on the grounds of race, sex, pregnancy and
        maternity, marital or civil partnership
      </span>
      <span> </span>
      <span>
        status, gender reassignment, disability, religion or
        beliefs, age or sexual orientation.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 10.4pt;
        margin-left: 0.5pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">
        5.2 Indirect Discrimination
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.15pt;
        margin-right: 0.65pt;
        margin-left: 0.65pt;
        text-indent: -0.25pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        This is the application of a policy, criterion or
        practice which the employer applies to all
      </span>
      <span> </span>
      <span>employees but which is such that:</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 8.05pt;
        margin-right: 0.4pt;
        margin-left: 36.9pt;
        text-indent: -18.05pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-family: 'Noto Sans Symbols'">
        •
      </span>
      <span>
        It is detrimental to a considerably larger
        proportion of people from the group that the
      </span>
      <span> </span>
      <span>
        person the employer is applying it to represents;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 8.05pt;
        margin-right: 0.45pt;
        margin-left: 36.9pt;
        text-indent: -18.05pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-family: 'Noto Sans Symbols'">
        •
      </span>
      <span>
        The employer cannot justify the need for the
        application of the policy on a neutral
      </span>
      <span> </span>
      <span>basis; and</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 8.05pt;
        margin-right: 0.6pt;
        margin-left: 36.65pt;
        text-indent: -17.8pt;
        line-height: 142%;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-family: 'Noto Sans Symbols'">
        •
      </span>
      <span>
        The person to whom the employer is applying it
        suffers detriment from the application
      </span>
      <span> </span>
      <span>of the policy.</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 7.55pt;
        margin-right: 0.65pt;
        margin-left: 0.6pt;
        text-indent: 0.5pt;
        text-align: justify;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        Example: A requirement that all employees must be
        6ft tall if that requirement is not justified
      </span>
      <span> </span>
      <span>
        by the position would indirectly discriminate
        against employees with an oriental ethnic origin,
      </span>
      <span> </span>
      <span>
        as they are less likely to be able to fulfil this
        requirement.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 10.3pt;
        margin-left: 0.5pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">5.3 Harassment</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 6.15pt;
        margin-right: 1.2pt;
        margin-left: 0.6pt;
        text-indent: -0.2pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        This occurs when a person is subjected to unwanted
        conduct that has the purpose or effect
      </span>
      <span> </span>
      <span>
        of violating their dignity or creating an
        intimidating, hostile, degrading, humiliating or
        offensive
      </span>
      <span> </span>
      <span>environment.</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 10.3pt;
        margin-left: 0.5pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">
        5.4 Victimisation
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 5.9pt;
        margin-right: 6.9pt;
        margin-left: 0.85pt;
        text-indent: -0.45pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        This occurs when a person is treated less favourably
        because they have bought or intend to
      </span>
      <span> </span>
      <span>
        bring proceedings or they have given or intend to
        give evidence.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 49.85pt;
        text-align: center;
        line-height: normal;
        widows: 0;
        orphans: 0;
        font-size: 8pt;
      "
    >
      <span style="font-family: 'Times New Roman'"> </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 0.55pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">6.</span>
      <span
        style="
          font-weight: bold;
          text-decoration: underline;
        "
      >
        Unlawful Reasons for Discrimination
      </span>
      <span style="font-weight: bold"></span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 14.9pt;
        margin-left: 0.55pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">6.1 Sex</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.15pt;
        margin-left: 0.6pt;
        text-indent: 0.6pt;
        text-align: justify;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        It is not permissible to treat a person less
        favourably on the grounds of sex, marital status,
      </span>
      <span> </span>
      <span>
        civil partnership, pregnancy or maternity, gender
        reassignment or transgender status. This
      </span>
      <span> </span>
      <span>
        applies to men, women and those undergoing or
        intending to undergo gender reassignment.
      </span>
      <span> </span>
      <span>
        Sexual harassment of men and women can be found to
        constitute sex discrimination.
      </span>
      <span> </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 7.35pt;
        margin-right: 0.7pt;
        margin-left: 0.65pt;
        text-indent: 0.5pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        Example: Asking a woman during an interview if she
        is planning to have any (more) children
      </span>
      <span> </span>
      <span>
        constitutes discrimination on the ground of gender.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 10.3pt;
        margin-left: 0.55pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">6.2 Age</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.15pt;
        margin-right: 0.65pt;
        margin-left: 0.85pt;
        text-indent: 0.35pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        It is not permissible to treat a person less
        favourably because of their age. This applies to
      </span>
      <span> </span>
      <span>
        people of all ages. This does not currently apply to
        the calculation of redundancy payments.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 10.05pt;
        margin-left: 0.55pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">6.3 Disability</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.15pt;
        margin-left: 0.6pt;
        text-indent: 0.6pt;
        text-align: justify;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        It is not permissible to treat a disabled person
        less favourably than a non-disabled person.
      </span>
      <span> </span>
      <span>
        Reasonable adjustments must be made to give the
        disabled person as much access to any
      </span>
      <span> </span>
      <span>
        services and ability to be employed, trained, or
        promoted as a non-disabled person.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 10.3pt;
        margin-left: 0.55pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">6.4 Race</span>
      <span style="font-weight: bold"> </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.15pt;
        margin-right: 0.65pt;
        margin-left: 0.6pt;
        text-indent: 0.6pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        It is not permissible to treat a person less
        favourably because of their race, the colour of
        their
      </span>
      <span> </span>
      <span>
        skin, their nationality or their ethnic origin.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 10.3pt;
        margin-left: 0.55pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">
        6.5 Sexual Orientation
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.15pt;
        margin-right: 0.55pt;
        margin-left: 0.65pt;
        text-indent: 0.55pt;
        text-align: justify;
        line-height: 143%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        It is not permissible to treat a person less
        favourably because of their sexual orientation. For
      </span>
      <span> </span>
      <span>
        example, an employer cannot refuse to employ a
        person because s/he is homosexual,
      </span>
      <span> </span>
      <span>heterosexual or bisexual.</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 10.4pt;
        margin-left: 0.55pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">
        6.6 Religion or Belief
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.15pt;
        margin-right: 0.6pt;
        margin-left: 1.05pt;
        text-indent: 0.15pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        It is not permissible to treat a person less
        favourably because of their religious beliefs or
        their
      </span>
      <span> </span>
      <span>
        religion or their lack of any religion or belief.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 35.35pt;
        margin-left: 0.5pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">7.</span>
      <span
        style="
          font-weight: bold;
          text-decoration: underline;
        "
      >
        Positive Action in Recruitment
      </span>
      <span style="font-weight: bold"></span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 20.15pt;
        margin-left: 0.4pt;
        text-indent: 0.7pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        Under the Equality Act 2010, positive action in
        recruitment and promotion applies as of 6
      </span>
      <span> </span>
      <span>
        April 2011. ‘Positive action’ means the steps that
        the Company can take to encourage people
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-right: 2.45pt;
        margin-left: 0.6pt;
        text-indent: -0.2pt;
        line-height: 142%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        from groups with different needs or with a past
        record of disadvantage or low participation, to
      </span>
      <span> </span>
      <span>apply for positions within the Company.</span>
      <span> </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 15.55pt;
        margin-right: 9pt;
        margin-left: 0.6pt;
        text-indent: 0.6pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        If the Company chooses to utilise positive action in
        recruitment, this will not be used to treat
      </span>
      <span> </span>
      <span>
        people with a protected characteristic more
        favourably, it will be used only in tie-break
      </span>
      <span> </span>
      <span>
        situations, when there are two candidates of equal
        merit applying for the same position.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 23.1pt;
        margin-left: 0.6pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">8.</span>
      <span
        style="
          font-weight: bold;
          text-decoration: underline;
        "
      >
        Reasonable Adjustments
      </span>
      <span style="font-weight: bold"></span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.15pt;
        margin-right: 0.55pt;
        margin-left: 0.6pt;
        text-indent: -0.2pt;
        line-height: 142%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        The Company has a duty to make reasonable
        adjustments to facilitate the employment of a
      </span>
      <span> </span>
      <span>disabled person. These may include:</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 10.5pt;
        margin-left: 0.65pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>8.1 Making adjustments to premises;</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 15.15pt;
        margin-left: 0.65pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        8.2 Re-allocating some or all of a disabled
        employee’s duties;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 15.15pt;
        margin-right: 66.55pt;
        margin-left: 0.65pt;
        line-height: 212%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        8.3 Transferring a disabled employee to a role
        better suited to their disability; 8.4 Relocating a
        disabled employee to a more suitable office;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 2.85pt;
        margin-right: 32.85pt;
        margin-left: 0.65pt;
        line-height: 212%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        8.5 Giving a disabled employee time off work for
        medical treatment or rehabilitation; 8.6 Providing
        training or mentoring for a disabled employee;
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 2.8pt;
        margin-right: 0.5pt;
        margin-left: 36.2pt;
        text-indent: -35.5pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        8.7 Supplying or modifying equipment, instruction
        and training manuals for disabled
      </span>
      <span> </span>
      <span>employees; or</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 10.05pt;
        margin-right: 0.45pt;
        margin-left: 0.65pt;
        text-align: center;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        8.8 Any other adjustments that the Company considers
        reasonable and necessary
      </span>
      <span> </span>
      <span>
        provided such adjustments are within the financial
        means of the Company.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 7.3pt;
        margin-right: 0.6pt;
        margin-left: 0.7pt;
        text-indent: 0.5pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        If an employee has a disability and feels that any
        such adjustments could be made by the
      </span>
      <span> </span>
      <span>
        Company, they should contact the Designated Officer.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 35.3pt;
        margin-left: 0.55pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">9.</span>
      <span
        style="
          font-weight: bold;
          text-decoration: underline;
        "
      >
        Responsibility for the Implementation of this Policy
      </span>
      <span style="font-weight: bold"></span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.15pt;
        margin-right: 0.4pt;
        margin-left: 0.6pt;
        text-indent: -0.2pt;
        text-align: justify;
        line-height: 143%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        All employees, subcontractors and agents of the
        Company are required to act in a way that
      </span>
      <span> </span>
      <span>
        does not subject any other employees or clients to
        direct or indirect discrimination,
      </span>
      <span> </span>
      <span>
        harassment or victimisation on the grounds of their
        race, sex, pregnancy or maternity, marital
      </span>
      <span> </span>
      <span>
        or civil partnership status, gender reassignment,
        disability, religion or beliefs, age or sexual
      </span>
      <span> </span>
      <span>orientation.</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 7.4pt;
        margin-left: 0.65pt;
        text-indent: -0.25pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        The cooperation of all employees is essential for
        the success of this Policy. Senior
      </span>
      <span> </span>
      <span>
        employees are expected to follow this Policy and to
        try to ensure that all employees,
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 0.6pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>subcontractors and agents do the same.</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 11.9pt;
        margin-right: 0.6pt;
        margin-left: 0.4pt;
        text-indent: 0.75pt;
        text-align: justify;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        Employees may be held independently and individually
        liable for their discriminatory acts by
      </span>
      <span> </span>
      <span>
        the Company and in some circumstances an Employment
        Tribunal may order them to pay
      </span>
      <span> </span>
      <span>
        compensation to the person who has suffered as a
        result of discriminatory acts.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 7.3pt;
        margin-right: 2.9pt;
        margin-left: 0.4pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        The Company takes responsibility for achieving the
        objectives of this Policy, and endeavours
      </span>
      <span> </span>
      <span>
        to ensure compliance with relevant Legislation and
        Codes of Practice.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 35.35pt;
        margin-left: 1pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">10.</span>
      <span
        style="
          font-weight: bold;
          text-decoration: underline;
        "
      >
        Acting on Discriminatory Behaviour
      </span>
      <span style="font-weight: bold"></span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.15pt;
        margin-right: 0.55pt;
        margin-left: 0.85pt;
        text-indent: 0.35pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        In the event that an employee is the subject or
        perpetrator of, or witness to, discriminatory
      </span>
      <span> </span>
      <span>
        behaviour, please refer to the Company Handbook and
        your contract of employment.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 35.1pt;
        margin-left: 1pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">11.</span>
      <span
        style="
          font-weight: bold;
          text-decoration: underline;
        "
      >
        Advice and Support on Discrimination
      </span>
      <span style="font-weight: bold"></span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.15pt;
        margin-right: 0.3pt;
        margin-left: 0.95pt;
        text-indent: 0.2pt;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        Employees may contact their employee or trade union
        representative if access to such an
      </span>
      <span> </span>
      <span>individual is possible.</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 7.3pt;
        margin-left: 0.65pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>Other contacts include:</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 24.9pt;
        margin-left: 19.1pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">
        Equality and Human Rights Commission
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 19.05pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-style: italic">Manchester</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 18.4pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>Arndale House</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 18.4pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>The Arndale Centre</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 19.05pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>Manchester</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 19.05pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>M4 3AQ</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.65pt;
        margin-left: 19.1pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-style: italic">London</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 19.2pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>Fleetbank House</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 18.55pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>2-6 Salisbury Square</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 19.1pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>London</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 19.2pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>EC4Y 8JX</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.4pt;
        margin-left: 19.45pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-style: italic">Cardiff</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 19.05pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>Block 1, Spur D, Government Buildings</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 18.8pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>St Agnes Road</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 18.8pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>Gabalfa</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 18.75pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>Cardiff</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 18.75pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>CF14 4YJ</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.4pt;
        margin-left: 19.45pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-style: italic">Glasgow</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 19.35pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>151 West George Street</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 18.8pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>Glasgow</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 18.8pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>G2 2JJ</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.4pt;
        margin-left: 19.1pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-style: italic">
        Helpline Telephone Number:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 19.2pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>Phone: 0808 800 0082</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 18.4pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>Textphone: 0808 800 0084</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.15pt;
        margin-left: 18.45pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
        font-size: 12pt;
      "
    >
      <span style="font-size: 11pt">Website:</span>
      <span
        style="text-decoration: underline; color: #0000ff"
      >
        www.equalityhumanrights.com
      </span>
      <span style="color: #0000ff"></span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.75pt;
        margin-left: 18.75pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">
        Citizens Advice Bureau
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 18.6pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
        font-size: 11.5pt;
      "
    >
      <span style="font-size: 11pt">3</span>
      <span
        style="font-size: 7.67pt; vertical-align: super"
      >
        rd
      </span>
      <span style="font-size: 11pt">Floor North</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 18.55pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>200 Aldersgate Street</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 19.1pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>London</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 19.2pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>EC1A 4HD</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.4pt;
        margin-left: 18.45pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
        font-size: 12pt;
      "
    >
      <span style="font-size: 11pt">Website:</span>
      <span
        style="text-decoration: underline; color: #0000ff"
      >
        www.citizensadvice.org.uk
      </span>
      <span style="color: #0000ff"></span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 25.2pt;
        margin-left: 18.75pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">
        Community Legal Services Direct
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 12.4pt;
        margin-left: 18.4pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>Telephone: 0845 345 4 345</span>
    </p>
    <p
      class="normal0"
      style="
        margin-left: 18.45pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span>Website:</span>
      <span
        style="text-decoration: underline; color: #0000ff"
      >
        www.clsdirect.uk
      </span>
      <span style="color: #0000ff"></span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 27.65pt;
        margin-left: 1pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">12.</span>
      <span
        style="
          font-weight: bold;
          text-decoration: underline;
        "
      >
        The Extent of the Policy
      </span>
      <span style="font-weight: bold"></span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 15.15pt;
        margin-right: 0.25pt;
        margin-left: 36.15pt;
        text-indent: -34.8pt;
        text-align: justify;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        12.1 The Company seeks to apply this Policy in the
        recruitment, selection, training,
      </span>
      <span> </span>
      <span>
        appraisal, development and promotion of all
        employees. The Company offers goods
      </span>
      <span> </span>
      <span>
        and services in a fashion that complies with the
        spirit of this Policy.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 10.3pt;
        margin-right: 0.05pt;
        margin-left: 35.9pt;
        text-indent: -34.55pt;
        text-align: justify;
        line-height: 144%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        12.2 This Policy does not form a part of any
        employment contract with any employee and
      </span>
      <span> </span>
      <span>
        its contents are not to be regarded by any person as
        implied, collateral or express
      </span>
      <span> </span>
      <span>
        terms to any contract made with the Company.
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 10.3pt;
        margin-right: 44.5pt;
        margin-left: 0.4pt;
        text-indent: 0.95pt;
        line-height: 212%;
        widows: 0;
        orphans: 0;
      "
    >
      <span>
        12.3 The Company reserves the right to amend and
        update this Policy at any time.
      </span>
      <span style="font-weight: bold">
        This policy has been approved &amp; authorised by:
      </span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 2.6pt;
        margin-left: 1pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">Name:</span>
      <span>Fraser Matcham</span>
    </p>
    <p
      class="normal0"
      style="
        margin-top: 15.15pt;
        margin-left: 1.05pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
      "
    >
      <span style="font-weight: bold">Position:</span>
      <span>Director</span>
    </p>
  </div>
</div>
`
        }}
        />
    )
}
