import React from "react";
import { Popup, } from "reactjs-popup";
import { PopupProps } from "reactjs-popup/dist/types"



interface MenuItem {
    Icon: any
    label: string
    valuehref?: string
    value?: string
    onClick?:()=>void
}
export const MenuItem: React.FC<MenuItem> = ({ Icon, label, valuehref, value,onClick }) => {
    return (
      <button onClick={onClick} type='button' className='flex flex-col'>
        <li className='flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100'>
          <Icon size={24} />
          <span className='ml-3'>
            {label}
            {valuehref && value ? <a href={valuehref}>{value} </a> : value}
          </span>
        </li>
      </button>
    );
}

interface InfoPopupProps extends PopupProps {
    triggerrer: string | React.ReactNode;
    title?: string;
    description?: string;
    className?: string;
    MenuItems?: JSX.Element[]
    themeColor: {
        hex:string;
        class:string
      },
}



const InfoPopup: React.FC<InfoPopupProps> = ({
    triggerrer,
    title,
    description,
    className,
    MenuItems,
    themeColor,
    ...rest
}) => {
    return (
        <Popup
            trigger={<button type="button">{triggerrer}</button>}
            position="top center"
            on={["hover", "focus"]}
            arrow={true}
            {...rest}
        >
            <div
                className={`inline-block  text-sm font-light text-black bg-white rounded-lg border border-gray-200 shadow-sm ${className}`}
            >
                {MenuItems ? (
                    <ul className="space-y-2 p-6">
                        {MenuItems}
                    </ul>
                ) : (
                    <>
                        <div className={`py-2 px-3 rounded-t-lg border-b border-gray-200 ${themeColor.class} `}>
                            <h5 className="font-bold text-base  dark:text-white text-center">
                                {title}
                            </h5>
                        </div>
                        <div className="p-3">
                            <p>{description}</p>
                        </div>
                    </>

                )}
            </div>
        </Popup>
    );
};

export default InfoPopup;
