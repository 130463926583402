import React, { useState } from "react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

interface StepperProps {
  themeColor: {
    hex: string
    class: string
  }
  steps: any
  getCurrentStep: (step: number) => void
  renderPopupMenu?: React.ReactNode
}

const Stepper: React.FC<StepperProps> = ({
  steps,
  themeColor,
  getCurrentStep,
  renderPopupMenu,
}) => {
  const [currentStep, setcurrentStep] = useState(0)
  const handleNextStep = () => {
    getCurrentStep(currentStep + 1)
    setcurrentStep(currentStep + 1)
  }
  const handlePreviousStep = () => {
    getCurrentStep(currentStep - 1)
    setcurrentStep(currentStep - 1)
  }

  return (
    <div className='flex flex-1 flex-col mt-4 text-black'>
      <div className='h-[320px] overflow-scroll md:flex md:flex-1'>
        {steps[currentStep]}
      </div>
      <div className='flex flex-row flex-wrap'>
        {renderPopupMenu && (
          <div
            className={`flex items-center justify-center h-8 w-8 rounded-full ${themeColor.class}`}
          >
            {renderPopupMenu}
          </div>
        )}
        <div className='flex flex-1 justify-center flex-row items-center'>
          {currentStep !== 0 && (
            <button onClick={handlePreviousStep}>
              <FaChevronLeft size={20} />
            </button>
          )}
          {steps.map((_step, index: number) => {
            const bgColor = index === currentStep ? themeColor.class : 'white'
            return (
              <div
                key={index}
                className={`inline-flex justify-center items-center w-6 h-6 text-base rounded-full mr-1 ${bgColor}`}
              >
                {index + 1}
              </div>
            )
          })}
          {currentStep + 1 !== steps.length && (
            <button onClick={handleNextStep}>
              <FaChevronRight size={20} />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Stepper;
