import React from 'react'
import img1 from "../assets/LawPageImages/EmploymentLaw.png";
import img2 from "../assets/LawPageImages/CommercialLaw.png";
import img3 from "../assets/LawPageImages/CorporateLaw.png";
import img4 from "../assets/LawPageImages/PlanningPropertyLandLaw.png";
import img5 from "../assets/LawPageImages/CivilLaw.png";
import img6 from "../assets/LawPageImages/ContractLaw.png";
import img7 from "../assets/LawPageImages/DataProtectionLaw.png";
import img8 from "../assets/LawPageImages/FamilyLaw.png";
import img9 from "../assets/LawPageImages/IntellectualPropertyLaw.png";
import img10 from "../assets/LawPageImages/TaxLaw.png";

const LawPagesData = [
    {
        id:1,
        img:img1,
        title:"Employment Law",
        captionText:"The obligations, regulations and requirements for employers, employee and self-employed",
        description:"This area of law is extensive and can be complex in nature, concerning the obligations, regulations and requirements for mostly employers and employees. This area also concerns the rights and protections of workers, the self-employed, and freelancers. Employment law is a highly procedural area and seeks to govern the legal framework of employer-employee relationships, ensuring fairness and accountability. Employment rights are established to regulate the treatment of people providing labour, and employment tribunals are set up to hear and resolve employment related disputes, as well as ensuring the protection of rights such as sick pay, holiday, dismissal, and redundancy to name a few.Here is what Employment Law covers:",
        elements:[
            {
                id:1,
                title:"Accountancy",
                description:"Accountancy concerns the financial element of the employer-employee relationship. Things to consider include sick pay, PAYE, workplace pension, furlough pay, holiday entitlements, commission, bereavement, maternity and paternity pay, bonus schemes, deductions from pay for taxes, and the obligation to pay minimum wage."
            },
            {
                id:2,
                title:"Discrimination & Harassment",
                description:"Discrimination is defined as the act of being treated differently and unjustly due to one's race, age, disability or sex. Harassment is the term given to the intimidation or threatening behaviour directed at an individual which makes them feel uncomfortable or offended. This topic concerns the way employers deal with complaints of this kind, as well as how employees who feel they have suffered from harassment, discrimination or victimisation, may report this to their employer or employment tribunal."
            },
            {
                id:3,
                title:"Employment Contracts",
                description:"A standard employment contract details the responsibilities and benefits of employment that have been agreed between an employer and an employee. Terms can include but are not limited to: working hours and payment, holiday entitlement, dress code and the sick day policy."
            },
            {
                id:4,
                title:"Employment Status",
                description:"An individual's legal employment status at work is important in determining their rights, who pays their taxes, and the type of employment contract they are bound by. There are three main categories of status: employee, self-employed and worker. The general and most common status held is employee, whereby they are obliged to follow their contract of employment and to report to an employer."
            },
            {
                id:5,
                title:"Employment Tribunal",
                description:"An Employment Tribunal is a public body whose role is to investigate and make legally binding decisions in employer-employee disputes. These can include disputes over redundancy, unfair/ constructive dismissal, and other grievances. The Rules of Procedure for Employment Tribunals governs the process of making a claim, and details the conditions that the applicant must satisfy to be successful. The Tribunal has legal powers, and can impose cost sanctions, various orders, and compensation for the employee."
            },
            {
                id:6,
                title:"Grievance",
                description:"Grievance is the term used to describe a complaint raised by an employee at their place of work, whereby they are unhappy with designated tasks, terms of their employment contract or their treatment in the workplace. Employers normally have procedures in place for employees to confidently and confidentially raise grievances with the relevant senior staff member, which is noted in the employee handbook or provided by HR. This area covers how to raise a grievance as an employee, as well as how to deal with a grievance as an employer."
            },{
                id:7,
                title:"Health & Safety",
                description:"Health and Safety is the term used for the regulations and procedures that are put in place to minimise risk of harm or accidents. There are a number of health and safety regulations in place for employers to apply to their work environments, to mitigate risk of injury to their employers. Obligations are placed on employers to: follow health and safety procedures, supply adequate personal protective equipment and work equipment to their employees, as well as adequate safety training, take steps to mitigate risks and improve standards when they fall below a certain threshold of safety, and have a protocol in place for reporting and dealing with accidents in the workplace. There are serious legal and financial consequences should an employer be found to be in breach of health and safety regulations."
            },{
                id:8,
                title:"Holiday Entitlement",
                description:"Employees are legally entitled to a set number of paid holiday days in the year. The amount of holiday differs depending on the company policies, the amount of hours the employee works, and whether it is a full-time or part-time contract. Holiday entitlement is normally detailed in the employment contract, and so an employer denying holiday pay is a breach of contract."
            },{
                id:9,
                title:"Minimun wage",
                description:"National Minimum Wage is the absolute minimum amount of remuneration an employer can pay their employees. All employers are obliged to pay at least this amount, or face penalties and sanctions against their business. The amount paid varies, depending on the age of the employee and whether they are an apprentice or a worker. The National Living wage is paid only to employees who are 23years of age and older. The Standard Adult Rate applies to those 21-22, Development Rate to those 18-20, and Young Worker Rate those 16-17 years of age."
            },{
                id:10,
                title:"Parental Leave",
                description:"Parental Leave is the term used when employees wish to take time off work to care for their child. Employment rights, such as the right to pay and to return to work, are protected while on parental leave, however the leave itself is unpaid. Parental Leave entitlement can be carried over to a new job, as the amount of leave applies to each child, not an individual's employment. Maternity/ Paternity Leave is different, and employees are entitled to payment throughout this period. Unlike parental leave, maternity/paternity leave is used just before and after an employee becomes a parent to a newborn baby, for a set amount of time as agreed by the employer."
            },{
                id:11,
                title:"Recruitment",
                description:"The recruitment process for an employer involves investigation into the background of the candidate, such as immigration and DBS checks where applicable; ensuring references are collected from previous employers; and making sure that equal opportunity is given to all candidates, regardless of gender, disability, ethnicity, race, religion or sexual orientation. The employer will also consider unpaid work trials, probation periods and induction days/training for new recruits."
            },{
                id:12,
                title:"Redundancy",
                description:"Redundancy is the term used to describe the premature termination of employment by an employer. The redundancy process takes place when a company needs to reduce the size of its workforce, for financial or production reasons. If the individual satisfies certain conditions, such as having worked with the company for more than two years and being classed as an employee, then they are eligible for statutory redundancy pay, whereby they are entitled to receive a certain amount of money for each year of service at the company. The employer is required to pay this, although if for financial reasons they cannot, it will be funded by the State."
            },{
                id:13,
                title:"TUPE",
                description:"TUPE is the regulation which governs the process of a business being transferred into new ownership. There is a transfer of undertakings, and an obligation is set on the new employer to protect the employment of the current employees of the business. Employers have a duty to inform the employees of their intention to transfer ownership, and the terms of the employment contracts are carried over to the new employer, which normally cannot be changed, with the exception of an 'economic, technical or organisational reason.'"
            },{
                id:14,
                title:"Trade Union Rights",
                description:"A Trade Union is an organised association made up of worker 'members', who enforce employees rights. Employees are entitled to join a trade union if they wish, and become a member. Representatives of the Union negotiate with employers on behalf of a body of workers, to achieve a desired goal, such as a fairer employment contracts, increased pay, or an improvement to the standard of working conditions."
            },{
                id:15,
                title:"Unfair/Constructive Dismissal",
                description:"Unfair dismissal is the term used should an individual believe that they have been wrongfully fired or subsequently removed from employment by their employer. Constructive dismissal is when an employee has to leave their job due to the creation of a unworkable work environment by the employer, through conduct. The conduct of the employer should amount to a breach of contract which could be a serious incident or a series of incidents that are serious when taken together."
            },{
                id:16,
                title:"Whistleblowing",
                description:"Whistleblowing is the term used to describe the actions of a worker reporting a wrongdoing in the workplace, in the public interest. Whistleblowers are protected by law, and reveal serious types of wrongdoing, such as if someone's health or safety is in danger, criminal offences, or miscarriages of justice."
            },{
                id:17,
                title:"Working Hours",
                description:"Unfair dismissal is the term used should an individual believe that they have been wrongfully fired or subsequently removed from employment by their employer. Constructive dismissal is when an employee has to leave their job due to the creation of a unworkable work environment by the employer, through conduct. The conduct of the employer should amount to a breach of contract which could be a serious incident or a series of incidents that are serious when taken together."
            },{
                id:18,
                title:"Workplace Pension",
                description:"A workplace pension scheme is automatically set up by an employer, when employment commences. A portion of the employee's salary is put into their pension scheme, and the employer and government also contribute to the scheme, so that on retirement, the employee will have accumulated an amount of money to live off. Tax relief is applied to workplace pensions if certain criteria is satisfied, and there is an opt-out system in place, so the employee must inform the employer if they do not wish to be enrolled in the scheme."
            }
        ]
    },
    {
        id:2,
        img:img2,
        title:"Commercial Law",
        captionText:"Concerned with conducts, rights and relations of persons and business engaged in trading.",
        description:"Commercial Law is the body of law concerned with the conduct, rights and relations of persons and businesses engaged in trading. It concerns agreements made between consumers and businesses, the express and implied terms of those agreements, as well as methods of trading and the various types of business structures.The development of agency, personal property, domestic sales, international commercial transactions, as well as areas of insurance, credit, financing, securities, bills of exchanges, and banking come within the umbrella term of Commercial Law.Here is what Commercial Law covers:",
        elements:[
            {
                id:1,
                title:"Advertising standards",
                description:"Advertising Standards are set out by the Advertising Standards Authority (ASA), an independent advertising regulator, that covers a wide range of advertisements. When businesses use adverts, they must comply with regulations and procedures put in place by the ASA, or risk legal consequences. For example, advertisements must be legal, honest and decent, and must not cause harm or offence."
            },
            {
                id:2,
                title:"Credit Agreements",
                description:"A Credit Agreement is a contract between a lender and a borrower, in which the terms of a loan are set out. The lender will agree to supply the borrower with credit, for instance, through a credit card, and the contract details the terms on which the borrowed funds are to be repaid to the lender. An interest rate is applied to the borrowed amount, which can build up, the longer the funds are borrowed. Also, some agreements have additional fees attached to them. A fixed term to pay back the borrowed funds at a specific rate of interest is agreed in the contract, and there are consequences for the borrowers' breach of these terms."
            },
            {
                id:3,
                title:"Distribution Agreements",
                description:"A Distribution Agreement is a legal contract between a supplier/manufacturer of a product, and a distributor of that product. The distributor purchases the goods from the supplier, and then resells them to its customers. A Distribution Agreement can be exclusive, non-exclusive, or selective. If exclusive, the supplier is restricted from selling its products to other distributors or others in that same territory. Selective agreements allow the supplier to contract with distributors only if they meet a certain criteria. Non-exclusive agreements give freedom to the supplier to contract with as many distributors as they wish."
            },
            {
                id:4,
                title:"E-commerce",
                description:"E-commerce is the term associated with the buying and selling of products and services over the internet. Transactions made on websites between consumers and businesses, services provided business to business, or even products sold and bought from consumer to consumer, all fall under the concept of e-commerce."
            },
            {
                id:5,
                title:"Franchise Agreement",
                description:"A Franchise Agreement is a legally binding contract between a franchisor and a franchisee, and concerns the transferring of the right to open shops and sell products or services using the franchisor's brand and assets. Essentially, the agreement should set out how the brand can and cannot be used by the franchisee. Franchising is used to expand businesses and make the brand's products and services accessible on a national or international scale."
            },
            {
                id:6,
                title:"Hire Purchase Agreement",
                description:"A Hire Purchase Agreement is a form of borrowing, and involves a consumer hiring a product from a lender for use, in exchange for payment in instalments. Until the consumer has paid the entire value of the product to the lender, they may not sell or dispose of the product without the lender's permission. If payments are not made on time, the lender has the right of repossession of the product."
            },
            {
                id:7,
                title:"Joint Ventures",
                description:"A Joint Venture is a business arrangement between two or more parties, whereby each participant contributes money or resources to achieve a common goal or succeed in a task, such as a new business idea. Each participant is responsible for profits, losses and costs of the venture. If successful, the profits are split among the participants of the joint venture."
            },
            {
                id:8,
                title:"Motor Loan Agreements",
                description:"A Motor Loan Agreement is an agreement between a consumer, lender and vehicle company, whereby the consumer agrees to pay monthly instalments in exchange for exclusive use of a vehicle. The lender then transfers the full price of the vehicle to the car company, and retains ownership of the vehicle until the final payment is made to them, at which time the consumer can choose to make a balloon payment and keep the vehicle, or sell the vehicle."
            },
            {
                id:9,
                title:"Trading Licences",
                description:"A trading licence is a licence legally required by a business in order to commence or conduct certain trading activities or activities in a particular place or method. The licence provides legal recognition to the business and permits the activity or trade of buying or selling products or services in the permitted way."
            },
            {
                id:10,
                title:"Sales and Supply of goods",
                description:"The Sale and Supply of Goods is governed by the corresponding legislation, which regulates commercial contracts. Through this legislation, implied terms are inserted into commercial contracts for the supply of goods, such as the obligation to ensure that goods are of satisfactory quality and sellable."
            },{
                id:11,
                title:"Supply of services",
                description:"The Supplying of Services is regulated by supply of goods and services legislation, concerning the regulation of businesses providing a service (the action of helping or doing work for someone). When supplying a service, a contract is normally drafted, containing both express terms and implied terms, depending on the context of the contractual relationship. The implied terms apply under statute and cannot be excluded."
            },
        ]
    },{
        id:3,
        img:img3,
        title:"Corporate Law",
        captionText:"Covers incorporation, management and control of companies governed by various laws.",
        description:"This area of law is highly administrative and formal with the incorporation, management and control of companies governed by various Companies Acts. It concerns the ways companies utilise their assets and shares to raise capital, distribute profits, and regulates the controls on shareholders and directors in companies.The vast majority of corporate law comes from the Companies Act 2006 which sets out minimum requirements on how companies, as separate legal entities, must be run to maintain a set of standards and protections.Here is what Corporate Law covers:",
         elements:[
            {
                id:1,
                title:"Company Admin",
                description:"Companies are obliged to record meetings and file certain forms with Companies House, both internally and with the registrar of companies in the UK. Documents such as board minutes, resolutions and corresponding forms, are to be registered and stored, to maintain a comprehensive record of decisions made by shareholders and directors of the company. The Companies Act details the deadlines for filing these documents with Companies House. Important documents regarding the incorporation of the business, such as the certificate of incorporation and the articles of association, are also essential documents that must be filed correctly."
            },{
                id:2,
                title:"Company Asset Transfer",
                description:"A Company Asset Transfer is the process of transferring ownership of assets from one party to another, in this context, between companies. A Company will agree to sell their assets to another company for an agreed price. Business assets include tangible and intangible goods, such as furniture, equipment, or intellectual property."
            },{
                id:3,
                title:"Company Incorporation",
                description:"In order for a company to be legally recognised, it must first be established through a process of incorporation at the Registrar of Companies. The Companies Act sets out the procedure of incorporation, which includes providing information about the shareholders, people with significant control of the company, directors, and the articles of association the company will be governed by. Once complete, the company will receive a certificate of incorporation from the Registrar of Companies and will appear on Companies House, confirming that the company legally exists and is registered under a unique company number."
            },{
                id:4,
                title:"Company Insolvency",
                description:"Company Insolvency is the term used when a company is unable to pay its creditors in full, and so its debt outweighs its assets. Companies can go into administration or can be liquidated, and the funds obtained through the selling of their assets go directly to the creditors in order of succession."
            },{
                id:5,
                title:"Company Share Transaction",
                description:"Company Share Transactions concern the selling, cancelling, repurchasing and redeeming of shares in a company. A Company may sell their shares in return for capital, or cancel shares, reducing the amount of shares available in a company. They may also choose to repurchase shares that they have sold to shareholders, or can redeem shares by requiring shareholders to sell some of their shares back to the company. These transactions are regulated by the various Companies Acts."
            },{
                id:6,
                title:"Company Strike-off and dissolution",
                description:"Company Strike-Off is the term used when a company is effectively closed down and dissolved. Dissolution is a cost-effective way of closing down a solvent company that has no assets. The details of the company are removed from the register at Companies House, and it can no longer trade. The business name could then become available for other companies to use."
            },{
                id:7,
                title:"Company Valuation",
                description:"Company Valuation is the process of determining the economic value of an entire business. There are various methods used to calculate this value, such as asset valuation, price earning ratio, a discounted cash flow valuation, entry cost valuation and industry valuation."
            },{
                id:8,
                title:"Director’s Loan agreements",
                description:"When a company makes a loan to one of its directors, or a director makes a loan to the company they work at, a Directors Loan Agreement is drafted, to detail the terms of the arrangement. Company Legislation governs the conditions under which a loan between a director and a company may be made, and details the formalities that must be applied to ensure the loan agreement is not void. Shareholder approval may be required for certain director's loan agreements. "
            },{
                id:9,
                title:"Directorship & Company Secretary",
                description:"Companies select directors to oversee and manage the day-to-day running of a company. Directors are seen as company officers, and they hold directors or 'Board' meetings to vote on changes and agreements regarding the business. There are some decisions that directors must seek shareholder approval for, before they can implement them. A company secretary is sometimes used and responsible for arranging meetings, filing minutes and forms with companies house, and maintaining the company's statutory books and important documents."
            },{
                id:10,
                title:"Equity Investment Agreement",
                description:"An Equity Investment Agreement is an arrangement made between shareholders of a company and an investor, whereby the investor agrees to invest money in the company in exchange for a share of the ownership (equity). The amount of shareholding informs the voting power the investor has will depend on the percentage of shares purchased in exchange for the money invested."
            },{
                id:11,
                title:"Partnership",
                description:"A Partnership is a type of business arrangement between two people who agree to work together to reach a common goal, with the intention of making money. There are different types of partnership, with varying liabilities attached to them. For example, a Limited Liability Partnership (LLP) differs from other types, as it reduces the partners' financial responsibilities."
            },{
                id:12,
                title:"Partnership Insolvency",
                description:"When a partnership's debts outweigh its assets, it becomes insolvent. The partners can declare bankruptcy together in court, and the court will order the winding up of the business by the official receiver. Creditors are restricted from retrieving their funds directly from the partnership if it is wound up, and the partners declared bankrupt."
            },{
                id:13,
                title:"Profit Share Agreement",
                description:"A Profit Share Agreement is a legal contract signed between partners, an employee and employer, or other persons in business together, that sets out the ratio for distribution of profits made as a result of reaching certain targets or achieving a stated objective. This is a formalisation of agreeing a bonus, for instance, for performance with the reward based on the profits of the business in question."
            },{
                id:14,
                title:"Share Capital",
                description:"Share Capital is defined as the amount of money a company raises after selling shares in their company. The Share Capital is detailed on the company's balance sheet, and signifies the total value at risk by the company's shareholders. Company's raise money by selling 'equity' or 'shares' that represent a proportion of ownership of that company. This money forms the value, collectively, that is the Share Capital."
            },{
                id:15,
                title:"Shareholder Dispute",
                description:"Shareholder Disputes are disagreements that occur between the owners of a company. Resolution of these disputes is normally governed by the company's articles of association, however the Companies Acts also assist in determining how disputes of this kind should be handled. A general meeting of the shareholders is normally called to discuss the matter face-to-face and votes on decisions held. If the statutory threshold is met, then the matter is resolved as the shareholders will have made a vote either in favour or against the matter in dispute."
            },{
                id:16,
                title:"Shareholders Agreement",
                description:"A Shareholder agreement is an arrangement made between all or some of the owners of a company, which sets out in a documented form, the way the company will be handled between them. It addresses the role shareholders play, their relationship to the company directors, and the procedure for meetings, voting and making changes to the business."
            }
        ]
    }
    ,{
        id:4,
        img:img4,
        title:"Planning, Property & Land Law",
        captionText:"This combination of laws concerns the ownership and use of land and property in England.",
        description:"This combination of laws concerns the ownership and use of land and property in England. Land law restricts and enforces limitations and conditions on submitting documents, registering interests in land, and changing ownership of land, as well as boundary, nuisance, and rights of use of land. Planning law covers the conditions developers and land owners must satisfy in order to obtain planning permission or other approvals for developments and alterations to their land and property. Property law seeks to protect rights in, and use of, residential and commercial property with a focus on the transaction between landlords and tenants; for instance, the protection from onerous terms and conditions, and to oblige landlords to be fair and reasonable when drafting tenancy agreements.Here is what Planning, Property & Land Law covers:",
         elements:[
            {
                id:1,
                title:"Advertisements",
                description:"An Advertisement is a notice or announcement put on public display which conveys a message or promotes a service or business. Depending on the size, colour and message of the advertisement, planning permission may be required before erecting it on property. There are also restrictions on advertisements included in some tenancy agreements, that require the prior written consent of the landlord."
            },{
                id:2,
                title:"Buildings Insurance",
                description:"When executing either minor renovations or major building works, building insurance should be obtained to protect the owner in the event of an unforeseen or potential accident, that subsequently causes damage to the property. The insurance covers the cost of repairs to the property, and is normally a condition of a mortgage contract, to ensure the lender's interest is protected."
            },{
                id:3,
                title:"Building Regulations",
                description:"Building Regulations Approval is required when carrying out certain building works or alterations to a property. There are enforcement notices that the local planning authorities may impose on any work completed that has not acquired prior approval. Fines can be issued as well as injunctions and enforcement notices, so obtaining guidance on seeking the relevant approvals is important."
            },{
                id:4,
                title:"Commercial Lease Agreement",
                description:"A Commercial Lease Agreement is a contract for the use of property between the property owner and a business. The landlord permits the use of the property by the business, for business purposes, for a set amount of time and rent, and both the landlord and business tenant have rights and responsibilities under the contract, to maintain and repair the building."
            },{
                id:5,
                title:"Conservation Areas",
                description:"A conservation area is an area with a special architectural or historical interest, and which has a character that should be preserved. These areas are specifically protected by law, and generally cannot be demolished or built upon. Local Planning Authorities determine what areas of land are deemed to be a conservation area. Planning permission is required for the smallest of building works or adjustments, and special permission (conservation area consent) is required via an application process, in order to demolish any part of a building in the area. "
            },{
                id:6,
                title:"Conveyancing",
                description:"Conveyancing is the term used to describe the process of buying and selling land or property. It involves a process of executing searches on the land to ensure there are no adverse interests or covenants the buyer shall be bound by on completion; as well as investigating the seller's title to verify their ownership. The process also involves an exchange of contracts, followed by completion, after which certain obligations are created, whereby the acquisition and any mortgages connected to it must be registered with the relevant authorities, and the relevant stamp duty land tax paid, within a strict timeframe."
            },{
                id:7,
                title:"Easements",
                description:"The term Easement is used to describe the right to use or otherwise travel over another individual's land at specific times and/or purposes. The person with the benefit of the easement has certain duties of upkeep and maintenance of the passageway, and the person with the burden is obliged to ensure that the right of way is not obstructed unreasonably. Some easements are binding on successors in title, and so is something to look out for when acquiring land."
            },{
                id:8,
                title:"Freehold Estate",
                description:"A Freehold Estate is an estate in land which provides rights of ownership. There are two types of freehold estate. The first is Fee Simple, which is a permanent tenure in land that gives the owner the ability to sell, renovate and build as they see fit. The second is Life Estate, which is the right of ownership only for the duration of the owner's life."
            },{
                id:9,
                title:"Mortgage",
                description:"A mortgage is a written agreement between a debtor and a bank or building society (the \"lender\"), whereby the latter agrees to lend money to the debtor for part of the title of a property. On repayment of the debt plus interest, the lender's title becomes void, and full ownership of the property transfers to the debtor. Interest is charged on mortgage repayments, and a restriction is placed on the title deeds of the property, prohibiting its sale without first discharging the mortgage in favour of the lender."
            },{
                id:10,
                title:"Nuisance",
                description:"Nuisance is a common law concept which involves the unreasonable interference of an individual's right to peaceful enjoyment of their land. This can include, among other things, light pollution, natural light blockage, smells, or excessive noise from a neighbour. It is actionable in tort and can lead to injunctions being implemented to stop the nuisance, and compensation can be awarded for any permanent loss of enjoyment."
            },{
                id:11,
                title:"Ownership",
                description:"Ownership is the term used when one possesses something, in this context an area of land or a property. Ownership can be obtained through providing consideration via a conveyancing transaction, transferring the legal title from one person to another, or through the process of constant physical occupation and registration of interest. The land owner is entitled to use their land how they see fit, but must not transcend the boundaries of their land."
            },{
                id:12,
                title:"Planning Permission",
                description:"Planning permission is required when carrying out developments on a property, such as a change of use or building works. Some developments do not require planning permission, and are detailed in statute, as permitted developments. It is vital that the owner obtain the necessary planning permissions before commencing work; failure to do so can result in sanctions and other legal consequences such as fines and injunctions."
            },{
                id:13,
                title:"Property Boundaries",
                description:"A boundary, normally displayed as a line on a property plan, marks the dividing of separate areas or points of ownership of land. Physical boundaries can be erected to make the boundary unequivocal, such a fence, a brick wall, or a hedge. Land owners are only entitled to use their own land up to the property boundary, after which, they may be trespassing on another owner's property."
            },{
                id:14,
                title:"Property Taxes",
                description:"Under the umbrella term \"property taxes\", there are a number of taxes to consider. Stamp duty land tax (also known as land and building transaction tax in Scotland) is paid after acquiring a property. VAT is a tax paid by landlords and tenants; capital gains tax is paid when profit is made from the sale of a property which is not the seller's own home; and annual tax on enveloped dwellings, is a tax paid by companies who own expensive residential property."
            },{
                id:15,
                title:"Protected Trees",
                description:"Protected Trees are trees that contribute to the attractiveness and desirability of an area, or are deemed to be a useful feature. A Tree Preservation Order can be put in place by the local planning authority to protect trees of this kind. The order protects the tree(s) against destruction or interference without the prior written consent of the planning authority."
            },{
                id:16,
                title:"Registration",
                description:"The process of registration concerns supplying relevant evidence to HM Land Registry, to register an interest in a piece of land. This interest could be occupation, ownership, or another persons' interest, such as a mortgage obtained via a third party. There are fees to pay during this process, and time constraints that must be adhered to, in order to avoid adverse interests being registered on the land against the owner. Legislation enforces compulsory registration of all unregistered land acquired after 1991. Registration makes it easier to identify ownership and title, and makes selling or transferring the property in the future less complex."
            },{
                id:17,
                title:"Rent Charge",
                description:"A rent charge is an annual sum paid by the owner of freehold land, to a third party with no remaining interest in the land. Historically, rent charges were written into contracts for the sale of freehold land, when owners of large estates would split up and sell their land in separate sections. A common condition of contracts of this kind was that the new owner would continue to pay the seller a nominal amount every year to live off of the land; they are now more commonly used for ongoing maintenance of the land."
            },{
                id:18,
                title:"Residential Tenency Agreements",
                description:"A Residential Tenancy Agreement is a contract between a landlord and tenant for the tenant's private use of the landlord's property, for a set period of time at a rent. Common terms in a tenancy agreement of this kind include but are not limited to: the amount of rent charged, the term commencement date and duration of the term, maintenance and repair obligations, and the ability to transfer the lease through assignment or underletting. While property legislation seeks to reduce any imbalance in rights and responsibilities of the parties to the agreement, tenants should still read the agreement carefully to ensure their responsibilities under the contract are not too onerous."
            },{
                id:19,
                title:"Sites of Scientific interest",
                description:"A site of scientific interest is an area of land protected by law. They tend to be nature reserves or natural habitats for endangered species, and therefore it is imperative to protect the land and prohibit development or interference."
            },{
                id:20,
                title:"Tenants in Common",
                description:"The term Tenants in Common is used to describe the way in which a property is held between its owners. Unlike joint tenants, tenants in common can hold unequal shares of a property. Consequently, there are no restrictions placed on the title register preventing the sale of the property by one person. A tenant in common can sell their interest without the other owner(s) permission. however, no tenant has the power to sell the entire property without first obtaining permission from all other owner(s). Additionally, the law of survivorship does not apply to tenants in common, unlike joint tenants, so on the death of one tenant in common, the deceased's share will pass by their will or intestacy rules, rather than transfer to the other owner(s)."
            },{
                id:21,
                title:"Wayleave",
                description:"The term Wayleave is used to describe a legally binding agreement between landowners and utilities/telecommunication providers, as well as miners, which gives them permission to install and replace equipment that runs under or on the land owner's property. This may be for the purposes of passing sewage or electricity through the land or to extract minerals or raw materials from the land. A Wayleave agreement runs with the land, so any successors in title are bound by the agreement. Providers can sometimes pay a fee for use of the land, and the agreement can also impose an obligation on the energy provider to maintain and pay for upkeep costs of the disturbed land."
            }
        ]
    },{
        id:5,
        img:img5,
        title:"Civil Law",
        captionText:"Extensive area concerning issues and disputes between private citizens.",
        description:"Civil Law is an extensive area concerning issues and disputes between private citizens. In this instance, this area includes disputes concerning private and commercial transactions between private citizens, as well as the protection and enforcement of rights of private citizens. The civil courts hold powers which are used to resolve and enforce these disputes and individual rights; for instance, the issuance of debt orders, bankruptcy petitions, restraining orders, charging orders, money claims and enforcement orders.Here is what Civil Law covers:",
         elements:[
            {
                id:1,
                title:"Bankruptcy",
                description:"Bankruptcy is a legal process imposed by a court order, and normally initiated by a debtor who cannot afford to pay their creditors. By declaring bankruptcy, creditors are barred from seeking the repayment of their funds from the bankrupt individual, and most debts are dissolved after expiry of a statutory time limit. There are both advantages and disadvantages to declaring bankruptcy, with serious financial and legal consequences to consider."
            },{
                id:2,
                title:"Civil Claim",
                description:"A Civil Claim is a formal claim made by an applicant between individuals or individuals and the state. The applicant can address the complaint in a civil court, and can seek remedies, injunctions, sanctions and other forms of relief if they meet certain thresholds and conditions. Generally, civil claims are used to retrieve assets, compensation or enforce personal or contractual rights."
            },{
                id:3,
                title:"Civil Procedure",
                description:"Civil Procedure is detailed in the Civil Procedure Rules which is a framework used by courts and parties to disputes when making or dealing with a civil claim. The Rules concern matters such as time constraints, the way evidence should be handled and produced, the variety of orders available to apply for, and sanctions for non-compliance of rules and orders."
            },{
                id:4,
                title:"Court Enforcement",
                description:"When a party refuses or fails to comply with a court order made against them, the court may put in place a further order or authorise court enforcement agents to enforce the active court order made. This includes, for instance, allowing enforcement agents to remove persons from land or property, as well as the collection or removal of funds or assets to satisfy a debt."
            },{
                id:5,
                title:"Interim Charging order",
                description:"An Interim Charging Order is a court order which allows a creditor to secure his debt against the debtor's property. This is made in the interim, if the court are satisfied that the debtor has a share or full ownership of the property in question. Interim orders can be made without notice, and once obtained, the creditor can register it against the debtor's property, prohibiting the sale of it until the court has made a final order. If a final order is made, the creditor could force the sale of the debtor's property to retrieve what is owed to them if certain conditions are met."
            },{
                id:6,
                title:"Restraining Order",
                description:"A Restraining order is an order put in place by the court which prevents an individual from contacting or visiting the applicant. This type of order can be put in place against an individual for a number of reasons. While the individual has a right to appeal the court's decision, if breached, there are serious consequences, including loss of child custody, being held in contempt of court, fines and imprisonment."
            },{
                id:7,
                title:"Third party Debt order",
                description:"A Third Party Debt Order is a court order which permits a creditor to take what is owed to them directly from a third party who holds the debtors funds; rather than waiting for the debtor to transfer the same to them. The creditor will need to demonstrate that the debtor has the funds available to pay the creditor, but that they are held by a third party (such as a bank or employer)."
            },
        ]
    },{
        id:6,
        img:img6,
        title:"Contract Law",
        captionText:"Contract law concerns the regulation of contracts and the obligations imposed by them.",
        description:"Contract law is notoriously structured and methodical, and seeks to provide a timeless legal framework to follow the contracting between parties. Contract law concerns the regulation of contracts and the obligations imposed by them to maintain certainty and protect parties from unfair or impossible contract terms, as well as the remedies which can be provided by the courts in times of dispute or breach of contract.Here is what Contract Law covers:",
         elements:[
            {
                id:1,
                title:"Breach of contract",
                description:"A Breach of Contract is the term used when a commitment or promise made by one party to an agreement has been broken or is anticipated to be breached. The victim of the breach (the party who suffered a loss) can seek damages and sanctions from the court for the breaching party's fault. Remedies could include compensation, injunctions, and termination of the contract."
            },{
                id:2,
                title:"Consumer credit rights",
                description:"Consumer Credit Rights are detailed in the corresponding legislation and entitle consumers to certain rights when they purchase a product or service using credit rather than an upfront payment. The legislation regulates the use of credit transactions between consumers and traders, to ensure fairness. A common example of a credit transaction is the use of a credit card."
            },{
                id:3,
                title:"Consumer rights",
                description:"Consumer Rights are rights given to individuals who purchase products or services for personal use. An example of a consumer right is the right to a refund or repair. The main rights are detailed in statute and legal remedies are available to consumers who can demonstrate a breach; so traders are legally required to ensure refunds, repairs, and discounts are provided in certain circumstances within strict time-limits."
            },{
                id:4,
                title:"Contract Formalities",
                description:"There are specific formalities that should be adhered to when drafting a contract, in order to ensure its validity and comprehensiveness. These include but are not limited to: making an offer, demonstrating acceptance of the offer, providing consideration for the agreement (payment or benefit of some kind), certainty in the terms agreed between the parties, and expressing an intention to create legal relations through the contract."
            },{
                id:5,
                title:"Misrepresentation",
                description:"Misrepresentation is the term used to describe a false or negligent statement or action made by a party which subsequently induces another party into a contract. There are three types of misrepresentation in contract law: fraudulent, innocent, and negligent. If the induced party can prove that the statements were false, and that they would not have entered into the contract without the statement, then compensation and/or contract termination could be issued. Misrepresentation is governed by both common law and statutory legislation."
            },{
                id:6,
                title:"Signing Contracts",
                description:"Signing a contract signifies the acceptance of the terms within it. There are formalities to bear in mind when signing different contracts in order to ensure their validity. Some contracts require a witness to be present when the document is signed, while others require stamps, or wet ink signatures as opposed to electronic signatures. Acceptance of contract terms does not necessarily require a signature and so could be indicated from conduct, for instance."
            },{
                id:7,
                title:"Termination",
                description:"Termination is the expression used when a contract or agreement is brought to an end, either by expiration or through the invoking of a clause which ends the contract. On termination, parties to the contractual agreement are released from their obligations and responsibilities under the contract."
            },{
                id:8,
                title:"Third Party Rights",
                description:"Third Party Rights give permission to a non-contractual party to enforce, benefit from, or be liable for elements of the contract in question. Legislation governs this area as it rebuts the principle of being aware of a contract, by which only the parties to the contract may enforce the terms of the same."
            },{
                id:9,
                title:"Travel Rights",
                description:"When travelling, consumers are entitled to certain rights concerning issues such as travel delays, cancellations and accommodation standards. Consumers are entitled to complain and seek compensation for faults in these services, so traders and service providers are held accountable to reimburse reasonable costs/losses and pay compensation/refunds for inconvenience. "
            },{
                id:10,
                title:"Unfair Contract terms",
                description:"A contract term is deemed to be unfair if it disproportionately disadvantages or advantages one contractually-bound party over the other. Statute governs this area of law and regulates both business and consumer contracts, to ensure that contract terms are not significantly imbalanced in favour of one party. "
            }
        ]},{
            id:7,
            img:img7,
            title:"Data Protection Law",
            captionText:"Concerns the ways in which personal data is used by businesses, organizations and governments.",
            description:"This area of law is procedural and concerns the ways in which personal data is used by businesses, organisations and governments. It defines and regulates personal data, including the ways it is handled, shared and protected, and enforces legal consequences for breaches of data protection obligations, as well as the creation of personal data rights. Data protection can sometimes be merged with information rights beyond personal including freedom of information, data security and data privacy rights between private individuals, business, and the state.Here is what Data Protection Law covers:",
             elements:[
                {
                    id:1,
                    title:"Cookies",
                    description:"Cookies are small pieces of data containing information which are subsequently downloaded onto a user's device, for example when they browse a website. A cookie saves information from the browsing history of the user and allows the website to analyse what the user searches for and their preferences, to create a more personalised browsing experience for them."
                },
                {
                    id:2,
                    title:"Data Breach",
                    description:"A data breach is an intentional or accidental lapse of security which results in personal data being exposed to unknown or unauthorised persons. Companies generally have a protocol to follow when this happens, and security measures in place to mitigate these risks. Data protection regulations outline the consequences of data breaches, as well as the reporting requirements to the regulator and data subjects"
                },
                {
                    id:3,
                    title:"Data Compliance Formalities",
                    description:"Data Compliance Formalities is the term used to define the common requirements and procedures that businesses and organisations must follow, to ensure the they are meeting the basic necessities of data protection laws and regulations. Businesses and organisations are obliged to follow these formalities in almost all circumstances, or else risk being unable to demonstrate compliance and face financial penalties."
                },
                {
                    id:4,
                    title:"Data Impact Assessments",
                    description:"A Data Impact Assessment is a process which seeks to identify potential risks and mitigations to those risks for projects that may pose a risk to personal data rights such as use of new technologies or processing capabilities."
                },
                {
                    id:5,
                    title:"Direct Marketing",
                    description:"Direct Marketing is the term used to describe a form of promotional communication between businesses, as well as between businesses and consumers. It concerns the rights and preventions to sending and receiving direct marketing communications through mediums such as emails, SMS, phone calls or targeted adverts."
                },
                {
                    id:6,
                    title:"Data Security",
                    description:"Data Security is the term used to describe the protections put in place to prevent personal and sensitive data falling into the wrong hands. Encryption, authentification processes, password protection and data masking are examples of data security procedures. This is a fundamental requirement of personal data processing and used to deter and block unauthorised access or breaches."
                },
                {
                    id:7,
                    title:"Data Sharing",
                    description:"Data sharing is the concept of making data available to other researchers and organisations, so that they may learn from it and analyse it. Sharing data between researchers encourages collaboration and can allow new findings to take place. Due to the priority of protecting individuals' personal data, there are formalities and procedures to be adhered to, such as the carrying out of data protection impact assessments or reviewing data adequacy before personal data is shared."
                },
                {
                    id:8,
                    title:"Data Subject Access Request",
                    description:"A data subject access request is a request that can be made by an individual whose data is processed or held, to seek access to the personal data a business holds about them. Data subjects are entitled to enquire into the business's data source, how they are using the data, and who they are providing it to."
                },
                {
                    id:9,
                    title:"Data Subject Claims",
                    description:"A data subject claim is a grievance that can be brought in court by an identifiable individual whose personal data is used, processed, or retained by organisations and businesses. If an individual has reason to believe their data protection rights have been breached, or that they have suffered damage as a result of misuse or abuse of their data, they can apply to court for compensation from the data controller or processor at fault."
                },{
                    id:10,
                    title:"Data Subject Rights",
                    description:"Data subject rights are rights given to identifiable individuals whose personal data is stored and used by companies and institutions. Rights include but are not limited to: the right to know what data is being collected about the individual, the right to be forgotten, and the right to withdraw consent at any time. Data subjects are also entitled to know if there has been a data breach where they have been put at risk."
                },{
                    id:11,
                    title:"Employee Data",
                    description:"Employee data is the information an employer collects and stores regarding their individual employees. Examples include basic identification data such as name, age, and address of each employee, as well as information like progress and performance levels at work."
                },{
                    id:12,
                    title:"Enforcement",
                    description:"The Information Commissioner governs the enforcement procedure for data protection laws. If any regulation or legislation is breached by a business, the commissioner has the power to impose large fines as both a sanction and a deterrent from future infringement. The Information Commissioner can also bring civil proceedings to search premises, enforce decisions, and prevent ongoing non-compliance."
                },{
                    id:13,
                    title:"Freedom of Information",
                    description:"Freedom of Information is a right of access provided to anyone, allowing them to request recorded information held by public authorities. Freedom of Information legislation governs what information must be made accessible by authorities, as well as how an individual can make an application. It includes strict time-limits, as well as exemptions and exclusions from disclosure to information requests."
                },{
                    id:14,
                    title:"Privacy Notice",
                    description:"A privacy notice is a document put on public display on an organisation's premises, which provides information to data subjects. The notice should detail the identity and contact information of the data controller, what data is used and how, and the company's legal basis for processing the data. Regulation requires businesses and organisations to display a privacy notice as part of the data protection formalities."
                },{
                    id:15,
                    title:"Sensitive Data",
                    description:"Sensitive data is personal information about a data subject which reveals intimate details about them, such as their ethnic origin, health records and genetic data, sexual orientation and political opinions."
                },
            ]
        },{
            id:8,
            img:img8,
            title:"Family Law",
            captionText:"Concerns disputes involving children, between adults and between adults and the state.",
            description:"This area of law can become highly complex depending on the facts of an individual's case. Family law concerns disputes involving children, such as adoption, maintenance, custody and living arrangements, as well as disputes between adults and between adults and the state (social services, for instance). It also includes the recognition of, and legal consequences to, marriage, civil partnerships, divorce and dissolution, as well as the legalities of monetary agreements made before or after marriage or civil partnership.Here is what Family Law covers:",
             elements:[
                {
                    id:1,
                    title:"Adoption",
                    description:"Adoption is the term used to describe the process of legally transferring parental rights and responsibilities of a child, from one set of parents to another. There is a long and intricate procedure involved, and the consent of various parties is required before adoption can be legally binding. The child being adopted becomes the legal child of the adoptive parents and parental responsibility passes to them. "
                },{
                    id:2,
                    title:"Child Maintenance",
                    description:"Child Maintenance is the term used when services and/or funds are provided to maintain the livelihood of a child. It is usually aimed at securing appropriate contributions for the cost of a child's livelihood to achieve the state had a relationship or marriage not ended. The legal liability for child maintenance can vary depending on the specific parental responsibilities and circumstances of the child."
                },{
                    id:3,
                    title:"Children and Child Arrangements",
                    description:"Child Arrangement Orders are court-imposed orders that determine who the child in question is to reside with and who they are to have contact with. Applications can be made for various orders, including contact, residence and prohibited steps orders. Only certain relatives/ guardians may apply to the court. For example, parents who have separated may seek an order of this kind to increase the amount of time they see their child or how many days or nights the child can spend in their custody."
                },{
                    id:4,
                    title:"Children and Local Authorities",
                    description:"Local authorities have a duty to protect and promote the welfare of children in need in their area. Local authorities are given powers, and if a child's circumstances become unsafe or unhealthy, it is the authority's duty to use those powers to act in the best interest of the child in question. Powers can range anywhere between providing daycare for children if a parent cannot do so sufficiently, to removing a child from their home due to risk of harm to the child. "
                },{
                    id:5,
                    title:"Children and Parental responsibility",
                    description:"Parental Responsibility is the duty of a parent to act in the best interests of their child by providing a home, and protecting and maintaining them. Parents who have parental responsibility are also responsible for discipline, education, medical treatment and looking after the child's belonging, among other things. Not every parent has parental responsibility, however, all parents have a duty to contribute to the maintenance of their child financially."
                },{
                    id:6,
                    title:"Children and Where they live",
                    description:"This area overlaps with child arrangements, and concerns the custody, residence and contact arrangements made between parents, regarding their children. Orders can be put in place by the court to determine which parent or relative has primary custody of the child and who the child resides or spends time with. The courts' decisions will be based upon the circumstances and state of family relations, but will prioritise the best interests of the child in question. "
                },{
                    id:7,
                    title:"Cohabitation",
                    description:"Cohabitation is the state of living with another individual in a personal relationship, without being married. This status is not yet recognised by law, so cohabitants do not have legal entitlements to eachothers' possessions, although there is argument to suggest that the longer the cohabitation, the stronger the evidence that there is a substantial loving relationship present."
                },
                {
                    id:8,
                    title:"Divorce and Dissolution",
                    description:"Divorce & Dissolution is the term used to describe the sometimes lengthy and emotional process of permanently ending a marriage or civil partnership by law. There is a legal procedure to be followed to validly dissolve a marriage and negotiations must take place to arrange child custody and the sorting of finances and possessions."
                },
                {
                    id:9,
                    title:"Family Proceedings and Procedures",
                    description:"The Family Procedure Rules are to be followed by those in attendance at court, to ensure proceedings are kept fair and orderly. The best interests of any children will always be the priority of the court. Proceedings can concern matters such as: parental disputes over children, divorce applications, local authority intervention in a child's home, domestic violence, financial support arrangements after divorce, and adoption to name a few. Corresponding applications should be made to the court, and deadlines should be adhered to strictly in accordance with set procedure."
                },
                {
                    id:10,
                    title:"Forced Marriage",
                    description:"Forced marriage is the process of marrying one or more individuals against their will, via intimidation or without their consent or knowledge. Legislation in England and Wales governs the consequences and legal status of forced marriages. Some forms of consent can be deemed invalid and there is common law and statutory guidance on the criteria and legal sanctions imposed on those who organise forced marriage."
                },
                {
                    id:11,
                    title:"Fostering",
                    description:"Fostering is the term used to describe the temporary guardianship of a child who is a ward of the state. Foster parents have a duty to care for the child and act in the child's best interests whilst they are in their care, similarly to natural and adoptive parents; however they are not legally the child's parent."
                },
                {
                    id:12,
                    title:"International Children disputes",
                    description:"International Child Disputes are disagreements between parents on child residence, visiting and maintenance arrangements that include an international element. Different jurisdictions may be involved in the dispute, making child arrangement orders more complex. Disputes can include disagreements about holidays, the duration of international family visits, and other general child travel arrangements made between parents."
                },
                {
                    id:14,
                    title:"Marriage and Civil partnership",
                    description:"Marriage is the legally recognised union of two individuals, whereby they participate in a lifelong commitment to each other and have a personal relationship. A civil partnership is also a legally recognised commitment, similar to marriage, which was created for same-sex couples when they were not allowed to legally marry. Marriage and civil partnerships are now available to both same-sex and opposite sex-couples."
                },
                {
                    id:15,
                    title:"Mediation and Disputes",
                    description:"Mediation is a process used by individuals whose relationship has broken down or a dispute has arisen, and seeks to provide a structured and safe communicating environment, where conversations can take place in the presence of an assisting neutral third party. The aim of mediation is to solve disputes between couples, and allow for compromises to be made before the matter proceeds to court. Active participation by all parties is vital to the success of mediation and sometimes required before court proceedings can be started."
                },{
                    id:16,
                    title:"Prenuptial / Postnuptial Agreements",
                    description:"A Prenuptial Agreement is an agreement made between two individuals before they marry, to determine how they will split their assets should their marriage ever cease. Similarly, postnuptial agreements determine how a couple will split their assets, however this agreement is made after the marriage ceremony has taken place. These types of agreements are generally used when individuals entering a marriage have large assets or wealth. The agreement seeks to protect their assets in the event of dissolution of the marriage"
                }
            ]
        },{
            id:9,
            img:img9,
            title:"Intellectual Property Law",
            captionText:"Covers rights such as design rights, trademarks, copyrights, and patents, as well as their eligibility. ",
            description:"Intellectual Property Law concerns the establishment and subsequent protection of intangible property that is a product of one's creativity. Rights are provided upon those who create a unique design, artistic work, or invention to be used in commerce. It therefore concerns the eligibility for, and enforcement of, rights to the exploitation of intangible property through design rights, trademarks, trade secrets, database rights, copyrights, and patents; and this area also deals with disputes concerning passing off and confidentiality.Here is what Intellectual Property Law covers:",
             elements:[
                {
                    id:1,
                    title:"Confidentiality",
                    description:"Confidentiality is the term used to describe the state of keeping something secret or hidden from public knowledge/ view. Confidential information that is used for commercial purposes can be classed as a business-critical asset or a 'trade secret' that must be protected, although it is not actual property."
                },{
                    id:2,
                    title:"Copyright",
                    description:"Copyright is a form of intellectual property which gives the creator of the design or works, the exclusive right to sell, produce or use their product for a set amount of time. Copyright applies to musical, artistic and literary works, such as films, songs and productions. Copyright prevents others from using the creator's work without their permission."
                },{
                    id:3,
                    title:"Database Rights",
                    description:"Database Rights concern data which is arranged and stored in a methodical way and can be individually accessed, usually by electronic means. Database rights are rights that protect the collection of data/contents of a database, but not its constituent elements. The database does not have to be original, but it must be demonstrated that a substantial investment was made in obtaining, verifying or presenting the data, in order to qualify for these rights to protect it from being copied and/or exploited."
                },{
                    id:4,
                    title:"Design Rights",
                    description:"Design rights are rights given to a creator of a design, and protects the shape and configuration of the design from being duplicated by another creator. To qualify for design rights, the creator will need to prove that it is their design, and evidence the date it was created, through drawings and other documents. Once obtained, the rights last for a set number of years."
                },{
                    id:5,
                    title:"Passing Off",
                    description:"Passing Off is the term used when an individual or business misrepresents itself as another persons' brand or business. Passing off is illegal and the victim can sue the offending business or individual for passing off, even if the applicant has not yet registered their brand or logo. If successful in court, the applicant may be awarded damages for loss of profit, an injunction could be put in place to stop the offending business from passing off, and destruction of the fraudulent goods or services can be ordered by the court."
                },{
                    id:6,
                    title:"Patents",
                    description:"A patent is a legal right granted to an inventor, which protects their invention from other people being able to use, sell, or import it, without the inventor's prior permission. In order to qualify for a patent, the invention must be new, something that can be used, and 'novel'. Applying for a patent is a long and expensive process, and needs to be renewed annually."
                },{
                    id:7,
                    title:"Trademarks",
                    description:"A Trademark is a word, symbol or design that identifies a brand. Names and logos are the most commonly used Trademarks. The Trademark must be unique enough so as not to be mistaken for another brand. Having a trademark makes it easier for consumers to distinguish one product or service from another. There is a process to follow when applying for a trademark; including ensuring that the design or word is not already registered, and that it is not too similar to another registered trademark. Exclusive use of a trademark is awarded if registered correctly. An written inside a circle, symbolises that a brand has been trademarked and cannot be used by other individuals."
                }
            ]
        },{
            id:10,
            img:img10,
            title:"Tax Law",
            captionText:"Regulated the payment of taxes on: goods and services.",
            description:"This is an expansive and continuously changing area of law, concerning the payment of compulsory financial charges imposed on individuals and business by the UK Government. Tax Law regulates the payment of taxes on: goods and services, such as VAT and fuel duty; individuals, such as council tax, income tax, and inheritance tax; and companies, such as corporation tax. HMRC and Tax Tribunals perform an essential role in the interpretation and application of this area of law.Here is what Tax Law covers:",
             elements:[
                {
                    id:1,
                    title:"Business Rates",
                    description:"Business Rates is a tax applied to the occupation of non-domestic property, such as offices, pubs and warehouses. Companies or organisations that use these premises for non-residential purposes, are liable to pay Business Rates, but can sometimes be eligible for reductions through tax relief."
                },{
                    id:2,
                    title:"Capital gains Tax",
                    description:"Capital Gains Tax is the tax paid on the profit made on a sold asset that has increased in value. This Tax is only paid on chargeable assets, and there are some exemptions that are tax-free. There is also a tax-free annual exempt amount, and profit made under this threshold is not liable to be taxed."
                },{
                    id:3,
                    title:"Corporation Tax",
                    description:"Corporation Tax is a tax paid on the profits made by limited companies in the accounting year. The company is responsible for registering and keeping accounting records, in order to calculate how much corporation tax they owe. Taxable profits that are chargeable include trading profits, investments and the selling of assets at a profit."
                },{
                    id:4,
                    title:"Council Tax",
                    description:"This is a tax that adult residential property owners must pay to their local councils, with some exceptions, for the services that are provided in turn. These services include but are not limited to: waste collection, libraries, schools, road maintenance and street lighting. "
                },{
                    id:5,
                    title:"Enterprise Management Incentives",
                    description:"An Enterprise Management Incentive scheme is a scheme available to employees whereby the company they work for can offer them share options, in recognition of their efforts within the business. Tax is not chargeable on these schemes, although capital gains tax may be payable if the worker sells the shares for a profit. "
                },{
                    id:6,
                    title:"HMRC and Disputes",
                    description:"HMRC stands for Her Majesty's Revenue and Customs, which is a government department that oversees the collection of tax paid by individuals and businesses. If a dispute arises, the tax payer has the right to appeal a decision made by HMRC within a certain time frame. In parallel, there are penalties and legal consequences for tax payers who avoid or refuse to pay their taxes. HMRC also make tax refunds if an individual or business has overpaid on their taxes or been overcharged."
                },{
                    id:7,
                    title:"Import Duty",
                    description:"Import Duty is the tax paid on imported goods from abroad. The Tax must be paid in order to release the products from customs, and the amount of tax payable is generally determined by the value of the goods being imported."
                },{
                    id:8,
                    title:"Inheritance Tax",
                    description:"Inheritance Tax is the tax paid on the estate of a deceased person. The amount of tax payable will depend on the value of the estate, as there is a tax-free threshold that applies to each individual. This area details who is liable to pay the tax, as well as the procedure of making the payment."
                },{
                    id:9,
                    title:"Stamp Duty",
                    description:"Stamp Duty Land Tax (SDLT) is paid when acquiring a property. It is calculated depending on (1) the value of the purchased property, and (2) whether the property is the main residence, or an additional home. The correct fee must be paid to HMRC within a certain timeframe, and must be accompanied by the corresponding SDLT application form. On receipt of the correct SDLT fee and forms, HMRC will return a certificate to the property owner, which is required to register their interest in the property with HM Land Registry."
                },{
                    id:10,
                    title:"Taxable Income",
                    description:"Taxable Income is the amount of income that is used to calculate an individual's tax liability to the government. Deductions and exemptions can be applied to the Gross Income of an individual, bringing the total taxable income figure down. Taxable income can include, wages, salaries, bonuses and tips, as well as investment profits and employment benefits."
                },{
                    id:11,
                    title:"Partnership",
                    description:"A Partnership is a type of business arrangement between two people who agree to work together to reach a common goal, with the intention of making money. There are different types of partnership, with varying liabilities attached to them. For example, a Limited Liability Partnership (LLP) differs from other types, as it reduces the partners' financial responsibilities."
                },{
                    id:12,
                    title:"Partnership Insolvency",
                    description:"When a partnership's debts outweigh its assets, it becomes insolvent. The partners can declare bankruptcy together in court, and the court will order the winding up of the business by the official receiver. Creditors are restricted from retrieving their funds directly from the partnership if it is wound up, and the partners declared bankrupt."
                },{
                    id:13,
                    title:"Profit Share Agreement",
                    description:"A Profit Share Agreement is a legal contract signed between partners, an employee and employer, or other persons in business together, that sets out the ratio for distribution of profits made as a result of reaching certain targets or achieving a stated objective. This is a formalisation of agreeing a bonus, for instance, for performance with the reward based on the profits of the business in question."
                },{
                    id:14,
                    title:"Share Capital",
                    description:"Share Capital is defined as the amount of money a company raises after selling shares in their company. The Share Capital is detailed on the company's balance sheet, and signifies the total value at risk by the company's shareholders. Company's raise money by selling 'equity' or 'shares' that represent a proportion of ownership of that company. This money forms the value, collectively, that is the Share Capital."
                },{
                    id:15,
                    title:"Shareholder Dispute",
                    description:"Shareholder Disputes are disagreements that occur between the owners of a company. Resolution of these disputes is normally governed by the company's articles of association, however the Companies Acts also assist in determining how disputes of this kind should be handled. A general meeting of the shareholders is normally called to discuss the matter face-to-face and votes on decisions held. If the statutory threshold is met, then the matter is resolved as the shareholders will have made a vote either in favour or against the matter in dispute."
                },{
                    id:16,
                    title:"Shareholders Agreement",
                    description:"A Shareholder agreement is an arrangement made between all or some of the owners of a company, which sets out in a documented form, the way the company will be handled between them. It addresses the role shareholders play, their relationship to the company directors, and the procedure for meetings, voting and making changes to the business."
                }
             ]
            }

]

export default LawPagesData
