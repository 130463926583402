export const TYPES_OF_LAWYER = [
  {
    label: "Solicitor",
    value: "Solicitor",
    infoText:
      "Solicitors are regulated lawyers who can advise you on and manage your legal matters, including conveyancing, helping prepare cases and drafting legal documents",
  },
  {
    label: "Barrister",
    value: "Barrister",
    infoText:
      "Public access barristers are regulated lawyers who are able to help and advise you on aspects of your case without the need to go to a solicitor",
  },
];
