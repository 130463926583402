import moment from 'moment'
import { useFormik } from 'formik'
import { FiVideo } from 'react-icons/fi'
import React, { useRef, useState } from 'react'
import { FaRegClock, FaCalendarAlt } from 'react-icons/fa'

import HeaderBar from './HeaderBar'
import BookingPopup from './BookingPopup'
import { TextInput } from './AppointmentBase'
import useBooking from 'common/providers/useBooking'
import AddReviewToAppointment from './AddReviewToAppointment'
import { AccountType, Booking, BookingStatus } from 'common/state-type'
import { AppointmentBtnMbl, FileUploadBtnMbl } from './AppointmentBaseMbl'

interface AppointmentDetailMobileProps {
  data: Booking
  handleClose: () => void
}
const AppointmentDetailMobile: React.FC<AppointmentDetailMobileProps> = ({
  data,
  handleClose,
}) => {
  const [activeTab, setActiveTab] = useState<'detail' | 'status'>('detail')
  const photoIdUpload = useRef(null)
  const proofOfAddressUpload = useRef(null)
  const { updateBooking, bookingSaving } = useBooking()

  const [isReviewActive, setIsReviewActive] = useState(false)
  const isExpired = data.status === BookingStatus.EXPIRED
  const isCompleted = data.status === BookingStatus.COMPLETE
  const { values, handleChange, handleSubmit, setFieldValue, errors } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        notes: data.notes ?? '',
        guests: data.guests,
        is_video: data.is_video,
        accessibility_requirements: data.accessibility_requirements ?? '',
        identification_document: data.identification_document as File,
        proof_of_address: data.proof_of_address as File,
      },
      onSubmit: (values) => {
        updateBooking({
          ...values,
          id: data.id,
          lawyerType: data.lawyer ? AccountType.LAWYER : AccountType.BARRISTER,
        })
      },
    })
  const isCompleteActive = data.status === BookingStatus.COMPLETE
  const isConfirmedActive =
    data.status === BookingStatus.ACCEPTED || isCompleteActive
  const isPendingActive =
    data.status === BookingStatus.PENDING_BARRISTER_ACCEPTANCE ||
    data.status === BookingStatus.PENDING_LAWYER_ACCEPTANCE ||
    isConfirmedActive
  const fullName = data.lawyer
    ? `${data.lawyer.user.first_name} ${data.lawyer.user.last_name}`
    : data.barrister.full_name
  const officeName = data.lawyer
    ? data.lawyer.office.name
    : data.barrister.chamber_name

  const handelAddReview = () => {
    setIsReviewActive(true)
  }

  const renderBottmBtn = () => {
    if (isCompleted && data.rating_id === null) {
      return (
        <div className='flex justify-center'>
          <button
            type='button'
            onClick={handelAddReview}
            disabled={bookingSaving}
            className={`font-medium self-center rounded-lg text-white mt-8 text-lg py-1 px-8 mb-5 bg-lawBlue`}
          >
            Leave a Review
          </button>
        </div>
      )
    } else if (!isExpired) {
      return (
        <div className='flex justify-center'>
          <button
            type='submit'
            disabled={bookingSaving}
            className={`font-medium self-center rounded-lg text-white mt-8 text-lg py-1 px-8 mb-5 bg-lawBlue`}
          >
            Apply Changes
          </button>
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <BookingPopup>
      <HeaderBar
        avatar={data.lawyer?.avatar ?? data.barrister?.avatar}
        fullName={fullName}
        officeName={officeName}
        onClose={() => {
          setIsReviewActive(false)
          handleClose()
        }}
      />
      {isReviewActive ? (
        <AddReviewToAppointment
          theme={{ hex: '#65C2D7', class: 'bg-[#65C2D7]' }}
          onBackClick={() => {
            setIsReviewActive(false)
          }}
          lawyerType={data.lawyer ? 'lawyer' : 'barrister'}
          lawyerId={data?.lawyer?.id ?? data?.barrister?.id}
        />
      ) : (
        <form
          onSubmit={handleSubmit}
          className='flex flex-col flex-1 place-content-between'
        >
          <div className='px-5 pt-4'>
            <div className='flex flex-row p-1 gap-4 border rounded-lg border-slate-300 items-center justify-between'>
              <button
                type='button'
                onClick={() => {
                  setActiveTab('detail')
                }}
                className={`font-medium self-center rounded-lg bg-white text-black text-lg py-0.5 px-8 w-[50%] ${
                  activeTab === 'detail' && 'bg-lawBlue text-white'
                }`}
              >
                Details
              </button>
              <button
                type='button'
                onClick={() => {
                  setActiveTab('status')
                }}
                className={`font-medium self-center rounded-lg bg-white text-black text-lg py-0.5 px-8 w-[50%] ${
                  activeTab === 'status' && 'bg-lawBlue text-white'
                }`}
              >
                Status
              </button>
            </div>
            {activeTab === 'detail' ? (
              <div className={`${isExpired && 'opacity-50'}`}>
                <div className='flex flex-row items-center text-black text-sm pb-2 pt-5'>
                  <FaRegClock size={16} />
                  <span className='block pl-2'>
                    {moment(data?.start).format('dddd DD MMMM YYYY')}
                  </span>
                </div>
                <div className='flex flex-row items-center text-black text-sm mb-4'>
                  <FaCalendarAlt size={16} />
                  <span className='block pl-2'>
                    {moment(data?.start).format('HH:mm')}
                    {' - '}
                    {moment(data?.end).format('HH:mm')}
                  </span>
                </div>
                <button
                  disabled={true}
                  type='button'
                  onClick={() => null}
                  className={`font-medium self-start rounded-lg bg-lawBlue text-white text-lg py-1 px-6 flex flex-row items-center mb-4`}
                >
                  <FiVideo size={20} className='mr-2' />
                  Join Video Call
                </button>
                <TextInput
                  disabled={isExpired || isCompleted}
                  name='notes'
                  value={values.notes}
                  onChange={handleChange}
                  label='Notes'
                  isMultiline
                  borderColor='#65C2D7'
                />
                {values.guests?.length > 0 && (
                  <label className='block mb-2 text-xl font-medium text-black mt-4'>
                    Attendees
                  </label>
                )}
                {values.guests?.map((guest, index) => (
                  <div key={index} className='pb-2'>
                    <TextInput
                      disabled={
                        isExpired ||
                        isCompleted ||
                        data.guests[index] !== undefined
                      }
                      onChange={(e) => {
                        setFieldValue(`guests[${index}]`, e.target.value)
                      }}
                      label='Email'
                      borderColor='#65C2D7'
                      name={`guests[${index}]`}
                      value={values.guests[index]}
                    />
                  </div>
                ))}
                {!values.guests ||
                  (values.guests?.length <= 1 && (
                    <button
                      disabled={isCompleted || isExpired}
                      type='button'
                      onClick={(e) => {
                        setFieldValue('guests', [...values.guests, ''])
                      }}
                      className={`font-medium self-center rounded-lg text-white mt-8 text-lg py-0.5 px-8 mb-5 bg-lawBlue`}
                    >
                      Add Guest
                    </button>
                  ))}
                <TextInput
                  name='accessibility_requirements'
                  label='Accessibility Requirements'
                  isMultiline
                  value={values.accessibility_requirements}
                  onChange={handleChange}
                  borderColor='#65C2D7'
                  disabled={isCompleted || isExpired}
                />
              </div>
            ) : (
              <div className={`${isExpired && 'opacity-50'}`}>
                <div className='mt-6'>
                  <label className='block mb-2 text-base font-bold text-black'>
                    Identification
                  </label>
                  <div className='flex flex-col gap-y-3'>
                    <FileUploadBtnMbl
                      infoText={
                        !values.identification_document && {
                          title: 'Photo ID  Examples',
                          content:
                            'Please note we accept the following forms of ID : Passport Driving Licence',
                        }
                      }
                      label='Upload Photo ID'
                      fileUploadRef={photoIdUpload}
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                          setFieldValue(
                            'identification_document',
                            e.target.files[0],
                          )
                        }
                      }}
                      onClick={() => {
                        photoIdUpload.current.click()
                      }}
                      btnClass='bg-white border rounded-lg border-lawBlue !text-gray-500'
                      value={values.identification_document}
                      disabled={isCompleted || isExpired}
                      isActive={values.identification_document ? true : false}
                    />
                    <FileUploadBtnMbl
                      infoText={
                        !values.proof_of_address && {
                          title: 'Proof of Address Examples',
                          content:
                            'Please note we accept the following forms of ID Untility bill. Credit card statement. Bank statement/letter. Mortgage statement Letter issued by Local authority Payslip. Home insurance policy.',
                        }
                      }
                      label='Upload Proof of Address'
                      fileUploadRef={proofOfAddressUpload}
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                          setFieldValue('proof_of_address', e.target.files[0])
                        }
                      }}
                      onClick={() => {
                        proofOfAddressUpload.current.click()
                      }}
                      value={values.proof_of_address}
                      disabled={isCompleted || isExpired}
                      isActive={values.proof_of_address ? true : false}
                    />
                  </div>
                </div>
                <div className='mt-6'>
                  <label className='block mb-2 text-base font-bold text-black'>
                    Meeting Status
                  </label>
                  <div className='flex flex-col gap-y-3'>
                    <AppointmentBtnMbl
                      onClick={() => null}
                      disabled={true}
                      isActive={isPendingActive}
                      label='Meeting Pending'
                    />
                    {(isCompleteActive || isConfirmedActive) && (
                      <AppointmentBtnMbl
                        onClick={() => null}
                        disabled={true}
                        isActive={isConfirmedActive}
                        label='Meeting Confirmed'
                      />
                    )}
                    {isCompleteActive && (
                      <AppointmentBtnMbl
                        onClick={() => null}
                        disabled={true}
                        isActive={isCompleteActive}
                        label='Meeting Complete'
                      />
                    )}
                    {data.status === BookingStatus.DECLINED && (
                      <AppointmentBtnMbl
                        onClick={() => null}
                        disabled={true}
                        isActive={data.status === BookingStatus.DECLINED}
                        label='Meeting Cancelled'
                        className='!bg-[#E56B66] !text-white !border-0'
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {renderBottmBtn()}
        </form>
      )}
    </BookingPopup>
  )
}

export default AppointmentDetailMobile
