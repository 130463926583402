import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseReview = {
  review: AppState['review']
  reviewLoading: AppState['reviewLoading']
  reviewSaving: AppState['reviewSaving']
  reviewError: AppState['reviewError']
  createReview: (
    data: RequestTypes['createReview'],
    callbacks?: Callbacks,
  ) => void
}

export default function useReview(): UseReview {
  const { review, reviewLoading, reviewSaving, reviewError } = useSelector(
    (state: AppState) => ({
      review: state.review,
      reviewLoading: state.reviewLoading,
      reviewSaving: state.reviewSaving,
      reviewError: state.reviewError,
    }),
  )
  const dispatch = useDispatch()

  const createReview = useCallback(
    (data: RequestTypes['createReview'], callbacks?: Callbacks) => {
      return dispatch(AppActions.createReview(data, callbacks))
    },
    [dispatch],
  )

  return {
    review,
    reviewLoading,
    reviewSaving,
    reviewError,
    createReview,
  }
}
