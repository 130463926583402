import { SVGProps } from 'react'

export const ProfileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='30'
    height='35'
    viewBox='0 0 30 35'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M20.1633 15.8403C22.3469 14.2478 23.7689 11.6705 23.7689 8.76692C23.7689 3.94199 19.8435 0.0166016 15.0186 0.0166016C10.1936 0.0166016 6.26826 3.94199 6.26826 8.76692C6.26826 11.6705 7.69016 14.2478 9.87384 15.8403C4.44563 17.9176 0.580566 23.1805 0.580566 29.3302C0.580566 31.9839 2.73952 34.1429 5.39324 34.1429H24.6439C27.2976 34.1429 29.4566 31.9839 29.4566 29.3302C29.4566 23.1805 25.5915 17.9176 20.1633 15.8403ZM8.89338 8.76692C8.89338 5.38949 11.6411 2.64172 15.0186 2.64172C18.396 2.64172 21.1438 5.38949 21.1438 8.76692C21.1438 12.1444 18.396 14.8922 15.0186 14.8922C11.6411 14.8922 8.89338 12.1444 8.89338 8.76692ZM24.6439 31.5177H5.39324C4.18702 31.5177 3.20569 30.5364 3.20569 29.3301C3.20569 22.8164 8.50486 17.5172 15.0186 17.5172C21.5324 17.5172 26.8316 22.8163 26.8316 29.3301C26.8315 30.5364 25.8502 31.5177 24.6439 31.5177Z'
      fill='white'
    />
  </svg>
)
