type TagListProps = {
  title: string;
  items: {
    id: string | number;
    name: string;
  }[]
}

const TagList = ({ title, items }: TagListProps) => (
  <dl className="gap-1.5 flex flex-row flex-wrap items-center">
    <dt className="font-medium mr-2 text-lg whitespace-nowrap">
      {title}:
    </dt>
    {items.map((item) => (
      <dd
        key={item.id}
      >
        <span
          className="rounded-full bg-lue-purple-400 text-white text-lg py-1 px-3 whitespace-nowrap inline text-xs uppercase"
        >
          {item.name}
        </span>
      </dd>
    ))}
  </dl>
);

export default TagList;
