import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseLegalDocumentRatings = {
  legalDocumentRatings?: AppState['legalDocumentRatings']
  legalDocumentRatingsLoading: AppState['legalDocumentRatingsLoading']
  legalDocumentRatingsError: AppState['legalDocumentRatingsError']
  getLegalDocumentRatings: (
    data: RequestTypes['getLegalDocumentRatings'],
    callbacks?: Callbacks,
  ) => void
  createLegalDocumentRating: (
    data: RequestTypes['createLegalDocumentRating'],
    callbacks?: Callbacks,
  ) => void
  updateLegalDocumentRating: (
    data: RequestTypes['updateLegalDocumentRating'],
    callbacks?: Callbacks,
  ) => void
}

export default function useLegalDocumentRatings(): UseLegalDocumentRatings {
  const {
    legalDocumentRatings,
    legalDocumentRatingsLoading,
    legalDocumentRatingsError,
  } = useSelector((state: AppState) => ({
    legalDocumentRatings: state.legalDocumentRatings,
    legalDocumentRatingsLoading: state.legalDocumentRatingsLoading,
    legalDocumentRatingsError: state.legalDocumentRatingsError,
  }))

  const dispatch = useDispatch()

  const getLegalDocumentRatings = useCallback(
    (data: RequestTypes['getLegalDocumentRatings'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getLegalDocumentRatings(data, callbacks))
    },
    [dispatch],
  )

  const createLegalDocumentRating = useCallback(
    (
      data: RequestTypes['createLegalDocumentRating'],
      callbacks?: Callbacks,
    ) => {
      return dispatch(AppActions.createLegalDocumentRating(data, callbacks))
    },
    [dispatch],
  )

  const updateLegalDocumentRating = useCallback(
    (
      data: RequestTypes['updateLegalDocumentRating'],
      callbacks?: Callbacks,
    ) => {
      return dispatch(AppActions.updateLegalDocumentRating(data, callbacks))
    },
    [dispatch],
  )

  return {
    legalDocumentRatings,
    legalDocumentRatingsLoading,
    legalDocumentRatingsError,
    getLegalDocumentRatings,
    createLegalDocumentRating,
    updateLegalDocumentRating,
  }
}
