import React from "react";
import Multiline from "components/base/Multiline";
import { OfficeSummary } from 'common/state-type'
import { InfoItem } from '../components'

interface LawfirmDetailProps {
  data: OfficeSummary
}

export const LawfirmDetail: React.FC<LawfirmDetailProps> = ({ data }) => {
  return (
    <>
      <div className='hidden md:flex flex-col md:flex-1'>
        <h4 className='text-center font-medium leading-tight text-black pb-2'>
          Company Information
        </h4>
        <div className='font-normal mb-4 text-black'>
          <Multiline text={data.organisation.description} />
        </div>
      </div>
      <div className='flex md:hidden flex-col flex-1'>
        <div className='flex flex-col flex-1 text-sm'>
          <h2 className='text-center font-bold text-lg leading-tight text-black pb-2'>
            Company Information
          </h2>
          <h4 className='font-bold text-black pb-2'>About Us</h4>
          <div className='font-normal mb-4 text-black'>
            <Multiline text={data.organisation.description} />
          </div>
          <div className='grid grid-cols-2 mb-4'>
            <InfoItem
              title='SRA Organisation ID'
              content={data.organisation.sra_organisation_id}
            />
            <InfoItem
              title='SRA Number'
              content={data.organisation.sra_number}
            />
            <InfoItem
              title='Company Number'
              content={data.organisation.company_registration_number}
            />
            <InfoItem
              title='Company Structure'
              content={data.organisation.company_structure}
            />
            <InfoItem
              title='Regulatory Body Type'
              content={data.organisation.regulatory_body_type}
            />
            <InfoItem
              title='Auth Date'
              content={data.organisation.authorisation_date}
            />
          </div>
        </div>
      </div>
    </>
  )
}
