import React, {
  useCallback,
  useState,
  useRef,
  FormEventHandler,
  useEffect,
} from 'react'
import Cognito from '../../cognito'
import { FormPageWrapper } from './FormPageWrapper'
import { FooterText } from './FooterText'
import { FormMessage } from './FormMessage'
import { MailboxIcon } from '../Icons/MailboxIcon'
import { VerificationCodeInput } from './VerificationCodeInput'
import { Button } from './Button'
import { decodeAuthState, AuthState } from 'common/utils/auth'

const VerifyEmailPage = () => {
  const codeInputRef = useRef<HTMLInputElement>(null)
  const [isResending, setIsResending] = useState<boolean>(false)
  const [formError, setFormError] = useState<string>('')
  const [decodedState, setDecodedState] = useState<Partial<AuthState>>({})

  const resendEmail = useCallback(() => {
    setFormError('')
    setIsResending(true)
    Cognito.resendConfirmSignUp(decodedState.email)
  }, [decodedState])

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (evt) => {
      evt.preventDefault()
      setFormError('')
      setIsResending(false)

      const code = codeInputRef.current?.value

      try {
        await Cognito.confirmSignUp(decodedState.email, code)
      } catch (err) {
        setFormError(err.message)

        return
      }

      if (!decodedState.password) {
        document.location.href = '/login'
      }

      // Auto-login if we have an email and password
      try {
        await Cognito.login(decodedState.email, decodedState.password)
      } catch (err) {
        document.location.href = '/login'

        return
      }

      document.location.href = '/mfa'
    },
    [decodedState],
  )

  useEffect(() => {
    setDecodedState(decodeAuthState())
  }, [])

  return (
    <FormPageWrapper title='Verification'>
      <p className=' text-center text-[#6E7785] text-base'>
        We’ve sent a verification code to{' '}
        {decodedState.email ? (
          <>
            <span className='font-bold text-black text-base'>
              {decodedState.email}
            </span>
            .
          </>
        ) : (
          'your email address.'
        )}
      </p>
      <p className='mt-0 leading-4 text-center text-[#6E7785] text-base'>
        Please don't forget to check Spam folder.
      </p>

      <p className='mt-2 leading-10 text-center text-[#6E7785] text-base'>
        Please input the code below:
      </p>

      {isResending && (
        <FormMessage type='success'>
          The verification email has been resent.
        </FormMessage>
      )}
      <form onSubmit={onSubmit}>
        <VerificationCodeInput
          label="Verification Code"
          isNew={true}
          error={formError }
          ref={codeInputRef}
        />
        <Button className={'!w-[40%] mt-10 !mx-auto'} type='submit'>
          Continue
        </Button>
      </form>
      <div className='mt-4'>
        <FooterText
          text="Didn't get the email?"
          linkOnClick={resendEmail}
          linkText='Click to resend'
        />
      </div>
    </FormPageWrapper>
  )
}

export default VerifyEmailPage
