import React from "react";
import events_next from "../../assets/Public Content/events_next.png";
import calender from "../../assets/Public Content/calender.png";
import calenderplus from "../../assets/Public Content/calenderplus.png";
import fraser from "../../assets/Public Content/fraser.png";
import post from "../../assets/Public Content/post.png";
import share from "../../assets/Public Content/share.png";

const ThoughtLeadership = () => {
  return (
    <React.Fragment>
      <div className="bg-[#65C2D7] bg-opacity-[0.2] py-[60px]">
        <div className="md:flex lg:max-w-[1024px] justify-between items-center mx-auto">
          <div className="flex flex-row md:mt-[40px]">
            <div className="text-[#1B4A8A] leading-[48px] text-[36px]  font-bold">
              Thought Leadership
            </div>
          </div>
        </div>
        <div className="md:flex lg:max-w-[1024px] justify-between items-center mx-auto py-[40px] px-[20px]">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8">
            <div className="rounded-[10px] overflow-hidden shadow-lg bg-[#1B4A8A] px-[28px] py-[22px] items-center">
              <div className="w-[420px] h-[238px] bg-[#777777] rounded-[10px] relative">
                <div className="absolute bottom-4 right-4">
                  <div className="float-right w-[42px] h-[42px] bg-[#F0F0F0] rounded-full relative">
                    <img
                      src={post}
                      className="max-w-full h-auto absolute top-0 bottom-0 left-0 right-0 m-auto"
                    />
                  </div>
                </div>
              </div>
              <div className="w-[420px] p-5 mt-4 bg-white rounded-[10px]">
                <div className="flex items-center pt-[20px] justify-between">
                  <img
                    className="w-[80px] h-auto rounded-full border-[2px] border-solid border-[#20BFE7]"
                    src={fraser}
                    alt="profile"
                  />
                  <div className="text-sm">
                    <p className="text-[#1B4A8A] font-bold text-[16px] leading-[26px]">
                      Fraser Matcham
                    </p>
                    <p className="text-[#1B4A8A] font-normal text-[16px] leading-[26px]">
                      Chief Product Officer{" "}
                    </p>
                    <div className="flex justify-between space-x-2">
                      <a className="inline bg-[#20BFE7] text-[16px] leading-[26px] text-white px-5 py-2 rounded-[10px]">
                        Position
                      </a>
                      <a className="inline bg-[#20BFE7] bg-opacity-[0.2] text-[16px] leading-[26px] text-black px-5 py-2 rounded-[10px]">
                        Contact
                      </a>
                    </div>
                  </div>
                  <div className="float-right w-[42px] h-[42px] bg-[#F0F0F0] rounded-full relative">
                    <img
                      src={share}
                      className="max-w-full h-auto absolute top-0 bottom-0 left-0 right-0 m-auto"
                    />
                  </div>
                </div>
              </div>

              <div className="py-4 text-white text-[16px] leading-[26px]">
              Technology brings the law closer to people. We have been bringing the law closer to more than 20,000 people since 2017. We have partnered with many law firms and organisations, receiving funding from national and international institutions for our innovative approaches. We’ve put law into peoples’ pockets. 
              </div>
            </div>
            <div className="rounded-[10px] overflow-hidden shadow-lg bg-[#1B4A8A] px-[28px] py-[22px] items-center">
              <div className="w-[420px] h-[238px] bg-[#777777] rounded-[10px] relative">
                <div className="absolute bottom-4 right-4">
                  <div className="float-right w-[42px] h-[42px] bg-[#F0F0F0] rounded-full relative">
                    <img
                      src={post}
                      className="max-w-full h-auto absolute top-0 bottom-0 left-0 right-0 m-auto"
                    />
                  </div>
                </div>
              </div>
              <div className="w-[420px] p-5 mt-4 bg-white rounded-[10px]">
                <div className="flex items-center pt-[20px] justify-between">
                  <img
                    className="w-[80px] h-auto rounded-full border-[2px] border-solid border-[#20BFE7]"
                    src={fraser}
                    alt="profile"
                  />
                  <div className="text-sm">
                    <p className="text-[#1B4A8A] font-bold text-[16px] leading-[26px]">
                      Fraser Matcham
                    </p>
                    <p className="text-[#1B4A8A] font-normal text-[16px] leading-[26px]">
                      Chief Product Officer{" "}
                    </p>
                    <div className="flex justify-between space-x-2">
                      <a className="inline bg-[#20BFE7] text-[16px] leading-[26px] text-white px-5 py-2 rounded-[10px]">
                        Position
                      </a>
                      <a className="inline bg-[#20BFE7] bg-opacity-[0.2] text-[16px] leading-[26px] text-black px-5 py-2 rounded-[10px]">
                        Contact
                      </a>
                    </div>
                  </div>
                  <div className="float-right w-[42px] h-[42px] bg-[#F0F0F0] rounded-full relative">
                    <img
                      src={share}
                      className="max-w-full h-auto absolute top-0 bottom-0 left-0 right-0 m-auto"
                    />
                  </div>
                </div>
              </div>

              <div className="py-4 text-white text-[16px] leading-[26px]">
              Technology brings the law closer to people. We have been bringing the law closer to more than 20,000 people since 2017. We have partnered with many law firms and organisations, receiving funding from national and international institutions for our innovative approaches. We’ve put law into peoples’ pockets. 
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ThoughtLeadership;
