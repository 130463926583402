import { LuLogo } from '../Icons/LuLogo'
import { LinkContext } from '../../providers/LinkProvider'
import { useContext } from 'react'

type NavbarLogoProps = {
  href?: string
}

export const NavbarLogo = ({ href = '/' }: NavbarLogoProps) => {
  const { Link } = useContext(LinkContext)

  return (
    <Link href={href}>
      <div className='flex items-center  tracking-wide text-xl md:text-3xl font-semibold text-lue-blue-800 leading-none md:whitespace-nowrap cursor-pointer'>
        <LuLogo className='min-w-[32px]' width='32' height='32' />
        <span className='hidden md:block md:w-full pl-2 text-2xl'>
          Legal Utopia
        </span>
      </div>
    </Link>
  )
}
