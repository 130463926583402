import { RoundIcon } from '../RoundIcon'
import { DropdownArrow } from 'common/components/Dropdowns/DropdownArrow'
import { Dropdown, DropdownProps } from 'common/components/Dropdowns/Dropdown'
import Project from 'common/project'
import { useState } from 'react'
import { AppState } from 'common/state-type'
import { hasFullName, getFullName } from 'common/utils/profile'
import { ProfileIcon } from 'common/components/Icons/ProfileIcon'
import { Avatar } from '../Avatar'
import classNames from 'classnames'

export interface NavbarDropdownProps {
  profile?: AppState['profile']
  onSignoutClick: () => void
  dropdownOptions: DropdownProps['options']
  isSlimDropdown?: boolean
}

export const NavbarDropdown: React.FC<NavbarDropdownProps> = ({
  onSignoutClick,
  profile,
  dropdownOptions,
  isSlimDropdown,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const isLoggedIn = !!profile?.id
  const {
    email,
    first_name: firstName,
    last_name: lastName,
    avatar,
  } = profile ?? {}

  const onClickOutside = () => {
    setIsDropdownVisible(false)
  }

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  const nameClassName =
    'text-sm md:text-lg px-2 md:px-4 text-ellipsis overflow-hidden max-w-[120px] lg:max-w-[200px] xl:max-w-[250px] 2xl:max-w-[300px] text-left'

  return (
    <div className='flex'>
      {isLoggedIn ? (
        <button
          className={classNames(
            'flex flex-row items-center',
            !isSlimDropdown &&
              'bg-lue-blue-300 rounded-full pl-2 md:pl-3 pr-4 md:pr-5 py-2 shadow',
          )}
          type='button'
          onClick={toggleDropdown}
        >
          <Avatar fullName={getFullName(profile)} imgSource={avatar} />

          <div
            className={classNames(
              'flex flex-col whitespace-nowrap',
              isSlimDropdown && 'text-lue-gray-500 font-medium',
            )}
          >
            {hasFullName(profile) ? (
              <>
                <span className={nameClassName}>{firstName}</span>
                <span className={nameClassName}>{lastName}</span>
              </>
            ) : (
              <span className={nameClassName}>{email}</span>
            )}
          </div>
          <DropdownArrow
            size={15}
            className={classNames(isSlimDropdown && 'text-lue-gray-500')}
          />
        </button>
      ) : (
        <button type='button' onClick={toggleDropdown}>
          <RoundIcon
            size={55}
            iconSize={30}
            className='bg-lue-purple-400 transition-colors hover:bg-lue-purple-500'
          >
            <ProfileIcon />
          </RoundIcon>
        </button>
      )}
      <div className='mt-4 relative'>
        <Dropdown
          isLarge
          className='right-0 px-4 mt-3'
          isVisible={isDropdownVisible}
          onClickOutside={onClickOutside}
          options={
            isLoggedIn
              ? dropdownOptions
              : [
                  {
                    isLink: true,
                    label: 'Individual',
                    value: new URL('/login', Project.cwp).href,
                  },
                  {
                    isLink: true,
                    label: 'Lawyer',
                    value: new URL('/login', Project.lp).href,
                  },
                ]
          }
        >
          {isLoggedIn && (
            <button
              className='cursor-pointer select-none rounded-full border border-lue-purple-600 text-lue-purple-600 text-lg font-bold py-2 px-8 whitespace-nowrap bg-white transition-colors hover:bg-lue-purple-600 hover:text-white flex-1 my-3 w-full'
              onClick={onSignoutClick}
            >
              Sign out
            </button>
          )}
        </Dropdown>
      </div>
    </div>
  )
}
