import { FaAngleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { VscTriangleRight } from 'react-icons/vsc'
import React, { useEffect, useState } from 'react'

import {
  Booking,
  BookingStatus,
  BarristerProfile,
  LawyerListObject,
} from 'common/state-type'
import { getCardTheme } from 'utils/StylesUtil'
import AppointmentDetail from './AppointmentDetail'
import useLawyerBookings from 'common/providers/useLawyerBookings'
import useBarristerBookings from 'common/providers/useBarristerBookings'
import useFavourite from 'common/providers/useFavourite'
import LawyerProfileCard from '../lawyer/LawyerProfileCard'
import AppointmentDetailMobile from './AppointmentDetailMobile'
import BarristerProfileCard from '../barrister/BarristerProfileCard'
import AppointmentCardMbl from './AppointmentCardMbl'
import PaginationBar from 'components/CWP Sections/components/PaginationBar'

const mileToKm = 1.60934 // mile to km
export type position = {
  lat: number
  lng: number
}

type RegionType = {
  latitude: number
  longitude: number
  latitudeDelta: number
  longitudeDelta: number
}

const Appointments = () => {
  const navigate = useNavigate()

  const { createFavourite, deleteFavourite } = useFavourite()
  const { getLawyerBookings, lawyerBookings } = useLawyerBookings()
  const { getBarristerBookings, barristerBookings } = useBarristerBookings()

  const kilometers = 20 * mileToKm
  const delta = kilometers / 100
  const initialRegionValue = {
    latitude: 51.5074,
    longitude: 0.1278,
    latitudeDelta: delta,
    longitudeDelta: delta,
  }
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null)
  const [region] = useState<RegionType>(initialRegionValue)

  useEffect(() => {
    getLawyerBookings({})
    getBarristerBookings({})
  }, [])

  const getStatusBackgroundColor = (status: BookingStatus) => {
    switch (status) {
      case BookingStatus.PENDING_BARRISTER_ACCEPTANCE:
      case BookingStatus.PENDING_LAWYER_ACCEPTANCE:
        return 'bg-[#F3EB1B]'
      case BookingStatus.ACCEPTED:
        return 'bg-[#6BAC7A]'
      case BookingStatus.CANCELLED:
      case BookingStatus.DECLINED:
        return 'bg-[#DD2720]'
      case BookingStatus.EXPIRED:
        return 'bg-[#9d9fa1]'
      case BookingStatus.COMPLETE:
        return 'bg-[#1976D2]'
      default:
        return 'bg-[#F3EB1B]'
    }
  }
  const getStatus = (status: BookingStatus) => {
    switch (status) {
      case BookingStatus.PENDING_BARRISTER_ACCEPTANCE:
      case BookingStatus.PENDING_LAWYER_ACCEPTANCE:
        return 'Pending'
      case BookingStatus.ACCEPTED:
        return 'Meeting Confirmed'
      case BookingStatus.CANCELLED:
      case BookingStatus.DECLINED:
        return 'Cancelled'
      case BookingStatus.EXPIRED:
        return 'Expired'
      case BookingStatus.COMPLETE:
        return 'Meeting Completed'
      default:
        return 'Pending'
    }
  }

  const bookingTheme =
    selectedBooking && selectedBooking.barrister
      ? getCardTheme(selectedBooking.barrister.membership_type)
      : selectedBooking?.lawyer
      ? getCardTheme(selectedBooking?.lawyer?.membership_type)
      : null

  const handleCardClick = (item: any) => {
    setSelectedBooking(item)
  }

  const handlePageClick = (clickEvent) => {
    if (clickEvent.isNext) {
      lawyerBookings.next && getLawyerBookings({ link: lawyerBookings.next })
      barristerBookings.next &&
        getBarristerBookings({ link: barristerBookings.next })
    }
    if (clickEvent.isPrevious) {
      lawyerBookings.previous &&
        getLawyerBookings({ link: lawyerBookings.previous })
      barristerBookings.previous &&
        getBarristerBookings({ link: barristerBookings.previous })
    }
  }

  const renderLeftArrow = (profile: Booking) => {
    selectedBooking?.id === profile.id ? (
      <div className='hidden items-center md:flex'>
        <VscTriangleRight
          size={42}
          color={
            getCardTheme(
              profile.barrister?.membership_type ??
                profile.lawyer?.membership_type,
            ).hex
          }
        />
      </div>
    ) : (
      <div className='hidden md:block w-10' />
    )
  }
  const bookings =
    lawyerBookings && barristerBookings
      ? [...lawyerBookings.results, ...barristerBookings.results]
      : []

  return (
    <>
      <div className='bg-gray-200 w-full p-2'>
        <button
          onClick={() => {
            navigate('/dashboard')
          }}
          className='flex flex-row items-center'
        >
          <FaAngleLeft size={26} />
          <span className='pl-2'>Back</span>
        </button>
      </div>
      <div className='flex flex-row pt-12 pb-8'>
        <div className='flex flex-col px-1 relative overfloww-scroll h-[100vh] w-full md:w-[45.5vw] bg-[#F5F5F5]'>
          <div id='profile-list' className='pt-4 mb-4'>
            {bookings?.map((profile) => {
              const isExpired = profile.status === BookingStatus.EXPIRED
              if (!!profile.barrister) {
                return (
                  <div
                    className={`flex w-[100%] flex-1 flex-row ${
                      isExpired && 'opacity-60'
                    }`}
                    key={profile.id}
                  >
                    <AppointmentCardMbl
                      onReviewBooking={() => handleCardClick(profile)}
                      latitude={region.latitude}
                      longitude={region.longitude}
                      data={profile.barrister as BarristerProfile}
                      lawyerType='barrister'
                      renderStatus={
                        <div
                          className={`text-white text-sm font-bold py-2 px-2 rounded-t ${getStatusBackgroundColor(
                            profile.status,
                          )}`}
                        >
                          {getStatus(profile.status)}
                        </div>
                      }
                    />
                    <BarristerProfileCard
                      cardStyle={'mt-4'}
                      className='hidden md:flex flex-1'
                      onReviewBooking={() => handleCardClick(profile)}
                      latitude={region.latitude}
                      longitude={region.longitude}
                      data={profile.barrister}
                      bookingStatus={
                        <div
                          className={`text-sm px-2.5 items-center flex h-7 text-white rounded-lg font-bold ml-2 ${getStatusBackgroundColor(
                            profile.status,
                          )}`}
                        >
                          <span>{getStatus(profile.status)}</span>
                        </div>
                      }
                    />
                    {renderLeftArrow(profile)}
                  </div>
                )
              } else {
                return (
                  <div
                    className={`flex md:flex-row ${isExpired && 'opacity-60'}`}
                    key={profile.id}
                  >
                    <AppointmentCardMbl
                      onReviewBooking={() => handleCardClick(profile)}
                      latitude={region.latitude}
                      longitude={region.longitude}
                      data={profile.lawyer as LawyerListObject}
                      lawyerType='solicitor'
                      renderStatus={
                        <div
                          className={`text-white text-sm font-bold py-2 rounded-t ${getStatusBackgroundColor(
                            profile.status,
                          )}`}
                        >
                          {getStatus(profile.status)}
                        </div>
                      }
                    />
                    <LawyerProfileCard
                      className='hidden md:flex flex-1'
                      cardStyle='mr-0 pr-0 mt-4'
                      onReviewBooking={() => handleCardClick(profile)}
                      latitude={region.latitude}
                      longitude={region.longitude}
                      data={profile.lawyer}
                      bookingStatus={
                        <div
                          className={`text-sm px-2.5 items-center flex h-7 text-white rounded-lg font-bold ml-2 ${getStatusBackgroundColor(
                            profile.status,
                          )}`}
                        >
                          <span>{getStatus(profile.status)}</span>
                        </div>
                      }
                    />
                    {renderLeftArrow(profile)}
                  </div>
                )
              }
            })}
          </div>
          <PaginationBar onClick={handlePageClick} />
        </div>
        {selectedBooking && (
          <>
            <div className='hidden md:flex md:flex-row flex-1'>
              <div className={`h-full w-0.5 ${bookingTheme.class}`} />
              <AppointmentDetail
                selectedAppointment={selectedBooking}
                theme={bookingTheme}
                handleClose={() => setSelectedBooking(null)}
              />
            </div>
            <div className='flex md:hidden'>
              <AppointmentDetailMobile
                data={selectedBooking}
                handleClose={() => setSelectedBooking(null)}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Appointments
