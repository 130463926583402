import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import { AppState, RequestTypes } from '../state-type'
import { useCallback } from 'react'

type UseUploadUserAvatar = {
  uploadUserAvatar: AppState['uploadUserAvatar']
  uploadUserAvatarLoading: AppState['uploadUserAvatarLoading']
  uploadUserAvatarSaving: AppState['uploadUserAvatarSaving']
  uploadUserAvatarError: AppState['uploadUserAvatarError']
  createUploadUserAvatar: (
    data: RequestTypes['createUploadUserAvatar'],
    callbacks?: Callbacks,
  ) => void
}

export default function useUploadUserAvatar(): UseUploadUserAvatar {
  const {
    uploadUserAvatar,
    uploadUserAvatarLoading,
    uploadUserAvatarSaving,
    uploadUserAvatarError,
  } = useSelector((state: AppState) => ({
    uploadUserAvatar: state.uploadUserAvatar,
    uploadUserAvatarLoading: state.uploadUserAvatarLoading,
    uploadUserAvatarSaving: state.uploadUserAvatarSaving,
    uploadUserAvatarError: state.uploadUserAvatarError,
  }))
  const dispatch = useDispatch()

  const createUploadUserAvatar = useCallback(
    (data: RequestTypes['createUploadUserAvatar'], callbacks?: Callbacks) => {
      return dispatch(AppActions.createUploadUserAvatar(data, callbacks))
    },
    [dispatch],
  )

  return {
    uploadUserAvatar,
    uploadUserAvatarLoading,
    uploadUserAvatarSaving,
    uploadUserAvatarError,
    createUploadUserAvatar,
  }
}
