import SignUpPage from 'common/components/Authentication/SignUpPage'
import LogInPage from 'common/components/Authentication/LogInPage'
import SignoutPage from 'common/components/Authentication/SignoutPage'
import ForgottenPasswordPage from 'common/components/Authentication/ForgottenPasswordPage'
import ResetPasswordPage from 'common/components/Authentication/ResetPasswordPage'
import ResetPasswordSuccessPage from 'common/components/Authentication/ResetPasswordSuccessPage'
import VerifyEmailPage from 'common/components/Authentication/VerifyEmailPage'
import EnableMFAPage from './components/MFA/SetUpMFA'

import { useAuth } from 'common/providers/useAuth'
import Constants from 'common/utils/constants'
import useProfile from 'common/providers/useProfile'
import Project from 'common/project'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import HomePage from './components/CWP Sections/home/HomePage'
import ContactPage from '../src/components/Contact'

import GetTheAppPage from '../src/components/GetTheAppPage'
import AppHeader from './components/AppHeader'
import BlogPage from 'components/BlogPage'
import NavBar from './components/NavBar'
import LawPage from './components/LawPage'
import WhoWeArePage from './components/WhoWeArePage'
import PublicContent from './components/Public Content/PublicContent'
import DashBoard from './components/CWP Sections/DashBoard'
import Favourites from './components/CWP Sections/Favourites'
import LawFirmResults from './components/CWP Sections/lawfirm/LawFirmResults'
import BookALayer from 'components/BookALayer'
import MarketingPage from './components/content/MarketingPage'
import CookiesAppPage from './components/content/CookiesAppPage'
import CookiesPage from './components/content/CookiesPage'
import EqualOpportunitiesPage from './components/content/EqualOpportunitiesPage'
import DataRetentionPage from './components/content/DataRetentionPage'
import EmployeePrivacyPage from './components/content/EmployeePrivacyPage'
import PrivacyPage from './components/content/PrivacyPage'
import PrivacyWebPage from './components/content/PrivacyWebPage'
import ComplaintsPage from './components/content/ComplaintsPage'
import JoinOurNetwork from './components/JoinOurNetwork'
import TermsOfSaleBusinessPage from './components/content/TermsOfSaleBusinessPage'
import TermsOfSaleLawyerPage from './components/content/TermsOfSaleLawyerPage'
import TermsOfSalePage from './components/content/TermsOfSalePage'
import TermsOfUsePage from './components/content/TermsOfUsePage'
import { useEffect } from 'react'
import { Hub, Auth, Amplify } from 'aws-amplify'
import TermsOfUseBusinessPage from './components/content/TermsOfUseBusinessPage'
import TermsOfUseLawyersPage from './components/content/TermsOfUseLawyersPage'
import DisclaimerPage from './components/content/DisclaimerPage'
import DisclaimerLegalCheckerPage from './components/content/DisclaimerLegalCheckerPage'
import EditProfile from './components/CWP Sections/EditProfile'
import ReviewsPolicyPage from './components/content/ReviewsPolicyPage'
import Appointments from 'components/CWP Sections/booking/Appointments'
import LawyerProfile from 'components/CWP Sections/lawyer/LawyerProfile'
import LawfirmProfile from 'components/CWP Sections/lawfirm/LawfirmProfile'
import BarristerProfile from 'components/CWP Sections/barrister/BarristerProfile'
import LawfirmMembersList from 'components/CWP Sections/lawfirm/LawfirmMembersList'
import LegalDocument from 'components/LegalDocument'
import LegalDocumentsPage from 'components/LegalDocumentsPage'
import {
  getAuthRedirectCookie,
  removeAuthRedirectCookie,
} from 'common/utils/redirects'
import LegalDocumentDownload from 'components/LegalDocumentDownload'

Amplify.configure(Project.cognitoWeb)
Auth.configure(Project.cognitoWeb)

if (typeof window !== 'undefined') {
  const wfa_pstMesgFrmFom = (evt) => {
    if (
      evt.origin === 'https://crm.zoho.eu' ||
      evt.origin === 'https://crm.zohopublic.eu'
    ) {
      const loc_obj = JSON.stringify({
        origin: window.location.origin,
        pathname: window.location.pathname,
        search: window.location.search,
        hash: window.location.hash,
      })
      evt.source.postMessage(
        'prnt_wnd_pg_lc_rc_frm_prwindow_' + loc_obj,
        evt.origin,
      )
    }
  }
  window.addEventListener('message', wfa_pstMesgFrmFom, false)
}

function App() {
  const user = useAuth()
  const { getProfile } = useProfile()

  useEffect(() => {
    getProfile({})
  }, [])

  useEffect(() => {
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signIn') {
        getProfile({
          onSuccess: (data) => {
            const authRedirectCookie = getAuthRedirectCookie()

            if (authRedirectCookie) {
              removeAuthRedirectCookie()
              document.location = authRedirectCookie

              return
            }

            document.location =
              data?.fullName || data.first_name ? '/dashboard' : '/profile'
          },
        })
      }
    })
  }, [])

  return (
    <div className='App'>
      <Router>
        <NavBar />
        {/* <AppHeader /> */}
        <Routes>
          <Route path='/pages/:slug' element={<BlogPage />} />
          <Route
            path={Utils.getRelativeContentUrl(Constants.complaintsURL)}
            element={<ComplaintsPage />}
          />
          <Route
            path={Utils.getRelativeContentUrl(Constants.cookiesPolicyAppURL)}
            element={<CookiesAppPage />}
          />
          <Route
            path={Utils.getRelativeContentUrl(Constants.cookiesPolicyURL)}
            element={<CookiesPage />}
          />
          <Route
            path={Utils.getRelativeContentUrl(Constants.dataRetentionURL)}
            element={<DataRetentionPage />}
          />
          <Route
            path={Utils.getRelativeContentUrl(Constants.employeePrivacyURL)}
            element={<EmployeePrivacyPage />}
          />
          <Route
            path={Utils.getRelativeContentUrl(Constants.equalOpportunitiesURL)}
            element={<EqualOpportunitiesPage />}
          />
          <Route
            path={Utils.getRelativeContentUrl(Constants.marketingPolicyURL)}
            element={<MarketingPage />}
          />
          <Route
            path={Utils.getRelativeContentUrl(Constants.privacyURL)}
            element={<PrivacyPage />}
          />
          <Route
            path={Utils.getRelativeContentUrl(Constants.privacyWebURL)}
            element={<PrivacyWebPage />}
          />
          <Route
            path={Utils.getRelativeContentUrl(Constants.termsOfSaleBusinessURL)}
            element={<TermsOfSaleBusinessPage />}
          />
          <Route
            path={Utils.getRelativeContentUrl(Constants.termsOfSaleLawyerURL)}
            element={<TermsOfSaleLawyerPage />}
          />
          <Route
            path={Utils.getRelativeContentUrl(Constants.termsOfSaleURL)}
            element={<TermsOfSalePage />}
          />
          <Route
            path={Utils.getRelativeContentUrl(Constants.termsURL)}
            element={<TermsOfUsePage />}
          />
          <Route
            path={Utils.getRelativeContentUrl(Constants.termsBusinessURL)}
            element={<TermsOfUseBusinessPage />}
          />
          <Route
            path={Utils.getRelativeContentUrl(Constants.termsURLLawyers)}
            element={<TermsOfUseLawyersPage />}
          />
          <Route
            path={Utils.getRelativeContentUrl(Constants.disclaimerURL)}
            element={<DisclaimerPage />}
          />
          <Route
            path={Utils.getRelativeContentUrl(
              Constants.disclaimerURLLegalChecker,
            )}
            element={<DisclaimerLegalCheckerPage />}
          />
          <Route path='/contact-us' element={<ContactPage />} />
          <Route path='/mfa' element={<EnableMFAPage />} />
          <Route path='/verify-email' element={<VerifyEmailPage />} />
          <Route path='/forgot-password' element={<ForgottenPasswordPage />} />
          <Route path='/login' element={<LogInPage />} />
          <Route path='/reset-password' element={<ResetPasswordPage />} />
          <Route
            path='/reset-password/success'
            element={<ResetPasswordSuccessPage />}
          />
          <Route path='/join-our-network' element={<JoinOurNetwork />} />
          <Route path='/signup' element={<SignUpPage />} />
          <Route path='/whoweare' element={<WhoWeArePage />} />
          <Route path='/publiccontent' element={<PublicContent />} />
          <Route path='/publiccontent' element={<PublicContent />} />
          <Route path='/legalpages' element={<LawPage />} />
          <Route path='/Signout' element={<SignoutPage />} />
          <Route path='/' element={<HomePage />} />
          <Route path='/legal-documents' element={<LegalDocumentsPage />} />
          <Route path='/bookalawyer' element={<BookALayer />} />
          <Route path='/dashboard' element={<DashBoard />} />
          <Route path='/profile' element={<EditProfile />} />
          <Route path='/appointments' element={<Appointments />} />
          <Route path='/reviews-policy' element={<ReviewsPolicyPage />} />
          <Route path='/offices/:id' element={<LawFirmResults />} />
          <Route path='/lawyers/:id' element={<LawyerProfile />} />
          <Route path='/lawfirms/:id' element={<LawfirmProfile />} />
          <Route path='/barristers/:id' element={<BarristerProfile />} />
          <Route
            path='/lawfirms/:id/members'
            element={<LawfirmMembersList />}
          />
          <Route path='/get-the-app' element={<GetTheAppPage />} />
          <Route path='/legal-documents/:id' element={<LegalDocument />} />
          <Route
            path='/legal-documents/:id/download'
            element={<LegalDocumentDownload />}
          />

          {user ? (
            <Route path='/dashboard' element={<DashBoard />} />
          ) : (
            <Route path='/login' element={<LogInPage />} />
          )}
          {user ? (
            <Route path='/favourites' element={<Favourites />} />
          ) : (
            <Route path='/login' element={<LogInPage />} />
          )}
        </Routes>
      </Router>
    </div>
  )
}

export default App
