import React from "react";
import Select from "react-select";

import { KeyValue } from "common/state-type";
import searchIcon from "assets/FAL/searchIcon.png";

interface FilterOptionProps {
  title: string
  inputElement: React.ReactNode
  className?: string
}
interface FilterOptionSelectProps {
  onSelect: (data: KeyValue) => void
  defaultValue: KeyValue
  data: KeyValue[]
  disabled?: boolean
}

interface RadioButtonProps {
  label: string
  disabled?: boolean
  defaultChecked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  id: string
}

export const FilterOptionSelect: React.FC<FilterOptionSelectProps> = ({
  onSelect,
  defaultValue,
  data,
  disabled,
}) => {
  const customStyles = {
    control: () => ({
      // none of react-select's styles are passed to <Control />
      paddingBottom: '0.3rem',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected && 'white',
      color: 'black',
    }),
  }

  const handleSelect = (option) => {
    onSelect(option)
  }

  return (
    <Select
      isDisabled={disabled}
      className='react-select'
      classNamePrefix='react-select'
      defaultValue={defaultValue}
      isSearchable={false}
      onChange={handleSelect}
      options={data}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      styles={customStyles}
    />
  )
}

export const FilterOption: React.FC<FilterOptionProps> = ({
  title,
  inputElement,
  className,
}) => {
  return (
    <div
      className={`bg-white block flex-row p-1 shadow-lg justify-between md:rounded-lg mr-2 ${className}`}
    >
      <div className='flex flex-col place-content-between'>
        <label
          className='font-bold text-black text-sm md:text-base'
          htmlFor='searchLocation'
        >
          {title}
        </label>
        {inputElement}
      </div>
      <img src={searchIcon} className='w-8 h-8 ml-2 self-center' />
    </div>
  )
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  disabled,
  defaultChecked,
  onChange,
  id,
}) => {
  return (
    <div className='flex items-center'>
      <input
        defaultChecked={defaultChecked}
        disabled={disabled}
        id={id}
        type='radio'
        name='filter-radio'
        className='w-5 h-5 text-[#5858DF] bg-white border-[#20BFE7] focus:ring-0'
        onChange={onChange}
      />
      <label className='ml-2 text-base md:text-lg font-bold text-black'>
        {label}
      </label>
    </div>
  )
}
