import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Ministry_of_Justice_logo from "../../assets/Public Content/Ministry_of_Justice_logo.png";
import lawtech_panel_logo from "../../assets/Public Content/lawtech-panel-logo.png";
import legal_logo from "../../assets/Public Content/legal_logo.png";
import technation from "../../assets/Public Content/technation.png";
import LawTech from "../../assets/Public Content/LawTech.png";
import fraser from "../../assets/Public Content/fraser.png";
import undraw_online_articles from '../../assets/Public Content/undraw_online_articles_re_yrkj 1.png'
import undraw_web_search from '../../assets/Public Content/undraw_web_search_re_efla 1.png'

const percentage = 66;
const ResearchMore = () => {
  return (
    <React.Fragment>
      <div className="bg-white bg-opacity-[0.1] py-[60px]">
        <div className="flex md:flex-col lg:max-w-[1024px] justify-between items-center mx-auto">
          <p className="text-[32px] font-bold text-[#1B4A8A] text-center my-[30px] leading-[41px]">
            Among 9,203 consumers and small business located in the United
            Kingdom,
          </p>
          <div className="md:flex md:flex-row ">
            <div className="max-w-[320px] h-[320px] w-[100%]">
              <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                background
                styles={buildStyles({
                  pathColor: `rgba(32, 191, 231, ${percentage / 100})`,
                  textColor: "#ffffff",
                  trailColor: "#C4C4C4",
                  backgroundColor: "#6C63FF",
                })}
              />
              ;
            </div>
            <div className="text-[#65C2D7] text-center text-[24px] leading-[31px] align-middle md:p-[100px]">
              said they would use or prefer to use a mobile-based application to
              identify and help resolve their legal problem.
            </div>
          </div>
        </div>
        <div className="flex md:flex-col lg:max-w-[1024px] justify-between items-center mx-auto">
          <p className="text-[32px] font-bold text-[#1B4A8A] text-center my-[30px] leading-[41px]">
            Out of 8,016 consumers and small businesses in the United Kingdom,
          </p>
          <div className="md:flex md:flex-row">
            <div className="max-w-[320px] h-[320px] w-[100%]">
              <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                background
                styles={buildStyles({
                  pathColor: `rgba(32, 191, 231, ${percentage / 100})`,
                  textColor: "#ffffff",
                  trailColor: "#C4C4C4",
                  backgroundColor: "#6C63FF",
                })}
              />
              ;
            </div>
            <div className="text-[#65C2D7] text-center text-[24px] leading-[31px] align-middle md:p-[100px]">
              said they would pay for a platform that could help them identify
              and resolve their legal problem on a monthly subscription of
              between £0.99-£9.99pm
            </div>
          </div>
        </div>
        <div className="flex md:flex-col lg:max-w-[1024px] justify-between items-center mx-auto">
          <p className="text-[32px] font-bold text-[#1B4A8A] text-center my-[30px] leading-[41px]">
            We asked over 7000 consumers and businesses to prioritise 10 legal
            services for the app.
          </p>
          <p className="text-[32px] font-normal text-[#65C2D7] text-center my-[30px] leading-[41px]">
            The three highest rankings were:
          </p>
          <div className="md:flex md:flex-row space-x-3">
            <div className="max-w-[343px] w-[343px] h-[167px] bg-[#65C2D7] md:flex md:flex-row px-[20px] bg-opacity-[0.25]">
              <p className="text-[#5858DF] text-[128px] leading-[167px] font-bold">
                1
              </p>
              <div className="text-center h-[167px] leading-[167px]">
                <p className="text-[20px] font-bold leading-[26px] text-[#1B4A8A] align-middle inline-block">
                  {" "}
                  Form Links (Access to forms and docs via URL links)
                </p>
              </div>
            </div>
            <div className="max-w-[343px] w-[343px] h-[167px] bg-[#65C2D7] md:flex md:flex-row px-[20px] bg-opacity-[0.25]">
              <p className="text-[#5858DF] text-[128px] leading-[167px] font-bold">
                2
              </p>
              <div className="text-center h-[167px] leading-[167px]">
                <p className="text-[32px] font-bold leading-[41px] text-[#1B4A8A] inline-block align-middle p-[18px] text-left">
                  Legal Advice
                </p>
              </div>
            </div>
            <div className="max-w-[343px] w-[343px] h-[167px] bg-[#65C2D7] md:flex md:flex-row px-[20px] bg-opacity-[0.25]">
              <p className="text-[#5858DF] text-[128px] leading-[167px] font-bold">
                3
              </p>
              <div className="text-center h-[167px] leading-[167px]">
                <p className="text-[32px] font-bold leading-[41px] text-[#1B4A8A] align-middle inline-block p-[18px] text-left">
                  Legal Guidance
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#294B85] py-[60px]">
        <div className="flex md:flex-col lg:max-w-[1024px] justify-between items-left mx-auto">
          <div className="md:flex md:flex-row md:space-x-20">
            <div className="base-1/2">
              <div className="bg-white rounded-[10px] w-[447px] h-[346px]"></div>
            </div>
            <div className="base-1/2">
              <div className="md:flex md:flex-col">
                <div className="items-center text-center pb-[20px]">
                  <img src={LawTech} className="m-auto" />
                </div>
                <div className="text-center text-[32px] leading-[19px] text-white py-[20px]">
                  Sandbox Pilot Showcase{" "}
                </div>
                <div className="text-center text-[20px] leading-[12px] text-[#F9986D] py-[20px]">
                  The Pioneers’ Journey
                </div>
                <div className="py-[20px]">
                  <div className="flex items-center pt-[20px] justify-center">
                    <img
                      className="w-[74px] h-[74px] rounded-full mr-4"
                      src={fraser}
                      alt="profile"
                    />
                    <div className="text-sm">
                      <p className="text-white font-bold text-[24px] leading-[31px]">
                        Fraser Matcham
                      </p>
                      <p className="text-white font-normal text-[16px] leading-[20px]">
                        Director, Legal Utopia
                      </p>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:flex-row space-x-8 justify-center items-center py-[20px]">
                  <img src={legal_logo} />
                  <img src={Ministry_of_Justice_logo} />
                  <img src={technation} />
                  <img src={lawtech_panel_logo} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#F0F0F0] bg-opacity-[0.35] py-[60px]">
        <div className="flex md:flex-col lg:max-w-[1024px] justify-between items-left mx-auto">
          <div className="md:flex md:flex-row md:space-x-20">
            <div className="base-1/2">
              <div className="md:flex md:flex-col">
                <p className="text-[#1B4A8A] text-center text-[24px] leading-[31px] py-[10px] font-bold">Legal Service Provider Scoring System</p>
                <p className="text-[#1B4A8A] text-[20px] leading-[26px] text-center py-[10px]">Research Report</p>
                <div className=" md:flex md:flex-col bg-[#65C2D7] w-[491px] h-[386px] mx-auto items-center justify-center rounded-[10px]">
                    <img src={undraw_web_search } className="mx-auto justify-center align-middle" />
                    <a className="bg-[#1B4A8A] rounded-[8px] text-[16px] leading-[21px] font-bold text-white my-[20px] p-[14px]">Download PDF(2020)</a>
                </div>
              </div>
            </div>
            <div className="base-1/2">
              <div className="md:flex md:flex-col">
                <p className="text-[#1B4A8A] text-center text-[24px] leading-[31px] py-[10px] font-bold">Legal Problem Diagnosis A.i.</p>
                <p className="text-[#1B4A8A] text-[20px] leading-[26px] text-center py-[10px]">Research Report</p>
                <div className=" md:flex md:flex-col bg-[#65C2D7] w-[491px] h-[386px] mx-auto items-center justify-center  rounded-[10px]">
                <img src={undraw_online_articles} className="mx-auto justify-center align-middle"/>
                <a className="bg-[#1B4A8A] rounded-[8px] text-[16px] leading-[21px] font-bold text-white my-[20px] p-[14px]">Download PDF(2021)</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResearchMore;
