import cn from "classnames";
import QRCode from "react-qr-code";
import { GetTheAppQrCode } from "./GetTheAppQrCode";

type GetTheAppButtonProps = {
  className?: string;
}

// Currently the app is only being promoted on iOS. When it is properly supported
// on Android then change this to /get-the-app (assuming that page still exists then).
const GET_THE_APP_URL = 'https://apps.apple.com/gb/app/id1487650991?mt=8';

export const GetTheAppButton = ({
  className,
}: GetTheAppButtonProps) => (
  <a
    href={GET_THE_APP_URL}
    className={cn(
      'flex',
      className,
    )}
  >
    <div className="rounded-full bg-lue-purple-400 text-white py-3 px-8 lg:px-3 lg:rounded-lg flex flex-col lg:flex-row">
      <GetTheAppQrCode className="hidden lg:block" size={90} />
      <div className="text-lg lg:text-2xl lg:uppercase lg:font-bold block lg:flex lg:flex-col items-start lg:mx-3">
        <span>Get</span>{' '}
        <span>the</span>{' '}
        <span>app<span className="hidden lg:inline-block">.</span></span>
      </div>
    </div>
  </a>
)
