import { MembershipType } from "../../../common/state-type";

export const getCardTheme = (subType: MembershipType) => {
  switch (subType) {
    case MembershipType.NONE:
      return {
        hex: "#20BFE7",
        class: 'bg-lawBlue',
      };
    case MembershipType.STANDARD:
      return {
        hex: '#8DFFD5',
        class: 'bg-lawBlue',
      }
    case MembershipType.PREMIUM:
      return {
        hex: "#BCBCF2",
        class: 'bg-lawBlue',
      };

    default:
      return {
        hex: "#20BFE7",
        class: 'bg-lawBlue',
      };
  }
};
