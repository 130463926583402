import cn from 'classnames'
import Select from 'react-select'

type Option = {
  value: string
  label: string
}

type DropdownMultiSelectProps = {
  name: string
  value: Option[]
  placeholder?: string
  options: Option[]
  onChange: (newValue: string[]) => void
  isLarge?: boolean
  className?: string
}

export const DropdownMultiSelect = ({
  value,
  options,
  onChange,
  placeholder,
  name,
  isLarge,
  className,
}: DropdownMultiSelectProps) => (
  <Select
    classNames={{
      control: () =>
        cn(
          'bg-transparent py-2 text-lg w-full outline-none placeholder:text-b33 px-3 w-full flex pr-8 flex items-center',
          isLarge && 'lg:pr-12 lg:min-h-[84px] lg:px-5',
          className,
        ),
      valueContainer: () => 'flex text-b33 text-sm gap-2',
      container: () => 'w-full',
      multiValue: () => 'bg-slate-200 flex rounded-full',
      menuList: () =>
        cn(
          'px-4 py-4 w-full rounded-lg text-b33 text-lg shadow-[0_0_14px_0_rgba(0,0,0,0.15)] rounded-[10px] border-width-0',
          isLarge &&
            'lg:rounded-t-none lg:shadow-[0_4px_4px_0_rgba(0,0,0,0.15)]',
        ),
      menu: () => cn('rounded-lg', isLarge && 'lg:rounded-t-none'),
    }}
    styles={{
      control: () => ({
        cursor: 'pointer',
      }),
      input: () => ({
        outline: 'none',
      }),
      multiValue: () => ({
        borderRadius: '1.5rem',
      }),
      option: () => ({
        cursor: 'pointer',
        paddingTop: 6,
        paddingBottom: 6,
      }),
      menu: () => ({
        position: 'absolute',
        background: 'white',
        zIndex: 99999,
      }),
    }}
    placeholder={
      placeholder ? (
        <div className='text-lg text-b33 absolute'>{placeholder}</div>
      ) : undefined
    }
    components={{
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
    }}
    value={value}
    onChange={(v) => {
      onChange(v.map((a) => a.value))
    }}
    options={options}
    name={name}
    isMulti
    isSearchable
  />
)
