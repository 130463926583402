import React from "react";
import Vector_r from "../../assets/Public Content/Vector_r.png";
import Vector_r1 from "../../assets/Public Content/Vector_r1.png";
import Vector_r2 from "../../assets/Public Content/Vector_r2.png";
import Vector_r3 from "../../assets/Public Content/Vector_r3.png";
import Vector_r4 from "../../assets/Public Content/Vector_r4.png";
import Vector_r5 from "../../assets/Public Content/Vector_r5.png";
import Vector_r6 from "../../assets/Public Content/Vector_r6.png";
import Vector_r7 from "../../assets/Public Content/Vector_r7.png";

const Research = () => {
  return (
    <React.Fragment>
      <div className="bg-white bg-opacity-[0.1] py-[60px]">
        <div className="flex lg:max-w-[1024px] justify-between items-center mx-auto">
          <p className="text-[36px] font-bold text-[#1B4A8A] text-left my-[30px] leading-[46px]">
            Research
          </p>
        </div>
      </div>
      <div className="bg-[#D9D9D9] bg-opacity-[0.35] py-[60px]">
        <div className="flex lg:max-w-[1024px] justify-between items-center mx-auto">
          <p className="text-[30px] font-bold text-[#1B4A8A] my-[30px] leading-[40px] text-center">
            Our research engaged with
          </p>
        </div>
        <div className="flex md:flex-col lg:max-w-[1024px] justify-between items-left mx-auto">
          <div className="flex justify-center space-x-5 py-8">
            <div className="flex flex-row w-[310px] p-[30px] bg-[#1B4A8A] rounded-[12px] justify-center">
              <img src={Vector_r1} className="rounded-[10px]" />
              <div className="text-[#F0F0F0] text-[16px] leading-[20px] pl-[10px]">
                <p className="text-center">1</p>
                <p className="text-center">Technology firm</p>
              </div>
            </div>
            <div className="flex flex-row w-[310px] p-[30px] bg-[#1B4A8A] rounded-[12px] leading-[48px] justify-center">
              <img src={Vector_r2} className="rounded-[10px]" />
              <div className="text-[#F0F0F0] text-[16px] leading-[20px] pl-[10px] ">
                <p className="text-center">1</p>
                <p className="text-center">Banking Institution</p>
              </div>
            </div>
            <div className="flex flex-row w-[310px] p-[30px] bg-[#1B4A8A] rounded-[12px] leading-[48px] justify-center">
              <img src={Vector_r} className="rounded-[10px]" />
              <div className="text-[#F0F0F0] text-[16px] leading-[20px] pl-[10px]">
                <p className="text-center">2</p>
                <p className="text-center">UK Universities</p>
              </div>
            </div>
          </div>
          <div className="flex justify-center space-x-5 py-8">
            <div className="flex flex-row w-[285px] p-[30px] bg-[#1B4A8A] rounded-[12px] justify-center">
              <img src={Vector_r3} className="rounded-[10px]" />
              <div className="text-[#F0F0F0] text-[16px] leading-[20px] pl-[10px]">
                <p className="text-center">27,817</p>
                <p className="text-center">Participants</p>
              </div>
            </div>
            <div className="flex flex-row w-[285px] p-[30px] bg-[#1B4A8A] rounded-[12px] leading-[48px] justify-center">
              <img src={Vector_r4} className="rounded-[10px]" />
              <div className="text-[#F0F0F0] text-[16px] leading-[20px] pl-[10px] ">
                <p className="text-center">142</p>
                <p className="text-center">Stakeholders</p>
              </div>
            </div>
            <div className="flex flex-row w-[285px] p-[30px] bg-[#1B4A8A] rounded-[12px] leading-[48px] justify-center">
              <img src={Vector_r5} className="rounded-[10px]" />
              <div className="text-[#F0F0F0] text-[16px] leading-[20px] pl-[10px]">
                <p className="text-center">146,536</p>
                <p className="text-center">Legal data files</p>
              </div>
            </div>
          </div>
          <div className="flex justify-center space-x-5 py-8">
            <div className="flex flex-row w-[329px] p-[30px] bg-[#1B4A8A] rounded-[12px] justify-center">
              <img src={Vector_r6} className="rounded-[10px]" />
              <div className="text-[#F0F0F0] text-[16px] leading-[20px] pl-[10px]">
                <p className="text-center">2</p>
                <p className="text-center">UK Regulators</p>
              </div>
            </div>
            <div className="flex flex-row w-[329px] p-[30px] bg-[#1B4A8A] rounded-[12px] leading-[48px] justify-center">
              <img src={Vector_r7} className="rounded-[10px]" />
              <div className="text-[#F0F0F0] text-[16px] leading-[20px] pl-[10px] ">
                <p className="text-center">5</p>
                <p className="text-center">International Law firms</p>
              </div>
            </div>
          </div>
          <div className="flex mx-auto justify-center items-center pb-[20px]">
            <a className=" text-center bg-[#65C2D7] bg-opacity-[0.2]  rounded-[10px] px-[80px] py-[14px] text-[#1B4A8A] leading-[31px] text-[24px] font-bold">
              See more
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Research;
