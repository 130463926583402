import React from "react";
import bakerMckenzie from '../../assets/Public Content/Lawfirms/bakerMckenzie.png'
import DataLab from '../../assets/Public Content/Lawfirms/DataLab.png'
import Dentons from '../../assets/Public Content/Lawfirms/Dentons.png'
import lawtech from '../../assets/Public Content/Lawfirms/lawtech.png'
import Mishcon from '../../assets/Public Content/Lawfirms/Mishcon.png'
import nortonrose from '../../assets/Public Content/Lawfirms/nortonrose.png'
import UniversityofWestminister from '../../assets/Public Content/Lawfirms/UniversityofWestminister.png'
import EagleLabs from '../../assets/Public Content/SupportedFirms/EagleLabs.png'
import FoundersEmbassy from '../../assets/Public Content/SupportedFirms/FoundersEmbassy.png'
import Kent from '../../assets/Public Content/SupportedFirms/Kent.png'
import LondonPartners from '../../assets/Public Content/SupportedFirms/LondonPartners.png'
import Technation from '../../assets/Public Content/SupportedFirms/Technation.png'
import UkResearch from '../../assets/Public Content/SupportedFirms/UkResearch.png'
import Wayra from '../../assets/Public Content/SupportedFirms/Wayra.png'


const PreFooter = () => {
  return (
    <React.Fragment>
      <div className="bg-[#F0F0F0] bg-opacity-[0.35] py-[60px]">
        <div className="flex md:flex-col lg:max-w-[1024px] justify-between items-left mx-auto">
          <div className="flex flex-col justify-center items-center">
            <p className="text-[36px] leading-[26px] text-black font-bold">We’ve collborated with leading law firms </p>
            <p className="text-[15px] leading-[26px] text-[#777777] font-normal w-[452px] mx-auto text-center p-[20px]">
              Dedicated to making legal assistance more accessible to the
              general public.
            </p>
          </div>
          <div className="flex justify-center space-x-5 py-[30px]">
            <div className="w-[212px] h-[142px] align-middle inline-flex border-solid border-[2px] border-[#cccccc]">
              <img src={lawtech } className="m-auto"/>
            </div>
            <div className="w-[212px] h-[142px] align-middle inline-flex border-solid border-[2px] border-[#cccccc]">
              <img src={bakerMckenzie} className="m-auto"/>
            </div>
            <div className="w-[212px] h-[142px] align-middle inline-flex border-solid border-[2px] border-[#cccccc]">
              <img src={nortonrose} className="m-auto"/>
            </div>
            <div className="w-[212px] h-[142px] align-middle inline-flex border-solid border-[2px] border-[#cccccc]">
              <img src={Mishcon} className="m-auto"/>
            </div>
          </div>
          <div className="flex justify-center space-x-5 py-[30px]">
            <div className="w-[212px] h-[142px] align-middle inline-flex border-solid border-[2px] border-[#cccccc]">
              <img src={UniversityofWestminister} className="m-auto"/>
            </div>
            <div className="w-[212px] h-[142px] align-middle inline-flex border-solid border-[2px] border-[#cccccc]">
              <img src={Dentons} className="m-auto"/>
            </div>
            <div className="w-[212px] h-[142px] align-middle inline-flex border-solid border-[2px] border-[#cccccc]">
              <img src={DataLab} className="m-auto"/>
            </div>
          </div>
          <div className="flex justify-center space-x-5 py-[30px]">
            <p className="text-[36px] leading-[26px] text-black font-bold">Supported & Accelerated by </p>
          </div>
          <div className="flex justify-center space-x-5 py-[30px]">
            <div className="w-[212px] h-[142px] align-middle inline-flex border-solid border-[2px] border-[#cccccc]">
              <img src={EagleLabs} className="m-auto"/>
            </div>
            <div className="w-[212px] h-[142px] align-middle inline-flex border-solid border-[2px] border-[#cccccc]">
              <img src={FoundersEmbassy} className="m-auto"/>
            </div>
            <div className="w-[212px] h-[142px] align-middle inline-flex border-solid border-[2px] border-[#cccccc]">
              <img src={Wayra} className="m-auto"/>
            </div>
            <div className="w-[212px] h-[142px] align-middle inline-flex border-solid border-[2px] border-[#cccccc]">
              <img src={Technation} className="m-auto"/>
            </div>
          </div>
          <div className="flex justify-center space-x-5 py-[30px]">
            <div className="w-[212px] h-[142px] align-middle inline-flex border-solid border-[2px] border-[#cccccc]">
              <img src={Kent} className="m-auto"/>
            </div>
            <div className="w-[212px] h-[142px] align-middle inline-flex border-solid border-[2px] border-[#cccccc]">
              <img src={UkResearch} className="m-auto"/>
            </div>
            <div className="w-[212px] h-[142px] align-middle inline-flex border-solid border-[2px] border-[#cccccc]">
              <img src={LondonPartners} className="m-auto"/>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PreFooter;
