import img1 from '../assets/LawCardImages/image 1.jpg'
import img2 from '../assets/LawCardImages/image 2.jpg'
import img3 from '../assets/LawCardImages/image 3.jpg'
import img4 from '../assets/LawCardImages/image 4.jpg'
import img5 from '../assets/LawCardImages/image 5.jpg'
import img6 from '../assets/LawCardImages/image 6.png'
import img7 from '../assets/LawCardImages/image 7.jpg'
import img8 from '../assets/LawCardImages/image 8.jpg'
import img9 from '../assets/LawCardImages/image 9.jpg'
import img10 from '../assets/LawCardImages/image 10.jpg'

export const lawData = [
  {
    id: 10,
    img: img1,
    title: "Tax",
    description: "Regulated the payment of taxes on: goods and services.",
  },
  {
    id: 1,
    img: img2,
    title: "Employment",
    description: "The obligations, regulations and requirements for  employers, employee and self-employed",
  },
  {
    id: 8,
    img: img7,
    title: "Family",
    description: "Concerns disputes involving children, between adults and between adults and the state.",
  },
  {
    id: 3,
    img: img4,
    title: "Corporate",
    description: "Covers incorporation, management and control of companies governed by various acts.",
  },
  {
    id: 4,
    img: img5,
    title: "Planning, Property & Land",
    description: "This combination of laws concerns the ownership and use of land and property in England.",
  },
  {
    id: 6,
    img: img6,
    title: "Contract",
    description: "Contract law concerns the regulation of contracts and the obligations imposed by them.",
  },
  {
    id: 2,
    img: img3,
    title: "Commercial",
    description: "Concerned with conducts, rights and relations of persons and business engaged in trading.",
  },
  {
    id: 9,
    img: img8,
    title: "Intellectual Property",
    description: "Covers rights such as design rights, trademarks, copyrights, and patents, as well as their eligibility.",
  },
  {
    id: 7,
    img: img9,
    title: "Data Protection",
    description: "Concerns the ways in which personal data is used by businesses, organizations and governments.",
  },{
    id: 5,
    img: img10,
    title: "Civil",
    description: "Extensive area concerning issues and disputes between private citizens.",
  }
]