import { useState, FormEventHandler, ChangeEvent } from "react";
import searchIcon from "../../assets/search.svg";
import { TypeOfLawyerInput } from "./TypeOfLawyerInput";
import { FieldOfLawInput } from "./FieldOfLawInput";
import { LocationInput } from "./LocationInput";

const SearchBar = () => {
  const [lawyerInput, setLawyerInput] = useState("");
  const [locationInput, setLocationInput] = useState("");
  const [fieldOfLawInput, setFieldOfLawInput] = useState<string[]>([]);

  const onLawyerTypeDropdownSelect = (value: string) => {
    setLawyerInput(value);
  };

  const onFieldOfLawChange = (value: string[]) => {
    setFieldOfLawInput(value);
  };

  const onLocationChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setLocationInput(evt.target.value);
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();

    const data = new FormData((evt.target as HTMLFormElement));
    const url = new URL('/dashboard', window.location.origin);
    const location = data.get('location');
    const type = data.get('type');
    const fields = data.getAll('field').filter((x) => x);

    if (location) {
      url.searchParams.set('location', String(location));
    }

    if (type) {
      url.searchParams.set('type', String(type));
    }

    if (fields.length) {
      url.searchParams.set('field', String(fields.join(',')));
    }

    window.location.href = url.href;
  };

  const separator = (
    <div
      className="hidden lg:block border-l-1 h-[60px] w-[1px] bg-slate-400 z-10"
    />
  );

  return (
    <div className="w-full">
      <div className="border-2 border-lue-purple-400 rounded-[10px] lg:rounded-[90px] mt-8 shadow-[0_0_24px_0_rgba(0,0,0,0.15)] w-full">
        <form
          className="bg-white p-3.5 lg:p-0 space-y-3 lg:space-y-0 rounded-[10px] lg:rounded-[90px] flex flex-col lg:flex-row lg:items-center"
          onSubmit={onSubmit}>
          <LocationInput
            onChange={onLocationChange}
            value={locationInput}
          />
          {separator}
          <TypeOfLawyerInput
            value={lawyerInput}
            onSelect={onLawyerTypeDropdownSelect}
          />
          {separator}
          <FieldOfLawInput
            value={fieldOfLawInput}
            onChange={onFieldOfLawChange}
          />
          <button
            type="submit"
            className="rounded-full bg-lue-purple-400 transition-colors hover:bg-lue-purple-500 text-white text-lg py-2 px-4 w-full flex items-center justify-center lg:w-[54px] lg:min-w-[54px] lg:min-h-[54px] lg:mr-4"
          >
            <div className="flex flex-row">
              <img src={searchIcon} width="22" height="22" className="mr-3 lg:mr-0" />
              <span className="lg:hidden">Search</span>
            </div>
          </button>
        </form>
      </div>
    </div>
  );
};

export default SearchBar;
