import { Amplify } from "aws-amplify";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useOffices from "common/providers/useOffices";
import { useLoadScript } from "@react-google-maps/api";

import Map from "./Map";
import {
  AccountType,
  BarristerProfile,
  LawyerListObject,
} from "common/state-type";
import Project from "common/project";
import { OfficeSummary } from "common/state-type";
import useProfile from "common/providers/useProfile";
import useFavourite from "common/providers/useFavourite";
import LawyerProfileCard from "./lawyer/LawyerProfileCard";
import useBarristers from "common/providers/useBarristers";
import LawfrimProfileCard from "./lawfirm/LawfirmProfileCard";
import useLawyerProfiles from "common/providers/useLawyerProfiles";
import BarristerProfileCard from "./barrister/BarristerProfileCard";
import LawfirmCardMbl from 'components/CWP Sections/lawfirm/LawfrimCardMbl'
import { BookingPopup, CreateBooking, BookingProfileType } from './booking'
import PaginationBar from 'components/CWP Sections/components/PaginationBar'
import ProfileCardMobile from 'components/CWP Sections/components/ProfileCardMobile'

const mileToKm = 1.60934 // mile to km
export type position = {
  lat: number
  lng: number
}

type RegionType = {
  latitude: number
  longitude: number
  latitudeDelta: number
  longitudeDelta: number
}

Amplify.configure(Project.cognitoWeb)
const isMobile = Utils.getWindowDimensions().width < 768

const Favourites = () => {
  const navigate = useNavigate()
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDomcLY-xoazL9KnRBNN9b_oROG_SMO9m8',
  })
  const { profile } = useProfile()
  const { getOffices, offices: _offices } = useOffices()
  const { createFavourite, deleteFavourite } = useFavourite()
  const { getBarristers, barristers: _barristers } = useBarristers()
  const { getLawyerProfiles, lawyerProfiles: _lawyers } = useLawyerProfiles()
  const [bookingProfile, setBookingProfile] = useState<BookingProfileType>(null)

  const kilometers = 20 * mileToKm
  const delta = kilometers / 100
  const initialRegionValue = {
    latitude: 51.5074,
    longitude: 0.1278,
    latitudeDelta: delta,
    longitudeDelta: delta,
  }
  const [region, setRegion] = useState<RegionType>(initialRegionValue)
  const [activeMarker, setActiveMarker] = useState<string | null>(null)
  const [page, setPage] = useState(1)

  // Initially setting the center of map to user's geolocation
  useEffect(() => {
    if (navigator.geolocation) {
      ;(
        navigator.permissions?.query?.({ name: 'geolocation' }) ||
        Promise.resolve()
      ).then(() => {
        navigator.geolocation.getCurrentPosition((position) => {
          setRegion({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            latitudeDelta: delta,
            longitudeDelta: delta,
          })
        })
      })
    }
  }, [])

  const fetchParams = {
    page_size: 20,
    favourites_only: true,
  }

  useEffect(() => {
    getLawyerProfiles(fetchParams)
    getBarristers(fetchParams)
    getOffices(fetchParams)
  }, [])

  const solicitor = _lawyers?.results?.map((lawyer) => ({
    ...lawyer,
    type: AccountType.LAWYER,
  }))

  const barristers = _barristers?.results?.map((barrister) => ({
    ...barrister,
    type: AccountType.BARRISTER,
  }))

  const lawfirms = _offices?.results?.map((office) => ({
    ...office,
    type: AccountType.BUSINESS,
  }))

  const allProfiles =
    solicitor && barristers && lawfirms
      ? [...solicitor, ...barristers, ...lawfirms]
      : []

  const handleFavPress = (
    item: LawyerListObject | BarristerProfile | OfficeSummary,
    accountType: AccountType,
  ) => {
    if (item.favourite_id) {
      const data = {
        id: item.favourite_id,
        type: accountType,
      }
      deleteFavourite(data)
    }
  }

  const handleBookPress = (
    item: LawyerListObject | BarristerProfile,
    accountType: AccountType,
  ) => {
    setBookingProfile({ profile: item, lawyerType: accountType })
  }

  const handlePageClick = (clickEvent) => {
    if (clickEvent.isNext) {
      _lawyers.next && getLawyerProfiles({ link: _lawyers.next })
      _barristers.next && getBarristers({ link: _barristers.next })
      _offices.next && getOffices({ link: _offices.next })
    }
    if (clickEvent.isPrevious) {
      _lawyers.previous && getLawyerProfiles({ link: _lawyers.previous })
      _barristers.previous && getBarristers({ link: _barristers.previous })
      _offices.previous && getOffices({ link: _offices.previous })
    }
  }

  return (
    <>
      <div className='bg-gray-200 w-full p-2'>
        <button
          onClick={() => {
            navigate('/dashboard')
          }}
          className='flex flex-row items-center'
        >
          <FaAngleLeft size={26} />
          <span className='pl-2'>Back</span>
        </button>
      </div>
      <div className='flex flex-row w-auto'>
        <div className='flex flex-col px-1 relative overfloww-scroll w-full md:w-[42.29vw] h-[100vh] bg-zinc-100 pb-8'>
          <div id='profile-list' className='py-4'>
            {allProfiles.map((profile) => {
              if (profile.type === AccountType.LAWYER) {
                return (
                  <div key={profile.id}>
                    <ProfileCardMobile
                      cardStyle={'mt-4'}
                      onMouseEnter={() => setActiveMarker(profile.id)}
                      latitude={region.latitude}
                      longitude={region.longitude}
                      data={profile as LawyerListObject}
                      onFavPress={() =>
                        handleFavPress(profile, AccountType.LAWYER)
                      }
                      onBookNow={() =>
                        handleBookPress(
                          profile as LawyerListObject,
                          AccountType.LAWYER,
                        )
                      }
                      lawyerType='solicitor'
                    />
                    <LawyerProfileCard
                      cardStyle={'mt-4'}
                      className='hidden md:flex flex-1'
                      key={profile.id}
                      onMouseEnter={() => setActiveMarker(profile.id)}
                      latitude={region.latitude}
                      longitude={region.longitude}
                      data={profile as LawyerListObject}
                      onFavPress={() =>
                        handleFavPress(profile, AccountType.LAWYER)
                      }
                      onBookNow={() =>
                        handleBookPress(
                          profile as LawyerListObject,
                          AccountType.LAWYER,
                        )
                      }
                    />
                  </div>
                )
              }
              if (profile.type === AccountType.BARRISTER) {
                return (
                  <div key={profile.id}>
                    <ProfileCardMobile
                      cardStyle={'mt-4'}
                      onMouseEnter={() => setActiveMarker(profile.id)}
                      latitude={region.latitude}
                      longitude={region.longitude}
                      data={profile as BarristerProfile}
                      onFavPress={() =>
                        handleFavPress(profile, AccountType.BARRISTER)
                      }
                      onBookNow={() =>
                        handleBookPress(
                          profile as BarristerProfile,
                          AccountType.BARRISTER,
                        )
                      }
                      lawyerType='barrister'
                    />
                    <BarristerProfileCard
                      cardStyle={'mt-4'}
                      className='hidden md:flex flex-1'
                      onMouseEnter={() => setActiveMarker(profile.id)}
                      latitude={region.latitude}
                      longitude={region.longitude}
                      data={profile as BarristerProfile}
                      onFavPress={() =>
                        handleFavPress(profile, AccountType.BARRISTER)
                      }
                      onBookNow={() =>
                        handleBookPress(
                          profile as BarristerProfile,
                          AccountType.BARRISTER,
                        )
                      }
                    />
                  </div>
                )
              }
              if (profile.type === AccountType.BUSINESS) {
                return (
                  <div key={profile.id}>
                    <LawfirmCardMbl
                      cardStyle={'mt-4'}
                      onMouseEnter={() => setActiveMarker(profile.id)}
                      latitude={region.latitude}
                      longitude={region.longitude}
                      data={profile as OfficeSummary}
                      onFavPress={() =>
                        handleFavPress(profile, AccountType.BUSINESS)
                      }
                      onBookNow={() =>
                        navigate(`/lawfirms/${profile.id}/members`)
                      }
                    />
                    <LawfrimProfileCard
                      cardStyle={'mt-4'}
                      className='hidden md:flex flex-1'
                      key={profile.id}
                      onMouseEnter={() => setActiveMarker(profile.id)}
                      latitude={region.latitude}
                      longitude={region.longitude}
                      data={profile as OfficeSummary}
                      onFavPress={() =>
                        handleFavPress(profile, AccountType.BUSINESS)
                      }
                      onBookNow={() => navigate('/lawfirms/members')}
                    />
                  </div>
                )
              }
            })}
          </div>
          <PaginationBar onClick={handlePageClick} />
        </div>
        {bookingProfile && (
          <BookingPopup>
            <CreateBooking
              lawyer={bookingProfile.profile}
              lawyerType={bookingProfile.lawyerType}
              handleClose={() => setBookingProfile(null)}
            />
          </BookingPopup>
        )}
        {isLoaded && !isMobile ? (
          <Map
            setActiveMarker={setActiveMarker}
            activeMarker={activeMarker}
            center={{
              lat: region.latitude,
              lng: region.longitude,
            }}
            lawyers={_lawyers}
            barristers={_barristers}
            offices={_offices}
          />
        ) : null}
      </div>
    </>
  )
}

export default Favourites;
